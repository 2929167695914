/**
 *
 * @module tokenRequestBuilder
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TokenRequest from './tokenRequest';

import type { TokenData } from './typedefs';

/**
 *
 * @access public
 * @desc Interface used to build a token exchange request.
 *
 */
export default abstract class TokenRequestBuilder {
    /**
     *
     * @access public
     * @type {String}
     * @since 4.9.0
     * @desc Gets or sets the grant type. This must be a valid type that is recognized by the service.
     * @note always expected to be token-exchange for delegation grants.
     *
     */
    public readonly grantType: string = '';

    /**
     *
     * @access protected
     * @type {TokenData}
     * @desc Data used in a `TokenRequest` by the `TokenRequestBuilder`.
     * @note When a delegation grant is exchanged with the token service,
     * the request body must include the following fields:
     *   - grant_type
     *   - subject_token_type
     *   - subject_token
     *   - actor_token_type
     *   - actor_token
     *   - refresh_token
     *
     */
    public tokenData: TokenData;

    public constructor() {
        this.tokenData = Object.create(null);
    }

    /**
     *
     * @access public
     * @param {Number} latitude - The latitude of the device.
     * @param {Number} longitude - The longitude of the device.
     * @desc Builds the token request.
     * @returns {Promise<TokenRequest>} Data required to make a token request.
     *
     */
    public async build(latitude: number, longitude: number) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                latitude: Types.number,
                longitude: Types.number
            };

            typecheck(this, 'build', params, arguments);
        }

        const { grantType, tokenData } = this;

        return new TokenRequest({ grantType, tokenData, latitude, longitude });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Token.TokenRequestBuilder';
    }
}
