/**
 *
 * @module subscription
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import BundleStatus from './bundleStatus';
import Product from './product';
import SubscriptionSource from './subscriptionSource';
import SubscriptionProvider from './subscriptionProvider';

import Account from '../services/account/account';

import ProductRule from '../services/subscription/productRule';
import SubscriptionCancellation from '../services/subscription/subscriptionCancellation';
import SubscriptionStatus from '../services/subscription/subscriptionStatus';
import SubscriptionOffer from '../services/subscription/subscriptionOffer';
import SubscriptionFreeTrial from '../services/subscription/subscriptionFreeTrial';

import {
    SubscriptionExpiryType,
    SubscriptionType
} from '../services/subscription/enums';

/**
 *
 * @desc Represents the subscription data the services provide.
 * This structure used by subscription API v1 (i.e. partners like ESPN, etc...).
 *
 */
export default class Subscription {
    /**
     *
     * @access public
     * @type {Date|null}
     * @desc Gets the expiration date of the subscription.
     *
     */
    public expirationDate: Nullable<Date>;

    /**
     *
     * @access private
     * @type {SDK.Services.Subscription.SubscriptionExpiryType}
     * @desc Gets the expiry type.
     *
     */
    public expiryType: SubscriptionExpiryType;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets the URN associated with the subscription.
     *
     */
    public id: string;

    /**
     *
     * @access public
     * @type {Boolean}
     * @gets Gets the boolean value of whether is active or not.
     *
     */
    public isActive: boolean;

    /**
     *
     * @access public
     * @type {Date|null}
     * @desc Gets the date of the subscription's next renewal.
     *
     */
    public nextRenewalDate: Nullable<Date>;

    /**
     *
     * @access public
     * @type {Array<SDK.Subscription.Product>}
     * @desc Gets the array of Products.
     *
     */
    public products: Array<Product>;

    /**
     *
     * @access public
     * @type {Date|null}
     * @desc Gets the start date of the subscription.
     *
     */
    public startDate: Nullable<Date>;

    /**
     *
     * @access public
     * @type {SDK.Services.Subscription.SubscriptionStatus}
     * @desc The status of the subscription.
     *
     */
    public status: SubscriptionStatus;

    /**
     *
     * @access public
     * @type {String<SDK.Subscription.SubscriptionProvider>}
     *
     */
    public provider: OpenEnumOf<typeof SubscriptionProvider>;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Gets the ID of the account the subscription is associated with.
     *
     */
    public accountId?: string;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Gets the ID of the device the subscription is associated with.
     *
     */
    public deviceId?: string;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Gets the external identity.
     *
     */
    public externalIdentity?: string;

    /**
     *
     * @access private
     * @type {Date|null}
     * @desc Gets the date of the last time the subscription was synchronized.
     *
     */
    public lastSyncDate: Nullable<Date>;

    /**
     *
     * @access public
     * @type {SDK.Services.Subscription.ProductRule|null}
     * @desc Gets the product rule.
     *
     */
    public rule: Nullable<ProductRule>;

    /**
     *
     * @access public
     * @type {SDK.Services.Subscription.SubscriptionSource}
     * @desc Gets the source of the subscription.
     *
     */
    public source: SubscriptionSource;

    /**
     *
     * @access private
     * @type {SDK.Services.Subscription.SubscriptionType|undefined}
     * @desc Gets the type of the subscription.
     *
     */
    public type?: SubscriptionType;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String|undefined}
     * @desc The purchase date of the subscription in ISO-8601 format.
     * @note ("YYYY-MM-DDThh:mm:ss.sssZ") Should conform to ISO-8601.
     *
     */
    public purchaseDate?: ISO_8601;

    /**
     *
     * @access public
     * @since 4.1.1
     * @type {SDK.Subscription.BundleStatus}
     * @desc Indicates whether the subscription is a bundle of entitlements for
     * different partners or third party systems.
     *
     */
    public bundleStatus: BundleStatus;

    /**
     *
     * @access public
     * @since 4.2.4
     * @type {SDK.Services.Subscription.SubscriptionOffer|null}
     *
     */
    public offer: Nullable<SubscriptionOffer>;

    /**
     *
     * @access public
     * @since 4.2.4
     * @type {SDK.Services.Subscription.SubscriptionFreeTrial|null}
     * @desc Free trial information for the subscription.
     *
     */
    public freeTrial: Nullable<SubscriptionFreeTrial>;

    /**
     *
     * @access public
     * @since 4.7.0
     * @type {SDK.Services.Subscription.SubscriptionCancellation|null}
     * @desc Cancellation information for the subscription.
     *
     */
    public cancellation: Nullable<SubscriptionCancellation>;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the subscription corresponds to an early access content.
     *
     */
    public earlyAccess?: boolean;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {Boolean|undefined}
     * @desc Denotes whether this subscription is eligible for cancellation.
     *
     */
    public canCancel?: boolean;

    /**
     *
     * @access public
     * @since 12.0.0
     * @type {String|undefined}
     * @desc The system or partner that is responsible for managing the payment relationship with
     * the customer and facilitates integration with payment processors, such as Worldpay or PayPal.
     * @note Classifier used to bill the customer, which will be used by client app devs to know not to show the
     * user the option to change their payment option (ie: in the case of Comcast "payWith" options).
     *
     */
    public paymentProvider?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.accountId]
     * @param {String} [options.deviceId]
     * @param {String} [options.expirationDate]
     * @param {String} [options.expiryType=SubscriptionExpiryType.UNKNOWN]
     * @param {String} [options.externalIdentity]
     * @param {String} options.id
     * @param {Boolean} options.isActive
     * @param {String} [options.lastSyncDate]
     * @param {String} [options.nextRenewalDate]
     * @param {Array<Object>} options.products
     * @param {Object} [options.rule]
     * @param {Object} options.source
     * @param {String} [options.startDate]
     * @param {Object} options.status
     * @param {String} [options.type]
     * @param {String} [options.purchaseDate]
     * @param {Boolean} [options.bundle]
     * @param {Object} [options.offer]
     * @param {Object} [options.freeTrial]
     * @param {Object} [options.cancellation]
     * @param {Boolean} [options.earlyAccess]
     * @param {String} [options.paymentProvider]
     * @param {Boolean} [options.canCancel]
     *
     */
    public constructor(options: {
        accountId?: string;
        deviceId?: string;
        expirationDate?: string;
        expiryType?: string;
        externalIdentity?: string;
        id: string;
        isActive: boolean;
        lastSyncDate?: string;
        nextRenewalDate?: string;
        products: Array<Record<string, unknown>>;
        rule?: Record<string, unknown>;
        source: Record<string, unknown>;
        startDate?: string;
        status: Record<string, unknown>;
        type?: string;
        purchaseDate?: ISO_8601;
        bundle?: boolean;
        offer?: Record<string, unknown>;
        freeTrial?: Record<string, unknown>;
        cancellation?: Record<string, unknown>;
        earlyAccess?: boolean;
        paymentProvider?: string;
        canCancel?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountId: Types.nonEmptyString.optional,
                    deviceId: Types.nonEmptyString.optional,
                    expirationDate: Types.nonEmptyString.optional,
                    expiryType: Types.nonEmptyString.optional,
                    externalIdentity: Types.nonEmptyString.optional,
                    id: Types.nonEmptyString,
                    isActive: Types.boolean,
                    lastSyncDate: Types.nonEmptyString.optional,
                    nextRenewalDate: Types.nonEmptyString.optional,
                    products: Types.array.of.instanceStrict(Object),
                    rule: Types.nonEmptyObject.optional,
                    source: Types.object(),
                    startDate: Types.nonEmptyString.optional,
                    status: Types.object(),
                    type: Types.nonEmptyString.optional,
                    purchaseDate: Types.dateString.optional,
                    bundle: Types.boolean.optional,
                    offer: Types.object().optional,
                    freeTrial: Types.object().optional,
                    cancellation: Types.object().optional,
                    earlyAccess: Types.boolean.optional,
                    paymentProvider: Types.nonEmptyString.optional,
                    canCancel: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            accountId,
            deviceId,
            expirationDate,
            expiryType,
            externalIdentity,
            id,
            isActive,
            lastSyncDate,
            nextRenewalDate,
            products = [],
            rule,
            source,
            startDate,
            status,
            type,
            purchaseDate,
            bundle,
            offer,
            freeTrial,
            cancellation,
            earlyAccess,
            paymentProvider,
            canCancel
        } = options;

        let bundleStatus;

        if (Check.assigned(bundle)) {
            bundleStatus = bundle
                ? BundleStatus.bundle
                : BundleStatus.notBundle;
        }

        this.expirationDate = expirationDate ? new Date(expirationDate) : null;

        this.expiryType =
            SubscriptionExpiryType[expiryType as SubscriptionExpiryType] ||
            SubscriptionExpiryType.UNKNOWN;

        this.id = id;
        this.isActive = isActive;

        this.nextRenewalDate = nextRenewalDate
            ? new Date(nextRenewalDate)
            : null;

        this.products = products.map(
            (product) => new Product(product as TodoAny)
        );

        this.startDate = startDate ? new Date(startDate) : null;
        this.status = new SubscriptionStatus(status as TodoAny);

        this.provider =
            SubscriptionProvider[source.provider as SubscriptionProvider] ||
            (source.provider as string);

        this.accountId = accountId;
        this.deviceId = deviceId;
        this.externalIdentity = externalIdentity;
        this.lastSyncDate = lastSyncDate ? new Date(lastSyncDate) : null;
        this.rule = rule ? new ProductRule(rule) : null;

        this.source = new SubscriptionSource(
            source as {
                provider: string;
                ref: string;
                type: string;
                subType?: string;
            }
        );

        this.type = SubscriptionType[type as SubscriptionType];
        this.purchaseDate = purchaseDate;
        this.bundleStatus = bundleStatus || BundleStatus.unknown;
        this.offer = offer ? new SubscriptionOffer(offer) : null;

        this.freeTrial = freeTrial
            ? new SubscriptionFreeTrial(freeTrial as TodoAny)
            : null;

        this.cancellation = cancellation
            ? new SubscriptionCancellation(cancellation as TodoAny)
            : null;

        this.earlyAccess = earlyAccess;
        this.canCancel = canCancel;
        this.paymentProvider = paymentProvider;
    }

    /**
     *
     * @access public
     * @desc Checks a subscription to determine if it is already bound to a device.
     * @returns {Boolean}
     *
     */
    public isBoundToDevice() {
        return Check.nonEmptyString(this.deviceId);
    }

    /**
     *
     * @access public
     * @desc Checks a subscription to determine if it is already bound to an account.
     * @param {SDK.Services.Account.Account} [account=null]
     * @returns {Boolean}
     *
     */
    public isBoundToAccount(account: Nullable<Account> = null) {
        const { accountId } = this;

        const hasAccountId = Check.assigned(accountId);

        if (hasAccountId && Check.instanceStrict(account, Account)) {
            return accountId === account.accountId;
        }

        return hasAccountId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Subscription.Subscription';
    }
}
