/**
 *
 * @module interstitialEventData
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    InterstitialActivity,
    InterstitialInsertionType,
    InterstitialPlacement,
    InterstitialType,
    NetworkType,
    InterstitialEndedCause,
    ProductType
} from './enums';

import { AdErrorData, AdErrorDataTypedef } from './typedefs';

/**
 *
 * @since 28.1.0
 * @desc This event should be sent each time interstitial playback activity changes.
 * @note [Current Supported Version](https://helios.eds.us-east-1.bamgrid.net/events/playbackInterstitial/1.0.0).
 * @note urn:dss:event:client:playback:interstitial:v1
 *
 */
export default class InterstitialEventData {
    /**
     *
     * @access public
     * @since 28.1.0
     * @type {InterstitialType}
     * @desc Type of interstitial this event is tracking.
     * @note currently only `promo` is supported.
     *
     */
    public interstitialType: InterstitialType;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {InterstitialActivity}
     * @desc Identifies an interstitial-related playback activity.
     * @note Source: SDK.Media.PlaybackEventListener.onInterstitialPlaybackStarted or
     * SDK.Media.PlaybackEventListener.onInterstitialPlaybackEnded event.
     *
     */
    public interstitialActivity: InterstitialActivity;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {InterstitialInsertionType}
     * @desc The way interstitials are inserted into the stream.
     * @note Source by converting `SDK.Media.MediaDescriptor.assetInsertionStrategy`.
     *
     */
    public interstitialInsertionType: InterstitialInsertionType;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {InterstitialPlacement}
     * @desc Placement information.
     * @note Source by converting `SDK.Media.InsertionPoint.placement`, `SDK.Media.InterstitialPlaybackStartedEvent.interstitialPlacement`,
     * or `SDK.Media.InterstitialPlaybackEndedEvent.interstitialPlacement`.
     *
     */
    public interstitialPlacement: InterstitialPlacement;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Number}
     * @desc Playhead position, measured as a millisecond offset from the beginning of the interstitial. Source from
     * `SDK.Media.InterstitialPlaybackStartedEvent.interstitialPlayhead`, or `SDK.Media.InterstitialPlaybackEndedEvent.interstitialPlayhead`.
     *
     */
    public interstitialPlayhead: number;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Number}
     * @desc The reported length of the interstitial in milliseconds.
     * @note Source by converting `SDK.Media.SgaiVodAuxiliaryInsertionPointContent.duration`,
     * `SDK.Media.InterstitialPlaybackStartedEvent.interstitialPlannedLength`, or `SDK.Media.InterstitialPlaybackEndedEvent.interstitialPlannedLength`.
     *
     */
    public interstitialPlannedLength: number;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {String|undefined}
     * @desc Unique identifier of the interstitial (UUID).
     * @note Required if interstitialInsertionType is sgai.
     * @note Source from `SDK.Media.SgaiVodAuxiliaryInsertionPointContent.auxId`, `SDK.Media.InterstitialPlaybackStartedEvent.interstitialId`,
     * or `SDK.Media.InterstitialPlaybackEndedEvent.interstitialId`.
     *
     */
    public interstitialId?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {AdErrorData|undefined}
     * @desc Track any errors that occur while interstitial is playing.
     * @note Source from SDK.Media.InterstitialPlaybackEndedEvent.errorData`.
     *
     */
    public errorData?: AdErrorData;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Object|undefined}
     * @desc Additional partner specific data provided by the `SDK.Logging.AnalyticsProvider` and `MediaApi.initializePlaybackContext`.
     * @note The properties provided in this should be flattened such that there is no `data` property but the information contained within it lives on the root of this class.
     *
     */
    public data?: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Object}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, string>;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {String|undefined}
     * @desc The client-side generated unique ID representing a single element interaction within the container. The interactionId will correspond with one of the
     * defined interactionTypes. The interactionId will be used as the primary key for all interactions.
     * @note Source from `SDK.Media.PlaybackContext`.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Number}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `SDK.Logging.MonotonicTimestampProvider.getTimestamp()`.
     *
     */
    public monotonicTimestamp: number;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {NetworkType}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType: NetworkType;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {string}
     * @desc Client generated ID of the stream/playback session.
     * @note Is added in the `logQoeEvent` method on `SDK.Media.PlaybackTelemetryDispatcher`.
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {ProductType|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `Sdk.Media.PlaybackContext`.
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {InterstitialEndedCause|undefined}
     * @desc The reason for ending interstitial playback.
     * @note Required if `interstitialActivity` is `interstitialEnded`.
     *
     */
    public cause?: InterstitialEndedCause;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Boolean}
     * @desc A flag indicating if the media fetch request to the playback orchestration service succeeded.
     * @note Expected to contain the value `true` in this event.
     *
     */
    public mediaFetchSucceeded: boolean;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {String|undefined}
     * @desc The name of the video player used to play a stream.
     * @note Source from the `SDK.Media.PlayerAdapter`.
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {String|undefined}
     * @desc The version of the video player used to play a stream.
     * @note Source from the `SDK.Media.PlayerAdapter.PlayerVersion`.
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the playlist contains slugs.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.hasSlugs field.
     * @note Default to false if unavailable.
     *
     */
    public hasSlugs?: boolean;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {String|undefined}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType?: string;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @access public
     * @since 28.1.0
     * @type {Object|undefined}
     * @desc Passthrough the value of `MediaPayload.tracking["qoe"]` data included in the playlist service response.
     * @note If available, the `MediaPayload.tracking["qoe"]` should be included on all PQoE events.
     * @note IMPORTANT: The key/value pairs from this Map must be flattened upon serialization such that the resulting json does not contain a
     * "qoe" property but rather a new top level property for each key/value pair in the Map.
     *
     */
    public qoe?: Record<string, unknown>;

    /**
     *
     * @param {Object} options
     * @param {InterstitialType} options.interstitialType
     * @param {InterstitialActivity} options.InterstitialActivity
     * @param {InterstitialInsertionType} options.interstitialInsertionType
     * @param {InterstitialPlacement} options.interstitialPlacement
     * @param {Number} options.interstitialPlayhead
     * @param {Number} options.interstitialPlannedLength
     * @param {String} [options.interstitialId]
     * @param {AdErrorData} [options.errorData]
     * @param {Object} [options.data={}]
     * @param {Object} options.contentKeys
     * @param {String} [options.interactionId]
     * @param {Number} options.monotonicTimestamp
     * @param {NetworkType} options.networkType
     * @param {String} [options.playbackSessionId]
     * @param {ProductType} [options.productType]
     * @param {PlaybackExitedCause} [options.cause]
     * @param {Boolean} options.mediaFetchSucceeded
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {String} [options.hasSlugs]
     * @param {String} [options.subscriptionType]
     * @param {String} [options.programBoundaryInfoBlock]
     * @param {String} [options.qoe={}]
     *
     */
    public constructor(options: {
        interstitialType: InterstitialType;
        interstitialActivity: InterstitialActivity;
        interstitialInsertionType: InterstitialInsertionType;
        interstitialPlacement: InterstitialPlacement;
        interstitialPlayhead: number;
        interstitialPlannedLength: number;
        interstitialId?: string;
        errorData?: AdErrorData;
        data?: Record<string, unknown>;
        contentKeys: Record<string, string>;
        interactionId?: string;
        monotonicTimestamp: number;
        networkType: NetworkType;
        playbackSessionId?: string;
        productType?: ProductType;
        cause?: InterstitialEndedCause;
        mediaFetchSucceeded: boolean;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
        hasSlugs?: boolean;
        subscriptionType?: string;
        programBoundaryInfoBlock?: string;
        qoe?: Record<string, unknown>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    interstitialType: Types.in(InterstitialType),
                    interstitialActivity: Types.in(InterstitialActivity),
                    interstitialInsertionType: Types.in(
                        InterstitialInsertionType
                    ),
                    interstitialPlacement: Types.in(InterstitialPlacement),
                    interstitialPlayhead: Types.number,
                    interstitialPlannedLength: Types.number,
                    interstitialId: Types.nonEmptyString.optional,
                    errorData: Types.object(AdErrorDataTypedef).optional,
                    data: Types.object().optional,
                    contentKeys: Types.object(),
                    interactionId: Types.nonEmptyString.optional,
                    monotonicTimestamp: Types.number,
                    networkType: Types.in(NetworkType),
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    cause: Types.in(InterstitialEndedCause).optional,
                    mediaFetchSucceeded: Types.boolean,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    hasSlugs: Types.boolean.optional,
                    subscriptionType: Types.nonEmptyString.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional,
                    qoe: Types.object().optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            interstitialType,
            interstitialActivity,
            interstitialInsertionType,
            interstitialPlacement,
            interstitialPlayhead,
            interstitialPlannedLength,
            interstitialId,
            errorData,
            data = {},
            contentKeys,
            interactionId,
            monotonicTimestamp,
            networkType,
            playbackSessionId,
            productType,
            cause,
            mediaFetchSucceeded,
            videoPlayerName,
            videoPlayerVersion,
            hasSlugs,
            subscriptionType,
            programBoundaryInfoBlock,
            qoe = {}
        } = options;

        this.interstitialType = interstitialType;
        this.interstitialActivity = interstitialActivity;
        this.interstitialInsertionType = interstitialInsertionType;
        this.interstitialPlacement = interstitialPlacement;
        this.interstitialPlayhead = interstitialPlayhead;
        this.interstitialPlannedLength = interstitialPlannedLength;
        this.interstitialId = interstitialId;
        this.errorData = errorData;
        this.contentKeys = contentKeys;
        this.interactionId = interactionId;
        this.monotonicTimestamp = monotonicTimestamp;
        this.networkType = networkType;
        this.playbackSessionId = playbackSessionId!;
        this.productType = productType;
        this.cause = cause;
        this.mediaFetchSucceeded = mediaFetchSucceeded;
        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.hasSlugs = hasSlugs;
        this.subscriptionType = subscriptionType;
        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        this.setData(data);
        this.setData(qoe);
    }

    /**
     *
     * @access private
     * @since 28.1.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: object) {
        Object.assign(this, data);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.InterstitialEventData';
    }
}
