/**
 *
 * @module configurationClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ConfigurationClientHostParams from './configurationClientHostParams';

/**
 *
 * @access protected
 * @desc Used to expose the list of configuration host names and corresponding baseUrls
 * in the `ConfigurationClientConfiguration`
 *
 */
export default class ConfigurationClientExtrasMap {
    /**
     *
     * @access public
     * @type {Record<string, ConfigurationClientHostParams>}
     * @desc The object containing the list of configuration host names and corresponding baseUrls
     *
     */
    public configHostParams: Record<
        string,
        ConfigurationClientHostParams | undefined
    >;

    /**
     *
     * @param {Record<string, ConfigurationClientHostParams>} configHostParams
     *
     */
    public constructor(
        configHostParams: Record<string, ConfigurationClientHostParams>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                configHostParams: Types.nonEmptyObject
            };

            typecheck.warn(this, params, arguments);
        }

        this.configHostParams = configHostParams;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ConfigurationClientExtrasMap';
    }
}
