/**
 *
 * @module guidRegex
 *
 */

export const guidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 *
 * @param {String} guidString
 * @desc tests a string to see if it represents a guid
 * @returns {Boolean}
 *
 */
export default (guidString?: string) => {
    return guidPattern.test(guidString || '');
};
