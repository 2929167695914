/**
 *
 * @module entry
 * @desc {SDK.UserActivity.Glimpse} entry point.
 *
 */

import GlimpseEvents from './glimpseEvents';

/**
 *
 * @typedef {SDK.UserActivity.Glimpse}
 *
 * @property {GlimpseEvents} GlimpseEvents
 *
 */
export default {
    GlimpseEvents
};
