/**
 *
 * @module purchaseApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/purchase.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/PurchaseApi.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/external-activation.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/eligibility.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import EligibilityClient from '../services/eligibility/eligibilityClient';
import ExternalActivationClient from '../services/externalActivation/externalActivationClient';
import TokenManager from '../token/tokenManager';

import ExternalActivationResult from '../services/externalActivation/externalActivationResult';
import ActivationToken from '../services/externalActivation/activationToken';
import EligibilityStatusResponse from '../services/eligibility/eligibilityStatusResponse';
import { ApiOptions } from '../typedefs';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';

import type Logger from '../logging/logger';
import type LogTransaction from '../logging/logTransaction';

const DustUrn = DustUrnReference.purchase.purchaseApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides a way to redeem and restore purchases.
 *
 */
export default class PurchaseApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Services.ExternalActivation.ExternalActivationClient}
     *
     */
    private externalActivationClient: ExternalActivationClient;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Services.Eligibility.EligibilityClient}
     *
     */
    private eligibilityClient: EligibilityClient;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.ExternalActivation.ExternalActivationClient} options.externalActivationClient
     * @param {SDK.Services.Eligibility.EligibilityClient} options.eligibilityClient
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        externalActivationClient: ExternalActivationClient;
        eligibilityClient: EligibilityClient;
        tokenManager: TokenManager;
        logger: Logger;
    }) {
        super({ accessTokenProvider: options.tokenManager, ...options });

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    externalActivationClient: Types.instanceStrict(
                        ExternalActivationClient
                    ),
                    eligibilityClient: Types.instanceStrict(EligibilityClient),
                    tokenManager: Types.instanceStrict(TokenManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { externalActivationClient, eligibilityClient, tokenManager } =
            options;

        this.externalActivationClient = externalActivationClient;
        this.eligibilityClient = eligibilityClient;
        this.tokenManager = tokenManager;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} providerId - The provider the activation token was obtained from ('hulu' when activating a Hulu bundle).
     * @param {String} activationTokenString - The activation token as a String.
     * @desc Creates a new link between account in header and account in token body. Checks if any subscription exists, then
     * new account is linked to that subscription as well. Can only be called for bundle linking.
     * @note Send as `linkToken` in the JSON payload.
     * @note Calls the `ExternalActivationClient.redeemBundle()` function.
     * @throws {ActivationTokenExpiredException} The service failed to activate due to the activation token being expired.
     * @throws {ActivationBadRequestException} The service failed to activate due to a bad request.
     * @throws {ActivationForbiddenException} The service failed to activate due to linking being forbidden.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the `ExternalActivationResult`
     * which can be inspected by the application developer to verify `temporaryAccessGranted` & `resultStatus`.
     *
     * @example <caption>Checking the external activation result to verify activation status</caption>
     *
     * const ExternalActivation = SDK.Services.ExternalActivation;
     * const TemporaryDefaultAccessActivationStatus = ExternalActivation.TemporaryDefaultAccessActivationStatus;
     * const externalActivationResult = await purchaseApi.redeemBundle(providerId, activationTokenString);
     * const resultStatus = externalActivationResult.resultStatus;
     *
     * if (resultStatus === TemporaryDefaultAccessActivationStatus.activationSucceeded) {
     *     console.log('Activation Succeeded.');
     * }
     *
     */
    public async redeemBundle(
        providerId: string,
        activationTokenString: string
    ): Promise<ExternalActivationResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString,
            activationTokenString: Types.nonEmptyString
        }
    })
    public async redeemBundle(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [providerId, activationTokenString]
        } = apiOptions as ApiOptions<[string, string]>;

        this.logger.info(
            this.toString(),
            `Redeem bundle for provider: ${providerId}`
        );

        const externalActivationResult =
            await this.externalActivationClient.redeemBundle(
                providerId,
                activationTokenString,
                super.accessToken,
                logTransaction
            );

        await this.processExternalActivationResult(
            externalActivationResult,
            logTransaction
        );

        const temporaryAccessGranted =
            externalActivationResult.temporaryAccessGranted;
        const resultStatus = externalActivationResult.resultStatus;

        if (temporaryAccessGranted) {
            this.logger.info(
                this.toString(),
                'Temporary access has been granted.'
            );
        }

        if (resultStatus) {
            this.logger.info(
                this.toString(),
                `Temporary access, result status: ${resultStatus}`
            );
        }

        dustLogUtility?.logData({
            result: externalActivationResult
        });

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @desc Verifies the purchase contained within the token with the third-party service identified by `providerId`, and grants subscription + entitlements.
     * @note Send as `linkToken` in the JSON payload.
     * @note Calls the `ExternalActivationClient.redeemToken()` function.
     * @throws {ActivationTokenExpiredException} The service failed to activate due to the activation token being expired.
     * @throws {ActivationBadRequestException} The service failed to activate due to a bad request.
     * @throws {ActivationForbiddenException} The service failed to activate due to linking being forbidden.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the `ExternalActivationResult`
     * which can be inspected by the application developer to verify `temporaryAccessGranted` & `resultStatus`.
     *
     * @example <caption>Checking the external activation result to verify activation status</caption>
     *
     * const ExternalActivation = SDK.Services.ExternalActivation;
     * const TemporaryDefaultAccessActivationStatus = ExternalActivation.TemporaryDefaultAccessActivationStatus;
     * const externalActivationResult = await purchaseApi.redeemToken(providerId, activationTokenString);
     * const resultStatus = externalActivationResult.resultStatus;
     *
     * if (resultStatus === TemporaryDefaultAccessActivationStatus.activationSucceeded) {
     *     console.log('Activation Succeeded.');
     * }
     *
     */
    public async redeemToken(
        providerId: string,
        activationTokenString: string
    ): Promise<ExternalActivationResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString,
            activationTokenString: Types.nonEmptyString
        }
    })
    public async redeemToken(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [providerId, activationTokenString]
        } = apiOptions as ApiOptions<[string, string]>;

        this.logger.info(
            this.toString(),
            `Redeem token for provider: ${providerId}`
        );

        const externalActivationResult =
            await this.externalActivationClient.redeemToken(
                providerId,
                activationTokenString,
                super.accessToken,
                logTransaction
            );

        await this.processExternalActivationResult(
            externalActivationResult,
            logTransaction
        );

        const temporaryAccessGranted =
            externalActivationResult.temporaryAccessGranted;
        const resultStatus = externalActivationResult.resultStatus;

        if (temporaryAccessGranted) {
            this.logger.info(
                this.toString(),
                'Temporary access has been granted.'
            );
        }

        if (resultStatus) {
            this.logger.info(
                this.toString(),
                `Temporary access, result status: ${resultStatus}`
            );
        }

        dustLogUtility?.logData({
            result: externalActivationResult
        });

        return externalActivationResult;
    }

    /**
     *
     * @access public
     * @param {String} providerId - The provider the token will be used to activate with ('hulu' when activating a Hulu bundle).
     * @desc Creates a JWT link token based on user's bundle purchase. Authorized third
     * party should be able to validate this token and grant user access. Can only be called from D+ partner.
     * @note Calls the `ExternalActivationClient.createExternalRedemptionToken()` function.
     * @note Currently very Hulu specific (the endpoint is templated, however only the Hulu provider is supported).
     * @note This method will be deprecated post unification.
     * @note Uses the legacy service. Will be replaced by the generateActivationToken flow.
     * @throws {BundleForbiddenException} The service failed to return the ActivationToken for the bundle purchase.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} A promise that returns information about the link token when it succeeds.
     *
     */
    public async createExternalRedemptionToken(
        providerId: string
    ): Promise<ActivationToken>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            providerId: Types.nonEmptyString
        }
    })
    public async createExternalRedemptionToken(apiOptions: unknown) {
        const {
            logTransaction,
            args: [providerId]
        } = apiOptions as ApiOptions<[string]>;

        this.logger.info(
            this.toString(),
            `Create external redemption token for provider: ${providerId}`
        );

        return this.externalActivationClient.createExternalRedemptionToken(
            providerId,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 14.1.0
     * @param {String} contentProvider - Content Provider of the entitlement -- who will provide the user with the content they purchased.
     * @desc Generate a JWT activation token based on the user's entitlements. Authorized third party should be able to
     * validate this token and grant the user access to content.
     * @throws {BundleForbiddenException}
     * @throws {ProviderNotEnabledException}
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an object containing the activation token.
     *
     */
    public async generateActivationToken(
        contentProvider: string
    ): Promise<ActivationToken>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            contentProvider: Types.nonEmptyString
        }
    })
    public async generateActivationToken(apiOptions: unknown) {
        const {
            logTransaction,
            args: [contentProvider]
        } = apiOptions as ApiOptions<[string]>;

        this.logger.info(
            this.toString(),
            `Generate ActivationToken for content provider: ${contentProvider}`
        );

        return this.externalActivationClient.generateActivationToken(
            contentProvider,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {String} [sku]
     * @desc Request eligibility status for a given product.
     * @throws {AccountResolutionException} The account could not be resolved.
     * @throws {HuluServiceException} There was a general Hulu error while processing the request.
     * @throws {InvalidRequestException} Response returned in case of invalid request.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Eligibility.EligibilityStatusResponse>} Returns an asynchronous action that
     * will send a notification when it is complete.
     *
     */
    public async getEligibilityStatus(
        sku?: string
    ): Promise<EligibilityStatusResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            sku: Types.nonEmptyString.optional
        }
    })
    public async getEligibilityStatus(apiOptions: unknown) {
        const {
            logTransaction,
            args: [sku]
        } = apiOptions as ApiOptions<[string?]>;

        return this.eligibilityClient.getEligibilityStatus({
            sku,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @desc refreshes the `AccessToken` if `forceRefresh` is true.
     *
     */
    private async processExternalActivationResult(
        externalActivationResult: ExternalActivationResult,
        logTransaction: LogTransaction
    ) {
        const forceRefresh = externalActivationResult.refreshAccessToken;

        if (forceRefresh) {
            await this.tokenManager.refreshAccessToken({
                forceRefresh,
                logTransaction
            });
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Purchase.PurchaseApi';
    }
}
