/**
 *
 * @module monotonicClock
 *
 */

let lastTime = 0;

/**
 *
 * @access protected
 * @since 20.0.0
 * @desc Monotonic Clock
 * @returns {Object}
 *
 */
export default {
    /**
     *
     * @access protected
     * @since 20.0.0
     * @desc Monotonically increasing timestamp.
     * @returns {Number}
     * @note performance.now() may return float numbers
     *
     */
    timestamp: () => {
        if (
            typeof performance !== 'undefined' &&
            typeof performance.now === 'function'
        ) {
            return performance.now();
        }

        const currentTime = Date.now();

        let adjusted;

        if (lastTime > currentTime) {
            // ensures offset is never below 0
            adjusted = currentTime + (lastTime - currentTime) + 1;
        } else {
            adjusted = currentTime;
        }

        lastTime = adjusted;

        return adjusted;
    }
};
