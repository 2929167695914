/**
 *
 * @module documentCode
 *
 */

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Paywall.DocumentCode
 * @desc Document that consumers will have to review before proceeding to purchase.
 * @note Flexible enum
 *
 * @property {String} subscriberConfirmation =- 'subscriberConfirmation' `4.13.0` - User is required (for specific countries) to review
 * the Subscriber Agreement ='Agreement' the Disney+ Sign Up flow.
 * @property {String} taxID =- 'taxID' `4.13.0` - User is required (for specific countries) to provide taxId on billing page.
 * @property {String} streetAddress =- 'streetAddress' `4.13.0` - User is required (for specific countries) to provide street address on billing page.
 * @property {String} city =- 'city' `4.13.0` - User is required (for specific countries) to provide city on billing page.
 * @property {String} state =- 'state' `4.13.0` - User is required (for specific countries) to provide state on billing page.
 * @property {String} postalCode - Since `4.13.0` - User is required (for specific countries) to provide postal code on billing page.
 *
 */
enum DocumentCode {
    subscriberConfirmation = 'subscriberConfirmation',
    taxID = 'taxID',
    streetAddress = 'streetAddress',
    city = 'city',
    state = 'state',
    postalCode = 'postalCode'
}
export default DocumentCode;
