/**
 *
 * @module eventBufferTelemetryPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TelemetryPayload from '../../services/internal/telemetry/telemetryPayload';
import EventBufferBase from './eventBufferBase';
import TelemetryEvent from './telemetryEvent';

export default class EventBufferTelemetryPayload extends EventBufferBase<TelemetryPayload> {
    /**
     *
     * @access private
     * @since 28.0.0
     * @type {String}
     *
     */
    protected bufferName: string;

    /**
     *
     * @access private
     * @type {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>}
     * @desc The manager will persist telemetry events in a queue prior to
     * forwarding to the client. This queue should survive an app restart.
     * this queue should be specific to this instances type - stream events or dust events
     * @todo set in Storage
     * @todo drain queue when authorization fails
     *
     */
    public queue: Array<TelemetryPayload>;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.bufferConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Internal.TelemetryClient} options.telemetryClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {Object} options.endpoint
     * @param {Object} options.prohibited
     * @param {Object} [options.fastTrack=null]
     * @param {SDK.DiagnosticFeature} [options.diagnosticFeature]
     * @param {String} bufferName - Used for differentiating which buffer is doing the logging.
     *
     */
    public constructor(
        options: Prettify<
            ConstructorParameters<typeof EventBufferBase>[0] & {
                bufferName: string;
            }
        >
    ) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bufferName: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { bufferName } = options;

        this.bufferName = bufferName;

        this.queue = [];
    }

    /**
     *
     * @access public
     * @param {SDK.Internal.Telemetry.TelemetryEvent} telemetryEvent
     * @desc Post an event to the telemetry queue for upload to the ce-telemetry-service
     * @note `fastTrack` events need to be sent immediately
     *
     */
    public postEvent(telemetryEvent: TelemetryEvent) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                telemetryEvent: Types.instanceStrict(TelemetryEvent)
            };

            typecheck(this, 'postEvent', params, arguments);
        }

        if (this.disabled) {
            this.logger.warn(
                this.toString(),
                'Buffering is disabled, events will not be posted.'
            );

            return;
        }

        const telemetryPayload = telemetryEvent.getPayload();

        const event = telemetryPayload.client.event;
        const isFastTrackEvent = this.fastTrack?.urns.includes(event);
        const isProhibited = this.prohibited.urns.includes(event);

        if (isProhibited) {
            this.logger.warn(
                this.toString(),
                `Event will not be posted because it is prohibited: ${event}`
            );

            return;
        }

        if (isFastTrackEvent) {
            this.logger.info(
                this.toString(),
                `Event added to beginning of queue: ${event}`
            );

            // calling async without await - it should not wait for processBatch - it's a fire-and-forget..
            this.processBatch(telemetryPayload);
        } else {
            if (this.queueLimit) {
                while (this.queue.length > this.queueLimit - 1) {
                    this.queue.shift();
                }
            }

            this.queue.push(telemetryPayload);
            this.logger.info(
                this.toString(),
                `Event added to end of queue: ${event}`
            );

            this.scheduleNextBatch();
        }
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {Array<SDK.Socket.RawSocketMessage>} [options.messageEnvelopes]
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} [options.telemetryPayloads]
     * @param {Boolean} options.useProxy
     * @desc A helper to send dust events.
     * returns {SDK.Services.Internal.Telemetry.TelemetryResponse}
     *
     */
    public override async postDust(payload: Array<TelemetryPayload>) {
        const { accessToken, useProxy } = this;

        const telemetryResponse = await this.client.postDustEvents({
            accessToken,
            telemetryPayloads: payload,
            useProxy
        });

        this.handleValidationResults(telemetryResponse, payload);

        return telemetryResponse;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @param {Number} [batchLimit]
     *
     */
    public override getMessagesToSend(batchLimit?: number) {
        return this.queue.splice(0, batchLimit || this.queue.length);
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @desc determines if any messages are available to be sent
     *
     */
    public override hasMessagesToSend() {
        return this.queue.length >= this.minimumBatchSize;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @desc requeue items
     *
     */
    protected override requeuePayloadItems(items: Array<TelemetryPayload>) {
        this.queue.unshift(...items);
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @desc clears the queue
     *
     */
    public override clearQueue() {
        this.queue = [];
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return `SDK.Internal.Telemetry.EventBufferTelemetryPayload [${this.bufferName}]`;
    }
}
