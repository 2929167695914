/**
 *
 * @module platformPlugin
 * @desc {SDK.Platform.PlatformPlugin} entry point.
 *
 */

import Platform from './entry';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Platform', typeof Platform> = {
    pluginName: 'Platform',
    entry: Platform
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
