/**
 *
 * @module reason
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/services/class.sdk.services.paywall.plantuml
 *
 */

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Paywall.Reason
 * @desc Supplemental reason for the returning SKU or reason for no returning SKU
 *
 * @property {String} other - Since `4.13.0` - An undocumented reason was returned.
 * @property {String} comingSoon - Since `4.13.0` - Before premiere date, SKU is returned for
 * supported platform (for early access only).
 * @property {String} platformUnavailable - Since `4.13.0` - Any period of time for unsupported
 * platform (for early access only) or when no SKU returned (for non-early access).
 * @property {String} purchaseAllowed - Since `4.13.0` - During premiere period with SKU returned (for early access only)
 * and SKU returned for other cases (for non-early access)
 * @property {String} purchased - Since `4.13.0` - During premiere period with SKU returned indicating user already
 * purchased this SKU (for early access only).
 * @property {String} blockout - Since `4.13.0` - During blockout period with no SKU return (for early access only).
 * @property {String} expired - Since `4.13.0` - After SKU is available in general catalog with no SKU return (for early access only).
 *
 */
enum Reason {
    /**
     *
     * @note fallback enum, catch all value
     *
     */
    other = 'other',
    comingSoon = 'comingSoon',
    platformUnavailable = 'platformUnavailable',
    purchaseAllowed = 'purchaseAllowed',
    purchased = 'purchased',
    blockout = 'blockout',
    expired = 'expired'
}
export default Reason;
