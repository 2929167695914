/**
 *
 * @module spriteThumbnailSet
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { ThumbnailResolution } from '../../media/enums';
import Presentation from './presentation';

import ThumbnailSet from './thumbnailSet';

export default class SpriteThumbnailSet extends ThumbnailSet {
    /**
     *
     * @access public
     * @type {Number}
     * @desc The number of rows of thumbnails in each sprite sheet.
     *
     */
    public thumbnailRows: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The number of rows of thumbnails in each sprite sheet.
     *
     */
    public thumbnailColumns: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Quality equates to jpeg quality, which represents the loss due to compression.
     *
     */
    public quality: number;

    /**
     *
     * @param {Object} options
     * @param {Number} options.thumbnailRows
     * @param {Number} options.thumbnailColumns
     * @param {Number} options.quality
     * @note params below are checked in the base class
     * @param {String<SDK.Media.ThumbnailResolution>} options.resolution
     * @param {Number} options.thumbnailWidth
     * @param {Number} options.thumbnailHeight
     * @param {Number} options.intervalMilliseconds
     * @param {Number} options.totalBytes
     * @param {Array<SDK.Services.Media.Presentation>} options.presentations
     *
     */
    public constructor(options: {
        thumbnailRows: number;
        thumbnailColumns: number;
        quality: number;
        resolution: ThumbnailResolution;
        thumbnailWidth: number;
        thumbnailHeight: number;
        intervalMilliseconds: number;
        totalBytes: number;
        presentations: Array<Presentation>;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailRows: Types.number,
                    thumbnailColumns: Types.number,
                    quality: Types.number
                })
            };

            typecheck(this, params, arguments);
        }

        const { thumbnailRows, thumbnailColumns, quality } = options;

        this.thumbnailRows = thumbnailRows;
        this.thumbnailColumns = thumbnailColumns;
        this.quality = quality;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Media.SpriteThumbnailSet';
    }
}
