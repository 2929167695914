/**
 *
 * @module qoeError
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    ApplicationContext,
    ErrorLevel,
    ErrorSource,
    QoePlaybackError
} from './enums';

import ErrorReason from '../exception/errorReason';

/**
 *
 * @since 13.0.0
 * @desc Represents a collection of error-specific properties required to log an error from either the app, SDK, or service.
 *
 */
export default class QoeError {
    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ApplicationContext}
     * @desc Used to distinguish where in the application the error is being sent from.
     *
     */
    public applicationContext: ApplicationContext;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc Was the error fatal to the application.
     * @note For player and ad events, `isFatal = TRUE` would mean errors that lead to playback stopping or being blocked.
     *
     */
    public isFatal: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ErrorSource}
     * @desc Where the error originated from.
     *
     */
    public source: ErrorSource;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc Client generated ID of the unique error.
     *
     */
    public errorId: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ErrorLevel}
     * @desc Impact level. In addition to indicating the level of impact that an event may have on the client,
     * it also affects the verbosity of error reporting.
     *
     */
    public errorLevel: ErrorLevel;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.QoePlaybackError}
     * @desc The name of the error.
     * @note If `applicationContext` is not `player`, set to `QoePlaybackError::Unknown` by default.
     *
     */
    public errorName: QoePlaybackError;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The localization key the client used to look up the user-facing error string in their associated SDK string dictionary.
     * @note Required if fatal error. Temporarily optional if `applicationContext` is `player`.
     * @note Ideally, this would be supplied for any fatal error. However, there may be platform/timing constraints
     * @note on getting this information from the application. For now, supply this value if it's available.
     *
     */
    public errorLocalizationKey?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The dictionary version the client used to look up the `errorLocalizationKey` (user-facing error string)
     * when `errorLocalizationKey` is a resource key for user-facing errors (i.e. `"82.0"`, `"82.1"`).
     * @note Required if fatal error.
     *
     */
    public dictionaryVersion?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.Exception.ErrorReason|undefined}
     * @desc Information about the reason(s) the error occurred. When the error occurred is an SDK error, use the SDK error cause.
     * @note Required if fatal error and the `source` is sdk.
     *
     */
    public underlyingSdkError?: ErrorReason;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Supporting error text for a `QoePlaybackError` for additional context.
     *
     */
    public errorMessage?: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.QualityOfService.ApplicationContext} options.applicationContext
     * @param {Boolean} options.isFatal
     * @param {SDK.Services.QualityOfService.ErrorSource} options.source
     * @param {String} options.errorId
     * @param {SDK.Services.QualityOfService.ErrorLevel} options.errorLevel
     * @param {SDK.Services.QualityOfService.QoePlaybackError} options.errorName
     * @param {String|undefined} [options.errorLocalizationKey]
     * @param {String|undefined} [options.dictionaryVersion]
     * @param {SDK.Services.Exception.ErrorReason|undefined} [options.underlyingSdkError]
     * @param {String|undefined} [options.errorMessage]
     *
     */
    public constructor(options: {
        applicationContext: ApplicationContext;
        isFatal: boolean;
        source: ErrorSource;
        errorId: string;
        errorLevel: ErrorLevel;
        errorName: QoePlaybackError;
        errorLocalizationKey?: string;
        dictionaryVersion?: string;
        underlyingSdkError?: ErrorReason;
        errorMessage?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    applicationContext: Types.in(ApplicationContext),
                    isFatal: Types.boolean,
                    source: Types.in(ErrorSource),
                    errorId: Types.nonEmptyString,
                    errorLevel: Types.in(ErrorLevel),
                    errorName: Types.in(QoePlaybackError),
                    errorLocalizationKey: Types.nonEmptyString.optional,
                    dictionaryVersion: Types.nonEmptyString.optional,
                    underlyingSdkError:
                        Types.instanceStrict(ErrorReason).optional,
                    errorMessage: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            applicationContext,
            isFatal,
            source,
            errorId,
            errorLevel,
            errorName,
            errorLocalizationKey,
            dictionaryVersion,
            underlyingSdkError,
            errorMessage
        } = options;

        this.applicationContext = applicationContext;
        this.isFatal = isFatal;
        this.source = source;
        this.errorId = errorId;
        this.errorLevel = errorLevel;
        this.errorName = errorName;
        this.errorLocalizationKey = errorLocalizationKey;
        this.dictionaryVersion = dictionaryVersion;
        this.underlyingSdkError = underlyingSdkError;
        this.errorMessage = errorMessage;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.QoeError';
    }
}
