/**
 *
 * @module orchestrationRequest
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import GraphQlRequest from '../../graphQl/graphQlRequest';

import type { QueryOptions } from '../../../orchestration/orchestrationManager';

/**
 *
 * @since 9.0.0
 *
 */
export default class OrchestrationRequest extends GraphQlRequest {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc The operation name.
     * @note If this value is assigned, AND it matches an entry in config ( services.orchestration ) , that config entry is to be used.
     * @note Otherwise the generic config endpoint ( services.orchestration.query ) is to be used.
     * @note This value is to be output as the dust event so it must *always* be set.
     *
     */
    public operationName: string;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {String} options.operationName - The operation name.
     * @param {String} options.query - A custom query that returns only the specified set of data.QueuingStrategySize
     * @param {Object} [options.variables={}] - The query variables object.
     *
     */
    public constructor(options: QueryOptions) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    operationName: Types.nonEmptyString
                })
            };

            // do not throw an error, warn for backward compatibility
            typecheck.warn(this, params, arguments);
        }

        const { operationName } = options;

        this.operationName = operationName;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.OrchestrationRequest';
    }
}
