/**
 *
 * @module genericErrorHandler
 *
 */

import { IErrorHandler } from './IErrorHandler';
import ErrorReason from '../../../exception/errorReason';

const isGenericFormat = (error: Record<string, unknown>) => {
    return typeof error.code === 'string';
};

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Generic error handler.
 * @returns {Object}
 *
 */
const GenericErrorHandler: IErrorHandler = {
    is: (options) => {
        const { errors = [] } = options.data || {};

        return Array.isArray(errors) && !!errors.length;
    },
    process: (options) => {
        const errorReasons: Array<ErrorReason> = [];
        const { errors = [] } = options.data || {};

        const filteredErrors = errors.filter(isGenericFormat);

        filteredErrors.forEach((filteredError) => {
            const { code, description, data } = filteredError;

            errorReasons.push(
                new ErrorReason({
                    code,
                    description,
                    data
                })
            );
        });

        return { errorReasons };
    }
};

export default GenericErrorHandler;
