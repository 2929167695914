/**
 *
 * @module registerDeviceRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';

import DeviceAttributes from '../../../device/deviceAttributes';
import DeviceMetadata from '../../../device/deviceMetadata';
import { registerDevice } from './queries.gql';

//
// @note: DeviceMetadata contains attributes: DeviceAttributes
// omitting attributes from DeviceMetadata here and checking
// a slimmed-down DeviceAttributes type instead
//
export type RegisterDeviceInput = Omit<DeviceMetadata, 'attributes'> & {
    deviceLanguage: string | null;
    attributes: Omit<
        DeviceAttributes,
        'deviceLanguage' | 'modelFamily' | 'passwordResetRequired'
    >;
    huluUserToken?: string;
};

/**
 *
 * @since 9.0.0
 *
 */
export default class RegisterDeviceRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: RegisterDeviceInput) {
        const variables = {
            input
        };

        super({
            query: registerDevice,
            variables,
            operationName: 'registerDevice'
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.RegisterDeviceRequest';
    }
}
