/**
 *
 * @module entry
 * @desc {SDK.Services.Exception} entry point.
 *
 */

import ErrorReason from './errorReason';
import ErrorCode from './errorCode';
import ExceptionReference from './exceptionReference';
import InvalidArgumentException from './invalidArgumentException';
import ProviderException from './providerException';
import ServiceException from './serviceException';

/**
 *
 * @typedef {Object} SDK.Services.Exception
 *
 * @property {ErrorReason} ErrorReason
 * @property {SDK.Services.Exception.ErrorCode} ErrorCode
 * @property {SDK.Services.Exception.ExceptionReference} ExceptionReference
 * @property {InvalidArgumentException} InvalidArgumentException
 * @property {ProviderException} ProviderException
 * @property {ServiceException} ServiceException
 *
 */
export default {
    ErrorReason,
    ErrorCode,
    ExceptionReference,
    InvalidArgumentException,
    ProviderException,
    ServiceException
};
