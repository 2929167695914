/**
 *
 * @module sessionInfo
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import getValueForKeyOrValue from '../util/getValueForKeyOrValue';
import getKeyForKeyValue from '../util/getKeyForKeyValue';

import {
    SessionProfileInfoTypedef,
    SessionLocationTypedef,
    SessionDeviceInfoTypedef,
    SessionAccountInfoTypedef,
    SessionEntitlementTypedef,
    SessionIdentityTypedef,
    SessionFeaturesTypedef,
    PortabilityLocationTypedef,
    PreferredMaturityRatingTypedef,
    HomeLocationTypedef
} from './typedefs';

import type {
    SessionAccountInfo,
    SessionDeviceInfo,
    SessionLocation,
    SessionProfileInfo,
    SessionEntitlement,
    SessionExperimentAssignment,
    PortabilityLocation,
    PreferredMaturityRating,
    HomeLocation,
    SessionIdentity,
    SessionFeatures
} from './typedefs';

import LocationType from './locationType';

/* eslint-disable camelcase */

type DirtyPortabilityLocation = {
    country_code: string;
    countryCode: string;
    type: string;
};
type DirtySessionExperimentAssignment = SessionExperimentAssignment & {
    variant_id: string;
};
type DirtyHomeLocation = HomeLocation & {
    country_code: string;
};
type DirtySessionInputData = {
    account: SessionAccountInfo;
    entitlements: Array<SessionEntitlement>;
    experiments:
        | Array<SessionExperimentAssignment | DirtySessionExperimentAssignment>
        | {
              [key in string]:
                  | SessionExperimentAssignment
                  | DirtySessionExperimentAssignment;
          };
    device: SessionDeviceInfo;
    inSupportedLocation: boolean;
    isSubscriber: boolean;
    location: SessionLocation & {
        zip_code?: string;
        type: string;
        country_code: string;
    };
    preferredMaturityRating: PreferredMaturityRating;
    profile: SessionProfileInfo;
    identity: SessionIdentity;
    features: SessionFeatures;
    session_id?: string;
    sessionId?: string;
    id?: string;
    partnerName: string;
    partner?: {
        name: string;
    };
    portability_location?: DirtyPortabilityLocation;
    portabilityLocation?: DirtyPortabilityLocation;
    home_location: DirtyHomeLocation;
    homeLocation: DirtyHomeLocation;
};
/* eslint-enable camelcase */

/**
 *
 * @desc Represents the session info provided by the service.
 *
 */
export default class SessionInfo {
    /**
     *
     * @access public
     * @type {String}
     * @desc The Session Id.
     *
     */
    public id: string;

    /**
     *
     * @access public
     * @type {Object<SDK.Services.Session.SessionAccountInfo>|undefined}
     *
     */
    public account?: SessionAccountInfo;

    /**
     *
     * @access public
     * @type {Object<SDK.Services.Session.SessionDeviceInfo>}
     *
     */
    public device: SessionDeviceInfo;

    /**
     *
     * @access public
     * @type {Object<SDK.Services.Session.SessionLocation>}
     *
     */
    public location: SessionLocation;

    /**
     *
     * @access public
     * @type {Object<SDK.Services.Session.SessionProfileInfo>|undefined}
     *
     */
    public profile?: SessionProfileInfo;

    /**
     *
     * @access public
     * @type {Array<Object<SDK.Services.Session.SessionEntitlement>>}
     *
     */
    public entitlements: Array<SessionEntitlement>;

    /**
     *
     * @access public
     * @type {Boolean}
     *
     */
    public inSupportedLocation: boolean;

    /**
     *
     * @access public
     * @since 3.10.0
     * @type {Boolean}
     *
     */
    public isSubscriber: boolean;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {Object<String, Object<SDK.Services.Session.SessionExperimentAssignment>>}
     *
     */
    public experiments: Record<string, SessionExperimentAssignment>;

    /**
     *
     * @access public
     * @since 4.7.0
     * @type {Object<SDK.Services.Session.PortabilityLocation>|undefined}
     * @desc Location with cross-border portability rules applied.
     *
     */
    public portabilityLocation?: PortabilityLocation;

    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Object<SDK.Services.Session.PreferredMaturityRating>|undefined}
     * @desc Preferred maturity rating for a profile.
     *
     */
    public preferredMaturityRating?: PreferredMaturityRating;

    /**
     *
     * @access public
     * @since 5.0.0
     * @type {Object<SDK.Services.Session.HomeLocation>|undefined}
     * @desc The home location of the user.
     *
     */
    public homeLocation?: HomeLocation;

    /**
     *
     * @access public
     * @since 8.0.0
     * @type {String}
     * @desc Partner associated with the session.
     *
     */
    public partnerName: string;

    /**
     *
     * @access public
     * @since 8.0.0
     * @type {Object<SDK.Services.Session.SessionIdentity>|undefined}
     *
     */
    public identity?: SessionIdentity;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Object<SDK.Services.Session.SessionFeatures>|undefined}
     *
     */
    public features?: SessionFeatures;

    /**
     *
     * @param {Object} options
     * @param {String} options.id
     * @param {Object<SDK.Services.Session.SessionAccountInfo>} [options.account]
     * @param {Object<SDK.Services.Session.SessionDeviceInfo>} options.device
     * @param {Object<SDK.Services.Session.SessionLocation>} options.location
     * @param {Object<SDK.Services.Session.SessionProfileInfo>} [options.profile]
     * @param {Array<Object<SDK.Services.Session.SessionEntitlement>>} options.entitlements
     * @param {Boolean} options.inSupportedLocation - Denotes whether a user is in a supported country
     * @param {Boolean} options.isSubscriber
     * @param {Object<String, Object<SDK.Services.Session.SessionExperimentAssignment>>} options.experiments
     * @param {Object<SDK.Services.Session.PortabilityLocation>} [options.portabilityLocation]
     * @param {Object<SDK.Services.Session.PreferredMaturityRating>} [options.preferredMaturityRating]
     * @param {Object<SDK.Services.Session.HomeLocation>} [options.homeLocation]
     * @param {String} options.partnerName
     * @param {Object<SDK.Services.Session.SessionIdentity>} [options.identity]
     * @param {Object<SDK.Services.Session.SessionFeatures>} [options.features]
     *
     */
    public constructor(options: {
        id: string;
        account?: SessionAccountInfo;
        device: SessionDeviceInfo;
        location: SessionLocation;
        profile?: SessionProfileInfo;
        entitlements: Array<SessionEntitlement>;
        inSupportedLocation: boolean;
        isSubscriber: boolean;
        experiments: Record<string, SessionExperimentAssignment>;
        portabilityLocation?: PortabilityLocation;
        preferredMaturityRating?: PreferredMaturityRating;
        homeLocation?: HomeLocation;
        partnerName: string;
        identity?: SessionIdentity;
        features?: SessionFeatures;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    id: Types.nonEmptyString,
                    account: Types.object(SessionAccountInfoTypedef).optional,
                    device: Types.object(SessionDeviceInfoTypedef),
                    location: Types.object(SessionLocationTypedef),
                    profile: Types.object(SessionProfileInfoTypedef).optional,
                    entitlements: Types.array.of.object(
                        SessionEntitlementTypedef
                    ),
                    inSupportedLocation: Types.boolean,
                    isSubscriber: Types.boolean,
                    experiments: Types.object(),
                    portabilityLocation: Types.object(
                        PortabilityLocationTypedef
                    ).optional,
                    preferredMaturityRating: Types.object(
                        PreferredMaturityRatingTypedef
                    ).optional,
                    homeLocation: Types.object(HomeLocationTypedef).optional,
                    partnerName: Types.nonEmptyString,
                    identity: Types.object(SessionIdentityTypedef).optional,
                    features: Types.object(SessionFeaturesTypedef).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            id,
            account,
            device,
            location,
            profile,
            entitlements,
            inSupportedLocation,
            partnerName,
            isSubscriber,
            experiments,
            portabilityLocation,
            preferredMaturityRating,
            homeLocation,
            identity,
            features
        } = options;

        this.id = id;
        this.account = account;
        this.device = device;
        this.location = location;
        this.profile = profile;
        this.entitlements = entitlements;
        this.inSupportedLocation = inSupportedLocation;
        this.isSubscriber = isSubscriber;
        this.experiments = experiments;
        this.portabilityLocation = portabilityLocation;
        this.preferredMaturityRating = preferredMaturityRating;
        this.homeLocation = homeLocation;
        this.partnerName = partnerName;
        this.identity = identity;
        this.features = features;
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {String}
     * @desc The Session Id.
     *
     */
    public get sessionId() {
        return this.id;
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {Object} data
     * @desc attempts to create a `SessionInfo` by normalizing session info data from several different sources including
     * 1) Old session info service
     * 2) New graphql session info schema
     * 3) SDK JSON serialized session info
     * @returns {SessionInfo}
     *
     */
    /* eslint-disable camelcase */
    public static create(inputData: unknown) {
        const data = inputData as DirtySessionInputData;

        const {
            account,
            entitlements,
            experiments = {},
            device,
            inSupportedLocation,
            isSubscriber,
            location,
            preferredMaturityRating,
            profile,
            identity,
            features
        } = data as DirtySessionInputData;

        const sessionInfoId = (data.session_id || // the old session service format
            data.sessionId || // the graphql schema format
            data.id) as string; // the serialized format

        const partnerName = (data.partnerName ||
            (data.partner && data.partner.name)) as string;

        let sessionLocation;

        if (location) {
            const type = getValueForKeyOrValue(
                LocationType,
                location.type,
                LocationType.UNKNOWN
            );
            const designatedMarketArea =
                location.dma || location.designatedMarketArea;
            const zipCode = location.zip_code || location.zipCode;
            const countryCode = location.country_code || location.countryCode;

            sessionLocation = {
                ...location,
                type,
                zipCode,
                countryCode,
                designatedMarketArea
            };
        }

        let sessionProfileInfo;

        if (profile) {
            sessionProfileInfo = profile;
        }

        let portabilityLocation;

        const portabilityLocationData =
            data.portability_location || data.portabilityLocation;

        if (Check.assigned(portabilityLocationData)) {
            const type =
                LocationType[
                    getKeyForKeyValue(
                        LocationType,
                        portabilityLocationData?.type
                    ) as keyof typeof LocationType
                ];

            if (type) {
                portabilityLocation = {
                    countryCode:
                        portabilityLocationData?.country_code ||
                        portabilityLocationData?.countryCode,
                    type
                };
            }
        }

        const sessionEntitlements: Array<SessionEntitlement> = [];

        if (entitlements) {
            entitlements.forEach((entitlement: SessionEntitlement) => {
                const name = Check.string(entitlement)
                    ? entitlement
                    : entitlement.name;

                sessionEntitlements.push({ name });
            });
        }

        const sessionExperiments = Object.create(null);

        if (Array.isArray(experiments)) {
            experiments.forEach((item) => {
                const { featureId } = item;

                sessionExperiments[featureId] = item;
            });
        } else {
            for (const [key, value] of Object.entries(experiments)) {
                const { variantId, variant_id, version } =
                    value as DirtySessionExperimentAssignment;
                const options = {
                    variantId: variantId || variant_id,
                    version,
                    featureId: key
                };

                sessionExperiments[key] = options;
            }
        }

        let homeLocation: HomeLocation | undefined;

        const homeLocationData = data.home_location || data.homeLocation;

        if (Check.assigned(homeLocationData)) {
            homeLocation = {
                ...homeLocationData,
                countryCode:
                    homeLocationData.country_code ||
                    homeLocationData.countryCode
            };
        }

        const sessionInfoResult = new SessionInfo({
            id: sessionInfoId,
            account,
            device,
            location: sessionLocation as SessionLocation,
            profile: sessionProfileInfo,
            entitlements: sessionEntitlements,
            inSupportedLocation,
            isSubscriber,
            experiments: sessionExperiments,
            portabilityLocation: portabilityLocation as PortabilityLocation,
            preferredMaturityRating,
            homeLocation,
            partnerName,
            identity,
            features
        });

        return sessionInfoResult;

        /* eslint-enable camelcase */
    }

    /**
     *
     * @access public;
     * @since 20.1.0
     * @param {Object<SessionExperimentAssignment>} experiments
     * @returns Array<SessionExperimentAssignment>
     *
     */
    public static convertSessionExperimentsToArray(
        experiments:
            | Record<string, SessionExperimentAssignment>
            | Array<SessionExperimentAssignment>
    ) {
        if (Array.isArray(experiments)) {
            return experiments;
        }

        if (Check.nonEmptyObject(experiments)) {
            // re-write object experiments back to array
            return Object.entries(experiments).map(
                ([featureId, experiment]) => {
                    const { variantId, version } = experiment;

                    return {
                        featureId,
                        variantId,
                        version
                    };
                }
            );
        }

        return [];
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Session.SessionInfo';
    }
}
