/**
 *
 * @module authenticatedMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import SocketConnectionState from '../socketConnectionState';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.9.0
 * @desc Handles the refresh socket message
 *
 */
export default class AuthenticatedMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {Function}
     *
     */
    private startupPromiseResolveCallback: () => void;
    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     * @param {Function} startupPromiseResolveCallback - The promise's `resolve` callback to be called when the authentication message is handled
     *
     */
    public constructor(
        socketManager: SocketManager,
        startupPromiseResolveCallback: () => void
    ) {
        const messageType = SocketUrns.socketManager.authenticated;

        super(messageType);

        this.socketManager = socketManager;
        this.startupPromiseResolveCallback = startupPromiseResolveCallback;
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @desc Handles finalizing our socket opening promise when the authentication message comes in as well as closing any previously open socket.
     *
     */
    public async process() {
        this.startupPromiseResolveCallback();

        this.socketManager.retryAttempts = 0;
        this.socketManager.retryDelay =
            this.socketManager.retryPolicy.retryBasePeriod * 1000;

        this.socketManager.emitConnectionState(SocketConnectionState.connected);
        this.socketManager.sendFlowControl();
        await this.socketManager.flushAwaitingConnectionBuffer();
        this.socketManager.pingPongMessageProcessor.start();

        if (this.socketManager.oldSocket) {
            this.socketManager.closeExistingSocket();
        }
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.AuthenticatedMessageProcessor';
    }
}
