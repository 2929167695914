/**
 *
 * @module deviceAttributes
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/device.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import OsDeviceId from './osDeviceId';

/**
 *
 * @access public
 * @since 9.0.0
 * @desc Attributes represent characteristics of the device and software runtime
 * of the application and SDK. All attributes should be sourced from documented
 * system APIs by the SDK unless overridden.
 *
 */
export default class DeviceAttributes {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {Array<OsDeviceId>}
     * @desc An array of system-provided identifiers.
     *
     */
    public osDeviceIds: Array<OsDeviceId>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String|null}
     * @desc The manufacturer of the device.
     *
     */
    public manufacturer: Nullable<string>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String|null}
     * @desc The model code of the device.
     *
     */
    public model: Nullable<string>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc The name of the device's operating system.
     * @note We fallback to 'n/a' if value not provided because the orchestration service requires a value.
     *
     */
    public operatingSystem: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String}
     * @desc The version of the device's operating system.
     * @note We fallback to 'n/a' if value not provided because the orchestration service requires a value.
     *
     */
    public operatingSystemVersion: string;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String|null}
     * @desc The name of the browser used to render the web application, if applicable.
     *
     */
    public browserName: Nullable<string>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {String|null}
     * @desc The version of the browser used to render the web application, if applicable.
     *
     */
    public browserVersion: Nullable<string>;

    /**
     *
     * @access public
     * @since 10.0.0
     * @type {String|null}
     * @desc The language used to render document text, if applicable.
     *
     */
    public deviceLanguage: Nullable<string>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|null}
     * @desc Grouping of device model (where applicable for a given platform).
     * @note This is currently only populated for Roku. example: '7000x' Others are to send `null`.
     *
     */
    public modelFamily: Nullable<string>;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Boolean}
     * @desc A password reset required setting.
     *
     */
    public passwordResetRequired: boolean;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String|undefined}
     * @desc Grouping of device brand (where applicable for a given platform).
     * @note This is currently only populated for Android but possibly will be in the future.
     *
     */
    public brand: string | undefined;

    /**
     *
     * @param {Object} [options={}]
     * @param {Array<OsDeviceId>} [options.osDeviceIds=[]]
     * @param {String} [options.manufacturer=null]
     * @param {String} [options.model=null]
     * @param {String} [options.operatingSystem='n/a']
     * @param {String} [options.operatingSystemVersion='n/a']
     * @param {String} [options.browserName=null]
     * @param {String} [options.browserVersion=null]
     * @param {String} [options.deviceLanguage=null]
     * @param {String} [options.modelFamily=null]
     * @param {String} [options.brand]
     * @param {Boolean} [options.passwordResetRequired=false]
     *
     */
    public constructor(options?: {
        osDeviceIds?: Array<OsDeviceId>;
        manufacturer?: string;
        model?: string;
        operatingSystem?: string;
        operatingSystemVersion?: string;
        browserName?: Nullable<string>;
        browserVersion?: Nullable<string>;
        deviceLanguage?: string;
        modelFamily?: string;
        brand?: string;
        passwordResetRequired?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    osDeviceIds:
                        Types.array.of.instanceStrict(OsDeviceId).optional,
                    manufacturer: Types.string.optional,
                    model: Types.string.optional,
                    operatingSystem: Types.string.optional,
                    operatingSystemVersion: Types.string.optional,
                    browserName: Types.string.optional,
                    browserVersion: Types.string.optional,
                    deviceLanguage: Types.string.optional,
                    modelFamily: Types.string.optional,
                    passwordResetRequired: Types.boolean.optional,
                    brand: Types.string.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            osDeviceIds,
            manufacturer,
            model,
            operatingSystem,
            operatingSystemVersion,
            browserName,
            browserVersion,
            deviceLanguage,
            modelFamily,
            passwordResetRequired,
            brand
        } = options || {};

        this.osDeviceIds = osDeviceIds || [];
        this.manufacturer = manufacturer || null;
        this.model = model || null;
        this.operatingSystem = operatingSystem || 'n/a';
        this.operatingSystemVersion = operatingSystemVersion || 'n/a';
        this.browserName = browserName || null;
        this.browserVersion = browserVersion || null;
        this.deviceLanguage = deviceLanguage || null;
        this.modelFamily = modelFamily || null;
        this.passwordResetRequired = passwordResetRequired || false;
        this.brand = brand;
    }

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {Object} [attributes={}]
     * @param {String} [attributes.manufacturer='n/a']
     * @param {String} [attributes.operatingSystem='n/a']
     * @param {String} [attributes.operatingSystemVersion='n/a']
     * @returns {Object} with normalize fallback values if input was not found
     *
     */
    public static normalizeAttributes(attributes?: {
        manufacturer?: Nullable<string>;
        operatingSystem?: Nullable<string>;
        operatingSystemVersion?: Nullable<string>;
    }) {
        const { manufacturer, operatingSystem, operatingSystemVersion } =
            attributes || {};

        return {
            ...attributes,
            manufacturer: manufacturer || 'n/a',
            operatingSystem: operatingSystem || 'n/a',
            operatingSystemVersion: operatingSystemVersion || 'n/a'
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Device.DeviceAttributes';
    }
}
