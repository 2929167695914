/**
 *
 * @module accountClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/account.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/account.md
 *
 */

/* eslint-disable camelcase */

import { Types, typecheck } from '@dss/type-checking';

import AccessToken from '../token/accessToken';
import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';

import replaceHeaders from '../util/replaceHeaders';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import OrchestrationClient from '../orchestration/orchestrationClient';

import Account from './account';
import AccountClientConfiguration from './accountClientConfiguration';
import AccountClientEndpoint from './accountClientEndpoint';
import AccountGrant from './accountGrant';
import CreateAccountResult from './createAccountResult';
import UserProfile from './userProfile';
import ClientBase from '../clientBase';
import HttpHeaders from '../providers/shared/httpHeaders';

import { HttpCoreMethod } from '../providers/typedefs';

const AccountClientDustUrnReference =
    DustUrnReference.services.account.accountClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access account services.
 *
 */
export default class AccountClient extends ClientBase<AccountClientConfiguration> {
    /**
     *
     * @access private
     * @type {OrchestrationClient}
     * @since 24.0.0
     *
     */
    public orchestrationClient: OrchestrationClient;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Account.AccountClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.Orchestration.OrchestrationClient} options.orchestrationClient
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: AccountClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        orchestrationClient: OrchestrationClient;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    orchestrationClient:
                        Types.instanceStrict(OrchestrationClient)
                })
            };

            typecheck(this, params, arguments);
        }

        this.orchestrationClient = options.orchestrationClient;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.identityToken - Identity to create the account from.
     * @param {Object} options.attributes - Attributes to create with the account.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {Object} [options.metadata] - Object which may hold additional information about the account
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Create an account by providing an id token and some registration information.
     * @returns {Promise<SDK.Services.Account.CreateAccountResult>} The account ID if successfully created.
     *
     */
    public createAccount(options: {
        identityToken: string;
        attributes: object;
        accessToken: AccessToken;
        metadata?: object;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    identityToken: Types.nonEmptyString,
                    attributes: Types.object(),
                    accessToken: Types.instanceStrict(AccessToken),
                    metadata: Types.object().optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'createAccount', params, arguments);
        }

        const {
            identityToken,
            attributes,
            accessToken,
            metadata,
            logTransaction
        } = options;

        const { logger } = this;

        const body = {
            ...{ id_token: identityToken },
            ...attributes,
            ...metadata
        };

        const endpointKey = AccountClientEndpoint.createAccount;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body
        });

        logger.info(this.toString(), 'Attempting to create account.');

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.createAccount,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const { accountId } = data;

                return new CreateAccountResult(accountId);
            }
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Get an account grant by providing some proof of identity e.g. id token.
     * @returns {Promise<SDK.Services.Account.Account>} Describes the result of fetching an Account
     *
     */
    public getCurrentAccount(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getCurrentAccount', params, arguments);
        }

        const { logger } = this;

        const endpointKey = AccountClientEndpoint.getCurrentAccount;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Get current account based on provided token.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.getCurrentAccount,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const { accountId, activeProfile, attributes, identities } =
                    data;

                const activeUserProfile = new UserProfile(activeProfile);

                const options = {
                    accountId,
                    activeUserProfile,
                    attributes,
                    identities
                };

                return new Account(options);
            }
        });
    }

    /**
     *
     * @deprecated Deprecated as of `27.1.0` and will be removed in a future version. See release notes for replacement.
     * @access public
     * @param {String} identityToken - Identity to get the account grant for.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Get an account grant by providing some proof of identity e.g. id token.
     * @returns {Promise<SDK.Services.Account.AccountGrant>} An account grant that can be exchanged for an access token
     * if successful.
     *
     */
    public createAccountGrant(
        identityToken: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                identityToken: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createAccountGrant', params, arguments);
        }

        const { logger } = this;

        const endpointKey = AccountClientEndpoint.createAccountGrant;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                id_token: identityToken
            }
        });

        logger.log(this.toString(), 'Attempting to create account grant.');

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.createAccountGrant,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const { assertion, grant_type } = data;

                return new AccountGrant({
                    assertion,
                    grant_type
                });
            }
        });
    }

    /**
     *
     * @access public
     * @param {Object} attributes - The account attributes to update.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Update the account attributes of the account identified in the access token,
     * if field(s) are missing from the request body, the original value will be left intact.
     * @returns {Promise<Object>} An awaitable task indicating settings were updated successfully.
     *
     */
    public async updateAttributes(
        attributes: object,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                attributes: Types.object(),
                accessToken: Types.instanceStrict(AccessToken)
            };

            typecheck(this, 'updateAttributes', params, arguments);
        }

        const { logger } = this;

        const endpointKey = AccountClientEndpoint.updateAccountAttributes;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: attributes
        });

        logger.info(
            this.toString(),
            `Update account attributes - "${JSON.stringify(attributes)}".`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AccountClientDustUrnReference.updateAttributes,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => response
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Account.AccountClientEndpoint} options.endpointKey - endpoint key to be referenced.
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: AccountClientEndpoint;
        body?: object;
    }) {
        const { accessToken, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { headers = {}, method, href } = endpoint || {};
        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href as string,
            method: method as HttpCoreMethod,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Account.AccountClient';
    }
}
