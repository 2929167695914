/**
 *
 * @module externalActivationClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/external-activation.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import handleServiceResponse from '../util/handleServiceResponse';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import ActivationToken from './activationToken';
import TemporaryDefaultAccessActivationStatus from './temporaryDefaultAccessActivationStatus';
import ExternalActivationResult from './externalActivationResult';
import ExternalActivationClientEndpoint from './externalActivationClientEndpoint';
import ExternalActivationClientConfiguration from './externalActivationClientConfiguration';
import LogTransaction from '../../logging/logTransaction';
import AccessToken from '../token/accessToken';
import { IEndpoint, ServerResponse } from '../providers/typedefs';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import {
    TEMPORARY_ACCESS_GRANTED,
    REFRESH_ACCESS_TOKEN
} from '../providers/shared/httpHeaderConstants';

const ExternalActivationClientDustUrnReference =
    DustUrnReference.services.externalActivation.externalActivationClient;

/**
 *
 * @access protected
 *
 */
export default class ExternalActivationClient extends ClientBase<ExternalActivationClientConfiguration> {
    /**
     *
     * @param options
     * @param {SDK.Services.ExternalActivation.ExternalActivationClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: ExternalActivationClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(
                    ExternalActivationClientConfiguration
                )
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Verifies the bundled purchase contained within the token with the
     * third-party service identified by `providerId`, and grants subscription + entitlements.
     * @note Send as 'linkToken' in the json payload.
     * @note This method should use the `activateBundle` endpoint.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the
     * ExternalActivationResult which can be inspected by the application developer to
     * verify temporaryAccessGranted & resultStatus.
     *
     */
    public async redeemBundle(
        providerId: string,
        activationTokenString: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                activationTokenString: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeemBundle', params, arguments);
        }

        const { logger } = this;

        const endpointKey = ExternalActivationClientEndpoint.activateBundle;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                linkToken: activationTokenString
            },
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.redeemBundle,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request<ExternalActivationResult>({
            payload,
            dustLogUtility,
            skipHandleServiceResponse: true,
            resultMapper: (response) => {
                return this.redeemResponseHandler(response, dustLogUtility);
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @param {String} providerId - The provider the activation token was obtained from.
     * @param {String} activationTokenString - The activation token as a String.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Verifies the purchase contained within the token with the third-party service
     * identified by `providerId`, and grants subscription + entitlements.
     * @note Send as 'linkToken' in the json payload.
     * @note This method should use the `activateToken` endpoint.
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>} Returns the
     * ExternalActivationResult which can be inspected by the application developer to
     * verify temporaryAccessGranted & resultStatus.
     *
     */
    public async redeemToken(
        providerId: string,
        activationTokenString: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                activationTokenString: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'redeemToken', params, arguments);
        }

        const { logger } = this;

        const endpointKey = ExternalActivationClientEndpoint.activateToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                linkToken: activationTokenString
            },
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.redeemToken,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request<ExternalActivationResult>({
            payload,
            dustLogUtility,
            skipHandleServiceResponse: true,
            resultMapper: (response) => {
                return this.redeemResponseHandler(response, dustLogUtility);
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {String} providerId - The provider the redemption token will be redeemed with.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a proof-of-purchase token for redemption with an external service.
     * @note Currently very Hulu specific (the endpoint is templated, however only the Hulu provider is supported).
     * @note This method will be deprecated post unification.
     * @note Uses the legacy service. Will be replaced by the generateActivationToken flow.
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an
     * Object containing the redemption token.
     *
     */
    public async createExternalRedemptionToken(
        providerId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                providerId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createExternalRedemptionToken', params, arguments);
        }

        const { logger } = this;

        const endpointKey = ExternalActivationClientEndpoint.getActivationToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                providerId
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.createExternalRedemptionToken,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data = {} } = response;
                const { linkToken } = data;

                const activationToken = new ActivationToken(linkToken);

                return activationToken;
            }
        });
    }

    /**
     *
     * @access protected
     * @since 14.1.0
     * @param {String} contentProvider - Content Provider of the entitlement -- who will provide the user with the content they purchased.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Generate a JWT activation token based on the user's entitlements. Authorized third party should be able to
     * validate this token and grant the user access to content.
     * @see https://github.bamtech.co/pages/activation/openapi-specs/content-provider-activation-api.html
     * @returns {Promise<SDK.Services.ExternalActivation.ActivationToken>} Returns an
     * Object containing the activation token.
     *
     */
    public async generateActivationToken(
        contentProvider: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                contentProvider: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'generateActivationToken', params, arguments);
        }

        const { logger } = this;

        const endpointKey =
            ExternalActivationClientEndpoint.generateActivationToken;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                contentProvider
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ExternalActivationClientDustUrnReference.generateActivationToken,
            data: {
                contentProviderId: contentProvider
            },
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data = {} } = response;
                const { activationToken } = data;

                return new ActivationToken(activationToken);
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @param {Object} response
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {Promise<SDK.Services.ExternalActivation.ExternalActivationResult>}
     *
     */
    private async redeemResponseHandler(
        response: ServerResponse,
        dustLogUtility: DustLogUtility
    ) {
        const { headers, status, data } = response;
        const { errors } = data;

        const refreshAccessToken = !!headers.get(REFRESH_ACCESS_TOKEN);
        const temporaryAccessGranted = !!headers.get(TEMPORARY_ACCESS_GRANTED);

        let resultStatus: TemporaryDefaultAccessActivationStatus;

        const externalActivationResult = new ExternalActivationResult({
            refreshAccessToken,
            temporaryAccessGranted,
            errors
        });

        return handleServiceResponse({ response, dustLogUtility })
            .then(() => {
                switch (status) {
                    case 200:
                        resultStatus =
                            TemporaryDefaultAccessActivationStatus.activationSucceeded;
                        break;
                    case 202:
                        if (temporaryAccessGranted) {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationRetryingWithTda;
                        } else {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationRetryingWithoutTda;
                        }
                        break;
                    // no default
                }

                externalActivationResult.resultStatus = resultStatus;

                return externalActivationResult;
            })
            .catch((exception) => {
                switch (exception.status) {
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                    case 504:
                        if (temporaryAccessGranted) {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationFailedWithTda;
                        } else {
                            resultStatus =
                                TemporaryDefaultAccessActivationStatus.activationFailedWithoutTda;
                        }
                        break;
                    default:
                        resultStatus = exception;
                }

                externalActivationResult.resultStatus = resultStatus;

                return externalActivationResult;
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.ExternalActivation.ExternalActivationClientEndpoint} options.endpointKey
     * @param {Object} [options.body]
     * @param {Object} [options.data]
     * @returns {Object} The payload for the client call.
     *
     */
    public getPayload(options: {
        accessToken: AccessToken;
        endpointKey: ExternalActivationClientEndpoint;
        body?: object;
        data?: {
            providerId?: string;
            contentProvider?: string;
        };
    }) {
        const { accessToken, endpointKey, body, data } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, headers, method } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        let url = href;

        if (endpoint.templated && data?.providerId) {
            url = url.replace(/\{providerId\}/gi, data.providerId);
        }

        if (endpoint.templated && data?.contentProvider) {
            url = url.replace(/\{contentProvider\}/gi, data.contentProvider);
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.ExternalActivation.ExternalActivationClient';
    }
}
