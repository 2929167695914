/**
 *
 * @module tokenRequest
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import type { TokenData } from './typedefs';

/**
 *
 * @access public
 * @desc Information required by TokenClient to exchange a token.
 *
 */
export default class TokenRequest {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public grantType: string;

    /**
     *
     * @access public
     * @type {TokenData}
     *
     */
    public tokenData: TokenData;

    /**
     *
     * @access public
     * @type {Number}
     *
     */
    public latitude: number;

    /**
     *
     * @access public
     * @type {Number}
     *
     */
    public longitude: number;

    /**
     *
     * @param {Object} options
     * @param {String} options.grantType - The grant type of the token that will be exchanged.
     * @param {TokenData} options.tokenData - The parameters that will be added to the token.
     * @param {Number} options.latitude - The latitude of the device.
     * @param {Number} options.longitude - The longitude of the device.
     *
     */
    public constructor(options: {
        grantType: string;
        tokenData: TokenData;
        latitude: number;
        longitude: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    grantType: Types.nonEmptyString,
                    tokenData: Types.nonEmptyObject,
                    latitude: Types.number,
                    longitude: Types.number
                })
            };

            typecheck(this, params, arguments);
        }

        const { grantType, tokenData, latitude, longitude } = options;

        this.grantType = grantType;
        this.tokenData = tokenData;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Token.TokenRequest';
    }
}
