/**
 *
 * @module graphQlRequest
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.12.0
 *
 */
export default class GraphQlRequest {
    /**
     *
     * @access public
     * @since 4.12.0
     * @type {String}
     *
     */
    public query: string;

    /**
     *
     * @access public
     * @since 4.12.0
     * @type {Object}
     *
     */
    public variables: Record<string, unknown>;

    /**
     *
     * @param {Object} options
     * @param {String} options.query - A custom query that returns only the specified set of data.QueuingStrategySize
     * @param {Object} [options.variables={}] - The query variables object.
     *
     */
    public constructor(options: {
        query: string;
        variables?: Record<string, unknown>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.string,
                    variables: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { query, variables } = options;

        this.query = query;
        this.variables = variables || {};
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.GraphQl.GraphQlRequest';
    }
}
