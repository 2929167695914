/**
 *
 * @module messageProcessorBase
 *
 */

import { Check } from '@dss/type-checking';

import ErrorCode from '../../services/exception/errorCode';
import ErrorReason from '../../services/exception/errorReason';
import ServiceException from '../../services/exception/serviceException';
import { RawSocketMessage } from '../typedefs';

export type ErrorCodeDescription = {
    code: string | number;
    description: string;
};

/**
 *
 * @access private
 * @since 4.9.0
 * @desc Base class that handles determine if a message matches a specific messageType
 *
 */
export default abstract class MessageProcessorBase {
    /**
     *
     * @access private
     * @since 12.0.0
     * @type {Array<String>}
     *
     */
    protected messageTypes: Array<string>;

    /**
     *
     * @param {String|Array<String>} messageTypes - The `Services.Internal.Dust.DustUrnReference.socket.socketManager.*`
     * urn or array of urns defining the supported socket message type(s).
     *
     */
    public constructor(messageTypes: string | Array<string>) {
        this.messageTypes = Check.array(messageTypes)
            ? messageTypes
            : [messageTypes];
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} messageData - The `WebSocket.MessageEvent`'s deserialized `data` property to inspect for a `type` value and compare
     * @desc determines if the current message is what is expected for the inherited class
     *
     */
    public is(messageData: { type: string }) {
        const { type } = messageData || {};

        return this.messageTypes.includes(type);
    }

    /**
     *
     * @access public
     * @since 21.0.0
     *
     */
    public abstract process(
        messageData: RawSocketMessage
    ): Promise<void> | void;

    /**
     *
     * @access private
     * @since 4.11.0
     * @param {Array<Object>} [errors]
     * @desc Creates a service exception based on the `errorMessage`
     * @returns {SDK.Services.Exception.ServiceException}
     *
     */
    protected createServiceException(errors: Array<ErrorCodeDescription>) {
        if (
            Check.not.nonEmptyArray(errors) ||
            Check.not.nonEmptyObject(errors[0])
        ) {
            return new ServiceException();
        }

        // @ts-ignore needs updated dependency related to https://github.bamtech.co/fed-packages/dss-type-checking/pull/80
        const code = errors[0].code;

        const reasons = errors.map(
            (error: TodoAny = {}) =>
                new ErrorReason({
                    code: error.code,
                    description: error.description
                })
        );

        const errorCode = Object.values(ErrorCode).find(
            // @ts-ignore needs updated dependency related to https://github.bamtech.co/fed-packages/dss-type-checking/pull/80
            (error) => error.code === code
        );
        const exceptionData = errorCode && errorCode.exceptionData;

        return new ServiceException({
            reasons,
            exceptionData
        });
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public toString() {
        return 'SDK.Socket.Messages.MessageProcessorBase';
    }
}
