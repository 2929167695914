/**
 *
 * @module qoeEventVersionInfo
 *
 */

import DustUrnReference from '../services/internal/dust/dustUrnReference';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;

// https://helios.eds.us-east-1.bamgrid.net/events/playback/1.4.0
export const DataVersion = '1.4.0';

const eventDataVersionOverride = {
    // https://helios.eds.us-east-1.bamgrid.net/events/playback/1.26.0
    [QualityOfServiceDustUrnReference.playback]: '1.26.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/playbackStartup/1.30.0
    [QualityOfServiceDustUrnReference.playbackStartup]: '1.30.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/error/1.20.0
    [QualityOfServiceDustUrnReference.error]: '1.20.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/adPlayback/1.13.0
    [QualityOfServiceDustUrnReference.playbackAd]: '1.13.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/playbackHeartbeat/1.23.0
    [QualityOfServiceDustUrnReference.playbackHeartbeat]: '1.23.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/playbackSnapshot/1.3.0
    [QualityOfServiceDustUrnReference.playbackSnapshot]: '1.3.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/playbackInterstitial/1.1.0
    [QualityOfServiceDustUrnReference.interstitial]: '1.1.0',
    // https://helios.eds.us-east-1.bamgrid.net/events/playbackCustom/1.1.0
    [QualityOfServiceDustUrnReference.custom]: '1.1.0'
};

export function getDataVersion(urn: string) {
    return eventDataVersionOverride[urn] || DataVersion;
}
