/**
 *
 * @module environmentConfiguration
 *
 */

import BaseEnvironmentConfiguration from '../baseEnvironmentConfiguration';
import Device from '../device';

type EnvironmentConfigurationOption = Prettify<
    Omit<
        ConstructorParameters<typeof BaseEnvironmentConfiguration>[0],
        'device'
    > & {
        userAgent?: string;
    }
>;

/**
 *
 * @access protected
 * @desc Provides configuration information that describes the current environment
 * and is used by the `ConfigurationClient` to retrieve the `SdkSessionConfiguration`
 * necessary to create an `SdkSession` instance.
 *
 */
export default class EnvironmentConfiguration extends BaseEnvironmentConfiguration {
    /**
     *
     * @param {Object} options
     * @param {String} options.applicationRuntime
     * @param {String} options.applicationVersion
     * @param {String} options.deviceFamily
     * @param {String} options.deviceProfile
     * @param {String} options.deviceRuntimeProfile
     * @param {String} [options.configVersion]
     * @param {String} [options.userAgent] - optional override for device testing
     * @param {String} [options.platform]
     *
     */
    public constructor(options = {} as EnvironmentConfigurationOption) {
        const { userAgent } = options;

        let {
            deviceProfile,
            deviceRuntimeProfile,
            applicationRuntime,
            platform
        } = options;

        const device = new Device(userAgent);

        if (!deviceProfile || deviceProfile.trim() === '') {
            if (
                device.is.desktop ||
                device.is.tablet ||
                device.is.phone ||
                device.is.tesla
            ) {
                deviceProfile = device.details.name;
            } else if (device.is.smarttv || device.is.connecteddevice) {
                deviceProfile = device.details.family;
            } else {
                deviceProfile = 'fallbackDeviceProfile';
            }
        }

        if (!deviceRuntimeProfile || deviceRuntimeProfile.trim() === '') {
            deviceRuntimeProfile =
                device.platformVersion || 'fallbackRuntimeProfile';
        }

        if (!applicationRuntime || applicationRuntime.trim() === '') {
            if (
                device.is.desktop ||
                device.is.tablet ||
                device.is.phone ||
                device.is.tesla
            ) {
                applicationRuntime = device.browser;
            } else if (device.is.smarttv || device.is.connecteddevice) {
                applicationRuntime = device.details.name;
            } else {
                applicationRuntime = 'fallbackApplicationRuntime';
            }
        }

        if (!platform || platform.trim() === '') {
            platform = device.platform;
        }

        super({
            ...options,
            deviceProfile,
            deviceRuntimeProfile,
            applicationRuntime,
            platform,
            device
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Providers.Browser.EnvironmentConfiguration';
    }
}
