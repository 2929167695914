type OneIdGuestInformation = {
    registrationtype: string;
    loginstatus: string;
    swid: string;
    gender: string;
    birthage: string;
};

export type AccountDetails = {
    activeEspnAccount: any;
    entitlementLevel: string;
    subscriptionType: string;
    provider: string;
    isActive: boolean;
    upgradeEligible: boolean;
    bundleUser: boolean;
    execUser: boolean;
    isLoggedIn: boolean;
};

type Entitlements = {
    description: string;
    id: number;
    name: string;
    partner: string;
};

type Products = {
    categoryCodes?: (string | null)[] | null;
    id: number;
    name: string;
    sku: string;
    status: string;
    error?: null;
    entitlements?: Entitlements[] | null;
    partner: string;
    rule?: null;
};

type Status = {
    type: string;
};

type Source = {
    provider: string;
    ref: string;
    subType: string;
    type: string;
};

type Subscriptions = {
    expirationDate: string;
    expiryType: string;
    id: string;
    isActive: boolean;
    nextRenewalDate?: null;
    products?: Products[] | null;
    startDate: string;
    status: Status;
    provider: string;
    accountId: string;
    lastSyncDate: string;
    rule?: null;
    source: Source;
    type: string;
    bundleStatus: string;
    offer?: null;
    freeTrial?: null;
    cancellation?: null;
    earlyAccess: boolean;
};

export type UserTrackingInfo = {
    unid?: string;
    dssId?: string;
    oneIdGuestInformation?: Partial<OneIdGuestInformation>;
    entitlements?: string[];
    accountDetails?: AccountDetails;
};

export function isEmpty(obj): boolean {
    return (
        [Object, Array].includes((obj || {}).constructor) &&
        !Object.entries(obj || {}).length
    );
}

class UserService {
    static instance: UserService;
    private _accountDetails: AccountDetails = {
        activeEspnAccount: {},
        entitlementLevel: "entitlement-none",
        subscriptionType: "not subscribed",
        provider: "no entitlements",
        isActive: false,
        upgradeEligible: false,
        bundleUser: false,
        execUser: false,
        isLoggedIn: false,
    };
    private _oneIdGuestInformation: OneIdGuestInformation;
    private _subscriptions: Subscriptions[] = [];
    private _unid: string;
    private _entitlements: string[] = [];
    private _dssId: string;

    static getInstance() {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }

        return UserService.instance;
    }

    private letterCase(word: string): string {
        const result = word || "";

        return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    }

    private convertGender(gender: string): string {
        return (
            {
                1: "M",
                2: "F",
            }[gender] || "not set"
        );
    }

    public setOneIdGuestInformation(guest) {
        const profile = guest.profile || {};

        this.oneIdGuestInformation = {
            registrationtype: "espn",
            loginstatus: `Registered:Logged in ${this.letterCase(
                profile.status
            )}`,
            swid: profile.swid,
            gender: this.convertGender(profile.gender),
            birthage: (profile.dateOfBirth || "").slice(0, 4),
        };
    }

    set oneIdGuestInformation(oneIdGuestInfo: OneIdGuestInformation) {
        this._oneIdGuestInformation = oneIdGuestInfo;
    }

    get oneIdGuestInformation(): OneIdGuestInformation {
        return this._oneIdGuestInformation;
    }

    set subscriptions(subscriptions) {
        this._subscriptions = subscriptions;
        this.setAccoutDetails(subscriptions);
        this.setEntitlements(subscriptions);
    }

    get subscriptions() {
        return this._subscriptions;
    }

    set unid(data: string) {
        this._unid = data;
    }

    get unid(): string {
        return this._unid;
    }

    private setAccoutDetails(subscriptions: Subscriptions[]) {
        const activeSubscription = subscriptions.filter((sub) => {
            return (
                sub.isActive &&
                sub.products.some((prod) => {
                    return (
                        prod.categoryCodes.includes("ESPN_submgmt_products") ||
                        prod.categoryCodes.includes("espn_submgmt_products") ||
                        prod.entitlements.some(function (entitlement) {
                            return entitlement.name === "ESPN_EXEC";
                        })
                    );
                })
            );
        });
        const activeEspnAccount = activeSubscription[0];
        this.accountDetails.isLoggedIn = true;

        if (isEmpty(activeEspnAccount)) return;

        const product = activeEspnAccount.products[0];
        const isMonthly = product.categoryCodes.includes("MONTHLY");
        const isYearly = product.categoryCodes.includes("YEARLY");
        const upgradeEligible =
            product.categoryCodes.includes("espn_switch_from");
        const bundleUser = activeEspnAccount.bundleStatus === "bundle";
        const execUser = product.entitlements.some((entitlement) => {
            return entitlement.name === "ESPN_EXEC";
        });

        this.accountDetails = {
            ...this.accountDetails,
            activeEspnAccount,
            provider: activeEspnAccount.source.provider.toLowerCase(),
            subscriptionType: "active",
            isActive: true,
            upgradeEligible,
            bundleUser,
            execUser,
        };

        if (isYearly || execUser) {
            this.accountDetails.entitlementLevel = "entitlement-annual";

            return;
        }

        if (bundleUser) {
            this.accountDetails.entitlementLevel = "entitlement-bundle";

            return;
        }

        if (isMonthly) {
            this.accountDetails.entitlementLevel = "entitlement-monthly";

            return;
        }

        this.accountDetails.entitlementLevel = "entitlement-unknown";
    }

    set accountDetails(accountDetails: AccountDetails) {
        this._accountDetails = accountDetails;
    }

    get accountDetails(): AccountDetails {
        return this._accountDetails;
    }

    private setEntitlements(subscriptions: Subscriptions[]) {
        this.entitlements = subscriptions.reduce((a, b) => {
            if (b.isActive) {
                b.products.forEach((product) => {
                    product.entitlements.forEach((entitlement) => {
                        if (!a.includes(entitlement.name)) {
                            a.push(entitlement.name);
                        }
                    });
                });
            }

            return a;
        }, []);
    }

    set entitlements(entitlements: string[]) {
        this._entitlements = entitlements;
    }

    get entitlements(): string[] {
        return this._entitlements;
    }

    set dssId(dssId: string) {
        this._dssId = dssId;
    }

    get dssId(): string {
        return this._dssId;
    }

    get userTrackingInfo(): UserTrackingInfo {
        return {
            unid: this.unid,
            dssId: this.dssId,
            oneIdGuestInformation: this.oneIdGuestInformation,
            entitlements: this.entitlements,
            accountDetails: this.accountDetails,
        };
    }
}

export default UserService;
