/**
 *
 * @module ageVerificationFlowType
 *
 */

/**
 *
 * @since 12.0.0
 * @typedef {Object} SDK.AgeVerificationFlowType
 * @desc Determines the flow type of an age verification changed event.
 * @note Should be implemented as Enum type 1: Traditional enum.
 *
 * @property {String} redeem - Since `12.0.0`.
 * @property {String} request - Since `12.0.0`.
 *
 */
enum AgeVerificationFlowType {
    redeem = 'redeem',
    request = 'request'
}
export default AgeVerificationFlowType;
