/**
 *
 * @module customerServicePlugin
 * @desc {SDK.CustomerService.CustomerServicePlugin} entry point.
 *
 */

import CustomerServiceManager from './customerServiceManager';

import CustomerServiceService from '../services/customerService/entry';
import CustomerServiceClient from '../services/customerService/customerServiceClient';
import CustomerServiceClientConfiguration from '../services/customerService/customerServiceClientConfiguration';
import CustomerServiceManagerConfiguration from '../services/configuration/customerServiceManagerConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'CustomerService', void, typeof CustomerServiceService> =
    {
        pluginName: 'CustomerService',
        service: CustomerServiceService,

        /**
         *
         * @access private
         * @since 12.0.0
         * @param {Object} options
         *
         */
        createManager(options) {
            const { managers, logger, httpClient, services } = options;

            managers.customerServiceManager = new CustomerServiceManager({
                config: services.customerService,
                customerServiceClient: new CustomerServiceClient({
                    config: services.customerService.client,
                    logger,
                    httpClient
                }),
                logger
            });
        },

        /**
         *
         * @access protected
         * @since 13.0.0
         * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
         * @param {Object} services - JSON services config object returned from configuration service.
         *
         */
        applyConfig(serviceDefinitionsConfiguration, services) {
            const clientConfiguration = new CustomerServiceClientConfiguration({
                baseUrl: services?.customerService?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.customerService?.client?.endpoints
                )
            });

            serviceDefinitionsConfiguration.customerService =
                new CustomerServiceManagerConfiguration({
                    client: clientConfiguration,
                    disabled: services.customerService.disabled
                });
        }
    };

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
