/**
 *
 * @module playerAdapterUtils
 *
 */
/* eslint-disable import/prefer-default-export */

import { AudioRole } from '../../services/media/enums';

import {
    AudioRendition,
    SubtitleRendition
} from '../../services/media/typedefs';

import {
    AudioTrackType,
    TimedTextTrackType
} from '../../services/qualityOfService/enums';

/**
 *
 * @access protected
 * @since 19.0.0
 * @param {Object} variant
 * @desc Takes a variant and combines the `width` and `height` to create a reporting string `{width}x{height}`
 * @returns {String|null|undefined}
 *
 */
export function getResolutionString(
    variant?: Nullable<{
        height?: Nullable<number>;
        width?: Nullable<number>;
    }>
) {
    const height = variant?.height?.toString();
    const width = variant?.width?.toString();

    if (height && width) {
        return `${width}x${height}`;
    }

    return null;
}

/**
 *
 * @access protected
 * @since 25.0.0
 * @param {AudioRendition} [audio]
 * @param {SubtitleRendition} [subtitle]
 * @desc Determines the appropriate `playlistAudioTrackType` and `playlistTimedTextTrackType` based on the AudioRole and
 * SubtitleRole inputs.
 * @returns {Object}
 *
 */
export function getTrackTypes(options: {
    audio?: AudioRendition;
    subtitle?: SubtitleRendition;
}) {
    const { audio = {}, subtitle = {} } = options;

    const playlistTimedTextTrackType =
        TimedTextTrackType[
            subtitle.role?.toLowerCase() as TimedTextTrackType
        ] || TimedTextTrackType.unknown;

    const playlistAudioTrackType =
        audio.role === AudioRole.NARRATION
            ? AudioTrackType.descriptive
            : AudioTrackType[audio.role?.toLowerCase() as AudioTrackType] ||
              AudioTrackType.unknown;

    return {
        playlistAudioTrackType,
        playlistTimedTextTrackType
    };
}
