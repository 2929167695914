/**
 *
 * @module contentApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/ContentApi.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import SearchOverrides from './searchOverrides';
import ContentQuery from './contentQuery';
import SearchQuery from './search/searchQuery';
import GraphQlQuery from './graphQlQuery';

import BaseApi from '../baseApi';
import ContentClient from '../services/content/contentClient';

import DustDecorators from '../services/internal/dust/dustDecorators';
import DustUrnReference from '../services/internal/dust/dustUrnReference';

import { ApiOptions } from '../typedefs';

import type Logger from '../logging/logger';
import type AccessTokenProvider from '../token/accessTokenProvider';

const DustUrn = DustUrnReference.content.contentApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides a way to access and discover content.
 *
 */
export default class ContentApi extends BaseApi {
    /**
     *
     * @access private
     * @type {ContentClient}
     * @since 29.0.0
     *
     */
    private contentClient: ContentClient;

    /**
     *
     * @access protected
     * @param options
     * @param {ContentClient} options.contentClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        contentClient: ContentClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    contentClient: Types.instanceStrict(ContentClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { contentClient } = options;

        this.contentClient = contentClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Content.ContentQuery} options.query - The GraphQL query object that defines the query criteria.
     * @param {SDK.Content.SearchOverrides} [options.overrides] - Object which contains override information on the time
     * of origin and or the country of origin to apply to the query request.
     * @param {String} [options.contentTransactionId] - An application developer provided value to be included with the
     * HTTP request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the content services.
     * @throws {InvalidRequestException} The content query request was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the operation has
     * succeeded and returns the JSON object or string to be interpreted by the application.
     *
     */
    public async query<T>(options: {
        query: ContentQuery;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
    }): Promise<T>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object({
                query: Types.instanceStrict(ContentQuery),
                overrides: Types.instanceStrict(SearchOverrides).optional,
                contentTransactionId: Types.nonEmptyString.optional
            })
        }
    })
    public async query<T>(apiOptions: unknown) {
        const {
            logTransaction,
            dustLogUtility,
            args: [options]
        } = apiOptions as ApiOptions<
            [
                {
                    query: ContentQuery;
                    overrides?: SearchOverrides;
                    contentTransactionId?: string;
                }
            ]
        >;

        const { query, contentTransactionId, overrides } = options;

        dustLogUtility?.logData({
            context: query.context,
            contentTransactionId
        });

        return (await this.contentClient.query({
            context: query.context,
            queryBuilder: query.query,
            accessToken: super.accessToken,
            overrides,
            contentTransactionId,
            logTransaction
        })) as T; // TODO make this T flow through...
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {SDK.Content.GraphQlQuery} query - Query to be used to retrieve content.
     * @param {SDK.Content.SearchOverrides} [overrides] - Object that will override the default date and or location of the search.
     * @param {String} [contentTransactionId] - An application developer-provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @desc Queries the search service and returns a set of search items.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} The GraphQL JSON returned from the search service.
     *
     */
    public async search(
        query: GraphQlQuery,
        overrides?: SearchOverrides,
        contentTransactionId?: string
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.instanceStrict(GraphQlQuery),
            overrides: Types.instanceStrict(SearchOverrides).optional,
            contentTransactionId: Types.nonEmptyString.optional
        }
    })
    public async search(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query, overrides, contentTransactionId]
        } = apiOptions as ApiOptions<[GraphQlQuery, SearchOverrides?, string?]>;

        const searchQuery = new SearchQuery(query);

        return this.contentClient.query({
            context: searchQuery.context,
            queryBuilder: searchQuery.query,
            accessToken: super.accessToken,
            overrides,
            contentTransactionId,
            logTransaction
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.ContentApi';
    }
}
