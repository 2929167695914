/**
 *
 * @module adEngineClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Media.AdEngine.AdEngineClientEndpoint
 *
 * @property {String} setTokenPost - Since `11.0.0`.
 * @property {String} setTokenRedirectPost - Since `11.0.0`.
 *
 */
enum AdEngineClientEndpoint {
    setTokenPost = 'setTokenPost',
    setTokenRedirectPost = 'setTokenRedirectPost'
}
export default AdEngineClientEndpoint;
