/**
 *
 * @module ripcutClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @since 17.0.0
 * @typedef {Object} SDK.Services.Ripcut.RipcutClientEndpoint
 *
 * @property {String} main - Since `17.0.0`
 * @property {String} mainScale - Since `17.0.0`
 * @property {String} mainCompose - Since `17.0.0`
 * @property {String} mainBadging - Since `17.0.0`
 * @property {String} rawFiles - Since `22.0.0`
 * @property {String} trim - Since `27.0.0`
 *
 */
enum RipcutClientEndpoint {
    main = 'main',
    mainScale = 'mainScale',
    mainCompose = 'mainCompose',
    mainBadging = 'mainBadging',
    rawFiles = 'rawFiles',
    trim = 'trim'
}

export default RipcutClientEndpoint;
