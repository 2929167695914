/**
 *
 * @module iDealApi
 * @see http://default.wallet-service.mercury.bamtech.qa.us-east-1.bamgrid.net/docs#tag/iDEAL-Payments
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { IDealPaymentRequest, IDealPaymentRequestTypedef } from './typedefs';
import { PaymentRedirectResponse } from '../../services/commerce/typedefs';

import CommerceClient from '../../services/commerce/commerceClient';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';

import { ApiOptions } from '../../typedefs';

import type Logger from '../../logging/logger';
import type AccessTokenProvider from '../../token/accessTokenProvider';

const DustUrn = DustUrnReference.commerce.iDealApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.11.0
 * @desc Provides ability to access iDeal data.
 *
 */
export default class IDealApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {CommerceClient}
     *
     */
    private commerceClient: CommerceClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {CommerceClient} options.commerceClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commerceClient: CommerceClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceClient: Types.instanceStrict(CommerceClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceClient } = options;

        this.commerceClient = commerceClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object<SDK.Commerce.IDeal.IDealPaymentRequest>} request - request Information used to submit an IDeal payment.
     * @desc Submit a payment request through the IDeal payment service. Returns a paymentMethodId and redirectUrl used to complete the payment process.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.PaymentRedirectResponse>}
     *
     */
    public async submitPayment(
        request: IDealPaymentRequest
    ): Promise<PaymentRedirectResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(IDealPaymentRequestTypedef)
        }
    })
    public async submitPayment(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[IDealPaymentRequest]>;

        return this.commerceClient.submitIDealPayment(
            request,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.IDeal.IDealApi';
    }
}
