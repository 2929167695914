/**
 *
 * @module qrCodeClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';
import QRCodeClientEndpoint from './qrCodeClientEndpoint';

/**
 *
 * @access protected
 * @since 26.0.0
 * @desc Provides configuration information used by the QRCodeClient.
 *
 */
export default class QRCodeClientConfiguration extends ServiceClientConfiguration<
    typeof QRCodeClientEndpoint
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.QrCode.QrCodeClientConfiguration';
    }
}
