/**
 *
 * @module dssWebPlayerAdapter
 * @desc The SDK.Media.PlayerAdapter instance for the Disney Streaming web player.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/pages/btm-browser-media-platform/documentation/jsdoc/btm-browser-media-platform-common/MediaPlatform.html
 * @see https://github.bamtech.co/pages/btm-browser-media-platform/documentation/jsdoc/btm-browser-media-platform-common/MediaPlatform.Common.Constants.Events.html
 * @see https://github.bamtech.co/btm-browser-media-platform
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import PublicEvents from '../../events';

import { getResolutionString, getTrackTypes } from './playerAdapterUtils';

import PlaybackEventListener from '../playbackEventListener';
import PlaybackMetrics from '../playbackMetrics';
import PlayerAdapter, { HlsPlaylist } from '../playerAdapter';
import Playlist from '../playlist';

import PlaybackVariant from '../../services/media/playbackVariant';

import { DrmType, InsertionPointPlacement } from '../../services/media/enums';

import DrmProvider from '../../drm/drmProvider';

import DustUrnReference from '../../services/internal/dust/dustUrnReference';

import {
    ApplicationContext,
    PodPosition,
    PresentationType,
    ErrorLevel,
    HttpMethod,
    MediaSegmentType,
    NetworkType,
    PlaybackExitedCause,
    StartupActivity,
    QoePlaybackError,
    PlaybackMode,
    PlaybackState,
    MediaSegmentTypeMap,
    InterstitialEndedCause,
    InterstitialType,
    InterstitialPlacement,
    PlaybackSeekCause,
    PlayerSeekDirection
} from '../../services/qualityOfService/enums';

import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import ServerRequest from '../../services/qualityOfService/serverRequest';
import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';
import getFetchStatus from '../../services/qualityOfService/getFetchStatus';
import { DEFAULT_ZERO_GUID } from '../../constants';

import {
    AdMetadata,
    BitrateChangedEvent,
    DSSHLSError,
    VariantStream
} from '../typedefs';

import {
    createSimpleException,
    createInvalidStateException
} from '../../services/util/errorHandling/createException';

import { AdSlotData } from '../../services/qualityOfService/typedefs';
import EventListenerProvider from '../eventListenerProvider';

const defaultAdMetadata = {
    adSlotData: {
        adMediaId: DEFAULT_ZERO_GUID,
        slotNumber: 0,
        plannedLength: 0
    },
    adPodPlacement: {
        podPosition: PodPosition.preroll
    },
    adPodData: {
        plannedSlotCount: 0,
        plannedLength: 0
    },
    adPlayheadPosition: 0,
    mediaId: DEFAULT_ZERO_GUID,
    adSubtitleData: {
        subtitleVisibility: false
    }
};

type AssetData = {
    mediaSegmentType: string;
    channels: string;
    codec: string;
    language: string;
    name: string;
    bitrate: number;
    range: string;
    averageBitrate: number;
    resolution: string;
    frameRate: number;
};

type InterstitialMetadata = {
    placement: string;
    midRollIndex?: number;
    totalDuration?: number;
    slotCount: number;
    slotNumber: number;
    adDuration: number;
    adId?: string;
};

type AdResponse = { pods: Array<{ ads: Array<AdSlotData> }> };

/**
 *
 * @since 3.9.0
 * @desc Interface used to communicate with the Disney Streaming web player.
 * @note Disney Streaming web player.
 *
 */
export default class DssWebPlayerAdapter extends PlayerAdapter<DssWebNativePlayer> {
    /**
     *
     * @access public
     * @type {Array<SDK.Drm.DrmProvider>}
     * @desc Set of DRM providers.
     *
     */
    public drmProviders: Array<DrmProvider>;

    /**
     *
     * @access private
     * @type {Object|null}
     *
     */
    private trackingData: Nullable<Record<string, unknown>>;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
     * @desc Keeps track of CDN fallback for QoE support.
     *
     */
    private playbackStartupEventData: PlaybackStartupEventData;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Date}
     * @desc Time when `rebufferingStartedEvent` is triggered. Used to calculate duration for `rebufferingEndedEvent`.
     *
     */
    private rebufferingDuration: Nullable<Date>;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Object}
     * @desc Keeps track of CDN fallback qos tracking information.
     *
     */
    private qos: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 20.0.2
     * @type {Object}
     * @desc Cached values use for the heartbeat event that are updated
     * when specific events fire.
     *
     */
    private heartbeatData: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 21.0.0
     * @type {Object|null}
     * @desc The most recent multivariant playlist request
     *
     */
    private currentMultivariantPlaylistRequest: Nullable<{
        playlist: HlsPlaylist;
        assetType: PresentationType;
        url?: string;
    }>;

    /**
     *
     * @access private
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.PresentationType}
     * @desc Used to store the last known presentation type.
     *
     */
    private presentationType: PresentationType;

    /**
     *
     * @access private
     * @since 22.0.0
     * @type {Number|null}
     * @desc the starting bitrate for the media content.
     *
     */
    private mediaStartBitrate: Nullable<number>;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to create the non-UI player stack.
     * @note Conditionally required if `startupActivity` = `initialized`.
     *
     */
    private initializePlayerDuration?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the creation of the non-UI player stack begins.
     * @note Conditionally required if `startupActivity` = `initialized`.
     *
     */
    private initializePlayerStartTime?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the multivariant playlist fetch begins.
     *
     */
    private multivariantFetchedStartTime?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number}
     * @desc The client epoch timestamp in milliseconds when the platform-appropriate playback measurement begins.
     *
     */
    private readyPlayerStartTime: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number}
     * @desc The client epoch timestamp in milliseconds when the DRM license request begins.
     *
     */
    public fetchLicenseStartTime: number;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Number}
     *
     */
    private interstitialSessionDuration: number;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {String}
     *
     */
    private interstitialSessionId: string;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Boolean}
     *
     */
    private isAdSkipped: boolean;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer - An instance of the BAMTECH web player.
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} [options.initialPlaybackMode]
     *
     */
    public constructor(options: {
        nativePlayer: DssWebNativePlayer;
        videoPlayerName: string;
        videoPlayerVersion: string;
        initialPlaybackMode?: PlaybackMode;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    initialPlaybackMode: Types.in(PlaybackMode).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { initialPlaybackMode } = options;

        this.drmProviders = [];
        this.trackingData = null;
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });
        this.rebufferingDuration = null;
        this.qos = {};
        this.heartbeatData = {};
        this.currentMultivariantPlaylistRequest = null;
        this.presentationType = PresentationType.main;
        this.mediaStartBitrate = null;
        this.readyPlayerStartTime = 0;
        this.fetchLicenseStartTime = 0;
        this.initialPlaybackMode = initialPlaybackMode;
        this.isAdSkipped = false;
        this.offHandlerRemovalList = [];
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Sets the source URI on the NativePlayer instance,
     * callback used when prepare has been called (usually via the {@link PlaybackSession}).
     * @note ensure a proper accessToken is passed on xhr requests for the NativePlayer DRM
     *
     */
    public override async setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        if (
            this.nativePlayer?.isStartFailureSaveSupported() &&
            this.cdnFallback.isEnabled
        ) {
            this.playlistUri = playlist.mediaSources;
        }

        this.nativePlayer?.drm.setXhrConfigProvider(() => ({
            headers: {
                key: {
                    Authorization: this.accessToken
                }
            }
        }));
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        super.clean();

        this.resetSource();
        this.resetDrmProviders();

        this.drmProvider = null;
        this.mediaStartBitrate = null;
        this.qos = {};
        this.heartbeatData = {};
        this.currentMultivariantPlaylistRequest = null;
        this.presentationType = PresentationType.main;
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        this.removeListener(listener);
    }

    /**
     *
     * @access public
     * @param {Object} trackingData - The tracking data for the current `playlistUri`
     * @desc Sets tracking data
     *
     */
    public setTrackingData(trackingData: Record<string, unknown>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingData: Types.nonEmptyObject
            };

            typecheck(this, 'setTrackingData', params, arguments);
        }

        this.trackingData = trackingData;
    }

    /**
     *
     * @access public
     * @since 4.16.2
     * @param {Object} eventData
     * @desc Trigger when playback has been exited.
     * @note avoids NaN
     *
     */
    public async playbackEndedEvent(eventData: {
        cdnRequestedTrail?: Array<string>;
        cdnFailedTrail?: Array<string>;
        cdnFallbackCount?: number;
        isCdnFallback?: boolean;
        errorName: string;
    }) {
        if (Check.assigned(this.listener)) {
            const { playbackStartupEventData } = this;

            const playbackEndedEvent = eventData || ({} as typeof eventData);

            playbackEndedEvent.cdnRequestedTrail =
                playbackStartupEventData.cdnRequestedTrail;
            playbackEndedEvent.cdnFailedTrail =
                playbackStartupEventData.cdnFailedTrail;
            playbackEndedEvent.cdnFallbackCount =
                playbackStartupEventData.cdnFallbackCount;
            playbackEndedEvent.isCdnFallback =
                playbackStartupEventData.isCdnFallback;

            if (
                [PresentationType.ad, PresentationType.bumper].includes(
                    this.currentMultivariantPlaylistRequest?.assetType
                )
            ) {
                this.adPlaybackEnded({
                    ...playbackEndedEvent,
                    asset: {
                        mediaId: this.getAdMediaId()
                    }
                });
            }

            await this.onPlaybackEnded(playbackEndedEvent);
        }
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all {@link PlayerAdapter} references.
     * This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the time when the player has started to be initialized.
     *
     */
    public override setInitializePlayerStartTime() {
        this.initializePlayerStartTime = Date.now();
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the duration for how long the player took to be initialized. `setInitializePlayerStartTime` must be
     * called before this method.
     *
     */
    public override setInitializePlayerDuration() {
        if (this.initializePlayerStartTime) {
            this.initializePlayerDuration =
                Date.now() - this.initializePlayerStartTime;
        } else {
            this.logger.warn(
                this.toString(),
                'setInitializePlayerStartTime is required to be called before duration can be set.'
            );
        }
    }

    /**
     *
     * @access protected
     * @param {PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer } = this;
        const { ErrorEvents, PlayerEvents, InterstitialEvents, UIEvents } = (
            nativePlayer as DssWebNativePlayer
        ).events;

        const { MEDIA_ERROR } = ErrorEvents;

        const {
            MEDIA_PAUSED,
            MEDIA_RESUMED,
            MEDIA_STARTED,
            INITIALIZED,
            READY,
            MEDIA_SEEK_COMPLETE,
            MEDIA_SEEKING,
            BUFFERING_STARTED,
            BUFFERING_ENDED,
            MULTIVARIANT_PLAYLIST_LOADED,
            VARIANT_LOADED,
            DRM_LICENSE_RECEIVED,
            MULTIVARIANT_PLAYLIST_REQUEST,
            MULTIVARIANT_PLAYLIST_FALLBACK,
            CHUNK_LOADED,
            DRM_LICENSE_REQUESTED
        } = PlayerEvents.PLAYBACK;

        const { PLAYBACK_MODE_CHANGED } = UIEvents.MODE;

        const {
            SESSION_REQUESTED: INTERSTITIAL_SESSION_REQUESTED,
            SESSION_FETCHED: INTERSTITIAL_SESSION_FETCHED,
            SESSION_STARTED: INTERSTITIAL_SESSION_STARTED,
            SESSION_FINISHED: INTERSTITIAL_SESSION_FINISHED,
            MULTIVARIANT_FETCHED: INTERSTITIAL_MULTIVARIANT_FETCHED,
            VARIANT_FETCHED: INTERSTITIAL_VARIANT_FETCHED,
            ASSET_STARTED: INTERSTITIAL_ASSET_STARTED,
            ASSET_FINISHED: INTERSTITIAL_ASSET_FINISHED,
            INTERSTITIAL_SKIPPED,
            SESSION_REQUESTED_ERROR: INTERSTITIAL_SESSION_REQUESTED_ERROR,
            BEACON_ERROR
        } = InterstitialEvents;

        const { ACTIVE_PROFILE_CHANGED } = PlayerEvents.QUALITY;
        const { SELECTED_TRACK_CHANGED: SUBTITLE_SELECTED_TRACK_CHANGED } =
            PlayerEvents.CAPTIONS;
        const { SELECTED_TRACK_CHANGED: AUDIO_SELECTED_TRACK_CHANGED } =
            PlayerEvents.AUDIO;

        if (nativePlayer && nativePlayer.on) {
            this.listener = listener;
            this.eventListenerProvider = new EventListenerProvider({
                onHandler: nativePlayer.on,
                offHandler: nativePlayer.off,
                logger: this.logger
            });

            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_STARTED,
                this.playbackStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_RESUMED,
                this.playbackResumedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_PAUSED,
                this.playbackPausedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INITIALIZED,
                this.playbackInitializedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                PLAYBACK_MODE_CHANGED,
                this.setPlaybackMode
            );
            this.eventListenerProvider.addEventHandler(
                this,
                READY,
                this.playbackReadyEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_SEEKING,
                this.playbackSeekStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_SEEK_COMPLETE,
                this.playbackSeekEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                ACTIVE_PROFILE_CHANGED,
                this.bitrateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                BUFFERING_STARTED,
                this.rebufferingStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                BUFFERING_ENDED,
                this.rebufferingEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SUBTITLE_SELECTED_TRACK_CHANGED,
                this.subtitleChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                AUDIO_SELECTED_TRACK_CHANGED,
                this.audioChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MULTIVARIANT_PLAYLIST_LOADED,
                this.multivariantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MULTIVARIANT_PLAYLIST_LOADED,
                this.successfulPlaylistLoad
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MULTIVARIANT_PLAYLIST_REQUEST,
                this.multivariantPlaylistRequest
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MULTIVARIANT_PLAYLIST_FALLBACK,
                this.multivariantPlaylistFallback
            );
            this.eventListenerProvider.addEventHandler(
                this,
                VARIANT_LOADED,
                this.variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                DRM_LICENSE_RECEIVED,
                this.drmKeyFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MEDIA_ERROR,
                this.playbackErrorEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                BEACON_ERROR,
                this.adBeaconError
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SESSION_REQUESTED,
                this.adPodRequested
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SESSION_FETCHED,
                this.adPodFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SESSION_REQUESTED_ERROR,
                this.adRequestedError
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_MULTIVARIANT_FETCHED,
                this.adMultivariantFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_VARIANT_FETCHED,
                this.adVariantFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_ASSET_STARTED,
                this.adPlaybackStarted
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_ASSET_FINISHED,
                this.adPlaybackEnded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SESSION_STARTED,
                this.adPodStarted
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SESSION_FINISHED,
                this.adPodEnded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                CHUNK_LOADED,
                this.videoSegmentLoaded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                DRM_LICENSE_REQUESTED,
                this.drmKeyRequestedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                INTERSTITIAL_SKIPPED,
                this.adPlaybackSkipped
            );
        } else {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access protected
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders - The array DRM providers from the playlist service
     * @desc Sets an array of DrmProviders
     * @returns {Promise<Void>} A promise that when resolved indicates the DRM providers have been set
     *
     */
    public async setDrmProviders(drmProviders: Array<DrmProvider>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        this.drmProviders = drmProviders;
        (this.nativePlayer as DssWebNativePlayer).drm.configuration =
            this.getDrmConfiguration(drmProviders);
    }

    /**
     *
     * @access protected
     * @desc Gets a snapshot of information about media playback.
     * @throws {ServiceException}
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by `PlaybackTelemetryDispatcher.recordStreamSample`
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        const {
            currentTimeAsPdt,
            isLive,
            quality,
            currentTime,
            seekableRange = {} as NonNullable<
                DssWebNativePlayer['seekableRange']
            >,
            metrics = {} as NonNullable<DssWebNativePlayer['metrics']>
        } = this.nativePlayer || ({} as DssWebNativePlayer);

        const { playerMetrics = {} as PlayerMetrics } = metrics;
        const { throughput, bufferedRange = {} as Playback['bufferedRange'] } =
            playerMetrics.playback || {};
        const { currentKbps, averageBitrate, peakBitrate } = quality || {};

        const bufferSegmentDuration = bufferedRange.end - currentTime;
        const currentInterstitial = this.getCurrentInterstitial();

        if (Check.nonEmptyObject(currentInterstitial)) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;

            if (currentInterstitial.currentAsset) {
                const adMediaId = currentInterstitial.currentAsset.mediaId;

                if (adMediaId) {
                    this.adData.adMetadata.adSlotData =
                        this.adData.data[adMediaId]?.adSlotData;
                }
            }
        }

        let playheadProgramDateTime = null;
        let seekableRangeEndProgramDateTime = null;
        let isLiveEdge = null;

        if (isLive) {
            playheadProgramDateTime = currentTimeAsPdt;
            seekableRangeEndProgramDateTime = seekableRange.pdtEnd;
            isLiveEdge = this.nativePlayer?.isLiveEdge;
        }

        return new PlaybackMetrics({
            adMetadata: this.adData.adMetadata,
            currentBitrate: currentKbps as number,
            currentPlayhead: currentTime,
            currentBitrateAvg: averageBitrate as number,
            currentBitratePeak: peakBitrate as number,
            currentThroughput: throughput,
            playheadProgramDateTime,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            bufferSegmentDuration,
            maxAllowedVideoBitrate: this.getMaxAllowedVideoBitrate()
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    public override getCdnName() {
        const cdnName = this.qos.cdnName;

        return Check.string(cdnName) ? cdnName : 'null';
    }

    /**
     *
     * @access protected
     * @since 15.1.0
     * @returns {Number}
     *
     */
    public override getMaxAllowedVideoBitrate() {
        const variants =
            (this.nativePlayer?.quality.profiles as Array<VariantStream>) ?? [];
        const maxVariant = variants[variants.length - 1] || ({} as Variant);

        return maxVariant.peakBitrate || 0;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Object}
     *
     */
    public override getHeartbeatData() {
        return {
            ...this.nativePlayer?.heartbeat,
            ...this.heartbeatData
        };
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {Object} adEngineData
     * @desc overwrites local adEngine object in playbackSession.prepare()
     * @note used in xhrCallbacks for bam-hls and other platforms that need to reuse adEngine data
     *
     */
    public override setAdEngineData(adEngineData: Record<string, unknown>) {
        super.setAdEngineData(adEngineData);

        const { ssess } = adEngineData;

        this.nativePlayer?.drm.setXhrConfigProvider(() => ({
            headers: {
                adEngine: { ssess }
            }
        }));
    }

    /**
     *
     * @access protected
     * @since 27.1.0
     * @desc returns playback state enum.
     * @note nativePlayer?.playbackState will always be populated on the DssWeb player.
     * @returns {PlaybackState|undefined}
     *
     */
    public override getPlaybackState() {
        let currentState;

        const playbackStates =
            (this.nativePlayer?.playbackState as Record<string, boolean>) || {};

        const playbackStateMap = {
            notready: PlaybackState.notready,
            paused: PlaybackState.paused,
            buffering: PlaybackState.buffering,
            playing: PlaybackState.playing,
            seeking: PlaybackState.seeking,
            ended: PlaybackState.finished
        };

        const playbackState = Object.keys(playbackStateMap).find(
            (key) => playbackStates[key]
        );

        if (playbackState) {
            currentState = playbackStateMap[playbackState];
        }

        return currentState;
    }

    /**
     *
     * @access private
     * @desc Resets the `playlistUri`.
     *
     */
    private resetSource() {
        this.playlistUri = '';
    }

    /**
     *
     * @access private
     * @desc Resets the DRM providers
     *
     */
    private resetDrmProviders() {
        this.drmProviders = [];
        (this.nativePlayer as DssWebNativePlayer).drm.configuration = null;
    }

    /**
     *
     * @access private
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @desc Builds and returns a KeySystem object from a given drmProvider
     * @returns {Object}
     *
     */
    private getKeySystem(
        drmProvider: DrmProvider & {
            getFairPlayCertificate?: Noop<undefined>;
            getWidevineCertificate?: Noop<undefined>;
        }
    ) {
        const keySystem = drmProvider.type;
        const licenseRequestUri = drmProvider.licenseRequestUri;
        const licenseRequestHeaders =
            drmProvider.processLicenseRequestHeaders();

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                urn: DustUrnReference.services.drm.drmClient
                    .getFairPlayCertificate,
                getCertificate: () => drmProvider.getFairPlayCertificate?.()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                urn: DustUrnReference.services.drm.drmClient
                    .getWidevineCertificate,
                getCertificate: () => drmProvider.getWidevineCertificate?.()
            }
        };

        const providerImplementation =
            providerImplementations[
                keySystem as keyof typeof providerImplementations
            ];

        const keySystemObj = {
            keySystem,
            licenseRequestUri,
            licenseRequestHeaders: Object.keys(licenseRequestHeaders).map(
                (item) => ({
                    name: item,
                    value: licenseRequestHeaders[item]
                })
            ),
            /**
             *
             * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
             * where serverCertificate is a reference to this function, in EME.js.
             * We need to use an IIFE here so we setup a promise since they are not executing this function
             * only expecting a Promise(cert), Promise(undefined), cert or undefined
             *
             */
            serverCertificate: Promise.resolve(undefined)
        };

        if (providerImplementation) {
            const { getCertificate } = providerImplementation;

            keySystemObj.serverCertificate = (async () => {
                return getCertificate();
            })();
        }

        return keySystemObj;
    }

    /**
     *
     * @access private
     * @param {SDK.Drm.DrmProvider} drmProviders - The array DRM providers from the playlist service
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {{ Array }} The DRM configuration object wrapping an array of keySystems
     *
     */
    private getDrmConfiguration(drmProviders: Array<DrmProvider>) {
        const keySystems = drmProviders.map((item: DrmProvider) =>
            this.getKeySystem(item)
        );

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when audio stream changes.
     *
     */
    private audioChangedEvent(eventData: {
        audioChannels: string;
        audioCodec: string;
        audioLanguage: string;
        audioName: string;
    }) {
        const { audioChannels, audioCodec, audioLanguage, audioName } =
            eventData || {};

        const parsedChannels = audioChannels
            ? parseInt(audioChannels, 10)
            : undefined;

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistAudioChannels =
            parsedChannels ?? this.heartbeatData.playlistAudioChannels;
        this.heartbeatData.playlistAudioCodec =
            audioCodec ?? this.heartbeatData.playlistAudioCodec;
        this.heartbeatData.playlistAudioLanguage =
            audioLanguage ?? this.heartbeatData.playlistAudioLanguage;
        this.heartbeatData.playlistAudioName =
            audioName ?? this.heartbeatData.playlistAudioName;

        this.onAudioChanged();
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData]
     * @desc Trigger when buffering starts.
     *
     */
    private rebufferingStartedEvent(eventData: Record<string, unknown>) {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = new Date();

        this.onRebufferingStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when buffering ends.
     *
     */
    private rebufferingEndedEvent(eventData: Record<string, unknown> = {}) {
        const duration =
            Date.now() - (this.rebufferingDuration?.getTime() ?? 0);

        this.onRebufferingEnded({
            ...eventData,
            duration
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback has been initialized.
     * @note avoids NaN
     *
     */
    private playbackInitializedEvent(eventData: {
        audio: { name: string; language: string };
        variant?: { audioChannels: number; audioCodec: string };
        subtitle: { language: string; name: string; forced: boolean };
    }) {
        const variant = new PlaybackVariant(eventData.variant);
        const audio = eventData.audio;
        const subtitle = eventData.subtitle;

        const { playlistAudioTrackType, playlistTimedTextTrackType } =
            getTrackTypes({ audio, subtitle });

        const {
            currentStreamUrl: streamUrl,
            initializePlayerDuration,
            initializePlayerStartTime
        } = this;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            playbackMode: this.initialPlaybackMode,
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced, // why is visibility is referenced through forced property?
            // subtitles could be not forced but still visible
            playlistAudioTrackType,
            playlistTimedTextTrackType
        };

        this.onPlaybackInitialized({
            ...eventData,
            variant,
            audio,
            subtitle,
            streamUrl,
            initializePlayerDuration,
            initializePlayerStartTime,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        });
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback seeks starts.
     * @note avoids NaN
     *
     */
    private playbackSeekStartedEvent(eventData: Record<string, unknown> = {}) {
        this.onPlaybackSeekStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback seeks ends.
     * @note avoids NaN
     *
     */
    private playbackSeekEndedEvent(eventData: Record<string, unknown> = {}) {
        this.onPlaybackSeekEnded(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when the subtitle changes.
     *
     */
    private subtitleChangedEvent(eventData: {
        subtitleLanguage: string;
        subtitleName: string;
        subtitleVisibility: boolean;
    }) {
        const { subtitleLanguage, subtitleName, subtitleVisibility } =
            eventData || {};

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage =
            subtitleLanguage ?? this.heartbeatData.playlistSubtitleLanguage;
        this.heartbeatData.playlistSubtitleName =
            subtitleName ?? this.heartbeatData.playlistSubtitleName;
        this.heartbeatData.subtitleVisibility =
            subtitleVisibility ?? this.heartbeatData.subtitleVisibility;

        this.onSubtitleChanged();
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when the bitrate changes.
     *
     */
    private bitrateChangedEvent(eventData: Record<string, unknown> = {}) {
        const isAdPlaying = this.getCurrentInterstitial().currentAsset;

        const adMetadata =
            isAdPlaying && this.adData.adMetadata ? this.adData.adMetadata : {};

        this.onBitrateChanged({
            ...adMetadata,
            ...eventData
        } as BitrateChangedEvent);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback is ready.
     *
     */
    private playbackReadyEvent(eventData: Record<string, unknown> = {}) {
        const { readyPlayerStartTime } = this;

        this.onPlaybackReady({
            ...eventData,
            readyPlayerStartTime,
            readyPlayerDuration: readyPlayerStartTime
                ? Date.now() - readyPlayerStartTime
                : 0
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback starts.
     *
     */
    private playbackStartedEvent(eventData: Record<string, unknown> = {}) {
        this.onPlaybackStarted(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback gets paused.
     *
     */
    private playbackPausedEvent(eventData: Record<string, unknown> = {}) {
        this.onPlaybackPaused(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData={}]
     * @desc Trigger when playback gets resumed.
     *
     */
    private playbackResumedEvent(eventData: Record<string, unknown> = {}) {
        this.onPlaybackResumed(eventData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} [eventData={}]
     *
     */
    private multivariantPlaylistFetchedEvent(eventData: {
        serverRequest: ServerRequest;
    }) {
        const { serverRequest: server } = eventData;

        const { multivariantFetchedStartTime } = this;

        const serverRequest = new ServerRequest(server);

        this.onMultivariantPlaylistFetched({
            ...eventData,
            serverRequest,
            multivariantFetchedStartTime,
            multivariantFetchedDuration: multivariantFetchedStartTime
                ? Date.now() - multivariantFetchedStartTime
                : undefined
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     *
     */
    private variantPlaylistFetchedEvent(eventData: {
        serverRequest: Record<string, unknown>;
        mediaSegmentType: string;
    }) {
        const { serverRequest: server, mediaSegmentType } = eventData || {};

        const segmentType =
            MediaSegmentTypeMap[
                mediaSegmentType as keyof typeof MediaSegmentTypeMap
            ];

        const serverRequest = new ServerRequest(server);

        this.onVariantPlaylistFetched({
            ...eventData,
            mediaSegmentType: segmentType,
            serverRequest
        });
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} [eventData={}]
     *
     */
    private drmKeyFetchedEvent(eventData: { serverRequest: ServerRequest }) {
        const { serverRequest: server } = eventData;
        const { fetchLicenseStartTime } = this;

        const {
            status,
            serverIP,
            cdnName,
            networkType,
            timeToFirstByte,
            error,
            method,
            host,
            path,
            statusCode,
            roundTripTime
        } = server || {};

        this.onDrmKeyFetched({
            ...eventData,
            serverRequest: new ServerRequest({
                method,
                host,
                path,
                statusCode,
                roundTripTime,
                status,
                serverIP,
                cdnName,
                networkType,
                timeToFirstByte,
                error
            }),
            fetchLicenseStartTime,
            fetchLicenseDuration: fetchLicenseStartTime
                ? Date.now() - fetchLicenseStartTime
                : 0
        });
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {Object} eventData
     * @note Handles the MANIFEST_LOADED event to update tracking when successful playlist loads.
     *
     */
    private successfulPlaylistLoad(eventData: { playlist: HlsPlaylist }) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            this.onSuccessfulPlaylistLoad(playlist);
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_REQUEST (to be renamed) event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistRequest(
        eventData: DssWebPlayerAdapter['currentMultivariantPlaylistRequest']
    ) {
        const { playbackStartupEventData } = this;
        const { assetType, playlist } = eventData || {};

        // Only track certain presentation type requests
        if (
            [
                PresentationType.bumper,
                PresentationType.ad,
                PresentationType.main
            ].includes(assetType)
        ) {
            this.currentMultivariantPlaylistRequest = eventData;
        }

        // This check is necessary, due to some asset types (Bumpers) won't have tracking data.
        if (playlist?.tracking) {
            const qosData = playlist.tracking.qos;
            const cdnVendor = qosData ? qosData.cdnVendor : null;

            this.qos = qosData;

            playbackStartupEventData.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }

        this.multivariantFetchedStartTime = Date.now();
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} eventData
     * @note Handles the MEDIA_ERROR event
     *
     */
    private playbackErrorEvent(eventData: {
        fatal: boolean;
        errorSource: DSSHLSError;
    }) {
        const { fatal = false, errorSource } = eventData || {};
        const { message: errorDetail } = errorSource || {};

        const {
            seekableRange = {} as NonNullable<
                DssWebNativePlayer['seekableRange']
            >,
            currentTime,
            isBehindLive,
            currentPDT: segmentPosition
        } = this.nativePlayer as DssWebNativePlayer;

        const playheadPosition = this.normalizePlayhead(
            Math.floor(currentTime * 1000)
        );

        const { data: adDataData = {}, adMetadata } = this.adData;
        const { adPodData, adPodPlacement } = adMetadata;

        const adMediaId = this.getAdMediaId();

        let adSlotData;

        if (adMediaId) {
            adSlotData = adDataData[adMediaId]?.adSlotData;
        } else {
            adSlotData = defaultAdMetadata.adSlotData;
        }

        let liveLatencyAmount;

        if (isBehindLive && seekableRange.pdtEnd - segmentPosition > 0) {
            liveLatencyAmount = seekableRange.pdtEnd - segmentPosition;
        }

        const errorName = mapHiveToQoeErrorCodes(errorSource);

        if (fatal) {
            // emit event to application
            this.emit(PublicEvents.MediaFailure, errorName);

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adPodData,
                    adPodPlacement,
                    // @ts-ignore TODO: Fix me - SDKMRJS-4982
                    adSlotData
                });

                this.playbackEndedEvent({ ...eventData, errorName });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adPodData,
                    adPodPlacement,
                    // @ts-ignore TODO: Fix me - SDKMRJS-4982
                    adSlotData
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistFallback(eventData: { playlist: HlsPlaylist }) {
        const { playlist } = eventData || {};

        if (playlist && playlist.tracking) {
            this.playbackStartupEventData.playbackError =
                QoePlaybackError.unknown;
            this.playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {Object}
     *
     */
    private getCurrentVariant() {
        const { variants = [], chosenVariant } =
            this.nativePlayer?.metrics?.playerMetrics?.playback ??
            ({} as Playback);

        const currentVariant = (variants[chosenVariant] ||
            {}) as VariantStream & { videoRangeSubType: unknown };

        const maxVariant = (variants[variants.length - 1] ||
            {}) as VariantStream;

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubType: videoRange
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {Object}
     *
     */
    private getAudioRendition() {
        const { averageBitrate, channels, codec, name, language } =
            this.nativePlayer?.metrics?.playerMetrics?.playback?.audio ??
            ({} as Audio);

        return {
            audioRendition: { name, language },
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    private getSubtitleRendition() {
        const { subtitles: subtitleRendition = {} } =
            this.nativePlayer?.metrics?.playerMetrics?.playback ??
            ({} as Playback);

        return subtitleRendition;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED` event
     *
     */
    private adPodRequested(eventData: {
        interstitialMetadata: InterstitialMetadata;
    }) {
        const { interstitialMetadata } = eventData;
        const { placement, midRollIndex: midrollIndex } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition) {
            return;
        }

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.onAdPodRequested({
            adPodPlacement
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_FETCHED` event
     *
     */
    private adPodFetched(eventData: {
        serverRequest: ServerRequest & { fetchStatus: string };
        startTimestamp: number;
        interstitialMetadata: InterstitialMetadata;
        response: AdResponse;
    }) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata,
            response
        } = eventData;

        const { placement = '' } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[
                (request.method || HttpMethod.post).toLowerCase() as HttpMethod
            ],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            error: undefined // mapping needs verified
        });

        this.processAdPodData({ interstitialMetadata, response });

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdPodFetched({
            adPodData,
            adPodPlacement,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @param {Object} podData
     * @returns {Object} The processed podData from the ad pod response stored in the adData private property.
     * @example
     * {
     *     data: {
     *         'someMediaId': {
     *             adSubtitleData: object;
     *             adSlotData: object;
     *             mediaUrl: string;
     *         };
     *     };
     *     adMetadata: {
     *         adPodData: object;
     *         adPodPlacement: object;
     *         adSlotData: object;
     *     }
     * }
     *
     */
    private processAdPodData(podData: {
        interstitialMetadata: InterstitialMetadata;
        response: AdResponse;
    }) {
        const { interstitialMetadata, response: adPodResponse } = podData;
        const { pods = [] } = adPodResponse || {};
        const {
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: adPodPlannedLength,
            slotCount: plannedSlotCount
        } = interstitialMetadata || {};

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        this.adData.adMetadata = defaultAdMetadata;

        this.adData.adMetadata.adPodData = {
            plannedSlotCount,
            plannedLength: Check.number(adPodPlannedLength)
                ? Math.floor(adPodPlannedLength)
                : undefined
        };

        this.adData.adMetadata.adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.adData.data = pods.reduce((data, pod) => {
            const { ads = [] } = pod;

            return ads.reduce((slotData: object, adSlot: AdSlotData) => {
                const {
                    creative: {
                        video: adCreative = {} as Record<
                            string,
                            string | number
                        >
                    } = {},
                    'slot-number': slotNumber
                } = adSlot;

                const {
                    'media-id': mediaId,
                    'media-url': mediaUrl,
                    'duration-ms': plannedLength
                } = adCreative;

                return {
                    ...slotData,
                    [mediaId]: {
                        adSubtitleData: {
                            subtitleVisibility: false
                        },
                        adSlotData: {
                            adMediaId: mediaId,
                            plannedLength: Check.number(plannedLength)
                                ? Math.floor(plannedLength)
                                : undefined,
                            slotNumber: slotNumber || 0
                        },
                        mediaUrl
                    }
                };
            }, data);
        }, {});
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_MULTIVARIANT_FETCHED` event
     *
     */
    private adMultivariantFetched(eventData: {
        serverRequest: ServerRequest & { fetchStatus: string };
        startTimestamp: number;
        interstitialMetadata: InterstitialMetadata;
    }) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method?.toLowerCase() as HttpMethod],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            error: undefined // mapping needs verified
        });

        const { adSlotData } =
            this.getAdDataByMediaId({
                adMediaId,
                plannedLength,
                slotNumber
            }) || {};

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdMultivariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} options
     * @param {String} options.adMediaId
     * @param {Number} [options.plannedLength]
     * @param {Number} [options.slotNumber]
     * @returns {Object}
     *
     */
    private getAdDataByMediaId(options: {
        adMediaId: string;
        plannedLength?: number;
        slotNumber?: number;
    }) {
        const { adMediaId, plannedLength, slotNumber } = options;

        let adDataData = this.adData.data[adMediaId as string];

        if (!adDataData) {
            adDataData = {
                adSubtitleData: {
                    subtitleVisibility: false
                },
                adSlotData: {
                    adMediaId,
                    plannedLength: Check.number(plannedLength)
                        ? Math.floor(plannedLength)
                        : undefined,
                    slotNumber: slotNumber || 0
                }
            };
        }

        this.adData.data[adMediaId as string] = adDataData;

        return adDataData;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_VARIANT_FETCHED` event
     *
     */
    private adVariantFetched(eventData: {
        serverRequest: ServerRequest & { fetchStatus: string };
        startTimestamp: number;
        assetData: AssetData;
        interstitialMetadata: InterstitialMetadata;
    }) {
        const subtitleVisibility =
            this.nativePlayer?.areSubtitlesEnabled || false;

        const {
            serverRequest: request,
            startTimestamp,
            assetData,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[request.method?.toLowerCase() as HttpMethod],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            error: undefined // mapping needs verified
        });

        const {
            mediaSegmentType,
            codec,
            range,
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            channels,
            language,
            name
        } = assetData;

        const adDataData =
            this.getAdDataByMediaId({
                adMediaId,
                plannedLength,
                slotNumber
            }) || ({} as AdMetadata);

        const { adSlotData } = adDataData;

        let { adVideoData, adAudioData, adSubtitleData } = adDataData || {};

        const segmentType =
            MediaSegmentTypeMap[
                mediaSegmentType as keyof typeof MediaSegmentTypeMap
            ];

        if (segmentType) {
            switch (segmentType) {
                case MediaSegmentType.video:
                    adVideoData = {
                        playlistVideoCodec: codec,
                        playlistVideoRange: range,
                        videoBitrate: bitrate,
                        videoAverageBitrate: averageBitrate,
                        playlistResolution: resolution,
                        playlistFrameRate: frameRate
                    };

                    adDataData.adVideoData = adVideoData;
                    break;

                case MediaSegmentType.audio:
                    adAudioData = {
                        playlistAudioChannels: parseInt(channels, 10),
                        playlistAudioCodec: codec,
                        playlistAudioLanguage: language,
                        playlistAudioName: name
                    };

                    adDataData.adAudioData = adAudioData;
                    break;

                case MediaSegmentType.subtitle:
                    adSubtitleData = {
                        subtitleVisibility,
                        playlistSubtitleLanguage: language,
                        playlistSubtitleName: name
                    };

                    adDataData.adSubtitleData = adSubtitleData;
                    break;

                default:
                    this.logger.error(
                        this.toString(),
                        `Unknown mediaSegmentType: ${mediaSegmentType}`
                    );
                    break;
            }
        }

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdVariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            mediaSegmentType: segmentType,
            adVideoData,
            adAudioData,
            adSubtitleData,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_STARTED` event
     *
     */
    private adPlaybackStarted(eventData: {
        asset: Asset;
        currentInterstitial: Interstitial;
    }) {
        const { asset = {} as Asset, currentInterstitial } = eventData;

        const { mediaId: adMediaId, type, subtype } = asset;

        const { placement = '' } =
            currentInterstitial || this.getCurrentInterstitial();

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        let presentation =
            PresentationType[type as PresentationType] ||
            PresentationType[subtype as PresentationType] ||
            PresentationType.ad;

        if (type === PresentationType.slug) {
            this.changePresentationType(type);
        }

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            presentation = PresentationType.bumper;
        }

        if (this.presentationType !== presentation) {
            this.changePresentationType(presentation);
        }

        if (!podPosition || !adMediaId) {
            return;
        }

        const { adSlotData, adVideoData, adAudioData, adSubtitleData } =
            this.adData.data[adMediaId] || {};

        this.adData.adMetadata.adSlotData = adSlotData;

        this.onAdPlaybackStarted({
            adPodPlacement: this.adData.adMetadata.adPodPlacement,
            adPodData: this.adData.adMetadata.adPodData,
            adSlotData: this.adData.adMetadata.adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     *
     */
    private adPlaybackEnded(options: {
        playbackError?: QoePlaybackError;
        playbackErrorDetail?: string;
        cause?: PlaybackExitedCause;
        asset: { mediaId?: string };
    }) {
        const {
            playbackError: errorName,
            playbackErrorDetail = '',
            cause: endCause,
            asset
        } = options || {};

        const { placement = '' } = this.getCurrentInterstitial();
        const { mediaId: adMediaId } = asset || {};

        let cause = endCause;
        let adVideoData;
        let adAudioData;
        let adSubtitleData;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        cause ??= PlaybackExitedCause.playedToEnd;

        const adErrorData = {
            errorName,
            errorMessage: playbackErrorDetail
        };

        if (this.adData.data[adMediaId]) {
            const data = Object.assign({}, this.adData.data[adMediaId]);

            adVideoData = data.adVideoData;
            adAudioData = data.adAudioData;
            adSubtitleData = data.adSubtitleData;
        }

        const { adPodData, adPodPlacement, adSlotData } =
            this.adData.adMetadata;

        this.onAdPlaybackEnded({
            adPodPlacement,
            adPodData,
            adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData,
            cause,
            adErrorData
        });

        if (adMediaId) {
            delete this.adData.data[adMediaId];
        }
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_STARTED` event
     *
     */
    private adPodStarted(eventData: { placement: string }) {
        const { placement = '' } = eventData;
        const { id, duration, position } = this.getCurrentInterstitial();

        let interstitialPlacement = InterstitialPlacement.preroll;

        this.presentationType = PresentationType.ad;

        if (placement === InsertionPointPlacement.MIDROLL) {
            interstitialPlacement = InterstitialPlacement.midroll;
        }

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            this.presentationType = PresentationType.bumper;
        }

        // eslint-disable-next-line custom-rules/no-unnecessary-optional-chain
        this.interstitialSessionId = id?.toString?.() || '';
        this.interstitialSessionDuration = duration;

        this.changePresentationType(this.presentationType);

        this.onInterstitialPlaybackStarted({
            interstitialType: InterstitialType.promo,
            interstitialPlacement,
            interstitialId: this.interstitialSessionId,
            interstitialPlannedLength: duration,
            interstitialPlayhead: position
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     *
     */
    private adPodEnded(eventData: {
        id?: number;
        duration?: number;
        position?: number;
        cause?: InterstitialEndedCause;
        message?: string;
        code?: number;
    }) {
        this.changePresentationType();

        const { id, duration, position = 0, cause, message } = eventData || {};

        // eslint-disable-next-line custom-rules/no-unnecessary-optional-chain
        const interstitialId = id?.toString?.() || this.interstitialSessionId;
        const interstitialDuration =
            duration || this.interstitialSessionDuration;

        let interstitialCause = cause
            ? InterstitialEndedCause.error
            : undefined;
        let errorData;

        if (message) {
            errorData = {
                errorName: mapHiveToQoeErrorCodes(eventData),
                errorMessage: message
            };
        }

        if (!interstitialCause) {
            interstitialCause = this.isAdSkipped
                ? InterstitialEndedCause.skip
                : InterstitialEndedCause.playedToEnd;
            this.isAdSkipped = false;
        }

        this.onInterstitialPlaybackEnded({
            interstitialType: InterstitialType.promo,
            interstitialPlacement: InterstitialPlacement.postroll,
            interstitialId,
            interstitialPlannedLength: interstitialDuration,
            interstitialPlayhead: position,
            cause: interstitialCause,
            errorData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED_ERROR` event
     *
     */
    private adRequestedError(eventData: { serverRequest: ServerRequest }) {
        const { serverRequest } = eventData || {};
        const { host, path } = serverRequest || {};

        this.changePresentationType(PresentationType.unknown);

        this.onAdRequestedError({
            error: QoePlaybackError.adServerError,
            errorLevel: ErrorLevel.error,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @note Handles the `INTERSTITIAL_SKIPPED` event
     * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#asset
     *
     */
    private adPlaybackSkipped() {
        const { adMetadata } = this.adData;

        const { currentPDT: segmentPosition, currentTime } = this
            .nativePlayer as DssWebNativePlayer;

        // Set flag for when `adPlaybackEnded` is triggered. flag is reset in `adPodEnded`
        this.isAdSkipped = true;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        this.onPlaybackSeekStarted({
            cause: PlaybackSeekCause.skip,
            playerSeekDirection: PlayerSeekDirection.forward,
            seekDistance: 0,
            playheadPosition: currentTime,
            segmentPosition,
            liveLatencyAmount: this.getLiveLatencyAmount(),
            adMetadata
        });
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @desc Returns the current live latency amount.
     * @returns {Number}
     *
     */
    private getLiveLatencyAmount() {
        const {
            seekableRange = {} as NonNullable<
                DssWebNativePlayer['seekableRange']
            >,
            isBehindLive,
            currentPDT
        } = this.nativePlayer as DssWebNativePlayer;

        let liveLatencyAmount = 0;

        if (seekableRange) {
            if (isBehindLive && seekableRange.pdtEnd - currentPDT > 0) {
                liveLatencyAmount = seekableRange.pdtEnd - currentPDT;
            }
        }

        return liveLatencyAmount;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `BEACON_ERROR` event
     *
     */
    private adBeaconError(eventData: { serverRequest: ServerRequest }) {
        const { serverRequest } = eventData;
        const { host, path } = serverRequest || {};

        this.onAdBeaconError({
            error: QoePlaybackError.adBeaconError,
            errorLevel: ErrorLevel.info,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {String} [presentation]
     * @desc Updates PresentationType
     *
     */
    private changePresentationType(presentation?: PresentationType) {
        const areSubtitlesEnabled = this.nativePlayer?.areSubtitlesEnabled;

        const {
            audioRendition: audio,
            averageBitrate: audioBitrate,
            audioChannels
        } = this.getAudioRendition();

        const { bitrate: videoBitrate, averageBitrate: videoAverageBitrate } =
            this.getCurrentVariant();

        const subtitle = this.getSubtitleRendition();

        this.presentationType = presentation || PresentationType.main;

        const { playlistAudioTrackType, playlistTimedTextTrackType } =
            getTrackTypes({ audio, subtitle });

        this.onPresentationTypeChanged({
            presentationType: this.presentationType,
            areSubtitlesEnabled,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            audioChannels: parseInt(audioChannels, 10),
            audio,
            subtitle,
            adMetadata: this.adData.adMetadata,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        });
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @note Handles the `CHUNK_LOADED` event
     *
     */
    private videoSegmentLoaded() {
        if (!this.readyPlayerStartTime) {
            this.readyPlayerStartTime = Date.now();
        }
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @notes Handles DRM_LICENSE_REQUESTED event
     *
     */
    private drmKeyRequestedEvent() {
        this.fetchLicenseStartTime = Date.now();
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @desc Get the ad mediaId from the ad data based on the currentMultivariantPlaylistRequest value
     * @returns {String|undefined}
     *
     */
    private getAdMediaId() {
        const { data: adDataData } = this.adData;

        const adMediaId = Object.keys(adDataData).find((key) =>
            this.currentMultivariantPlaylistRequest?.url?.includes(
                adDataData[key]?.mediaUrl
            )
        );

        return adMediaId;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} exception - the exception object that dss web throws
     * @desc Converts a dss web error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given dss web error
     *
     */
    private constructErrorCaseFromDssWebError(exception: {
        error: string;
        errorDetail: string;
    }) {
        const { error: code, errorDetail: description } = exception || {};

        return createSimpleException({
            code,
            description,
            exceptionData: { name: code, message: description }
        });
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @desc Sets the AdPlayheadPosition in AdMetadata.
     *
     */
    private setAdPlayheadPosition() {
        const currentInterstitial = this.getCurrentInterstitial();

        if (currentInterstitial) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.DssWebPlayerAdapter';
    }
}
