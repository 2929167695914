/**
 *
 * @module entry
 * @desc {SDK.Services.Eligibility} entry point.
 *
 */

import EligibilityStatus from './eligibilityStatus';
import EligibilityStatusResponse from './eligibilityStatusResponse';
import Partner from './partner';

/**
 *
 * @typedef {Object} SDK.Services.Eligibility
 *
 * @property {SDK.Services.Eligibility.EligibilityStatus} EligibilityStatus
 * @property {EligibilityStatusResponse} EligibilityStatusResponse
 * @property {Partner} Partner
 *
 */
export default {
    EligibilityStatus,
    EligibilityStatusResponse,
    Partner
};
