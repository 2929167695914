/**
 *
 * @module browser
 * @desc Service providers used in a web browser environment
 *
 */

import HttpClient from '../../services/providers/browser/httpClientLegacy';
import EnvironmentConfiguration from '../../services/providers/browser/environmentConfiguration';
import Storage from '../../services/providers/browser/storage';

/**
 *
 * @typedef {Object} SDK.Providers.BootstrapProviders.Browser
 * @access public
 * @since 12.0.0
 * @property {Function<HttpClient>} options.HttpClient - an `HttpClient` class that implements `CoreHttpClientProvider`
 * @property {Function<SDK.Services.Configuration.BaseEnvironmentConfiguration>} options.EnvironmentConfiguration - an `EnvironmentConfiguration` class
 * @property {Function<Storage>} options.Storage - A `Storage` class
 *
 */
export default {
    HttpClient,
    EnvironmentConfiguration,
    Storage
};
