/**
 *
 * @module deviceGrant
 *
 */

import Grant from './grant';

/**
 *
 * @access public
 * @desc A special type of grant used for exchanging device information for a token.
 *
 */
export default class DeviceGrant extends Grant {
    /**
     *
     * @access public
     * @type {String}
     * @desc Key used to lookup the `subject_token_type` value needed during token exchange.
     *
     */
    public override readonly subjectTokenTypeKey: string;

    /**
     *
     * @param {String} grantType
     * @param {String} assertion
     *
     */
    public constructor(grantType: string, assertion: string) {
        super(assertion, grantType);

        this.subjectTokenTypeKey = 'device';
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.DeviceGrant';
    }
}
