/**
 * Detect and classify some device specific information via userAgent.  
 * Detects browser, browser version, os, form-factor.
 * @name Device
 */
 (function (root, factory) {
    if ((typeof define === 'function' && define.amd) && !(typeof bam !== "undefined" && typeof bam.define === "function")) {
        // AMD.
        define([], factory);
    } else {
        // Browser globals
        root.deviceBootstrap = factory();
    }
}(this, function () {
	
	var OPEN='(',
		CLOSE=')',
		CASE='i',
		PIPE='|',

		OTHER 		= 'desktop', //treating other as desktop - chromebooks were getting classified as other
 		VERSION 	= 'version',
		EMPTY 		= "",

		Regex=function(regex,flags){return new RegExp(regex,flags||CASE)},
		ios 	="iP(?:hone|ad|od)",
		bb 		="(?:BlackBerry|BB10| RIM )",
		android ="Android",
		windows ="Windows",
		winpdsk ="WPDesktop", //Windows Phone Desktop Mode
		winpold = windows+" Phone",
		winphone="Trident(?:.*?Touch(?:.*?Mobile))",
		linux  	="Linux(?!.*Android)",
		mac 	="Macintosh",
		chromebook = "CrOS",
		desktop=OPEN+"?:"+[mac,windows,linux,chromebook].join(PIPE)+CLOSE,

		rSEP=/[\._]/,
		rPLATFORM={
			windowsphone: Regex(winphone+PIPE+winpold+PIPE+winpdsk),
			windows 	: Regex(windows),
			ios 		: Regex(ios),
			blackberry  : Regex(bb),
			android 	: Regex(android),
			linux 		: Regex(linux),
			macintosh 	: Regex(mac),
			chromebook  : Regex(chromebook)
		},
		rmsFORMFACTOR={
			/* 
			 * Microsoft platform specific form factor tests to address their UA nonsense without affecting other platforms
			 * treating Xbox as Tablet to avoid Flash issues / push into apps when appropriate
			 */
			desktop : /(?:Windows NT(?!.*WPDesktop)(?!.*Xbox))/i,
			tablet 	: Regex(OPEN+(['Tablet|iPad','\\sNT.*?'+winphone,android+'(?!.*Mobi)','silk',bb+'.*?Tablet','xbox'].join(PIPE))+CLOSE), 
			phone 	: Regex(OPEN+['Mobi|Mobile','WPDesktop',ios,winphone,bb].join(PIPE)+CLOSE)
		},
		rFORMFACTOR={
				/* Use Mobi for android partial match to also include Opara mobile for android
				 * @todo NT+winp can report incorrect matches for touch enabled win 8 desktop */
			tablet 	: Regex(OPEN+(['Tablet|iPad','\\sNT.*?'+winphone,android+'(?!.*Mobi)','silk',bb+'.*?Tablet'].join(PIPE))+CLOSE), 
			phone 	: Regex(OPEN+['Mobi|Mobile',ios,winphone,bb].join(PIPE)+CLOSE),
			desktop : Regex(desktop)
		},
		rBROWSER ={
			msie     	: /msie|iemobile|trident/i,
			edge        : /edge/i,
			android 	: /android(?!.+(?:chrome|silk))/i,
			chrome 		: /chrome|crios/i,
			firefox 	: /firefox/i,
			opera   	: /opera(?!.*?mini)\//i,
			operamini   : /opera mini/i,
			silk 		: /silk/i,
			safari 		: /safari/i
		},
		rBROWSERCORE ={
			webkit 		: /AppleWebKit/i,
			gecko 		: /gecko\//i,
			trident 	: /trident/i,
			edge        : /Edge/i
		},
		rNOTPOLYFILL=/\[\s*native code\s*\]/,

		FORMFACTORS = {
			desktop: "desktop",
			other  : OTHER,
			phone  : "phone",
			tablet : "tablet"
		},
		deviceCache={},
		queue;

	function safeArray(ar,index){
		return ar && index ? ar[index] : ar;
	}
	function version(vstr){
		var varr=vstr.split(rSEP).slice(0,3);
		if(vstr && varr.length<2){
			varr.push(0);
		}
		return varr.join('.');
	}
	
	/**
	 * Build a device descriptor object via a userAgent string.  
	 * Identifies the platform, browser name and version, and os version.
	 * Attempts to classify the device's form factor as either Phone, Tablet, or Desktop.
	 * Parsed devices are cached internally to reduce processing, and provide a mechanism
	 * for updating properties on the fly as need (@see getOrientation).
	 * @constructor
	 * @param {string} [ua] Optional userAgent string to consume. Defaults to navigator.userAgent.
	 */
	function Device(ua){
		ua=ua||navigator.userAgent;
		if(deviceCache[ua]){
			return deviceCache[ua];
		}
		if(!(this instanceof Device)){
			return new Device(ua);
		}
		deviceCache[ua]=this;
		this.userAgent=ua;
		this.is={};
		this.platform=this.getPlatform();
		this.platformVersion=this.getPlatformVersion();
		this.browser=this.getBrowser();
		this.browserVersion=this.getBrowserVersion();
		this.formFactor=this.getFormFactor();
	}


	Device.prototype={
		/**
		 * Determine os from a known set of values.  If not readily classifiable, 
		 * the platform will be 'other'.
		 * @see  rPLATFORMS
		 * @return {string} Platform name or 'other'.
		 */
		getPlatform : function(){
			return this.test(rPLATFORM)||OTHER;
		},
		/**
		 * Attempt to determing the version of the parsed platform. 
		 * Version will be formatted as MAJOR.MINOR.PATCH (if availble).
		 * @return {string} Version string of the determined platform.  In unknown, value is "".
		 */
		getPlatformVersion : function() {
			return version(this.match(Regex("(?:"+ (this.is.blackberry? VERSION:this.platform )+"|"+winpold+"|OS)[^\\d]+([\\d\\.\\_]+)","ig"),1));
		},
		/**
		 * Determine the browser name from the defined set.
		 * @see  rBrowser
		 * @return {string} Matched browser name from rBrowser. Defaults to 'other'.
		 */
		getBrowser : function() {
			this.test(rBROWSERCORE);
			return (this.test(rBROWSER)||OTHER);
		},
		/**
		 * Attempt to match the device to the form factor set {tablet, phone, desktop, other} where possible.
		 * @return {string} Form factor label, or 'other'.
		 */
		getFormFactor: function() {
			var formFactorTest = "";
			//different tests per platform?
			if (this.is.msie) {
				formFactorTest = rmsFORMFACTOR; 
			} else {
				formFactorTest = rFORMFACTOR;
			}
					
			return FORMFACTORS[this.test(formFactorTest)]||FORMFACTORS.other;
		},
		/**
		 * Parse out the browser version as a MAJOR.MINOR.PATCH formatted string. 
		 * @note  BUILD fragment is discarded.
		 * @return {string} Parsed version string of the form MAJOR.MINOR.PATCH.
		 */
		getBrowserVersion : function() {
			var searchBrowser=this.browser,
				delimiter = "[\\/\\s]";
			if(this.is.chrome && this.is.ios){
				searchBrowser="crios";
			}else if(this.is.opera){
				searchBrowser=VERSION
			}else if(this.is.msie){
				searchBrowser = searchBrowser + PIPE + "IEMobile"+PIPE+"rv";
				delimiter = "[\\/\\:\\s]";
			}
			return version(this.match((Regex("(?:"+searchBrowser+PIPE+VERSION+")"+delimiter+"([\\d\\.]+)","i")),1));
		},
		/**
		 * Helper method for safely running regex matches against the instances userAgent.
		 * @param  {regexp} 	regex Regex to match.
		 * @param  {number} 	[index] Optional captured match to return.  If no index is supplied, 
		 *                           	the entire match set is return as an array.
		 * @return {mixed}      String when used with index, or array of matches.
		 */
		match : function(regex,index){
			return safeArray(regex.exec(this.userAgent),index)||EMPTY;
		},
		test  : function(map){
			var key,
				check,
				result,
				ua=this.userAgent;

			for(key in map){
				check=map[key].test(ua);
				this.is[key]=this.is[key] || (check && !result);
				if(check && !result){
					result=key;
				}
			}
			return result;
		}
	};

	return Device;
}));