// Generated from SHA: 413ac25. Timestamp: 2024-03-13T13:49:36.795Z 

export enum AccessTokenType {
    Account = 'Account',
    AccountWithoutActiveProfile = 'AccountWithoutActiveProfile',
    Device = 'Device'
}

export enum AgeBandName {
    AccountHolder = 'AccountHolder',
    Minor = 'Minor',
    Teen = 'Teen'
}

export enum BundleType {
    Noah = 'Noah',
    Sash = 'Sash'
}

export enum ContentMaturityRatingAdvisoryDisplayStyle {
    Icons = 'Icons',
    Standard = 'Standard'
}

export enum ContentMaturityRatingDisplayStyle {
    Icons = 'Icons',
    Standard = 'Standard'
}

export enum ContentMaturityRatingIconSizeClass {
    Standard = 'Standard',
    TenPercent = 'TenPercent'
}

export enum Gender {
    Man = 'Man',
    NonBinary = 'NonBinary',
    PreferNotToSay = 'PreferNotToSay',
    Woman = 'Woman'
}

export enum IdentityPersonalInfoCollection {
    DateOfBirth = 'DateOfBirth',
    Gender = 'Gender'
}

export enum IdentityPersonalInfoEligibility {
    NotEligible = 'NotEligible',
    Optional = 'Optional',
    Required = 'Required'
}

export enum MarketingPreferencesProduct {
    DisneyPlus = 'DisneyPlus',
    EspnPlus = 'EspnPlus',
    StarPlus = 'StarPlus',
    WaltDisneyFamily = 'WaltDisneyFamily'
}

export enum NextOperation {
    Login = 'Login',
    RegisterAccount = 'RegisterAccount'
}

export enum NielsenMeasurementConsent {
    Agreed = 'Agreed',
    NotAgreed = 'NotAgreed'
}

export enum OffDeviceRedemptionFlow {
    Login = 'Login',
    PlanSwitch = 'PlanSwitch',
    Register = 'Register'
}

export enum Operation {
    Login = 'Login',
    Otp = 'OTP',
    Register = 'Register',
    RegisterAccount = 'RegisterAccount'
}

export enum OrderStatus {
    AuthOk = 'AuthOk',
    PaymentOk = 'PaymentOk',
    Pending = 'Pending',
    Redirect = 'Redirect',
    Success = 'Success',
    TemporaryDefaultAccess = 'TemporaryDefaultAccess'
}

export enum OtpSecurityAction {
    ChangePassword = 'ChangePassword'
}

export enum PaymentMethodUsage {
    MultiUse = 'multi_use',
    SingleUse = 'single_use'
}

export enum PaywallReason {
    Blockout = 'Blockout',
    ComingSoon = 'ComingSoon',
    Expired = 'Expired',
    Other = 'Other',
    PlatformUnavailable = 'PlatformUnavailable',
    PurchaseAllowed = 'PurchaseAllowed',
    Purchased = 'Purchased'
}

export enum PhysicalLocationType {
    AnonymousIp = 'ANONYMOUS_IP',
    CountryCode = 'COUNTRY_CODE',
    LowConfidence = 'LOW_CONFIDENCE',
    ProxiedIp = 'PROXIED_IP',
    Unavailable = 'UNAVAILABLE',
    Unknown = 'UNKNOWN',
    ZipCode = 'ZIP_CODE'
}

export enum ProfilePersonalInfoCollection {
    DateOfBirth = 'DateOfBirth',
    Gender = 'Gender',
    MinorConsent = 'MinorConsent',
    SuggestedMaturityRating = 'SuggestedMaturityRating',
    TeenConsent = 'TeenConsent'
}

export enum ProfilePersonalInfoEligibility {
    NotEligible = 'NotEligible',
    Optional = 'Optional',
    Required = 'Required'
}

export enum PurchaseContext {
    AccountDetails = 'AccountDetails',
    AppleDirectBillingEarlyAccess = 'AppleDirectBillingEarlyAccess',
    EarlyAccess = 'EarlyAccess',
    EarlyAccessPlanPicker = 'EarlyAccessPlanPicker',
    PlanPicker = 'PlanPicker',
    PlanSwitch = 'PlanSwitch'
}

export enum RequiresCollection {
    DateOfBirth = 'DateOfBirth',
    Gender = 'Gender',
    MinorConsent = 'MinorConsent',
    SuggestedMaturityRating = 'SuggestedMaturityRating'
}

export enum RequiresCollectionForJrMode {
    DateOfBirth = 'DateOfBirth',
    Gender = 'Gender',
    MinorConsent = 'MinorConsent',
    SuggestedMaturityRating = 'SuggestedMaturityRating'
}

export enum SecurityAction {
    ChangePassword = 'ChangePassword'
}

export enum SouthKoreanMobileCarrier {
    Kt = 'KT',
    LguPlus = 'LGUPlus',
    Mvnokt = 'MVNOKT',
    MvnolguPlus = 'MVNOLGUPlus',
    Mvnoskt = 'MVNOSKT',
    Skt = 'SKT'
}

export enum SubscriberStatus {
    Active = 'Active',
    Cancelled = 'Cancelled',
    Churned = 'Churned',
    Grace = 'Grace',
    Hold = 'Hold',
    Paused = 'Paused'
}

export enum SubscriptionAtRisk {
    Cancelled = 'Cancelled',
    Grace = 'Grace',
    Hold = 'Hold'
}

export enum SubscriptionCancellationType {
    AbuseRevocation = 'AbuseRevocation',
    AutoCancel = 'AutoCancel',
    Chargeback = 'Chargeback',
    InvoluntaryCancel = 'InvoluntaryCancel',
    PlanSwitched = 'PlanSwitched',
    Unknown = 'Unknown',
    VoluntaryCancel = 'VoluntaryCancel'
}

export enum SubscriptionPaymentProvider {
    Comcast = 'Comcast',
    Roku = 'Roku'
}

export enum SubscriptionPeriod {
    Annual = 'Annual',
    Monthly = 'Monthly',
    MultiAnnual = 'MultiAnnual'
}

export enum SubscriptionStackingStatus {
    Discounted = 'Discounted',
    DoubleBilled = 'DoubleBilled',
    Stacked = 'Stacked'
}

export enum SubscriptionState {
    Active = 'Active',
    Cancelled = 'Cancelled',
    Churned = 'Churned',
    Grace = 'Grace',
    Hold = 'Hold',
    Paused = 'Paused'
}

export enum SubscriptionType {
    Comp = 'Comp',
    GiftCard = 'GiftCard',
    GiftSub = 'GiftSub',
    Initial = 'Initial',
    Paid = 'Paid',
    PlanSwitched = 'PlanSwitched',
    PromoRedemption = 'PromoRedemption',
    Recurring = 'Recurring',
    Temp = 'Temp'
}
