/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 23.1.0
 * @typedef {Object} SDK.Services.Explore.RefIdType
 * @desc The type of the `refId` parameter.
 *
 * @property {String} encodedSeriesId - Since `23.1.0`.
 * @property {String} encodedFamilyId - Since `23.1.0`.
 * @property {String} deeplinkId - Since `24.0.0`.
 *
 */
export enum RefIdType {
    encodedSeriesId = 'encodedSeriesId',
    encodedFamilyId = 'encodedFamilyId',
    deeplinkId = 'deeplinkId'
}

/**
 *
 * @enum
 * @since 23.1.0
 * @typedef {Object} SDK.Services.Explore.ActionType
 * @desc The type of the `action` parameter.
 *
 * @property {String} browse - Since `23.1.0`.
 * @property {String} playback - Since `23.1.0`.
 *
 */
export enum ActionType {
    browse = 'browse',
    playback = 'playback'
}

/**
 *
 * @enum
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Explore.AccentClassification
 * @desc An enumeration of possible accent classifications. This is a type 1 enum.
 *
 * @property {String} light - Since `24.0.0`
 * @property {String} medium - Since `24.0.0`
 * @property {String} dark - Since `24.0.0`
 *
 */
export enum AccentClassification {
    light = 'light',
    medium = 'medium',
    dark = 'dark'
}

/**
 *
 * @enum
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Explore.WatchListEventType
 * @desc Add or remove from watchlist.
 * @note To be set by the SDK when constructing the watchlist event.
 *
 * @property {String} add - Since `24.0.0`.
 * @property {String} remove - Since `24.0.0`.
 *
 */
export enum WatchListEventType {
    add = 'add',
    remove = 'remove'
}

/**
 *
 * @enum
 * @since 25.0.0
 * @typedef {Object} SDK.Services.Explore.UpNextType
 * @desc Describes the target upNext type.
 *
 * @property {String} recommendation - Since `25.0.0`.
 * @property {String} sequential - Since `25.0.0`.
 *
 */
export enum UpNextType {
    recommendation = 'recommendation',
    sequential = 'sequential'
}
