/**
 *
 * @module serviceException
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/exceptions.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.md
 *
 */

import { Check } from '@dss/type-checking';

import Logger from '../../logging/logger';

import ErrorReason from './errorReason';
import ExceptionReference from './exceptionReference';

/**
 *
 * @desc base exception for SDK services
 * @note Interacts with Logging package and active sinks
 *
 */
export default class ServiceException extends Error {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public transactionId: string;

    /**
     *
     * @access public
     * @type {Array<ErrorReason>}
     *
     */
    public reasons: Array<ErrorReason>;

    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public status: number | null;

    /**
     *
     * @access public
     * @type {Object}
     * @desc Exception information provided to aid in debugging.
     *
     */
    public data: { name: string; message: string };

    public throttleTimeout?: number;

    /**
     *
     * @param {Object|String} [options={}]
     * @param {String} [options.transactionId=''] - The identifier allows BAM to lookup the entire operation in DUST
     * @param {Array<ErrorReason>} [options.reasons=[]] - Returned by the upstream service or by the SDK operation
     * @param {Object} [options.exceptionData={}]
     * @param {Number} [options.status=null]
     * @param {SDK.Services.Exception.ExceptionReference} [exData=ExceptionReference.common.invalidState]
     *
     */
    public constructor(
        options?:
            | string
            | {
                  transactionId?: string;
                  reasons?: Array<ErrorReason>;
                  status?: number;
                  exceptionData?: {
                      name: string;
                      message: string;
                  };
                  throttleTimeout?: number;
              },
        exData = ExceptionReference.common.invalidState
    ) {
        // this allows constructor overloading, we can pass a message and data as arguments
        // ie: new ServiceException(message, data)
        if (Check.string(options)) {
            const errorMessage = options;

            options = {
                reasons: [new ErrorReason({ description: errorMessage })],
                exceptionData: exData
            };
        }

        const {
            transactionId,
            reasons,
            status,
            exceptionData,
            throttleTimeout
        } = options || {};

        const defaultName = 'ServiceException';
        const defaultMessage =
            'An unexpected error occurred communicating with a service.';

        const message = exceptionData?.message ?? defaultMessage;

        super(message);

        this.transactionId = Check.string(transactionId) ? transactionId : '';
        this.reasons = Check.array(reasons) ? reasons : [];
        this.status = Check.number(status) ? status : null;

        this.name = exceptionData?.name ?? defaultName;
        this.throttleTimeout = throttleTimeout;

        this.data = {
            name: this.name,
            message: this.message
        };

        if (Check.function(Error.captureStackTrace)) {
            Error.captureStackTrace(this, this.constructor);
        }

        if (Check.string(this.stack)) {
            const stack = this.stack.split('\n');

            Logger.instance.error(stack.slice(0, 3).join('\n'), this);
        } else {
            Logger.instance.error('Unknown', this);
        }
    }

    /**
     *
     * @access public
     * @returns {String}
     *
     */
    public override toString() {
        return `${this.name}: ${this.message}`;
    }

    /**
     *
     * @access public
     * @returns {String}
     *
     */
    public inspect() {
        return this.toString();
    }
}
