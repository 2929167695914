import pageMeta from 'btm-pageMeta/pageMeta';
import BroadcastService from './broadcast.service';
import UserService from './user.service';
import SdkService from './sdk.service';
import { BroadcastServiceActions } from './actions';

type UNID = {
    done: boolean;
    new: boolean;
    reason: string;
    received: string;
    silo: boolean;
    unid: string;
};

class OneIDService {
    static instance: OneIDService;
    private _broadcastService: BroadcastService;
    private _userService: UserService;
    private _sdkService: SdkService;
    public oneId;

    static getInstance(broadcastService: BroadcastService, userService: UserService, sdkService: SdkService) {
        if (!OneIDService.instance) {
            OneIDService.instance = new OneIDService(broadcastService, userService, sdkService);
        }
        return OneIDService.instance;
    }

    constructor(broadcastService: BroadcastService, userService: UserService, sdkService: SdkService) {
        this._broadcastService = broadcastService;
        this._userService = userService;
        this._sdkService = sdkService;
    }

    private loginRedirect(): void {
        const successUrl = sessionStorage.getItem('loginSuccessUrl');

        /* istanbul ignore next */
        if (successUrl) {
            (window as any).open(successUrl, '_self');
        }
    }

    private setEntitlementLevel(entitlementLevel: string): void {
        document.body.classList.remove('entitlement-none');
        document.body.classList.add(entitlementLevel);
    }

    private getOneIDLangPref() {
        const lang = pageMeta.locale.split('_')[0];
        if (lang && lang === 'es') {
            return 'es-LA';
        } else {
            return 'en-US';
        }
    }

    private getConfig() {
        const serverPath = (window as any).server_path;
        const sectionId = pageMeta.section;
        /* istanbul ignore next */
        const responderUrl = (window as any).location.hostname.indexOf('espnqa.com') > -1 ? serverPath.responder_espnqa : serverPath.responder;
        const oneIdConfig = {
            clientId: 'ESPN-ONESITE.WEB',
            responderPage: responderUrl,
            cssOverride: `${serverPath.espnstatic}/espn/data/oneid/override.basesite.css`,
            langPref: this.getOneIDLangPref(),
            optionalConfigs: {
                reporting: {
                    source: 'DTC',
                    context: 'direct'
                }
            }
        };

        return oneIdConfig;
    }

    private initListeners() {
        this.oneId.on('login', /* istanbul ignore next */ async (guest) => {
            const jwt = guest.token.id_token;

            try {
                await this._sdkService.login(jwt);
            } catch (err) {
                console.error(err);
            }

            this._userService.setOneIdGuestInformation(guest);
            this._userService.unid = 'deprecated';
            this.setEntitlementLevel(this._userService.accountDetails.entitlementLevel);
            this._broadcastService.emit(BroadcastServiceActions.User.userSet, this._userService);
            this._broadcastService.emit(BroadcastServiceActions.User.loggedIn, true);
            this.loginRedirect();
        });

        this.oneId.on('error', (e) => {
            console.error(e);

            this.loginRedirect();
        });

        this.oneId.on('refresh', async (token) => {
            try {
                await this._sdkService.logout();
                await this._sdkService.login(token.id_token);
            } catch (err) {
                await this.oneId.logout();
                await this._sdkService.logout();
            }
        });
    }

    public async initialize() {
        const oneID = (window as any).OneID;

        if (!oneID) {
            console.log('Unable to initialize, OneID not found on window');
            return;
        }
        try {
            const oneIdConfig = this.getConfig();

            if (!this.oneId) {
                this.oneId = oneID.get(oneIdConfig);
            }

            const data = await this.oneId.init();

            if (data.guest) {
                const token = data.guest.token?.id_token || '';

                try {
                    const isTrusted = await this.oneId.getTrustStateStatus();

                    if (isTrusted) {
                        await this._sdkService.login(token);

                        this._userService.setOneIdGuestInformation(data.guest);
                        this._userService.unid = 'deprecated';
                        this.setEntitlementLevel(this._userService.accountDetails.entitlementLevel);
                        this._broadcastService.emit(BroadcastServiceActions.User.userSet, this._userService);
                    } else {
                        (window as any).isLoggedIn = false;
                        await this.oneId.logout();
                    }
                } catch (err) {
                    console.error(err);

                    await this.oneId.logout();
                    await this._sdkService.logout();
                }
            } else {
                await this._sdkService.logout();
                this.setEntitlementLevel(this._userService.accountDetails.entitlementLevel);
                this._broadcastService.emit(BroadcastServiceActions.User.userSet, this._userService);
            }
            this.initListeners();

        } catch (err) {
            console.error(err);
            throw err;
        }
    }
}

export default OneIDService;
