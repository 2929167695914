/**
 *
 * @module telemetryClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Internal.Telemetry.TelemetryClientEndpoint
 *
 * @property {String} postEvent
 * @property {String} dustEvent
 * @property {String} validateDustEvent - Since `4.11.0`.
 * @property {String} validateQoeEvent
 *
 */
enum TelemetryClientEndpoint {
    postEvent = 'postEvent',
    dustEvent = 'dustEvent',
    envelopeEvent = 'envelopeEvent',
    validateDustEvent = 'validateDustEvent',
    validateQoeEvent = 'validateQoeEvent',
    validateDustEnvelope = 'validateDustEnvelope'
}
export default TelemetryClientEndpoint;
