/**
 *
 * @module mediaPlugin
 * @desc {SDK.Media.MediaPlugin} entry point.
 *
 */

import DrmPlugin from '../drm/drmPlugin';

import Media from './entry';

import MediaApi from './mediaApi';
import MediaManager from './mediaManager';

import MediaService from '../services/media/entry';
import MediaClient from '../services/media/mediaClient';

import AdEngineClient from '../services/media/adEngine/adEngineClient';
import AdEngineClientConfiguration from '../services/media/adEngine/adEngineClientConfiguration';
import AdEngineClientExtrasMap from '../services/media/adEngine/adEngineClientExtrasMap';

import MediaClientConfiguration from '../services/media/mediaClientConfiguration';
import MediaManagerConfiguration from '../services/configuration/mediaManagerConfiguration';
import MediaManagerExtrasMap from '../services/configuration/mediaManagerExtrasMap';

import DrmClient from '../services/drm/drmClient';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Media', typeof Media, typeof MediaService> = {
    pluginName: 'Media',
    entry: Media,
    service: MediaService,
    dependencies: [DrmPlugin],

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { platformMetricsProvider } = sdkSession;

        const { mediaManager } = sdkSession.managers;

        /**
         *
         * @access public
         * @type {SDK.Media.MediaApi}
         * @desc Gets the media resource used to support media playback.
         *
         */
        sdkSession.mediaApi = new MediaApi({
            mediaManager,
            platformMetricsProvider,
            logger
        });
    },

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createManager(options) {
        const {
            managers,
            logger,
            httpClient,
            services,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            geoProvider,
            advertisingIdProvider,
            monotonicTimestampProvider,
            storage,
            metadata: { clientId, environment }
        } = options;

        const { tokenManager, telemetryManager, deviceManager } = managers;

        const drmClient = new DrmClient({
            config: services.drm,
            clientId,
            environment,
            storage,
            logger,
            httpClient
        });

        const adEngineClient = new AdEngineClient({
            config: services.adEngine,
            sdkver: deviceManager.environmentConfiguration.sdkVersion,
            geoProvider,
            advertisingIdProvider,
            disabled: services.adEngine.disabled,
            logger,
            httpClient
        });

        managers.mediaManager = new MediaManager({
            mediaManagerConfiguration: services.media.config,
            tokenManager,
            drmClient,
            telemetryManager,
            mediaCapabilitiesProvider,
            platformMetricsProvider,
            mediaClient: new MediaClient({
                config: services.media.client,
                logger,
                httpClient,
                monotonicTimestampProvider
            }),
            adEngineClient,
            logger,
            advertisingIdProvider,
            monotonicTimestampProvider
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ManagerConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { extras: adEngineExtras } = services.adEngine;
        const { adTargeting } = adEngineExtras;
        const { extras: mediaExtras } = services.media;

        serviceDefinitionsConfiguration.adEngine =
            new AdEngineClientConfiguration({
                baseUrl: services?.adEngine?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.adEngine?.client?.endpoints
                ),
                extras: new AdEngineClientExtrasMap(adTargeting)
            });

        const mediaClientConfiguration = new MediaClientConfiguration({
            baseUrl: services?.media?.client?.baseUrl,
            endpoints: serviceDefinitionsConfiguration.processEndpoints(
                services?.media?.client?.endpoints
            )
        });

        serviceDefinitionsConfiguration.media = {
            config: new MediaManagerConfiguration({
                client: mediaClientConfiguration,
                extras: new MediaManagerExtrasMap(mediaExtras),
                disabled: services.media.disabled
            }),
            client: mediaClientConfiguration
        };
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
