/**
 *
 * @module sdk_browser_browser
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/SdkPluginModel.md
 *
 */

import SDK from './../../sdk';

import bootstrapProviders from './../../providers/bootstrapProviders/browser';

SDK.bootstrapProviders(bootstrapProviders);

/**
 *
 * @typedef {Object} SDK(browser_browser)
 * @access public
 * @desc Targeted build for Browser and Browser Based Platforms.
 *
 * @property {SDK.Account} Account - Supports account management.
 * @property {SDK.Advertising} Advertising - Supports the advertising feature.
 * @property {SDK.Commerce} Commerce - Supports direct commerce (IAP without going through the platform's store).
 * @property {SDK.Content} Content - Single content management system to allow access to all content discovery services.
 * @property {SDK.CustomerService} CustomerService - Support for getting a Customer Service Support Code to be used to find a customer's current context (such as device ID).
 * @property {SDK.Drm} Drm - License DRM providers and their use within the SDK to decrypt media content.
 * @property {SDK.Eligibility} Eligibility - Used to check if a user is eligible to purchase.
 * @property {SDK.Entitlement} Entitlement - Supports entitlements.
 * @property {SDK.Explore} Explore - Provides an object for all Explore related flows.
 * @property {SDK.ExternalActivation} ExternalActivation
 * @property {SDK.Flex} Flex - Provides an object for all GrowthLife related flows.
 * @property {SDK.QRCode} QRCode - Provides an object used to access QR Code related features.
 * @property {SDK.Invoice} Invoice - Supports retrieving invoices information for displaying in client applications.
 * @property {SDK.Media} Media - Provides a resource that can be used to help load, control, and monitor media playback.
 * @property {SDK.Paywall} Paywall - Supports retrieving the Paywall.
 * @property {SDK.Platform} Platform - Provide the ability to get platform metrics.
 * @property {SDK.Purchase} Purchase - Provides support for redemption of purchases made through platform-specific stores.
 * @property {SDK.Socket} Socket - Provides ability to access sockets.
 * @property {SDK.UserActivity} UserActivity - Public interface for DUST.
 *
 * @property {SDK.Configuration} Configuration - Core Feature - Manages configuration information required to use the SDK.
 * @property {SDK.Device} Device - Core Feature - Handles device grant logic.
 * @property {SDK.InitializationState} InitializationState - Core Feature
 * @property {SDK.Logging} Logging - Core Feature - Manages information that is used for logging purposes.
 * @property {SDK.Orchestration} Orchestration - Core Feature - Provides ability to access orchestration data.
 * @property {SDK.Services} Services - Core Feature - Service layer of all SDK features.
 * @property {SDK.Subscription} Subscription - Core Feature - Support for the BAM Subscription Service.
 * @property {SDK.Token} Token - Core Feature - Supports token exchange and token refresh.
 * @property {SDK.Session} Session - Core Feature - Supports session management.
 * @property {SDK.SdkSession} SdkSession - Core Feature - Represents an SDK session for the application to interact with.
 * @property {SDK.Events} Events - Core Feature - Comprehensive list of public events emitted by the SDK.
 * @property {SDK.OffDeviceTokenRefreshEvent} OffDeviceTokenRefreshEvent - Core Feature
 * @property {SDK.ReauthorizationFailure} ReauthorizationFailure - Core Feature - The event raised when automatic token refresh fails.
 * @property {SDK.Endpoint} Endpoint - Core Feature - Provides configuration information for a service endpoint (i.e. link).
 * @property {SDK.DiagnosticsApi} DiagnosticsApi - Core Feature - Provides the ability to validate Glimpse and QoE DUST events against the event and field definitions contained in the Schema Registry.
 * @property {SDK.DiagnosticFeature} DiagnosticFeature - Core Feature - Specifies the feature that will have its events validated.
 * @property {SDK.SessionInfoChangedEvent} SessionInfoChangedEvent - Core Feature - Emitted by SdkSession when the session info associated with the underlying access token has changed, providing access to both the old and new SessionInfo objects.
 * @property {SDK.AccessChangedEvent} AccessChangedEvent - Core Feature - Emitted by SdkSession each time an access token is updated.
 * @property {SDK.AgeVerificationFlowType} AgeVerificationFlowType - Core Feature - Determines the flow type of an age verification changed event.
 * @property {SDK.Ripcut} Ripcut - Core Feature - Provides an api for retrieving images from Ripcut.
 *
 */
export default SDK;
