/**
 *
 * @module constants
 *
 */

/**
 *
 * @access public
 * @since 28.1.0
 * @desc String constant for the DEFAULT_ZERO_GUID value.
 *
 */
export const DEFAULT_ZERO_GUID = '00000000-0000-0000-0000-000000000000';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc Regex constant for replacing new line and whitespace.
 *
 */
export const REPLACE_NL_WS = /(?:\n\s+)/g;
