/**
 *
 * @module imageFormat
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.ImageFormat
 * @desc File format of the desired variant image.
 * The `pvr` and `jpeg2000` formats only when the library parameter is present on request.
 *
 * @property {String} jpeg - Since `17.0.0`
 * @property {String} png - Since `17.0.0`
 * @property {String} jpeg2000 - Since `17.0.0`
 * @property {String} pvr - Since `17.0.0`
 * @property {String} webp - Since `26.0.0`
 *
 * @example
 * SDK.Ripcut.ImageFormat.jpeg // 'jpeg'
 *
 */
enum ImageFormat {
    jpeg = 'jpeg',
    png = 'png',
    jpeg2000 = 'jpeg2000',
    pvr = 'pvr',
    webp = 'webp'
}

export default ImageFormat;
