/**
 *
 * @module entitlementPlugin
 * @desc {SDK.Entitlement.EntitlementPlugin} entry point.
 *
 */

import EntitlementApi from './entitlementApi';
import EntitlementClient from '../services/entitlement/entitlementClient';
import EntitlementClientConfiguration from '../services/entitlement/entitlementClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Entitlement'> = {
    pluginName: 'Entitlement',

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.entitlementApi = new EntitlementApi({
            entitlementClient: new EntitlementClient({
                config: sdkSession.config.services.entitlement,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.entitlement =
            new EntitlementClientConfiguration({
                baseUrl: services?.entitlement?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.entitlement?.client?.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
