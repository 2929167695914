/**
 *
 * @module playbackHeartbeatEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback-heartbeat.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import {
    AdInsertionType,
    AudioTrackType,
    PresentationType,
    HeartbeatSampleType,
    NetworkType,
    PlaybackMode,
    PlaybackState,
    PlaylistBitrateType,
    ProductType,
    TimedTextTrackType
} from './enums';

import {
    AdPodData,
    AdPodDataTypedef,
    AdPodPlacement,
    AdPodPlacementTypedef,
    AdSlotData,
    AdSlotDataTypedef
} from './typedefs';

/**
 *
 * @since 13.0.0
 * @description This event represents a playback heartbeat.
 * @note urn:dss:event:client:playback:heartbeat:v1
 * @note This event should be sent every 30 seconds or when one of the following properties changes: `cpSessionId`,
 * `playbackSessionId`, `streamUrl`, `contentId`, `presentationType`, `cdnName`, `videoBitrate`.
 * @note Fire immediately before playback pause and playback ended. Throttle to not send when the playback duration is
 * 0 or if playback hasn't happened in the past 30 seconds.
 *
 */
export default class PlaybackHeartbeatEventData {
    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Duration of the playback since last `player_heartbeat` event in milliseconds, regardless of `presentationType`.
     *
     */
    public playbackDuration?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.PlaybackState|undefined}
     * @desc Current state of playback.
     *
     */
    public playbackState?: Nullable<PlaybackState>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ProductType|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The total time, in milliseconds, from the playlist configuration service request to either
     * the first time unit render or the player being fully buffered and ready in the case of preloading.
     * @note In the case of content with a Negative Stereotype Advisory (NSA) then the total VST should exclude this NSA
     * duration from the above totalVst calculation.
     * @note Pass 0 if the value is unavailable at the time of reporting.
     * @note Source this by calculating the time elapsed from the `videoStartTimestamp` stored during the
     * `requested` event and the current time of the `ready` event.
     * @note This may vary by platform implementation, especially when bumpers and prerolls are involved.
     * See the QoE Feature Overview for additional details.
     *
     */
    public totalVst: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The client epoch timestamp of requesting playlist configuration service.
     * @note When preloading (prefetch playlist), this time is the epoch timestamp, in milliseconds,
     * that the platform loads the prefetched playlist from cache.
     * @note Pass 0 if the value is unavailable at the time of reporting.
     * @note Source this by getting the current time during the `requested` event.
     *
     */
    public videoStartTimestamp: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Amount of video currently fully buffered in milliseconds. Best version is accessed via a getter provided by logic player.
     * @note Pass 0 if the value is unavailable at the time of reporting.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public bufferSegmentDuration: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Sum of all bytes downloaded since the last player heartbeat for all the following resource types:
     * manifests (M3U8 or MPD), video segments, audio segments, muxed video/audio segments, timed text segments or sidecar files,
     * thumbnail images or I-frame segments.
     * @note The value should preferably represent bytes downloaded (not played), but if that data is not available it’s acceptable
     * to represent bytes played instead.
     * @note If data about all listed resource types is not available, clients should use best effort to include as many listed
     * resource types as possible. Value is the number of bytes.
     * @note The SDK should reference the previous `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider`
     * to calculate this value.
     *
     */
    public mediaBytesDownloaded?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Sum of time spent for all media bytes downloaded since the last player heartbeat event in milliseconds.
     * @note "All media" here means all media resources, mainly including video segments and audio segments.
     * @note The SDK should reference the previous `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider`
     * to calculate this value.
     *
     */
    public mediaDownloadTotalTime?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Number of all downloaded (including successfully and failed) video and audio segments.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public mediaDownloadTotalCount?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Client generated ID of the stream/playback session.
     * @note Added after this event is created via the `logQoeEvent` method on `SDK.Media.PlaybackTelemetryDispatcher`
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
     * @note Source from the associated `SDK.Media.PlaybackEndedEvent`.
     *
     */
    public playheadPosition: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The peak video bitrate of the current `presentationType`, in bps.
     * @dnote The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged`
     * and `PlaybackEventListener.onPresentationTypeChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The average video bitrate of the current presentationType, in bps.
     * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged`
     * and `PlaybackEventListener.onPresentationTypeChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoAverageBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
     * last played before the event, as reported in the HLS or DASH manifest.
     * @note If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
     * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged`
     * and `PlaybackEventListener.onPresentationTypeChanged` event.
     *
     */
    public audioBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The highest video bitrate (in bps) available for the currently selected presentationType in the current
     * DASH/HLS manifest that is allowed to play. The user may not reach this bitrate in their session.
     * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
     * resolution caps based on video window resolution/orientation.
     * @note The value should default to 0 if unavailable or unknown.
     * @note If the `PlaybackActivity` is `started`, SDKs should source this from the associated `SDK.Media.PlaybackStartedEvent`
     * event and update the cached `maxAllowedVideoBitrate` value.
     * @note If the `PlaybackActivity` is not `started`, SDKs should source this from the cached `maxAllowedVideoBitrate` value.
     *
     */
    public maxAllowedVideoBitrate?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc This should be the epoch time in milliseconds of the video. Required if `productType` is `Live`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public segmentPosition?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc The CDN running the server.
     * @note A string value of "null" needs to be provided if not run by a CDN.
     *
     */
    public cdnName: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.NetworkType}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType: NetworkType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Latency of the current playback position, in milliseconds, with the live head
     * (live head - current position, >= 0). Required if `productType` is `Live`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public liveLatencyAmount?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.PlaylistBitrateType|undefined}
     * @desc Average or peak (average is default). If known, we should indicate whether this is the average
     * (e.g. `AVERAGE-BANDWIDTH`) or peak (e.g. `BANDWIDTH`).
     *
     */
    public playlistBitrateType?: PlaylistBitrateType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The current user throughput reported, if sent by the application (measure in bits per second), regardless of the presentationType.
     * @note Heimdall 1.0 field.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public currentThroughput?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Current available CPU as a percentage.
     * @note Heimdall 1.0 field.
     * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
     *
     */
    public cpuPercentage?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Current available memory in MB.
     * @note Heimdall 1.0 field.
     * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
     *
     */
    public freeMemory?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Value of the PROGRAM-DATE-TIME of the last (most recent) seekable segment + segment length in epoch millis
     * i.e. end of last segment. Used to support live latency calculation.
     * @note Heimdall 1.0 field.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public seekableRangeEndProgramDateTime?: Nullable<number>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean|undefined}
     * @desc Indication that the content being played is at the live edge (3 segment durations behind live edge)
     * i.e. playing 4th segment from front of playlist.
     * @note Heimdall 1.0 field.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public isLiveEdge?: Nullable<boolean>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc Indicates if some form of artificial delay occurs on content start up.
     * @note Source from the `artificialDelay` flag passed in from `SDK.Media.MediaApi.fetch()`.
     *
     */
    public artificialDelay: boolean;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number}
     * @desc Time in milliseconds of some form of artificial delay that occurred during content start up i.e. NSA duration.
     * @note Source from the `artificialDelayDuration` passed in from `Bam.Sdk.Media.PlaybackSession.prepare()`.
     * @note Conditionally required when artificialDelay = true.
     * @note Default value is 0.
     *
     */
    public artificialDelayDuration: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when testing PQM.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public clientGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public serverGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Object<String, String>}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, string>;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {SDK.Services.QualityOfService.HeartbeatSampleType|undefined}
     * @desc Differentiates the different kinds of playback heartbeat events.
     * @note This is technically optional, but the intent is to provide this when available.
     *
     */
    public sampleType?: HeartbeatSampleType;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Number|undefined|null}
     * @desc Audio channels as defined by the selected playlist variant.
     * @note The SDK should keep track of the most recent playlistAudioChannels value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established.
     *
     */
    public playlistAudioChannels?: Nullable<number>;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {String|undefined|null}
     * @desc Audio codec as defined by the selected playlist variant.
     * @note The SDK should keep track of the most recent playlistAudioCodec value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established.
     *
     */
    public playlistAudioCodec?: Nullable<string>;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {String|undefined}
     * @description Audio language as defined by the selected audio rendition.
     * @note The SDK should keep track of the most recent playlistAudioLanguage value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onAudioChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established.
     *
     */
    public playlistAudioLanguage?: string;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {String|undefined}
     * @desc Audio name as defined by the selected audio rendition.
     * @note The SDK should keep track of the most recent playlistAudioName value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onAudioChanged` and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established.
     *
     */
    public playlistAudioName?: string;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {String|undefined}
     * @desc Subtitle language as defined by a variant playlist.
     * @note The SDK should keep track of the most recent playlistSubtitleLanguage value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note Required if subtitleVisibility is `true`.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established if subtitleVisibility is `true`.
     *
     */
    public playlistSubtitleLanguage?: string;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {String|undefined}
     * @desc Name of the subtitle.
     * @note The SDK should keep track of the most recent playlistSubtitleName value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note Required if subtitleVisibility is `true`.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events, regardless of
     * sampleType, once a value has been established if subtitleVisibility is `true`.
     *
     */
    public playlistSubtitleName?: string;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Boolean}
     * @desc Name of the subtitle.
     * @desc Subtitle visibility.
     * @note The SDK should keep track of the most recent subtitleVisibility value from `PlaybackEventListener.onInitialized`,
     * `PlaybackEventListener.onSubtitleChanged`, and `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note Default to false if unavailable.
     *
     */
    public subtitleVisibility: boolean;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.PresentationType}
     * @desc The type of presentation currently being played.
     * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
     * event or the `SDK.Media.PlaybackStartedEvent` object.
     *
     */
    public presentationType: PresentationType;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.AdInsertionType}
     * @desc The way ads are inserted into the stream.
     * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
     *
     */
    public adInsertionType: AdInsertionType;

    /**
     *
     * @access public
     * @since 22.0.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the playlist contains slugs.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.hasSlugs field.
     * @note Default to false if unavailable.
     *
     */
    public hasSlugs?: boolean;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.Media.SubscriptionType>}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
     * @note Required if `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Random UUID assigned by the ad server.
     * @note Required if `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
     *
     */
    public adSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
     * @desc Placement information relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adPodPlacement?: AdPodPlacement;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
     * @desc Metadata relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public adPodData?: AdPodData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
     * @desc Metadata relevant to ad slots.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public adSlotData?: AdSlotData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the current ad pod.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public adPlayheadPosition?: number;

    /**
     *
     * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
     * @access public
     * @since 20.0.1
     * @type {String}
     *
     */
    public periodType: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String|undefined}
     * @desc The name of the video player used to play a stream.
     * @note Source from the `SDK.Media.PlayerAdapter`.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all
     * heartbeat events.
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String|undefined}
     * @desc The version of the video player used to play a stream.
     * @note Source from the `SDK.Media.PlayerAdapter`.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all
     * heartbeat events.
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Boolean}
     * @desc True if the media is being played from a local source, e.g. it has been downloaded for offline playback or was bundled in the application.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all playback events.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public localMedia: boolean;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The `interactionId` will correspond with one of the defined `interactionTypes`. The `interactionId` will be used as
     * the primary key for all interactions.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for
     * all playback events when `interactionId` is not null.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String<SDK.Services.QualityOfService.AudioTrackType>}
     * @desc The track type of the currently selected audio rendition.
     * @note The SDK should keep track of the most recent playlistAudioTrackType value from
     * `PlaybackEventListener.onInitialized`, `PlaybackEventListener.onAudioChanged` and
     * `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events,
     * regardless of sampleType, once a value has been established.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
     *
     */
    public playlistAudioTrackType: AudioTrackType;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String<SDK.Services.QualityOfService.TimedTextTrackType>}
     * @desc The track type of the currently selected timed text rendition.
     * @note The SDK should keep track of the most recent playlistTimedTextTrackType value from
     * `PlaybackEventListener.onInitialized`, `PlaybackEventListener.onSubtitleChanged`, and
     * `PlaybackEventListener.onPresentationTypeChanged` and report this field whenever available.
     * @note Required if subtitleVisibility is `true`.
     * @note SDKs are expected to continue to report this field on all subsequent Heartbeat events,
     * regardless of sampleType, once a value has been established if subtitleVisibility is `true`.
     *
     */
    public playlistTimedTextTrackType: TimedTextTrackType;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Boolean}
     * @desc A flag indicating if the media fetch request to the playback orchestration service succeeded.
     * @note SDKs can determine this value if the media fetch to the PBO service was successful or not.
     * @note Required for all heartbeat events.
     *
     */
    public mediaFetchSucceeded: boolean;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {Number|undefined}
     * @desc As defined by the BANDWIDTH (where available) of the highest bitrate variant attained during a
     * viewer session while playing primary content. For HLS Manifest, use the 'BANDWIDTH' value and for
     * DASH, use the '@bandwidth' value.
     * @note Should be updated at least once per period (per heartbeat).
     * @note The SDK should keep track of the highest `videoBitrate` value from the `PlaybackEventListener.onPlaybackStarted`, `PlaybackEventListener.onBitrateChanged`, and `PlaybackEventListener.onPresentationTypeChanged` events.
     * @note If value is 0 or not available return 0.
     *
     */
    public maxAttainedBitrate?: number;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {String<SDK.Services.QualityOfService.PlaybackMode>|undefined}
     * @desc Window mode in which content is being played.
     * @note The SDK should keep track of the most recent playbackMode value from
     * `PlaybackEventListener.onPlaybackInitialized` and `PlaybackEventListener.onPlaybackModeChanged`,
     * and report this field whenever available.
     * @note Required when `sampleType` is `user`
     *
     */
    public playbackMode?: PlaybackMode;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String|undefined}
     * @desc Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
     * @note Conditionally required where `platformId` = `realitydevice`.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     * @note The SDK should keep track of the most recent `viewingEnvironment` value, initially from the `PlaybackContextOptions.viewingEnvironment`
     * field and updates through the `PlaybackEventListener.onViewingEnvironmentChanged` event.
     *
     */
    public viewingEnvironment?: string;

    /**
     *
     * @access public
     * @since 28.3.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `MonotonicTimestampProvider.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @param {Object} [options={}]
     * @param {Number} [options.playbackDuration]
     * @param {SDK.Services.QualityOfService.PlaybackState} [options.playbackState]
     * @param {SDK.Services.QualityOfService.ProductType} [options.productType]
     * @param {Number} [options.totalVst=0]
     * @param {Number} [options.videoStartTimestamp=0]
     * @param {Number} [options.bufferSegmentDuration=0]
     * @param {Number} [options.mediaBytesDownloaded]
     * @param {Number} [options.mediaDownloadTotalTime]
     * @param {Number} [options.mediaDownloadTotalCount]
     * @param {String} [options.playbackSessionId]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {SDK.Services.QualityOfService.NetworkType} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {Number} [options.liveLatencyAmount]
     * @param {SDK.Services.QualityOfService.PlaylistBitrateType} [options.playlistBitrateType]
     * @param {Number} [options.currentThroughput]
     * @param {Number} [options.cpuPercentage]
     * @param {Number} [options.freeMemory]
     * @param {Number} [options.seekableRangeEndProgramDateTime]
     * @param {Boolean} [options.isLiveEdge]
     * @param {Number} [options.artificialDelayDuration=0]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {SDK.Services.QualityOfService.HeartbeatSampleType} [options.sampleType]
     * @param {Number} [options.playlistAudioChannels]
     * @param {String} [options.playlistAudioCodec]
     * @param {String} [options.playlistAudioLanguage]
     * @param {String} [options.playlistAudioName]
     * @param {String} [options.playlistSubtitleLanguage]
     * @param {String} [options.playlistSubtitleName]
     * @param {Boolean} [options.subtitleVisibility=false]
     * @param {SDK.Services.QualityOfService.PresentationType} [options.presentationType=PresentationType.unknown]
     * @param {SDK.Services.QualityOfService.AdInsertionType} [options.adInsertionType=AdInsertionType.none]
     * @param {Boolean} [options.hasSlugs=false]
     * @param {String<SDK.Services.Media.SubscriptionType>|undefined} [options.subscriptionType]
     * @param {String|undefined} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData} [options.adSlotData]
     * @param {Number} [options.adPlayheadPosition]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {Boolean} [options.localMedia=false]
     * @param {String} [options.interactionId]
     * @param {Boolean} options.mediaFetchSucceeded
     * @param {SDK.Services.QualityOfService.AudioTrackType} [options.playlistAudioTrackType=AudioTrackType.unknown]
     * @param {SDK.Services.QualityOfService.TimedTextTrackType} [options.playlistTimedTextTrackType=TimedTextTrackType.unknown]
     * @param {Object} [options.qoe={}]
     * @param {Number} [options.maxAttainedBitrate=0]
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} [options.playbackMode]
     * @param {String} [options.viewingEnvironment]
     * @param {Number} [options.monotonicTimestamp]
     * @param {String} [options.programBoundaryInfoBlock]
     *
     */
    public constructor(options?: {
        playbackDuration?: number;
        playbackState?: Nullable<PlaybackState>;
        productType?: ProductType;
        totalVst?: number;
        videoStartTimestamp?: number;
        bufferSegmentDuration?: Nullable<number>;
        mediaBytesDownloaded?: Nullable<number>;
        mediaDownloadTotalTime?: Nullable<number>;
        mediaDownloadTotalCount?: Nullable<number>;
        playbackSessionId?: string;
        playheadPosition?: Nullable<number>;
        videoBitrate?: Nullable<number>;
        videoAverageBitrate?: Nullable<number>;
        audioBitrate?: number;
        maxAllowedVideoBitrate?: Nullable<number>;
        segmentPosition?: Nullable<number>;
        cdnName?: string;
        networkType?: NetworkType;
        liveLatencyAmount?: Nullable<number>;
        playlistBitrateType?: PlaylistBitrateType;
        currentThroughput?: Nullable<number>;
        cpuPercentage?: Nullable<number>;
        freeMemory?: Nullable<number>;
        seekableRangeEndProgramDateTime?: Nullable<number>;
        isLiveEdge?: Nullable<boolean>;
        artificialDelayDuration?: number;
        clientGroupIds?: string;
        serverGroupIds?: string;
        contentKeys?: Record<string, string>;
        data?: TodoAny;
        sampleType?: HeartbeatSampleType;
        playlistAudioChannels?: number;
        playlistAudioCodec?: string;
        playlistAudioLanguage?: string;
        playlistAudioName?: string;
        playlistSubtitleLanguage?: string;
        playlistSubtitleName?: string;
        subtitleVisibility?: boolean;
        presentationType?: PresentationType;
        adInsertionType?: AdInsertionType;
        hasSlugs?: boolean;
        subscriptionType?: string;
        adSessionId?: string;
        adPodPlacement?: AdPodPlacement;
        adPodData?: AdPodData;
        adSlotData?: AdSlotData;
        adPlayheadPosition?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
        localMedia?: boolean;
        interactionId?: string;
        mediaFetchSucceeded?: boolean;
        playlistAudioTrackType?: AudioTrackType;
        playlistTimedTextTrackType?: TimedTextTrackType;
        qoe?: Record<string, unknown>;
        maxAttainedBitrate?: Nullable<number>;
        playbackMode?: PlaybackMode;
        viewingEnvironment?: string;
        monotonicTimestamp?: number;
        programBoundaryInfoBlock?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackDuration: Types.number.optional,
                    playbackState: Types.in(PlaybackState).optional,
                    productType: Types.in(ProductType).optional,
                    totalVst: Types.number.optional,
                    videoStartTimestamp: Types.number.optional,
                    bufferSegmentDuration: Types.number.optional,
                    mediaBytesDownloaded: Types.number.optional,
                    mediaDownloadTotalTime: Types.number.optional,
                    mediaDownloadTotalCount: Types.number.optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    playlistBitrateType: Types.in(PlaylistBitrateType).optional,
                    currentThroughput: Types.number.optional,
                    cpuPercentage: Types.number.optional,
                    freeMemory: Types.number.optional,
                    seekableRangeEndProgramDateTime: Types.number.optional,
                    isLiveEdge: Types.boolean.optional,
                    artificialDelayDuration: Types.number.optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional,
                    sampleType: Types.in(HeartbeatSampleType).optional,
                    playlistAudioChannels: Types.number.optional,
                    playlistAudioCodec: Types.nonEmptyString.optional,
                    playlistAudioLanguage: Types.nonEmptyString.optional,
                    playlistAudioName: Types.nonEmptyString.optional,
                    playlistSubtitleLanguage: Types.nonEmptyString.optional,
                    playlistSubtitleName: Types.nonEmptyString.optional,
                    subtitleVisibility: Types.boolean.optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    hasSlugs: Types.boolean.optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    adPlayheadPosition: Types.number.optional,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    localMedia: Types.boolean.optional,
                    interactionId: Types.nonEmptyString.optional,
                    mediaFetchSucceeded: Types.boolean.optional,
                    playlistAudioTrackType: Types.in(AudioTrackType).optional,
                    playlistTimedTextTrackType:
                        Types.in(TimedTextTrackType).optional,
                    qoe: Types.object().optional,
                    maxAttainedBitrate: Types.number.optional,
                    playbackMode: Types.in(PlaybackMode).optional,
                    viewingEnvironment: Types.nonEmptyString.optional,
                    monotonicTimestamp: Types.number.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            playbackDuration,
            playbackState,
            productType,
            totalVst,
            videoStartTimestamp,
            bufferSegmentDuration,
            mediaBytesDownloaded,
            mediaDownloadTotalTime,
            mediaDownloadTotalCount,
            playbackSessionId,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            networkType,
            liveLatencyAmount,
            playlistBitrateType,
            currentThroughput,
            cpuPercentage,
            freeMemory,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            artificialDelayDuration,
            clientGroupIds,
            serverGroupIds,
            contentKeys,
            sampleType,
            data = {},
            playlistAudioChannels,
            playlistAudioCodec,
            playlistAudioLanguage,
            playlistAudioName,
            playlistSubtitleLanguage,
            playlistSubtitleName,
            subtitleVisibility,
            presentationType,
            adInsertionType,
            hasSlugs,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition,
            videoPlayerName,
            videoPlayerVersion,
            localMedia,
            interactionId,
            mediaFetchSucceeded,
            playlistAudioTrackType,
            playlistTimedTextTrackType,
            qoe = {},
            maxAttainedBitrate,
            playbackMode,
            viewingEnvironment,
            monotonicTimestamp,
            programBoundaryInfoBlock
        } = options || {};

        this.playbackDuration = Check.greaterOrEqual(playbackDuration, 0)
            ? playbackDuration
            : 0;

        this.playbackState = playbackState;
        this.productType = productType;

        this.totalVst = totalVst ?? 0;
        this.videoStartTimestamp = videoStartTimestamp ?? 0;
        this.bufferSegmentDuration = Math.ceil(bufferSegmentDuration ?? 0);

        this.mediaBytesDownloaded = mediaBytesDownloaded;
        this.mediaDownloadTotalTime = mediaDownloadTotalTime;
        this.mediaDownloadTotalCount = mediaDownloadTotalCount;
        this.playbackSessionId = playbackSessionId;

        this.playheadPosition = Math.ceil(playheadPosition ?? -1);
        this.videoBitrate = videoBitrate ?? 0;
        this.videoAverageBitrate = videoAverageBitrate ?? 0;
        this.audioBitrate = audioBitrate ?? 0;

        this.maxAllowedVideoBitrate = Check.assigned(maxAllowedVideoBitrate)
            ? maxAllowedVideoBitrate
            : 0;

        this.segmentPosition = Check.number(segmentPosition)
            ? Math.floor(segmentPosition)
            : undefined;

        this.cdnName = cdnName || 'null';
        this.networkType = networkType || NetworkType.unknown;

        this.liveLatencyAmount = Check.number(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : undefined;

        this.artificialDelay = Check.greater(artificialDelayDuration, 0);

        this.playlistBitrateType = playlistBitrateType;
        this.currentThroughput = currentThroughput;
        this.cpuPercentage = cpuPercentage;
        this.freeMemory = freeMemory;
        this.seekableRangeEndProgramDateTime = seekableRangeEndProgramDateTime;
        this.isLiveEdge = isLiveEdge;
        this.artificialDelayDuration = artificialDelayDuration ?? 0;
        this.clientGroupIds = clientGroupIds;
        this.serverGroupIds = serverGroupIds;
        this.contentKeys = contentKeys || {};
        this.sampleType = sampleType;
        this.playlistAudioChannels = playlistAudioChannels;
        this.playlistAudioCodec = playlistAudioCodec;
        this.playlistAudioLanguage = playlistAudioLanguage;
        this.playlistAudioName = playlistAudioName;
        this.playlistSubtitleLanguage = playlistSubtitleLanguage;
        this.playlistSubtitleName = playlistSubtitleName;
        this.subtitleVisibility = subtitleVisibility ?? false;
        this.presentationType = presentationType || PresentationType.unknown;
        this.adInsertionType = adInsertionType || AdInsertionType.none;
        this.hasSlugs = hasSlugs;
        this.subscriptionType = subscriptionType || '';
        this.adSessionId = adSessionId;
        this.adPodPlacement = adPodPlacement;
        this.adPodData = adPodData;
        this.adSlotData = adSlotData;
        this.adPlayheadPosition = adPlayheadPosition;
        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.localMedia = localMedia || false;
        this.interactionId = interactionId;
        this.periodType = 'deprecated';
        this.playlistAudioTrackType =
            playlistAudioTrackType || AudioTrackType.unknown;
        this.playlistTimedTextTrackType =
            playlistTimedTextTrackType || TimedTextTrackType.unknown;
        this.mediaFetchSucceeded = mediaFetchSucceeded ?? true;
        this.maxAttainedBitrate = maxAttainedBitrate ?? 0;
        this.playbackMode = playbackMode;
        this.viewingEnvironment = viewingEnvironment;
        this.monotonicTimestamp = monotonicTimestamp;
        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        this.setData(data);
        this.setData(qoe);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: object) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackHeartbeatEventData';
    }
}
