/**
 *
 * @module eligibilityClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/eligibility.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/eligibility.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import EligibilityClientConfiguration from './eligibilityClientConfiguration';
import EligibilityClientEndpoint from './eligibilityClientEndpoint';
import EligibilityStatusResponse from './eligibilityStatusResponse';
import Partner from './partner';
import LogTransaction from '../../logging/logTransaction';
import AccessToken from '../token/accessToken';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const EligibilityClientDustUrnReference =
    DustUrnReference.services.eligibility.eligibilityClient;

/**
 *
 * @access protected
 * @since 4.2.0
 * @desc Provides a data client that can be used to access eligibility services.
 *
 */
export default class EligibilityClient extends ClientBase<EligibilityClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Eligibility.EligibilityClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: EligibilityClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        EligibilityClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {Object} options
     * @param {String} [options.sku]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves eligibility information contextually sensitive to the environment and requester.
     * @returns {Promise<SDK.Services.Eligibility.EligibilityStatusResponse>}
     *
     */
    public async getEligibilityStatus(options: {
        sku?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sku: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getEligibilityStatus', params, arguments);
        }

        const { logger } = this;
        const { sku, accessToken, logTransaction } = options;

        const endpointKey = EligibilityClientEndpoint.getEligibilityStatus;
        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        if (Check.nonEmptyString(sku)) {
            payload.url = `${payload.url}?sku=${sku}`;
        }

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: EligibilityClientDustUrnReference.getEligibilityStatus,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            handleServiceResponseMethodName: 'getEligibilityStatus',
            resultMapper: (response) => {
                const { data } = response;
                const partners: Record<string, Partner> = {};

                Object.keys(data).forEach((partner) => {
                    partners[partner as string] = new Partner({
                        ...data[partner],
                        partner
                    });
                });

                const eligibilityStatusResponse = new EligibilityStatusResponse(
                    partners
                );

                return eligibilityStatusResponse;
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.2.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Eligibility.EligibilityClientEndpoint} options.endpointKey
     * @param {Object} [options.body]
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: EligibilityClientEndpoint;
        body?: object;
    }) {
        const { accessToken, endpointKey, body } = options;

        const endpoint = this.config.endpoints[endpointKey];
        const { href, headers, method } = endpoint as IEndpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Eligibility.EligibilityClient';
    }
}
