/**
 *
 * @module entry
 * @desc {SDK.UserActivity} entry point.
 *
 */

import UserActivityApi from './userActivityApi';
import EventSchema from './eventSchema';
import EventSchemata from './eventSchemata';
import EventSchemataProvider from './eventSchemataProvider';
import EventValue from './eventValue';
import Glimpse from './glimpse/entry';
import Personalization from './personalization/entry';
import { UserActivityEventCategory } from './enums';

/**
 *
 * @typedef {Object} SDK.UserActivity
 *
 * @property {UserActivityApi} UserActivityApi
 * @property {EventSchema} EventSchema
 * @property {EventSchemata} EventSchemata
 * @property {EventSchemataProvider} EventSchemataProvider
 * @property {EventValue} EventValue
 * @property {Glimpse} Glimpse
 * @property {Personalization} Personalization
 * @property {UserActivityEventCategory} UserActivityEventCategory
 *
 */
export default {
    UserActivityApi,
    EventSchema,
    EventSchemata,
    EventSchemataProvider,
    EventValue,
    Glimpse,
    Personalization,
    UserActivityEventCategory
};
