/**
 *
 * @module eventBufferMessageEnvelope
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import EventBufferBase from './eventBufferBase';
import EnvelopeMessageQueue from '../envelopeMessageQueue';
import MessageEnvelope from '../../socket/messageEnvelope';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';

export default class EventBufferMessageEnvelope extends EventBufferBase<MessageEnvelope> {
    /**
     *
     * @access private
     * @since 28.0.0
     * @type {EnvelopeMessageQueue}
     *
     */
    private envelopeMessageQueue: EnvelopeMessageQueue;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {String}
     * @desc The source urn to be used for the socket message envelope.
     *
     */
    private sourceUrn: string;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.bufferConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Internal.TelemetryClient} options.telemetryClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {Object} options.endpoint
     * @param {Object} options.prohibited
     * @param {Object} [options.fastTrack=null]
     * @param {SDK.DiagnosticFeature} [options.diagnosticFeature]
     * @param {EnvelopeMessageQueue} envelopeMessageQueue
     * @param {String} sourceUrn
     *
     */
    public constructor(
        options: Prettify<
            ConstructorParameters<typeof EventBufferBase>[0] & {
                envelopeMessageQueue: EnvelopeMessageQueue;
                sourceUrn: string;
            }
        >
    ) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    envelopeMessageQueue:
                        Types.instanceStrict(EnvelopeMessageQueue),
                    sourceUrn: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { envelopeMessageQueue, sourceUrn } = options;

        this.envelopeMessageQueue = envelopeMessageQueue;
        this.sourceUrn = sourceUrn;
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {Array<SDK.Socket.RawSocketMessage>} [options.messageEnvelopes]
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} [options.telemetryPayloads]
     * @param {Boolean} options.useProxy
     * @desc A helper to send dust events.
     *
     */
    public override async postDust(payload: Array<MessageEnvelope>) {
        const { accessToken, useProxy } = this;

        const messageEnvelopes = payload.map((envelope) => {
            const tokenAwareMessageEnvelope = {
                message: envelope,
                accessToken: accessToken.token
            };

            this.envelopeMessageQueue.trackMessage(tokenAwareMessageEnvelope);

            return envelope.getSocketMessage({
                source: this.sourceUrn
            });
        });

        const response = await this.client.postDustEnvelopes({
            accessToken,
            messageEnvelopes,
            useProxy
        });

        if (Array.isArray(response)) {
            const noop = () => {
                // TODO should we do something here?
                // no-op
            };

            response.forEach((message) => {
                this.envelopeMessageQueue.processAcknowledgment(
                    /* resendMessage() */ noop,
                    /* sendWithSessionAuthentication() */ noop,
                    message
                );
            });
        }

        return response;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @param {Number} [batchLimit]
     * @desc acquire messages from the queue to send and filters out non-dust events.
     *
     */
    public override getMessagesToSend(batchLimit?: number) {
        const messages =
            this.envelopeMessageQueue.getMessagesToSend(batchLimit);

        // TODO: when all socket messages can go to /envelope endpoint we should be able to remove this filter.
        // When sending non-dust events we'll get back an error
        // "status": "rejected.event-type-invalid",
        // this filter only allows dust events for now to avoid this error
        const dustOnlyMessages = messages.filter(
            (message) =>
                message.eventType ===
                DustUrnReference.socket.socketManager.event
        );

        return dustOnlyMessages;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @desc determines if any messages are available to be sent
     *
     */
    public override hasMessagesToSend() {
        return this.envelopeMessageQueue.messageLength >= this.minimumBatchSize;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @desc clears the queue
     *
     */
    public override clearQueue() {
        this.envelopeMessageQueue.clear();
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected override requeuePayloadItems(items: Array<MessageEnvelope>) {
        // no-op
        //
        // for now (since we're not using removing things from the
        // envelopeMessageQueue directly (will try to tie into the response
        // tracking for this instead)
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return `SDK.Internal.Telemetry.EventBufferMessageEnvelope`;
    }
}
