/**
 *
 * @module parseUrl
 *
 */

import { Check } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 21.0.0
 * @param {String} url - the URL to parse
 * @desc Parses the host and path components from a URL
 * @returns {{host: String|undefined, path: String|undefined}} An Object containing the host and path values
 *
 */
export default (url?: string) => {
    let host;
    let path;

    if (Check.nonEmptyString(url as string)) {
        const urlRegex = '^(.*:)//?([www.]+)*([A-Za-z0-9-._]+)(:[0-9]+)?(.*)$';
        const regex = new RegExp(urlRegex, 'g');
        const groups = regex.exec(url as string) as RegExpExecArray;

        if (Check.nonEmptyArray(groups)) {
            host = `${groups[1]}//${groups[3]}`;
            path = groups[5];
        }
    }

    return {
        host,
        path
    };
};
