/**
 *
 * @module bundleStatus
 *
 */

/**
 *
 * @enum
 * @since 4.2.0
 * @typedef {Object} SDK.Subscription.BundleStatus
 * @desc Defines the different status a bundle can be in.
 *
 * @property {String} bundle - The `bundle` field was true
 * @property {String} notBundle - The `bundle` field was false
 * @property {String} unknown - The `bundle` field was null
 *
 * @example
 * BundleStatus.bundle // 'bundle'
 *
 */
enum BundleStatus {
    bundle = 'bundle',
    notBundle = 'notBundle',
    unknown = 'unknown'
}

export default BundleStatus;
