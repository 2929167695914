/**
 *
 * @module userProfile
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access public
 * @since 3.5.0
 *
 */
export default class UserProfile {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public profileId: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public profileName: string;

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public attributes?: object;

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public metadata?: object;

    /**
     *
     * @param {Object} options
     * @param {String} options.profileId
     * @param {String} options.profileName
     * @param {Object} [options.attributes={}]
     * @param {Object} [options.metadata={}]
     *
     */
    public constructor(options: {
        profileId: string;
        profileName: string;
        attributes?: object;
        metadata?: object;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    profileId: Types.nonEmptyString,
                    profileName: Types.nonEmptyString,
                    attributes: Types.object().optional,
                    metadata: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            profileId,
            profileName,
            attributes = {},
            metadata = {}
        } = options;

        this.profileId = profileId;
        this.profileName = profileName;
        this.attributes = attributes;
        this.metadata = metadata;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Account.UserProfile';
    }
}
