/**
 *
 * @module configurationClientConfiguration
 *
 */

import type ConfigurationClientExtrasMap from './configurationClientExtrasMap';
import ServiceClientConfiguration from './serviceClientConfiguration';
import ServiceEndpoint from './serviceEndpoint';

/**
 *
 * @access protected
 * @desc A model containing the ConfigurationClient's configuration settings.
 *
 */
export default class ConfigurationClientConfiguration extends ServiceClientConfiguration<
    Record<string, ServiceEndpoint>,
    ConfigurationClientExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.ConfigurationClientConfiguration';
    }
}
