/**
 *
 * @module eventValue
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc An interface that can be implemented by application developers to provide dynamic values
 *
 */
export default class EventValue {
    /**
     *
     * @type {Function}
     * @desc stores a static value wrapped in a function or a reference to a function
     *
     */
    public value: (value: TodoAny) => TodoAny;
    /**
     *
     * @param {*} value
     * @note we do not do any type checking here since the value being passed in can be a function or any value
     *
     */
    public constructor(value: unknown) {
        this.value = Check.function(value)
            ? (value as () => typeof value)
            : () => value;
    }

    /**
     *
     * @access private
     * @param {Object} payload
     * @note this method returns the string stored in `this.value` or it executes the function stored in `this.value`
     * we pass the tracking parameters that were merged in `SDK.UserActivity.EventSchema` into this method and pass it
     * into the app developer provided function or it is ignored if `this.value` is a static value
     * @note this is marked `public` in the spec but the we invoke it in our implementation so it's marked `private`
     * @returns {Object}
     *
     */
    public generateValue(payload: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                payload: Types.object()
            };

            typecheck(this, 'generateValue', params, arguments);
        }

        return this.value(payload);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.EventValue';
    }
}
