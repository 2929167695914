/**
 *
 * @module playbackContext
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { PlaybackIntent, ProductType } from '../qualityOfService/enums';
import { MediaLocatorType } from '../../media/enums';

import uuidv4 from '../util/uuidv4';

/**
 *
 * @since 4.0.0
 *
 */
export default class PlaybackContext {
    /**
     *
     * @access public
     * @since 4.5.0
     * @type {String|undefined}
     * @desc The client-side generated unique ID representing a single element interaction within the container. The `interactionId`
     * will correspond with one of the defined `interactionTypes`. The `interactionId` will be used as the primary key for all interactions.
     * @note Map from the client provided value in the `constructor` of this `Object`.
     * @note Serialize as `interaction_id` for telemetry stream samples and `interactionId` for PQoE events.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Boolean}
     * @desc Indicates that the media is offline/previously downloaded.
     *
     */
    public offline: boolean;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String}
     * @desc The SDK generated unique ID for the playback session.
     * @note Generate a unique ID in the constructor of this object.
     *
     */
    public playbackSessionId: string;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {String<SDK.Services.QualityOfService.PlaybackIntent>}
     * @desc Indicates the intent which started playback.
     *
     */
    public playbackIntent: PlaybackIntent;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {SDK.Services.QualityOfService.ProductType|undefined}
     * @desc Indicates the type of product (Live or VOD).
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Boolean}
     * @desc Indicates that pre buffering is occurring if true (the video will be loaded before we are ready to show it).
     *
     */
    public isPreBuffering: boolean;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Object}
     * @desc A collection of associated content identifiers for the media, such as `contentId` and `mediaId`.
     *
     */
    public contentKeys: Record<string, string>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Object}
     * @desc Partner specific data to provide additional context for all playback events.
     *
     */
    public data: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {String<SDK.Media.MediaLocatorType>|undefined}
     * @desc The media locator type associated with this playback.
     *
     */
    public mediaLocatorType?: MediaLocatorType;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Number}
     * @description Time in milliseconds of some form of artificial delay that occurred during content start up i.e. NSA duration.
     * displaying the Negative Stereotype Advisory warning.
     *
     */
    public artificialDelayDuration: number;

    /**
     *
     * @access protected
     * @since 15.0.0
     * @type {Number|null}
     * @desc Time when playback was initially requested in milliseconds.
     * @note this value is set in `MediaApi.fetch()`
     *
     */
    public playbackRequestedAtTimestamp: Nullable<number>;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Object}
     * @desc Associated key-value pairs to be serialized with all QoE startup events. This should
     * include data such as the `playbackConfigVersions` received from the Playback Configuration Service (PCS).
     * @note This should be provided by the client via `SDK.Media.MediaApi.initializePlaybackContext()`.
     *
     */
    public startupContext: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {Boolean}
     * @desc An application override to explicitly disable the Playback QoE PlaybackSnapshot event.
     *
     */
    public disablePlaybackSnapshotEventsOverride: boolean;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {Boolean}
     * @desc A flag representing whether the `wpnx-playback-snapshot-events-enabled` orchestration feature flag was
     * present and contained a `true` value.
     *
     */
    public snapshotEventFeatureFlagEnabled: boolean;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {String|undefined}
     * @desc The binary version of the Playback Quality Manager (PQM) currently used in this playback session.
     *
     */
    public pqmGroupId?: string;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {String|undefined}
     * @desc Used in PQM to distinguish different client-side ABR algorithm experiment groups.
     *
     */
    public pqmVersion?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Boolean}
     * @desc Boolean used to determine if artificialDelayDuration should be used in the calculation of `totalVst`.
     * @note `isNsaIncludedInVst` does not exist in the SDK spec and is JS specific.
     * @note default value is `true`.
     *
     */
    public isNsaIncludedInVst: boolean;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String|undefined}
     * @desc Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
     * @note Conditionally required where `platformId` = `realitydevice`.
     *
     */
    public viewingEnvironment?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.interactionId]
     * @param {Boolean} [options.offline=false]
     * @param {String<SDK.Services.QualityOfService.PlaybackIntent>} options.playbackIntent
     * @param {String<SDK.Services.QualityOfService.ProductType>} options.productType
     * @param {Boolean} options.isPreBuffering
     * @param {Boolean} options.disablePlaybackSnapshotEventsOverride
     * @param {Boolean} options.snapshotEventFeatureFlagEnabled
     * @param {Number} [options.artificialDelayDuration=null]
     * @param {String} [options.pqmGroupId]
     * @param {String} [options.pqmVersion]
     * @param {Boolean} [options.isNsaIncludedInVst]
     * @param {Object} [options.startupContext={}]
     * @param {Object} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Media.MediaLocatorType>} [options.mediaLocatorType]
     * @param {String} [options.viewingEnvironment]
     *
     */
    public constructor(options: {
        interactionId?: string;
        offline?: boolean;
        playbackIntent: PlaybackIntent;
        productType: ProductType;
        isPreBuffering: boolean;
        disablePlaybackSnapshotEventsOverride: boolean;
        snapshotEventFeatureFlagEnabled: boolean;
        artificialDelayDuration?: Nullable<number>;
        pqmGroupId?: string;
        pqmVersion?: string;
        isNsaIncludedInVst?: boolean;
        startupContext?: Record<string, unknown>;
        contentKeys?: Record<string, string>;
        data?: Record<string, unknown>;
        mediaLocatorType?: MediaLocatorType;
        viewingEnvironment?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    interactionId: Types.nonEmptyString.optional,
                    offline: Types.boolean.optional,
                    playbackIntent: Types.in(PlaybackIntent),
                    productType: Types.in(ProductType),
                    isPreBuffering: Types.boolean,
                    disablePlaybackSnapshotEventsOverride: Types.boolean,
                    snapshotEventFeatureFlagEnabled: Types.boolean,
                    artificialDelayDuration: Types.number.optional,
                    pqmGroupId: Types.nonEmptyString.optional,
                    pqmVersion: Types.nonEmptyString.optional,
                    isNsaIncludedInVst: Types.boolean.optional,
                    startupContext: Types.object().optional,
                    contentKeys: Types.object(),
                    data: Types.object().optional,
                    mediaLocatorType: Types.in(MediaLocatorType).optional,
                    viewingEnvironment: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            interactionId,
            offline,
            playbackIntent,
            productType,
            isPreBuffering,
            disablePlaybackSnapshotEventsOverride,
            snapshotEventFeatureFlagEnabled,
            artificialDelayDuration,
            pqmGroupId,
            pqmVersion,
            isNsaIncludedInVst = true,
            startupContext,
            contentKeys,
            data,
            mediaLocatorType,
            viewingEnvironment
        } = options;

        this.interactionId = interactionId;
        this.offline = offline || false;
        this.playbackSessionId = uuidv4();
        this.playbackIntent = playbackIntent;
        this.productType = productType;
        this.isPreBuffering = isPreBuffering;
        this.contentKeys = contentKeys || {};
        this.data = data || {};
        this.artificialDelayDuration = artificialDelayDuration ?? 0;
        this.playbackRequestedAtTimestamp = null;
        this.startupContext = startupContext || {};
        this.disablePlaybackSnapshotEventsOverride =
            disablePlaybackSnapshotEventsOverride;
        this.snapshotEventFeatureFlagEnabled = snapshotEventFeatureFlagEnabled;
        this.pqmGroupId = pqmGroupId;
        this.pqmVersion = pqmVersion;
        this.isNsaIncludedInVst = isNsaIncludedInVst;
        this.mediaLocatorType = mediaLocatorType;
        this.viewingEnvironment = viewingEnvironment;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Sets values for `playbackRequestedAtTimestamp`.
     *
     */
    public setPlaybackRequestedAtTimestamp() {
        this.playbackRequestedAtTimestamp = Date.now();
    }

    /**
     *
     * @access protected
     * @since 27.0.0
     * @param {String<SDK.Media.MediaLocatorType>} mediaLocatorType
     * @desc Sets the `MediaLocatorType` once available.
     * @note SDKs should update the `PlaybackContext` with the `MediaLocatorType` when `MediaApi.fetch()` is called.
     *
     */
    public setMediaLocatorType(mediaLocatorType: MediaLocatorType) {
        this.mediaLocatorType = mediaLocatorType;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackContext';
    }
}
