/**
 *
 * @module mediaClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playhead.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/skynet/media-service/blob/master/docs/api/README.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/thumbnail.md
 * @see https://github.bamtech.co/playio/playback-orchestration-service
 *
 */

/* eslint-disable camelcase */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import handleServiceResponse from '../util/handleServiceResponse';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import DustCategory from '../internal/dust/dustCategory';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import MediaPayload from './mediaPayload';
import MediaPayloadStream from './mediaPayloadStream';
import MediaPlayhead from './mediaPlayhead';
import MediaClientEndpoint from './mediaClientEndpoint';
import MediaClientConfiguration from './mediaClientConfiguration';
import MediaPlaybackSelectionPayload from './mediaPlaybackSelectionPayload';
import PlaybackAttributes from './playbackAttributes';
import PlaybackContext from './playbackContext';
import PlaybackVariant from './playbackVariant';
import MediaThumbnailLink from './mediaThumbnailLink';
import MediaThumbnailLinks from './mediaThumbnailLinks';
import SpriteThumbnailSet from './spriteThumbnailSet';
import BifThumbnailSet from './bifThumbnailSet';
import Presentation from './presentation';
import LogTransaction from '../../logging/logTransaction';

import { MediaPlayheadStatus, PlaylistType, StreamingType } from './enums';

import {
    MediaFetchErrorMapping,
    QoePlaybackErrorMapping
} from './mediaFetchErrorMapping';

import {
    AdInsertionType,
    ApplicationContext,
    ErrorLevel,
    ErrorSource,
    NetworkType,
    PlaybackActivity,
    PlaybackExitedCause,
    StartupActivity,
    FetchStatus,
    HttpMethod as QosHttpMethod,
    NetworkError
} from '../qualityOfService/enums';

import PlaybackEventData from '../qualityOfService/playbackEventData';
import ErrorEventData from '../qualityOfService/errorEventData';
import PlaybackStartupEventData from '../qualityOfService/playbackStartupEventData';
import AccessToken from '../token/accessToken';

import { getDataVersion } from '../../media/qoeEventVersionInfo';
import MediaLocator from '../../media/mediaLocator';

import ServerRequest from '../qualityOfService/serverRequest';
import ServiceException from '../exception/serviceException';
import ExceptionReference from '../exception/exceptionReference';

import {
    AssetInsertionStrategy,
    MediaLocatorType,
    MediaAnalyticsKey
} from '../../media/enums';

import {
    AudioRendition,
    ClientDecisions,
    PlaybackRenditions,
    PlaybackRightsTypedef,
    ServerDecisions,
    SourceInfo,
    SubtitleRendition
} from './typedefs';

import {
    IMonotonicTimestampProvider,
    MonotonicTimestampProviderTypedef
} from '../../providers/typedefs';

import { HttpCoreMethod, IEndpoint } from '../providers/typedefs';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { DEFAULT_ZERO_GUID } from '../../constants';
import HttpStatus from '../util/errorHandling/httpStatus';
import { PLAYBACK_EXPERIENCE_CONTEXT } from '../providers/shared/httpHeaderConstants';
import {
    PlaybackRightsProgramBoundaryCheck,
    PlaybackRightsProgramBoundaryCheckTypedef
} from '../../media/typedefs';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const MediaClientDustUrnReference = DustUrnReference.services.media.mediaClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access media services.
 *
 */
export default class MediaClient extends ClientBase<MediaClientConfiguration> {
    /**
     *
     * @access private
     * @since 22.0.0
     * @type {IMonotonicTimestampProvider}
     * @desc The monotonic timestamp provider used to generate timestamps for events.
     *
     */
    private monotonicTimestampProvider: IMonotonicTimestampProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Media.MediaClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     * @throws {SDK.Services.Exception.InvalidArgumentException}
     *
     */
    public constructor(options: {
        config: MediaClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        monotonicTimestampProvider: IMonotonicTimestampProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(MediaClientConfiguration),
                logger: Types.instanceStrict(Logger),
                httpClient: Types.instanceStrict(CoreHttpClientProvider),
                monotonicTimestampProvider: Types.object(
                    MonotonicTimestampProviderTypedef
                )
            });

            typecheck(this, params, arguments);
        }

        this.monotonicTimestampProvider = options.monotonicTimestampProvider;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {Object} options
     * @param {SDK.Media.MediaLocator} options.mediaLocator - An object that provides a way to locate media.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {String} options.baseDeviceCapability - The base device capability used to construct the service request.
     * @param {SDK.Services.Media.MediaPlaybackSelectionPayload} options.playbackSelectionPayload
     * @param {SDK.Services.Media.PlaybackContext} [options.playbackContext]
     * @param {SDK.Services.Media.PlaylistType} options.preferredPlaylistType
     * @param {String} [options.qcPlaybackExperienceContext] - String provided by qc viewer team to switch bumpers for the playback experience.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves the MediaPayload from the media location using the supplied playback scenario.
     * @note we remap several items from the playlist service before constructing `SDK.Services.Media.MediaPayload`
     * @note qcPlaybackExperienceContext should replace the value of the optional `X-Bamtech-Playback-Experience-Context` header on media payload requests'
     * @returns {Promise<SDK.Services.Media.MediaPayload>}
     *
     */
    public async mediaPayload(options: {
        mediaLocator: MediaLocator;
        accessToken: AccessToken;
        baseDeviceCapability: string;
        playbackSelectionPayload: MediaPlaybackSelectionPayload;
        playbackContext?: PlaybackContext;
        preferredPlaylistType: PlaylistType;
        qcPlaybackExperienceContext?: string;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaLocator: Types.instanceStrict(MediaLocator),
                    accessToken: Types.instanceStrict(AccessToken),
                    baseDeviceCapability: Types.nonEmptyString,
                    playbackSelectionPayload: Types.instanceStrict(
                        MediaPlaybackSelectionPayload
                    ),
                    playbackContext:
                        Types.instanceStrict(PlaybackContext).optional,
                    preferredPlaylistType: Types.in(PlaylistType),
                    qcPlaybackExperienceContext: Types.string.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'mediaPayload', params, arguments);
        }

        const {
            mediaLocator,
            accessToken,
            baseDeviceCapability,
            playbackSelectionPayload,
            playbackContext,
            preferredPlaylistType,
            qcPlaybackExperienceContext,
            logTransaction
        } = options;

        const { httpClient, logger } = this;

        const { assetInsertionStrategy } =
            playbackSelectionPayload.playback.attributes;

        let endpointKey;
        let playbackUrl;

        if (mediaLocator.mediaLocatorType === MediaLocatorType.url) {
            endpointKey = MediaClientEndpoint.mediaPayloadV5;
            playbackUrl = mediaLocator.id;
        } else if (mediaLocator.mediaLocatorType === MediaLocatorType.v6Url) {
            endpointKey = MediaClientEndpoint.mediaPayloadV6;
            playbackUrl = mediaLocator.id;
        } else {
            endpointKey = MediaClientEndpoint.mediaPayload;
            playbackUrl = (this.config.endpoints[endpointKey] as IEndpoint)
                .href;
        }

        const url = playbackUrl.replace(/\{scenario\}/, baseDeviceCapability);

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: {
                ...playbackSelectionPayload
            },
            data: {
                qcPlaybackExperienceContext,
                url
            }
        });

        logger.info(
            this.toString(),
            `Sending post media payload request: ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.mediaPayload,
            payload,
            endpointKey,
            logTransaction
        });

        let mediaPayload;
        let fallbackQos;
        let fallbackQoe;
        let error;
        let qosDecision;
        let reasons;
        let errorMessage;
        let playlistFetchedStartTime;
        let qoeError;

        try {
            const response = await httpClient.request(payload);

            await handleServiceResponse({ response, dustLogUtility });

            ({ requestStartTime: playlistFetchedStartTime } = response);
            const { data } = response;
            const { stream, tracking, playhead, thumbnails } = data || {};

            // sets the `qoe` field for PBO v5
            if (!data?.tracking?.[MediaAnalyticsKey.qoe]) {
                if (mediaLocator.mediaLocatorType === MediaLocatorType.url) {
                    data.tracking[MediaAnalyticsKey.qoe] = {
                        pboVersion: 5
                    };
                }

                if (mediaLocator.mediaLocatorType === MediaLocatorType.v6Url) {
                    data.tracking[MediaAnalyticsKey.qoe] = {
                        pboVersion: 6
                    };
                }
            }

            const mediaPlayhead = this.parseMediaPlayhead(playhead);
            const mediaPayloadStream = this.parseMediaPayloadStream(stream);

            const mediaPayloadStreamSource = mediaPayloadStream.sources[0];

            let playlist;

            if (preferredPlaylistType === PlaylistType.COMPLETE) {
                playlist =
                    mediaPayloadStreamSource.complete ||
                    mediaPayloadStreamSource.slide;
                qosDecision = mediaPayloadStream.qosDecisions.complete;
            } else if (preferredPlaylistType === PlaylistType.SLIDE) {
                playlist =
                    mediaPayloadStreamSource.slide ||
                    mediaPayloadStreamSource.complete;
                qosDecision = mediaPayloadStream.qosDecisions.slide;
            }

            fallbackQos = playlist?.tracking?.qos ?? ({} as TodoAny);
            fallbackQoe = playlist?.tracking?.qoe ?? ({} as TodoAny);

            let mediaThumbnailLinks;

            if (Check.assigned(thumbnails)) {
                mediaThumbnailLinks = new MediaThumbnailLinks(thumbnails);
            }

            mediaPayload = {
                stream: mediaPayloadStream,
                tracking,
                playhead: mediaPlayhead,
                thumbnails: mediaThumbnailLinks
            };

            return new MediaPayload(mediaPayload);
        } catch (ex) {
            const exception = ex as ServiceException;

            reasons = exception.reasons || [];
            errorMessage = exception.data ? exception.data.message : undefined;

            error = this.constructErrorFromMediaPayloadException(exception);
            qoeError =
                this.constructQoeErrorFromMediaPayloadException(exception);

            throw exception;
        } finally {
            if (
                logger.dustEnabled &&
                Check.nonEmptyString(playbackContext?.playbackSessionId)
            ) {
                const qoeEventDataArray: Array<{
                    eventDataUrn: string;
                    eventData: TodoAny;
                }> = [];

                const {
                    playbackSessionId,
                    productType,
                    playbackIntent,
                    isPreBuffering,
                    contentKeys = {},
                    startupContext,
                    data: playbackContextData,
                    offline = false,
                    interactionId
                } = playbackContext as PlaybackContext;

                const { analyticsProvider } = logger;

                const attributes = mediaPayload?.stream.attributes;
                const playbackVariants = mediaPayload?.stream.variants ?? [];

                const variants = playbackVariants.map(
                    (item) => new PlaybackVariant(item)
                );

                const serverRequest =
                    this.getQosServerRequestData(dustLogUtility);

                const payloadQos = mediaPayload?.tracking?.qos ?? null;
                const qos = { ...payloadQos, ...fallbackQos };

                const payloadQoe = mediaPayload?.tracking?.qoe ?? null;
                const qoe = { ...payloadQoe, ...fallbackQoe };

                const {
                    clientDecisions = {} as ClientDecisions,
                    serverDecisions = {} as ServerDecisions
                } = qosDecision || {};

                const adInsertionType =
                    AdInsertionType[
                        (
                            assetInsertionStrategy as AssetInsertionStrategy
                        ).toLowerCase() as AdInsertionType
                    ];

                const adsQos = mediaPayload?.stream.adsQos ?? null;
                const insertion = mediaPayload?.stream.insertion ?? null;
                const monotonicTimestamp =
                    this.monotonicTimestampProvider.getTimestamp();

                let dictionaryVersion;
                let adSessionId;
                let subscriptionType = '';
                let totalPodCount = 0;
                let totalSlotCount = 0;
                let totalAdLength = 0;
                let createAdSessionResponseCode;
                let getPodsResponseCode;
                let data;

                if (analyticsProvider) {
                    const commonProperties =
                        analyticsProvider.getCommonProperties(
                            playbackSessionId
                        );

                    data = {
                        ...playbackContextData,
                        ...commonProperties
                    };

                    dictionaryVersion =
                        analyticsProvider.getDictionaryVersion(
                            playbackSessionId
                        );
                }

                if (adInsertionType !== AdInsertionType.none) {
                    adSessionId = adsQos?.adSession?.id ?? DEFAULT_ZERO_GUID;
                    subscriptionType = adsQos?.subscriptionType as string;
                    createAdSessionResponseCode =
                        adsQos?.adSession?.responseCode;

                    if (adInsertionType === AdInsertionType.ssai) {
                        getPodsResponseCode = adsQos?.getPods?.responseCode;
                    }
                }

                if (insertion?.points) {
                    totalPodCount = insertion.points.length;

                    if (adInsertionType === AdInsertionType.ssai) {
                        insertion.points.forEach((insertionPoint) => {
                            const { plannedSlotCount, duration } =
                                insertionPoint;

                            totalSlotCount = totalSlotCount
                                ? totalSlotCount + plannedSlotCount
                                : plannedSlotCount;
                            totalAdLength = totalAdLength
                                ? totalAdLength + duration
                                : duration;
                        });
                    }
                }

                const playbackStartupEventData = new PlaybackStartupEventData({
                    startupActivity: StartupActivity.fetched,
                    playbackSessionId,
                    productType,
                    networkType: NetworkType.unknown,
                    playbackIntent,
                    mediaPreBuffer: isPreBuffering,
                    playbackUrl: mediaLocator.id,
                    serverRequest,
                    playbackScenario: baseDeviceCapability,
                    attributes,
                    streamVariants: variants,
                    mediaFetchError: error,
                    clientGroupIds: clientDecisions.clientGroupIds,
                    serverGroupIds: serverDecisions.serverGroupIds,
                    contentKeys,
                    subscriptionType,
                    adSessionId,
                    totalPodCount,
                    totalSlotCount,
                    totalAdLength,
                    createAdSessionResponseCode,
                    getPodsResponseCode,
                    monotonicTimestamp,
                    adInsertionType,
                    data,
                    qos,
                    startupContext,
                    playlistFetchedStartTime,
                    playlistFetchedDuration: playlistFetchedStartTime
                        ? Date.now() - playlistFetchedStartTime
                        : undefined,
                    interactionId,
                    qoe
                });

                qoeEventDataArray.push({
                    eventDataUrn:
                        QualityOfServiceDustUrnReference.playbackStartup,
                    eventData: playbackStartupEventData
                });

                if (Check.assigned(error)) {
                    const errorEventData = new ErrorEventData({
                        applicationContext: ApplicationContext.player,
                        playbackSessionId,
                        isFatal: true,
                        source: ErrorSource.service,
                        errorName: qoeError,
                        errorLevel: ErrorLevel.error,
                        productType,
                        cdnName: serverRequest.cdnName ?? undefined,
                        dictionaryVersion,
                        underlyingSdkError: reasons?.[0],
                        errorMessage,
                        contentKeys,
                        clientGroupIds: clientDecisions.clientGroupIds,
                        serverGroupIds: serverDecisions.serverGroupIds,
                        adInsertionType,
                        subscriptionType,
                        data,
                        monotonicTimestamp,
                        localMedia: offline,
                        interactionId,
                        mediaFetchSucceeded: false,
                        qoe
                    });

                    const playbackEventData = new PlaybackEventData({
                        playbackActivity: PlaybackActivity.ended,
                        productType,
                        cdnName: serverRequest.cdnName ?? undefined,
                        cdnVendor: qos?.cdnVendor,
                        cdnWithOrigin: qos?.cdnWithOrigin,
                        cause: PlaybackExitedCause.error,
                        clientGroupIds: clientDecisions.clientGroupIds,
                        serverGroupIds: serverDecisions.serverGroupIds,
                        contentKeys,
                        data,
                        localMedia: offline,
                        interactionId,
                        mediaFetchSucceeded: false,
                        qoe
                    });

                    qoeEventDataArray.push({
                        eventDataUrn: QualityOfServiceDustUrnReference.error,
                        eventData: errorEventData
                    });
                    qoeEventDataArray.push({
                        eventDataUrn: QualityOfServiceDustUrnReference.playback,
                        eventData: playbackEventData
                    });
                }

                qoeEventDataArray.forEach((item) => {
                    const qosLogUtility = new DustLogUtility({
                        category: DustCategory.qoe,
                        logger,
                        source: this.toString(),
                        urn: item.eventDataUrn,
                        data: {
                            ...item.eventData
                        },
                        dataVersion: getDataVersion(item.eventDataUrn),
                        skipLogTransaction: true
                    });

                    qosLogUtility.log();
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a cookie based on the accessToken
     * @returns {Promise<Void>}
     *
     */
    public createAuthCookie(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createAuthCookie', params, arguments);
        }

        const { logger } = this;

        const endpointKey = MediaClientEndpoint.playbackCookie;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        }) as ReturnType<typeof this.getPayload> & { credentials: string };

        payload.credentials = 'include';

        logger.info(
            this.toString(),
            `Sending create auth cookie request: ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.createAuthCookie,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => response
        });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} options
     * @param {SDK.Services.Media.MediaThumbnailLink} options.thumbnailLink
     * @param {String} [options.resolution]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note overwrites each `presentation` field returned from the service after converting that `Object` into
     * an instance of `SDK.Services.Media.Presentation`
     * @returns {Promise<Array<SDK.Services.Media.SpriteThumbnailSet>>}
     *
     */
    public getSpriteSheetThumbnails(options: {
        thumbnailLink: MediaThumbnailLink;
        resolution?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailLink: Types.instanceStrict(MediaThumbnailLink),
                    resolution: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSpriteSheetThumbnails', params, arguments);
        }

        const { thumbnailLink, resolution, accessToken, logTransaction } =
            options;

        const { logger } = this;

        const url = this.createThumbnailUrl(thumbnailLink, resolution);

        const endpointKey = MediaClientEndpoint.spriteSheetThumbnails;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                url,
                headers: thumbnailLink.headers
            }
        });

        payload.method = thumbnailLink.method.toUpperCase() as HttpCoreMethod;

        logger.info(
            this.toString(),
            `request spritesheet thumbnails ${(thumbnailLink as TodoAny).url}`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getSpriteSheetThumbnails,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const { spritesheets } = data;

                if (Check.not.nonEmptyArray(spritesheets)) {
                    const thumbnailsNotAvailableException =
                        new ServiceException({
                            exceptionData:
                                ExceptionReference.media.thumbnailsNotAvailable
                        });

                    return Promise.reject(thumbnailsNotAvailableException);
                }

                const spriteThumbnailSets = spritesheets.map(
                    (spritesheet: TodoAny) => {
                        const presentations = spritesheet.presentations.map(
                            (presentation: Presentation) =>
                                new Presentation(presentation)
                        );

                        return new SpriteThumbnailSet({
                            ...spritesheet,
                            ...{ presentations }
                        });
                    }
                );

                return spriteThumbnailSets;
            }
        });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.Services.Media.Presentation} presentation
     * @param {Number} index
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note fetches the Byte[] version of the .jpeg file - unlikely this will be used on JS platforms as it generally
     * means saving the byte array to disk and loading into memory later on
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public getSpriteSheetThumbnail(
        presentation: Presentation,
        index: number,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                presentation: Types.instanceStrict(Presentation),
                index: Types.number,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getSpriteSheetThumbnail', params, arguments);
        }

        const { logger } = this;

        const endpointKey = MediaClientEndpoint.spriteSheetThumbnail;

        const payload = this.getPayload({
            endpointKey,
            data: {
                url: presentation.paths[index]
            },
            bodyType: 'arrayBuffer'
        });

        logger.info(
            this.toString(),
            `request spritesheet thumbnail ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getSpriteSheetThumbnail,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;

                return data;
            }
        });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} options
     * @param {SDK.Services.Media.MediaThumbnailLink} options.thumbnailLink
     * @param {String} [options.resolution]
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note overwrites each `presentation` field returned from the service after converting that `Object` into
     * an instance of `SDK.Services.Media.Presentation`
     * @returns {Promise<Array<SDK.Services.Media.BifThumbnailSet>>}
     *
     */
    public getBifThumbnails(options: {
        thumbnailLink: MediaThumbnailLink;
        resolution?: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    thumbnailLink: Types.instanceStrict(MediaThumbnailLink),
                    resolution: Types.nonEmptyString.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getBifThumbnails', params, arguments);
        }

        const { thumbnailLink, resolution, accessToken, logTransaction } =
            options;
        const { logger } = this;

        const url = this.createThumbnailUrl(thumbnailLink, resolution);

        const endpointKey = MediaClientEndpoint.bifThumbnails;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                url,
                headers: thumbnailLink.headers
            }
        });

        payload.method = thumbnailLink.method.toUpperCase() as HttpCoreMethod;

        logger.info(this.toString(), `request bif thumbnails ${url}`);

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.getBifThumbnails,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const { bifs } = data;

                if (Check.not.nonEmptyArray(bifs)) {
                    const thumbnailsNotAvailableException =
                        new ServiceException({
                            exceptionData:
                                ExceptionReference.media.thumbnailsNotAvailable
                        });

                    return Promise.reject(thumbnailsNotAvailableException);
                }

                const bifThumbnailSets = bifs.map((bif: TodoAny) => {
                    const presentations = bif.presentations.map(
                        (presentation: Presentation) =>
                            new Presentation(presentation)
                    );

                    return new BifThumbnailSet({
                        ...bif,
                        ...{ presentations }
                    });
                });

                return bifThumbnailSets;
            }
        });
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @note the JS SDK does not support this method
     * @returns {Promise<Void>}
     *
     */
    public async downloadBifThumbnail() {
        throw new Error(`${this}.downloadBifThumbnail() - not-implemented`);
    }

    /**
     *
     * @access public
     * @since 28.3.0
     * @param {Object} options
     * @param {SDK.Media.PlaybackRightsProgramBoundaryCheck} playbackRightsProgramBoundaryCheck - The airing to check for the media rights.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Checks for Playback Media Rights for program boundaries.
     * @returns {Promise<PlaybackRights>}
     *
     */
    public checkPlaybackRights(options: {
        playbackRightsProgramBoundaryCheck: PlaybackRightsProgramBoundaryCheck;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackRightsProgramBoundaryCheck: Types.object(
                        PlaybackRightsProgramBoundaryCheckTypedef
                    ),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'checkPlaybackRights', params, arguments);
        }

        const {
            playbackRightsProgramBoundaryCheck,
            accessToken,
            logTransaction
        } = options;

        const { logger } = this;

        const endpointKey =
            MediaClientEndpoint.checkProgramBoundaryPlaybackRights;

        const payload = this.getPayload({
            body: {
                ...playbackRightsProgramBoundaryCheck
            },
            accessToken,
            endpointKey
        }) as ReturnType<typeof this.getPayload> & { credentials: string };

        payload.credentials = 'include';

        logger.info(
            this.toString(),
            `Sending check for playback media rights request: ${payload.url}`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: MediaClientDustUrnReference.checkPlaybackRights,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PlaybackRightsTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} [stream={}] - playhead data object
     * @desc Constructs a SDK.Services.Media.MediaPlayhead instance based on the provided playhead data, returns null
     * if the data is not available. Please note, this method checks against the status property because
     * its guaranteed to exist if the playhead exists.
     * @returns {SDK.Services.Media.MediaPayloadStream}
     *
     */
    private parseMediaPayloadStream(stream?: MediaPayloadStream) {
        const {
            adsQos,
            sources = this.translateMediaPayloadV5(stream),
            insertion,
            editorial,
            attributes,
            variants,
            renditions,
            qosDecisions,
            streamingType = StreamingType.VOD // Playlist version 5 does not support streamingType and thus defaults to VOD
        } = stream || {};

        const playbackAttributes = PlaybackAttributes.parse(
            attributes as PlaybackAttributes
        );

        const playbackVariants = variants?.map((v) => new PlaybackVariant(v));

        const playbackRenditions = {
            audio: renditions?.audio.map((a: AudioRendition) => a) ?? [],
            subtitles:
                renditions?.subtitles.map((s: SubtitleRendition) => s) ?? []
        } as PlaybackRenditions;

        return new MediaPayloadStream({
            adsQos,
            sources,
            variants: playbackVariants ?? [],
            renditions: playbackRenditions,
            attributes: playbackAttributes,
            qosDecisions,
            streamingType,
            insertion,
            editorial
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} [stream={}] - playhead data object
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    private translateMediaPayloadV5(stream: TodoAny) {
        const { complete, slide } = stream || {};

        let sources: Array<SourceInfo> = [];

        const completeSources = this.translateSourceInfo(complete, 'complete');
        const slideSources = this.translateSourceInfo(slide, 'slide');

        if (completeSources.length >= slideSources.length) {
            sources = this.mergeSources(completeSources, slideSources);
        } else {
            sources = this.mergeSources(slideSources, completeSources);
        }

        return sources;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Array<Object>} sources
     * @param {Array<Object>} toMerge
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    private mergeSources(
        sources: Array<SourceInfo>,
        toMerge: Array<SourceInfo>
    ) {
        const mergedSources = sources.map((item) => {
            const foundElement = toMerge.find(
                (element) => element.priority === item.priority
            );

            return {
                ...item,
                ...foundElement
            };
        });

        return mergedSources;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Array<Object>} [source=[]]
     * @param {String} type
     * @desc Translates version 5 media payload structure to version 6
     * @returns {Array<Object>}
     *
     */
    private translateSourceInfo(source: Array<TodoAny>, type: string) {
        if (!source) {
            return [];
        }

        return source.map((sourceInfo) => {
            return {
                priority: sourceInfo.priority,
                [type]: {
                    url: sourceInfo.url,
                    tracking: sourceInfo.tracking
                }
            };
        });
    }

    /**
     *
     * @access private
     * @param {Object} [data={}] - playhead data object
     * @param {Number} [data.position] - The position of the playhead in the last session for the associated media.
     * @param {String} [data.last_updated] - The timestamp when the playhead was last recorded.
     * @param {String} data.last_modified - The timestamp when the playhead was last recorded (PBOv7).
     * @param {SDK.Services.Media.MediaPlayheadStatus} data.status
     * @desc Constructs a SDK.Services.Media.MediaPlayhead instance based on the provided playhead data.
     * @note This method checks against the status property because its guaranteed to exist if the playhead exists.
     * @note `last_updated` and `last_modified` are remapped to align with JS
     * and SDK conventions, services returns the variable names with underscores.
     * @todo remove the default for lastUpdated when services are settled
     * @returns {SDK.Services.Media.MediaPlayhead}
     *
     */
    private parseMediaPlayhead(data?: {
        position?: number;
        last_updated?: string;
        last_modified: string;
        status: string;
    }) {
        const { position, last_updated, last_modified, status } = data || {};

        const lastUpdated = last_updated || last_modified;

        const remap = {
            PlayheadFound: MediaPlayheadStatus.Success
        };

        const playheadStatus =
            MediaPlayheadStatus[status as keyof typeof MediaPlayheadStatus] ||
            remap[status as keyof typeof remap] ||
            MediaPlayheadStatus.Unavailable;

        const options = {
            position,
            lastUpdated,
            status: playheadStatus
        };

        return new MediaPlayhead(options);
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {SDK.QualityOfService.ServerRequest}
     *
     */
    private getQosServerRequestData(dustLogUtility: DustLogUtility) {
        if (Check.not.instanceStrict(dustLogUtility, DustLogUtility)) {
            return new ServerRequest();
        }

        const {
            roundTripTime,
            host,
            path,
            method = '',
            statusCode
        } = dustLogUtility.server;

        let networkError;
        let status = FetchStatus.completed;

        if ((statusCode as number) >= 400) {
            if (
                statusCode === HttpStatus.UNAUTHORIZED ||
                statusCode === HttpStatus.FORBIDDEN
            ) {
                networkError = NetworkError.prohibited;
            } else if ((statusCode as number) >= 500) {
                networkError = NetworkError.notConnected;
                status = FetchStatus.noNetwork;
            } else {
                networkError = NetworkError.unknown;
            }
        }

        return new ServerRequest({
            host,
            path,
            statusCode,
            roundTripTime,
            method: method.toLowerCase() as QosHttpMethod,
            status,
            error: networkError
        });
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Media.MediaThumbnailLink} thumbnailLink
     * @param {String} [resolution]
     * @returns {String}
     *
     */
    private createThumbnailUrl(
        thumbnailLink: MediaThumbnailLink,
        resolution?: string
    ) {
        const queryChar = thumbnailLink.href.includes('?') ? '&' : '?';
        const thumbnailResolution = resolution
            ? `resolution=${resolution}`
            : null;
        const queryString = thumbnailResolution
            ? `${queryChar}${thumbnailResolution}`
            : '';

        return `${thumbnailLink.href}${queryString}`;
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @param {SDK.Services.Exception.ServiceException} exception
     * @returns {SDK.Services.QualityOfService.MediaFetchError}
     *
     */
    private constructErrorFromMediaPayloadException(
        exception: ServiceException
    ) {
        return (
            MediaFetchErrorMapping[
                exception.name as keyof typeof MediaFetchErrorMapping
            ] || MediaFetchErrorMapping.default
        );
    }

    /**
     *
     * @access private
     * @since 27.0.0
     * @param {SDK.Services.Exception.ServiceException} exception
     * @returns {SDK.Services.QualityOfService.QoePlaybackError}
     *
     */
    private constructQoeErrorFromMediaPayloadException(
        exception: ServiceException
    ) {
        return (
            QoePlaybackErrorMapping[
                exception.name as keyof typeof QoePlaybackErrorMapping
            ] || QoePlaybackErrorMapping.default
        );
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken]
     * @param {SDK.Services.Media.MediaClientEndpoint} options.endpointKey - Endpoint to be referenced.
     * @param {Object} [options.data] - Additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {String} options.data.url - An Url override to construct the service request.
     * @param {Object} [options.data.headers] - Custom headers for a thumbnail service to be merged with headers from the config
     * @param {String} [options.data.qcPlaybackExperienceContext] - String provided by qc viewer team to switch bumpers for the playback experience.
     * @param {Object} [options.body] - Body to be serialized and passed with the request.
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken?: AccessToken;
        endpointKey: MediaClientEndpoint;
        data?: {
            url: string;
            headers?: Record<string, string>;
            qcPlaybackExperienceContext?: string;
        };
        body?: Record<string, unknown>;
        bodyType?: string;
    }) {
        const { accessToken, endpointKey, data, body, bodyType } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, optionalHeaders, method } = endpoint;

        const {
            url: dataUrl,
            headers: dataHeaders,
            qcPlaybackExperienceContext
        } = data || {};

        const url = dataUrl || href;

        const requestBody = body ? JSON.stringify(body) : undefined;

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken?.token
                    };
                },
                [PLAYBACK_EXPERIENCE_CONTEXT]: () => {
                    return {
                        replacer: '{qcPlaybackExperienceContext}',
                        value: qcPlaybackExperienceContext
                    };
                }
            },
            { ...dataHeaders, ...endpoint.headers },
            optionalHeaders
        );

        return {
            url,
            method,
            body: requestBody,
            bodyType,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Media.MediaClient';
    }
}
