/* eslint-disable import/no-unresolved, import/extensions */
import bamUrl from 'bam.url/url';

const url = bamUrl.Location(); //eslint-disable-line
const localeTestParam = url.getParam('locale');

//TODO: Should these come from an external data source? HB?
const countryCodeWhitelist = [
    'AS', //American Samoa
    'GU', //Guam
    'MP', //Northern Mariana Islands
    'PR', //Puerto Rico
    'VI', //Virgin Islands, U.S.
    'UM', //United States Minor Outlying Islands
    'US' //United States
];

function isUserInGeofence(code) {
    const countryCode = localeTestParam || code;

    return new Promise((resolve) => {
        const isUserGeoEnabled = !countryCode || countryCodeWhitelist.indexOf(countryCode.toUpperCase()) >= 0;
        resolve(isUserGeoEnabled);
    });
}

export default {
    isUserInGeofence
};
