/**
 *
 * @module playbackCustomEventData
 * @see https://helios.eds.us-east-1.bamgrid.net/events/playbackCustom/1.0.0
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 28.2.0
 * @description These events represent the playback custom event.
 * @note [Current Supported Version](https://helios.eds.us-east-1.bamgrid.net/events/playbackCustom/1.0.0)
 * @note urn:dss:event:client:playback:custom:v1
 * @note This event may not be implemented by all platforms and is not required.
 *
 */
export default class PlaybackCustomEventData {
    /**
     *
     * @access public
     * @since 28.2.0
     * @type {String}
     * @desc Client generated ID of the stream/playback session.
     *
     */
    public playbackSessionId: string;

    /**
     *
     * @access public
     * @since 28.2.0
     * @type {String}
     * @desc A json formatted string containing a collection of key-value pairs that provides additional diagnostic data about the playback session.
     * @note Source from the `Bam.Sdk.Media.PlayerAdapter` via the `PlaybackCustomEvent` event arguments.
     *
     */
    public playbackDiagnostics: string;

    /**
     *
     * @access public
     * @since 28.2.0
     * @type {String|undefined}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The interactionId will correspond with one of the defined interactionTypes. The interactionId will be used as the primary key for all interactions.
     * @note Source from `Bam.Sdk.Media.PlaybackContext`.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all playback events when `interactionId` is not null.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @access public
     * @since 28.2.0
     * @type {Object|undefined}
     * @desc Additional partner specific data provided by the `SDK.Logging.AnalyticsProvider` and `MediaApi.initializePlaybackContext`.
     * @note The properties provided in this should be flattened such that there is no `data` property but the information contained within it lives on the root of this class.
     * @note `activitySessionId`, `cpSessionId`, and `cpVideoIndex` are expected to be included within `data`.
     *
     */
    public data?: Record<string, unknown>;

    /**
     *
     * @param {Object} [options={}]
     * @param {String} options.playbackSessionId
     * @param {String} options.playbackDiagnostics
     * @param {String} [options.interactionId]
     * @param {String} [options.programBoundaryInfoBlock]
     * @param {Object} [options.data]
     *
     */
    public constructor(options: {
        playbackSessionId: string;
        playbackDiagnostics: string;
        interactionId?: string;
        programBoundaryInfoBlock?: string;
        data?: Record<string, unknown>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackSessionId: Types.nonEmptyString,
                    playbackDiagnostics: Types.nonEmptyString,
                    interactionId: Types.nonEmptyString.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional,
                    data: Types.object().optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        ({
            playbackSessionId: this.playbackSessionId,
            playbackDiagnostics: this.playbackDiagnostics,
            interactionId: this.interactionId,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock
        } = options);

        this.setData(options.data);
    }

    /**
     *
     * @access private
     * @since 28.2.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: object) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackCustomEventData';
    }
}
