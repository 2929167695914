/**
 *
 * @module coreHttpClientProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../../logging/logger';
import TokenUpdater from '../../tokenUpdater';
import InternalEvents from '../../../internalEvents';

import ExceptionReference from '../../exception/exceptionReference';
import HttpHeaders from './httpHeaders';
import { NodeEnv } from '../../../typedefs';

import {
    GetPayloadResult,
    HttpOptionsTypedef,
    HttpOptions,
    ServerResponse
} from '../typedefs';

import {
    AUTHORIZATION,
    SDK_REGION,
    REFRESH_ACCESS_TOKEN,
    REQUEST_ID
} from './httpHeaderConstants';

import guidRegex from '../../util/guidRegex';
import { createSimpleException } from '../../util/errorHandling/createException';

const TEST_RETRY_COUNT = 4;

type Response = {
    status: number;
    dataWrapper: TodoAny;
};

/**
 *
 * @access protected
 * @desc Core `HttpClient` provider implementation definition.
 * Acts as a base definition that is extended by platform specific implementations.
 *
 */
export default abstract class CoreHttpClientProvider {
    /**
     *
     * @access private
     * @type {Object}
     * @desc required options structure
     *
     */
    protected optionsBaseline: Record<string, unknown>;

    /**
     *
     * @access private
     * @type {String}
     * @desc message exposed if provided options don't meet baseline requirements
     *
     */
    private optionsMismatchError: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {TokenUpdater}
     *
     */
    private tokenUpdater: TokenUpdater;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private regionHeader: string;

    /**
     * @access private
     * @type {Number|undefined}
     */
    private testRetryCount?: number;

    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {TokenUpdater} tokenUpdater
     *
     */
    public constructor(logger: Logger, tokenUpdater: TokenUpdater) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger),
                tokenUpdater: Types.instanceStrict(TokenUpdater)
            };

            typecheck(this, params, arguments);
        }

        this.optionsBaseline = {
            url: ''
        };

        this.optionsMismatchError = 'Options do not match expected input';

        this.logger = logger;

        this.tokenUpdater = tokenUpdater;

        this.regionHeader = SDK_REGION;

        if (process.env.NODE_ENV === NodeEnv.Test) {
            this.testRetryCount = 0;
        }

        this.logger.log(this.toString(), 'Created Core Provider.');
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {GetPayloadResult} payload
     * @returns {Promise<Object>}
     *
     */
    public async request<T = TodoAny>(payload: GetPayloadResult) {
        return this.fetchRequest<T>({
            ...payload
        });
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} response
     * @param {String} [bodyType=null] - The expected response data type, executed after initial JSON attempt.
     * @param {Function} [preJsonParseProcessor] - A function to execute on the response data string before JSON parsing
     * @desc Reads the text off a HTTP response and tries to parse it as JSON otherwise fall back to text
     * @returns {Promise<Object>}
     *
     */
    // This override is needed because the implementation
    // in the child classes have a `bodyType` param
    public async getResponseData(
        response: { text: () => string },
        bodyType?: Nullable<string>,
        preJsonParseProcessor?: (data: string) => string
    ): Promise<{ data: TodoAny; dataType: string }>;

    public async getResponseData(
        response: { text: () => string },
        bodyType?: Nullable<string>,
        preJsonParseProcessor?: (data: string) => string
    ) {
        let data;
        let dataType = 'text';

        try {
            data = await response.text();
            dataType = 'text';

            if (preJsonParseProcessor) {
                this.logger.trace(
                    this.toString(),
                    'preJsonParseProcessor Before'
                );
                this.logger.trace(this.toString(), data);
                data = preJsonParseProcessor(data);
                this.logger.trace(
                    this.toString(),
                    'preJsonParseProcessor After'
                );
                this.logger.trace(this.toString(), data);
            }

            data = JSON.parse(data);
            dataType = 'json';
        } catch (error) {
            // no-op
        }

        return {
            data,
            dataType
        };
    }

    /**
     *
     * @access private
     * @param {Object} error
     * @param {Object} httpOptions - the original http `options` object used to generate a request
     * @returns {Promise<NetworkConnectionException>}
     * @desc Fetch API "will only reject on network failure or
     * if anything prevented the request from completing."
     * Therefore, reject a `NetworkConnectionException`. 4xx and 5xx responses
     * "will resolve normally (with ok status set to false)"
     * Handle them further down the Promise chain.
     *
     */
    private onError(error: Record<string, string>, httpOptions: HttpOptions) {
        if (process.env.NODE_ENV === NodeEnv.Test) {
            console.log('DEBUG: HTTP fetch options', httpOptions); // eslint-disable-line no-console
            console.error('DEBUG: Error', error); // eslint-disable-line no-console
        }

        const exceptionData = ExceptionReference.common.network;

        return Promise.reject(
            createSimpleException({
                code: error.name,
                description: error.message,
                exceptionData
            })
        );
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} options
     * @param {String} options.url
     * @param {SDK.Services.Configuration.HttpMethod} options.method a `HttpMethod` value (GET, POST, etc...)
     * @param {Object} options.headers
     * @param {Object} options.body
     * @desc to be overridden and provide the raw HTTP platform network call
     * @returns {Promise<Object>}
     *
     */
    protected abstract rawFetch(options: HttpOptions): Promise<TodoAny>;

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} options - that will get passed to the `HttpClient` specific implementation of `rawFetch`
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @param {Boolean} [retry=true] - when true will attempt to retry with a refreshed auth token if we receive a specific invalid token edge error
     * @desc Runs a standard HTTP fetch request but fails with a very specific error code from the edge service.
     * It will attempt to refresh the auth token and re-execute the fetch one more time.
     * @returns {Promise<Object>}
     *
     */
    // eslint-disable-next-line custom-rules/inferred-return-type
    protected async fetchRequest<T>(
        options: HttpOptions,
        retry = true
    ): Promise<ServerResponse<T>> {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object(HttpOptionsTypedef),
                retry: Types.boolean.optional
            };

            typecheck(this, params, arguments);
        }

        // //
        // // SAVE - for debugging purposes - can uncomment to generate curl's for failing test http requests for debugging.
        // //
        // try {
        //     const body = options.body
        //         ? JSON.stringify(options.body).replace(/\\"/g, '"')
        //         : undefined;
        //     const headers2 =
        //         options.headers &&
        //         options?.headers?.headers &&
        //         Object.keys(options?.headers?.headers)
        //             .map(
        //                 (key) =>
        //                     `-H '${key}: ${options.headers.headers?.[key]}'`
        //             )
        //             .join(' \\\n');
        //     const httpRequest = `curl -v '${options.url}' \\\n${headers2} ${
        //         body ? `\\\n--data-binary $'${body.slice(1).slice(0, -1)}'` : ''
        //     }`;
        //     console.log(httpRequest); // eslint-disable-line no-console, padding-line-between-statements
        // } catch (error) {
        //     console.log(error); // eslint-disable-line no-console
        // }

        if (
            process.env.NODE_ENV === NodeEnv.Test &&
            options.tuningOptions?.ignoreRequestIdValidation !== true
        ) {
            this.validateHeadersForRequestId(options.headers);
        }

        try {
            const requestStartTime = Date.now();

            const response = await this.rawFetch(options);

            const requestEndTime = Date.now();
            const requestDuration = requestEndTime - requestStartTime;

            const dataWrapper = await this.getResponseData(
                response,
                options.bodyType,
                options.preJsonParseProcessor
            );

            const { status, headers } = response;
            const { data } = dataWrapper;

            const refreshAccessToken = headers.get(REFRESH_ACCESS_TOKEN);

            const result = {
                url: options.url,
                status,
                headers,
                data,
                requestStartTime,
                requestEndTime,
                requestDuration
            };

            if (retry) {
                const shouldRefreshToken =
                    await this.isTokenInvalidatedBasedOnErrorResponse({
                        status,
                        dataWrapper
                    });

                if (shouldRefreshToken) {
                    await this.applyNewTokenToHeaders(
                        options.headers as HttpHeaders
                    );

                    return this.fetchRequest<T>(options, false);
                }

                // our integration tests can often fail due to an upstream timeout
                // let's re-play the request on a timeout (but only in test)...
                if (process.env.NODE_ENV === NodeEnv.Test) {
                    (this.testRetryCount as number)++;

                    if (
                        this.requestTimedOut({
                            status: response.status,
                            dataWrapper
                        })
                    ) {
                        /* eslint-disable no-console */
                        console.log('**************************************');
                        console.log('* TIMEOUT DETECTED RETRYING REQUEST...');
                        console.log('**************************************');
                        /* eslint-enable no-console */

                        return this.fetchRequest(
                            options,
                            (this.testRetryCount as number) < TEST_RETRY_COUNT
                        );
                    }
                }
            }

            if (refreshAccessToken === 'true') {
                // force refresh the access token if `X-BAMTech-Refresh-Access-Token` header is set to true.
                await this.tokenUpdater.refreshAccessToken({
                    forceRefresh: true,
                    reason: `${REFRESH_ACCESS_TOKEN} set to true.`
                });
            }

            return result;
        } catch (error) {
            return this.onError(error as Record<string, string>, options);
        }
    }

    private validateHeadersForRequestId(headers: HttpHeaders) {
        const requestId = headers.get(REQUEST_ID);

        if (!guidRegex(requestId)) {
            throw new Error(
                `${REQUEST_ID} is required to be a guid, actual value: ${requestId}`
            );
        }
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} response
     * @desc Attempts to parse the response and inspect it for a very specific type of 401
     * error code associated with the Edge service notifying about an invalid token
     * @returns {Boolean}
     *
     */
    private async isTokenInvalidatedBasedOnErrorResponse({
        status,
        dataWrapper
    }: Response) {
        const unauthorizedStatus = status === 401;
        const hasJSON = dataWrapper.dataType === 'json';

        // Edge service is not returning application/json content type
        // so we'll just try to pull JSON out of it regardless - if it works - cool
        if (unauthorizedStatus && hasJSON) {
            try {
                const firstError = dataWrapper?.data?.errors?.[0];
                const isErrorTokenInvalid =
                    firstError && firstError.code === 'access-token.invalid';
                const reasonsToCheck = ['auth.invalidated', 'auth.expired'];

                // Ideally we should only have to check the `description`
                // but original spec had `reason` so we go ahead and test both just in case.
                if (
                    isErrorTokenInvalid &&
                    (reasonsToCheck.includes(firstError.description) ||
                        reasonsToCheck.includes(firstError.reason))
                ) {
                    this.logger.warn(
                        'CoreHttpClientProvider',
                        `${firstError.code} found - going to re-try acquire token`
                    );

                    return true;
                }
            } catch (error) {
                this.logger.error(
                    'Error reading JSON response',
                    error,
                    dataWrapper.data
                );
            }
        }

        return false;
    }

    /**
     *
     * @access private
     * @since 21.0.0
     * @param {Object} response
     * @desc Attempts to parse the response and inspect it for a very specific type HTTP timeout code(s).
     * @returns {Boolean}
     *
     */
    private requestTimedOut({ status, dataWrapper }: Response) {
        // HTTP GATEWAY TIMEOUT
        if (status === 504) {
            return true;
        }

        const hasJSON = dataWrapper?.dataType === 'json';
        const hasErrors = dataWrapper?.data?.errors?.length;

        // Edge service is not returning application/json content type
        // so we'll just try to pull JSON out of it regardless - if it works - cool
        if (status === 200 && hasJSON && hasErrors) {
            try {
                const firstError = dataWrapper.data.errors[0];

                const errorCodeIsTimeout =
                    firstError.code === 'graph.upstream.timeout';
                const errorDescriptionLikeTimeout =
                    firstError.description &&
                    firstError.description.includes(
                        "upstream error with status '503'"
                    );

                if (errorCodeIsTimeout || errorDescriptionLikeTimeout) {
                    console.log('DEBUG: HTTP fetch options', status); // eslint-disable-line no-console
                    console.error('DEBUG: Error', firstError); // eslint-disable-line no-console

                    this.logger.warn(
                        'CoreHttpClientProvider',
                        `${firstError.code} found - going to re-try the request`
                    );

                    return true;
                }
            } catch (error) {
                this.logger.error(
                    'Error reading JSON response',
                    error,
                    dataWrapper.data
                );
            }
        }

        return false;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} headers
     * @desc Refreshes the current accessToken and replaces the Authentication header with the updated token.
     * @returns {Promise<Void>}
     *
     */
    private applyNewTokenToHeaders(headers: HttpHeaders) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve, reject) => {
            try {
                const forceRefresh = true;

                // The tokenUpdater.refreshAccessToken could potentially resolve in a failure
                // state so we catch that error and reject this promise before it actually
                // resolves to properly fail out of this effort.

                if (this.tokenUpdater && this.tokenUpdater.once) {
                    this.tokenUpdater.once(
                        InternalEvents.TokenRefreshFailed,
                        (error: unknown) => {
                            reject(error);
                        }
                    );
                }

                // force refresh of access token
                await this.tokenUpdater.refreshAccessToken({ forceRefresh });

                const authHeader = headers.get(AUTHORIZATION);
                const accessToken = this.tokenUpdater.getAccessToken();

                const token = accessToken?.token;

                if (token && authHeader) {
                    // This regex updates the Authorization token by replacing the following possible headers values
                    // 1. "Bearer TOKEN_HERE"
                    // 2. "bearer TOKEN_HERE"
                    // 3. "TOKEN_HERE"
                    headers.set(
                        AUTHORIZATION,
                        authHeader.replace(/([Bb]earer ?)?(.*)/, `$1${token}`)
                    );
                }

                resolve();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Shared.CoreHttpClientProvider';
    }
}
