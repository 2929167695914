/**
 *
 * @module reconnectMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';
import { RawSocketMessage } from '../typedefs';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.9.0
 * @desc Handles the reconnect socket message
 *
 */
export default class ReconnectMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageType = SocketUrns.socketManager.reconnect;

        super(messageType);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} messageData - reconnect message JSON object.
     *
     */
    public async process(messageData: RawSocketMessage) {
        this.socketManager.reconnect((messageData.data as TodoAny).region);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.ReconnectMessageProcessor';
    }
}
