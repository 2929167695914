/**
 *
 * @module entry
 * @desc {SDK.Subscription} entry point.
 *
 */

import BundleStatus from './bundleStatus';
import Product from './product';
import ProductEntitlement from './productEntitlement';
import ProductStatus from './productStatus';
import Subscription from './subscription';
import SubscriptionProvider from './subscriptionProvider';
import SubscriptionSource from './subscriptionSource';

/**
 *
 * @typedef {Object} SDK.Subscription
 *
 * @property {SDK.Subscription.BundleStatus} BundleStatus
 * @property {Product} Product
 * @property {ProductEntitlement} ProductEntitlement
 * @property {SDK.Subscription.ProductStatus} ProductStatus
 * @property {Subscription} Subscription
 * @property {SDK.Subscription.SubscriptionProvider} SubscriptionProvider
 * @property {SubscriptionSource} SubscriptionSource
 *
 */
export default {
    BundleStatus,
    Product,
    ProductEntitlement,
    ProductStatus,
    Subscription,
    SubscriptionProvider,
    SubscriptionSource
};
