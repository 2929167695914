/**
 *
 * @module drmClientExtrasMap
 *
 */

/**
 *
 * @access protected
 * @since 8.0.0
 *
 */
export default class DrmClientExtrasMap {
    /**
     *
     * @access public
     * @since 8.0.0
     * @type {Object}
     * @desc Gets or sets the licenseEndpoints.
     * @example
     * "licenseEndpoints": {
     *   "PLAYREADY": {
     *     "linear": "playReadyLicenseLinear",
     *     "vod": "playReadyLicense",
     *     "complete": "playReadyLicense"
     *   },
     *   "WIDEVINE": {
     *     "linear": "widevineLicenseLinear",
     *     "vod": "widevineLicense",
     *     "complete": "widevineLicense"
     *   }
     * }
     *
     */
    public licenseEndpoints: TodoAny;

    /**
     *
     * @param {Object} extras
     *
     */
    public constructor(extras: {
        licenseEndpoints: Record<
            string,
            {
                linear: string;
                vod: string;
                complete: string;
            }
        >;
    }) {
        const { licenseEndpoints } = extras;

        this.licenseEndpoints = licenseEndpoints;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Drm.DrmClientExtrasMap';
    }
}
