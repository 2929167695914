/**
 *
 * @module commercePlugin
 * @desc {SDK.Commerce.CommercePlugin} entry point.
 *
 */

import Commerce from './entry';
import CommerceService from '../services/commerce/entry';

import CommerceClient from '../services/commerce/commerceClient';

import CommerceApi from './commerceApi';

import ComcastApi from './comcast/comcastApi';
import IDealApi from './iDeal/iDealApi';
import KlarnaApi from './klarna/klarnaApi';
import MercadoApi from './mercado/mercadoApi';
import PaymentCardApi from './paymentCard/paymentCardApi';
import PayPalApi from './payPal/payPalApi';

import CommerceClientConfiguration from '../services/commerce/commerceClientConfiguration';
import CommerceClientExtrasMap from '../services/commerce/commerceClientExtrasMap';
import CommerceManagerExtrasMap from '../services/configuration/commerceManagerExtrasMap';

import BrowserInfoProvider from './browserInfoProvider';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Commerce', typeof Commerce, typeof CommerceService> = {
    pluginName: 'Commerce',
    entry: Commerce,
    service: CommerceService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;

        const {
            accessTokenProvider,
            environmentConfiguration,
            browserInfoProvider = new BrowserInfoProvider({ logger })
        } = sdkSession;

        const configExtras = CommerceManagerExtrasMap.parse(
            sdkSession.config.services.commerce.extras
        );

        const commerceClient = new CommerceClient({
            config: sdkSession.config.services.commerce,
            logger,
            httpClient: clients.httpClient,
            environmentConfiguration,
            browserInfoProvider
        });

        sdkSession.commerceApi = new CommerceApi({
            commerceClient,
            accessTokenProvider,
            configExtras,
            logger
        });

        sdkSession.commerce = {
            comcastApi: new ComcastApi({
                commerceClient,
                accessTokenProvider,
                logger
            }),
            iDealApi: new IDealApi({
                commerceClient,
                accessTokenProvider,
                logger
            }),
            klarnaApi: new KlarnaApi({
                commerceClient,
                accessTokenProvider,
                logger
            }),
            mercadoApi: new MercadoApi({
                commerceClient,
                accessTokenProvider,
                logger
            }),
            paymentCardApi: new PaymentCardApi({
                commerceClient,
                accessTokenProvider,
                logger
            }),
            payPalApi: new PayPalApi({
                commerceClient,
                accessTokenProvider,
                logger
            })
        };
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.commerce =
            new CommerceClientConfiguration({
                baseUrl: services.commerce.client.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services.commerce.client.endpoints
                ),
                extras: new CommerceClientExtrasMap({
                    clientId: services?.commerce?.client?.extras?.clientId,
                    namespaceId:
                        services?.commerce?.client?.extras?.namespaceId,
                    createPaymentMethodRegionalEndpoints:
                        services?.commerce?.client?.extras
                            ?.createPaymentMethodRegionalEndpoints
                })
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
