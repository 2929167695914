define([], function( ) {
	
	var exports = {};

	var QS_RX = new RegExp("([^&\\?]+?)=([^&]*)", "ig"), STRING = 'string', decode = decodeURIComponent,
		COERCE_VALUES = {'true':!0, 'false':!1, 'null':null};
		
	function cloneLocation(loc) {
		var locProps = ["constructor","hash","host","hostname","href","origin","pathname","port","protocol","search"];
		var locClone = {};
		for(var i=0,l=locProps.length;i<l;i++) {
		    locClone[locProps[i]] = (loc) ? loc[locProps[i]] : window.location[locProps[i]];
		}
		locClone.toString = function(){ return this.href; };
		return locClone;
	}
	
	/**
	* Enabler function to augment window.location with helper functions
	* Added functionality:
	* - getParam(str) - returns parameter value
	* - getParams() - returns and object of all key/value pairs
	* - isSecure() - return boolean if location is secure
	* - getDomain() - returns just a domain value (i.e. www.mlb.com -> mlb)
	*/
	exports.Location = function(loc) {
		//hack to clone location since window.location doesn't like Object.create
		loc = cloneLocation(loc);
		var queryString = loc.search || '', 
		    queryParameters = exports.parseQueryParameters(queryString);

		loc.getParam = function(name) {
			return ((name+'') in queryParameters)?queryParameters[name]:null;
		};
		loc.getParams = function( autoType ) {
			return exports.parseQueryParameters( queryString, autoType );
		};
		loc.isSecure = function() {
			return /^https/i.test( loc.protocol );
		};
		loc.getDomain = function() {
			var hParts = loc.hostname.split('.');
			return hParts[hParts.length-2];
		};
		return loc;
	};
	
	/**
	* Converts QueryString parameters to object
	*/
	exports.parseQueryParameters = function( str, autoType ) {
		str = str || window.location.search;
		autoType = autoType === undefined ? false : autoType;
		var params = {}, key, value;
		if(typeof(str)===STRING) {
			decode(str).replace(QS_RX, function() {
				key = arguments[1];
				value = autoType ? coerce(decode(arguments[2])) : decode(arguments[2]);
				if(key in params) {
					if(Array.isArray(params[key])) {
						params[key].push(value);
					} else {
						params[key] = [params[key], value];
					}
				} else {
					params[key] = value;
				}
			});
		}
		return params;
	};
	
	/**
	* Converts QueryString parameters to object
	*/
	exports.parseEncodedQueryParameters = function( str, autoType ) {
		str = str || window.location.search;
		autoType = autoType === undefined ? false : autoType;
		var params = {}, key, value;
		if(typeof(str)===STRING) {
			str.replace(QS_RX, function() {
				key = arguments[1];
				value = autoType ? coerce(decode(arguments[2])) : decode(arguments[2]);
				if(key in params) {
					if(Array.isArray(params[key])) {
						params[key].push(value);
					} else {
						params[key] = [params[key], value];
					}
				} else {
					params[key] = value;
				}
			});
		}
		return params;
	};
	
	/**
	*	Converts Frament Identifier (hash) parameters to object
	*/
	exports.parseFragmentIdentifiers = function( url, typeVals ) {
		url = url || window.location.hash;
		var str = url.substring(url.indexOf('#') + 1);
		return exports.parseQueryParameters(str, typeVals);
	};

	//Automatic coercian function
	function coerce(o){
		var iVal;
		if(typeof(o)===STRING) {
			iVal = !isNaN(o)?parseFloat(o):(o in COERCE_VALUES)?COERCE_VALUES[o]:o;
		}
		return iVal;
	}

	return exports;
			
});