/**
 *
 * @module httpStatus
 *
 */

/**
 *
 * @access public
 * @typedef {Object}
 * @since 28.0.0
 * @desc Enum that contains HTTP status code values.
 *
 * @property {Number} OK - Since `28.0.0` - HTTP status code `200`.
 * @property {Number} ACCEPTED - Since `28.0.0` - HTTP status code `202`.
 * @property {Number} BAD_REQUEST - Since `28.0.0` - HTTP status code `400`.
 * @property {Number} UNAUTHORIZED - Since `28.0.0` - HTTP status code `401`.
 * @property {Number} FORBIDDEN - Since `28.0.0` - HTTP status code `403`.
 * @property {Number} NOT_FOUND - Since `28.0.0` - HTTP status code `404`.
 * @property {Number} UNPROCESSABLE_CONTENT - Since `28.0.0` - HTTP status code `422`.
 * @property {Number} TOO_MANY_REQUESTS - Since `28.0.0` - HTTP status code `429`.
 * @property {Number} INTERNAL_SERVER_ERROR - Since `28.0.0` - HTTP status code `500`.
 * @property {Number} SERVICE_UNAVAILABLE - Since `28.0.0` - HTTP status code `503`.
 *
 */
enum HttpStatus {
    OK = 200,
    ACCEPTED = 202,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNPROCESSABLE_CONTENT = 422,
    TOO_MANY_REQUESTS = 429,
    INTERNAL_SERVER_ERROR = 500,
    SERVICE_UNAVAILABLE = 503
}

export default HttpStatus;
