/**
 *
 * @module consoleSink
 *
 */

/* eslint no-console: "off" */

import LogEvent from '../logEvent';
import LogLevel from '../logLevel';
import LogSink from '../logSink';

/**
 *
 * @desc Sink implementation that logs to the JavaScript console.
 *
 */
export default class ConsoleSink extends LogSink {
    /**
     *
     * @access protected
     * @param {LogLevel} logLevel
     *
     */
    public constructor(logLevel: LogLevel) {
        super(logLevel);
    }

    /**
     *
     * @access public
     * @param {LogEvent} logEvent - The event to be logged.
     * @desc prints a given {LogEvent} to the console; provides colored output to
     * help visually aid developers (if supported on a given platform)
     * @returns {Promise<Void>}
     *
     */
    public async log(logEvent: LogEvent) {
        if (logEvent.level < this.logLevel) {
            return;
        }

        const message = logEvent.toString();

        if (typeof console !== 'undefined') {
            console.log(message);
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Logging.Sinks.ConsoleSink';
    }
}
