/**
 *
 * @module entitlementClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/entitlement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import replaceHeaders from '../util/replaceHeaders';

import EntitlementClientConfiguration from './entitlementClientConfiguration';
import EntitlementClientEndpoint from './entitlementClientEndpoint';
import AccessToken from '../token/accessToken';
import LogTransaction from '../../logging/logTransaction';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { HttpCoreMethod } from '../providers/typedefs';

const EntitlementClientDustUrnReference =
    DustUrnReference.services.entitlement.entitlementClient;

/**
 *
 * @access protected
 * @since 4.9.0
 * @desc Provides a data client that can be used to access entitlement services.
 *
 */
export default class EntitlementClient extends ClientBase<EntitlementClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Entitlement.EntitlementClientConfiguration} options.entitlementClientConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: EntitlementClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        EntitlementClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {String} mediaId - The mediaId to verify account has sufficient entitlements to watch.
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns nothing upon successful verification
     * @returns {Promise<Void>}
     *
     */
    public async verifyMediaRights(
        mediaId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                mediaId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'verifyMediaRights', params, arguments);
        }

        const { logger } = this;

        const endpointKey = EntitlementClientEndpoint.verifyMediaRights;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                mediaId
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: EntitlementClientDustUrnReference.verifyMediaRights,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                return response;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Entitlement.EntitlementClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: EntitlementClientEndpoint;
        data?: {
            mediaId?: string;
        };
    }) {
        const { accessToken, endpointKey, data = {} } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { headers = {}, method } = endpoint || {};

        let href = endpoint?.href;

        if (endpoint?.templated && data.mediaId) {
            href = href?.replace(/\{mediaId\}/gi, data.mediaId);
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href as string,
            method: method as HttpCoreMethod,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Entitlement.EntitlementClient';
    }
}
