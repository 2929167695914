/**
 *
 * @module httpClientBase
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 */
import { Check } from '@dss/type-checking';

import CoreHttpClientProvider from '../shared/coreHttpClientProvider';

import HttpMethod from '../../configuration/httpMethod';
import TokenUpdater from '../../tokenUpdater';
import Logger from '../../../logging/logger';
import { HttpOptions } from '../typedefs';

/**
 *
 * @access protected
 * @desc Browser based HttpClient implementation.
 * @example
 * const httpClient = new HttpClient(logger, tokenUpdater);
 *
 */
export default class HttpClientBase extends CoreHttpClientProvider {
    private fetch: TodoAny;
    private Request: TodoAny;
    private Headers: TodoAny;

    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {TokenUpdater} tokenUpdater
     *
     */
    public constructor(options: {
        logger: Logger;
        tokenUpdater: TokenUpdater;
        fetch: TodoAny;
        Request: TodoAny;
        Headers: TodoAny;
    }) {
        const { logger, tokenUpdater, fetch, Request, Headers } = options;

        super(logger, tokenUpdater);

        this.fetch = fetch;
        this.Request = Request;
        this.Headers = Headers;

        this.logger.log(this.toString(), 'Created Browser Provider.');
    }

    /**
     *
     * @access private
     * @param {Object} [response={}] - The response Object.
     * @param {String} [bodyType=null] - The expected response data type, executed after initial JSON attempt
     * @param {Function} [preJsonParseProcessor] - A function to execute on the response data string before JSON parsing
     * @desc Returns a consistent Object containing status, headers,
     * and data (parsed JSON output). If the response cannot be parsed, provide the
     * response as a plain String
     * @returns {Promise<Object>}
     *
     */
    public override async getResponseData(
        response = {} as TodoAny,
        bodyType = null,
        preJsonParseProcessor?: (data: string) => string
    ) {
        const hasBodyType = Check.assigned(bodyType);
        const hasArrayBufferType = bodyType === 'arrayBuffer';
        const hasArrayBufferSupport = Check.assigned(response.arrayBuffer);

        if (hasBodyType && hasArrayBufferType && hasArrayBufferSupport) {
            return {
                data: await response.arrayBuffer(),
                dataType: 'arrayBuffer'
            };
        }

        return super.getResponseData(response, bodyType, preJsonParseProcessor);
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {String} options.url
     * @param {SDK.Services.Configuration.HttpMethod} options.method a `HttpMethod` value (GET, POST, etc...)
     * @param {Object} options.headers
     * @param {Object} options.body
     * @desc Executes the underlying HTTP network call.
     * @returns {Promise<Object>}
     *
     */
    protected override rawFetch(options: HttpOptions) {
        const { url, headers, method } = options;

        const methodUpperCase = method?.toUpperCase();

        // Some getPayload helpers might tack on a 'body' property but it
        // would be undefined for GET/HEAD and would throw an error in
        // native Request object inputs - so we just delete it.
        if (
            methodUpperCase === HttpMethod.GET ||
            methodUpperCase === HttpMethod.HEAD
        ) {
            delete options.body;
        }

        const request = new this.Request(url, {
            ...options,
            headers: new this.Headers(headers.headers)
        });

        return this.fetch(request);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Providers.Browser.HttpClientBase';
    }
}
