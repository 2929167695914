/**
 *
 * @module configurationManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/configuration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import LogTransaction from '../logging/logTransaction';

import CoreHttpClientProvider from '../services/providers/shared/coreHttpClientProvider';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import PlatformProviders from '../services/providers/platformProviders';
import { SDKSESSION_CONFIGURATION_KEY } from '../services/providers/shared/storageConstants';

import BootstrapConfiguration from '../services/configuration/bootstrapConfiguration';
import SdkSessionConfiguration from '../services/configuration/sdkSessionConfiguration';
import ConfigurationClient from '../services/configuration/configurationClient';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import PlatformProfileReference from '../services/configuration/platformProfileReference';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import ConfigurationClientConfiguration from '../services/configuration/configurationClientConfiguration';
import EnvironmentConfiguration from '../services/providers/browser/environmentConfiguration';

import EmbeddedConfiguration from './embeddedConfiguration';

import { REPLACE_NL_WS } from '../constants';
import type { SdkConfigRoot } from '../services/configuration/typedefs';

const ConfigurationManagerDustUrnReference =
    DustUrnReference.configuration.configurationManager;

/**
 *
 * @access protected
 * @desc Manages configuration information required to use the SDK.
 *
 */
export default class ConfigurationManager {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.BootstrapConfiguration}
     *
     */
    private bootstrapConfiguration: BootstrapConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {CoreHttpClientProvider}
     *
     */
    private httpClient: CoreHttpClientProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.EnvironmentConfiguration}
     *
     */
    public environmentConfiguration: EnvironmentConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    private configurationClientConfiguration: ConfigurationClientConfiguration;

    /**
     *
     * @access private
     * @type {ConfigurationClient}
     *
     */
    private client: ConfigurationClient;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     * @since 1.1.2
     *
     */
    private cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        bootstrapConfiguration: BootstrapConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        storage: CoreStorageProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bootstrapConfiguration: Types.instanceStrict(
                        BootstrapConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { bootstrapConfiguration, logger, httpClient, storage } = options;

        this.bootstrapConfiguration = bootstrapConfiguration;
        this.logger = logger;
        this.httpClient = httpClient;
        this.storage = storage;
        this.environmentConfiguration =
            ConfigurationManager.createEnvironmentConfiguration(
                this.bootstrapConfiguration
            );

        this.configurationClientConfiguration =
            ConfigurationManager.getClientConfiguration();

        this.client = new ConfigurationClient({
            config: this.configurationClientConfiguration,
            logger,
            httpClient
        });

        this.cacheKey = `
            ${SDKSESSION_CONFIGURATION_KEY}
            ${this.bootstrapConfiguration.clientId}_
            ${this.bootstrapConfiguration.environment}
        `.replace(REPLACE_NL_WS, '');

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Function} [options.getConfiguration]
     * @param {LogTransaction} options.logTransaction
     * @desc Retrieves the remote configuration, falls back to cached version if available.
     * @note We don’t really make separate builds for different envs, a default value for
     * bootstrapConfiguration.configHostName is set in BootstrapConfiguration
     * @returns {Promise<SDK.Services.Configuration.SdkSessionConfiguration>}
     *
     */
    public async getConfiguration(options: {
        getConfiguration?: () => Promise<SdkConfigRoot>;
        logTransaction: LogTransaction;
    }) {
        const { getConfiguration, logTransaction } = options;

        const { cacheKey, logger } = this;
        const { bootstrapConfiguration, environmentConfiguration } = this;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ConfigurationManagerDustUrnReference.getConfiguration,
            skipLogTransaction: true
        });

        logger.info(
            this.toString(),
            `Getting configuration:
            clientId: "${bootstrapConfiguration.clientId}",
            environment: "${bootstrapConfiguration.environment}",
            deviceFamily: "${environmentConfiguration.deviceFamily}",
            sdkVersion: "${environmentConfiguration.sdkVersion}",
            configVersion: "${environmentConfiguration.configVersion}",
            configHostName: "${bootstrapConfiguration.configHostName}",
            configHostOverride: "${bootstrapConfiguration.configHostOverride}",
            configHostUrlOverride: "${bootstrapConfiguration.configHostUrlOverride}"
        `.replace(/(\s(?=\s))/g, '')
        );

        try {
            let wasFetchedViaExternalGetter = true;

            const sdkSessionConfiguration =
                await this.getSdkSessionConfiguration({
                    getConfiguration:
                        getConfiguration ||
                        (async () => {
                            const result = await this.client.getConfiguration(
                                bootstrapConfiguration,
                                environmentConfiguration,
                                logTransaction
                            );

                            wasFetchedViaExternalGetter = false;

                            return result;
                        }),
                    logTransaction
                });

            sdkSessionConfiguration.wasFetchedViaExternalGetter =
                wasFetchedViaExternalGetter;

            logger.log(this.toString(), 'Successfully fetched configuration.');

            this.storage.remove(cacheKey);

            return sdkSessionConfiguration;
        } catch (ex) {
            logger.error(this.toString(), 'Unable to get configuration.');
            logger.log(
                this.toString(),
                `Loading from storage, cache key: "${cacheKey}".`
            );

            dustLogUtility.captureError(ex);

            return await this.getFallbackConfiguration(logTransaction);
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @param {Object} options
     * @param {Function} options.getConfiguration
     * @param {LogTransaction} options.logTransaction
     * @returns {Promise<SDK.Services.Configuration.SdkSessionConfiguration>}
     *
     */
    private async getSdkSessionConfiguration(options: {
        getConfiguration: () => Promise<SdkConfigRoot>;
        logTransaction: LogTransaction;
    }) {
        const { getConfiguration } = options;

        let config = await getConfiguration();

        config = this.postConfigProcess(config);

        return new SdkSessionConfiguration({
            ...config,
            environmentConfiguration: this.environmentConfiguration
        });
    }

    /**
     *
     * @since 28.0.0
     * @param {SdkConfigRoot} config
     * @desc Post config process hook implementation if the consuming api passed it in
     * @returns {SdkConfigRoot}
     *
     */
    private postConfigProcess(config: SdkConfigRoot) {
        return (
            this.bootstrapConfiguration.extras?.configExtensions?.postConfig?.(
                config
            ) || config
        );
    }

    /**
     *
     * @access private
     * @param {LogTransaction} logTransaction
     * @returns {Promise<SDK.Services.Configuration.SdkSessionConfiguration>}
     *
     */
    public async getFallbackConfiguration(logTransaction: LogTransaction) {
        const fallbackConfiguration = new EmbeddedConfiguration({
            isFallback: true
        });
        const configurationClient = new ConfigurationClient({
            config: fallbackConfiguration.client,
            logger: this.logger,
            httpClient: this.httpClient
        });

        this.logger.log(this.toString(), 'Fetching fallback configuration.');

        const config = await configurationClient.getConfiguration(
            this.bootstrapConfiguration,
            this.environmentConfiguration,
            logTransaction
        );

        const sdkSessionConfiguration = new SdkSessionConfiguration({
            ...config,
            environmentConfiguration: this.environmentConfiguration
        });

        return sdkSessionConfiguration;
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Configuration.BootstrapConfiguration} bootstrapConfiguration
     * @returns {SDK.Services.Configuration.EnvironmentConfiguration}
     *
     */
    public static createEnvironmentConfiguration(
        bootstrapConfiguration: BootstrapConfiguration
    ) {
        const { platform, extras } = bootstrapConfiguration;

        let platformProfile = extras?.configExtensions?.platformOverride;

        if (!platformProfile && platform) {
            platformProfile =
                PlatformProfileReference[
                    platform as keyof typeof PlatformProfileReference
                ];
        }

        // allow the app teams to override the applicationRuntime in the case of a "generic" config
        const applicationRuntime =
            extras?.configExtensions?.configTokenReplacements?.platformId ||
            platformProfile?.applicationRuntime;

        const applicationRuntimeConfigSegment =
            extras?.configExtensions?.platformOverride?.applicationRuntime ||
            platformProfile?.applicationRuntime;

        const options = {
            applicationVersion: bootstrapConfiguration.application.version,
            applicationRuntimeConfigSegment,
            ...platformProfile,
            applicationRuntime
        };

        return new PlatformProviders.EnvironmentConfiguration(options);
    }

    /**
     *
     * @access public
     * @returns {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    public static getClientConfiguration() {
        const embeddedConfiguration = new EmbeddedConfiguration({
            isFallback: false
        });

        return embeddedConfiguration.client;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Configuration.ConfigurationManager';
    }
}
