/**
 *
 * @module eventListenerProvider
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import PlayerAdapter from './playerAdapter';
import Logger from '../logging/logger';

/**
 *
 * @since 29.0.0
 * @desc Facilitates and generalizes the adding and removing of player event listeners.
 *
 */
export default class EventListenerProvider {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Function}
     *
     */
    protected onHandler: TodoAny;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Function|undefined}
     *
     */
    protected onceHandler?: TodoAny;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Function}
     *
     */
    protected offHandler: TodoAny;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {Function} options.onHandler
     * @param {Function} [options.onceHandler]
     * @param {Function} options.offHandler
     * @param {Logger} options.logger
     *
     */
    public constructor(options: {
        onHandler: TodoAny;
        onceHandler?: TodoAny;
        offHandler: TodoAny;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    onHandler: Types.function,
                    onceHandler: Types.function.optional,
                    offHandler: Types.function,
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { onHandler, onceHandler, offHandler, logger } = options;

        this.onHandler = onHandler;
        this.onceHandler = onceHandler;
        this.offHandler = offHandler;
        this.logger = logger;
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @param {Object} options
     * @param {PlayerAdapter} options.playerAdapter
     * @param {String} options.eventName
     * @param {TodoAny} options.handler
     * @param {TodoAny} options.onHandler
     * @param {TodoAny} options.offHandler
     * @desc Commong logic for adding an event handler that persists or that only should be handled once.
     * @returns {Function|undefined} The event handler bound to the PlayerAdapter's 'this'.
     *
     */
    private addHandler(options: {
        playerAdapter: PlayerAdapter;
        eventName: string;
        handler: TodoAny;
        onHandler: TodoAny;
        offHandler: TodoAny;
    }) {
        const { playerAdapter, eventName, handler, onHandler, offHandler } =
            options;
        const { offHandlerRemovalList } = playerAdapter;

        if (!eventName || Check.emptyString(eventName)) {
            return undefined;
        }

        let boundHandler: TodoAny;

        if (Check.function(onHandler)) {
            boundHandler = handler.bind(playerAdapter);
            onHandler.call(playerAdapter.nativePlayer, eventName, boundHandler);
        } else {
            this.logger.error(
                this.toString(),
                'The nativePlayer is missing a ".on(...)" or ".once(...)" event notification utility.'
            );
        }

        offHandlerRemovalList.push(() => {
            offHandler.call(
                playerAdapter.nativePlayer,
                eventName,
                boundHandler
            );
        });

        return boundHandler;
    }

    /**
     *
     * @access public
     * @since 29.0.0
     * @param {PlayerAdapter} playerAdapter
     * @param {String} eventName
     * @param {TodoAny} handler
     * @desc Attaches a single handler to a player event and adds to the removal list.
     * @returns {Function|undefined} The event handler bound to the PlayerAdapter's 'this'.
     *
     */
    public addEventHandler(
        playerAdapter: PlayerAdapter,
        eventName: string,
        handler: TodoAny
    ) {
        const { onHandler, offHandler } = this;

        return this.addHandler({
            playerAdapter,
            eventName,
            handler,
            onHandler,
            offHandler
        });
    }

    /**
     *
     * @access public
     * @since 29.0.0
     * @param {PlayerAdapter} playerAdapter
     * @param {String} eventName
     * @param {TodoAny} handler
     * @desc Attaches a single handler to a player event that only listens for the first time it fires.
     * @returns {Function|undefined} The event handler bound to the PlayerAdapter's 'this'.
     *
     */
    public addEventHandlerOnce(
        playerAdapter: PlayerAdapter,
        eventName: string,
        handler: TodoAny
    ) {
        const { onceHandler, offHandler } = this;

        return this.addHandler({
            playerAdapter,
            eventName,
            handler,
            onHandler: onceHandler,
            offHandler
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.EventListenerProvider';
    }
}
