/**
 *
 * @module subscriptionClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/subscription.md
 * @see https://github.bamtech.co/services-commons/api-docs/tree/master/v3.0.0-subs-api
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/subscription.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import replaceHeaders from '../util/replaceHeaders';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import SubscriptionClientEndpoint from './subscriptionClientEndpoint';
import SubscriptionClientConfiguration from './subscriptionClientConfiguration';

import appendQuerystring from '../util/appendQuerystring';

import {
    Subscription as SubscriptionV2,
    SubscriptionTypedef,
    SubscriberInfoTypedef,
    Term
} from '../subscriber/typedefs';

import AccessToken from '../token/accessToken';
import { SubscriptionState } from '../subscriber/enums';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const SubscriptionClientDustReference =
    DustUrnReference.services.subscription.subscriptionClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access subscription services.
 *
 */
export default class SubscriptionClient extends ClientBase<SubscriptionClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Subscription.SubscriptionClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: SubscriptionClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(SubscriptionClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets a list of subscriptions associated with the provided AccessToken.
     * @returns {Promise<Array<Subscription>>}
     *
     */
    public getSubscriptions(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getSubscriptions', params, arguments);
        }

        const { logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getSubscriptions;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(this.toString(), 'Attempting to get subscriptions.');

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getSubscriptions,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;

                return Promise.resolve(data);
            }
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Associates the subscriptions from the device to the account, which are both defined in the AccessToken.
     * @returns {Promise<Object>}
     *
     */
    public linkSubscriptionsFromDevice(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'linkSubscriptionsFromDevice', params, arguments);
        }

        const { logger } = this;
        const endpointKey =
            SubscriptionClientEndpoint.linkSubscriptionsFromDevice;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to link subscriptions from device.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.linkSubscriptionsFromDevice,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => response
        });
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {Object} options
     * @param {SDK.Services.Subscriber.SubscriptionState} [options.state] - When supplied, only returns subscriptions
     * with this status. If not supplied, returns all subscriptions except churned.
     * @param {Boolean} [options.includeChurned] - When supplied, indicates whether churned subscriptions should be returned. Default is false.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Returns subscriber's subscription information.
     * @returns {Promise<Object<SDK.Services.Subscriber.SubscriberInfo>>}
     *
     */
    public getSubscriberInfo(options: {
        state?: SubscriptionState;
        includeChurned?: boolean;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    state: Types.in(SubscriptionState).optional,
                    includeChurned: Types.boolean.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSubscriberInfo', params, arguments);
        }

        const { state, includeChurned, accessToken, logTransaction } = options;
        const { logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getSubscriberInfo;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        let href = payload.url as string;

        if (Check.assigned(state)) {
            href = appendQuerystring(href, `state=${state}`);
        }
        if (Check.assigned(includeChurned)) {
            href = appendQuerystring(href, `includeChurned=${includeChurned}`);
        }

        payload.url = href;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getSubscriberInfo,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            handleServiceResponseMethodName: 'getSubscriberInfo',
            resultMapper: (response) => {
                const { data } = response;

                data.subscriptions.forEach((subscription: TodoAny) => {
                    const term = subscription.term;

                    this.normalizeTerm(term);
                });

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(SubscriberInfoTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            }
        });
    }

    /**
     *
     * @access public
     * @since 4.13.0
     * @param {String} subscriptionId - The subscription identifier.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Returns a subscription by id.
     * @returns {Promise<Object<SDK.Services.Subscriber.Subscription>>}
     *
     */
    public getAccountSubscription(
        subscriptionId: string,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                subscriptionId: Types.nonEmptyString,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getAccountSubscription', params, arguments);
        }

        const { logger } = this;
        const endpointKey = SubscriptionClientEndpoint.getAccountSubscription;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        payload.url = payload.url.replace(
            /\{subscriptionId\}/gi,
            subscriptionId
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: SubscriptionClientDustReference.getAccountSubscription,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            handleServiceResponseMethodName: 'getAccountSubscription',
            resultMapper: (response) => {
                const { data } = response;
                const term = data.term;

                this.normalizeTerm(term);

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(SubscriptionTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data as SubscriptionV2;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {String<SDK.Services.Subscription.SubscriptionClientEndpoint>} options.endpointKey
     * @param {Object} options.body
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: SubscriptionClientEndpoint;
        body?: object;
    }) {
        const { accessToken, endpointKey, body } = options;
        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { headers, href, method } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     * @since 4.17.0
     * @param {Object<SDK.Services.Subscriber.Term>} term
     * @returns {Void}
     *
     */
    private normalizeTerm(term: Term) {
        if (term) {
            const processDate = (date?: Date) => {
                return date ? new Date(date) : date;
            };

            term.purchaseDate = processDate(term.purchaseDate);
            term.startDate = processDate(term.startDate);
            term.expiryDate = processDate(term.expiryDate);
            term.nextRenewalDate = processDate(term.nextRenewalDate);
            term.pausedDate = processDate(term.pausedDate);
            term.churnedDate = processDate(term.churnedDate);
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Subscription.SubscriptionClient';
    }
}
