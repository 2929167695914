/**
 *
 * @module grant
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access public
 * @desc Provides a base implementation for a grant.
 *
 */
export default class Grant {
    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc Gets or sets the grant type. This must be a valid type that is recognized by the service.
     *
     */
    public grantType?: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the packet of security information for the token.
     *
     */
    public assertion: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the subject token type key used to lookup
     * the `subject_token_type` value needed during token exchange.
     * @note for lookup purposes only, should not be included in a request body.
     *
     */
    public readonly subjectTokenTypeKey: string;

    /**
     *
     * @param {String} assertion
     * @param {String} [grantType]
     *
     */
    public constructor(assertion: string, grantType?: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                assertion: Types.nonEmptyString,
                grantType: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.grantType = grantType;
        this.assertion = assertion;

        this.subjectTokenTypeKey = '';
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Token.Grant';
    }
}
