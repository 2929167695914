/**
 *
 * @module versionInfo
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    versionRegExp,
    getVersionShort
} from './_utils/bam-browser-sdk-configuration-util';

let version: string;
let versionShort: string;

/**
 *
 * @access public
 * @since 4.14.0
 * @desc An object with the SDK version information.
 *
 *
 */
export default class VersionInfo {
    /**
     *
     * @access public
     * @since 4.14.0
     * @desc Returns the current full version EX: `7.0.0` or `7.0.0-beta.1`
     * @returns {String}
     *
     */
    public static get version() {
        return version;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @desc Returns the current short version EX: `7.0`
     * @returns {String}
     *
     */
    public static get versionShort() {
        return versionShort;
    }

    /**
     *
     * @access private
     * @since 5.0.0
     * @param {String} input - Semantic version string to parse ex: `17.0.0` or `17.0.0-beta.1`
     * @desc parses and sets up the internal use `version` and `versionShort` module scoped variables associated with the current build of the SDK.
     *
     */
    public static setVersion(input: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                input: Types.match(versionRegExp)
            };

            typecheck(VersionInfo, 'setVersion', params, arguments);
        }

        version = input;
        versionShort = getVersionShort(version);
    }
}

// Be sure to initialize on initial load...
VersionInfo.setVersion(process.env.SDK_VERSION as string);
