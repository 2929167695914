/**
 *
 * @module entry
 * @desc {SDK.Commerce} entry point.
 *
 */

import BrowserInfoProvider from './browserInfoProvider';

/**
 *
 * @typedef {Object} SDK.Commerce
 *
 * @property {BrowserInfoProvider} BrowserInfoProvider
 *
 */
export default {
    BrowserInfoProvider
};
