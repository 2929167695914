/**
 *
 * @module externalActivationClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.ExternalActivation.ExternalActivationClientEndpoint
 *
 * @property {String} activateToken
 * @property {String} activateBundle - Since `4.4.0`.
 * @property {String} getActivationToken
 * @property {String} generateActivationToken - Since `14.1.0`.
 *
 */
enum ExternalActivationClientEndpoint {
    activateToken = 'activateToken',
    activateBundle = 'activateBundle',
    getActivationToken = 'getActivationToken',
    generateActivationToken = 'generateActivationToken'
}

export default ExternalActivationClientEndpoint;
