/**
 *
 * @module loggingApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './logger';
import AnalyticsProvider from './analyticsProvider';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import { HttpCoreMethod } from '../services/providers/typedefs';

/**
 *
 * @access public
 * @desc Manages information that is used for logging purposes.
 *
 */
export default class LoggingApi {
    /**
     *
     * @access private
     * @since 4.8.0
     * @type {Boolean}
     *
     */
    private permitAppDustEvents: boolean;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: { logger: Logger }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger } = options;

        this.permitAppDustEvents = false;
        this.logger = logger;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 15.2.0
     * @param {String} id - The tracking ID that should be registered.
     * @param {String} forProvider - The provider that is tracking the ID.
     * @desc Registers an ID for third party tracking.
     *
     */
    public registerCorrelationId(id: string, forProvider: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                id: Types.nonEmptyString,
                forProvider: Types.nonEmptyString
            };

            typecheck(this, 'registerCorrelationId', params, arguments);
        }

        this.logger.correlationIds[forProvider] = id;
    }

    /**
     *
     * @access public
     * @since 15.2.0
     * @param {String} forProvider - The provider that is tracking the ID.
     * @desc Unregisters an ID for third party tracking.
     *
     */
    public deregisterCorrelationId(forProvider: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                forProvider: Types.nonEmptyString
            };

            typecheck(this, 'deregisterCorrelationId', params, arguments);
        }

        delete this.logger.correlationIds[forProvider];
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @param {Object} dustEvent - The custom dust event Object to be logged.
     * @param {String} dustEvent.category - The category for this event.
     * @param {String} dustEvent.event - The urn corresponding to this event.
     * @param {Object} [dustEvent.data] - Any extra relevant data for this event.
     * @param {Object} [dustEvent.server] - Server-specific data for this event such as request method.
     * @desc Allows a custom dust event to be logged.
     * @returns {Promise<Void>}
     * @example
     * const dustEvent = {
     *     category: 'urn:bamtech:dust:bamsdk:event:sdk',
     *     event: 'some:custom:event:urn',
     *     data: { custom: data },
     *     server: { method: 'GET' }
     * }
     *
     * await loggerApi.logDustEvent(dustEvent);
     *
     */
    public async logDustEvent(dustEvent: {
        category: string;
        event: string;
        data: object;
        server: {
            method: HttpCoreMethod;
        };
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                dustEvent: Types.object({
                    category: Types.nonEmptyString,
                    event: Types.nonEmptyString,
                    data: Types.nonEmptyObject.optional,
                    server: Types.object({
                        method: Types.nonEmptyString.optional
                    }).optional
                })
            };

            typecheck(this, 'logDustEvent', params, arguments);
        }

        const { category, event, data, server } = dustEvent;
        const { method } = server || {};

        if (this.permitAppDustEvents) {
            const dustLogUtility = new DustLogUtility({
                logger: this.logger,
                category,
                source: this.toString(),
                urn: event,
                data,
                payload: {
                    method
                },
                skipLogTransaction: true
            });

            await dustLogUtility.log();
        }
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @param {SDK.Logging.AnalyticsProvider} analyticsProvider - Interface for application-provided analytics properties.
     * The SDK will query the provider for these values when reporting QoE events.
     * @desc Sets the `AnalyticsProvider` required to obtain common properties for QoE reporting.
     * @note This method should be called as soon as possible to ensure accurate QoE reporting.
     * @returns {Void}
     *
     */
    public setAnalyticsProvider(analyticsProvider: AnalyticsProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                analyticsProvider: Types.instanceStrict(AnalyticsProvider)
            };

            typecheck(this, 'setAnalyticsProvider', params, arguments);
        }

        this.logger.analyticsProvider = analyticsProvider;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @desc Returns the analyticsProvider or undefined if no provider is set.
     * @returns {SDK.Logging.AnalyticsProvider|undefined}
     *
     */
    public getAnalyticsProvider() {
        return this.logger.analyticsProvider;
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @desc All currently registered third party tracking identifiers
     * @returns {Object}
     *
     */
    public correlationIDs() {
        return this.logger.correlationIds;
    }

    /**
     *
     * @access private
     * @since 4.8.0
     * @desc All currently registered third party tracking identifiers
     *
     */
    public enableCustomDustEvents() {
        this.permitAppDustEvents = true;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.LoggingApi';
    }
}
