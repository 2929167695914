/**
 *
 * @module eligibilityStatusResponse
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Partner from './partner';

/**
 *
 * @access public
 * @since 4.2.0
 *
 */
export default class EligibilityStatusResponse {
    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Object<String, Object<SDK.Services.Eligibility.Partner>>}
     *
     */
    public partners: Record<string, Partner>;

    /**
     *
     * @param {Record<string, Partner>} partners
     *
     */
    public constructor(partners: Record<string, Partner>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const validatorMessage =
                'non-empty Object containing instances of SDK.Services.Eligibility.Partner';

            /**
             *
             * @param {Object} value
             * @desc Custom typecheck validator, ensures each item in the set is a proper Partner instance.
             * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#custom-validation
             * @see https://www.npmjs.com/package/check-types#how-do-i-use-it
             * @returns {Boolean}
             *
             */
            const validator = (value: object) => {
                // if no partners provided - fail
                if (Check.emptyObject(value)) {
                    return false;
                }

                for (const instance of Object.values(value)) {
                    if (Check.not.instanceStrict(instance, Partner)) {
                        // fail the on any instance that is not valid.
                        return false;
                    }
                }

                return true;
            };

            const params = {
                partners: Types.custom(validator, validatorMessage)
            };

            typecheck(this, params, arguments);
        }

        this.partners = partners;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Eligibility.EligibilityStatusResponse';
    }
}
