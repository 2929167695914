/**
 *
 * @module memoryFirstStore
 *
 */

import { Check } from '@dss/type-checking';

import MemoryStore from './memoryStore';
import { IStore } from './IStore'; // eslint-disable-line no-unused-vars
import ProviderException from '../../exception/providerException';

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc The MemoryFirstStore prioritizes storing and getting values from memory, instead of the local platformStore.
 * All values are still stored in platformStore and it is used as a fallback storage instance in this case.
 *
 */
export default class MemoryFirstStore implements IStore {
    /**
     *
     * @access private
     * @type {MemoryStore}
     * @desc Memory storage reference.
     *
     */
    private memoryStore: MemoryStore;

    /**
     *
     * @access private
     * @type {IStore}
     * @desc Store provided by the platform.
     *
     */
    private platformStore: IStore;

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {IStore} platformStore
     *
     */
    public constructor(platformStore: IStore) {
        this.memoryStore = new MemoryStore();
        this.platformStore = platformStore;
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @param {*} value
     * @desc Sets a key/value pair in storage.
     * @returns {Promise<Void>}
     *
     */
    public async setItem(key: string, value: TodoAny) {
        try {
            await Promise.all([
                this.memoryStore.setItem(key, value),
                this.platformStore.setItem(key, value)
            ]);
        } catch (error) {
            const errorMessage = `${this.toString()}.setItem(key, value) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @desc Retrieves a given key from storage.
     * @returns {Promise<*>}
     *
     */
    public async getItem(key: string) {
        try {
            // Get value from memory first.
            let value = await this.memoryStore.getItem(key);

            if (Check.not.assigned(value)) {
                value = await this.platformStore.getItem(key);
            }

            return value;
        } catch (error) {
            const errorMessage = `${this.toString()}.getItem(key) unable to parse value - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @desc Removes a given key from storage.
     * @returns {Promise<Void>}
     *
     */
    public async removeItem(key: string) {
        try {
            await this.memoryStore.removeItem(key);
            await this.platformStore.removeItem(key);
        } catch (error) {
            const errorMessage = `${this.toString()}.removeItem(key) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Store.MemoryFirstStore';
    }
}
