/**
 *
 * @module ageVerificationMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import SocketEvents from '../socketEvents';
import AgeVerificationFlowType from '../../ageVerificationFlowType';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';
import { RawSocketMessage } from '../typedefs';

const SocketUrns = DustUrnReference.socket.socketManager;

/**
 *
 * @access private
 * @since 12.0.0
 * @desc Handles the age verification socket messages
 *
 */
export default class AgeVerificationMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 12.0.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageTypes = [
            SocketUrns.ageVerificationRequestCompleted,
            SocketUrns.ageVerificationRequestErrored,
            SocketUrns.ageVerificationRedeemCompleted,
            SocketUrns.ageVerificationRedeemErrored
        ];

        super(messageTypes);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} messageData
     * @desc Sets up and emits the `ageVerificationChanged` event.
     *
     */
    public async process(messageData: RawSocketMessage) {
        const { type, data } = messageData;
        const { deviceId, accountId, profileId, errorCode, timestamp } =
            data as TodoAny;

        const requests = [
            SocketUrns.ageVerificationRequestCompleted,
            SocketUrns.ageVerificationRequestErrored
        ];

        const flowType = requests.includes(type)
            ? AgeVerificationFlowType.request
            : AgeVerificationFlowType.redeem;

        const ageVerificationChangedEvent = {
            deviceId,
            accountId,
            profileId,
            errorCode,
            timestamp,
            flowType
        };

        this.socketManager.emit(
            SocketEvents.ageVerificationChanged,
            ageVerificationChangedEvent
        );
    }

    /**
     *
     * @access private
     * @since 12.0.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.AgeVerificationMessageProcessor';
    }
}
