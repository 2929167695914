/**
 *
 * @module sdk
 * @desc {BAM.SDK} entry point (core).
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#configuration
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#types
 * @see https://www.typescriptlang.org/cheatsheets
 *
 */

import AccountPlugin from './account/accountPlugin';
import AdvertisingPlugin from './advertising/advertisingPlugin';
import CommercePlugin from './commerce/commercePlugin';
import ContentPlugin from './content/contentPlugin';
import CustomerServicePlugin from './customerService/customerServicePlugin';
import DrmPlugin from './drm/drmPlugin';
import EligibilityPlugin from './eligibility/eligibilityPlugin';
import EntitlementPlugin from './entitlement/entitlementPlugin';
import ExternalActivationPlugin from './externalActivation/externalActivationPlugin';
import ExplorePlugin from './explore/explorePlugin';
import FlexPlugin from './flex/flexPlugin';
import QRCodePlugin from './qrCode/qrCodePlugin';
import InvoicePlugin from './invoice/invoicePlugin';
import MediaPlugin from './media/mediaPlugin';
import PaywallPlugin from './paywall/paywallPlugin';
import PlatformPlugin from './platform/platformPlugin';
import PurchasePlugin from './purchase/purchasePlugin';
import RipcutPlugin from './ripcut/ripcutPlugin';
import SocketPlugin from './socket/socketPlugin';
import SubscriptionPlugin from './subscription/subscriptionPlugin';
import UserActivityPlugin from './userActivity/userActivityPlugin';

import { extendSDKWithPlugins } from './sdkPlugins';

import SDKCore from './sdkCore';

const plugins = [
    AccountPlugin,
    CommercePlugin,
    ContentPlugin,
    CustomerServicePlugin,
    DrmPlugin,
    EligibilityPlugin,
    EntitlementPlugin,
    ExternalActivationPlugin,
    ExplorePlugin,
    FlexPlugin,
    QRCodePlugin,
    InvoicePlugin,
    MediaPlugin,
    PaywallPlugin,
    PlatformPlugin,
    PurchasePlugin,
    RipcutPlugin,
    SocketPlugin,
    SubscriptionPlugin,
    UserActivityPlugin,
    AdvertisingPlugin
];

/**
 *
 * @desc The BAM SDK manages all BAM platform service API interactions and simplifies client-side integrations.
 *
 */
export default extendSDKWithPlugins(SDKCore, SDKCore.SdkSession, plugins);
