/**
 *
 * @module userActivityAction
 *
 */

/**
 *
 * @enum
 * @since 3.4.0
 * @typedef {Object} SDK.UserActivity.Personalization.UserActivityAction
 *
 * @property {String} CLICK
 *
 */
enum UserActivityAction {
    CLICK = 'CLICK'
}

export default UserActivityAction;
