/**
 *
 * @module serviceEndpoint
 *
 */

import { HttpCoreMethod, IEndpoint } from '../providers/typedefs';
import HttpMethod from './httpMethod';

/**
 *
 * @access protected
 * @desc Provides configuration information for a service endpoint (i.e. link).
 *
 */
export default class ServiceEndpoint {
    /**
     *
     * @access public
     * @type {String|undefined}
     *
     */
    public rel?: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the URL for the service request.
     *
     */
    public href: string;

    /**
     *
     * @access public
     * @type {Integer}
     *
     */
    public ttl: number;

    /**
     *
     * @access public
     * @type {Integer}
     * @desc Gets or sets the timeout for the service request.
     *
     */
    public timeout: number;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc Gets or sets a value that indicates whether or not the href requires
     * template string substitution prior to sending the service request.
     *
     */
    public templated: boolean;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the HTTP method that should be used for requests to the endpoint.
     *
     */
    public method: HttpCoreMethod;

    /**
     *
     * @access public
     * @type {Record<string, string>|null}
     * @desc Gets or sets the headers to append to the service request.
     *
     */
    public headers?: Nullable<Record<string, string>>;

    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Record<string, string>|null}
     * @desc Gets or sets the optionalHeaders to append to the service request.
     *
     */
    public optionalHeaders?: Nullable<Record<string, string>>;

    /**
     *
     * @param {Object} options
     * @param {String} options.rel
     * @param {String} options.href
     * @param {Integer} options.ttl
     * @param {Integer} options.timeout
     * @param {Boolean} options.templated
     * @param {String} [options.method=HttpMethod.Get]
     * @param {Record<string, string>} [options.headers=null]
     * @param {Record<string, string>} [options.optionalHeaders=null]
     *
     */
    public constructor(
        options: Omit<IEndpoint, 'method'> & { method?: HttpCoreMethod }
    ) {
        const {
            rel,
            href,
            ttl,
            timeout,
            templated,
            method = HttpMethod.GET,
            headers = null,
            optionalHeaders = null
        } = options;

        this.rel = rel;
        this.href = href;
        this.ttl = ttl;
        this.timeout = timeout;
        this.templated = templated;
        this.method = method;
        this.headers = headers;
        this.optionalHeaders = optionalHeaders;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceEndpoint';
    }
}
