/**
 *
 * @module widevineCertificateStorage
 *
 */

import CertificateStorage from './certificateStorage';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import type Logger from '../logging/logger';

import { DrmType } from '../services/media/enums';

/**
 *
 * @access protected
 * @since 3.2.0
 * @desc Provides a storage mechanism for storing a Widevine Certificate.
 *
 */
export default class WidevineCertificateStorage extends CertificateStorage {
    /**
     *
     * @param {Object} options
     * @param {String} clientId
     * @param {String} environment
     * @param {SDK.Services.PlatformProviders.Storage} storage
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
    }) {
        super({ ...options, drmType: DrmType.WIDEVINE });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.WidevineCertificateStorage';
    }
}
