/**
 *
 * @module telemetryEvent
 *
 */

import type TelemetryPayload from '../../services/internal/telemetry/telemetryPayload';

/**
 *
 * @access protected
 * @interface TelemetryEvent
 *
 */
export default class TelemetryEvent {
    /**
     *
     * @access private
     * @returns {SDK.Services.Internal.Telemetry.TelemetryPayload} JSONSerializable payload
     *
     */
    // eslint-disable-next-line custom-rules/inferred-return-type
    public getPayload(): TelemetryPayload {
        throw new Error('TelemetryEvent#getPayload - not-implemented');
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Internal.Telemetry.TelemetryEvent';
    }
}
