/**
 *
 * @module accountClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Account.AccountClientEndpoint
 *
 * @property {String} createAccount
 * @property {String} createAccountGrant
 * @property {String} getCurrentAccount
 * @property {String} updateAccountAttributes
 *
 */
enum AccountClientEndpoint {
    createAccount = 'createAccount',
    createAccountGrant = 'createAccountGrant',
    getCurrentAccount = 'getCurrentAccount',
    updateAccountAttributes = 'updateAccountAttributes'
}

export default AccountClientEndpoint;
