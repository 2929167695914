/**
 *
 * @module storageConstants
 *
 */

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_serverTime`.
 *
 */
export const SERVER_TIME_KEY = '__bam_sdk_serverTime';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_sdkSession_configuration--`.
 *
 */
export const SDKSESSION_CONFIGURATION_KEY =
    '__bam_sdk_sdkSession_configuration--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_drm-`.
 *
 */
export const BAM_DRM_KEY = '__bam_sdk_drm-';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__dss_sdk_drm-`.
 *
 */
export const DSS_DRM_KEY = '__dss_sdk_drm-';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `-certificate--`.
 *
 */
export const CERTIFICATE_KEY = '-certificate--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_feature_flags--`.
 *
 */
export const FEATURE_FLAGS_KEY = '__bam_sdk_feature_flags--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_session_info--`.
 *
 */
export const SESSION_INFO_KEY = '__bam_sdk_session_info--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_access--`.
 *
 */
export const ACCESS_KEY = '__bam_sdk_access--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_authorization_context--`.
 *
 */
export const AUTHORIZATION_CONTEXT_KEY = '__bam_sdk_authorization_context--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_account_delegation_refresh_token--`.
 *
 */
export const ACCOUNT_DELEGATION_REFRESH_TOKEN_KEY =
    '__bam_sdk_account_delegation_refresh_token--';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The storage key for `__bam_sdk_device_grant--`.
 *
 */
export const DEVICE_GRANT_KEY = '__bam_sdk_device_grant--';
