/**
 *
 * @module eventSchemataProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { UserActivityEvent, UserActivityEventTypedef } from './typedefs';
import EventSchema from './eventSchema';
import EventSchemata from './eventSchemata';

/**
 *
 * @desc The schema provider that should be used to generate event payloads
 *
 */
export default class EventSchemataProvider {
    /**
     *
     * @access private
     * @type {Object<String, SDK.UserActivity.EventSchemata>|Object}
     * @note the spec notes that the key should be `SDK.UserActivity.UserActivityEvent` but JS does not have the
     * ability to use an `Object` as a key so we use `SDK.UserActivity.UserActivityEvent.eventUrn` instead
     *
     */
    private schemata: Record<string, EventSchemata>;

    public constructor() {
        this.schemata = {};
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.UserActivity.UserActivityEvent} options.event
     * @param {Array<SDK.UserActivity.EventSchema>} options.schemata
     * @param {Boolean} options.passthrough
     * @desc duplicate keys are overwritten
     * @note `schemata` is renamed `schemaArray` since we already have a `schemata` property on the constructor
     *
     */
    public setSchemata(options: {
        event: UserActivityEvent;
        schemata: Array<EventSchema>;
        passthrough: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    event: Types.object(UserActivityEventTypedef),
                    schemata: Types.array.of.instanceStrict(EventSchema),
                    passthrough: Types.boolean
                })
            };

            typecheck(this, 'setSchemata', params, arguments);
        }

        const { schemata } = this;
        const { event, schemata: schemaArray, passthrough } = options;

        schemata[event.eventUrn] = new EventSchemata(schemaArray, passthrough);
    }

    /**
     *
     * @access private
     * @param {SDK.UserActivity.UserActivityEvent} event
     * @note if no schema is found we return a bare bones version of `SDK.UserActivity.EventSchemata` to ensure our
     * payload generator functions correctly
     * @note we are unable to use the `SDK.UserActivity.UserActivityEvent` as the key so we do the lookup via the
     * `eventUrn` property
     * @note if a schemata isn't found we create a new one using default values of `schema=[], passthrough=true`
     * @returns {SDK.UserActivity.EventSchemata}
     *
     */
    public getSchemata(event: UserActivityEvent) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                event: Types.object(UserActivityEventTypedef)
            };

            typecheck(this, 'getSchemata', params, arguments);
        }

        const { schemata } = this;

        return schemata[event.eventUrn] || new EventSchemata([], true);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.EventSchemataProvider';
    }
}
