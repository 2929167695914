/**
 *
 * @module localStore
 *
 */

import { Check } from '@dss/type-checking';
import { IStore } from './IStore'; // eslint-disable-line no-unused-vars
import ProviderException from '../../exception/providerException';

/**
 *
 * @access protected
 * @since 16.0.0
 *
 */
export default class LocalStore implements IStore {
    /**
     *
     * @access private
     * @since 16.0.0
     * @type {Storage}
     * @desc local storage instance provided by the platform.
     *
     */
    public localStorage: Storage;

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {Storage} localStorage
     * @param {Boolean} [doJSONHandling=true] - to avoid double-JSON handling on compressed usage
     *
     */
    public constructor(localStorage: Storage) {
        this.localStorage = localStorage;
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @param {*} value
     * @returns {Promise<Void>}
     *
     */
    public async setItem(key: string, value: TodoAny) {
        try {
            await this.localStorage.setItem(key, value);
        } catch (error) {
            const errorMessage = `${this.toString()}.setItem(key, value) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @returns {Promise<*>}
     *
     */
    public async getItem(key: string) {
        try {
            let parsedValue;

            const value = await this.localStorage.getItem(key);

            if (Check.nonEmptyString(value)) {
                parsedValue = value;
            }

            return parsedValue;
        } catch (error) {
            const errorMessage = `${this.toString()}.getItem(key) unable to parse value - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @returns {Promise<Void>}
     *
     */
    public async removeItem(key: string) {
        try {
            await this.localStorage.removeItem(key);
        } catch (error) {
            const errorMessage = `${this.toString()}.removeItem(key) cannot complete successfully - ${error}`;

            throw new ProviderException(errorMessage);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Store.LocalStore';
    }
}
