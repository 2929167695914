/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';
import { UserActivityEventCategory } from './enums';

/**
 *
 * @access public
 * @typedef {Object} SDK.UserActivity.UserActivityEvent
 * @since 22.0.0
 * @desc creates a key for referencing UserActivity-related events
 *
 * @property {String} eventUrn
 * @note stores the event urn referenced from `SDK.Services.Internal.Dust.DustUrnReference` or a custom urn
 *
 * @example { name: SDK.Services.Internal.Dust.DustUrnReference.userActivity.glimpse.appLoaded }
 * @example { name: 'urn:dss:something:custom' } // when you need something custom
 *
 */
export interface UserActivityEvent {
    eventUrn: string;
}

/**
 *
 * @access private
 *
 */
export const UserActivityEventTypedef = {
    eventUrn: Types.nonEmptyString
};

/**
 *
 * @access public
 * @typedef {Object} SDK.UserActivity.GlimpseEvent
 * @since 29.0.0
 * @desc Creates a key for referencing Glimpse-related events.
 *
 * @property {String} eventUrn
 *
 */
export type GlimpseEvent = UserActivityEvent;

/**
 *
 * @access public
 * @typedef {Object} SDK.UserActivity.PersonalizationEvent
 * @since 29.0.0
 * @desc Creates a key for referencing Personalization-related events.
 *
 * @property {String} eventUrn
 *
 */
export type PersonalizationEvent = UserActivityEvent;

/**
 *
 * @access public
 * @typedef {Object} SDK.UserActivity.TrackEventArgs
 * @since 22.0.0
 * @desc A convenience wrapper for arguments required for tracking multiple events.
 * @property {UserActivityEvent} event - The event identifier/type.
 * @property {object} parameters - Data to be associated with the event.
 * @property {SDK.UserActivity.UserActivityEventCategory} eventCategory - The category of this event.
 * @property {String} eventVersion - The version of the event schema that the parameters correspond to.
 *
 * @note The eventVersion corresponds to the `Bam.Sdk.Services.Internal.Dust.DustClientPayload.dataVersion` field.
 *
 */
export interface TrackEventArgs {
    event: UserActivityEvent;
    parameters: Record<string, unknown>;
    eventCategory: UserActivityEventCategory;
    eventVersion: string;
}

/**
 *
 * @access private
 *
 */
export const TrackEventArgsTypedef = {
    event: Types.object(UserActivityEventTypedef),
    parameters: Types.object(),
    eventCategory: Types.in(UserActivityEventCategory),
    eventVersion: Types.string
};
