/**
 *
 * @module exchangeGrant
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import Grant from './grant';
import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @access public
 * @desc Provides an implementation for ITokenRequestBuilder used to exchange a grant for an AccessContext.
 *
 */
export default class ExchangeGrant extends TokenRequestBuilder {
    /**
     *
     * @access public
     * @type {Grant}
     *
     */
    public grant: Grant;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public subjectType: string;

    /**
     *
     * @access protected
     * @type {String}
     *
     */
    public override readonly grantType: string;

    /**
     *
     * @param {Grant} grant - The grant to build a TokenRequest for.
     * @param {String} subjectType - The subject_token_type of the grant.
     *
     */
    public constructor(grant: Grant, subjectType: string) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                grant: Types.instanceStrict(Grant),
                subjectType: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.grant = grant;
        this.subjectType = subjectType;
        this.grantType = 'urn:ietf:params:oauth:grant-type:token-exchange';
        // assign data to token data
        this.tokenData.subject_token_type = this.subjectType;
        this.tokenData.subject_token = this.grant.assertion;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.ExchangeGrant';
    }
}
