/**
 *
 * @module productType
 *
 */

/**
 *
 * @enum
 * @since 3.9.0
 * @typedef {Object} SDK.Services.Paywall.ProductType
 * @desc Defines the different product types that are supported.
 *
 * @property {String} D2C - Since `3.9.0` - Direct to consumer.
 * @property {String} IAP - Since `3.9.0` - In app purchase.
 *
 */
enum ProductType {
    D2C = 'D2C',
    IAP = 'IAP'
}
export default ProductType;
