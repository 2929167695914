/**
 *
 * @module platformProviders
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import EnvironmentConfiguration from './browser/environmentConfiguration';
import CoreHttpClientProvider from './shared/coreHttpClientProvider';
import CoreStorageProvider from './shared/coreStorageProvider';

type ConstructableHttpClientProvider = {
    new (
        ...args: ConstructorParameters<typeof CoreHttpClientProvider>
    ): CoreHttpClientProvider;
};

type ConstructableCoreStorageProvider = {
    new ({
        store,
        logger,
        useMemoryFirst,
        useStorageCompression
    }: Partial<
        ConstructorParameters<typeof CoreStorageProvider>[0]
    >): CoreStorageProvider;
};

let environmentConfigurationProvider: typeof EnvironmentConfiguration;
let httpClientProvider: ConstructableHttpClientProvider;
let storageProvider: ConstructableCoreStorageProvider;

/**
 *
 * @access protected
 * @desc Provides a way to inject and pull core platform provider implementations.
 *
 */
export default class PlatformProviders {
    /**
     *
     * @access public
     * @since 4.14.0
     * @param {Object} options
     * @param {Function<SDK.Services.Configuration.BaseEnvironmentConfiguration>} options.EnvironmentConfiguration
     * @param {Function<CoreHttpClientProvider>} options.HttpClient
     * @param {Function<Storage>} options.Storage
     * @desc Allows configuration of core infrastructure providers
     *
     */
    public static setProviders(options: {
        EnvironmentConfiguration: typeof EnvironmentConfiguration;
        HttpClient: ConstructableHttpClientProvider;
        Storage: ConstructableCoreStorageProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    EnvironmentConfiguration: Types.function,
                    HttpClient: Types.function,
                    Storage: Types.function
                })
            };

            typecheck(PlatformProviders, 'setProviders', params, arguments);
        }

        const {
            EnvironmentConfiguration: environmentConfiguration,
            HttpClient: httpClient,
            Storage: storage
        } = options;

        environmentConfigurationProvider = environmentConfiguration;
        httpClientProvider = httpClient;
        storageProvider = storage;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {SDK.Services.Configuration.BaseEnvironmentConfiguration}
     *
     */
    public static get EnvironmentConfiguration() {
        if (!environmentConfigurationProvider) {
            throw new Error('EnvironmentConfiguration provider not specified');
        }

        return environmentConfigurationProvider;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {CoreHttpClientProvider}
     *
     */
    public static get HttpClient() {
        if (!httpClientProvider) {
            throw new Error('HttpClient provider not specified');
        }

        return httpClientProvider;
    }

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {CoreStorageProvider}
     *
     */
    public static get Storage() {
        if (!storageProvider) {
            throw new Error('Storage provider not specified');
        }

        return storageProvider;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.PlatformProviders';
    }
}
