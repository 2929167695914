/**
 *
 * @module platformProfileReference
 *
 */

import { PlatformProfile } from './typedefs';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Configuration.PlatformProfileReference
 * @since 3.7.0
 * @desc Internal platform hash reference.
 *
 * @property {Object} chromecast
 * @property {Object} netcast
 * @property {Object} panasonictv
 * @property {Object} sonybravia
 * @property {Object} tivo
 * @property {Object} tizen
 * @property {Object} vizio
 * @property {Object} webos
 * @property {Object} canalPlus
 * @property {Object} skyq
 * @property {Object} cableVision
 * @property {Object} comcastx1
 * @property {Object} telecentro
 * @property {Object} toshibaRegza
 * @property {Object} hisense
 * @property {Object} rogersx1
 * @property {Object} coxx1
 * @property {Object} xglobal
 * @property {Object} shawx1
 * @property {Object} ditv - Since `22.0.0`.
 * @property {Object} pwaWindowsApp - Since `24.0.0`.
 * @property {Object} foxtel - Since `26.0.0` - Foxtel, a telco based in Australia.
 * @property {Object} charter - Since `26.0.0` - Charter (US).
 *
 */

const platformProfileReference: Record<string, PlatformProfile> = {
    chromecast: {
        deviceProfile: 'google',
        applicationRuntime: 'chromecast',
        deviceRuntimeProfile: 'chromecast',
        platform: 'chromecast'
    },
    netcast: {
        deviceProfile: 'lg',
        applicationRuntime: 'netcast',
        deviceRuntimeProfile: 'netcast',
        platform: 'netcast'
    },
    panasonictv: {
        deviceProfile: 'panasonic',
        applicationRuntime: 'panasonictv',
        deviceRuntimeProfile: 'panasonictv',
        platform: 'panasonictv'
    },
    sonybravia: {
        deviceProfile: 'sony',
        applicationRuntime: 'sonybravia',
        deviceRuntimeProfile: 'sonybravia',
        platform: 'sonybravia'
    },
    tivo: {
        deviceProfile: 'tivo',
        applicationRuntime: 'tivo',
        deviceRuntimeProfile: 'tivo',
        platform: 'tivo'
    },
    tizen: {
        deviceProfile: 'samsung',
        applicationRuntime: 'tizen',
        deviceRuntimeProfile: 'tizen',
        platform: 'tizen'
    },
    vizio: {
        deviceProfile: 'vizio',
        applicationRuntime: 'vizio',
        deviceRuntimeProfile: 'vizio',
        platform: 'vizio'
    },
    webos: {
        deviceProfile: 'lg',
        applicationRuntime: 'webos',
        deviceRuntimeProfile: 'webos',
        platform: 'webos'
    },
    canalPlus: {
        deviceProfile: 'canalPlus',
        applicationRuntime: 'canalPlus',
        deviceRuntimeProfile: 'canalPlus',
        platform: 'canalPlus'
    },
    skyq: {
        deviceProfile: 'sky',
        applicationRuntime: 'skyq',
        deviceRuntimeProfile: 'skyq',
        platform: 'skyq'
    },
    cableVision: {
        deviceProfile: 'cableVision',
        applicationRuntime: 'cableVision',
        deviceRuntimeProfile: 'cableVision',
        platform: 'cableVision'
    },
    comcastx1: {
        deviceProfile: 'comcast',
        applicationRuntime: 'comcastx1',
        deviceRuntimeProfile: 'comcastx1',
        platform: 'comcastx1'
    },
    telecentro: {
        deviceProfile: 'telecentro',
        applicationRuntime: 'telecentro',
        deviceRuntimeProfile: 'telecentro',
        platform: 'telecentro'
    },
    toshibaRegza: {
        deviceProfile: 'toshiba',
        applicationRuntime: 'toshibaRegza',
        deviceRuntimeProfile: 'toshibaRegza',
        platform: 'toshibaRegza'
    },
    hisense: {
        deviceProfile: 'hisense',
        applicationRuntime: 'hisense',
        deviceRuntimeProfile: 'hisense',
        platform: 'hisense'
    },
    rogersx1: {
        deviceProfile: 'rogers',
        applicationRuntime: 'rogersx1',
        deviceRuntimeProfile: 'rogersx1',
        platform: 'rogersx1'
    },
    coxx1: {
        deviceProfile: 'cox',
        applicationRuntime: 'coxx1',
        deviceRuntimeProfile: 'coxx1',
        platform: 'coxx1'
    },
    xglobal: {
        deviceProfile: 'xglobal',
        applicationRuntime: 'xglobal',
        deviceRuntimeProfile: 'xglobal',
        platform: 'xglobal'
    },
    shawx1: {
        deviceProfile: 'shaw',
        applicationRuntime: 'shawx1',
        deviceRuntimeProfile: 'shawx1',
        platform: 'shawx1'
    },
    ditv: {
        deviceProfile: 'lg',
        applicationRuntime: 'ditv',
        deviceRuntimeProfile: 'ditv',
        platform: 'ditv'
    },
    pwaWindowsApp: {
        deviceProfile: 'windows',
        applicationRuntime: 'pwa-windows-app',
        deviceRuntimeProfile: 'pwa-windows-app',
        platform: 'pwa-windows-app'
    },
    foxtel: {
        deviceProfile: 'foxtel',
        applicationRuntime: 'foxtel',
        deviceRuntimeProfile: 'foxtel',
        platform: 'foxtel'
    },
    charter: {
        deviceProfile: 'charter',
        applicationRuntime: 'charter',
        deviceRuntimeProfile: 'charter',
        platform: 'charter'
    }
};

/**
 *
 * @type {SDK.Services.Configuration.PlatformProfileReference}
 *
 */
export default platformProfileReference;
