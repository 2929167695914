/**
 *
 * @module accountPlugin
 * @desc {SDK.Account.AccountPlugin} entry point.
 *
 */

import AccountApi from './accountApi';

import AccountService from '../services/account/entry';
import Account from './entry';

import AccountClient from '../services/account/accountClient';
import AccountClientConfiguration from '../services/account/accountClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Account', typeof Account, typeof AccountService> = {
    pluginName: 'Account',
    entry: Account,
    service: AccountService,

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { orchestrationClient, httpClient, subscriptionClient } = clients;

        sdkSession.accountApi = new AccountApi({
            accountClient: new AccountClient({
                config: sdkSession.config.services.account,
                logger,
                httpClient,
                orchestrationClient
            }),
            tokenManager: sdkSession.managers.tokenManager,
            subscriptionClient,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.account =
            new AccountClientConfiguration({
                baseUrl: services?.account?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.account?.client?.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
