/**
 *
 * @module accessTokenType
 *
 */

/**
 *
 * @deprecated Deprecated as of version `28.1.0`. This enum is now generated by the `sdk-types` package.
 * @enum
 * @access public
 * @since 4.17.0
 * @typedef {Object} SDK.Services.Token.AccessTokenType
 *
 * @property {String} device - Since `4.17.0`.
 * @property {String} account - Since `4.17.0`.
 * @property {String} accountWithoutActiveProfile - Since `4.17.0`.
 *
 */
enum AccessTokenType {
    device = 'Device',
    account = 'Account',
    accountWithoutActiveProfile = 'AccountWithoutActiveProfile'
}

export default AccessTokenType;
