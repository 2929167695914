/**
 *
 * @module telemetryPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Model containing the information to report to the telemetry service.
 *
 */
export default class TelemetryPayload {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Dictionary containing server provided tracking information.
     *
     */
    public server: TodoAny;

    /**
     *
     * @access public
     * @type {Object}
     * @desc Dictionary containing client provided tracking information.
     *
     */
    public client: TodoAny;

    /**
     *
     * @access protected
     * @param {Object} [options]
     * @param {Object} [options.server={}]
     * @param {Object} [options.client={}]
     *
     */
    public constructor(options = {}) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    server: Types.object().optional,
                    client: Types.object().optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const { server = {}, client = {} } = options as TodoAny;

        this.server = server;
        this.client = client;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Telemetry.TelemetryPayload';
    }
}
