/**
 *
 * @module validationResultJsonDustEventDecodeError
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ValidationResult from './validationResult';

/**
 *
 * @access protected
 * @since 4.11.0
 * @desc Applicable if `ValidationResult.validationResultType` is `com.disneystreaming.sdp.http.JsonEventDecodeError`
 *
 */
export default class ValidationResultJsonDustEventDecodeError extends ValidationResult {
    /**
     *
     * @access public
     * @since 4.11.0
     * @type {Array<String>|undefined}
     *
     */
    public errors?: Array<string>;

    /**
     *
     * @param {Object} options
     * @param {Array<String>} [options.errors]
     *
     */
    public constructor(options: { errors?: Array<string> }) {
        // @ts-expect-error TODO: wrong super call...
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    errors: Types.array.of.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { errors } = options;

        this.errors = errors;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.ValidationResultJsonDustEventDecodeError';
    }
}
