/**
 *
 * @module platformProviders
 *
 */

import Logger from '../logging/logger';
import DefaultGeoProvider from './defaultGeoProvider';
import { IGeoProvider } from './IGeoProvider';

type GeoProviderCtor = { new (logger?: Logger): IGeoProvider };

let geoProvider: GeoProviderCtor = DefaultGeoProvider;

/**
 *
 * @desc Provides a way to inject and pull core platform provider implementations.
 *
 */
export default {
    /**
     *
     * @access public
     * @since 5.0.0
     * @param {Object} options
     * @param {IGeoProvider} options.GeoProvider
     * @desc Allows configuration of core infrastructure providers
     *
     */
    setProviders(options: { GeoProvider: GeoProviderCtor }) {
        geoProvider = options.GeoProvider;
    },

    /**
     *
     * @access public
     * @since 4.14.0
     * @returns {IGeoProvider}
     *
     */
    get GeoProvider() {
        if (!geoProvider) {
            throw new Error('GeoProvider provider not specified');
        }

        return geoProvider;
    }
};
