/**
 *
 * @module horaValidationResultType
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 14.0.0
 * @typedef {Object} SDK.Services.Internal.Telemetry.HoraValidationResultType
 * @desc Specifies the success or failure of the Hora validation and the type of error
 * @note Flexible enum
 *
 * @property {String} success - Since `14.0.0`.
 * @property {String} horaFailure - Since `14.0.0`.
 * @property {String} sdpFailure - Since `14.0.0`.
 * @property {String} multipleFailures - Since `14.0.0`.
 * @property {String} schemaParsingFailure - Since `14.0.0`.
 * @property {String} healthCheckFailure - Since `14.0.0`.
 * @property {String} internalServerError - Since `14.0.0`.
 * @property {String} unableToReachServer - Since `14.0.0`.
 * @property {String} dataValidationFailure - Since `14.0.0`.
 * @property {String} noValidationPerformed - Since `16.0.0`.
 *
 * @example
 * SDK.Services.Internal.Telemetry.HoraValidationResultType.success // 'Success'
 *
 */
export default {
    success: 'Success',
    horaFailure: 'HoraFailure',
    sdpFailure: 'SdpFailure',
    multipleFailures: 'MultipleFailures',
    schemaParsingFailure: 'SchemaParsingFailure',
    healthCheckFailure: 'HealthCheckFailure',
    internalServerError: 'InternalServerError',
    unableToReachServer: 'UnableToReachServer',
    dataValidationFailure: 'DataValidationFailure',
    noValidationPerformed: 'NoValidationPerformed'
};
