/**
 *
 * @module eventBuffer
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../../logging/logger';
import InternalEvents from '../../internalEvents';

import LogTransaction from '../../logging/logTransaction';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DiagnosticFeature from '../../diagnosticFeature';

import TokenManager from '../../token/tokenManager';
import TelemetryClient from '../../services/internal/telemetry/telemetryClient';
import TelemetryClientEndpoint from '../../services/internal/telemetry/telemetryClientEndpoint';
import TelemetryBufferConfiguration from '../../services/configuration/telemetryBufferConfiguration';

import ExceptionReference from '../../services/exception/exceptionReference';
import circularReplacer from '../../services/util/circularReplacer';
import TelemetryPayload from '../../services/internal/telemetry/telemetryPayload';
import TelemetryResponse from '../../services/internal/telemetry/telemetryResponse';
import ServiceException from '../../services/exception/serviceException';

import type AccessToken from '../../token/accessToken';
import HoraValidatedTelemetryResponse from '../../services/internal/telemetry/horaValidatedTelemetryResponse';
import { IEndpoint } from '../../services/providers/typedefs';

const DustUrn = DustUrnReference.services.internal.telemetry.eventBuffer;

export type UrnsFilterType = {
    urns: Array<string>;
};

/**
 *
 * @access private
 * @since 15.0.0
 * @param {Number} retryCount - The number of retries.
 * @param {Number} maxBackOffRetrySeconds - The max amount of time before allowing telemetry to be retried after a failure.
 * @desc Given a number of retries calculate when to retry again.
 * @returns {Number} a new replyAfter value to delay a next retry.
 *
 */
function retryBackOffFormula(
    retryCount: number,
    maxBackOffRetrySeconds: number
) {
    const maxBackOff = maxBackOffRetrySeconds * 1000;

    return Math.min(2 ** retryCount + Math.random() * 5, maxBackOff);
}

export default abstract class EventBufferBase<
    TMessageType
> extends EventEmitter {
    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @note the spec states that this property should be an instance of `SDK.Configuration.ConfigurationProvider`
     *
     */
    private config: TelemetryBufferConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Telemetry.TelemetryClient}
     *
     */
    protected client: TelemetryClient;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @access private
     * @type {Object}
     *
     */
    private endpoint: IEndpoint;

    /**
     *
     * @access private
     * @since 3.10.0
     * @type {Object}
     * @desc contains information related to fast tracking events
     *
     */
    protected fastTrack: Nullable<UrnsFilterType>;

    /**
     *
     * @access private
     * @since 4.8.0
     * @type {Object}
     * @desc the filter for prohibited telemetry events.
     *
     */
    protected prohibited: UrnsFilterType;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    private isScheduled: boolean;

    /**
     *
     * @access private
     * @type {Number}
     * @note defaults to `config.replyAfterFallback` until a `TelemetryResponse` is received and this value is updated
     *
     */
    private replyAfter: number;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc if this is true then telemetry events will not be published
     *
     */
    public disabled: boolean;

    /**
     *
     * @access protected
     * @since 14.0.0
     * @type {DiagnosticFeature|undefined}
     * @desc Determines which type of validation to perform when diagnostics is enabled for this event buffer.
     *
     */
    private diagnosticFeature?: DiagnosticFeature;

    /**
     *
     * @access private
     * @since 4.11.0
     * @type {Boolean}
     * @desc Determines whether events will be validated. When enabled, validation errors will be surfaced through
     * the logging system and the events will not be recorded for analytics purposes.
     *
     */
    private validateEvents: boolean;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {Boolean}
     * @desc Proxy of traffic through the normal DUST endpoint is to be prevented in publicly released applications.
     * @note Applications should only pass `useProxy=true` in dev builds.
     */
    protected useProxy: boolean;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number}
     * @desc The number of times a telemetry client send has happened
     *
     */
    private retryCount: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc The timeoutId for the next batch of events to be sent.
     *
     */
    private nextBatchTimeoutId: number;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.bufferConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Internal.TelemetryClient} options.telemetryClient
     * @param {SDK.Logging.Logger} options.logger
     * @param {Object} options.endpoint
     * @param {Object} options.prohibited
     * @param {Object} [options.fastTrack=null]
     * @param {SDK.DiagnosticFeature} [options.diagnosticFeature]
     *
     */
    public constructor(options: {
        bufferConfiguration: TelemetryBufferConfiguration;
        tokenManager: TokenManager;
        telemetryClient: TelemetryClient;
        logger: Logger;
        endpoint: IEndpoint;
        prohibited: UrnsFilterType;
        fastTrack?: UrnsFilterType;
        diagnosticFeature?: DiagnosticFeature;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    tokenManager: Types.instanceStrict(TokenManager),
                    telemetryClient: Types.instanceStrict(TelemetryClient),
                    logger: Types.instanceStrict(Logger),
                    endpoint: Types.nonEmptyObject,
                    prohibited: Types.object({
                        urns: Types.array
                    }),
                    fastTrack: Types.object({
                        urns: Types.array.of.nonEmptyString
                    }).optional,
                    diagnosticFeature: Types.in(DiagnosticFeature).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            bufferConfiguration,
            tokenManager,
            telemetryClient,
            logger,
            endpoint,
            prohibited,
            diagnosticFeature,
            fastTrack = null
        } = options;

        this.config = bufferConfiguration;
        this.tokenManager = tokenManager;
        this.client = telemetryClient;
        this.logger = logger;
        this.endpoint = endpoint;
        this.fastTrack = fastTrack;
        this.prohibited = prohibited;
        this.isScheduled = false;
        this.replyAfter = this.config.replyAfterFallback;
        this.disabled = this.config.disabled;
        this.diagnosticFeature = diagnosticFeature;
        this.validateEvents = false;
        this.useProxy = false;
        this.retryCount = 0;
        this.nextBatchTimeoutId = 0;

        this.tokenManager.on(
            InternalEvents.TokenRefreshFailed,
            (tokenRefreshFailed) => {
                // Avoid disabling sockets when we still have a valid anonymous token state
                if (
                    tokenRefreshFailed &&
                    tokenRefreshFailed.hasResetToAnonymousToken
                ) {
                    return;
                }

                this.disabled = true;
            }
        );

        this.tokenManager.on(InternalEvents.AccessChanged, () => {
            this.disabled = this.config.disabled;
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @type {Number}
     *
     */
    protected get queueLimit() {
        return this.config.queueLimit;
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @type {Number}
     *
     */
    protected get minimumBatchSize() {
        return this.config.minimumBatchSize;
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Telemetry.TelemetryPayload} [fastTrackEvent]
     * @returns {Promise<Void>}
     * @note client returns {TelemetryResponse} with `replyAfter` and `requestId`
     * @note the config has a `replyAfterFallback` prop in case `replyAfter` returns null
     * @note `isFastTrack` event bypasses the timer and sends the `fastTrackEvent` immediately
     * @note only send events from the queue when `queue.length >= config.minimumBatchSize`
     * @note if there is service error the failed events are added to the beginning of the queue to be retried
     *
     */
    protected async processBatch(fastTrackEvent?: TMessageType) {
        let batchLimitedEvents: Array<TMessageType> = [];

        if (this.disabled) {
            this.logger.warn(
                this.toString(),
                'Buffering is disabled, events will not be posted.'
            );

            this.clearSchedule();

            return;
        }

        try {
            this.retryCount++;

            if (fastTrackEvent) {
                batchLimitedEvents = [fastTrackEvent];
            } else if (!this.hasMessagesToSend()) {
                this.clearSchedule();

                // TODO: we should probably consider not doing this to allow small event counts to still flow?
                return;
            } else {
                batchLimitedEvents = this.getMessagesToSend(
                    this.config.batchLimit
                );
            }

            this.logger.info(
                this.toString(),
                `Posting ${batchLimitedEvents.length} events`
            );

            // refreshing the token
            await this.tokenManager.refreshAccessToken();

            const { accessToken, useProxy, validateEvents } = this;

            let telemetryResponse: TelemetryResponse;

            if (this.endpoint.rel === TelemetryClientEndpoint.postEvent) {
                telemetryResponse = LogTransaction.wrapLogTransaction({
                    file: this.toString(),
                    urn: DustUrn.processBatch,
                    logger: this.logger,
                    action: async (logTransaction) =>
                        this.client.postEvents(
                            {
                                accessToken,
                                telemetryPayloads:
                                    batchLimitedEvents as unknown as Array<TelemetryPayload>,
                                useProxy
                            },
                            logTransaction
                        )
                });
            } else {
                if (validateEvents) {
                    telemetryResponse = await this.client.horaEventValidation(
                        accessToken,
                        batchLimitedEvents as unknown as Array<TelemetryPayload>
                    );
                } else {
                    telemetryResponse = await this.postDust(batchLimitedEvents);
                }
            }

            // NOTE: the logic here magically works to ignore the dust envelope scenario (but is strange)
            this.handleValidationResults(telemetryResponse, batchLimitedEvents);

            this.retryCount = 0;

            this.replyAfter =
                telemetryResponse.replyAfter || this.config.replyAfterFallback;

            this.clearSchedule();
            this.scheduleNextBatch();
        } catch (exec) {
            const ex = exec as ServiceException;
            const { status, data: { name: exceptionName } = {} } = ex;

            if (
                (status as number) < 400 ||
                (status as number) >= 500 ||
                exceptionName === ExceptionReference.common.network.name
            ) {
                this.requeuePayloadItems(batchLimitedEvents);

                this.replyAfter = retryBackOffFormula(
                    this.retryCount,
                    this.config.maxBackOffRetrySeconds
                );

                this.clearSchedule();
                this.scheduleNextBatch();
            }

            this.logger.warn(this.toString(), ex);
        }
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @param {Array<TMessageType>} items
     *
     */
    protected abstract requeuePayloadItems(items: Array<TMessageType>): void;

    /**
     *
     * @access private
     * @since 28.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {Array<SDK.Socket.RawSocketMessage>} [options.messageEnvelopes]
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} [options.telemetryPayloads]
     * @param {Boolean} options.useProxy
     * @desc A helper to send dust events.
     *
     */
    protected abstract postDust(
        payload?: Array<TMessageType>
    ): Promise<TelemetryResponse>;

    protected abstract getMessagesToSend(
        batchLimit?: number
    ): Array<TMessageType>;

    protected abstract hasMessagesToSend(): boolean;

    protected abstract clearQueue(): void;

    /**
     *
     * @access public
     * @param {Number} [replyAfter] - Seconds to wait before processing batch.
     * @desc The client may return a replyAfter value in its response. If so,
     * the manager should note the time and not send any additional
     * events until after the specified wait period. If the client fails, the manager
     * should retry at a later time using the replyAfter value if available, or a configured value
     * if the replyAfter value is not available.
     * @note If the application restarts, the telemetry manager should send any queued events.
     *
     */
    public scheduleNextBatch(replyAfter?: number) {
        // check for a current replyAfter timer, if already set then bail
        if (this.isScheduled) {
            return;
        }

        if (replyAfter === undefined || replyAfter === null) {
            replyAfter = this.replyAfter || this.config.replyAfterFallback;
        }

        this.isScheduled = true;

        this.nextBatchTimeoutId = setTimeout(
            this.processBatch.bind(this),
            replyAfter * 1000
        );
    }

    /**
     *
     * @access private
     * @desc Clears the last scheduled batch
     *
     */
    private clearSchedule() {
        this.isScheduled = false;
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @desc Sends all events and then empties the queue.
     *
     */
    public async drain() {
        const remainingMessages = this.getMessagesToSend();

        if (remainingMessages.length === 0) {
            return;
        }

        this.clearQueue();

        this.clearSchedule();

        const telemetryResponse = await this.postDust(remainingMessages);

        this.handleValidationResults(telemetryResponse, remainingMessages);

        this.replyAfter =
            telemetryResponse.replyAfter || this.config.replyAfterFallback;

        // TODO: commented this out (when we drain the queue we shouldn't be scheduling another batch run)
        // this.scheduleNextBatch(this.replyAfter);
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Returns whether any type of validation is enabled for this `EventBuffer`'s `DiagnosticFeature`.
     * @returns {Boolean}
     *
     */
    public isValidationEnabled() {
        return this.validateEvents || this.useProxy;
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @param {Boolean} useProxy - Proxy validation setting.
     * @desc Enables validation either via the direct validation endpoint or proxied through the normal endpoint.
     *
     */
    public enableValidation(useProxy: boolean) {
        this.validateEvents = !useProxy;
        this.useProxy = useProxy;
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Disables validation.
     *
     */
    public disableValidation() {
        this.validateEvents = false;
        this.useProxy = false;
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    protected get accessToken() {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     * @desc Determines whether a Hora response was returned and emits the ValidationResultsReceived event if needed.
     * @returns {Void}
     *
     */
    protected handleValidationResults(
        telemetryResponse: TelemetryResponse,
        telemetryPayloads: Array<TMessageType>
    ) {
        if ((telemetryResponse as HoraValidatedTelemetryResponse).results) {
            try {
                this.logger.diagnostics(
                    'TelemetryValidation',
                    JSON.stringify(telemetryResponse, circularReplacer(), '  ')
                );
            } catch (ex) {
                this.logger.diagnostics(
                    'TelemetryValidation',
                    `Could not stringify the validation response: ${ex}`
                );
            }

            this.emit(InternalEvents.ValidationResultsReceived, {
                telemetryPayloads,
                telemetryResponse
            });
        }
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @desc Cleans up the `EventBuffer`.
     *
     */
    public async dispose() {
        if (process.env.NODE_ENV === 'test') {
            this.disabled = true;
        }

        try {
            await this.drain();
        } catch (error) {
            this.logger.error(this.toString(), error);
        }

        clearTimeout(this.nextBatchTimeoutId);
        this.clearSchedule();
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Telemetry.EventBufferBase';
    }
}
