/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    BillingAddress,
    BillingAddressTypedef,
    CallbackUrl,
    CallbackUrlTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.IDeal.IDealPaymentMethod
 * @since 9.0.0
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#extend-params
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {String} shopperBankCode - Bank identifier.
 * @property {String} bankName - Name of the bank.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 * @property {String} [orderCode] - Identifier for an order. Used for repeat billing.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface IDealPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    shopperBankCode: string;
    bankName: string;
    usage: string;
    isShared?: boolean;
    billingAddress: BillingAddress;
    orderCode?: string;
}

/**
 *
 * @access private
 *
 */
export const IDealPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    shopperBankCode: Types.nonEmptyString,
    bankName: Types.nonEmptyString,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    billingAddress: Types.object(BillingAddressTypedef),
    orderCode: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.IDeal.IDealPaymentRequest
 * @since 8.0.0
 * @property {String} bankCode - Bank identifier.
 * @property {String} bankName - Name of the bank.
 * @property {SDK.Commerce.CallbackUrl} callbackUrl - Set of callback urls used in the IDeal payment flow.
 * @property {String} campaignCode - Campaign Code required to calculate price of the order.
 * @property {String} voucherCode - Voucher Code required to calculate price of the order.
 * @property {String} redemptionCode - Redemption code in a gated purchase flow.
 * @property {String} offerId - Identifier to apply applicable offer.
 * @property {Array<String>} skus - Array of sku values associated with the payment request.
 * @property {Boolean} isDefault - Flag used to determine if this payment method should be used for future transactions.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 *
 */
export interface IDealPaymentRequest {
    bankCode: string;
    bankName: string;
    callbackUrl: CallbackUrl;
    campaignCode: string;
    voucherCode: string;
    redemptionCode: string;
    offerId: string;
    skus: Array<string>;
    isDefault: boolean;
    usage: string;
}

/**
 *
 * @access private
 *
 */
export const IDealPaymentRequestTypedef = {
    bankCode: Types.nonEmptyString,
    bankName: Types.nonEmptyString,
    callbackUrl: Types.object(CallbackUrlTypedef),
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString,
    redemptionCode: Types.nonEmptyString,
    offerId: Types.nonEmptyString,
    skus: Types.array.of.nonEmptyString,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.IDeal.IDealUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} [orderCode] - Identifier for an order. Used for repeat billing.
 * @property {String} [customerToken] - The user's customer token.
 *
 */
export interface IDealUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    orderCode?: string;
    customerToken?: string;
}

/**
 *
 * @access private
 *
 */
export const IDealUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    orderCode: Types.nonEmptyString.optional,
    customerToken: Types.nonEmptyString.optional
};
