/**
 *
 * @module entry
 * @desc {SDK.Socket} entry point.
 *
 */

import FlowControlPolicy from './messages/flowControlPolicy';
import FlowControlPolicyType from './messages/flowControlPolicyType';
import SocketConnectionState from './socketConnectionState';
import MessageEnvelope from './messageEnvelope';
import SocketEvents from './socketEvents';

/**
 *
 * @typedef {Object} SDK.Socket
 *
 * @property {FlowControlPolicy} FlowControlPolicy
 * @property {SDK.Socket.FlowControlPolicyType} FlowControlPolicyType
 * @property {SDK.Socket.SocketConnectionState} SocketConnectionState
 * @property {MessageEnvelope} MessageEnvelope
 * @property {SDK.Socket.SocketEvents} SocketEvents
 *
 */
export default {
    FlowControlPolicy,
    FlowControlPolicyType,
    SocketConnectionState,
    MessageEnvelope,
    SocketEvents
};
