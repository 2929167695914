/**
 *
 * @module tokenClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 *
 */
export default class TokenClientExtrasMap {
    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc Gets or sets the platform ID.
     *
     */
    public platformId?: string;

    /**
     *
     * @param {Object} [options]
     * @param {String} options.platformId
     *
     */
    public constructor(options?: { platformId: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    platformId: Types.nonEmptyString
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const { platformId } = options || {};

        this.platformId = platformId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Token.TokenClientExtrasMap';
    }
}
