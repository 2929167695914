/**
 *
 * @module entry
 * @desc {SDK.Platform} entry point.
 *
 */

import PlatformMetricsProvider from './platformMetricsProvider';

/**
 *
 * @typedef {Object} SDK.Platform
 * @since 7.0.0
 *
 * @property {PlatformMetricsProvider} PlatformMetricsProvider
 *
 */
export default {
    PlatformMetricsProvider
};
