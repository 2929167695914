/**
 *
 * @module eligibilityClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 4.2.0
 * @typedef {Object} SDK.Services.Eligibility.EligibilityClientEndpoint
 *
 * @property {String} getEligibilityStatus
 *
 */
enum EligibilityClientEndpoint {
    getEligibilityStatus = 'getEligibilityStatus'
}

export default EligibilityClientEndpoint;
