/**
 *
 * @module account
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import UserProfile from './userProfile';
import Identity from './identity';

/**
 *
 * @access public
 * @desc Represents the account information associated with a given a user.
 *
 */
export default class Account {
    /**
     *
     * @access public
     * @type {String}
     * @desc Gets the ID of the account.
     *
     */
    public accountId: string;

    /**
     *
     * @access public
     * @since 3.5.0
     * @type {UserProfile}
     *
     */
    public activeProfile: UserProfile;

    /**
     *
     * @access public
     * @type {Object}
     * @desc Gets the attributes of an account that may be returned by the service.
     *
     */
    public attributes?: object;

    /**
     *
     * @access public
     * @since 3.5.0
     * @type {Array<Identity>}
     *
     */
    public identities: Array<Identity>;

    /**
     *
     * @param {Object} options
     * @param {String} options.accountId - the account ID.
     * @param {UserProfile} options.activeUserProfile
     * @param {Object} [options.attributes={}] - the account attributes which can be modified and saved.
     * @param {Array<Identity>} [options.identities=[]] - an array of associated Identities
     *
     */
    public constructor(options: {
        accountId: string;
        activeUserProfile: UserProfile;
        attributes?: object;
        identities?: Array<Identity>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountId: Types.nonEmptyString,
                    activeUserProfile: Types.instanceStrict(UserProfile),
                    attributes: Types.object().optional,
                    identities: Types.array.of.object({
                        identityId: Types.nonEmptyString,
                        providerId: Types.nonEmptyString
                    }).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            accountId,
            activeUserProfile,
            attributes = {},
            identities = []
        } = options || {};

        this.accountId = accountId;
        this.activeProfile = activeUserProfile;
        this.attributes = attributes;

        this.identities = identities.map(
            (identity) => new Identity(identity.identityId, identity.providerId)
        );
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Account.Account';
    }
}
