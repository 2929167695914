/**
 *
 * @module uuidv4
 *
 */
import { v4 as uuidv4 } from 'uuid';

/**
 *
 * @private
 * @since 28.1.0
 * @desc returns a v4 uuid.
 * @returns {String}
 *
 */
export default uuidv4;
