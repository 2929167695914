/**
 *
 * @module platform
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.Platform
 * @desc Enum for the possible platform values in the ConfigurationClientConfiguration
 *
 * @property {String} chromecast
 * @property {String} netcast
 * @property {String} panasonictv
 * @property {String} sonybravia
 * @property {String} tivo
 * @property {String} tizen
 * @property {String} trilithium
 * @property {String} vizio
 * @property {String} webos
 * @property {String} canalPlus
 * @property {String} skyq - Since `4.5.0`.
 * @property {String} cableVision - Since `4.10.0`.
 * @property {String} comcastx1 - Since `5.0.0`.
 * @property {String} telecentro - Since `5.1.0`.
 * @property {String} toshibaRegza - Since `8.0.0`.
 * @property {String} hisense - Since `8.0.0`.
 * @property {String} rogersx1 - Since `9.0.0`.
 * @property {String} coxx1 - Since `10.0.0`.
 * @property {String} xglobal - Since `11.0.0`.
 * @property {String} shawx1 - Since `19.0.0`.
 * @property {String} ditv - Since `22.0.0`.
 * @property {String} pwaWindowsApp - Since `24.0.0`.
 * @property {String} foxtel - Since `26.0.0`.
 * @property {String} charter - Since `26.0.0`.
 *
 */
enum Platform {
    chromecast = 'chromecast',
    netcast = 'netcast',
    panasonictv = 'panasonictv',
    sonybravia = 'sonybravia',
    tivo = 'tivo',
    tizen = 'tizen',
    trilithium = 'trilithium',
    vizio = 'vizio',
    webos = 'webos',
    canalPlus = 'canalPlus',
    skyq = 'skyq',
    cableVision = 'cableVision',
    comcastx1 = 'comcastx1',
    telecentro = 'telecentro',
    toshibaRegza = 'toshibaRegza',
    hisense = 'hisense',
    rogersx1 = 'rogersx1',
    coxx1 = 'coxx1',
    xglobal = 'xglobal',
    shawx1 = 'shawx1',
    ditv = 'ditv',
    pwaWindowsApp = 'pwaWindowsApp',
    foxtel = 'foxtel',
    charter = 'charter'
}

export default Platform;
