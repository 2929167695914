/**
 *
 * @module accessStorage
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import PlatformProviders from '../services/providers/platformProviders';
import Access from './access';
import AccessContextState from './accessContextState';
import AccessContext from '../services/token/accessContext';
import CoreStorageProvider from '../services/providers/shared/coreStorageProvider';

import {
    ACCESS_KEY,
    AUTHORIZATION_CONTEXT_KEY
} from '../services/providers/shared/storageConstants';

import { REPLACE_NL_WS } from '../constants';

/**
 *
 * @desc Provides a storage mechanism for storing Access.
 *
 */
export default class AccessStorage {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.Access|null}
     *
     */
    private access: Nullable<Access>;

    /**
     *
     * @access private
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    private cacheKey: string;

    /**
     *
     * @access private
     * @type {String}
     * @desc legacy (version 2) cacheKey reference
     *
     */
    private cacheKeyLegacy: string;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc helps determine if you are migrating from a v2 -> v3 token
     *
     */
    private isLegacyToken: boolean;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.Access} [options.access=null]
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        logger: Logger;
        access?: Access;
        storage: CoreStorageProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    access: Types.instanceStrict(Access).optional,
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientId, environment, logger, access, storage } = options;

        this.clientId = clientId;

        this.environment = environment;

        this.logger = logger;

        this.access = access || null;

        this.storage = storage;

        this.cacheKey = `
            ${ACCESS_KEY}${this.clientId}_${this.environment}
        `.replace(REPLACE_NL_WS, '');

        this.cacheKeyLegacy = `
            ${AUTHORIZATION_CONTEXT_KEY}${this.clientId}_${this.environment}
        `.replace(REPLACE_NL_WS, '');

        this.isLegacyToken = false;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {SDK.Token.Access} access
     * @desc Stores given Access in memory and in Storage
     * @returns {Promise<Void>}
     *
     */
    public async saveAccess(access: Access) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                access: Types.instanceStrict(Access)
            };

            typecheck(this, 'saveAccess', params, arguments);
        }

        this.logger.info(
            this.toString(),
            `Saving Access to Storage for key: "${this.cacheKey}".`
        );

        this.access = access;

        await this.storage.set(this.cacheKey, this.access);
    }

    /**
     *
     * @access protected
     * @desc Gets Access from memory
     * @returns {SDK.Token.Access|null}
     *
     */
    public getAccess() {
        return this.access;
    }

    /**
     *
     * @access protected
     * @desc Deletes the current Access from memory and from Storage
     * @returns {Promise<Void>}
     *
     */
    protected async clear() {
        this.logger.warn(
            this.toString(),
            `Deleting Access from Storage for key: "${this.cacheKey}".`
        );

        this.access = null;

        await this.storage.remove(this.cacheKey);
    }

    /**
     *
     * @access protected
     * @desc Gets Access from Storage.
     * @note Executed a single time on AccessStorage instantiation.
     * @note Checks Storage to determine if Access was previously stored.
     * @note Should fail silently if no Access found but still returns it from memory if there is one.
     * @returns {Promise<SDK.Token.Access|null>}
     *
     */
    public async loadAccessFromStorage() {
        this.logger.info(
            this.toString(),
            `Loading Access from Storage for key: "${this.cacheKey}".`
        );

        try {
            const access = await this.storage.get(this.cacheKey);

            if (access) {
                const accessContext = new AccessContext(access.context);
                const contextStateModes = new AccessContextState(
                    access.contextState.modes
                );

                this.access = new Access(accessContext, contextStateModes);

                return this.access;
            }
        } catch (ex) {} // eslint-disable-line no-empty

        if (Check.assigned(this.access)) {
            this.logger.info(
                this.toString(),
                'Could not load Access from Storage but it is in memory.'
            );
        } else {
            this.logger.info(
                this.toString(),
                'Could not load Access from Storage and memory is null.'
            );
        }

        return this.access;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.AccessStorage';
    }
}
