/**
 *
 * @module entry
 * @desc {SDK.Account} entry point.
 *
 */

import Gender from './gender';

/**
 *
 * @typedef {Object} SDK.Account
 *
 * @property {SDK.Account.Gender} Gender
 *
 */
export default {
    Gender
};
