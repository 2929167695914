/**
 *
 * @module exploreClientEndpoint
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @access protected
 * @since 23.1.0
 * @typedef {Object} SDK.Services.Explore.ExploreClientEndpoint
 *
 * @property {String} getDeeplink - Since `23.1.0` - Supporting method for interpreting incoming deep links and for navigating between Content API-powered surfaces and Explore API-powered surfaces.
 * @property {String} getDownloadMetadata - Since `27.0.0` - Gets a map of avail IDs to download metadata for the active user.
 * @property {String} getPage - Since `23.1.0` - Method for details metadata.
 * @property {String} getSet - Since `23.1.0` - Method for details metadata.
 * @property {String} getUserState - Since `23.1.0` - Gets a map of entity ID to entity state for a user.
 * @property {String} getPlayerExperience - Since `24.0.0`.
 * @property {String} messageEnvelope - Since `24.0.0`.
 * @property {String} getSeason - Since `24.0.0`.
 * @property {String} getUpNext - Since `25.0.0`.
 * @property {String} search - Since `25.0.0`.
 * @property {String} getPartnerContinueWatching - Since `28.3.0`.
 *
 */
enum ExploreClientEndpoint {
    getDeeplink = 'getDeeplink',
    getDownloadMetadata = 'getDownloadMetadata',
    getPage = 'getPage',
    getSet = 'getSet',
    getUserState = 'getUserState',
    getPlayerExperience = 'getPlayerExperience',
    messageEnvelope = 'messageEnvelope',
    getSeason = 'getSeason',
    getUpNext = 'getUpNext',
    search = 'search',
    getPartnerContinueWatching = 'getPartnerContinueWatching'
}

export default ExploreClientEndpoint;
