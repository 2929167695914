/**
 *
 * @module subscriptionProvider
 *
 */

/**
 *
 * @enum
 * @typedef {Object} SDK.Subscription.SubscriptionProvider
 * @desc Defines the providers of subscriptions.
 *
 * @property {String} BAMTECH - The subscription was initiated through BAMTECH.
 * @property {String} APPLE - The subscription was initiated through Apple.
 * @property {String} GOOGLE - The subscription was initiated through Google.
 * @property {String} ROKU - The subscription was initiated through Roku.
 * @property {String} SAMSUNG - The subscription was initiated through Samsung.
 * @property {String} HULU - Since `4.1.2` - The subscription was initiated through Hulu.
 * @property {String} AMAZON - Since `4.1.2` - The subscription was initiated through Amazon.
 * @property {String} VERIZON - Since `4.9.0` - The subscription was initiated through Verizon.
 * @property {String} NO_PAYMENT - Since `4.9.0` - The subscription was initiated without payment.
 * @property {String} CANAL - Since `4.9.0` - The subscription was initiated through Canal.
 * @property {String} FREE - Since `4.9.0` - The subscription was Free.
 * @property {String} BOUYGUES - Since `4.9.0` - The subscription was initiated through Bouygues.
 * @property {String} SKYUK - Since `4.9.0` - The subscription was initiated through SkyUK.
 * @property {String} MOVISTAR - Since `4.9.0` - The subscription was initiated through Moviestar.
 * @property {String} TIM - Since `7.0.0` - The subscription was initiated through Telecom Italia.
 * @property {String} ORANGE - Since `4.9.0` - The subscription was initiated through Orange.
 * @property {String} DETELEKOM - Since `7.0.0` - The subscription was initiated through Deutsche Telekom.
 * @property {String} SFR - Since `4.9.0` - The subscription was initiated through SFR.
 * @property {String} O2 - Since `4.9.0` - The subscription was initiated through O2
 *
 */
enum SubscriptionProvider {
    BAMTECH = 'BAMTECH',
    APPLE = 'APPLE',
    GOOGLE = 'GOOGLE',
    ROKU = 'ROKU',
    SAMSUNG = 'SAMSUNG',
    HULU = 'HULU',
    AMAZON = 'AMAZON',
    VERIZON = 'VERIZON',
    NO_PAYMENT = 'NO_PAYMENT',
    CANAL = 'CANAL',
    FREE = 'FREE',
    BOUYGUES = 'BOUYGUES',
    SKYUK = 'SKYUK',
    MOVISTAR = 'MOVISTAR',
    TIM = 'TIM',
    ORANGE = 'ORANGE',
    DETELEKOM = 'DETELEKOM',
    SFR = 'SFR',
    O2 = 'O2'
}

export default SubscriptionProvider;
