/**
 *
 * @module entry
 * @desc {SDK.Internal.Dust} entry point.
 *
 */

import DustSink from './dustSink';
import DustEvent from './dustEvent';
import EdgeEvent from './edgeEvent';
import EdgeSink from './edgeSink';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Internal.Dust
 *
 * @property {DustSink} DustSink
 * @property {DustEvent} DustEvent
 * @property {EdgeEvent} EdgeEvent
 * @property {EdgeSink} EdgeSink
 *
 */
export default {
    DustSink,
    DustEvent,
    EdgeEvent,
    EdgeSink
};
