/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import { PaymentMethod, PaymentMethodTypedef } from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.DisneyRewards.DisneyRewardsPaymentMethod
 * @since 9.0.0
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#extend-params
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {String} maskedCard - Masked card type
 * @property {String} country - Card country. See ISO 3166-1 alpha-2.
 * @property {String} issuer - Card issuer. UNKNOWN when the issuer cannot be determined.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface DisneyRewardsPaymentMethod extends PaymentMethod {
    maskedCard: string;
    country: string;
    issuer: string;
}

/**
 *
 * @access private
 *
 */
export const DisneyRewardsPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    maskedCard: Types.nonEmptyString,
    country: Types.nonEmptyString,
    issuer: Types.nonEmptyString
};
