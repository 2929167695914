/**
 *
 * @module errorContextMethod
 *
 */

import { MethodExceptions } from '../../exception/exceptionDefinitions';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Filters through the `MethodExceptions` object to determine the error case based on
 * the HTTP status code and method name.
 * @returns {Exception|undefined}
 *
 */
export default function errorContextMethod(status: number, methodName: string) {
    let exceptionData;

    for (const key of Object.keys(MethodExceptions)) {
        const methodException = MethodExceptions[key];

        const { errorCases } = methodException;

        if (Array.isArray(errorCases) && errorCases.length) {
            const isErrorCase = errorCases.some((value) => {
                if (value.status === status && value.method === methodName) {
                    return true;
                }

                return false;
            });

            if (isErrorCase) {
                exceptionData = methodException;
            }
        }

        if (exceptionData) {
            break;
        }
    }

    return exceptionData;
}
