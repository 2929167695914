/**
 *
 * @module sessionManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/session.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import OrchestrationClient from '../services/orchestration/orchestrationClient';

import Logger from '../logging/logger';
import SessionInfoStorage from './sessionInfoStorage';
import SessionInfoRequest from './sessionInfoRequest';
import FeatureFlagsStorage from './featureFlagsStorage';
import TokenManager from '../token/tokenManager';
import SessionInfo from '../services/session/sessionInfo';
import LogTransaction from '../logging/logTransaction';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import AccessToken from '../token/accessToken';

/**
 *
 * @access protected
 * @see https://nodejs.org/api/events.html
 * @desc Manages the current session and serves as a go
 * between for the public APIs and the OrchestrationClient.
 *
 */
export default class SessionManager {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 4.3.0
     * @type {SDK.Session.SessionInfoStorage}
     *
     */
    public storage: SessionInfoStorage;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Session.FeatureFlagsStorage}
     *
     */
    private featureFlagsStorage: FeatureFlagsStorage;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {SDK.Services.Orchestration.OrchestrationClient}
     *
     */
    private orchestrationClient: OrchestrationClient;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Session.SessionInfoStorage} options.sessionInfoStorage
     * @param {SDK.Session.FeatureFlagsStorage} options.featureFlagsStorage
     * @param {SDK.Services.Orchestration.OrchestrationClient} options.orchestrationClient
     *
     */
    public constructor(options: {
        logger: Logger;
        tokenManager: TokenManager;
        sessionInfoStorage: SessionInfoStorage;
        featureFlagsStorage: FeatureFlagsStorage;
        orchestrationClient: OrchestrationClient;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    tokenManager: Types.instanceStrict(TokenManager),
                    sessionInfoStorage:
                        Types.instanceStrict(SessionInfoStorage),
                    featureFlagsStorage:
                        Types.instanceStrict(FeatureFlagsStorage),
                    orchestrationClient:
                        Types.instanceStrict(OrchestrationClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            logger,
            tokenManager,
            sessionInfoStorage,
            featureFlagsStorage,
            orchestrationClient
        } = options;

        this.logger = logger;
        this.tokenManager = tokenManager;
        this.storage = sessionInfoStorage;
        this.featureFlagsStorage = featureFlagsStorage;
        this.orchestrationClient = orchestrationClient;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 3.1.0
     * @param {SDK.Logging.LogTransaction} [logTransaction]
     * @desc Gets information about the current session.
     * @note Attempts to pull the information from a cached copy. If the cached copy exists its used, otherwise a
     * request is made to services. When an `AccessChanged` event is emitted, the cached version is cleared.
     * @returns {Promise<SDK.Services.Session.SessionInfo>}
     *
     */
    public async getInfo(logTransaction?: LogTransaction) {
        const { storage } = this;

        const sessionInfo = await storage.getSessionInfo();

        if (Check.instanceStrict(sessionInfo, SessionInfo)) {
            return sessionInfo;
        }

        if (logTransaction) {
            return this.getInfoFromServices(logTransaction);
        }

        return LogTransaction.wrapLogTransaction({
            file: this.toString(),
            urn: DustUrnReference.sdkSession.getSessionInfo,
            logger: this.logger,
            action: async (newLogTransaction) =>
                this.getInfoFromServices(newLogTransaction)
        });
    }

    /**
     *
     * @access protected
     * @since 4.3.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note The `SessionInfoStorage` `saveSessionInfo` method emits `InternalEvents.SessionInfoChanged`.
     * @desc Retrieves session info from services
     * @returns {Promise<SDK.Services.Session.SessionInfo>}
     *
     */
    public async getInfoFromServices(logTransaction: LogTransaction) {
        const { accessToken, storage } = this;

        const data = await this.orchestrationClient.query<{
            activeSession: Record<string, unknown>;
        }>({
            request: new SessionInfoRequest(),
            accessToken: accessToken as AccessToken,
            logTransaction
        });

        const newSessionInfo = SessionInfo.create(data.activeSession);

        await storage.saveSessionInfo(newSessionInfo);

        return newSessionInfo;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Returns the feature flags object for the current session.
     * @returns {Promise<Object|null>}
     *
     */
    public async getFeatureFlags() {
        const featureFlags = await this.featureFlagsStorage.getFeatureFlags();

        return featureFlags;
    }

    /**
     *
     * @access protected
     * @since 24.0.0
     * @desc Constructs the message subject in the proper format
     * @returns {String|null} Returns the message subject in `sessionId=123,profileId=456` format. Either `sessionId`
     * or `profileId` can be missing. If both are missing then `null` is returned.
     *
     */
    public async createMessageSubject() {
        // we have this in a try/catch as some early messages come in before we can acquire
        // the `sessionInfo` - it gets tacked on later in the workflow if it doesn't exist

        try {
            if (!this.accessToken) {
                return null;
            }

            const sessionInfo = await this.getInfo();

            if (sessionInfo) {
                const sessionId = sessionInfo.id;
                const profileId = sessionInfo.profile?.id;
                const subjects: Array<string> = [];

                if (sessionId) {
                    subjects.push(`sessionId=${sessionId}`);
                }

                if (profileId) {
                    subjects.push(`profileId=${profileId}`);
                }

                if (subjects.length) {
                    return subjects.join();
                }
            }
        } catch (error) {
            // no-op
        }

        return null;
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the TokenManager instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken();
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Session.SessionManager';
    }
}
