/**
 *
 * @module playbackEventListener
 *
 */

import type PlaybackStartupEventData from '../services/qualityOfService/playbackStartupEventData';
import type PlaybackMetricsProvider from './playbackMetricsProvider';
import type { HlsPlaylist } from './playerAdapter';

import type {
    AdMultivariantFetchedEvent,
    AdPlaybackEndedEvent,
    AdPlaybackStartedEvent,
    AdPodFetchedEvent,
    AdPodRequestedEvent,
    AdVariantFetchedEvent,
    AudioBitrateChangedEvent,
    BitrateChangedEvent,
    InterstitialPlaybackEndedEvent,
    InterstitialPlaybackStartedEvent,
    MultivariantPlaylistFallbackEvent,
    MultivariantPlaylistFetchedEvent,
    NonFatalPlaybackErrorEvent,
    PlaybackCustomEvent,
    PlaybackEndedEvent,
    PlaybackErrorInfo,
    PlaybackInitializedEvent,
    PlaybackPausedEvent,
    PlaybackReadyEvent,
    PlaybackResumedEvent,
    PlaybackSeekEndedEvent,
    PlaybackSeekStartedEvent,
    PlaybackStartedEvent,
    PresentationTypeChangedEvent,
    RebufferingEndedEvent,
    RebufferingStartedEvent,
    SnapshotCreatedEvent,
    VariantPlaylistFetchedEvent,
    ViewingEnvironmentChangedEvent
} from './typedefs';

import { PlaybackMode } from '../services/qualityOfService/enums';

/**
 *
 * @since 2.0.0
 *
 */
export default abstract class PlaybackEventListener {
    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackStartedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: PlaybackStartedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackPausedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackPaused(
        provider: PlaybackMetricsProvider,
        args: PlaybackPausedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackResumedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackResumed(
        provider: PlaybackMetricsProvider,
        args: PlaybackResumedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingStartedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onRebufferingStarted(
        provider: PlaybackMetricsProvider,
        args: RebufferingStartedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingEndedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onRebufferingEnded(
        provider: PlaybackMetricsProvider,
        args: RebufferingEndedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackEndedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: PlaybackEndedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackInitializedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackInitialized(
        provider: PlaybackMetricsProvider,
        args: PlaybackInitializedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackReadyEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackReady(
        provider: PlaybackMetricsProvider,
        args: PlaybackReadyEvent
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekStartedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackSeekStarted(
        provider: PlaybackMetricsProvider,
        args: PlaybackSeekStartedEvent
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekEndedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackSeekEnded(
        provider: PlaybackMetricsProvider,
        args: PlaybackSeekEndedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @returns {Void}
     *
     */
    public abstract onAudioChanged(provider: PlaybackMetricsProvider): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @returns {Void}
     *
     */
    public abstract onSubtitleChanged(provider: PlaybackMetricsProvider): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.BitrateChangedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onBitrateChanged(
        provider: PlaybackMetricsProvider,
        args: BitrateChangedEvent
    ): void;

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AudioBitrateChangedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAudioBitrateChanged(
        provider: PlaybackMetricsProvider,
        args: AudioBitrateChangedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onMultivariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: MultivariantPlaylistFetchedEvent
    ): void;

    /**
     *
     * @access public
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onVariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: VariantPlaylistFetchedEvent
    ): void;

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.DrmKeyFetchedEventData} args
     * @returns {Void}
     *
     */
    public abstract onDrmKeyFetched(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ): void;

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.PlaybackStartupEventData} args
     * @returns {Void}
     *
     */
    public abstract onPlaybackReattempt(
        provider: PlaybackMetricsProvider,
        args: PlaybackStartupEventData
    ): void;

    /**
     *
     * @access public
     * @since 15.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     * @returns {Void}
     *
     */
    public abstract onSuccessfulPlaylistLoad(
        provider: PlaybackMetricsProvider,
        args: HlsPlaylist
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PresentationTypeChangedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onPresentationTypeChanged(
        provider: PlaybackMetricsProvider,
        args: PresentationTypeChangedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.SnapshotCreatedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onSnapshotCreated(
        provider: PlaybackMetricsProvider,
        args: SnapshotCreatedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdPodRequestedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdPodRequested(
        provider: PlaybackMetricsProvider,
        args: AdPodRequestedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdPodFetchedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdPodFetched(
        provider: PlaybackMetricsProvider,
        args: AdPodFetchedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdMultivariantFetchedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdMultivariantFetched(
        provider: PlaybackMetricsProvider,
        args: AdMultivariantFetchedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdVariantFetchedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdVariantFetched(
        provider: PlaybackMetricsProvider,
        args: AdVariantFetchedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdPlaybackStartedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: AdPlaybackStartedEvent
    ): void;

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdPlaybackEndedEvent>} args
     * @returns {Void}
     *
     */
    public abstract onAdPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: AdPlaybackEndedEvent
    ): void;

    /**
     *
     * @access public
     * @since 22.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEvent>} args
     *
     */
    public abstract onAdBeaconError(
        provider: PlaybackMetricsProvider,
        args: NonFatalPlaybackErrorEvent
    ): void;

    /**
     *
     * @access public
     * @since 22.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEvent>} args
     *
     */
    public abstract onAdRequestedError(
        provider: PlaybackMetricsProvider,
        args: NonFatalPlaybackErrorEvent
    ): void;

    /**
     *
     * @access public
     * @since 22.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public abstract onPlaybackError(
        provider: PlaybackMetricsProvider,
        args: PlaybackErrorInfo
    ): void;

    /**
     *
     * @access public
     * @since 27.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} args
     *
     */
    public abstract onPlaybackModeChanged(
        provider: PlaybackMetricsProvider,
        args: PlaybackMode
    ): void;

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.ViewingEnvironmentChangedEvent>} args
     *
     */
    public abstract onViewingEnvironmentChanged(
        provider: PlaybackMetricsProvider,
        args: ViewingEnvironmentChangedEvent
    ): void;

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.InterstitialPlaybackStartedEvent>} args
     *
     */
    public abstract onInterstitialPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: InterstitialPlaybackStartedEvent
    ): void;

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.InterstitialPlaybackEndedEvent>} args
     *
     */
    public abstract onInterstitialPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: InterstitialPlaybackEndedEvent
    ): void;

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFallbackEvent>} args
     *
     */
    public abstract onMultivariantPlaylistFallback(
        provider: PlaybackMetricsProvider,
        args: MultivariantPlaylistFallbackEvent
    ): void;

    /**
     *
     * @access public
     * @since 28.2.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackCustomEvent>} args
     *
     */
    public abstract onPlaybackCustomEvent(
        provider: PlaybackMetricsProvider,
        args: PlaybackCustomEvent
    ): void;

    /**
     *
     * @access public
     * @since 28.4.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {String} programBoundaryInfoBlock
     *
     */
    public abstract updateProgramMetadata(
        provider: PlaybackMetricsProvider,
        programBoundaryInfoBlock: string
    ): void;

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.PlaybackEventListener';
    }
}
