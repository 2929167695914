/**
 *
 * @module tokenManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import { AccessTokenType } from 'sdk-types/types/orchestration-enums';

import VersionInfo from '../versionInfo';

import Logger from '../logging/logger';
import Access from './access';
import AccessState from './accessState';
import AccessStateData from './accessStateData';
import AccessStorage from './accessStorage';
import AccessToken from './accessToken';
import AccessTokenProvider from './accessTokenProvider';
import AccessContextState from './accessContextState';
import TokenRefreshFailure from './tokenRefreshFailure';
import Providers from '../providers/platformProviders';
import AccessContext from '../services/token/accessContext';
import AccessChangedEvent from '../accessChangedEvent';
import InternalEvents from '../internalEvents';
import OrchestrationManager from '../orchestration/orchestrationManager';
import ErrorCode from '../services/exception/errorCode';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import LogTransaction from '../logging/logTransaction';

import DeviceManager from '../device/deviceManager';

import DeviceGrantStorage from './deviceGrantStorage';
import ExchangeGrant from '../services/token/exchangeGrant';
import RefreshTokenDelegation from '../services/token/refreshTokenDelegation';
import TokenClient from '../services/token/tokenClient';
import TokenManagerConfiguration from '../services/configuration/tokenManagerConfiguration';

import ServiceException from '../services/exception/serviceException';
import AccountDelegationRefreshTokenStorage from './accountDelegationRefreshTokenStorage';

import DeviceGrant from '../services/token/deviceGrant';
import AccountGrant from '../services/account/accountGrant';
import TokenRequestBuilder from '../services/token/tokenRequestBuilder';
import { IGeoProvider } from '../providers/IGeoProvider';
import { createInvalidStateException } from '../services/util/errorHandling/createException';

type DeviceGrantOptions = { deviceGrant: DeviceGrant; userToken?: string };

/**
 *
 * @access protected
 * @see https://nodejs.org/api/events.html
 * @desc Manages the current AccessContext, allows tokens to be exchanged to get a new AccessContext
 * and auto refreshes the AccessContext before it expires.
 *
 */
export default class TokenManager extends AccessTokenProvider {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private apiKey: string;

    /**
     *
     * @access private
     * @type {SDK.Services.Configuration.TokenManagerConfiguration}
     *
     */
    private config: TokenManagerConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Services.Token.TokenClient}
     *
     */
    private client: TokenClient;

    /**
     *
     * @access private
     * @type {IGeoProvider}
     *
     */
    private geoProvider: IGeoProvider;

    /**
     *
     * @access private
     * @type {SDK.Device.DeviceManager}
     *
     */
    public deviceManager: DeviceManager;

    /**
     *
     * @access private
     * @since 10.0.0
     * @type {SDK.Orchestration.OrchestrationManager}
     *
     */
    private orchestrationManager: OrchestrationManager;

    /**
     *
     * @access private
     * @since 4.11.0
     * @type {Function}
     * @desc a function to be called to update session info after token updates
     *
     */
    private refreshSessionInfo: (logTransaction: LogTransaction) => void;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {DeviceGrantStorage}
     *
     */
    private deviceGrantStorage: DeviceGrantStorage;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {AccountDelegationRefreshTokenStorage}
     *
     */
    private accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;

    /**
     *
     * @access private
     * @type {Number} timeout ID or -1
     *
     */
    private refreshTimeoutId: number;

    /**
     *
     * @access private
     * @type {Number}
     * @desc retry counter for access token refresh
     *
     */
    private retryAttempt: number;

    /**
     *
     * @access private
     * @type {Number} timeout ID or -1
     *
     */
    private retryDelay: number;

    /**
     *
     * @access protected
     * @since 8.0.0
     * @type {Promise}
     *
     */
    public currentTokenRefreshPromise: Nullable<Promise<TodoAny>>;

    /**
     *
     * @param {Object} options
     * @param {String} options.apiKey
     * @param {SDK.Services.Configuration.TokenManagerConfiguration} options.tokenManagerConfiguration
     * @param {SDK.Services.Token.TokenClient} options.tokenClient
     * @param {SDK.Token.DefaultGeoProvider} [options.geoProvider=null]
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessStorage} options.storage
     * @param {SDK.Device.DeviceManager} options.deviceManager
     * @param {SDK.Token.DeviceGrantStorage} options.deviceGrantStorage
     * @param {SDK.Orchestration.OrchestrationManager} options.orchestrationManager
     * @param {Function} options.refreshSessionInfo
     * @param {SDK.Token.AccountDelegationRefreshTokenStorage} options.accountDelegationRefreshTokenStorage
     *
     */
    public constructor(options: {
        apiKey: string;
        tokenManagerConfiguration: TokenManagerConfiguration;
        tokenClient: TokenClient;
        geoProvider?: IGeoProvider;
        logger: Logger;
        storage: AccessStorage;
        deviceManager: DeviceManager;
        deviceGrantStorage: DeviceGrantStorage;
        orchestrationManager: OrchestrationManager;
        refreshSessionInfo: (logTransaction: LogTransaction) => void;
        accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;
    }) {
        const { storage, logger } = options || {};

        super(storage, logger);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    apiKey: Types.nonEmptyString,
                    tokenManagerConfiguration: Types.instanceStrict(
                        TokenManagerConfiguration
                    ),
                    tokenClient: Types.instanceStrict(TokenClient),
                    geoProvider: Types.instanceStrict(Providers.GeoProvider)
                        .optional,
                    logger: Types.instanceStrict(Logger),
                    storage: Types.instanceStrict(AccessStorage),
                    deviceManager: Types.instanceStrict(DeviceManager),
                    deviceGrantStorage:
                        Types.instanceStrict(DeviceGrantStorage),
                    orchestrationManager:
                        Types.instanceStrict(OrchestrationManager),
                    refreshSessionInfo: Types.function,
                    accountDelegationRefreshTokenStorage: Types.instanceStrict(
                        AccountDelegationRefreshTokenStorage
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            apiKey,
            tokenManagerConfiguration,
            tokenClient,
            deviceManager,
            deviceGrantStorage,
            orchestrationManager,
            refreshSessionInfo,
            accountDelegationRefreshTokenStorage,
            geoProvider
        } = options;

        this.apiKey = apiKey;

        this.config = tokenManagerConfiguration;

        this.client = tokenClient;

        this.geoProvider = geoProvider ?? new Providers.GeoProvider(logger);

        this.logger = logger;

        this.deviceManager = deviceManager;

        this.orchestrationManager = orchestrationManager;

        this.refreshSessionInfo = refreshSessionInfo;

        this.deviceGrantStorage = deviceGrantStorage;

        this.accountDelegationRefreshTokenStorage =
            accountDelegationRefreshTokenStorage;

        this.refreshTimeoutId = -1;

        this.retryAttempt = 0;

        this.retryDelay = -1;

        this.currentTokenRefreshPromise = null;

        this.logger.log(this.toString(), 'Created');
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.DeviceGrant} options.deviceGrant
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Exchanges a device grant for an access token.
     * @returns {Promise<Void>}
     *
     */
    public async exchangeDeviceGrant(
        options: DeviceGrantOptions,
        logTransaction: LogTransaction
    ) {
        await this.orchestrationManager.exchangeDeviceGrantForAccessToken(
            options,
            logTransaction
        );
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {SDK.Services.Account.AccountGrant} accountGrant
     * @param {SDK.Token.AccessContextState} accessContextState
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Exchanges an account grant for an access token.
     * @returns {Promise<Void>}
     *
     */
    public async exchangeAccountGrant(
        accountGrant: AccountGrant,
        accessContextState: AccessContextState,
        logTransaction: LogTransaction
    ) {
        const subjectTokenType =
            this.config.extras.subjectTokenTypes[
                accountGrant.subjectTokenTypeKey
            ];
        const exchangeGrant = new ExchangeGrant(accountGrant, subjectTokenType);

        await this.exchangeRequest(
            exchangeGrant,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access protected
     * @param {SDK.Services.Token.TokenRequestBuilder} tokenRequestBuilder
     * @param {SDK.Token.AccessContextState} accessContextState
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Void>}
     *
     */
    public async exchangeRequest(
        tokenRequestBuilder: TokenRequestBuilder,
        accessContextState: AccessContextState,
        logTransaction: LogTransaction
    ) {
        const { apiKey, client } = this;

        try {
            const geoLocation = await this.geoProvider.getGeoLocation();

            const { latitude, longitude } = geoLocation;

            const tokenRequest = await tokenRequestBuilder.build(
                latitude,
                longitude
            );

            const accessContext = await client.exchange(
                tokenRequest,
                apiKey,
                logTransaction
            );

            await this.processAccess({
                accessContext,
                accessContextState,
                logTransaction
            });
        } catch (exception) {
            await this.handleTokenException(exception as Error, logTransaction);
        }
    }

    /**
     *
     * @access protected
     * @desc Gets the access state of the user in a format that can be
     * used to rebuild the state in an external system.
     * @returns {SDK.Token.AccessState}
     *
     */
    public getAccessState() {
        const access = this.storage.getAccess() as Access;

        return new AccessState(
            VersionInfo.versionShort,
            new AccessStateData(
                access.context.token,
                access.context.refreshToken,
                access.contextState
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.17.0
     * @param {Object} options
     * @param {Object} options.newAccessToken - The new accessToken
     * @param {Boolean} [options.shouldRefreshSessionInfo=true]
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Updates an accessToken when it's received via a service response.
     * @returns {Promise<Void>}
     *
     */
    public async updateAccessToken(options: {
        newAccessToken: Record<string, TodoAny>;
        shouldRefreshSessionInfo?: boolean;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                object: Types.object({
                    newAccessToken: Types.object(),
                    shouldRefreshSessionInfo: Types.boolean.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'updateAccessToken', params, arguments);
        }

        const {
            newAccessToken,
            shouldRefreshSessionInfo = true,
            logTransaction
        } = options;

        const {
            accessToken,
            expiresIn,
            refreshToken,
            tokenType,
            region,
            accessTokenType
        } = newAccessToken;

        const accessContext = new AccessContext({
            token: accessToken,
            tokenType,
            refreshToken,
            expiresIn,
            region,
            accessTokenType
        });

        let accessContextState;

        if (accessTokenType === AccessTokenType.Device) {
            accessContextState = new AccessContextState();
        } else if (
            accessTokenType === AccessTokenType.AccountWithoutActiveProfile
        ) {
            accessContextState = new AccessContextState();
        } else {
            // when type is not Device, access always exist (token is not anonymous)

            const access = this.storage.getAccess() as Access;

            accessContextState = access.contextState;
        }

        await this.processAccess({
            accessContext,
            accessContextState,
            shouldRefreshSessionInfo,
            logTransaction
        });
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} options
     * @param {String} options.refreshToken
     * @param {String} [options.reason]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Refreshes access token, process failures if necessary
     * @returns {Promise<Void>}
     *
     */
    private async executeTokenRefresh(
        options: { refreshToken: string; reason?: string },
        logTransaction: LogTransaction
    ) {
        try {
            await this.orchestrationManager.refreshToken(
                options,
                logTransaction
            );
        } catch (exception) {
            await this.handleTokenException(exception as Error, logTransaction);
        } finally {
            this.currentTokenRefreshPromise = null;
        }
    }

    /**
     *
     * @access protected
     * @param {Object} [options={}]
     * @param {Boolean} [options.forceRefresh=false] - Forces a refresh regardless of the token's current expiration
     * @param {String} [options.reason] - The reason why the token is being refreshed.
     * @param {SDK.Logging.LogTransaction} [options.logTransaction]
     * @desc Refreshes the access token, if it is about to expire.
     * @note Ensure a proper Access is available before executing logic
     * @note The refreshToken is recast, however the contextState is not because it
     * is always expected to be a instance of AccessContextState at this point
     * @returns {Promise<Void>}
     *
     */
    public async refreshAccessToken(
        options: {
            forceRefresh?: boolean;
            reason?: string;
            logTransaction?: LogTransaction;
        } = { forceRefresh: false }
    ) {
        if (this.currentTokenRefreshPromise) {
            this.logger.info(
                this.toString(),
                'Waiting for current token refresh operation in progress to complete.'
            );

            return this.currentTokenRefreshPromise;
        }

        const { reason, forceRefresh = false } = options;

        const access = this.storage.getAccess();

        if (access) {
            const tokenExpired = access.context.isAccessTokenExpired(
                this.config.extras.refreshThreshold
            );

            if (forceRefresh || tokenExpired) {
                const refreshToken = access.context.refreshToken;
                const refreshData = { refreshToken, reason };

                if (options.logTransaction) {
                    this.currentTokenRefreshPromise = this.executeTokenRefresh(
                        refreshData,
                        options.logTransaction
                    );
                } else {
                    // // otherwise, create a new one
                    LogTransaction.wrapLogTransaction({
                        urn: DustUrnReference.services.orchestration
                            .orchestrationClient.refreshToken,
                        file: this.toString(),
                        logger: this.logger,
                        action: async (logTransaction) => {
                            this.currentTokenRefreshPromise =
                                this.executeTokenRefresh(
                                    refreshData,
                                    logTransaction
                                );
                        }
                    });
                }
            }

            return this.currentTokenRefreshPromise;
        }

        throw createInvalidStateException(
            'A successful token exchange is required before refresh is allowed'
        );
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Error} exception
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Handles an exception and in specific cases attempts to reset the access token using the current device grant.
     * @returns {Promise<Void>}
     *
     */
    private async handleTokenException(
        exception: Error,
        logTransaction: LogTransaction
    ) {
        const storedDeviceGrant = this.deviceGrantStorage.getDeviceGrant();

        let secondaryException;

        try {
            if (this.isInvalidTokenException(exception as ServiceException)) {
                await this.reset(
                    { deviceGrant: storedDeviceGrant as DeviceGrant },
                    logTransaction
                );
            }

            throw exception;
        } catch (ex) {
            secondaryException = ex;

            // if the device grant has become invalid then get a new one.
            // note that a device grant must always be established
            if (
                this.isInvalidTokenException(
                    secondaryException as ServiceException
                )
            ) {
                // establish a new device grant, clear out the reset retry counter on success
                await this.deviceManager.createDeviceGrant(
                    undefined /* @todo how to acquire hulu token possibly related to https://jira.disneystreaming.com/browse/SDKMRJS-4433? */,
                    logTransaction
                );

                return;
            }

            throw secondaryException;
        } finally {
            this.emit(
                InternalEvents.TokenRefreshFailed,
                new TokenRefreshFailure(
                    (secondaryException ?? exception) as ServiceException,
                    true
                )
            );
        }
    }

    /**
     *
     * @access protected
     * @since 4.7.0
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Removes the current user and resets to an anonymous state
     * @returns {Promise<Void>}
     *
     */
    public async logout(logTransaction: LogTransaction) {
        // establish an anonymous state
        const deviceGrant = await this.deviceManager.getDeviceGrant(
            undefined,
            logTransaction
        );

        // exchange anonymous device grant
        await this.reset({ deviceGrant }, logTransaction);

        // remove account delegation refresh token from storage
        await this.accountDelegationRefreshTokenStorage.clear();
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Token.DeviceGrant} options.deviceGrant - An object that is capable of providing all the data needed to perform a token exchange.
     * @param {String} [options.userToken]
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Exchanges a grant for an access token and starts the auto refresh timer.
     * @note Only works for device grant
     * @returns {Promise<Void>}
     *
     */
    public async reset(
        options: DeviceGrantOptions,
        logTransaction: LogTransaction
    ) {
        try {
            this.clearRetryRefresh();

            await this.exchangeDeviceGrant(options, logTransaction);
        } catch (exception) {
            // if the device grant has become invalid then get a new one.
            // note that a device grant must always be established
            if (this.isInvalidTokenException(exception as ServiceException)) {
                // establish a new device grant, clear out the reset retry counter on success
                await this.deviceManager.createDeviceGrant(
                    options,
                    logTransaction
                );
            } else {
                throw exception;
            }
        }
    }

    /**
     *
     * @access protected
     * @param {String} accessState - The serialized state retrieved via SdkSession#getAccessState
     * could be retrieved from another device, instance, etc...
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Exchanges a grant for an access token and starts the auto refresh timer.
     * @returns {Promise<Void>}
     *
     */
    public async restoreAccessState(
        accessState: string,
        logTransaction: LogTransaction
    ) {
        const { data } = JSON.parse(accessState);
        const { refreshToken, contextState } = data;

        const access = this.storage.getAccess();
        const delegationToken = new RefreshTokenDelegation(refreshToken);
        const accessContextState = new AccessContextState(contextState.modes);

        // backfill the current access context device refresh token
        delegationToken.actor = access?.context.refreshToken as string;

        this.clearRetryRefresh();

        await this.exchangeRequest(
            delegationToken,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access protected
     * @param {String} mode - Unique key for a given state.
     * @returns {Boolean} A boolean indicating whether or not the access context is set for the given state.
     *
     */
    public hasAccessMode(mode: string) {
        const access = this.storage.getAccess();

        if (Check.assigned(access)) {
            const accessState = access?.contextState.hasMode(mode) ?? false;

            return accessState;
        }

        return false;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessContext} options.accessContext
     * @param {SDK.Token.AccessContextState} options.accessContextState
     * @param {Boolean} [options.shouldRefreshSessionInfo=true]
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @emits {SDK.InternalEvents.AccessChanged} The event raised each time a access token is updated.
     * @desc utility function, handles common functionality for exchange
     * @returns {Promise<Void>}
     *
     */
    private async processAccess(options: {
        accessContext: AccessContext;
        accessContextState: AccessContextState;
        shouldRefreshSessionInfo?: boolean;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessContext: Types.instanceStrict(AccessContext),
                    accessContextState:
                        Types.instanceStrict(AccessContextState),
                    shouldRefreshSessionInfo: Types.boolean.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'processAccess', params, arguments);
        }

        const {
            accessContext,
            accessContextState,
            shouldRefreshSessionInfo = true,
            logTransaction
        } = options;

        const access = new Access(accessContext, accessContextState);
        const accessToken = new AccessToken(
            accessContext.token,
            accessContext.region
        );

        try {
            await this.storage.saveAccess(access);
        } catch (ex) {
            this.logger.error(
                this.toString(),
                'Unable to save Access to local storage.'
            );
        }

        this.logger.info(this.toString(), 'Dispatch AccessChanged event.');

        this.emit(
            InternalEvents.AccessChanged,
            new AccessChangedEvent(accessToken)
        );

        this.enqueueRefresh();

        if (shouldRefreshSessionInfo) {
            await this.refreshSessionInfo(logTransaction);
        }
    }

    /**
     *
     * @access private
     * @desc uses setTimeout to enqueue a call to #refreshAccessToken
     *
     */
    private enqueueRefresh() {
        if (this.config.extras.disableTokenRefresh) {
            this.logger.warn(
                this.toString(),
                'TokenRefresh is disabled, skipping refresh logic.'
            );

            return;
        }

        const access = this.storage.getAccess();

        if (!access) {
            throw createInvalidStateException(
                'A successful token exchange is required before refresh is allowed'
            );
        }

        if (!access.context.refreshToken) {
            this.logger.warn(
                this.toString(),
                'refreshToken missing, skipping refresh logic.'
            );

            return;
        }

        this.clearRetryRefresh();

        const refreshAt = access.context.getRefreshAt(
            this.config.extras.refreshThreshold
        );

        const delay = refreshAt - Date.now();

        this.retryAttempt = 0;
        this.retryDelay =
            (this.config.extras.autoRefreshRetryPolicy as TodoAny)
                .retryBasePeriod * 1000;

        // we need to use `window` or
        // TS looks to Node's `setTimeout` which returns NodeJs.Timeout
        this.refreshTimeoutId = setTimeout(
            this.retryRefresh.bind(this),
            delay
        ) as unknown as number;
    }

    /**
     *
     * @access private
     * @desc attempts to retry refreshing access
     * @emits {SDK.InternalEvents.TokenRefreshFailed} The event raised when automatic token refresh fails.
     * @returns {Promise<Void>}
     *
     */
    private retryRefresh() {
        const { config, logger } = this;

        const autoRefreshRetryPolicy = config.extras
            .autoRefreshRetryPolicy as TodoAny;
        const retryMaxAttempts = autoRefreshRetryPolicy.retryMaxAttempts;
        const retryMultiplier = autoRefreshRetryPolicy.retryMultiplier;
        const retryMaxPeriod = autoRefreshRetryPolicy.retryMaxPeriod * 1000;
        const forceRefresh = true;

        this.clearRetryRefresh();

        return LogTransaction.wrapLogTransaction({
            urn: DustUrnReference.services.orchestration.orchestrationClient
                .refreshToken,
            file: this.toString(),
            logger,
            /**
             *
             * @param {SDK.Logging.LogTransaction} logTransaction
             *
             */
            action: (logTransaction) => {
                return this.refreshAccessToken({
                    forceRefresh,
                    logTransaction
                }).catch((ex) => {
                    if (this.retryAttempt < retryMaxAttempts) {
                        // we need to use cast here because
                        // TS looks to Node's `setTimeout` which returns NodeJs.Timeout
                        this.refreshTimeoutId = setTimeout(
                            this.retryRefresh.bind(this),
                            this.retryDelay
                        ) as unknown as number;

                        const increasedRetryDelay =
                            this.retryDelay * retryMultiplier;

                        if (increasedRetryDelay < retryMaxPeriod) {
                            this.retryDelay = increasedRetryDelay;
                        } else {
                            this.retryDelay = retryMaxPeriod;
                        }

                        this.retryAttempt++;
                    } else {
                        logger.error(this.toString(), 'Token refresh failed.');
                        logger.info(
                            this.toString(),
                            'Dispatch TokenRefreshFailed event.'
                        );

                        this.emit(
                            InternalEvents.TokenRefreshFailed,
                            new TokenRefreshFailure(ex)
                        );
                    }

                    throw ex;
                });
            }
        });
    }

    /**
     *
     * @access private
     * @desc cancels all pending actions
     *
     */
    public clearRetryRefresh() {
        clearTimeout(this.refreshTimeoutId);

        this.refreshTimeoutId = -1;
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @desc Checks if an exception is an invalid token.
     * @returns {Boolean} True if the exception is an invalid token.
     *
     */
    private isInvalidTokenException(ex: ServiceException) {
        const errorCode = ex.reasons[0]?.code;
        const isInvalid =
            errorCode === ErrorCode.tokenServiceInvalidGrant.code ||
            errorCode === ErrorCode.invalidGrant.code;

        return isInvalid;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Token.TokenManager';
    }
}
