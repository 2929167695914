/**
 *
 * @module advertisingPlugin
 * @desc {SDK.Advertising.AdvertisingPlugin} entry point.
 *
 */

import type { IPlugin } from '../IPlugin';
import Advertising from './entry';

const plugin: IPlugin<'Advertising', typeof Advertising> = {
    pluginName: 'Advertising',
    entry: Advertising
};

/**
 *
 * @access public
 * @since 18.0.0
 *
 */
export default plugin;
