/**
 *
 * @module embeddedConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ConfigurationClientConfiguration from '../services/configuration/configurationClientConfiguration';
import ConfigurationClientExtrasMap from '../services/configuration/configurationClientExtrasMap';
import ConfigurationHostName from '../services/configuration/configurationHostName';
import ServiceEndpoint from '../services/configuration/serviceEndpoint';
import { IEndpoints } from '../services/providers/typedefs';

import {
    configHostParams,
    configPathnameTemplate,
    defaultConfigHostName
} from '../_utils/bam-browser-sdk-configuration-util';

/**
 *
 *
 */
export default class EmbeddedConfiguration {
    public static headers = {
        Accept: 'application/json'
    };

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ConfigurationHostName}
     * @desc The default configuration host name to use ("dev", "release" or "staging").
     *
     */
    public defaultConfigHostName: ConfigurationHostName;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ConfigurationClientConfiguration}
     *
     */
    public client: ConfigurationClientConfiguration;

    /**
     *
     * @param {Object} options
     * @param {Boolean} [options.isFallback=false] - flag to determine if the EmbeddedConfiguration
     * instance is being used as a fallback (for example, if config fetch fails)
     *
     */
    public constructor(options: { isFallback: boolean }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    isFallback: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { isFallback = false } = options;

        let href = configPathnameTemplate;

        if (isFallback) {
            href = href
                .replace(/\{deviceProfile\}/gi, 'default')
                .replace(/\{applicationRuntime\}/gi, 'browser');
        }

        const endpoints = {
            bootstrap: new ServiceEndpoint({
                rel: 'bootstrap',
                href,
                ttl: 0,
                timeout: 30,
                templated: true,
                headers: EmbeddedConfiguration.headers
            })
        } as IEndpoints<Record<string, ServiceEndpoint>>;

        this.defaultConfigHostName = defaultConfigHostName;
        this.client = new ConfigurationClientConfiguration({
            baseUrl: defaultConfigHostName,
            endpoints,
            extras: new ConfigurationClientExtrasMap(configHostParams)
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Configuration.EmbeddedConfiguration';
    }
}
