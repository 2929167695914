/**
 *
 * @module eligibilityClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the EligibilityClient.
 *
 */
export default class EligibilityClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Eligibility.EligibilityClientConfiguration';
    }
}
