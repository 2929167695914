/**
 *
 * @module socketApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import FlowControlPolicy from './messages/flowControlPolicy';
import socketConnectionState from './socketConnectionState';
import socketConnectionStateMapping from './socketConnectionStateMapping';
import MessageEnvelope from './messageEnvelope';
import SocketManager from './socketManager';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import Logger from '../logging/logger';
import { ApiOptions } from '../typedefs';
import EnvelopeMessageRouter from '../internal/envelopeMessageRouter';

const DustUrn = DustUrnReference.socket.socketApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.9.0
 * @desc Provides ability to access sockets.
 *
 */
export default class SocketApi {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SDK.Socket.EnvelopeMessageRouter}
     *
     */
    private envelopeMessageRouter: EnvelopeMessageRouter;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Socket.SocketManager} options.socketManager
     *
     */
    public constructor(options: {
        logger: Logger;
        socketManager: SocketManager;
        envelopeMessageRouter: EnvelopeMessageRouter;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    socketManager: Types.instanceStrict(SocketManager),
                    envelopeMessageRouter: Types.instanceStrict(
                        EnvelopeMessageRouter
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const { socketManager, envelopeMessageRouter, logger } = options;

        this.socketManager = socketManager;
        this.envelopeMessageRouter = envelopeMessageRouter;
        this.logger = logger;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc The current state of the socket connection.
     * @returns {SocketConnectionStateMapping}
     *
     */
    public get connectionState() {
        const readyState = this.socketManager.currentSocketReadyState;

        if (!this.socketManager.enabled) {
            return socketConnectionState.disabled;
        }

        return (
            // @ts-ignore TODO work around compiler error
            socketConnectionStateMapping[readyState] ||
            socketConnectionState.closed
        );
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Enables the Events@Edge websocket connection, if configured
     * @throws {SDK.Services.Exception.ServiceException}
     * @returns {Promise<Void>} Starts management of socket connections, opening a connection if necessary. This method has no effect if the SDK is already managing a socket connection.
     *
     */
    @apiMethodDecorator()
    public async start() {
        return this.socketManager.start();
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Terminates the Events@Edge websocket connection
     * @returns {Promise<Boolean>} Closes the current connection and suspends management of socket connections.
     *
     */

    @apiMethodDecorator()
    public async stop() {
        return this.socketManager.stop();
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {MessageEnvelope} messageEnvelope - the event to send
     * @desc Serializes the data into an Edge service envelope and sends it over the socket connection.
     * @returns {Promise<Object>} Will return an Object representing the full message envelope sent through the socket.
     *
     */
    public async sendMessage(
        messageEnvelope: MessageEnvelope
    ): Promise<unknown>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            messageEnvelope: Types.instanceStrict(MessageEnvelope)
        }
    })
    public async sendMessage(apiOptions?: unknown) {
        const {
            args: [messageEnvelope]
        } = apiOptions as ApiOptions<[MessageEnvelope]>;

        await this.envelopeMessageRouter.route(messageEnvelope);
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows access to socket messages from the underlying SDK socket connection.
     * @param {String} urn - The name of the event.
     * @param {Function} listener - The callback function.
     * @returns {EventEmitter}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public on(urn: string, listener: (...args: Array<any>) => void) {
        return this.socketManager.on(urn, listener);
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows access to unsubscribe from socket messages on the underlying SDK socket connection.
     * @param {String} urn - The name of the event.
     * @param {Function} listener - The callback function.
     * @returns {EventEmitter}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public off(urn: string, listener: (...args: Array<any>) => void) {
        return this.socketManager.off(urn, listener);
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @desc Request a specific flow rate of events from the Event-Edge platform
     * @param {Array<SDK.Socket.FlowControlPolicy>} policies
     * @returns {Promise<Void>}
     *
     */
    public async requestFlowControl(policies: Array<FlowControlPolicy>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                policies: Types.array.of.instanceStrict(FlowControlPolicy)
            };

            typecheck(this, 'requestFlowControl', params, arguments);
        }

        return this.socketManager.requestFlowControl(policies);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {Object} [options]
     * @param {Number} [options.pingInterval] - The number of seconds between ping attempts.
     * @param {Number} [options.pongTimeout] - The number of seconds to wait for a pong response after sending a ping.
     * @param {Number} [options.pingMaxAttempts] - The number of unsuccessful ping attempts before trying to reestablish
     * the connection.
     * @desc Enables pinging the socket server
     * @returns {Void}
     *
     */
    public enablePing(options?: {
        pingInterval?: number;
        pongTimeout?: number;
        pingMaxAttempts?: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    pingInterval: Types.number.optional,
                    pongTimeout: Types.number.optional,
                    pingMaxAttempts: Types.number.optional
                }).optional
            };

            typecheck(this, 'enablePing', params, arguments);
        }

        this.socketManager.enablePing(options);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @desc Disables pinging the socket server
     * @returns {Void}
     *
     */
    public disablePing() {
        this.socketManager.disablePing();
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public toString() {
        return 'SDK.Socket.SocketApi';
    }
}
