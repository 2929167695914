/**
 *
 * @module consoleDebug
 *
 */

import { NodeEnv } from '../../../typedefs';

import Logger from '../../../logging/logger';
import ErrorReason from '../../exception/errorReason';
import ServiceException from '../../exception/serviceException';
import { ServerResponse } from '../../providers/typedefs';

type ExtendedConsole = typeof console & {
    [key: string]: (...logs: Array<TodoAny>) => void;
};

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Logs server response info to the console for debugging.
 * @note Only logs if debug mode is enabled and in the test environment.
 *
 */
export default function consoleDebug(
    errors: Array<ErrorReason>,
    exception: ServiceException,
    response: ServerResponse
) {
    if (Logger.instance.debugEnabled && process.env.NODE_ENV === NodeEnv.Test) {
        const { status, headers, url, body } = response;

        const eConsole = console as ExtendedConsole;

        eConsole.info('\n**********************************************');

        eConsole.log('DEBUG: response.url:', url);
        eConsole.log('DEBUG: response.status:', status);
        eConsole.log('DEBUG: response.headers:', headers);
        eConsole.log('DEBUG: response.body:', body);
        eConsole.log('DEBUG: exception.reasons:', exception.reasons);

        if (errors) {
            eConsole.log('DEBUG: Graph Response Errors', errors);
        }

        eConsole.info('**********************************************\n');
    }
}
