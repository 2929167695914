/**
 *
 * @module product
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Campaign from './campaign';
import IntroPrice from './introPrice';
import PaywallSubscription from './paywallSubscription';
import ProductType from './productType';

type ProductOptions = {
    name: string;
    productType: ProductType;
    sku: string;
    entitlements: Array<string>;
    campaign?: Nullable<Campaign>;
    introPrice?: Nullable<IntroPrice>;
    subscriptionId?: Nullable<string>;
    groups?: Array<string>;
    subscription?: PaywallSubscription;
    offerId?: string;
    paywallEvent?: string;
    purchaseBehavior?: string;
};

/**
 *
 * @since 3.9.0
 * @desc The available product information.
 *
 */
export default class Product {
    /**
     *
     * @access public
     * @since 3.9.0
     * @type {SDK.Services.Paywall.Campaign|null}
     * @desc The campaign information, will be null for IAP products.
     *
     */
    public campaign: Nullable<Campaign>;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {Array<String>}
     * @desc The product entitlements.
     *
     */
    public entitlements: Array<string>;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {Array<String>|undefined}
     * @desc Maps to product categories in commerce.
     *
     */
    public groups?: Array<string>;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {SDK.Services.Paywall.IntroPrice|null}
     * @desc The introductory pricing info, will be null for direct billing products.
     *
     */
    public introPrice: Nullable<IntroPrice>;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String}
     * @desc The product name.
     *
     */
    public name: string;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String<SDK.Services.Paywall.ProductType>}
     * @desc The product type (IAP or D2C).
     *
     */
    public productType: ProductType;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String}
     * @desc The product sku.
     *
     */
    public sku: string;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Services.Paywall.PaywallSubscription|undefined}
     * @desc Subscription details so that apps can present upgrades for bundles and subscription updates
     *
     */
    public subscription?: PaywallSubscription;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     * @desc Identifier to apply applicable offer.
     *
     */
    public offerId?: string;

    /**
     *
     * @access public
     * @since 8.0.0
     * @type {String|null}
     * @desc For active entitled users this field identify an existing subscription that can be switched to this
     * product, will be null for NOT active entitled.
     *
     */
    public subscriptionId: Nullable<string>;

    /**
     *
     * @access public
     * @since 8.0.0
     * @type {String|undefined}
     * @desc The method how this product can be purchased.
     *
     */
    public paywallEvent?: string;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String|undefined}
     * @desc Represents this product's purchase behavior whether it is immediately switch or deferred switch.
     * This is an open string instead of an enum because there could be other behavior values added in the future.
     *
     */
    public purchaseBehavior?: string;
    /**
     *
     * @param {Object} options
     * @param {String} options.name
     * @param {String<SDK.Services.Paywall.ProductType>} options.productType
     * @param {String} options.sku
     * @param {Array<String>} options.entitlements
     * @param {SDK.Services.Paywall.Campaign} [options.campaign]
     * @param {SDK.Services.Paywall.IntroPrice} [options.introPrice]
     * @param {String} [options.subscriptionId]
     * @param {Array<String>} [options.groups=[]]
     * @param {SDK.Services.Paywall.PaywallSubscription} [options.subscription]
     * @param {String} [options.offerId]
     * @param {String} [options.paywallEvent]
     * @param {String} [options.purchaseBehavior]
     *
     */
    public constructor(options: ProductOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    name: Types.nonEmptyString,
                    productType: Types.in(ProductType),
                    sku: Types.nonEmptyString,
                    entitlements: Types.array.of.nonEmptyString,
                    campaign: Types.instanceStrict(Campaign).optional,
                    introPrice: Types.instanceStrict(IntroPrice).optional,
                    subscriptionId: Types.nonEmptyString.optional,
                    groups: Types.array.of.nonEmptyString.optional,
                    subscription:
                        Types.instanceStrict(PaywallSubscription).optional,
                    offerId: Types.nonEmptyString.optional,
                    paywallEvent: Types.nonEmptyString.optional,
                    purchaseBehavior: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            name,
            productType,
            sku,
            entitlements,
            campaign,
            introPrice,
            subscriptionId,
            groups,
            subscription,
            offerId,
            paywallEvent,
            purchaseBehavior
        } = options || {};

        this.campaign = campaign || null;

        this.entitlements = entitlements;

        this.groups = groups;

        this.introPrice = introPrice || null;

        this.name = name;

        this.productType = productType;

        this.sku = sku;

        this.subscription = subscription;

        this.offerId = offerId;

        this.subscriptionId = subscriptionId || null;

        this.paywallEvent = paywallEvent;

        this.purchaseBehavior = purchaseBehavior;
    }

    /**
     *
     * @access private
     * @since 9.0.0
     * @param {Object} data
     * @returns {SDK.Services.Paywall.Product}
     *
     */
    public static parse(data: ProductOptions) {
        const {
            campaign: campaignData,
            introPrice: introPriceData,
            subscription: subscriptionData,
            subscriptionId: subscriptionIdData
        } = data;

        const { campaignCode, voucherCode } = campaignData || ({} as Campaign);
        const { unit, price, length } = introPriceData || ({} as IntroPrice);
        const {
            sourceType,
            sourceProvider,
            // @ts-expect-error - input data differs from expected type
            subscriptionPeriod: paymentPeriod
        } = subscriptionData || ({} as PaywallSubscription);

        let campaign: Nullable<Campaign> = null;
        let introPrice: Nullable<IntroPrice> = null;
        let subscription;
        let subscriptionId: Nullable<string> = null;

        if (Check.assigned(campaignCode) && Check.assigned(voucherCode)) {
            campaign = new Campaign(campaignCode, voucherCode);
        }

        if (
            Check.assigned(unit) &&
            Check.assigned(price) &&
            Check.assigned(length)
        ) {
            introPrice = new IntroPrice({
                length,
                price,
                unit
            });
        }

        if (
            Check.assigned(sourceType) &&
            Check.assigned(sourceProvider) &&
            Check.assigned(paymentPeriod)
        ) {
            subscription = new PaywallSubscription({
                sourceType,
                sourceProvider,
                paymentPeriod
            });
        }

        if (Check.assigned(subscriptionIdData)) {
            subscriptionId = subscriptionIdData as string;
        }

        return new Product({
            ...data,
            campaign,
            introPrice,
            subscription,
            subscriptionId
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.Product';
    }
}
