/**
 *
 * @module pingPongMessageProcessor
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Ping from './ping';
import WebSocketStates from '../webSocketStates';
import MessageProcessorBase from './messageProcessorBase';
import Logger from '../../logging/logger';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import SocketManagerConfiguration from '../../services/configuration/socketManagerConfiguration';
import type SocketManager from '../socketManager';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.18.0
 * @desc Handles the server ping logic to detect an active connection
 *
 */
export default class PingPongMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Services.Configuration.SocketManagerConfiguration}
     *
     */
    private config: SocketManagerConfiguration;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Object}
     *
     */
    private pingPolicy: TodoAny;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     *
     */
    private intervalId?: ReturnType<typeof setTimeout>;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Socket.Ping|null}
     *
     */
    private currentPing: Ping | null;

    /**
     *
     * @param {Object} options
     * @param {SDK.Socket.SocketManager} options.socketManager
     * @param {SDK.Services.Configuration.SocketManagerConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        socketManager: SocketManager;
        config: SocketManagerConfiguration;
        logger: Logger;
    }) {
        const messageType = SocketUrns.socketManager.pong;

        super(messageType);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    socketManager: Types.assigned, // not importing SocketManager due to circular import reference
                    config: Types.instanceStrict(SocketManagerConfiguration),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { socketManager, config, logger } = options;

        const defaultPingPolicy = {
            disabled: false,
            pingInterval: 5,
            pongTimeout: 5,
            pingMaxAttempts: 2
        };

        this.socketManager = socketManager;
        this.config = config;
        this.logger = logger;
        this.pingPolicy = {
            ...defaultPingPolicy,
            ...this.config.extras.pingPolicy
        };
        this.intervalId = undefined;
        this.currentPing = null;
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @param {Object} [options]
     * @param {Number} [options.pingInterval]
     * @param {Number} [options.pongTimeout]
     * @param {Number} [options.pingMaxAttempts]
     * @desc enable the ping handler
     * @returns {Void}
     *
     */
    public enable(options?: {
        pingInterval?: number;
        pongTimeout?: number;
        pingMaxAttempts?: number;
    }) {
        this.pingPolicy = { ...this.pingPolicy, ...options };

        this.pingPolicy.disabled = false;
        this.start();
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc disable the ping handler
     * @returns {Void}
     *
     */
    public disable() {
        this.pingPolicy.disabled = true;
        this.stop();
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc stop the ping handler
     * @returns {Void}
     *
     */
    public stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.intervalId = undefined;
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc handle the pong message
     *
     */
    public async process() {
        if (this.currentPing) {
            this.currentPing.pong();
            this.logger.info('PingPongMessageProcessor', 'Pong received');
        }
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc a message has been received so reset the ping process
     *
     */
    public reset() {
        if (this.currentPing) {
            this.currentPing.pong();
            this.logger.info(
                'PingPongMessageProcessor',
                'Message received, resetting the ping timer'
            );
        }

        this.start();
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc sends a ping message
     * @returns {Promise<Void>}
     *
     */
    public async start() {
        await new Promise((resolve, reject) => {
            if (this.pingPolicy.disabled) {
                resolve(undefined);

                return;
            }

            if (!this.isSocketConnected) {
                setTimeout(() => {
                    this.start().then(resolve, reject);
                }, this.pingPolicy.pongTimeout * 1000);

                return;
            }

            if (this.intervalId) {
                this.stop();
            }

            this.intervalId = setTimeout(async () => {
                this.currentPing = new Ping({
                    socketManager: this.socketManager,
                    logger: this.logger,
                    pongTimeout: this.pingPolicy.pongTimeout,
                    pingMaxAttempts: this.pingPolicy.pingMaxAttempts
                });

                try {
                    await this.currentPing.send();
                } catch (err) {
                    this.stop();
                    await this.socketManager.beginReconnecting();
                }

                this.start().then(resolve, reject);
            }, this.pingPolicy.pongTimeout * 1000);
        });
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc returns whether the socket state is currently open
     * @returns {Boolean}
     *
     */
    public get isSocketConnected() {
        return this.socketManager.isSocketInReadyState(WebSocketStates.OPEN);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.PingPongMessageProcessor';
    }
}
