/**
 *
 * @module playbackStartupEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback-startup.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ServerRequest from './serverRequest';

import PlaybackAttributes from '../media/playbackAttributes';
import PlaybackVariant from '../media/playbackVariant';

import { PlaylistType } from '../media/enums';

import {
    AudioRendition,
    AudioRenditionTypedef,
    SubtitleRendition,
    SubtitleRenditionTypedef
} from '../media/typedefs';

import {
    AdInsertionType,
    ApplicationProtocol,
    AudioTrackType,
    MediaFetchError,
    MediaSegmentType,
    NetworkType,
    QoePlaybackError,
    PlaybackIntent,
    ProductType,
    StartupActivity,
    TimedTextTrackType
} from './enums';

type ContentKeys = {
    channelId?: string;
    collectionId?: string;
    contentId?: string;
    encodedFamilyId?: string;
    encodedSeriesId?: string;
    eventId?: string;
    infoBlockItem?: string;
    leagueId?: string;
    mediaId?: string;
    programId?: string;
    sportId?: string;
    superEventId?: string;
    teamId?: string;
};

/**
 *
 * @since 13.0.0
 * @desc These events represent playback startup related events. This event should be sent every time the `startupActivity` changes.
 * @note urn:dss:event:client:playback:startup:v1
 *
 */
export default class PlaybackStartupEventData {
    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.StartupActivity}
     * @desc Used to identify what startup activity is occurring.
     *
     */
    public startupActivity: StartupActivity;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc client generated ID of the stream/playback session.
     * @note Is added in the `logQoeEvent` method in `SDK.Media.PlaybackTelemetryDispatcher`.
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ProductType|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
     * @note Required if `startupActivity` is `ready`, `initialized`, `masterFetched`, `variantFetched`, `drmCertificateFetched`, or `drmKeyFetched`.
     * @note Omit if unavailable and the `startupActivity` is `requested` or `fetched`.
     * @note This is the intended startup position.
     * @note Source from the associated playback event that matches the `StartupActivity` (e.g. `SDK.Media.PlaybackInitializedEvent`).
     *
     */
    public playheadPosition: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.NetworkType}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType: NetworkType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.PlaybackIntent|undefined}
     * @desc The intent which started playback.
     * @note Required if `startupActivity` is `requested`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public playbackIntent?: PlaybackIntent;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc True if pre buffering is occurring, false if not.
     * @note Required if `startupActivity` is `requested`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public mediaPreBuffer: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The amount of video currently fully buffered in milliseconds.
     * @note Required if `startupActivity` is `ready`.
     * @note Source from the `SDK.Media.PlaybackReadyEvent`.
     *
     */
    public bufferSegmentDuration?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The name of the video player used to play a stream.
     * @note Required if `startupActivity` is `preparing`, `masterFetched`,
     * `variantFetched`, `drmCertificateFetched`, `drmKeyFetched`, `initialized`, `ready`, `reattempt`.
     * @note Source from the `SDK.Media.PlayerAdapter`.
     * @note If available, send on all startup activities.
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The version of the video player used to play a stream.
     * @note Required if `startupActivity` is `preparing`, `masterFetched`,
     * `variantFetched`, `drmCertificateFetched`, `drmKeyFetched`, `initialized`, `ready`, `reattempt`.
     * @note Source from the `SDK.Media.PlayerAdapter`.
     * @note If available, send on all startup activities.
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc For media served by PBO v6 and below, the playbackUrl represents the url before the playback scenario has been injected.
    '* For media served by PBO v7 and above, the playbackUrl represents one of the following:
    '* A base64 encoded resourceId, representing content returned from the Explore API.
    '* A base64 encoded mediaId which is a JSON object formatted as { "mediaId": value }, representing content returned from the Content API.
     * @note Required if `startupActivity` is `fetched`.
     * @note Source from the `SDK.Media.MediaDescriptor.MediaLocator.id` passed into `SDK.Media.MediaApi.fetch()`.
     *
     */
    public playbackUrl?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc One of the pre-defined playback scenarios.
     * @note Required if `startupActivity` is `fetched`.
     * @note Source from the playback scenario derived during `SDK.Media.MediaApi.fetch()`.
     *
     */
    public playbackScenario?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.Media.PlaylistType|undefined}
     * @desc The variety of live playlist.
     * @note Required if `startupActivity` is `masterFetched`.
     * @note Source from the `SDK.Services.Media.PlaylistType` passed into `SDK.Media.MediaApi.fetch()`.
     *
     */
    public playlistLiveType?: PlaylistType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc True if the media is being played from a local source, e.g. it has been downloaded for offline playback or was bundled in the application.
     * @note Required if `startupActivity` is `requested`, `fetched`, `masterFetched`, `variantFetched`, `drmCertificateFetched`, `drmKeyFetched`, `initialized`, preparing`, or `ready`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public localMedia: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ServerRequest|undefined}
     * @desc Data about a request/response to a server.
     * @note Required if `startupActivity` is `fetched`, `masterFetched`, `variantFetched`, `drmCertificateFetched`, or `drmKeyFetched`.
     * @note ServerRequest information may not be available for all of the required startupActivity events on every platform.
     * Send the ServerRequest data when able to do so.
     *
     */
    public serverRequest?: ServerRequest;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Current available CPU as a percentage.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `requested`.
     * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
     *
     */
    public cpuPercentage?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Current available memory in MB.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `requested`.
     * @note Source from the `SDK.Platform.PlatformMetricsProvider`.
     *
     */
    public freeMemory?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Audio channels as defined by the selected playlist variant.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistAudioChannels?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Audio codec as defined by the selected playlist variant.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistAudioCodec?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Audio language as defined by the selected audio rendition.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistAudioLanguage?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Audio name as defined by the selected audio rendition.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistAudioName?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Starting bitrate (in bps), if set by application.
     * @note For HLS playlists this is the 'BANDWIDTH' property.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public mediaStartBitrate?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Subtitle language as defined by a variant playlist.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistSubtitleLanguage?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Name of the subtitle.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistSubtitleName?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean|undefined}
     * @desc Subtitle visibility.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public subtitleVisibility?: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Video codec as defined by a variant playlist.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistVideoCodec?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Video range as defined by a variant playlist.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public playlistVideoRange?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The selected stream URL from the MediaPayload.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent`.
     *
     */
    public streamUrl?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.Media.PlaybackAttributes|undefined}
     * @desc Value of `playlistResponse.stream.attributes`.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
     * @note Source from the `SDK.Media.MediaPayload` response while fetching.
     *
     */
    public attributes?: PlaybackAttributes;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Array<SDK.Services.Media.PlaybackVariant>|undefined}
     * @desc Value of `playlistResponse.stream.variants`.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
     * @note Source from the `SDK.Media.MediaPayload` response while fetching.
     *
     */
    public streamVariants?: Array<PlaybackVariant>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.MediaFetchError|null}
     * @desc Additional error details when fetching media. `null` if no error occurred.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `fetched`.
     * @note Source from the reported error, if any, while fetching.
     *
     */
    public mediaFetchError: Nullable<MediaFetchError>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc ID representing the DRM Key. Obtained from the HLS Media Playlist, a new key is signalled by EXT-X-KEY tags.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `drmKeyFetched`.
     *
     */
    public drmKeyId?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The peak video bitrate of the current presentationType, in bps.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized` or `variantFetched`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent` when `startupActivity` is `variantFetched`.
     *
     */
    public videoBitrate?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The average video bitrate of the current presentationType, in bps.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `initialized` or `variantFetched`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent` when `startupActivity` is `variantFetched`.
     *
     */
    public videoAverageBitrate?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Value of `CHANNELS` attribute of variant playlist (if an audio variant).
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public playlistChannels?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Value of `NAME` attribute (for audio and subtitle variants).
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public playlistName?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Value of `LANGUAGE` attribute (for audio and subtitle variants).
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public playlistLanguage?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.MediaSegmentType|undefined}
     * @desc What type of content a media segment contains.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public mediaSegmentType?: MediaSegmentType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Resolution as defined by a variant playlist.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public playlistResolution?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Frame Rate as reported by the playlist service.
     * @note Heimdall 1.0 field. Can optionally be provided if `startupActivity` is `variantFetched`.
     * @note Source from the `SDK.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public playlistFrameRate?: number;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `MonotonicTimestampProvider.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Object}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: ContentKeys;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.QoePlaybackError|undefined}
     * @desc An error representing a fatal playback failure. `null` if no error occurred.
     * @note Can optionally be provided if `playbackActivity` is `ended`.
     * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
     *
     */
    public playbackError?: Nullable<QoePlaybackError>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Supporting error text for `playbackError` property where this can be provided for additional context.
     * @note Unless requested otherwise, set to native platform error message if available.
     * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
     * @note Source from the `SDK.Media.PlaybackEndedEvent`.
     *
     */
    public playbackErrorDetail?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Array<String>|undefined}
     * @desc List of CDNs attempted in case of failure cases e.g. {Fastly, Akamai, Level-3}.
     * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
     *
     */
    public cdnRequestedTrail?: Array<string>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Array<String>|undefined}
     * @desc List of CDNs failed e.g. {Fastly, Akamai}.
     * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
     *
     */
    public cdnFailedTrail?: Array<string>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Value increments from 0 for each fallback action. This value initializes at zero for the
     * first CDN attempt, and will increment by 1 for each fallback action that occurs.
     * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
     *
     */
    public cdnFallbackCount: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc False if first cdn selected successful i.e. `cdnFailedTrail` empty, True otherwise.
     * @note Heimdall 1.0 field. Required if `startupActivity` is `reattempt`.
     *
     */
    public isCdnFallback: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when testing PQM.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public clientGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public serverGroupIds?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.AdInsertionType|undefined}
     * @desc The way ads are inserted into the stream.
     * @note Conditionally required if `startupActivity` = `fetched`.
     * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
     *
     */
    public adInsertionType?: AdInsertionType;

    /**
     *
     * @access public
     * @since 22.0.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the playlist contains slugs.
     * @note Conditionally required if `startupActivity` != `requested`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.hasSlugs field.
     * @note Default to false if unavailable.
     * @note Omit this value from the `startupActivity` = `requested` event. Don't default the value for this `startupActivity` as the PlayIO response is required to source this field.
     *
     */
    public hasSlugs?: boolean;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.Media.SubscriptionType>}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that
     * is entitled for playback.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The number of total planned ad pods.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
     * @note Source by getting the count of the MediaPayload.MediaPayloadStream.Insertion.Points collection.
     * Each `InsertionPoint` represents an Ad Pod.
     *
     */
    public totalPodCount?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Random UUID assigned by the ad server.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
     *
     */
    public adSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The total number of planned ad slots.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
     * @note Source by calculating the sum of each `InsertionPoint.plannedSlotCount` from the
     * MediaPayload.MediaPayloadStream.Insertion.Points.
     *
     */
    public totalSlotCount?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The total length of planned ads in milliseconds.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
     * @note Source by calculating the sum of each `InsertionPoint.duration` from the
     * MediaPayload.MediaPayloadStream.Insertion.Points.
     *
     */
    public totalAdLength?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number}
     * @desc The status code of the create ad session request.
     * @note Conditionally required if `startupActivity` = `fetched`and `adInsertionType` != `none`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.responseCode field.
     *
     */
    public createAdSessionResponseCode?: number;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The status code of the get pods request.
     * @note Conditionally required if `startupActivity` = `fetched` and `adInsertionType` = `ssai`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.getPods.responseCode field.
     *
     */
    public getPodsResponseCode?: number;

    /**
     *
     * @access public
     * @since 21.1.1
     * @type {String}
     * @desc The CDN running the server.
     *
     */
    public cdnName: string;

    /**
     *
     * @access public
     * @since 21.1.1
     * @type {String}
     * @desc The vendor of the CDN.
     *
     */
    public cdnVendor: string;

    /**
     *
     * @access public
     * @since 21.1.1
     * @type {String}
     * @desc Name of the CDN, appended with Origin.
     *
     */
    public cdnWithOrigin: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to acquire the DRM certificate.
     * @note Conditionally required if `startupActivity` = `drmCertificateFetched`.
     *
     */
    public fetchCertificateDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {SDK.Services.QualityOfService.ApplicationProtocol}
     * @desc Provides indication of http vs https for multi-variant playlist (m3u8).
     *
     */
    public applicationProtocol: ApplicationProtocol;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the DRM certificate request begins.
     * @note Conditionally required if `startupActivity` = `drmCertificateFetched`.
     *
     */
    public fetchCertificateStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The `interactionId` will correspond with one of the defined `interactionTypes`. The `interactionId` will be used as
     * the primary key for all interactions.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for
     * all playback events when `interactionId` is not null.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String<SDK.Services.QualityOfService.AudioTrackType>}
     * @desc The track type of the currently selected audio rendition.
     * @note Conditionally Required when `startupActivity` is `initialized`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
     *
     */
    public playlistAudioTrackType: AudioTrackType;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the DRM license request begins.
     * @note Conditionally required if `startupActivity` = `drmKeyFetched`.
     *
     */
    public fetchLicenseStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to acquire the DRM license.
     * @note Conditionally required if `startupActivity` = `drmKeyFetched`.
     *
     */
    public fetchLicenseDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to create the non-UI player stack.
     * @note Conditionally required if `startupActivity` = `initialized`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackInitializedEvent`.
     *
     */
    public initializePlayerDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the creation of the non-UI player stack begins.
     * @note Conditionally required if `startupActivity` = `initialized`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackInitializedEvent`.
     *
     */
    public initializePlayerStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to load the multivariant playlist, including network request and local parse time.
     * @note Conditionally required if `startupActivity` = `masterFetched`.
     * @note Source from the associated `Bam.Sdk.Media.MultivariantPlaylistFetchedEvent`.
     *
     */
    public multivariantFetchedDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the multivariant playlist fetch begins.
     * @note Conditionally required if `startupActivity` = `masterFetched`.
     * @note Source from the associated `Bam.Sdk.Media.MultivariantPlaylistFetchedEvent`.
     *
     */
    public multivariantFetchedStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to load the Playlist Service response, including network request and local parse time.
     * @note Conditionally required if `startupActivity` = `fetched`.
     *
     */
    public playlistFetchedDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds of the call to Playlist Service to get information about a stream.
     * @note Conditionally required if `startupActivity` = `fetched`.
     *
     */
    public playlistFetchedStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to load the first media segment for all elementary streams (audio, video, and text) active at the start of playback.
     * @note Conditionally required if `startupActivity` = `ready`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackReadyEvent`.
     *
     */
    public readyPlayerDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the platform-appropriate playback measurement begins.
     * @note Conditionally required if `startupActivity` = `ready`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackReadyEvent`.
     *
     */
    public readyPlayerStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when a user signals intent to play back media.
     * @note Conditionally required if `startupActivity` = `requested`.
     *
     */
    public requestedStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to load the variant playlist, including network request and local parse time.
     * @note Conditionally required if `startupActivity` = `variantFetched`.
     * @note Source from the associated `Bam.Sdk.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public variantFetchedDuration?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the variant playlist fetch begins.
     * @note Conditionally required if `startupActivity` = `variantFetched`.
     * @note Source from the associated `Bam.Sdk.Media.VariantPlaylistFetchedEvent`.
     *
     */
    public variantFetchedStartTime?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String<SDK.Services.QualityOfService.TimedTextTrackType>}
     * @desc The track type of the currently selected timed text rendition.
     * @note Conditionally Required when `startupActivity` is `initialized` and `subtitleVisibility` = `true`.
     * @note Source from the `SDK.Media.PlaybackInitializedEvent` when `startupActivity` is `initialized`.
     *
     */
    public playlistTimedTextTrackType: TimedTextTrackType;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Boolean|undefined}
     * @desc A flag indicating if the media fetch request to the playback orchestration service succeeded.
     * @note SDKs can determine this value if the media fetch to the PBO service was successful or not.
     * @note Conditionally required if `startupActivity` != `requested`.
     *
     */
    public mediaFetchSucceeded?: boolean;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String|undefined}
     * @desc Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
     * @note Conditionally required where `platformId` = `realitydevice`.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     * @note The SDK should keep track of the most recent `viewingEnvironment` value, initially from the `PlaybackContextOptions.viewingEnvironment`
     * field and updates through the `PlaybackEventListener.onViewingEnvironmentChanged` event.
     *
     */
    public viewingEnvironment?: string;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.QualityOfService.StartupActivity} options.startupActivity
     * @param {String} [options.playbackSessionId]
     * @param {SDK.Services.QualityOfService.ProductType} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {SDK.Services.QualityOfService.NetworkType} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {SDK.Services.QualityOfService.PlaybackIntent} [options.playbackIntent]
     * @param {Boolean} [options.mediaPreBuffer=false]
     * @param {Number} [options.bufferSegmentDuration]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {String} [options.playbackUrl]
     * @param {String} [options.playbackScenario]
     * @param {SDK.Services.Media.PlaylistType} [options.playlistLiveType]
     * @param {Boolean} [options.localMedia=false]
     * @param {SDK.Services.QualityOfService.ServerRequest} [options.serverRequest]
     * @param {Number} [options.cpuPercentage]
     * @param {Number} [options.freeMemory]
     * @param {SDK.Services.Media.AudioRendition} [options.audio]
     * @param {SDK.Services.Media.SubtitleRendition} [options.subtitle]
     * @param {SDK.Services.Media.PlaybackVariant} [options.variant]
     * @param {String} [options.streamUrl]
     * @param {SDK.Services.Media.PlaybackAttributes} [options.attributes]
     * @param {Array<SDK.Services.Media.PlaybackVariant>} [options.streamVariants]
     * @param {SDK.Services.QualityOfService.MediaFetchError} [options.mediaFetchError]
     * @param {String} [options.drmKeyId]
     * @param {String} [options.playlistName]
     * @param {String} [options.playlistLanguage]
     * @param {SDK.Services.QualityOfService.MediaSegmentType} [options.mediaSegmentType]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {SDK.Services.QualityOfService.QoePlaybackError} [options.playbackError]
     * @param {String} [options.playbackErrorDetail]
     * @param {Object} [options.qos={}]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Array<String>} [options.cdnRequestedTrail]
     * @param {Array<String>} [options.cdnFailedTrail]
     * @param {Number} [options.cdnFallbackCount]
     * @param {Boolean} [options.isCdnFallback]
     * @param {SDK.Services.QualityOfService.AdInsertionType} [options.adInsertionType=AdInsertionType.none]
     * @param {Boolean} [options.hasSlugs=false]
     * @param {String<SDK.Services.Media.SubscriptionType>|undefined} [options.subscriptionType]
     * @param {Number} [options.totalPodCount]
     * @param {String} [options.adSessionId]
     * @param {Number} [options.totalSlotCount]
     * @param {Number} [options.totalAdLength]
     * @param {Number} [options.createAdSessionResponseCode]
     * @param {Number} [options.getPodsResponseCode]
     * @param {Object} [options.startupContext]
     * @param {Number} [options.fetchCertificateDuration]
     * @param {Number} [options.fetchCertificateStartTime]
     * @param {Number} [options.fetchLicenseDuration]
     * @param {Number} [options.fetchLicenseStartTime]
     * @param {Number} [options.initializePlayerDuration]
     * @param {Number} [options.initializePlayerStartTime]
     * @param {Number} [options.multivariantFetchedDuration]
     * @param {Number} [options.multivariantFetchedStartTime]
     * @param {Number} [options.playlistFetchedDuration]
     * @param {Number} [options.playlistFetchedStartTime]
     * @param {Number} [options.readyPlayerDuration]
     * @param {Number} [options.readyPlayerStartTime]
     * @param {Number} [options.requestedStartTime]
     * @param {Number} [options.variantFetchedDuration]
     * @param {Number} [options.variantFetchedStartTime]
     * @param {String} [options.interactionId]
     * @param {Boolean} [options.mediaFetchSucceeded=true]
     * @param {SDK.Services.QualityOfService.AudioTrackType} [options.playlistAudioTrackType=AudioTrackType.unknown]
     * @param {SDK.Services.QualityOfService.TimedTextTrackType} [options.playlistTimedTextTrackType=TimedTextTrackType.unknown]
     * @param {Object} [options.qoe={}]
     * @param {String} [options.viewingEnvironment]
     * @param {String} [options.programBoundaryInfoBlock]
     *
     */
    public constructor(options: {
        startupActivity: StartupActivity;
        playbackSessionId?: string;
        productType?: ProductType;
        playheadPosition?: number;
        networkType?: NetworkType;
        playbackIntent?: PlaybackIntent;
        mediaPreBuffer?: boolean;
        bufferSegmentDuration?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
        playbackUrl?: string;
        playbackScenario?: string;
        playlistLiveType?: PlaylistType;
        localMedia?: boolean;
        serverRequest?: ServerRequest;
        cpuPercentage?: number;
        freeMemory?: number;
        audio?: AudioRendition;
        subtitle?: SubtitleRendition;
        variant?: PlaybackVariant;
        streamUrl?: string;
        attributes?: PlaybackAttributes;
        streamVariants?: Array<PlaybackVariant>;
        mediaFetchError?: MediaFetchError;
        drmKeyId?: string;
        playlistName?: string;
        playlistLanguage?: string;
        mediaSegmentType?: MediaSegmentType;
        monotonicTimestamp?: number;
        contentKeys?: ContentKeys;
        data?: object;
        playbackError?: QoePlaybackError;
        playbackErrorDetail?: string;
        qos?: Record<string, string>;
        clientGroupIds?: string;
        serverGroupIds?: string;
        cdnRequestedTrail?: Array<string>;
        cdnFailedTrail?: Array<string>;
        cdnFallbackCount?: number;
        isCdnFallback?: boolean;
        adInsertionType?: AdInsertionType;
        hasSlugs?: boolean;
        subscriptionType?: string;
        totalPodCount?: number;
        adSessionId?: string;
        totalSlotCount?: number;
        totalAdLength?: number;
        createAdSessionResponseCode?: number;
        getPodsResponseCode?: number;
        startupContext?: object;
        fetchCertificateDuration?: number;
        fetchCertificateStartTime?: number;
        fetchLicenseDuration?: number;
        fetchLicenseStartTime?: number;
        initializePlayerDuration?: number;
        initializePlayerStartTime?: number;
        multivariantFetchedDuration?: number;
        multivariantFetchedStartTime?: number;
        playlistFetchedDuration?: number;
        playlistFetchedStartTime?: number;
        readyPlayerDuration?: number;
        readyPlayerStartTime?: number;
        requestedStartTime?: number;
        variantFetchedDuration?: number;
        variantFetchedStartTime?: number;
        interactionId?: string;
        playlistAudioTrackType?: AudioTrackType;
        playlistTimedTextTrackType?: TimedTextTrackType;
        mediaFetchSucceeded?: boolean;
        qoe?: Record<string, string>;
        viewingEnvironment?: string;
        programBoundaryInfoBlock?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    startupActivity: Types.in(StartupActivity),
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    networkType: Types.in(NetworkType).optional,
                    playbackIntent: Types.in(PlaybackIntent).optional,
                    mediaPreBuffer: Types.boolean.optional,
                    bufferSegmentDuration: Types.number.optional,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    playbackUrl: Types.nonEmptyString.optional,
                    playbackScenario: Types.nonEmptyString.optional,
                    playlistLiveType: Types.in(PlaylistType).optional,
                    localMedia: Types.boolean.optional,
                    serverRequest: Types.instanceStrict(ServerRequest).optional,
                    cpuPercentage: Types.number.optional,
                    freeMemory: Types.number.optional,
                    audio: Types.object(AudioRenditionTypedef).optional,
                    subtitle: Types.object(SubtitleRenditionTypedef).optional,
                    variant: Types.instanceStrict(PlaybackVariant).optional,
                    streamUrl: Types.nonEmptyString.optional,
                    attributes:
                        Types.instanceStrict(PlaybackAttributes).optional,
                    streamVariants:
                        Types.array.of.instanceStrict(PlaybackVariant).optional,
                    mediaFetchError: Types.in(MediaFetchError).optional,
                    drmKeyId: Types.nonEmptyString.optional,
                    playlistName: Types.nonEmptyString.optional,
                    playlistLanguage: Types.nonEmptyString.optional,
                    mediaSegmentType: Types.in(MediaSegmentType).optional,
                    monotonicTimestamp: Types.number.optional,
                    contentKeys: Types.object().optional,
                    data: Types.object().optional,
                    playbackError: Types.in(QoePlaybackError).optional,
                    playbackErrorDetail: Types.nonEmptyString.optional,
                    qos: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    cdnRequestedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFailedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFallbackCount: Types.number.optional,
                    isCdnFallback: Types.boolean.optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    hasSlugs: Types.boolean.optional,
                    subscriptionType: Types.string.optional,
                    totalPodCount: Types.number.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    totalSlotCount: Types.number.optional,
                    totalAdLength: Types.number.optional,
                    createAdSessionResponseCode: Types.number.optional,
                    getPodsResponseCode: Types.number.optional,
                    startupContext: Types.object().optional,
                    fetchCertificateDuration: Types.number.optional,
                    fetchCertificateStartTime: Types.number.optional,
                    fetchLicenseDuration: Types.number.optional,
                    fetchLicenseStartTime: Types.number.optional,
                    initializePlayerDuration: Types.number.optional,
                    initializePlayerStartTime: Types.number.optional,
                    multivariantFetchedDuration: Types.number.optional,
                    multivariantFetchedStartTime: Types.number.optional,
                    playlistFetchedDuration: Types.number.optional,
                    playlistFetchedStartTime: Types.number.optional,
                    readyPlayerDuration: Types.number.optional,
                    readyPlayerStartTime: Types.number.optional,
                    requestedStartTime: Types.number.optional,
                    variantFetchedDuration: Types.number.optional,
                    variantFetchedStartTime: Types.number.optional,
                    interactionId: Types.nonEmptyString.optional,
                    mediaFetchSucceeded: Types.boolean.optional,
                    playlistAudioTrackType: Types.in(AudioTrackType).optional,
                    playlistTimedTextTrackType:
                        Types.in(TimedTextTrackType).optional,
                    qoe: Types.object().optional,
                    viewingEnvironment: Types.nonEmptyString.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            startupActivity,
            playbackSessionId,
            productType,
            playheadPosition,
            networkType,
            playbackIntent,
            mediaPreBuffer,
            bufferSegmentDuration,
            videoPlayerName,
            videoPlayerVersion,
            playbackUrl,
            playbackScenario,
            playlistLiveType,
            localMedia,
            serverRequest,
            cpuPercentage,
            freeMemory,
            audio,
            subtitle,
            variant,
            streamUrl,
            attributes,
            streamVariants,
            mediaFetchError,
            drmKeyId,
            playlistName,
            playlistLanguage,
            mediaSegmentType,
            monotonicTimestamp,
            contentKeys,
            data = {},
            playbackError,
            playbackErrorDetail,
            qos = {},
            clientGroupIds,
            serverGroupIds,
            cdnRequestedTrail,
            cdnFailedTrail,
            cdnFallbackCount,
            isCdnFallback,
            adInsertionType,
            hasSlugs,
            subscriptionType,
            totalPodCount,
            adSessionId,
            totalSlotCount,
            totalAdLength,
            createAdSessionResponseCode,
            getPodsResponseCode,
            startupContext = {},
            fetchCertificateDuration,
            fetchCertificateStartTime,
            fetchLicenseDuration,
            fetchLicenseStartTime,
            initializePlayerDuration,
            initializePlayerStartTime,
            multivariantFetchedDuration,
            multivariantFetchedStartTime,
            playlistFetchedDuration,
            playlistFetchedStartTime,
            readyPlayerDuration,
            readyPlayerStartTime,
            requestedStartTime,
            variantFetchedDuration,
            variantFetchedStartTime,
            interactionId,
            mediaFetchSucceeded,
            playlistAudioTrackType,
            playlistTimedTextTrackType,
            qoe = {},
            viewingEnvironment,
            programBoundaryInfoBlock
        } = options;

        this.startupActivity = startupActivity;
        this.playbackSessionId = playbackSessionId;
        this.productType = productType;

        this.playheadPosition = Math.ceil(playheadPosition ?? -1);

        this.networkType = networkType || NetworkType.unknown;
        this.playbackIntent = playbackIntent;

        this.mediaPreBuffer = mediaPreBuffer ?? false;

        this.bufferSegmentDuration = Math.ceil(bufferSegmentDuration || 0);
        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.playbackUrl = playbackUrl;
        this.playbackScenario = playbackScenario;
        this.playlistLiveType = playlistLiveType;

        this.localMedia = localMedia ?? false;

        this.serverRequest = serverRequest;
        this.cpuPercentage = cpuPercentage;
        this.freeMemory = freeMemory;

        this.playlistAudioChannels = variant?.audioChannels ?? undefined;
        this.playlistAudioCodec = variant?.audioCodec ?? undefined;
        this.playlistAudioLanguage = audio?.language;
        this.playlistAudioName = audio?.name;
        this.mediaStartBitrate = variant?.bandwidth ?? undefined;
        this.playlistSubtitleLanguage = subtitle?.language;
        this.playlistSubtitleName = subtitle?.name;
        this.subtitleVisibility = subtitle?.forced;
        this.playlistVideoCodec = variant?.videoCodec ?? undefined;
        this.playlistVideoRange = variant?.videoRange ?? undefined;

        this.streamUrl = streamUrl;
        this.attributes = attributes;
        this.streamVariants = streamVariants;

        this.mediaFetchError = mediaFetchError || null;

        this.drmKeyId = drmKeyId;

        this.videoBitrate = variant?.bandwidth ?? undefined;
        this.videoAverageBitrate = variant?.averageBandwidth ?? undefined;

        this.playlistChannels = variant?.audioChannels?.toString();

        this.playlistName = playlistName;
        this.playlistLanguage = playlistLanguage;
        this.mediaSegmentType = mediaSegmentType;

        this.playlistResolution = variant?.resolution ?? undefined;
        this.playlistFrameRate = variant?.frameRate ?? undefined;

        this.monotonicTimestamp = monotonicTimestamp || undefined;
        this.contentKeys = contentKeys || {};
        this.playbackError = playbackError || null;
        this.playbackErrorDetail = playbackErrorDetail;
        this.cdnRequestedTrail = cdnRequestedTrail || undefined;
        this.cdnFailedTrail = cdnFailedTrail || undefined;
        this.cdnFallbackCount = cdnFallbackCount || 0;
        this.isCdnFallback = isCdnFallback || false;
        this.clientGroupIds = clientGroupIds;
        this.serverGroupIds = serverGroupIds;
        this.adInsertionType = adInsertionType || AdInsertionType.none;

        if (startupActivity !== StartupActivity.requested) {
            this.hasSlugs = hasSlugs || false;
        }
        this.subscriptionType = subscriptionType || '';
        this.totalPodCount = totalPodCount;
        this.adSessionId = adSessionId;
        this.totalSlotCount = totalSlotCount;
        this.totalAdLength = totalAdLength;
        this.createAdSessionResponseCode = createAdSessionResponseCode || 0;
        this.getPodsResponseCode = getPodsResponseCode;
        this.interactionId = interactionId;
        this.playlistAudioTrackType =
            playlistAudioTrackType || AudioTrackType.unknown;
        this.playlistTimedTextTrackType =
            playlistTimedTextTrackType || TimedTextTrackType.unknown;

        this.applicationProtocol = (serverRequest?.host || '').startsWith(
            'https'
        )
            ? ApplicationProtocol.https
            : ApplicationProtocol.http;

        this.cdnName = 'null';
        this.cdnVendor = 'null';
        this.cdnWithOrigin = 'null';

        this.fetchCertificateDuration = fetchCertificateDuration;
        this.fetchCertificateStartTime = fetchCertificateStartTime;
        this.fetchLicenseDuration = fetchLicenseDuration;
        this.fetchLicenseStartTime = fetchLicenseStartTime;
        this.initializePlayerDuration = initializePlayerDuration;
        this.initializePlayerStartTime = initializePlayerStartTime;
        this.multivariantFetchedDuration = multivariantFetchedDuration;
        this.multivariantFetchedStartTime = multivariantFetchedStartTime;
        this.playlistFetchedDuration = playlistFetchedDuration;
        this.playlistFetchedStartTime = playlistFetchedStartTime;
        this.readyPlayerDuration = readyPlayerDuration;
        this.readyPlayerStartTime = readyPlayerStartTime;
        this.requestedStartTime = requestedStartTime;
        this.variantFetchedDuration = variantFetchedDuration;
        this.variantFetchedStartTime = variantFetchedStartTime;
        this.mediaFetchSucceeded = mediaFetchSucceeded ?? true;
        this.viewingEnvironment = viewingEnvironment;
        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        this.setQosData(qos);
        this.setData(data);
        this.setData(startupContext);
        this.setData(qoe);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [qos]
     * @desc Assign QoS data.
     * @note IMPORTANT: The key/value pairs from the qos HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "qos" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    public setQosData(qos?: Record<string, string>) {
        if (qos && Check.nonEmptyObject(qos)) {
            this.fixProductType(qos);

            Object.assign(this, qos);
        }
    }

    /**
     *
     * @access private
     * @since 17.0.3
     * @param {Object} qos
     * @desc Fix the incoming `productType` to be the proper enum casing for qos as it differs from the media playlist tracking casing.
     *
     */
    private fixProductType(qos: Record<string, string>) {
        let fixedProductType = qos.productType;

        if (fixedProductType) {
            const valueLower = fixedProductType.toLowerCase();

            if (valueLower === ProductType.live.toLowerCase()) {
                fixedProductType = ProductType.live;
            } else if (valueLower === ProductType.vod.toLowerCase()) {
                fixedProductType = ProductType.vod;
            }

            qos.productType = fixedProductType;
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: object) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {String|null} [cdnVendor=null]
     * @desc Used when CDN has failed and is attempting to fallback to the next.
     *
     */
    public fallbackAttempt(cdnVendor: Nullable<string> = null) {
        if (this.isCdnFallback) {
            this.cdnFallbackCount++;
        }

        if (!this.cdnRequestedTrail) {
            this.cdnRequestedTrail = [cdnVendor as unknown as string];
        } else {
            this.cdnRequestedTrail.push(cdnVendor as unknown as string);
        }
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @desc Used when CDN fails and builds the failure trail.
     *
     */
    public fallbackFailed() {
        this.isCdnFallback = true;

        if (this.cdnRequestedTrail) {
            this.cdnFailedTrail = Array.from(this.cdnRequestedTrail);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackStartupEventData';
    }
}
