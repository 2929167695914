/**
 *
 * @module entry
 * @desc Services SDK entry point.
 *
 */

import Configuration from './configuration/entry';
import Exception from './exception/entry';
import GraphQl from './graphQl/entry';
import Orchestration from './orchestration/entry';
import Internal from './internal/entry';
import QualityOfService from './qualityOfService/entry';
import Session from './session/entry';
import Subscriber from './subscriber/entry';
import Token from './token/entry';
import Util from './util/entry';
import Identity from './identity/entry';

import PlatformProviders from './providers/platformProviders';

/**
 *
 * @note Only the core modules are imported here.
 *
 */
export default {
    /**
     *
     * @access public
     * @returns {SDK.Services.Configuration}
     *
     */
    get Configuration() {
        return Configuration;
    },

    /**
     *
     * @access public
     * @returns {SDK.Services.Exception}
     *
     */
    get Exception() {
        return Exception;
    },

    /**
     *
     * @access public
     * @since 4.12.0
     * @returns {SDK.Services.GraphQl}
     *
     */
    get GraphQl() {
        return GraphQl;
    },

    /**
     *
     * @access private
     * @returns {SDK.Services.Internal}
     *
     */
    get Internal() {
        return Internal;
    },

    /**
     *
     * @access public
     * @returns {SDK.Services.Identity}
     *
     */
    get Identity() {
        return Identity;
    },

    /**
     *
     * @access public
     * @since 4.12.0
     * @returns {SDK.Services.Orchestration}
     *
     */
    get Orchestration() {
        return Orchestration;
    },

    /**
     *
     * @access public
     * @since 4.3.0
     * @note namespace was moved here in 4.3.0 but was introduced in 4.0.0
     * @returns {SDK.Services.QualityOfService}
     *
     */
    get QualityOfService() {
        return QualityOfService;
    },

    /**
     *
     * @access public
     * @returns {SDK.Services.Session}
     *
     */
    get Session() {
        return Session;
    },

    /**
     *
     * @access public
     * @since 4.13.0
     * @returns {SDK.Services.Subscriber}
     *
     */
    get Subscriber() {
        return Subscriber;
    },

    /**
     *
     * @access public
     * @returns {SDK.Services.Token}
     *
     */
    get Token() {
        return Token;
    },

    /**
     *
     * @access public
     * @returns {SDK.Services.Util}
     *
     */
    get Util() {
        return Util;
    },

    /**
     *
     * @access protected
     * @since x.x.x
     *
     */
    get PlatformProviders() {
        return PlatformProviders;
    }
};
