/**
 *
 * @module httpHeaderConstants
 *
 */

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `x-amz-cf-pop` header.
 *
 */
export const AMZ_CF_ID = 'x-amz-cf-id';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `x-amz-cf-pop` header.
 *
 */
export const AMZ_CF_POP = 'x-amz-cf-pop';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-Application-Version` header.
 *
 */
export const APPLICATION_VERSION = 'X-Application-Version';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `Authorization` header.
 *
 */
export const AUTHORIZATION = 'Authorization';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Browser-Info` header.
 *
 */
export const BROWSER_INFO = 'X-BAMTech-Browser-Info';

/**
 *
 * @access public
 * @since 28.1.0
 * @desc The HTTP header key for the `X-CDN-No-Cache` header.
 *
 */
export const BYPASS_CDN_CACHE = 'X-CDN-No-Cache';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-Content-Transaction-ID` header.
 *
 */
export const CONTENT_TRANSACTION_ID = 'X-Content-Transaction-ID';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `content-type` header.
 *
 */
export const CONTENT_TYPE = 'Content-Type';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTECH-CTF-UBA-ID` header.
 *
 */
export const CTF_UBA_ID = 'X-BAMTECH-CTF-UBA-ID';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-DELOREAN` header.
 *
 */
export const DELOREAN = 'X-DELOREAN';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTECH-Event-Transport-Timestamp` header.
 *
 */
export const EVENT_TRANSPORT_TIMESTAMP = 'X-BAMTECH-Event-Transport-Timestamp';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Generate-ADRT` header.
 *
 */
export const GENERATE_ADRT = 'X-BAMTech-Generate-ADRT';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-GEO-OVERRIDE` header.
 *
 */
export const GEO_OVERRIDE = 'X-GEO-OVERRIDE';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Hora-Proxy` header.
 *
 */
export const HORA_PROXY = 'X-BAMTech-Hora-Proxy';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-Bamtech-Playback-Experience-Context` header.
 *
 */
export const PLAYBACK_EXPERIENCE_CONTEXT =
    'X-Bamtech-Playback-Experience-Context';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Purchase-Platform` header.
 *
 */
export const PURCHASE_PLATFORM = 'X-BAMTech-Purchase-Platform';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-Rate-Limit-Duration` header.
 *
 */
export const RATE_LIMIT_DURATION = 'X-Rate-Limit-Duration';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Refresh-Access-Token` header.
 *
 */
export const REFRESH_ACCESS_TOKEN = 'X-BAMTech-Refresh-Access-Token';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `x-mlbam-reply-after` header.
 *
 */
export const REPLY_AFTER = 'x-mlbam-reply-after';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-Request-ID` header.
 *
 */
export const REQUEST_ID = 'X-Request-ID';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `'X-BAMTech-Sales-Platform'` header.
 *
 */
export const SALES_PLATFORM = 'X-BAMTech-Sales-Platform';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTECH-ERROR` header.
 *
 */
export const SDK_ERROR = 'X-BAMTECH-ERROR';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMSDK-Platform` header.
 *
 */
export const SDK_PLATFORM = 'X-BAMSDK-Platform';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `x-bamtech-region` header.
 *
 */
export const SDK_REGION = 'x-bamtech-region';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMSDK-Version` header.
 *
 */
export const SDK_VERSION = 'X-BAMSDK-Version';

/**
 *
 * @access public
 * @since 28.0.0
 * @desc The HTTP header key for the `X-BAMTech-Temporary-Access-Granted` header.
 *
 */
export const TEMPORARY_ACCESS_GRANTED = 'X-BAMTech-Temporary-Access-Granted';
