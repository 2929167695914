/**
 *
 * @module searchOverrides
 *
 */
import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Provides a mechanism to override the country or date values
 * for testing search responses
 *
 */
export default class SearchOverrides {
    /**
     *
     * @access private
     * @type {String|undefined}
     *
     */
    public activeDate?: string;

    /**
     *
     * @access private
     * @type {String|undefined}
     *
     */
    public countryCode?: string;

    /**
     *
     * @access private
     * @since 28.1.0
     * @type {Boolean|undefined}
     * @desc When present and set to true, this flag enables the application to bypass the CDN cache for testing purposes.
     *
     */
    public bypassCDNCache?: boolean;

    /**
     *
     * @param {Object} options
     * @param {String} [options.activeDate] - Defines the time of origin for the search request.
     * May affect availability, appearance, and expiration of content. See ISO 8601 for format
     * @param {String} [options.countryCode] - Defines the country of origin for the search request.
     * See ISO 3166-1 alpha-2 for format
     * @param {Boolean} [options.bypassCDNCache] - When present and set to true, this flag enables the
     * application to bypass the CDN cache for testing purposes.
     *
     */
    public constructor(options: {
        activeDate?: string;
        countryCode?: string;
        bypassCDNCache?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    activeDate: Types.dateString.optional,
                    countryCode: Types.nonEmptyString.optional,
                    bypassCDNCache: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { activeDate, countryCode, bypassCDNCache } = options;

        this.activeDate = activeDate;
        this.countryCode = countryCode;
        this.bypassCDNCache = bypassCDNCache;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Content.SearchOverrides';
    }
}
