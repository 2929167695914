import SDK from 'bam-browser-sdk';
import { BroadcastServiceActions } from './services/actions';
import BroadcastService from "./services/broadcast.service";
import OneIDService from "./services/oneid.service";
import SdkService from "./services/sdk.service";
import SyndicatedDispatcherService from "./services/syndicatedDispatcher.service";
import UserService from "./services/user.service";

export default class ApplicationState {
    static appState: ApplicationState;
    public broadcastService: BroadcastService;
    public oneIdService: OneIDService;
    public sdkService: SdkService;
    public syndicatedDispatcherService: SyndicatedDispatcherService;
    public userService: UserService;
    public browserInfo;
    static instance = Promise;

    static getInstance() {
        if (!this.appState) {
            ApplicationState.appState = new ApplicationState();
        }

        return ApplicationState.instance.resolve(this.appState);
    }

    constructor() {
        this.broadcastService = BroadcastService.getInstance();
        this.syndicatedDispatcherService = SyndicatedDispatcherService.getInstance(this.broadcastService);
        this.userService = UserService.getInstance();
        this.sdkService = SdkService.getInstance(SDK, this.userService, this.broadcastService);
        this.oneIdService = OneIDService.getInstance(this.broadcastService, this.userService, this.sdkService);
    }

    public isEmbedded() {
        return window.self !== window.top;
    }

    public async initialize() {
        const device = (window as any).deviceBootstrap();

        this.browserInfo = {
            isIE: device.is.msie,
            version: parseInt(device.browserVersion, 10),
            isFireFox: device.is.firefox,
        };

        if (this.isEmbedded()) {
            this.syndicatedDispatcherService.initialize();

            this.broadcastService.on(BroadcastServiceActions.SyndicatedPlayer.ready, async (externalData) => {
                const configData = externalData?.config?.data;
                const sdkInitialState = configData?.sdkInitialState;
                const authToken = configData.auth?.token;

                if (sdkInitialState) {
                    await this.sdkService.initialize(sdkInitialState);
                } else {
                    await this.sdkService.initialize();
                }

                if (authToken) {
                    await this.sdkService.login(authToken);
                } else {
                    this.broadcastService.emit(BroadcastServiceActions.User.userSet, this.userService);
                }

                this.broadcastService.emit(BroadcastServiceActions.Application.embedReady, {
                    ...externalData,
                    state: ApplicationState.appState
                });

                this.broadcastService.emit(BroadcastServiceActions.Application.ready, true);
            });
        } else {
            await this.sdkService.initialize();
            await this.oneIdService.initialize();

            // TODO: need a better solution to handle this, but for time being this is a workaround for an external reference to oneId. eg. MLP
            // Legacy wrapper plays nice with Cannonball.
            (window as any).disneyOneIdWrapper = {
                disneyIdService: this.oneIdService.oneId
            };

            this.broadcastService.emit(BroadcastServiceActions.Application.ready, ApplicationState.appState);

            const purchaseApi = this.sdkService.sdkSession.purchaseApi;
            (window as any).redeemBundle = purchaseApi.redeemBundle.bind(purchaseApi);
            (window as any).redeemToken = purchaseApi.redeemToken.bind(purchaseApi);
            (window as any).resetSdkSesion = this.sdkService.sdkSession.reset.bind(this.sdkService.sdkSession);
        }
    }
}
