/**
 *
 * @module eligibilityPlugin
 * @desc {SDK.Eligibility.EligibilityPlugin} entry point.
 *
 */

import EligibilityService from '../services/eligibility/entry';
import EligibilityClientConfiguration from '../services/eligibility/eligibilityClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Eligibility', void, typeof EligibilityService> = {
    pluginName: 'Eligibility',
    service: EligibilityService,

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.eligibility =
            new EligibilityClientConfiguration({
                baseUrl: services?.eligibility?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.eligibility?.client?.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
