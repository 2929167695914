/**
 *
 * @module drmPlugin
 * @desc {SDK.Drm.DrmPlugin} entry point.
 *
 */

import DrmService from '../services/drm/entry';

import DrmClientConfiguration from '../services/drm/drmClientConfiguration';
import DrmClientExtrasMap from '../services/drm/drmClientExtrasMap';

import type { IPlugin } from '../IPlugin';
import ServiceDefinitionsConfiguration from '../services/configuration/serviceDefinitionsConfiguration';

const plugin: IPlugin<'Drm', void, typeof DrmService> = {
    pluginName: 'Drm',
    service: DrmService,

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(
        serviceDefinitionsConfiguration: ServiceDefinitionsConfiguration,
        services
    ) {
        const { endpoints, extras } = services?.drm?.client;

        serviceDefinitionsConfiguration.drm = new DrmClientConfiguration({
            baseUrl: services?.drm?.baseUrl,
            endpoints:
                serviceDefinitionsConfiguration.processEndpoints(endpoints),
            extras: new DrmClientExtrasMap(extras)
        });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
