/**
 *
 * @module streamSampleTelemetryEvent
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TelemetryEvent from './telemetryEvent';

import StreamSampleClientPayload from '../../services/internal/telemetry/streamSampleClientPayload';
import StreamSamplePayload from '../../services/internal/telemetry/streamSamplePayload';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';

/**
 *
 * @access protected
 * @implements {TelemetryEvent}
 * @since 3.5.0
 * @note was previously part of SDK.Media
 *
 */
export default class StreamSampleTelemetryEvent extends TelemetryEvent {
    /**
     *
     * @access private
     * @type {Object}
     * @note assigned in SDK.Services.Internal.Telemetry.StreamSamplePayload as { server }
     *
     */
    private serverData: TodoAny;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private playbackSessionId: string;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private playhead: number;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private bitrate: number;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public event: string;

    /**
     *
     * @access public
     * @type {Number}
     * @desc a timestamp in milliseconds
     *
     */
    public timestamp: number;

    /**
     *
     * @access private
     * @since 4.5.0
     * @type {String|undefined}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The interactionId will correspond with one of the defined interactionTypes. The interactionId will be used
     * as the primary key for all interactions.
     *
     */
    private interactionId?: string;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {Object}
     * @desc Extra data to track during the playback session.
     *
     */
    public extraData: TodoAny;

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.playbackSessionId - a unique id for the playback session, may be UUID
     * @param {Number} [options.playhead=0] - the current playhead position in milliseconds
     * @param {Number} [options.bitrate=0] - the current playback bitrate in kbps (optional)
     * @param {Object} [options.serverData={}] - the server data returned from the Playlist Service call
     * @param {String} [options.interactionId] - an interaction id passed in by the Application Developer
     * @param {Object} [options.extraData={}] - Extra data to track during this playback session.
     * @note server does not appear in the current UML but we need it passed in the constructor here
     *
     */
    public constructor(options: {
        playbackSessionId: string;
        playhead?: number;
        bitrate?: number;
        serverData?: TodoAny;
        interactionId?: string;
        extraData?: TodoAny;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackSessionId: Types.nonEmptyString,
                    playhead: Types.greaterOrEqual(0).optional,
                    bitrate: Types.number.optional,
                    serverData: Types.object().optional,
                    interactionId: Types.nonEmptyString.optional,
                    extraData: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            playbackSessionId,
            playhead,
            bitrate,
            serverData = {}
        } = options;
        const { interactionId, extraData = {} } = options;

        this.serverData = serverData;
        this.playbackSessionId = playbackSessionId;
        this.playhead = playhead || 0;
        this.bitrate = bitrate || 0;
        this.event = DustUrnReference.streamSample;
        this.timestamp = Date.now();
        this.interactionId = interactionId;
        this.extraData = extraData;
    }

    /**
     *
     * @access public
     * @returns {SDK.Services.Internal.Telemetry.StreamSamplePayload}
     * @note serverData can be an empty object
     * @example
     *
     * "serverData": {
     *      "event": "stream-sample",
     *      "media_state": "MEDIA_ON",
     *      "cdn": "PRIMARY_AKAMAI",
     *      "playback_scenario": "HTTP_CLOUD_TABLET",
     *      "media_version": "3.2",
     *      "playlist_request_id": "dc49356e-3afd-4e3b-9fcd-ddd5e51ba5ee",
     *      "stream_id": "112342314",
     *      "media_revision_id": "843e3d3f-389d-46f5-907f-3bf448324f"
     *  },
     *  "client": {
     *      "event": "urn:dss:telemetry-service:event:stream-sample",
     *      "timestamp": 1544638712502,
     *      "play_head": 0,
     *      "playback_session_id": "b21514d5-394e-4618-967a-327aad0f9873",
     *      "bitrate": 2
     *      "group_id": "12345"
     *  }
     *
     */
    public override getPayload() {
        const {
            serverData,
            event,
            playbackSessionId,
            playhead,
            bitrate,
            timestamp,
            interactionId,
            extraData
        } = this;
        const { groupId } = extraData;

        const streamSampleClientPayload = new StreamSampleClientPayload({
            event,
            timestamp,
            playbackSessionId,
            playhead,
            bitrate,
            interactionId,
            groupId
        });

        return new StreamSamplePayload({
            server: serverData,
            client: streamSampleClientPayload
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Telemetry.StreamSampleTelemetryEvent';
    }
}
