/* eslint-disable camelcase */
/**
 *
 * @module streamSampleClientPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 3.5.0
 *
 */
export default class StreamSampleClientPayload {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private event: string;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private timestamp: number;

    /**
     *
     * @access private
     * @type {Number|undefined}
     *
     */
    private play_head?: number;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private playback_session_id: string;

    /**
     *
     * @access private
     * @type {Number|undefined}
     *
     */
    private bitrate?: number;

    /**
     *
     * @access private
     * @since 4.5.0
     * @type {String|null}
     *
     */
    private interaction_id: Nullable<string>;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {String|undefined}
     *
     */
    private group_id?: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.event
     * @param {Number} options.timestamp
     * @param {String} options.playbackSessionId
     * @param {Number} [options.playhead]
     * @param {Number} [options.bitrate]
     * @param {String} [options.interactionId=null]
     * @param {String} [options.groupId]
     *
     */
    public constructor(options: {
        event: string;
        timestamp: number;
        playbackSessionId: string;
        playhead?: number;
        bitrate?: number;
        interactionId?: Nullable<string>;
        groupId?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    event: Types.nonEmptyString,
                    timestamp: Types.number,
                    playbackSessionId: Types.nonEmptyString,
                    playhead: Types.number.optional,
                    bitrate: Types.number.optional,
                    interactionId: Types.nonEmptyString.optional,
                    groupId: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            event,
            timestamp,
            playbackSessionId,
            playhead,
            bitrate,
            interactionId = null,
            groupId
        } = options;

        this.event = event;
        this.timestamp = timestamp;
        this.play_head = playhead;
        this.playback_session_id = playbackSessionId;
        this.bitrate = bitrate;
        this.interaction_id = interactionId;
        this.group_id = groupId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Telemetry.StreamSampleClientPayload';
    }
}
