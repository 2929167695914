/**
 *
 * @module delay
 *
 */

/**
 *
 * @param {Number} delayMilliseconds
 * @since 4.9.0
 * @desc delay helper that will resolve it's promise once the elapsed milliseconds has completed.
 * @returns {Promise<Void>}
 *
 * @example await delay(2000) // delay for 2 seconds
 *
 */
export default (delayMilliseconds = 0) => {
    return new Promise((resolve) => setTimeout(resolve, delayMilliseconds));
};
