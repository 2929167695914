/**
 *
 * @module adEngineClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md#adengineclient
 * @see https://wiki.disneystreaming.com/pages/viewpage.action?pageId=17892211
 * @see https://wiki.disneystreaming.com/display/AdEngine/adEngine+RFC003+Settoken+SDK+Integration
 * @see https://www.npmjs.com/package/query-string
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import type { IGeoProvider } from '../../../providers/IGeoProvider';
import PlatformProviders from '../../../providers/platformProviders';

import Logger from '../../../logging/logger';
import DustLogUtility from '../../internal/dust/dustLogUtility';
import DustUrnReference from '../../internal/dust/dustUrnReference';

import AdEngineClientConfiguration from './adEngineClientConfiguration';
import AdEngineClientEndpoint from './adEngineClientEndpoint';
import CoreHttpClientProvider from '../../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../../logging/logTransaction';
import AccessToken from '../../token/accessToken';
import HttpHeaders from '../../providers/shared/httpHeaders';
import replaceHeaders from '../../util/replaceHeaders';
import ClientBase from '../../clientBase';
import { IEndpoint } from '../../providers/typedefs';

import AdvertisingIdProvider from '../../../advertising/advertisingIdProvider';
import LimitAdTrackingEnabled from '../../../advertising/limitAdTrackingEnabled';

const AdEngineClientDustUrnReference =
    DustUrnReference.services.media.adEngine.adEngineClient;

/**
 *
 * @access protected
 * @since 3.6.0
 * @desc The `AdEngineClient` is used to retrieve the AdEngine cookie.
 * The network request requires several parameters to be passed with the request body
 * along with an access token.
 *
 */
export default class AdEngineClient extends ClientBase<AdEngineClientConfiguration> {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {string}
     * @note sdkver comes from deviceManger.environmentConfiguration.sdkVersion
     *
     */
    private sdkver: string;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Token.GeoProvider}
     *
     */
    private geoProvider: IGeoProvider;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Object}
     * @desc contains some static values used for AdEngine tracking - pulled from the SDK Config
     * @note adTargeting comes from adEngine.extras.adTargeting
     *
     */
    private adTargeting: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Advertising.AdvertisingIdProvider}
     * @desc the AdvertisingIdProvider provides device-specific advertising information for the adEngine team
     *
     */
    private advertisingIdProvider: AdvertisingIdProvider;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Boolean|undefined}
     *
     */
    public disabled?: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Media.AdEngine.AdEngineClientConfiguration} options.config
     * @param {String} options.sdkver
     * @param {SDK.Token.GeoProvider} options.geoProvider
     * @param {SDK.Advertising.AdvertisingIdProvider} options.advertisingIdProvider
     * @param {Boolean} [options.disabled]
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: AdEngineClientConfiguration;
        sdkver: string;
        geoProvider: IGeoProvider;
        advertisingIdProvider: AdvertisingIdProvider;
        disabled?: boolean;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(AdEngineClientConfiguration),
                    geoProvider: Types.instanceStrict(
                        PlatformProviders.GeoProvider
                    ),
                    sdkver: Types.nonEmptyString,
                    advertisingIdProvider: Types.instanceStrict(
                        AdvertisingIdProvider
                    ),
                    disabled: Types.boolean.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { sdkver, advertisingIdProvider, geoProvider, config, disabled } =
            options;

        const { extras } = config;
        const { adTargeting = {} } = extras || {};

        this.sdkver = sdkver;
        this.adTargeting = { ...adTargeting, ...{ sdkver } };
        this.advertisingIdProvider = advertisingIdProvider;
        this.geoProvider = geoProvider;
        this.disabled = disabled;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Used when the SDK and a native player share a networking stack. The stack must
     * have a local cookie store. If no cookie store is available, {getCookies} should be used.
     * @note adEngineObject is defined as SDK.Media.AdEngine.AdEnginePayload in the SDK spec,
     * for the JS SDK it's a passthrough Object called adEngineObject (which originates in the PlaybackSession).
     * @note The underlying network stack will call the ad-engine endpoint. The cookie will be saved in the cookie store.
     * @note The adEnginePayload Map should be posted as form data to the payload url.
     * @note this method needs to catch a failure in order to prevent playback from failing
     * @returns {Promise<Void>}
     *
     */
    public async updateCookies(
        options: Record<string, unknown>,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.nonEmptyObject,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'updateCookies', params, arguments);
        }

        const adEngineObject = await this.constructAdEngineData(options);

        const { logger } = this;

        const endpointKey = AdEngineClientEndpoint.setTokenPost;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: adEngineObject
        }) as ReturnType<typeof this.getPayload> & { credentials: string };

        payload.credentials = 'include';

        logger.info(
            this.toString(),
            `Attempting to update AdEngine cookies with: "${payload.body}".`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AdEngineClientDustUrnReference.updateCookies,
            payload,
            data: {
                query: payload.body
            },
            endpointKey,
            logTransaction
        });

        return super
            .request({
                payload,
                dustLogUtility,
                resultMapper: (response) => {
                    return response;
                }
            })
            .catch((exception) => {
                this.logger.warn(this.toString(), exception);

                return Promise.resolve();
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Used when the SDK and a native player share do not share a networking stack and
     * cookies can be set on the native player. May not be required on all platforms.
     * @note adEngineObject is defined as SDK.Media.AdEngine.AdEnginePayload in the SDK spec,
     * for the JS SDK it's a passthrough Object called adEngineObject.
     * @note The networking stack will call the ad-engine endpoint and retrieve the cookie from
     * the response. The cookie is then added to the native player.
     * @note The adEnginePayload Map should be posted as form data to the payload url.
     * @note this method needs to catch a failure in order to prevent playback from failing.
     * @note this method is not currently used but exists to align with the spec
     * @returns {Promise<Cookie[]>}
     *
     */
    public async getCookies(
        options: Record<string, unknown>,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.nonEmptyObject,
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getCookies', params, arguments);
        }

        const adEngineObject = await this.constructAdEngineData(options);

        const { logger } = this;

        const endpointKey = AdEngineClientEndpoint.setTokenPost;

        const payload = this.getPayload({
            accessToken,
            endpointKey, // uses the same endpoint as updateCookies
            body: adEngineObject
        });

        logger.info(
            this.toString(),
            `Attempting to update AdEngine cookies with: "${payload.body}".`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: AdEngineClientDustUrnReference.getCookies,
            payload,
            data: {
                query: payload.body
            },
            endpointKey,
            logTransaction
        });

        return super
            .request({
                payload,
                dustLogUtility,
                resultMapper: (response) => {
                    return response;
                }
            })
            .catch((exception) => {
                this.logger.warn(this.toString(), exception);

                return Promise.resolve([]);
            });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @desc gets geoLocation and merges various tracking information into a temporary, flat object
     * @note xny needs to be a String w/o spaces - e.g. '[1,2]'
     * @note xny should default to undefined to avoid being serialized in the client
     * @note xny should be omitted if 0,0 i.e. not provided
     * @note lat maps true to 1 and false to 0 - must be 0|1
     * @note all adEngine query param keys are lowercase not camelCase
     * @note IMPORTANT: when constructing the adEngine object make sure that `this.adTargeting` is merged last to
     * avoid app developers from overriding values from the config
     * @returns {Promise<Object>}
     *
     */
    private async constructAdEngineData(options: Record<string, unknown>) {
        const { adTargeting } = this;

        const geoLocation = await this.geoProvider.getGeoLocation();

        const { latitude, longitude } = geoLocation;

        const coordinates = `[${latitude},${longitude}]`;
        const advertisingId = this.advertisingIdProvider.getId();
        const limitAdTracking =
            this.advertisingIdProvider.getLimitAdTrackingEnabled();

        const sdkGenerated = {
            devid: advertisingId,
            lat: limitAdTracking === LimitAdTrackingEnabled.YES ? 1 : 0,
            xny: coordinates !== '[0,0]' ? coordinates : undefined
        };

        const adEngineObject = { ...sdkGenerated, ...options, ...adTargeting };

        return adEngineObject;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Media.AdEngine.AdEngineClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: AdEngineClientEndpoint;
        data?: Record<string, unknown>;
        body?: Record<string, unknown>;
    }) {
        const { accessToken, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, headers, method } = endpoint;
        const requestBody = body ? queryString.stringify(body) : '';
        const url = href;

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Media.AdEngine.AdEngineClient';
    }
}
