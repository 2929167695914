/**
 *
 * @module serverRequest
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { FetchStatus, HttpMethod, NetworkError, NetworkType } from './enums';

/**
 *
 * @since 4.0.0
 *
 */
export default class ServerRequest {
    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.QualityOfService.HttpMethod|null}
     * @desc HTTP method used in the request.
     *
     */
    public method: Nullable<HttpMethod>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|null}
     * @desc Scheme and Hostname of the request.
     * @note Example: "https://bam-sdk-configs.bamgrid.com".
     *
     */
    public host: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|null}
     * @desc Path and Query Paramaters of the request.
     *
     */
    public path: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Number|null}
     * @desc HTTP status code returned in the response.
     *
     */
    public statusCode: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|null}
     * @desc Platform request identifier, returned as `X-Request-ID` header in the response.
     *
     */
    public requestId: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Number|null}
     * @desc Duration in milliseconds between request and response, as measured by the client.
     *
     */
    public roundTripTime: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.QualityOfService.FetchStatus|null}
     * @desc the status of the service request.
     *
     */
    public status: Nullable<FetchStatus>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|null}
     * @desc The IP of the server. `null` if error or cannot be determined.
     *
     */
    public serverIP: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String}
     * @desc The CDN running the server. `null` if not run by a CDN or cannot be determined.
     *
     */
    public cdnName: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.QualityOfService.NetworkType|null}
     * @desc The type of network connection currently in use by the client. `null` if it cannot be determined.
     *
     */
    public networkType: Nullable<NetworkType>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Number|null}
     * @desc The time between when request was issued and first byte received in milliseconds. `null` if no response.
     *
     */
    public timeToFirstByte: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.QualityOfService.NetworkError|null}
     * @desc An error representing a service request failure. `null` if no error occurred.
     *
     */
    public error: Nullable<NetworkError>;

    /**
     *
     * @param {Object} [options={}]
     * @param {SDK.Services.QualityOfService.HttpMethod|null} [options.method=null]
     * @param {String|null} [options.host=null]
     * @param {String|null} [options.path=null]
     * @param {Number|null} [options.statusCode=null]
     * @param {String|null} [options.requestId=null]
     * @param {Number|null} [options.roundTripTime=null]
     * @param {String<SDK.Services.QualityOfService.FetchStatus>|null} [options.status=null]
     * @param {String|null} [options.serverIP=null]
     * @param {String|null} [options.cdnName=null]
     * @param {String<SDK.Services.QualityOfService.NetworkType>|null} [options.networkType=null]
     * @param {Number|null} [options.timeToFirstByte=null]
     * @param {String<SDK.Services.QualityOfService.NetworkError>|null} [options.error=null]
     *
     */
    public constructor(options?: {
        method?: Nullable<HttpMethod>;
        host?: Nullable<string>;
        path?: Nullable<string>;
        statusCode?: Nullable<number>;
        requestId?: Nullable<string>;
        roundTripTime?: Nullable<number>;
        status?: Nullable<FetchStatus>;
        serverIP?: Nullable<string>;
        cdnName?: Nullable<string>;
        networkType?: Nullable<NetworkType>;
        timeToFirstByte?: Nullable<number>;
        error?: Nullable<NetworkError>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    method: Types.in(HttpMethod).optional,
                    host: Types.nonEmptyString.optional,
                    path: Types.nonEmptyString.optional,
                    statusCode: Types.number.optional,
                    requestId: Types.nonEmptyString.optional,
                    roundTripTime: Types.number.optional,
                    status: Types.in(FetchStatus).optional,
                    serverIP: Types.nonEmptyString.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    timeToFirstByte: Types.number.optional,
                    error: Types.in(NetworkError).optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            method,
            host,
            path,
            statusCode,
            requestId,
            roundTripTime,
            status,
            serverIP,
            cdnName,
            networkType,
            timeToFirstByte,
            error
        } = options || {};

        this.method = method || null;
        this.host = host || null;
        this.path = path || null;
        this.statusCode = statusCode ?? null;
        this.requestId = requestId || null;
        this.roundTripTime = roundTripTime ?? null;
        this.status = status || null;
        this.serverIP = serverIP || null;
        this.cdnName = cdnName || 'null';
        this.networkType = networkType || null;
        this.timeToFirstByte = timeToFirstByte ?? null;
        this.error = error || null;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.ServerRequest';
    }
}
