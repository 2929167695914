/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import {
    AffiliateTracking,
    AffiliateTrackingTypedef,
    BillingAddress,
    BillingAddressTypedef,
    LineItem,
    LineItemTypedef,
    OrderCampaign,
    OrderCampaignTypedef,
    StrongCustomerAuth,
    StrongCustomerAuthTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

import { PaymentCardType } from '../../services/commerce/enums';

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentCard.CardPaymentMethod
 * @since 9.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: `"single_use"` | `"multi_use"`.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {String} [alternateName] - Alias (nickname) for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentCardType>} cardType - Card type: CREDIT | DEBIT.
 * @property {String<SDK.Services.Commerce.PaymentCardType>} [cardTypeOverride] - Used to override cardType: CREDIT | DEBIT.
 * @property {String} firstSix - This is a fake string returned for compatibility reasons.
 * @property {String} lastFour - Last four digits of the card number.
 * @property {Number} expiryMonth - Expiry month of a payment card.
 * @property {Number} expiryYear - Expiry year of a payment card.
 * @property {String} brand - Card brand.
 * @property {String} country - Card country. See ISO 3166-1 alpha-2.
 * @property {String} issuer - Card issuer. UNKNOWN when the issuer cannot be determined.
 * @property {Boolean} isCorporate Flag to indicate if the payment card a corporate card.
 * @property {String} ownerFullName - Card holder full name.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users billing address.
 * @property {String} [schemeTransactionIdentifier] - World Pay transaction identifier.
 * @property {String} [networkTxReference] - Links the transaction to the previous in a subscription/card-on-file chain.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface CardPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    usage: string;
    isShared?: boolean;
    alternateName?: string;
    cardType: PaymentCardType;
    cardTypeOverride?: PaymentCardType;
    firstSix: string;
    lastFour: string;
    expiryMonth: number;
    expiryYear: number;
    brand: string;
    country: string;
    issuer: string;
    isCorporate: boolean;
    ownerFullName: string;
    billingAddress: BillingAddress;
    schemeTransactionIdentifier?: string;
    networkTxReference?: string;
}

/**
 *
 * @access private
 *
 */
export const CardPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    alternateName: Types.nonEmptyString.optional,
    cardType: Types.in(PaymentCardType),
    cardTypeOverride: Types.in(PaymentCardType).optional,
    firstSix: Types.nonEmptyString,
    lastFour: Types.nonEmptyString,
    expiryMonth: Types.number,
    expiryYear: Types.number,
    brand: Types.nonEmptyString,
    country: Types.nonEmptyString,
    issuer: Types.nonEmptyString,
    isCorporate: Types.boolean,
    ownerFullName: Types.nonEmptyString,
    billingAddress: Types.object(BillingAddressTypedef),
    schemeTransactionIdentifier: Types.nonEmptyString.optional,
    networkTxReference: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentCard.CardPaymentMethodInformation
 * @since 9.0.0
 * @property {String} creditCardNumber - The valid credit card number.
 * @property {String} [alternateName] - Alias (nickname) for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentCardType>} [cardTypeOverride] - Optional card type override, example: CREDIT | DEBIT.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - A postal address.
 * @property {Number} expiryMonth - Expiry month of a payment method.
 * @property {Number} expiryYear - Expiry year of a payment method.
 * @property {String} ownerFullName - Card holder full name.
 * @property {Boolean} isDefault - Flag to mark this payment method as default.
 * @property {Boolean} isReusable - Flag to mark this payment method as re-usable for future transactions.
 * @property {Boolean} isShared - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 *
 */
export interface CardPaymentMethodInformation {
    creditCardNumber: string;
    alternateName?: string;
    cardTypeOverride?: PaymentCardType;
    billingAddress: BillingAddress;
    expiryMonth: number;
    expiryYear: number;
    ownerFullName: string;
    isDefault: boolean;
    isReusable: boolean;
    isShared: boolean;

    /**
     * @note optional because it's set based on isReusable within the SDK
     */
    usage?: 'single_use' | 'multi_use';
}

/**
 *
 * @access private
 *
 */
export const CardPaymentMethodInformationTypedef = {
    creditCardNumber: Types.nonEmptyString,
    alternateName: Types.nonEmptyString.optional,
    cardTypeOverride: Types.in(PaymentCardType).optional,
    billingAddress: Types.object(BillingAddressTypedef),
    expiryMonth: Types.number,
    expiryYear: Types.number,
    ownerFullName: Types.nonEmptyString,
    isDefault: Types.boolean,
    isReusable: Types.boolean,
    isShared: Types.boolean
};

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentCard.CardUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {Number} [expiryMonth] - Expiry month of a payment card.
 * @property {Number} [expiryYear] - Expiry year of a payment card.
 * @property {String} [alternateName] - Alias (nickname) for the payment method.
 * @property {String} [schemeTransactionIdentifier] - WorldPay transaction identifier.
 * @property {Boolean} [isShared] - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 * @property {String} [networkTxReference] - Links the transaction to the previous in a subscription/card-on-file chain. Required for token-based transactions where Adyen does not tokenize the card.
 * @property {String} [dateOfBirth] - Since `16.1.0` - Date of birth of the user, formatted as YYYY-MM-DD.
 * @property {String} [pin] - Since `16.1.0` - Two-digit PIN used to authorize a transaction.
 * @property {String} [cvv] - Since `16.1.0` - CVV of the user's card.
 *
 */
export interface CardUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    expiryMonth?: number;
    expiryYear?: number;
    alternateName?: string;
    schemeTransactionIdentifier?: string;
    isShared?: boolean;
    networkTxReference?: string;
    dateOfBirth?: string;
    pin?: string;
    cvv?: string;
}

/**
 *
 * @access private
 *
 */
export const CardUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    expiryMonth: Types.number.optional,
    expiryYear: Types.number.optional,
    alternateName: Types.nonEmptyString.optional,
    schemeTransactionIdentifier: Types.nonEmptyString.optional,
    isShared: Types.boolean.optional,
    networkTxReference: Types.nonEmptyString.optional,
    dateOfBirth: Types.dateString.optional,
    pin: Types.nonEmptyString.optional,
    cvv: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PassthroughData
 * @since 9.0.0
 * @property {String} [alternateName] - Alias (nickname) for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentCardType>} [cardTypeOverride] - Optional card type override, example: CREDIT | DEBIT.
 * @property {Number} expiryMonth - Expiry month of a payment card.
 * @property {Number} expiryYear - Expiry year of a payment card.
 * @property {String} ownerFullName - Card holder full name.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - A postal address.
 * @property {Boolean} isDefault - Flag to mark this payment method as default.
 * @property {Boolean} isShared - Flag to mark if the user has agreed to allow payment method to be shared across TWDC partners.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 *
 */
export interface PassthroughData {
    alternateName?: string;
    cardTypeOverride?: PaymentCardType;
    expiryMonth: number;
    expiryYear: number;
    ownerFullName: string;
    billingAddress: BillingAddress;
    isDefault: boolean;
    isShared: boolean;
    usage: string;
}

/**
 *
 * @access private
 *
 */
export const PassthroughDataTypedef = {
    alternateName: Types.nonEmptyString.optional,
    cardTypeOverride: Types.in(PaymentCardType).optional,
    expiryMonth: Types.number,
    expiryYear: Types.number,
    ownerFullName: Types.nonEmptyString,
    billingAddress: Types.object(BillingAddressTypedef),
    isDefault: Types.boolean,
    isShared: Types.boolean,
    usage: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.CreateCardPaymentMethodRequest
 * @since 9.0.0
 * @property {String} creditCardNumber - Card number for the payment method.
 * @property {Number} namespaceId - Identifies the partner this payment method should be associated to.
 * @property {Object<SDK.Commerce.PassthroughData>} passthroughData - Data to be passed through directly to commerce services.
 *
 */
export interface CreateCardPaymentMethodRequest {
    creditCardNumber: string;
    namespaceId: number;
    passthroughData: PassthroughData;
}

/**
 *
 * @access private
 *
 */
export const CreateCardPaymentMethodRequestTypedef = {
    creditCardNumber: Types.nonEmptyString,
    namespaceId: Types.number,
    passthroughData: Types.object(PassthroughDataTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentCard.SubmitOrderWithCardPaymentMethodRequest
 * @since 9.0.0
 * @property {Object<SDK.Commerce.AffiliateTracking>} [affiliateTracking] - Affiliated partner id and source.
 * @property {String} cardSecurityCode - CSC/CVV number of a credit card.
 * @property {Array<Object<SDK.Commerce.LineItem>>} lineItems - Products to be purchased in this order.
 * @property {String} paymentMethodId - Payment method ID retrieved from a request to create or get a payment method.
 * @property {Array<Object<SDK.Commerce.OrderCampaign>>} orderCampaigns - Information relevant to special pricing considerations for an order.
 * @property {Object<SDK.Commerce.StrongCustomerAuth>} [sca] - Additional information that is only required for 3DS security challenges.
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {Object} [attributes] - Optional context to apply to purchase order.
 *
 */
export interface SubmitOrderWithCardPaymentMethodRequest {
    affiliateTracking?: AffiliateTracking;
    cardSecurityCode: string;
    lineItems: Array<LineItem>;
    paymentMethodId: string;
    orderCampaigns: Array<OrderCampaign>;
    sca?: StrongCustomerAuth;
    offerId?: string;
    attributes?: object;
}

/**
 *
 * @access private
 *
 */
export const SubmitOrderWithCardPaymentMethodRequestTypedef = {
    affiliateTracking: Types.object(AffiliateTrackingTypedef).optional,
    cardSecurityCode: Types.nonEmptyString,
    lineItems: Types.array.of.object(LineItemTypedef),
    paymentMethodId: Types.nonEmptyString,
    orderCampaigns: Types.array.of.object(OrderCampaignTypedef),
    sca: Types.object(StrongCustomerAuthTypedef).optional,
    offerId: Types.nonEmptyString.optional,
    attributes: Types.object().optional
};
