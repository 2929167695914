/**
 *
 * @module playbackAttributes
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { DrmType } from './enums';

import { PlaybackSecurity, PlaybackSecurityTypedef } from './typedefs';

/**
 *
 * @since 4.0.0
 * @desc Contains information about the playlist streams.
 *
 */
export default class PlaybackAttributes {
    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|undefined}
     * @desc Gets the ad partner ID.
     *
     */
    public adPartner?: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<SDK.Services.Media.DrmType>}
     * @desc Gets the list of DRMs supported by this content.
     *
     */
    public drms: Array<DrmType>;

    /**
     *
     * @access public
     * @since 8.0.0
     * @type {SDK.Services.Media.PlaybackSecurity}
     * @desc Gets the media playback security `Object`.
     *
     */
    public security: PlaybackSecurity;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String}
     *
     */
    public encryptionType: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<String>}
     *
     */
    public audioSegmentTypes: Array<string>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<String>}
     *
     */
    public videoSegmentTypes: Array<string>;

    /**
     *
     * @access public
     * @since 4.5.0
     * @type {Array<String>}
     *
     */
    public videoRanges: Array<string>;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Number|undefined}
     *
     */
    public imageAspectRatio?: number;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {String|undefined}
     *
     */
    public experienceContext?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.adPartner]
     * @param {Array<SDK.Services.Media.DrmType>} options.drms
     * @param {SDK.Services.Media.PlaybackSecurity} options.security
     * @param {String} options.encryptionType
     * @param {Array<String>} options.audioSegmentTypes
     * @param {Array<String>} options.videoSegmentTypes
     * @param {Array<String>} options.videoRanges
     * @param {String} [options.imageAspectRatio]
     * @param {String} [options.experienceContext]
     *
     */
    public constructor(options: {
        adPartner?: string;
        drms: Array<DrmType>;
        security: PlaybackSecurity;
        encryptionType: string;
        audioSegmentTypes: Array<string>;
        videoSegmentTypes: Array<string>;
        videoRanges: Array<string>;
        imageAspectRatio?: number;
        experienceContext?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    adPartner: Types.nonEmptyString.optional,
                    drms: Types.array.of.in(DrmType),
                    security: Types.object(PlaybackSecurityTypedef),
                    encryptionType: Types.nonEmptyString,
                    audioSegmentTypes: Types.array.of.nonEmptyString,
                    videoSegmentTypes: Types.array.of.nonEmptyString,
                    videoRanges: Types.array.of.nonEmptyString,
                    imageAspectRatio: Types.number.optional,
                    experienceContext: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            adPartner,
            drms,
            security,
            encryptionType,
            audioSegmentTypes,
            videoSegmentTypes,
            videoRanges,
            imageAspectRatio,
            experienceContext
        } = options;

        this.adPartner = adPartner;
        this.drms = drms;
        this.security = security;
        this.encryptionType = encryptionType;
        this.audioSegmentTypes = audioSegmentTypes;
        this.videoSegmentTypes = videoSegmentTypes;
        this.videoRanges = videoRanges;
        this.imageAspectRatio = imageAspectRatio;
        this.experienceContext = experienceContext;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} data
     * @desc Returns a PlaybackAttributes object or undefined if data is not assigned.
     * @returns {SDK.Services.Media.PlaybackAttributes|undefined}
     *
     */
    public static parse(data: TodoAny) {
        if (data) {
            const security: PlaybackSecurity = data.security;

            return new PlaybackAttributes({
                ...data,
                security
            });
        }

        return undefined;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackAttributes';
    }
}
