/**
 *
 * @module handleErrorCase
 *
 */

import ErrorCode from '../../exception/errorCode';
import ErrorReason from '../../exception/errorReason';

import errorContextMethod from './errorContextMethod';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Finds the matching error case with given error criteria.
 * @returns {Exception|undefined}
 *
 */
export default function handleErrorCase(
    errors: Array<ErrorReason>,
    status: number,
    methodName?: string
) {
    const { description, code } = errors[0] || {};

    let exceptionData;

    /**
     *
     * @note Filters through the ErrorCode reference object to determine the error case based on
     * the supplied error code from the failed service request.
     *
     */
    for (const key of Object.keys(ErrorCode)) {
        const errorCode = ErrorCode[key];

        /**
         *
         * @note Some error cases require us to check against the returned error description.
         * Currently this is only used for LocationNotAllowedException and InvalidGrantException
         *
         */
        if (
            Array.isArray(errorCode.description) &&
            code === errorCode.code &&
            errorCode.description.includes(description)
        ) {
            exceptionData = errorCode.exceptionData;
            break;
        }

        /**
         *
         * @note Some error cases require us to check against the http status code and the returned error code.
         *
         */
        if (Array.isArray(errorCode.status) && !exceptionData) {
            if (
                code === errorCode.code &&
                errorCode.status.includes(status as number)
            ) {
                exceptionData = errorCode.exceptionData;
                break;
            }
        }

        /**
         *
         * @note Some specific error cases only require a unique error code.
         *
         */
        if (code === errorCode.code && !exceptionData) {
            exceptionData = errorCode.exceptionData;
            break;
        }
    }

    if (methodName && !exceptionData) {
        /**
         *
         * @note Possible error cases for a given exception. These error cases are for exceptions
         * that are based on http status and method name.
         *
         */
        exceptionData = errorContextMethod(status, methodName);
    }

    return exceptionData;
}
