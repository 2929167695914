/**
 *
 * @module sessionInfoRequest
 *
 */

import OrchestrationRequest from '../services/orchestration/internal/orchestrationRequest';
import { activeSession } from '../services/orchestration/internal/queries.gql';

/**
 *
 * @since 14.0.0
 * @access protected
 *
 */
export default class SessionInfoRequest extends OrchestrationRequest {
    public constructor() {
        super({ query: activeSession, operationName: 'activeSession' });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Session.SessionInfoRequest';
    }
}
