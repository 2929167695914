/**
 *
 * @module entry
 * @desc {SDK.Services.GraphQl} entry point.
 *
 */

import GraphQlClient from './graphQlClient';
import GraphQlRequest from './graphQlRequest';
import GraphQlAccountDelegationRefreshToken from './graphQlAccountDelegationRefreshToken';

/**
 *
 * @access public
 * @since 4.12.0
 * @typedef {Object} SDK.Services.GraphQl
 *
 * @property {GraphQlClient} GraphQlClient
 * @property {GraphQlRequest} GraphQlRequest
 * @property {GraphQlRequest} GraphQlAccountDelegationRefreshToken
 *
 */
export default {
    GraphQlClient,
    GraphQlRequest,
    GraphQlAccountDelegationRefreshToken
};
