/**
 *
 * @module accessStateData
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessContextState from './accessContextState';

/**
 *
 * @desc Represents the access state data.
 *
 */
export default class AccessStateData {
    /**
     *
     * @access public
     * @type {String}
     * @desc The primary access token.
     *
     */
    public token: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc The token needed for refreshing and obtaining a new token.
     *
     */
    public refreshToken: string;

    /**
     *
     * @access public
     * @type {AccessContextState}
     * @desc The modes or permissions the token provides.
     *
     */
    public contextState: AccessContextState;

    /**
     *
     * @param {String} token
     * @param {String} refreshToken
     * @param {AccessContextState} contextState
     *
     */
    public constructor(
        token: string,
        refreshToken: string,
        contextState: AccessContextState
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                token: Types.nonEmptyString,
                refreshToken: Types.nonEmptyString,
                contextState: Types.instanceStrict(AccessContextState)
            };

            typecheck(this, params, arguments);
        }

        this.token = token;

        this.refreshToken = refreshToken;

        this.contextState = contextState;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.AccessStateData';
    }
}
