/**
 *
 * @module melHivePlayerAdapter
 * @desc PlayerAdapter for mel-hive devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/master/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/master/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/events.html
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import PublicEvents from '../../events';
import InternalEvents from '../../internalEvents';
import PlaybackEventListener from '../playbackEventListener';
import DrmProvider from '../../drm/drmProvider';
import PlaybackMetrics from '../playbackMetrics';
import Playlist from '../playlist';
import PlayerAdapter, { HlsPlaylist } from '../playerAdapter';
import BamHlsErrorMapping from '../bamHlsErrorMapping';
import EventListenerProvider from '../eventListenerProvider';

import PlaybackVariant from '../../services/media/playbackVariant';

import { SubtitleRendition } from '../../services/media/typedefs';

import {
    DrmType,
    InsertionPointPlacement,
    PlaylistType
} from '../../services/media/enums';

import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import ServerRequest from '../../services/qualityOfService/serverRequest';

import {
    PodPosition,
    PresentationType,
    BufferType,
    ErrorLevel,
    FetchStatus,
    HttpMethod,
    MediaSegmentType,
    NetworkType,
    PlaybackExitedCause,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackSeekCause,
    PlaybackState,
    PlayerSeekDirection,
    SeekDirection,
    StartupActivity,
    QoePlaybackError,
    ApplicationContext,
    PlaybackMode,
    MediaSegmentTypeMap,
    InterstitialType,
    InterstitialPlacement,
    InterstitialEndedCause
} from '../../services/qualityOfService/enums';

import { getResolutionString, getTrackTypes } from './playerAdapterUtils';
import PlaybackHeartbeatEventData from '../../services/qualityOfService/playbackHeartbeatEventData';
import Debugger from '../debugger';

import {
    NetworkMetricsEventData,
    PlaybackMetricsEventData,
    PlaylistEvent,
    TimeUpdateEventData
} from '../typedefs';

import { HttpCoreMethod } from '../../services/providers/typedefs';
import { AUTHORIZATION } from '../../services/providers/shared/httpHeaderConstants';
import getFetchStatus from '../../services/qualityOfService/getFetchStatus';
import {
    createSimpleException,
    createInvalidStateException
} from '../../services/util/errorHandling/createException';

import { AdSlotData } from '../../services/qualityOfService/typedefs';
import { DEFAULT_ZERO_GUID } from '../../constants';
import delay from '../../services/util/delay';

const defaultAdMetadata = {
    adSlotData: {
        adMediaId: DEFAULT_ZERO_GUID,
        slotNumber: 0,
        plannedLength: 0
    },
    adPodPlacement: {
        podPosition: PodPosition.preroll
    },
    adPodData: {
        plannedSlotCount: 0,
        plannedLength: 0
    },
    adPlayheadPosition: 0,
    mediaId: DEFAULT_ZERO_GUID,
    adSubtitleData: {
        subtitleVisibility: false
    }
};

const HlsStreamEvents = {
    MEDIAELEMENT_STATE_CHANGED: 'MEDIAELEMENT_STATE_CHANGED',
    PRESENTATION_STATE_LOADED: 'PRESENTATION_STATE_LOADED',
    MEDIAELEMENT_STATE_PLAYING: 'MEDIAELEMENT_STATE_PLAYING',
    DRM_LICENSE_REQUESTED: 'DRM_LICENSE_REQUESTED',
    DRM_LICENSE_RECEIVED: 'DRM_LICENSE_RECEIVED',
    CANPLAY: 'CANPLAY',
    SEEKING_STARTED: 'SEEKING_STARTED',
    SEEKABLE_RANGE_CHANGED: 'SEEKABLE_RANGE_CHANGED',
    BUFFERING_STARTED: 'BUFFERING_STARTED',
    BUFFERING_FINISHED: 'BUFFERING_FINISHED',
    SUBTITLE_RENDITION_CHANGED: 'SUBTITLE_RENDITION_CHANGED',
    MULTIVARIANT_PLAYLIST_REQUEST: 'MULTIVARIANT_PLAYLIST_REQUEST',
    MULTIVARIANT_PLAYLIST_FALLBACK: 'MULTIVARIANT_PLAYLIST_FALLBACK',
    MULTIVARIANT_PLAYLIST_LOADED: 'MULTIVARIANT_PLAYLIST_LOADED',
    MULTIVARIANT_PLAYLIST_PARSED: 'MULTIVARIANT_PLAYLIST_PARSED',
    SEEKING_FINISHED: 'SEEKING_FINISHED',
    VIDEO_RENDITION_UPDATED: 'VIDEO_RENDITION_UPDATED',
    AUDIO_RENDITION_UPDATED: 'AUDIO_RENDITION_UPDATED',
    SUBTITLE_RENDITION_UPDATED: 'SUBTITLE_RENDITION_UPDATED',
    AUDIO_RENDITION_CHANGED: 'AUDIO_RENDITION_CHANGED',
    ERROR: 'ERROR',
    SEGMENT_PLAYING: 'SEGMENT_PLAYING',
    SOURCE_BUFFER_APPEND_STARTED: 'SOURCE_BUFFER_APPEND_STARTED',
    CONTENT_DOWNLOAD_FINISHED: 'CONTENT_DOWNLOAD_FINISHED',
    PLAYBACK_METRICS_CHANGED: 'PLAYBACK_METRICS_CHANGED',
    NETWORK_METRICS_CHANGED: 'NETWORK_METRICS_CHANGED',
    INTERSTITIAL_SESSION_REQUESTED: 'INTERSTITIAL_SESSION_REQUESTED',
    INTERSTITIAL_SESSION_FETCHED: 'INTERSTITIAL_SESSION_FETCHED',
    INTERSTITIAL_SESSION_STARTED: 'INTERSTITIAL_SESSION_STARTED',
    INTERSTITIAL_SESSION_FINISHED: 'INTERSTITIAL_SESSION_FINISHED',
    INTERSTITIAL_MULTIVARIANT_FETCHED: 'INTERSTITIAL_MULTIVARIANT_FETCHED',
    INTERSTITIAL_VARIANT_FETCHED: 'INTERSTITIAL_VARIANT_FETCHED',
    INTERSTITIAL_ASSET_STARTED: 'INTERSTITIAL_ASSET_STARTED',
    INTERSTITIAL_ASSET_FINISHED: 'INTERSTITIAL_ASSET_FINISHED',
    INTERSTITIAL_SKIPPED: 'INTERSTITIAL_SKIPPED',
    BEACON_ERROR: 'BEACON_ERROR',
    INTERSTITIAL_SESSION_REQUESTED_ERROR:
        'INTERSTITIAL_SESSION_REQUESTED_ERROR',
    VIDEO_SEGMENT_LOADED: 'VIDEO_SEGMENT_LOADED',
    MEDIA_PLAYLIST_REQUEST: 'MEDIA_PLAYLIST_REQUEST',
    MEDIA_PLAYLIST_LOADED: 'MEDIA_PLAYLIST_LOADED',
    TIMEUPDATE: 'TIMEUPDATE'
} as const;

const HlsStreamMediaElementStates = {
    DETACHING: 0,
    DETACHED: 1,
    ATTACHING: 2,
    ATTACHED: 3,
    ENDED: 4,
    PLAYING: 5,
    PAUSED: 6,
    BUFFERING: 7,
    SEEKING: 8
} as const;

const HlsStreamDataTypes = {
    KEY: 'KEY',
    CHUNK: 'CHUNK',
    MULTIVARIANT_PLAYLIST: 'MULTIVARIANT_PLAYLIST',
    MEDIA_PLAYLIST_VIDEO: 'MEDIA_PLAYLIST_VIDEO',
    MEDIA_PLAYLIST_AUDIO: 'MEDIA_PLAYLIST_AUDIO',
    MEDIA_PLAYLIST_SUBTITLES: 'MEDIA_PLAYLIST_SUBTITLES',
    MEDIA_SEGMENT_VIDEO: 'MEDIA_SEGMENT_VIDEO',
    MEDIA_SEGMENT_AUDIO: 'MEDIA_SEGMENT_AUDIO',
    MEDIA_SEGMENT_SUBTITLES: 'MEDIA_SEGMENT_SUBTITLES',
    INIT_SECTION_VIDEO: 'INIT_SECTION_VIDEO',
    INIT_SECTION_AUDIO: 'INIT_SECTION_AUDIO',
    INIT_SECTION_SUBTITLES: 'INIT_SECTION_SUBTITLES',
    DRM_LICENSE: 'DRM_LICENSE',
    AD_POD: 'AD_POD'
} as const;

const HlsStreamAssetTypes = {
    AD: 'ad',
    CONTENT: 'content',
    BUMPER: 'bumper',
    SLUG: 'slug',
    CONTENT_PROMO: 'promo'
} as const;

const HlsStreamAssetIds = {
    MAIN_CONTENT: 'main-content'
} as const;

export type HlsStream = {
    Events: typeof HlsStreamEvents;
    MediaElementStates: typeof HlsStreamMediaElementStates;
    DataTypes: typeof HlsStreamDataTypes;
    AssetTypes: typeof HlsStreamAssetTypes;
    AssetIds: typeof HlsStreamAssetIds;
};

type Rendition = {
    url: UrlString;
    language: string;
    name: string;
    forced: boolean;
    codec: string;
    channels: string;
};

type PlayerServerRequest = {
    serverIp: Nullable<string>;
    fetchStatus: string;
    method: string;
    host: string;
    path: string;
};

type InterstitialMetadata = {
    placement: string;
    midRollIndex?: number;
    totalDuration?: number;
    slotCount: number;
    slotNumber: number;
    adDuration: number;
    adId?: string;
};

type AssetData = {
    mediaSegmentType: string;
    channels: string;
    codec: string;
    language: string;
    name: string;
    bitrate: number;
    range: string;
    averageBitrate: number;
    resolution: string;
    frameRate: number;
};

type EventData = {
    playlist?: HlsPlaylist;
    url?: string;
    keyID: string;
    mediaElementState: number;
    lastMediaElementState: number;
    rendition: Rendition;
    serverRequest: PlayerServerRequest;
    currentTime: number;
    targetPosition: number;
    id: string;
    fatal: boolean;
    message: string;
    error: string;
    pdtEnd: number;
    interstitialMetadata: InterstitialMetadata;
    startTimestamp: number;
    assetData: AssetData;
    asset: { mediaId: string };
    segment?: { programDateTimeStart: number; byteSize: number };
    type: MediaSegmentType;
    startTime: number;
    endTime: number;
    cause: keyof typeof BamHlsErrorMapping;
    response: Record<string, Array<unknown>>;
    assetType: PresentationType;
};

type AdResponse = { pods: Array<{ ads: Array<AdSlotData> }> };

/**
 *
 * @since 18.0.0
 * @desc Interface used to communicate with the media player.
 *
 */
@Debugger.NativePlayerProxy()
@Debugger.EventCapture(HlsStreamEvents)
export default class MelHivePlayerAdapter extends PlayerAdapter<MelHiveNativePlayer> {
    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Number|undefined}
     * @desc Value of the `PROGRAM-DATE-TIME` of the last (most recent) seek-able segment + segment length in
     * epoch millis i.e. end of last segment. Used to support live latency calculation.
     * @note This is stored here as an intermediary due to the data being returned in one event but being needed in others.
     *
     */
    private seekableRangeEndProgramDateTime?: number;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Services.QualityOfService.PlaybackStartupEventData|null}
     * @desc Keeps track of CDN fallback for QoE support.
     *
     */
    private playbackStartupEventData: Nullable<PlaybackStartupEventData>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Object}
     * @desc Keeps track of CDN fallback QoE tracking information.
     *
     */
    private qos?: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Date|null}
     * @desc Time when rebufferingStartedEvent is triggered. Used to calculate duration for rebufferingEndedEvent.
     *
     */
    private rebufferingDuration: Nullable<Date>;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Array<SDK.Drm.DrmProvider>}
     * @desc Set of DRM providers
     *
     */
    public drmProviders: Array<
        DrmProvider & {
            individualizationUri?: string;
            getFairPlayCertificate?: Noop<undefined>;
            getWidevineCertificate?: Noop<undefined>;
            getNagraCertificate?: Noop<undefined>;
        }
    >;

    /**
     * @access public
     * @since 18.0.0
     * @type {Object}
     * @desc Formatted drmConfigurations object to be used on the MelHive player.
     */
    public drmConfigurations: Record<string, unknown>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {HlsStream}
     *
     */
    private melStatic: HlsStream;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {HlsStream.Events}
     * @note Events enum
     *
     */
    private Events: typeof HlsStreamEvents;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {HlsStream.MediaElementStates}
     * @note MediaElementStates Enum
     *
     */
    private MediaElementStates: typeof HlsStreamMediaElementStates;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {HlsStream.DataTypes}
     * @note DataTypes Enum
     *
     */
    private DataTypes: typeof HlsStreamDataTypes;

    /**
     *
     * @access private
     * @since 21.0.0
     * @type {HlsStream.AssetTypes}
     * @note AssetTypes Enum
     *
     */
    private AssetTypes: typeof HlsStreamAssetTypes;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Boolean}
     * @desc Indicates when player is buffering.
     *
     */
    private isBuffering: boolean;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Boolean}
     * @desc Indicates when application is backgrounded or foregrounded.
     *
     */
    private isApplicationBackgrounded: boolean;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Number|null}
     * @desc current playing segment from the `SEGMENT_PLAYING` event.
     * @note eventData.segment.programDateTimeStart.
     *
     */
    private segmentPosition: Nullable<number>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Number|null}
     * @desc the starting bitrate for the media content.
     *
     */
    private mediaStartBitrate: Nullable<number>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {String<SDK.Services.QualityOfService.MediaSegmentType>|null}
     * @desc the starting bitrate for the media content.
     *
     */
    private mediaSegmentType: Nullable<MediaSegmentType>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Number}
     * @desc the total number of media bytes downloaded.
     *
     */
    private mediaBytesDownloaded: number;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {SDK.Services.QualityOfService.BufferType|undefined}
     * @desc Used to store buffer type for rebuffering ended.
     *
     */
    private bufferType?: BufferType;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Boolean}
     * @desc A flag to keep track of when the player is ready.
     *
     */
    private isReady: boolean;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {Object}
     * @desc Cached values use for the heartbeat event that are updated
     * when specific events fire.
     *
     */
    private heartbeatData: Partial<PlaybackHeartbeatEventData>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {PlaybackMetricsEventData|null}
     * @desc Used to store the last known playback metrics raised
     *
     */
    private playbackMetricsEventData: Nullable<PlaybackMetricsEventData>;

    /**
     *
     * @access private
     * @since 18.0.0
     * @type {NetworkMetricsEventData|null}
     * @desc Used to store the last known network metrics raised
     *
     */
    private networkMetricsEventData: Nullable<NetworkMetricsEventData>;

    /**
     *
     * @access private
     * @since 19.0.0
     * @type {String<SDK.Services.QualityOfService.PresentationType>}
     * @desc Used to store the last known presentation type.
     *
     */
    private presentationType: PresentationType;

    /**
     *
     * @access private
     * @since 20.0.1
     * @type {Number}
     * @desc used to indicate the current bitrateAvg for the chosen variant
     *
     */
    private bitrateAvg: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number}
     * @desc The client epoch timestamp in milliseconds when the DRM license request begins.
     *
     */
    public fetchLicenseStartTime: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The total time in milliseconds it took to create the non-UI player stack.
     *
     */
    private initializePlayerDuration?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the creation of the non-UI player stack begins.
     *
     */
    private initializePlayerStartTime?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc The client epoch timestamp in milliseconds when the multivariant playlist fetch begins.
     *
     */
    private multivariantFetchedStartTime?: number;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {Number}
     * @desc The client epoch timestamp in milliseconds when the platform-appropriate playback measurement begins.
     *
     */
    private readyPlayerStartTime: number;

    /**
     *
     * @access private
     * @since 22.0.0
     * @type {Object|null}
     * @desc The most recent multivariant playlist request
     *
     */
    private currentMultivariantPlaylistRequest: Nullable<{
        assetType?: PresentationType;
        url?: string;
    }>;

    /**
     *
     * @access private
     * @since 24.0.0
     * @type {Object}
     * @desc Cached values use for the variantFetch event
     *
     */
    private playlistCache: Record<
        UrlString,
        { variantFetchedStartTime: number; event?: Record<string, unknown> }
    >;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Boolean}
     * @desc Determines pause log tracking.
     *
     */
    private capturePauseLogs: boolean;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Array<String>}
     * @desc Used to track logs after a pause.
     * @note Used to determine if a pause was caused by a seek.
     *
     */
    private pauseLogs: Array<string>;

    /**
     *
     * @access private
     * @since 27.1.2
     * @type {Number}
     *
     */
    private lastKnownMainContentTime!: number;

    /**
     *
     * @access private
     * @since 27.1.2
     * @type {Number}
     *
     */
    private lastKnownCurrentTime!: number;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Boolean}
     *
     */
    private isAdSkipped: boolean;

    /**
     *
     * @access private
     * @since 28.1.0
     * @type {Number}
     * @note Value is in milliseconds
     *
     */
    private interstitialSessionDuration: number;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer - An instance of MelHivePlayer.
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @param {Number} [options.videoStartTimestamp]
     * @param {Boolean} [options.enableDebugLogs]
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} [options.initialPlaybackMode]
     * @note The MelHivePlayerAdapter requires nativePlayer?.on && nativePlayer?.off
     *
     */
    public constructor(options: {
        nativePlayer: MelHiveNativePlayer;
        videoPlayerName: string;
        videoPlayerVersion: string;
        videoStartTimestamp?: number;
        enableDebugLogs?: boolean;
        initialPlaybackMode?: PlaybackMode;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    videoStartTimestamp: Types.number.optional,
                    enableDebugLogs: Types.boolean.optional,
                    initialPlaybackMode: Types.in(PlaybackMode).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { videoStartTimestamp = Date.now(), initialPlaybackMode } =
            options;

        this.seekableRangeEndProgramDateTime = undefined;
        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });
        this.qos = {};
        this.rebufferingDuration = null;
        this.drmProviders = [];
        this.drmConfigurations = {};
        this.melStatic = this.nativePlayer?.getClass() as HlsStream;
        this.Events = this.melStatic.Events;
        this.MediaElementStates = this.melStatic.MediaElementStates;
        this.DataTypes = this.melStatic.DataTypes;
        this.AssetTypes = this.melStatic.AssetTypes;
        this.isBuffering = false;
        this.isApplicationBackgrounded = false;
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.mediaBytesDownloaded = 0;
        this.bufferType = undefined;
        this.isReady = false;
        this.heartbeatData = {
            videoStartTimestamp
        };
        this.playbackMetricsEventData = null;
        this.networkMetricsEventData = null;
        this.presentationType = PresentationType.main;
        this.bitrateAvg = 0;

        this.currentMultivariantPlaylistRequest = null;
        this.readyPlayerStartTime = 0;
        this.fetchLicenseStartTime = 0;
        this.playlistCache = {};
        this.initialPlaybackMode = initialPlaybackMode;
        this.isAdSkipped = false;
        this.resetPauseLogCapture();
        this.setLastKnownTimes();
        this.offHandlerRemovalList = [];
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    public override async setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        const { cdnFallback } = this;

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer?.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        // @todo not sure what to do about this yet, configs should come from PCS so we shouldn't be updating anything here

        if (cdnFallback.isEnabled) {
            this.playlistUri = playlist.mediaSources;
            //     this.nativePlayer?.config = {
            //         bufferingTimeoutStart: cdnFallback.defaultTimeout ? cdnFallback.defaultTimeout * 1000 : 90000,
            //         requestStartContentNetworkFallbackLimit: cdnFallback.fallbackLimit || 5
            //     };
        }
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        super.clean();

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.qos = {};
        this.mediaBytesDownloaded = 0;
        this.isBuffering = false;
        this.isReady = false;
        this.heartbeatData = {} as PlaybackHeartbeatEventData;
        this.mediaDownloadTotalCount = 0;
        this.mediaDownloadTotalTime = 0;
        this.bitrateAvg = 0;
        this.currentMultivariantPlaylistRequest = null;
        this.presentationType = PresentationType.main;
        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });
        this.playbackMetricsEventData = null;
        this.networkMetricsEventData = null;

        this.removeListener(listener);
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.clean();

        this.nativePlayer = null;
        this.accessToken = null;

        this.playbackStartupEventData = null;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    public setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    public setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} [options]
     * @param {Object} [options.eventData]
     * @param {Object} [options.eventData.errorName] - include if playback ended with an error
     * @param {Object} [options.eventData.errorDetail] - include if playback ended with an error
     * @param {Boolean} [options.isEnded]
     * @param {String<PlaybackExitedCause>} [options.cause] - Since `20.1.0` - signal the type of `PlaybackExitedCause`
     * @note eventData is internal use only when called via playbackErrorEvent, should be undefined otherwise.
     *
     */
    public async playbackEndedEvent(options: {
        eventData?: {
            errorName?: string;
            errorDetail?: string;
        };
        isEnded?: boolean;
        cause?: PlaybackExitedCause;
    }) {
        const { eventData, isEnded } = options || {};
        const { playbackStartupEventData } = this;
        const { errorName: playbackError, errorDetail: playbackErrorDetail } =
            eventData || {};

        let { cause } = options || {};

        if (this.fatalErrorPromise) {
            return;
        }

        const playbackData = this.getPlaybackData();

        if (!PlaybackExitedCause[cause as PlaybackExitedCause]) {
            if (Check.assigned(eventData)) {
                cause = PlaybackExitedCause.error;
            } else if (isEnded) {
                cause = PlaybackExitedCause.playedToEnd;
            } else {
                cause = PlaybackExitedCause.user;
            }
        }

        const playbackEndedEvent = {
            ...playbackData,
            cause,
            playbackError,
            playbackErrorDetail
        } as ReturnType<typeof this.getPlaybackData> & {
            cdnRequestedTrail?: Array<string>;
            cdnFailedTrail?: Array<string>;
            cdnFallbackCount?: number;
            isCdnFallback?: boolean;
        };

        playbackEndedEvent.cdnRequestedTrail =
            playbackStartupEventData?.cdnRequestedTrail;
        playbackEndedEvent.cdnFailedTrail =
            playbackStartupEventData?.cdnFailedTrail;
        playbackEndedEvent.cdnFallbackCount =
            playbackStartupEventData?.cdnFallbackCount;
        playbackEndedEvent.isCdnFallback =
            playbackStartupEventData?.isCdnFallback;

        // case: Playback has ended and the rebufferingEndedEvent did not fire for an unknown reason.
        if (this.isBuffering) {
            this.rebufferingEndedEvent();
        }

        if (
            [PresentationType.ad, PresentationType.bumper].includes(
                this.currentMultivariantPlaylistRequest
                    ?.assetType as PresentationType
            )
        ) {
            const adMediaId = this.getAdMediaId();

            this.adPlaybackEnded({
                ...playbackEndedEvent,
                asset: {
                    mediaId: adMediaId
                }
            });
        }

        await this.onPlaybackEnded(playbackEndedEvent);
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the time when the player has started to be initialized.
     *
     */
    public override setInitializePlayerStartTime() {
        this.initializePlayerStartTime = Date.now();
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the duration for how long the player took to be initialized. `setInitializePlayerStartTime` must be
     * called before this method.
     *
     */
    public override setInitializePlayerDuration() {
        if (this.initializePlayerStartTime) {
            this.initializePlayerDuration =
                Date.now() - this.initializePlayerStartTime;
        } else {
            this.logger.warn(
                this.toString(),
                'setInitializePlayerStartTime is required to be called before duration can be set.'
            );
        }
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls ERROR event
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, Events, logger } = this;

        if (nativePlayer && nativePlayer.on) {
            this.listener = listener;

            this.eventListenerProvider = new EventListenerProvider({
                onHandler: nativePlayer.on,
                onceHandler: nativePlayer.once,
                offHandler: nativePlayer.off,
                logger
            });

            this.eventListenerProvider.addEventHandler(
                this,
                Events.MEDIAELEMENT_STATE_CHANGED,
                this.mediaElementStateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.PRESENTATION_STATE_LOADED,
                this.playbackInitializedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SEEKING_STARTED,
                this.playbackSeekStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SEEKING_FINISHED,
                this.playbackSeekEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.NETWORK_METRICS_CHANGED,
                this.bitrateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.BUFFERING_STARTED,
                this.rebufferingStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.BUFFERING_FINISHED,
                this.rebufferingEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SUBTITLE_RENDITION_CHANGED,
                this.subtitleChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.AUDIO_RENDITION_CHANGED,
                this.audioChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.MULTIVARIANT_PLAYLIST_PARSED,
                this.multivariantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.VIDEO_RENDITION_UPDATED,
                this.variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.AUDIO_RENDITION_UPDATED,
                this.variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SUBTITLE_RENDITION_UPDATED,
                this.variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.MULTIVARIANT_PLAYLIST_LOADED,
                this.successfulPlaylistLoad
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.MULTIVARIANT_PLAYLIST_REQUEST,
                this.multivariantPlaylistRequest
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.DRM_LICENSE_REQUESTED,
                this.drmKeyRequestedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.DRM_LICENSE_RECEIVED,
                this.drmKeyFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SEEKABLE_RANGE_CHANGED,
                this.seekableRangeChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.ERROR,
                this.playbackErrorLocker
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SEGMENT_PLAYING,
                this.segmentPlayingEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.SOURCE_BUFFER_APPEND_STARTED,
                this.sourceBufferAppendStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.CONTENT_DOWNLOAD_FINISHED,
                this.contentDownloadFinishedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.PLAYBACK_METRICS_CHANGED,
                this.playbackMetricsChanged
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SESSION_REQUESTED,
                this.adPodRequested
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SESSION_FETCHED,
                this.adPodFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_MULTIVARIANT_FETCHED,
                this.adMultivariantFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_VARIANT_FETCHED,
                this.adVariantFetched
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_ASSET_STARTED,
                this.adPlaybackStarted
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_ASSET_FINISHED,
                this.adPlaybackEnded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SESSION_STARTED,
                this.adPodStarted
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SESSION_FINISHED,
                this.adPodEnded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.BEACON_ERROR,
                this.adBeaconError
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SESSION_REQUESTED_ERROR,
                this.adRequestedError
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.VIDEO_SEGMENT_LOADED,
                this.videoSegmentLoaded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.MEDIA_PLAYLIST_REQUEST,
                this.mediaPlaylistRequested
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.MEDIA_PLAYLIST_LOADED,
                this.mediaPlaylistLoaded
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.INTERSTITIAL_SKIPPED,
                this.adPlaybackSkipped
            );
            this.eventListenerProvider.addEventHandler(
                this,
                Events.TIMEUPDATE,
                this.timeUpdated
            );

            // *********************************
            // below this are .once(...) handlers
            this.eventListenerProvider.addEventHandlerOnce(
                this,
                Events.CANPLAY,
                this.playbackReadyEvent
            );
            this.eventListenerProvider.addEventHandlerOnce(
                this,
                Events.MEDIAELEMENT_STATE_PLAYING,
                this.playbackStartedEvent
            );

            Object.keys(Events).forEach((event) => {
                this.eventListenerProvider.addEventHandler(this, event, () =>
                    this.allEvents(event)
                );
            });
        } else {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    public async setDrmProviders(
        drmProviders: Array<MelHivePlayerAdapter['drmProviders'][0]>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await (
            nativePlayer as MelHiveNativePlayer
        ).getDrmCapabilities();

        const { keySystems = {} } = drmCapabilities;

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            const type = drmProviders[drmProvidersIndex]?.type;
            const provider =
                type === DrmType.PRMNAGRA
                    ? keySystems.NAGRA
                    : keySystems[type ?? -1];

            if (!provider?.supported ?? true) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        this.drmConfigurations = this.setDrmConfiguration();

        // @todo could we provide a callback function to the applications so they know then the drmConfigurations are available?
        // Then it seems like the could do playerAdapter.drmConfigurations and pluck out the value to pass to MelHive?
        // nativePlayer?.drmConfiguration = this.setDrmConfiguration();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @note DssHls expects the keySystems[x].keySystem value to be `NAGRA` instead of `PRMNAGRA`
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    private setDrmConfiguration() {
        const { drmProviders } = this;

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                getCertificate: (
                    drmProvider: MelHivePlayerAdapter['drmProviders'][0]
                ) => drmProvider.getFairPlayCertificate?.()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                getCertificate: (
                    drmProvider: MelHivePlayerAdapter['drmProviders'][0]
                ) => drmProvider.getWidevineCertificate?.()
            },
            [DrmType.PRMNAGRA]: {
                keySystem: 'NAGRA',
                getCertificate: (
                    drmProvider: MelHivePlayerAdapter['drmProviders'][0]
                ) => drmProvider.getNagraCertificate?.()
            }
        };

        const keySystems = drmProviders.map((drmProvider) => {
            const keySystemObj = {
                individualizationUri: drmProvider.individualizationUri,
                keySystem: drmProvider.type as string,
                licenseRequestUri: drmProvider.licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                serverCertificate: Promise.resolve(undefined)
            };

            const providerImplementation =
                providerImplementations[
                    drmProvider.type as keyof typeof providerImplementations
                ];

            if (providerImplementation) {
                const { keySystem, getCertificate } = providerImplementation;

                keySystemObj.keySystem = keySystem;
                keySystemObj.serverCertificate = (async () => {
                    return getCertificate(drmProvider);
                })();
            }

            return keySystemObj;
        });

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 24.0.0
     * @desc Gets current buffer segment duration.
     * @returns {Number}
     *
     */
    private getBufferSegmentDuration() {
        let bufferSegmentDuration = 0;

        if (this.playbackMetricsEventData) {
            const { bufferedRange } = this.playbackMetricsEventData;

            if (bufferedRange.end - this.lastKnownCurrentTime > 0) {
                bufferSegmentDuration =
                    bufferedRange.end - this.lastKnownCurrentTime;
            }
        }

        return bufferSegmentDuration;
    }

    /**
     *
     * @access private
     * @since 24.0.0
     * @desc Returns the current live latency amount.
     * @returns {Number}
     *
     */
    private getLiveLatencyAmount() {
        const { positionPdt, isBehindLive } = this
            .nativePlayer as MelHiveNativePlayer;

        let liveLatencyAmount = 0;

        if (this.playbackMetricsEventData) {
            const { seekableRange } = this.playbackMetricsEventData;

            if (isBehindLive && seekableRange.pdtEnd - positionPdt > 0) {
                liveLatencyAmount = seekableRange.pdtEnd - positionPdt;
            }
        }

        return liveLatencyAmount;
    }

    /**
     *
     * @access private
     * @since 24.0.0
     * @desc Returns the current segment position value.
     * @returns {Number}
     *
     */
    private getSegmentPosition() {
        const { positionPdt } = this.nativePlayer as MelHiveNativePlayer;

        return positionPdt;
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        const {
            positionPdt,
            isLive,
            isBehindLive,
            variants,
            currentVariantIndex
        } = this.nativePlayer as MelHiveNativePlayer;

        const isLiveEdge = isLive ? !isBehindLive : false;
        const currentInterstitial = this.getCurrentInterstitial();

        if (Check.nonEmptyObject(currentInterstitial)) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;

            if (currentInterstitial.currentAsset) {
                const { index: slotNumber, mediaId: adMediaId } =
                    currentInterstitial.currentAsset;

                const adDataData = this.getAdDataData(adMediaId, slotNumber);

                if (adDataData) {
                    const { adSlotData = defaultAdMetadata.adSlotData } =
                        adDataData;

                    this.adData.adMetadata.adSlotData = adSlotData;
                }
            }
        }

        const { averageBitrate = 0, peakBitrate = 0 } =
            variants?.[currentVariantIndex] || {};
        const chosenBitrateKbps = averageBitrate / 1000; // divide by 1000 to convert to Kbps

        const currentThroughput = this.networkMetricsEventData?.throughput || 0;

        const playbackState = this.getPlaybackState();
        const maxAllowedVideoBitrate = this.getMaxAllowedVideoBitrate();

        let currentPlayhead = 0;
        let seekableRangeEndProgramDateTime = 0;

        if (this.playbackMetricsEventData) {
            const { seekableRange } = this.playbackMetricsEventData;

            currentPlayhead = this.lastKnownMainContentTime;
            seekableRangeEndProgramDateTime = seekableRange.pdtEnd;
        }

        const liveLatencyAmount = this.getLiveLatencyAmount();
        const bufferSegmentDuration = this.getBufferSegmentDuration();

        return new PlaybackMetrics({
            currentBitrate: chosenBitrateKbps,
            currentPlayhead,
            currentBitrateAvg: averageBitrate,
            currentBitratePeak: peakBitrate,
            currentThroughput,
            playheadProgramDateTime: positionPdt,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            bufferSegmentDuration,
            mediaBytesDownloaded: this.mediaBytesDownloaded,
            playbackState,
            segmentPosition: positionPdt,
            liveLatencyAmount,
            maxAllowedVideoBitrate,
            adMetadata: this.adData.adMetadata
        });
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {String}
     *
     */
    public override getCdnName() {
        return (this.qos?.cdnName as string) ?? 'null';
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {Number|undefined}
     *
     */
    public override getAudioBitrate() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer || {};
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex ?? -1];

        return audioRendition?.averageBitrate;
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @returns {Number|undefined}
     *
     */
    public override getMaxAllowedVideoBitrate() {
        const variants = this.nativePlayer?.variants;
        const maxVariant = variants?.[variants.length - 1];

        return maxVariant?.peakBitrate;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the MEDIAELEMENT_STATE_PLAYING event. This uses the nativePlayer.once to make sure that this QoE
     * event is only posted once. The MEDIAELEMENT_STATE_PLAYING event will fire every time media is started, even after pausing, so
     * we only want this QoE event to post after the first time MEDIAELEMENT_STATE_PLAYING fires and then disregard it thereafter
     * for this playback session.
     *
     */
    private playbackStartedEvent() {
        const playbackData = this.getPlaybackData();

        this.onPlaybackStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_PARSED event
     *
     */
    private multivariantPlaylistFetchedEvent(eventData: EventData) {
        if (Check.object(eventData)) {
            const { isLive, isSlidingWindow } = this
                .nativePlayer as MelHiveNativePlayer;
            const { mediaStartBitrate, multivariantFetchedStartTime } = this;

            const { id, playlist } = eventData;
            const url = playlist ? playlist.url : eventData.url;
            const priority = playlist ? playlist.priority : 1;

            if (
                Check.assigned(id) &&
                id !== this.melStatic.AssetIds.MAIN_CONTENT
            ) {
                return;
            }

            const playbackStartupData = this.getPlaybackStartupData();

            const { host, path } = parseUrl(url);

            let playlistLiveType;

            if (isLive && isSlidingWindow) {
                playlistLiveType = PlaylistType.SLIDE;
            } else {
                playlistLiveType = PlaylistType.COMPLETE;
            }

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed,
                method: HttpMethod.get
            });

            this.emit(InternalEvents.UpdateAdEngine, priority);

            this.onMultivariantPlaylistFetched({
                ...playbackStartupData,
                mediaStartBitrate,
                playlistLiveType,
                serverRequest,
                multivariantFetchedStartTime,
                multivariantFetchedDuration: multivariantFetchedStartTime
                    ? Date.now() - multivariantFetchedStartTime
                    : undefined
            });
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} [eventData={}]
     * @note Handles the VIDEO_RENDITION_UPDATED, AUDIO_RENDITION_UPDATED, and SUBTITLE_RENDITION_UPDATED events
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer?.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    private variantPlaylistFetchedEvent(eventData = {} as EventData) {
        const {
            rendition = {} as Rendition,
            serverRequest: request = {} as PlayerServerRequest
        } = eventData;

        const { url } = rendition;
        const { host, path } = parseUrl(url);

        const currentVariant = this.getCurrentVariant();

        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        const { averageBitrate, bitrate, resolution } = currentVariant;

        const serverRequest = new ServerRequest({
            ...request,
            host,
            path,
            method: HttpMethod[
                (
                    request.method || 'get'
                ).toLowerCase() as Lowercase<HttpCoreMethod>
            ],
            status: getFetchStatus(request.fetchStatus),
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const details = { ...rendition };

        const playlistCashByUrl = this.playlistCache[url];

        if (!playlistCashByUrl) {
            return;
        }

        playlistCashByUrl.event = {
            ...eventData,
            playlistAverageBandwidth: averageBitrate,
            playlistBandwidth: bitrate,
            playlistResolution: resolution,
            serverRequest,
            playheadPosition,
            details
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the CANPLAY event
     *
     */
    private playbackReadyEvent() {
        const { mediaStartBitrate, readyPlayerStartTime } = this;
        const playbackStartupData = this.getPlaybackStartupData();

        this.onPlaybackReady({
            ...playbackStartupData,
            mediaStartBitrate,
            readyPlayerStartTime,
            readyPlayerDuration: readyPlayerStartTime
                ? Date.now() - readyPlayerStartTime
                : 0
        });

        this.isReady = true;
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @desc Handles all events
     *
     */
    private async allEvents(eventName: string) {
        // used to detect if a pause/resume is triggered by a seek
        if (this.capturePauseLogs) {
            this.pauseLogs.push(eventName);
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @desc Handles the MEDIAELEMENT_STATE_CHANGED event
     *
     */
    private async mediaElementStateChangedEvent(eventData: EventData) {
        const { MediaElementStates } = this;
        const { mediaElementState, lastMediaElementState } = eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (
            lastMediaElementState === MediaElementStates.PAUSED &&
            mediaElementState === MediaElementStates.PLAYING
        ) {
            await delay(1000);

            const isTrueResume = !this.pauseLogs.includes(
                HlsStreamEvents.SEEKING_STARTED
            );

            if (isTrueResume) {
                cause = PlaybackResumedCause.user;

                this.onPlaybackResumed({
                    ...playbackData,
                    cause
                });

                this.resetPauseLogCapture();
            }
        }

        if (
            lastMediaElementState === MediaElementStates.PLAYING &&
            mediaElementState === MediaElementStates.PAUSED
        ) {
            this.capturePauseLogs = true;

            // Adding in a minor delay so we can detect if the change
            // was triggered due to a seek.
            // @see https://www.w3.org/TR/2011/WD-html5-20110113/video.html#seeking
            await delay(1000);

            const isTruePause = !this.pauseLogs.length;

            if (isTruePause) {
                if (this.isApplicationBackgrounded) {
                    cause = PlaybackPausedCause.applicationBackgrounded;
                } else if (this.isBuffering) {
                    cause = PlaybackPausedCause.stall;
                } else {
                    cause = PlaybackPausedCause.user;
                }

                this.onPlaybackPaused({
                    ...playbackData,
                    cause
                });
            }
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Triggered when playback is initialized.
     * @note Handles the PRESENTATION_STATE_LOADED event
     *
     */
    private playbackInitializedEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        const {
            variant,
            audio = {} as AudioRenditionType,
            subtitle = {} as SubtitleRenditionType,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        } = playbackStartupData;

        const {
            heartbeatData,
            initializePlayerDuration,
            initializePlayerStartTime
        } = this;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            ...heartbeatData,
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        };

        this.onPlaybackInitialized({
            ...playbackStartupData,
            initializePlayerDuration,
            initializePlayerStartTime
        });
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @notes Handles DRM_LICENSE_REQUESTED event
     *
     */
    private drmKeyRequestedEvent() {
        this.fetchLicenseStartTime = Date.now();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @notes Handles DRM_LICENSE_RECEIVED event
     *
     */
    private drmKeyFetchedEvent(eventData: EventData) {
        const playbackStartupData = this.getPlaybackStartupData();
        const { fetchLicenseStartTime } = this;

        const { url = '', keyID: drmKeyId } = eventData || {};
        const { host, path } = parseUrl(url);

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onDrmKeyFetched({
            ...playbackStartupData,
            drmKeyId,
            serverRequest,
            fetchLicenseStartTime,
            fetchLicenseDuration: fetchLicenseStartTime
                ? Date.now() - fetchLicenseStartTime
                : 0
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_LOADED event to update tracking when successful playlist loads.
     *
     */
    private successfulPlaylistLoad(eventData: EventData) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            if (playlist.tracking) {
                this.onSuccessfulPlaylistLoad(playlist);
            }
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_REQUEST event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistRequest(eventData: {
        playlist?: HlsPlaylist;
        assetType: PresentationType;
    }) {
        const { playbackStartupEventData } = this;
        const { assetType, playlist } = eventData || {};

        const isTrackablePresentationRequestType = [
            PresentationType.bumper,
            PresentationType.ad,
            PresentationType.main
        ].includes(assetType);

        if (isTrackablePresentationRequestType) {
            this.currentMultivariantPlaylistRequest = eventData;
        }

        if (playlist?.tracking) {
            // This check is necessary, due to some asset types (Bumpers) won't have tracking data.

            const qosData = playlist.tracking.qos;
            const cdnVendor = qosData ? qosData.cdnVendor : undefined;

            this.qos = qosData;

            playbackStartupEventData?.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData?.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }

        this.multivariantFetchedStartTime = Date.now();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the MULTIVARIANT_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistFallback(eventData: EventData) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist?.tracking && playbackStartupEventData) {
            playbackStartupEventData.playbackError = QoePlaybackError.unknown;
            playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 24.0.0
     * @desc Sets the AdPlayheadPosition in AdMetadata.
     *
     */
    private setAdPlayheadPosition() {
        const { currentInterstitial } = this
            .nativePlayer as MelHiveNativePlayer;

        if (currentInterstitial) {
            this.adData.adMetadata.adPlayheadPosition =
                currentInterstitial.position
                    ? Math.floor(currentInterstitial.position)
                    : 0;
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_STARTED event
     *
     */
    private playbackSeekStartedEvent(eventData: EventData) {
        const { isLive } = this.nativePlayer as MelHiveNativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause = PlaybackSeekCause.app, seekSize } =
            this.seekData || {};

        const liveLatencyAmount = this.getLiveLatencyAmount();
        const segmentPosition = this.getSegmentPosition();
        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        const distance = Math.floor(targetPosition - currentTime);
        const seekDistance = Math.abs(distance) * 1000; // coverts seekDistance to milliseconds.

        let playerSeekDirection;
        let seekDirection;

        if (distance < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (distance === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        if (this.seekData) {
            this.seekData.seekDistance = seekDistance;
            this.seekData.distance = distance;
        }

        this.onPlaybackSeekStarted({
            ...eventData,
            playerSeekDirection,
            seekSize,
            seekDistance,
            playheadPosition,
            cause,
            seekDirection,
            segmentPosition,
            liveLatencyAmount
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_FINISHED event
     *
     */
    private async playbackSeekEndedEvent() {
        const { isLive } = this.nativePlayer as MelHiveNativePlayer;
        const {
            seekCause: cause = PlaybackSeekCause.app,
            seekDistance = 0,
            distance = 0
        } = this.seekData || {};

        const liveLatencyAmount = this.getLiveLatencyAmount();
        const segmentPosition = this.getSegmentPosition();
        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        let playerSeekDirection;
        let seekDirection;

        if (distance < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (distance === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekEnded({
            playerSeekDirection,
            seekDistance,
            playheadPosition,
            cause,
            seekDirection,
            segmentPosition,
            liveLatencyAmount
        });
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @param {Object} eventData
     * @note Handles the ERROR event and stores the fatal event for processing before listeners are removed.
     *
     */
    private async playbackErrorLocker(eventData: EventData) {
        const { fatal = false } = eventData || {};

        if (fatal) {
            this.fatalErrorPromise = this.playbackErrorEvent(eventData); // if set make ended call return
        } else {
            await this.playbackErrorEvent(eventData);
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the ERROR event
     *
     */
    private async playbackErrorEvent(eventData: EventData) {
        const { fatal = false, message: errorDetail } = eventData || {};
        const { currentPDT: segmentPosition } = this
            .nativePlayer as MelHiveNativePlayer;

        const liveLatencyAmount = this.getLiveLatencyAmount();
        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        // adSlotData is saved in both this.adData.data.adSlotData and this.adData.adMetadata.adSlotData
        // this.adData.data.adSlotData is saved in this.getAdDataByMediaId()
        // this.adData.adMetadata.adSlotData is saved in this.getPlaybackMetrics()

        const { data: adDataData = {}, adMetadata } = this.adData;
        const { adPodData, adPodPlacement } = adMetadata;

        const adMediaId = this.getAdMediaId() as string;
        const { adSlotData } = adDataData[adMediaId] || defaultAdMetadata;

        const errorName = mapHiveToQoeErrorCodes(eventData);

        if (fatal) {
            // @todo probably don't need this anymore since Bam-hls is gone?
            if (eventData.error === 'DRM_FAILED') {
                const errorCase =
                    this.constructErrorCaseFromDssHlsError(eventData);

                this.emit(PublicEvents.MediaFailure, errorCase);
            }

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adSlotData,
                    adPodData,
                    adPodPlacement
                });

                await this.playbackEndedEvent({
                    eventData: { errorName, errorDetail },
                    cause: PlaybackExitedCause.error
                });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount,
                    adSlotData,
                    adPodData,
                    adPodPlacement
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SEEKABLE_RANGE_CHANGED event
     *
     */
    private seekableRangeChangedEvent(eventData: EventData) {
        const { pdtEnd } = eventData || {};

        this.seekableRangeEndProgramDateTime = pdtEnd;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {NetworkMetricsEventData} eventData
     * @note Handles the NETWORK_METRICS_CHANGED event
     *
     */
    private bitrateChangedEvent(eventData: NetworkMetricsEventData) {
        this.networkMetricsEventData = eventData;

        if (this.isReady) {
            const liveLatencyAmount = this.getLiveLatencyAmount();
            const segmentPosition = this.getSegmentPosition();
            const playheadPosition = this.lastKnownMainContentTime;

            this.setAdPlayheadPosition();
            this.setAdSlotData();

            // Per Helios docs for videoAverageBitrate and maxAttainedBitrate: If value is 0 or not available return 0
            const { averageBitrate = 0, peakBitrate = 0 } =
                eventData.currentVariant || ({} as Variant);

            if (this.bitrateAvg > 0 && this.bitrateAvg !== averageBitrate) {
                // don't trigger event on initial load when this.bitrateAvg=0
                this.onBitrateChanged({
                    ...eventData,
                    bitrateAvg: averageBitrate,
                    bitratePeak: peakBitrate,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount
                });
            }

            this.bitrateAvg = averageBitrate;
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the SUBTITLE_RENDITION_CHANGED event
     *
     */
    private subtitleChangedEvent(eventData: {
        currentSubtitleRendition: number;
    }) {
        const { currentSubtitleRendition } = eventData || {};

        const { subtitleRenditions = [] } = this
            .nativePlayer as MelHiveNativePlayer;
        const subtitleRendition =
            subtitleRenditions[currentSubtitleRendition] ||
            ({} as SubtitleRendition);

        const {
            language: subtitleLanguage,
            name: subtitleName,
            forced: subtitleVisibility
        } = subtitleRendition;

        const { playlistTimedTextTrackType } = getTrackTypes({
            subtitle: subtitleRendition
        });

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage =
            subtitleLanguage ?? this.heartbeatData.playlistSubtitleLanguage;
        this.heartbeatData.playlistSubtitleName =
            subtitleName ?? this.heartbeatData.playlistSubtitleName;
        this.heartbeatData.subtitleVisibility =
            subtitleVisibility ?? this.heartbeatData.subtitleVisibility;
        this.heartbeatData.playlistTimedTextTrackType =
            playlistTimedTextTrackType ??
            this.heartbeatData.playlistTimedTextTrackType;

        this.onSubtitleChanged();
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} eventData
     * @note Handles the AUDIO_RENDITION_CHANGED event
     *
     */
    private audioChangedEvent(eventData: { currentAudioRendition: number }) {
        // TODO: Typecheck validation warn?

        if (this.isReady) {
            const { currentAudioRendition } = eventData || {};

            const { audioRenditions = [] } = this
                .nativePlayer as MelHiveNativePlayer;
            const audioRendition =
                audioRenditions[currentAudioRendition] ||
                ({} as AudioRenditionType);

            const {
                channels,
                codec: audioCodec,
                language: audioLanguage,
                name: audioName
            } = audioRendition || {};

            const audioChannels =
                (channels as number) >= 0
                    ? parseInt(channels as string, 10)
                    : undefined;

            const { playlistAudioTrackType } = getTrackTypes({
                audio: audioRendition
            });

            // Cache values to be used for heartbeat event.
            this.heartbeatData.playlistAudioChannels =
                audioChannels ?? this.heartbeatData.playlistAudioChannels;
            this.heartbeatData.playlistAudioCodec =
                audioCodec ?? this.heartbeatData.playlistAudioCodec;
            this.heartbeatData.playlistAudioLanguage =
                audioLanguage ?? this.heartbeatData.playlistAudioLanguage;
            this.heartbeatData.playlistAudioName =
                audioName ?? this.heartbeatData.playlistAudioName;
            this.heartbeatData.playlistAudioTrackType =
                playlistAudioTrackType ??
                this.heartbeatData.playlistAudioTrackType;

            this.onAudioChanged();
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the BUFFERING_STARTED event
     *
     */
    private rebufferingStartedEvent() {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = new Date();

        this.isBuffering = true;

        const playbackData = this.getPlaybackData();

        this.bufferType = playbackData.bufferType;

        this.onRebufferingStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @note Handles the `BUFFERING_FINISHED` event
     *
     */
    private rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackData = this.getPlaybackData();
        const duration =
            Date.now() - (this.rebufferingDuration?.getTime() ?? 0);

        this.onRebufferingEnded({
            ...playbackData,
            duration,
            bufferType: playbackData.bufferType
                ? playbackData.bufferType
                : this.bufferType
        });

        this.bufferType = undefined;
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `` event
     *
     */
    private snapshotCreated() {
        this.onSnapshotCreated({});
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `BEACON_ERROR` event
     *
     */
    private adBeaconError(eventData = {} as EventData) {
        const { serverRequest } = eventData;

        const { host, path } = serverRequest || {};

        this.onAdBeaconError({
            error: QoePlaybackError.adBeaconError,
            errorLevel: ErrorLevel.info,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED_ERROR` event
     *
     */
    private adRequestedError(eventData: EventData) {
        const { serverRequest } = eventData || {};

        const { host, path } = serverRequest || {};

        this.onAdRequestedError({
            error: QoePlaybackError.adServerError,
            errorLevel: ErrorLevel.error,
            applicationContext: ApplicationContext.ad,
            errorDetail: `https://${host}${path}`
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_REQUESTED` event
     *
     */
    private adPodRequested(eventData: EventData) {
        const { interstitialMetadata } = eventData;
        const { placement, midRollIndex: midrollIndex } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition) {
            return;
        }

        const adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.onAdPodRequested({
            adPodPlacement
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_FETCHED` event
     *
     */
    private adPodFetched(eventData: {
        serverRequest: PlayerServerRequest;
        startTimestamp: number;
        interstitialMetadata: InterstitialMetadata;
        response: AdResponse;
    }) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const { placement = '' } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[
                (
                    request.method || HttpMethod.post
                ).toLowerCase() as Lowercase<HttpCoreMethod>
            ],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        this.processAdPodData(eventData);

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdPodFetched({
            adPodData,
            adPodPlacement,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_MULTIVARIANT_FETCHED` event
     *
     */
    private adMultivariantFetched(eventData: EventData) {
        const {
            serverRequest: request,
            startTimestamp,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[
                request.method.toLowerCase() as Lowercase<HttpCoreMethod>
            ],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const { adSlotData } =
            this.getAdDataByMediaId({
                adMediaId: adMediaId as string,
                plannedLength,
                slotNumber
            }) || {};

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdMultivariantFetched({
            adPodData,
            adPodPlacement,
            adSlotData,
            serverRequest,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} options
     * @param {String} options.adMediaId
     * @param {Number} [options.plannedLength]
     * @param {Number} [options.slotNumber]
     * @returns {Object}
     *
     */
    private getAdDataByMediaId(options: {
        adMediaId: string;
        plannedLength?: number;
        slotNumber?: number;
    }) {
        const { adMediaId, plannedLength, slotNumber } = options;

        let adDataData = this.getAdDataData(adMediaId, slotNumber as number);

        adDataData ??= {
            adSubtitleData: {
                subtitleVisibility: false
            },
            adSlotData: {
                adMediaId,
                plannedLength: Check.assigned(plannedLength)
                    ? Math.floor(plannedLength as number)
                    : undefined,
                slotNumber: slotNumber || 0
            }
        };

        const metadataKey = this.getMetadataKey(
            adMediaId,
            slotNumber as number
        );

        this.adData.data[metadataKey] = adDataData;

        return adDataData;
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_VARIANT_FETCHED` event
     *
     */
    private adVariantFetched(eventData: EventData) {
        const subtitleVisibility =
            this.nativePlayer?.areSubtitlesEnabled || false;

        const {
            serverRequest: request,
            startTimestamp,
            assetData,
            interstitialMetadata
        } = eventData;

        const {
            placement = '',
            slotNumber,
            adDuration: plannedLength,
            adId: adMediaId
        } = interstitialMetadata;

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        const serverRequest = new ServerRequest({
            ...request,
            method: HttpMethod[
                request.method.toLowerCase() as Lowercase<HttpCoreMethod>
            ],
            networkType: NetworkType.unknown, // currently needs to be mapped and will be updated in a player update
            status: getFetchStatus(request.fetchStatus),
            serverIP: request.serverIp,
            error: undefined // mapping needs verified
        });

        const {
            mediaSegmentType,
            codec,
            range,
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            channels,
            language,
            name
        } = assetData;

        const adDataData =
            this.getAdDataByMediaId({
                adMediaId: adMediaId as string,
                plannedLength,
                slotNumber
            }) || {};

        const { adSlotData } = adDataData;
        let { adVideoData, adAudioData, adSubtitleData } = adDataData;

        const segmentType =
            MediaSegmentTypeMap[
                mediaSegmentType as keyof typeof MediaSegmentTypeMap
            ];

        switch (segmentType) {
            case MediaSegmentType.video:
                adVideoData = {
                    playlistVideoCodec: codec,
                    playlistVideoRange: range,
                    videoBitrate: bitrate,
                    videoAverageBitrate: averageBitrate,
                    playlistResolution: resolution,
                    playlistFrameRate: frameRate
                };

                adDataData.adVideoData = adVideoData;
                break;

            case MediaSegmentType.audio:
                adAudioData = {
                    playlistAudioChannels: parseInt(channels, 10),
                    playlistAudioCodec: codec,
                    playlistAudioLanguage: language,
                    playlistAudioName: name
                };

                adDataData.adAudioData = adAudioData;
                break;

            case MediaSegmentType.subtitle:
                adSubtitleData = {
                    subtitleVisibility,
                    playlistSubtitleLanguage: language,
                    playlistSubtitleName: name
                };
                adDataData.adSubtitleData = adSubtitleData;
                break;

            default:
                this.logger.error(
                    this.toString(),
                    `Unknown mediaSegmentType: ${mediaSegmentType}`
                );
                break;
        }

        const { adPodData, adPodPlacement } = this.adData.adMetadata;

        this.onAdVariantFetched({
            adPodPlacement,
            adPodData,
            adSlotData,
            serverRequest,
            mediaSegmentType: segmentType,
            adVideoData,
            adAudioData,
            adSubtitleData,
            startTimestamp
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_ASSET_STARTED` event
     * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#assetsession
     *
     */
    private adPlaybackStarted(eventData: EventData) {
        const { asset } = eventData;
        const {
            mediaId: adMediaId,
            duration: plannedLength,
            index: slotNumber,
            type,
            subtype
        } = asset as TodoAny;
        const { placement = '' } = this.getCurrentInterstitial();

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        const AssetTypes = this.AssetTypes;
        const presentationLookup = {
            [AssetTypes.SLUG]: PresentationType.slug,
            [AssetTypes.AD]: PresentationType.ad,
            [AssetTypes.BUMPER]: PresentationType.bumper,
            [AssetTypes.CONTENT_PROMO]: PresentationType.promo
        };

        const presentation =
            presentationLookup[type as keyof typeof presentationLookup] ||
            presentationLookup[subtype as keyof typeof presentationLookup] ||
            PresentationType.ad;

        if (this.presentationType !== presentation) {
            this.changePresentationType(presentation);
        }

        if (!podPosition || !adMediaId || !slotNumber) {
            return;
        }

        const { adVideoData, adAudioData, adSubtitleData } =
            this.getAdDataData(adMediaId, slotNumber) || {};

        this.adData.adMetadata.mediaId = adMediaId;
        this.adData.adMetadata.adSlotData = {
            adMediaId,
            plannedLength: Check.assigned(plannedLength)
                ? Math.floor(plannedLength)
                : undefined,
            slotNumber: slotNumber || 0
        };

        this.onAdPlaybackStarted({
            adPodPlacement: this.adData.adMetadata.adPodPlacement,
            adPodData: this.adData.adMetadata.adPodData,
            adSlotData: this.adData.adMetadata.adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_ASSET_FINISHED` event
     * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#assetsession
     *
     */
    private adPlaybackEnded(options: {
        playbackError?: QoePlaybackError;
        playbackErrorDetail?: string;
        cause?: PlaybackExitedCause;
        asset: Partial<Asset>;
    }) {
        const {
            asset,
            playbackError,
            playbackErrorDetail = '',
            cause: endCause
        } = options || {};

        const { currentAsset, placement = '' } = this.getCurrentInterstitial();

        const { mediaId: adMediaId, index: slotNumber } =
            currentAsset || asset || {};

        const { adPodPlacement, adPodData, adSlotData } =
            this.adData.adMetadata;

        const adErrorData = {
            errorName: playbackError!,
            errorMessage: playbackErrorDetail
        };

        let cause = endCause;
        let adVideoData;
        let adAudioData;
        let adSubtitleData;

        if (this.isAdSkipped) {
            // isAdSkipped flag is set to false in `adPodEnded`
            this.onPlaybackSeekEnded({
                cause: PlaybackSeekCause.skip,
                playerSeekDirection: PlayerSeekDirection.forward,
                seekDistance: 0,
                playheadPosition: this.lastKnownMainContentTime,
                segmentPosition: this.getSegmentPosition(),
                liveLatencyAmount: this.getLiveLatencyAmount()
            });
        }

        const podPosition = PodPosition[placement.toLowerCase() as PodPosition];

        if (!podPosition || !adMediaId) {
            return;
        }

        cause ??= PlaybackExitedCause.playedToEnd;

        const adDataData = this.getAdDataData(adMediaId, slotNumber);

        if (adDataData) {
            const data = Object.assign({}, adDataData);

            adVideoData = data.adVideoData;
            adAudioData = data.adAudioData;
            adSubtitleData = data.adSubtitleData;
        }

        this.onAdPlaybackEnded({
            adPodPlacement,
            adPodData,
            adSlotData,
            adVideoData,
            adAudioData,
            adSubtitleData,
            cause,
            adErrorData
        });

        const metadataKey = this.getMetadataKey(adMediaId, slotNumber);

        delete this.adData.data[metadataKey];
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @note Handles the `INTERSTITIAL_SKIPPED` event
     * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#asset
     *
     */
    private adPlaybackSkipped() {
        const { adMetadata } = this.adData;

        // Set flag for when `adPlaybackEnded` is triggered. flag is reset in `adPodEnded`
        this.isAdSkipped = true;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        this.onPlaybackSeekStarted({
            cause: PlaybackSeekCause.skip,
            playerSeekDirection: PlayerSeekDirection.forward,
            seekDistance: 0,
            playheadPosition: this.lastKnownMainContentTime,
            segmentPosition: this.getSegmentPosition(),
            liveLatencyAmount: this.getLiveLatencyAmount(),
            adMetadata
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_STARTED` event
     *
     */
    private adPodStarted() {
        const {
            placement = '',
            duration,
            position
        } = this.getCurrentInterstitial();

        let interstitialPlacement = InterstitialPlacement.preroll;

        this.presentationType = PresentationType.ad;

        if (placement === InsertionPointPlacement.MIDROLL) {
            interstitialPlacement = InterstitialPlacement.midroll;
        }

        if (placement === InsertionPointPlacement.BUMPER_PREROLL) {
            this.presentationType = PresentationType.bumper;
        }

        this.changePresentationType(this.presentationType);

        // Convert to milliseconds since nativePlayer.currentInterstitial.duration is in seconds
        this.interstitialSessionDuration = Math.floor(duration * 1000);

        this.onInterstitialPlaybackStarted({
            interstitialType: InterstitialType.promo,
            interstitialPlacement,
            interstitialId: this.getAdInterstitialId(),
            interstitialPlannedLength: this.interstitialSessionDuration,
            interstitialPlayhead: position
        });
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @note Handles the `INTERSTITIAL_SESSION_FINISHED` event
     *
     */
    private adPodEnded(eventData: {
        id?: number;
        duration?: number;
        position?: number;
        cause?: InterstitialEndedCause;
        message?: string;
        code?: number;
    }) {
        this.changePresentationType();

        const { duration, position = 0, cause, message } = eventData || {};

        const interstitialDuration =
            duration || this.interstitialSessionDuration;

        let interstitialCause = cause
            ? InterstitialEndedCause.error
            : undefined;
        let errorData;

        if (message) {
            errorData = {
                errorName: mapHiveToQoeErrorCodes(eventData),
                errorMessage: message
            };
        }

        if (!interstitialCause) {
            interstitialCause = this.isAdSkipped
                ? InterstitialEndedCause.skip
                : InterstitialEndedCause.playedToEnd;
            this.isAdSkipped = false;
        }

        this.onInterstitialPlaybackEnded({
            interstitialType: InterstitialType.promo,
            interstitialPlacement: InterstitialPlacement.postroll,
            interstitialId: this.getAdInterstitialId(),
            interstitialPlannedLength: interstitialDuration,
            interstitialPlayhead: position,
            cause: interstitialCause,
            errorData
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `SEGMENT_PLAYING` event
     *
     */
    private segmentPlayingEvent(eventData = {} as EventData) {
        const programDateTimeStart = eventData.segment?.programDateTimeStart;

        this.segmentPosition = Check.number(programDateTimeStart)
            ? // TS isn't aware of the `Check.number` call
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Math.floor(programDateTimeStart!)
            : null;
        this.mediaSegmentType = MediaSegmentType[eventData.type];
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `SOURCE_BUFFER_APPEND_STARTED` event.
     * @note This value gets cleared when `PlaybackTelemetryDispatcher.recordStreamSample()` is called.
     *
     */
    private sourceBufferAppendStartedEvent(eventData?: EventData) {
        if (Check.number(eventData?.segment?.byteSize)) {
            // TS isn't aware of the `Check.number` call
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.mediaBytesDownloaded += eventData!.segment!.byteSize;
        }
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Handles the `CONTENT_DOWNLOAD_STARTED` event.
     * @note These values get reset to zero when `PlaybackTelemetryDispatcher.createPlaybackHeartbeatEventData(provider)` is called.
     *
     */
    private contentDownloadFinishedEvent(eventData: EventData) {
        const { startTime, endTime } = eventData;

        this.mediaDownloadTotalCount++;
        this.mediaDownloadTotalTime += endTime - startTime;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {PlaybackMetricsEventData} eventData
     * @desc Handles the `PLAYBACK_METRICS_CHANGED` event.
     *
     */
    private playbackMetricsChanged(eventData: PlaybackMetricsEventData) {
        this.playbackMetricsEventData = eventData;

        this.setLastKnownTimes(eventData);
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @note Handles the `VIDEO_SEGMENT_LOADED` event
     *
     */
    private videoSegmentLoaded() {
        if (!this.readyPlayerStartTime) {
            this.readyPlayerStartTime = Date.now();
        }
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @param {Object} eventData
     * @note Handles the `MEDIA_PLAYLIST_REQUEST` event
     *
     */
    private mediaPlaylistRequested(eventData: PlaylistEvent) {
        const { url, assetId } = eventData;

        if (assetId === HlsStreamAssetIds.MAIN_CONTENT) {
            this.playlistCache[url] = {
                variantFetchedStartTime: Date.now()
            };
        }
    }

    /**
     *
     * @access private
     * @since 23.0.0
     * @param {Object} eventData
     * @note Handles the `MEDIA_PLAYLIST_LOADED` event
     *
     */
    private mediaPlaylistLoaded(eventData: PlaylistEvent) {
        const { url } = eventData;
        const { variantFetchedStartTime = 0, event } =
            this.playlistCache[url] || {};

        if (event) {
            this.onVariantPlaylistFetched({
                ...event,
                variantFetchedStartTime,
                variantFetchedDuration: Date.now() - variantFetchedStartTime
            });

            delete this.playlistCache[url];
        }
    }

    /**
     *
     * @access private
     * @since 24.4.0
     * @desc Returns the current `adMediaId` or fall back to a zero guid value if not found.
     *
     */
    private getAdInterstitialId() {
        const id = this.getAdMediaId();

        return id || DEFAULT_ZERO_GUID;
    }

    /**
     *
     * @access private
     * @since 27.1.2
     * @param {PlaybackMetricsEventData} eventData
     * @desc Handles the `TIMEUPDATE` event.
     *
     */
    private timeUpdated(event: TimeUpdateEventData) {
        this.setLastKnownTimes(event, 1000);
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    private setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        (nativePlayer as MelHiveNativePlayer).xhrSetupCallback = (
            xhr: XMLHttpRequest,
            url: string,
            type: keyof typeof DataTypes
        ) => {
            // @todo DataTypes doesn't seem to exist how this is expecting
            /**
             *  DataTypes: Object.freeze({
                    MULTIVARIANT_PLAYLIST: 'MULTIVARIANT_PLAYLIST',
                    MEDIA_PLAYLIST       : 'MEDIA_PLAYLIST',
                    MEDIA_SEGMENT        : 'MEDIA_SEGMENT',
                    INIT_SECTION         : 'INIT_SECTION',
                    DRM_LICENSE          : 'DRM_LICENSE',
                    AD_POD               : 'AD_POD',
                }),
             */
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader(AUTHORIZATION, accessToken as string);
                } else if (isAdEngineCall && ssess) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    private constructErrorCaseFromDssHlsError(exception: {
        cause: keyof typeof BamHlsErrorMapping;
    }) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;

        const { code, exceptionData } = errorCode || {};

        return createSimpleException({ code, exceptionData });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {Object}
     *
     */
    private getCurrentVariant() {
        const { variants = [], currentVariantIndex } = this
            .nativePlayer as MelHiveNativePlayer;
        const currentVariant = variants[currentVariantIndex] || ({} as Variant);

        const maxVariant = variants[variants.length - 1] || ({} as Variant);

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubType: videoRange
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {Object}
     *
     */
    private getAudioRendition() {
        const { audioRenditions = [], currentAudioRenditionIndex } =
            this.nativePlayer || {};
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex ?? -1] ||
            ({} as AudioRenditionType);

        const { averageBitrate, channels, codec, name, language } =
            audioRendition || {};

        return {
            audioRendition: { name, language },
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    private getSubtitleRendition() {
        const { subtitleRenditions = [], currentSubtitleRenditionIndex } = this
            .nativePlayer as MelHiveNativePlayer;

        return subtitleRenditions[currentSubtitleRenditionIndex] || {};
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @returns {String<SDK.Services.QualityOfService.BufferType>}
     *
     */
    private getBufferType() {
        let bufferType;

        const { mediaElementState } = this.nativePlayer as MelHiveNativePlayer;

        const {
            // DETACHING,
            // DETACHED,
            ATTACHING,
            // ATTACHED,
            // ENDED,
            // PLAYING,
            PAUSED,
            BUFFERING,
            SEEKING
        } = this.MediaElementStates || {};

        if (mediaElementState) {
            switch (mediaElementState) {
                case ATTACHING:
                    bufferType = BufferType.initializing;
                    break;
                case BUFFERING:
                    bufferType = BufferType.buffering;
                    break;
                case SEEKING:
                    bufferType = BufferType.seeking;
                    break;
                case PAUSED:
                    bufferType = BufferType.resuming;
                    break;
                // no default
            }
        }

        return bufferType;
    }

    /**
     *
     * @access protected
     * @since 18.0.0
     * @desc returns playback state enum.
     * @note nativePlayer?.mediaElementState will always be populated and is used to determine the playback state.
     * @returns {PlaybackState|undefined}
     *
     */
    public override getPlaybackState() {
        let state;

        const { MediaElementStates } = this;

        switch (this.nativePlayer?.mediaElementState) {
            case MediaElementStates.BUFFERING:
            case MediaElementStates.DETACHED: // player starts in a detached state
            case MediaElementStates.DETACHING:
            case MediaElementStates.ATTACHING:
            case MediaElementStates.ATTACHED:
                state = PlaybackState.buffering;
                break;

            case MediaElementStates.ENDED:
                state = PlaybackState.finished;
                break;

            case MediaElementStates.PAUSED:
                state = PlaybackState.paused;
                break;

            case MediaElementStates.PLAYING:
                state = PlaybackState.playing;
                break;

            case MediaElementStates.SEEKING:
                state = PlaybackState.seeking;
                break;

            // no default
        }

        return state;
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc return data relevant to create `PlaybackEventData`
     * @returns {Object}
     *
     */
    private getPlaybackData() {
        const { currentStreamUrl: streamUrl, nativePlayer } = this;
        const { positionPdt: segmentPosition } =
            nativePlayer as MelHiveNativePlayer;

        const liveLatencyAmount = this.getLiveLatencyAmount();
        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        const { averageBitrate: audioBitrate } = this.getAudioRendition();

        const {
            bitrate: videoBitrate,
            averageBitrate: videoAverageBitrate,
            maxAllowedVideoBitrate
        } = this.getCurrentVariant();

        const bufferType = this.getBufferType();

        return {
            playheadPosition,
            streamUrl,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            liveLatencyAmount,
            bufferType
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc return data relevant to create `PlaybackStartupEventData`
     * @returns {Object}
     *
     */
    private getPlaybackStartupData() {
        const { currentStreamUrl: streamUrl } = this;

        const bufferSegmentDuration = this.getBufferSegmentDuration();

        const playheadPosition = this.lastKnownMainContentTime;

        this.setAdPlayheadPosition();
        this.setAdSlotData();

        const { audioRendition, audioChannels } = this.getAudioRendition();
        const subtitle = this.getSubtitleRendition();

        const {
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            audioCodec,
            videoCodec,
            videoRange
        } = this.getCurrentVariant();

        let audioChannelsNumber;

        if (Check.string(audioChannels)) {
            const audioChannelsMatches = /\d+/.exec(audioChannels);

            if (audioChannelsMatches) {
                audioChannelsNumber = Number(audioChannelsMatches[0]);
            }
        } else if (Check.number(audioChannels)) {
            audioChannelsNumber = audioChannels;
        }

        // @todo: Look into commented values.
        const variant = new PlaybackVariant({
            bandwidth: bitrate,
            resolution,
            // videoBytes,
            // maxAudioRenditionBytes,
            // maxSubtitleRenditionBytes,
            audioChannels: audioChannelsNumber,
            videoRange,
            videoCodec,
            // audioType,
            audioCodec,
            averageBandwidth: averageBitrate,
            frameRate
        });

        const { playlistAudioTrackType, playlistTimedTextTrackType } =
            getTrackTypes({ audio: audioRendition, subtitle });

        return {
            variant,
            audio: audioRendition,
            subtitle,
            streamUrl,
            playheadPosition,
            bufferSegmentDuration,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        };
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @desc Returns heartbeatData object on the ctor for heartbeat event.
     * @returns {Object}
     *
     */
    public override getHeartbeatData() {
        return this.heartbeatData;
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {SDK.Services.QualityOfService.PresentationType} [presentation]
     * @desc Updates PresentationType
     *
     */
    private changePresentationType(presentation?: PresentationType) {
        const areSubtitlesEnabled = this.nativePlayer?.areSubtitlesEnabled;

        const {
            audioRendition: audio,
            averageBitrate: audioBitrate,
            audioChannels
        } = this.getAudioRendition();

        const { bitrate: videoBitrate, averageBitrate: videoAverageBitrate } =
            this.getCurrentVariant();

        const subtitle = this.getSubtitleRendition();

        const { playlistAudioTrackType, playlistTimedTextTrackType } =
            getTrackTypes({ audio, subtitle });

        if (!presentation) {
            this.presentationType = PresentationType.main;
        } else {
            this.presentationType = presentation;
        }

        this.onPresentationTypeChanged({
            presentationType: this.presentationType,
            areSubtitlesEnabled,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            audioChannels: parseInt(audioChannels as string, 10),
            audio,
            subtitle,
            adMetadata: this.adData.adMetadata,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        });
    }

    /**
     *
     * @access private
     * @since 22.0.0
     * @param {Object} podData
     * @desc Process AdPodData
     * @returns {Object} The processed podData from the ad pod response stored in the adData private property.
     * @example
     * {
     *     data: {
     *         'someMediaId': {
     *             adSubtitleData: object;
     *             adSlotData: object;
     *             mediaUrl: string;
     *         };
     *     };
     *     adMetadata: {
     *         adPodData: object;
     *         adPodPlacement: object;
     *         adSlotData: object;
     *     }
     * }
     *
     */
    private processAdPodData(podData: {
        interstitialMetadata: InterstitialMetadata;
        response: AdResponse;
    }) {
        const {
            interstitialMetadata = {} as InterstitialMetadata,
            response: adPodResponse = {} as AdResponse
        } = podData;

        const { pods = [] } = adPodResponse;
        const {
            placement = '',
            midRollIndex: midrollIndex,
            totalDuration: adPodPlannedLength,
            slotCount: plannedSlotCount
        } = interstitialMetadata;

        const podPosition =
            PodPosition[(placement as string).toLowerCase() as PodPosition];

        this.adData.adMetadata = defaultAdMetadata;

        this.adData.adMetadata.adPodData = {
            plannedSlotCount,
            plannedLength: Check.number(adPodPlannedLength)
                ? Math.floor(adPodPlannedLength)
                : undefined
        };

        this.adData.adMetadata.adPodPlacement = {
            midrollIndex,
            podPosition
        };

        this.adData.data = pods.reduce((data, pod) => {
            const { ads = [] } = pod;

            return ads.reduce(
                (slotData: Record<string, unknown>, adSlot: AdSlotData) => {
                    const {
                        creative: {
                            video: adCreative = {} as Record<
                                string,
                                string | number
                            >
                        } = {},
                        'slot-number': slotNumber
                    } = adSlot;
                    const {
                        'media-id': mediaId,
                        'media-url': mediaUrl,
                        'duration-ms': plannedLength
                    } = adCreative;

                    return {
                        ...slotData,
                        [mediaId]: {
                            adSubtitleData: {
                                subtitleVisibility: false
                            },
                            adSlotData: {
                                adMediaId: mediaId,
                                plannedLength: Check.number(plannedLength)
                                    ? Math.floor(plannedLength)
                                    : undefined,
                                slotNumber: slotNumber || 0
                            },
                            mediaUrl
                        }
                    };
                },
                data
            );
        }, {});
    }

    /**
     *
     * @access private
     * @since 22.0.0
     * @desc Get the ad mediaId from the ad data based on the currentMultivariantPlaylistRequest value
     * @returns {String|undefined}
     *
     */
    private getAdMediaId() {
        const { data: adDataData = {} } = this.adData;

        const adMediaId = Object.keys(adDataData).find((key) =>
            this.currentMultivariantPlaylistRequest?.url?.includes(
                adDataData[key]?.mediaUrl
            )
        );

        return adMediaId;
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @desc Resets pause logs capture
     *
     */
    private resetPauseLogCapture() {
        this.capturePauseLogs = false;
        this.pauseLogs = [];
    }

    /**
     *
     * @access private
     * @since 27.1.2
     * @param {Object} options
     * @param {Number} options.mainContentTime
     * @param {Number} options.currentTime
     * @param {Number} conversionRate - rate to convert `mainContentTime` and `currentTime` to seconds.
     * @desc Sets `this.lastKnownMainContentTime` and `this.lastKnownCurrentTime` as seconds.
     *
     */
    private setLastKnownTimes(
        options?: { mainContentTime: number; currentTime: number },
        conversionRate = 1
    ) {
        const { mainContentTime = 0, currentTime = 0 } = options || {};

        this.lastKnownMainContentTime = mainContentTime * conversionRate;
        this.lastKnownCurrentTime = currentTime * conversionRate;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.MelHivePlayerAdapter';
    }
}
