/**
 *
 * @module envelopeMessageRouter
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import MessageEnvelope from '../socket/messageEnvelope';
import SocketManager from '../socket/socketManager';
import WebSocketStates from '../socket/webSocketStates';
import EnvelopeMessageQueue from './envelopeMessageQueue';
import EventBufferMessageEnvelope from './telemetry/eventBufferMessageEnvelope';

/**
 *
 * @access protected
 *
 */
export default class EnvelopeMessageRouter {
    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SDK.Internal.Telemetry.EventBufferMessageEnvelope}
     *
     */
    private dustEnvelopeBuffer: EventBufferMessageEnvelope;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Boolean}
     * @desc Flag to determine if sockets are enabled for sending dust events.
     *
     */
    private isEventsAtEdgeEnabled: boolean;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SocketManager|undefined}
     *
     */
    private socketManager?: SocketManager;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {EnvelopeMessageQueue}
     *
     */
    private envelopeMessageQueue: EnvelopeMessageQueue;

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Internal.Telemetry.EventBufferMessageEnvelope} options.dustEnvelopeBuffer
     * @param {Boolean} options.isEventsAtEdgeEnabled
     * @param {SDK.Socket.SocketManager} [options.socketManager]
     * @param {SDK.Internal.EnvelopeMessageQueue} options.envelopeMessageQueue
     *
     */
    public constructor(options: {
        logger: Logger;
        dustEnvelopeBuffer: EventBufferMessageEnvelope;
        isEventsAtEdgeEnabled: boolean;
        socketManager?: SocketManager;
        envelopeMessageQueue: EnvelopeMessageQueue;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    dustEnvelopeBuffer: Types.instanceStrict(
                        EventBufferMessageEnvelope
                    ),
                    isEventsAtEdgeEnabled: Types.boolean,
                    socketManager: Types.instanceStrict(SocketManager).optional,
                    envelopeMessageQueue:
                        Types.instanceStrict(EnvelopeMessageQueue)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            logger,
            dustEnvelopeBuffer,
            isEventsAtEdgeEnabled,
            socketManager,
            envelopeMessageQueue
        } = options;

        this.logger = logger;
        this.dustEnvelopeBuffer = dustEnvelopeBuffer;
        this.isEventsAtEdgeEnabled = isEventsAtEdgeEnabled;
        this.socketManager = socketManager;
        this.envelopeMessageQueue = envelopeMessageQueue;
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {SDK.Socket.MessageEnvelope} envelope
     * @desc Evaluates remote configuration values and status of socket connection to
     * dispatch a Dustv2 log for transmission either over a socket connection, or over REST.
     * @todo When `socketManager` is required use `socketManager.source`.
     * @see https://jira.disneystreaming.com/browse/SDKMRJS-5257
     *
     */
    public async route(envelope: MessageEnvelope) {
        const { socketManager, dustEnvelopeBuffer, isEventsAtEdgeEnabled } =
            this;

        const shouldSendToSocket =
            isEventsAtEdgeEnabled &&
            socketManager &&
            socketManager.isSocketInReadyState(WebSocketStates.OPEN);

        if (shouldSendToSocket) {
            this.logger.info(this.toString(), 'Edge message sent to socket.');

            await socketManager.sendMessage(envelope);

            return;
        }

        this.envelopeMessageQueue.enqueue(envelope);

        this.logger.info(
            this.toString(),
            `Edge message sent to dust envelope buffer. [isEventsAtEdgeEnabled=${isEventsAtEdgeEnabled}]`
        );

        dustEnvelopeBuffer.scheduleNextBatch();
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Internal.EnvelopeMessageRouter';
    }
}
