/**
 *
 * @module certificateStorage
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import PlatformProviders from '../services/providers/platformProviders';
import type CoreStorageProvider from '../services/providers/shared/coreStorageProvider';

import { DrmType } from '../services/media/enums';

import {
    BAM_DRM_KEY,
    CERTIFICATE_KEY,
    DSS_DRM_KEY
} from '../services/providers/shared/storageConstants';

import { REPLACE_NL_WS } from '../constants';

type LowerCaseDrmType = Lowercase<DrmType>;

/**
 *
 * @access protected
 * @since 3.2.0
 * @desc Provides a mechanism for storing a certificate.
 *
 */
export default class CertificateStorage {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    private certificateType: LowerCaseDrmType;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @type {SDK.Services.ServicePlatformProviders.Storage}
     *
     */
    protected storage: CoreStorageProvider;

    /**
     *
     * @access protected
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @access private
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    private oldCacheKey: string;

    /**
     *
     * @access private
     * @since 10.1.0
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK
     *
     */
    protected cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Media.DrmType>} options.drmType
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        drmType: DrmType;
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    drmType: Types.in(DrmType),
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    storage: Types.instanceStrict(PlatformProviders.Storage),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { drmType, clientId, environment, storage, logger } = options;

        const certificateType = DrmType[
            drmType
        ].toLowerCase() as LowerCaseDrmType;

        this.certificateType = certificateType;
        this.clientId = clientId;
        this.environment = environment;
        this.storage = storage;
        this.logger = logger;
        this.oldCacheKey = `
            ${BAM_DRM_KEY}${this.certificateType}${CERTIFICATE_KEY}${this.clientId}_${this.environment}
        `.replace(REPLACE_NL_WS, '');
        this.cacheKey = `
            ${DSS_DRM_KEY}${this.certificateType}${CERTIFICATE_KEY}${this.clientId}_${this.environment}
        `.replace(REPLACE_NL_WS, '');

        /**
         *
         * @since 10.1.0
         * @note This removes the old certificate storage to conserve space.
         *
         */
        this.storage.remove(this.oldCacheKey);
    }

    /**
     *
     * @access public
     * @desc gets the stored certificate if found
     * @returns {Promise<Uint8Array|undefined>}
     *
     */
    public getStoredCertificate() {
        const { cacheKey, storage } = this;

        return storage.get(cacheKey).then((storedCertificate) => {
            let certificate;

            try {
                // needs to be explicitly converted to Uint8Array from an Object
                // after being parsed by storage.get
                certificate = new Uint8Array(
                    Object.keys(storedCertificate).map(
                        (byte) => storedCertificate[byte]
                    )
                );
            } catch (ex) {
                // bam-hls expects undefined in this case, note
                // that this might need to be revisited for other types
                certificate = undefined;
            }

            return Promise.resolve(certificate);
        });
    }

    /**
     *
     * @access public
     * @param {ArrayBuffer} certificate
     * @desc attempts to store a certificate via the Storage API
     * @returns {Promise<Void>}
     *
     */
    public storeCertificate(certificate: ArrayBuffer) {
        const { cacheKey, storage } = this;

        return storage.set(cacheKey, new Uint8Array(certificate));
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Drm.CertificateStorage';
    }
}
