/**
 *
 * @module entry
 * @desc {SDK.Services.Drm} entry point.
 *
 */

import PlayReadyMessage from './playReadyMessage';

/**
 *
 * @typedef {Object} SDK.Services.Drm
 *
 * @property {PlayReadyMessage} PlayReadyMessage
 *
 */
export default {
    PlayReadyMessage
};
