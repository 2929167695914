/**
 *
 * @module samsungTizenPlayerAdapter
 * @desc PlayerAdapter for Samsung Tizen TVs
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see http://developer.samsung.com/tv/develop/api-references/samsung-product-api-references/avplay-api
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import DrmProvider from '../../drm/drmProvider';
import PlayerAdapter from '../playerAdapter';
import PlaybackMetrics from '../playbackMetrics';
import PlaybackEventListener from '../playbackEventListener';
import Playlist from '../playlist';

import { createInvalidStateException } from '../../services/util/errorHandling/createException';

/**
 *
 * @desc Interface used to communicate with the media player.
 *
 */
export default class SamsungTizenPlayerAdapter extends PlayerAdapter<SamsungTizenNativePlayer> {
    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public streamingProperty: Nullable<string>;

    /**
     *
     * @access public
     * @type {Object|null}
     * @desc Exposed listeners for when application developer needs to call
     * nativePlayer?.setListener after a playbackSession.prepare
     * @since 2.2.1
     *
     */
    public playerListener: Nullable<Record<string, unknown>>;

    /**
     *
     * @access private
     * @type {object|null}
     * @desc Listeners that are set by application developer
     * @since 2.2.1
     *
     */
    public clientListener: Record<string, Noop> | null;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {Object} [options.clientListener]
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The SamsungTizenPlayerAdapter requires nativePlayer?.setStreamingProperty,
     * nativePlayer?.open, and nativePlayer?.setListener
     *
     */
    public constructor(options: {
        nativePlayer: SamsungTizenNativePlayer;
        clientListener?: Record<string, Noop>;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        setStreamingProperty: Types.function,
                        setListener: Types.function,
                        open: Types.function
                    }),
                    clientListener: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientListener = null } = options;

        this.streamingProperty = null;
        this.playerListener = null;
        this.clientListener = clientListener;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    public override setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        return new Promise<void>((resolve, reject) => {
            this.playlistUri = playlist.streamUri as string;

            try {
                if (Check.assigned(this.accessToken)) {
                    const streamingProperty = `|COOKIE={Authorization=${this.accessToken};}`;

                    this.playlistUri = playlist.streamUri as string;
                    this.streamingProperty = streamingProperty;

                    this.nativePlayer?.open(playlist.streamUri);
                    this.nativePlayer?.setStreamingProperty(
                        'ADAPTIVE_INFO',
                        streamingProperty
                    );

                    return resolve();
                }

                return reject(
                    createInvalidStateException(
                        `${this.toString()}.setSource(playlist) needs valid accessToken`
                    )
                );
            } catch (ex) {
                return reject(
                    createInvalidStateException(
                        `${this.toString()}.setSource(playlist) unable to set playlistUri on NativePlayer - ${ex}`
                    )
                );
            }
        });
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        super.clean();

        this.playlistUri = '';
        this.boundHandlers = {};
        this.drmProvider = null;

        this.removeListener(listener);
    }

    /**
     *
     * @access public
     * @since 11.0.0
     * @desc Trigger when playback has been exited.
     *
     */
    public async playbackEndedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        await this.onPlaybackEnded({
            error: null,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access public
     * @since 2.0.0
     * @desc Gets a snapshot of information about media playback.
     * @throws {InvalidStateException} Unable to get NativePlayer playhead or bitrate data
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     * @note nativePlayer?.getCurrentTime - Time returned in milliseconds.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        if (Check.not.function(this.nativePlayer?.getCurrentStreamInfo)) {
            throw createInvalidStateException(
                `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`
            );
        }

        if (Check.not.function(this.nativePlayer?.getCurrentTime)) {
            throw createInvalidStateException(
                `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer bitrate data`
            );
        }

        const currentStreamInfo = (
            this.nativePlayer as SamsungTizenNativePlayer
        ).getCurrentStreamInfo();
        const currentBitrate = this.getBitrate(currentStreamInfo);
        const currentPlayhead =
            (this.nativePlayer as SamsungTizenNativePlayer).getCurrentTime() /
            1000;

        return new PlaybackMetrics({ currentBitrate, currentPlayhead });
    }

    /**
     *
     * @access public
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     * @note Samsung Tizen has several events that can be fired by the player.
     * Callbacks for these events are defined and then set to the player.
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { boundHandlers, nativePlayer, clientListener } = this;
        const { playbackStartedEvent, playbackEndedEvent } = this;

        if (Check.function(nativePlayer?.setListener)) {
            this.listener = listener;

            boundHandlers.onPlay = playbackStartedEvent.bind(this);
            boundHandlers.onPlayedToCompletion = playbackEndedEvent.bind(this);

            const playerListener = {
                onbufferingstart: boundHandlers.onPlay,
                onstreamcompleted: boundHandlers.onPlayedToCompletion
            } as Record<string, Noop>;

            if (clientListener) {
                Object.keys(clientListener).forEach((key) => {
                    if (
                        Check.assigned(clientListener[key]) &&
                        Check.function(clientListener[key])
                    ) {
                        if (
                            Check.assigned(playerListener[key]) &&
                            Check.function(playerListener[key])
                        ) {
                            playerListener[key] = (value: unknown) => {
                                clientListener[key](value);
                                playerListener[key](value);
                            };
                        } else {
                            playerListener[key] = clientListener[key];
                        }
                    }
                });
            }

            this.playerListener = playerListener;

            nativePlayer?.setListener(this.playerListener);
        } else {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access public
     * @since 2.0.0
     * @param {SDK.Media.PlaybackEventListener} [listener]
     * @returns {Void}
     *
     */
    public override removeListener(listener?: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener).optional
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        if (!listener) {
            return;
        }

        const { nativePlayer } = this;

        if (
            Check.function(nativePlayer?.setListener) &&
            this.listener === listener
        ) {
            this.listener = undefined;
            this.playerListener = null;

            (nativePlayer as SamsungTizenNativePlayer).onbufferingstart = null;
            (nativePlayer as SamsungTizenNativePlayer).onstreamcompleted = null;
        }
    }

    /**
     *
     * @access public
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    public override setDrmProvider(drmProvider: DrmProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProvider: Types.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProvider', params, arguments);
        }

        this.drmProvider = drmProvider;

        return Promise.resolve();
    }

    /**
     *
     * @access private
     * @since 11.0.0
     * @desc Trigger when playback starts.
     *
     */
    private playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    private dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.playerListener = null;
    }

    /**
     *
     * @access private
     * @since 2.0.0
     * @param {Array} currentStreamInfo
     * @returns {Number|null}
     *
     */
    private getBitrate(currentStreamInfo: Array<Record<string, string>>) {
        let bitrate: Nullable<number> = null;

        for (const item of currentStreamInfo) {
            if (item.type === 'VIDEO') {
                const extraInfo = JSON.parse(item.extra_info);

                bitrate = parseInt(extraInfo.Bit_rate, 10);
            }
        }

        return bitrate;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.SamsungTizenPlayerAdapter';
    }
}
