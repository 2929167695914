/**
 *
 * @module subscriptionClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the SubscriptionClient.
 *
 */
export default class SubscriptionClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Subscription.SubscriptionClientConfiguration';
    }
}
