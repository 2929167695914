/**
 *
 * @module externalActivationResult
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ServiceException from '../exception/serviceException';

import TemporaryDefaultAccessActivationStatus from './temporaryDefaultAccessActivationStatus';

/**
 *
 * @access public
 * @note because resultStatus follow a different flow for type checking in this
 * scenario, it is skipped in params below but listed for clarity
 *
 */
export default class ExternalActivationResult {
    /**
     *
     * @access private
     * @type {Boolean}
     * @desc A Boolean that indicates whether or not the Access token should be refreshed.
     * @note This property will be set from the `X-BAMTech-Refresh-Access-Token` header in
     * the JSON response. It defaults to `false` if the header does not exist.
     *
     */
    public refreshAccessToken: boolean;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Boolean}
     * @desc An indicator of whether or not temporary access has been granted in the meantime.
     * @note This property will be set from the `X-BAMTech-Temporary-Access-Granted` header in
     * the JSON response. It defaults to `false` if the header does not exist.
     *
     */
    public temporaryAccessGranted: boolean;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String<SDK.Services.ExternalActivation.TemporaryDefaultAccessActivationStatus>|ServiceException|null}
     * @desc temporary default access status based on the service status and temporary access granted token
     * @note the result status logic is set up in the ExternalActivationClient activateToken method, based
     * on the status code and temporary access granted header
     * @note the JS SDK stores the associated key not the value
     *
     */
    public resultStatus?: Nullable<
        TemporaryDefaultAccessActivationStatus | ServiceException
    >;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Array}
     * @desc group of errors returned from services
     * @note exposed to app developers as a source of additional data if necessary
     *
     */
    public errors: Array<object>;

    /**
     *
     * @param {Object} options
     * @param {Boolean} options.refreshAccessToken
     * @param {Boolean} options.temporaryAccessGranted
     * @param {String<SDK.Services.ExternalActivation.TemporaryDefaultAccessActivationStatus>|ServiceException} [options.resultStatus=null]
     * @param {Array} [options.errors=[]]
     *
     */
    public constructor(options: {
        refreshAccessToken: boolean;
        temporaryAccessGranted: boolean;
        resultStatus?:
            | TemporaryDefaultAccessActivationStatus
            | ServiceException;
        errors?: Array<object>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const validator = (
                resultStatus: TemporaryDefaultAccessActivationStatus
            ) => {
                if (Check.string(resultStatus)) {
                    const resultStatusKey =
                        this.getResultStatusKey(resultStatus);

                    return Check.contains(
                        Object.keys(TemporaryDefaultAccessActivationStatus),
                        resultStatusKey
                    );
                }

                return Check.instanceStrict(resultStatus, ServiceException);
            };

            const params = {
                options: Types.object({
                    refreshAccessToken: Types.boolean,
                    temporaryAccessGranted: Types.boolean,
                    resultStatus: Types.custom(
                        validator,
                        'String or an instance of ServiceException'
                    ).optional,
                    errors: Types.array.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            refreshAccessToken,
            temporaryAccessGranted,
            resultStatus = null,
            errors = []
        } = options;

        this.refreshAccessToken = refreshAccessToken;
        this.temporaryAccessGranted = temporaryAccessGranted;
        this.resultStatus =
            typeof resultStatus === 'string'
                ? this.getResultStatusKey(resultStatus)
                : resultStatus;
        this.errors = errors;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {String} resultStatus
     * @returns {TemporaryDefaultAccessActivationStatus|undefined}
     *
     */
    private getResultStatusKey(resultStatus: string) {
        return Object.keys(TemporaryDefaultAccessActivationStatus).find(
            (key) =>
                TemporaryDefaultAccessActivationStatus[key] === resultStatus
        ) as TemporaryDefaultAccessActivationStatus;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.ExternalActivation.ExternalActivationResult';
    }
}
