/**
 *
 * @module accountApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/account.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/AccountApi.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/SDK-Authorization-Workflow.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Account from '../services/account/account';
import AccountClient from '../services/account/accountClient';
import CreateAccountResult from '../services/account/createAccountResult';

import BaseApi from '../baseApi';
import DustDecorators from '../services/internal/dust/dustDecorators';
import TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';

import AccessContextState from '../token/accessContextState';
import { ApiOptions } from '../typedefs';
import { ServerResponse } from '../services/providers/typedefs';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import SubscriptionClient from '../services/subscription/subscriptionClient';

const DustUrn = DustUrnReference.account.accountApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides ability to access account data and link subscriptions to the account.
 *
 */
export default class AccountApi extends BaseApi {
    /**
     *
     * @access private
     * @type {AccountClient}
     *
     */
    private accountClient: AccountClient;

    /**
     *
     * @access private
     * @type {TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {SubscriptionClient}
     * @since 29.0.0
     *
     */
    private subscriptionClient: SubscriptionClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {AccountClient} options.accountClient
     * @param {TokenManager} options.tokenManager
     * @param {SubscriptionClient} options.subscriptionClient
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        accountClient: AccountClient;
        tokenManager: TokenManager;
        subscriptionClient: SubscriptionClient;
        logger: Logger;
    }) {
        super({ accessTokenProvider: options.tokenManager, ...options });

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountClient: Types.instanceStrict(AccountClient),
                    tokenManager: Types.instanceStrict(TokenManager),
                    subscriptionClient: Types.instanceStrict(SubscriptionClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { accountClient, tokenManager, subscriptionClient } = options;

        this.accountClient = accountClient;
        this.tokenManager = tokenManager;
        this.subscriptionClient = subscriptionClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @deprecated Deprecated as of `27.1.0` and will be removed in a future version. See release notes for replacement.
     * @access public
     * @since 3.5.0
     * @param {String} token
     * @param {String} provider
     * @desc Authorizes a session with an identity that has been authenticated by an external provider.
     * @throws {AccountBlockedException} Account is blocked because it has been either identified as a minor or banned globally by OneID.
     * @throws {InvalidTokenException} External identity token was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>}
     *
     */
    public async authorize(token: string, provider: string): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            token: Types.nonEmptyString,
            provider: Types.nonEmptyString
        }
    })
    public async authorize(apiOptions: unknown) {
        const {
            logTransaction,
            args: [token, provider]
        } = apiOptions as ApiOptions<[string, string]>;

        const accountGrant = await this.accountClient.createAccountGrant(
            token,
            super.accessToken,
            logTransaction
        );
        const accessContextState = new AccessContextState([provider]);

        await this.tokenManager.exchangeAccountGrant(
            accountGrant,
            accessContextState,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.5.0
     * @param {String} token
     * @param {Object} [attributes={}] - A set of attributes to associate with the new account. Note: certain
     * attributes may be required while others are optional.
     * @param {Object} [metadata] - Object which may hold additional information about the account.
     * @desc Creates an account from an identity that has been authenticated by an external provider.
     * @throws {InvalidTokenException} External identity token was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Account.CreateAccountResult>}
     *
     */
    public async createAccount(
        token: string,
        attributes?: object,
        metadata?: object
    ): Promise<CreateAccountResult>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            token: Types.nonEmptyString,
            attributes: Types.object().optional,
            metadata: Types.object().optional
        }
    })
    public async createAccount(apiOptions: unknown) {
        const {
            logTransaction,
            args: [token, attributes = {}, metadata]
        } = apiOptions as ApiOptions<[string, object?, object?]>;

        return this.accountClient.createAccount({
            identityToken: token,
            attributes,
            metadata,
            logTransaction,
            accessToken: super.accessToken
        });
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @since 3.1.0
     * @desc Gets an Account instance that can be used to get information about the account.
     * @throws {AccountNotFoundException} The account was not found.
     * @throws {ResourceTimedOutException} Call to dynamodb in the resource-service timed out.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Account.Account>}
     *
     * @example sdkSession.accountApi.getAccount().then(account) => {
     *     console.log(account.accountId);
     *     console.log(account.attributes);
     * });
     *
     */
    public async getAccount(): Promise<Account>;

    @apiMethodDecorator()
    public async getAccount(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return this.accountClient.getCurrentAccount(
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 3.2.0
     * @param {SDK.Services.Account.Account} account - account to be saved.
     * @desc Saves all current changes made to the account attributes.
     * @throws {AttributeValidationException} One or more attribute was invalid.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the operation has succeeded.
     *
     * @example const accountApi = sdkSession.accountApi;
     * @example accountApi.getAccount().then(account) => {
     *     account.attributes.optInUpdates = true; // update attribute
     *     account.attributes.birthDate = null; // delete attribute
     *
     *     return accountApi.updateAccount(account);
     * });
     *
     */
    public async updateAccount(
        account: Account
    ): Promise<ServerResponse<ApprovedAny>>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            account: Types.instanceStrict(Account)
        }
    })
    public async updateAccount(apiOptions: unknown) {
        const {
            logTransaction,
            args: [account]
        } = apiOptions as ApiOptions<[Account]>;

        return this.accountClient.updateAttributes(
            account.attributes as object,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Links (copies) all subscriptions from the user's device to the Account.
     * @throws {LinkSubscriptionPartialException} Copy partial error.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the operation has succeeded.
     *
     */
    public async linkSubscriptionsFromDevice(): Promise<
        ServerResponse<ApprovedAny>
    >;

    @apiMethodDecorator()
    public async linkSubscriptionsFromDevice(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return this.subscriptionClient.linkSubscriptionsFromDevice(
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Account.AccountApi';
    }
}
