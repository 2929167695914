/**
 *
 * @module paymentPeriod
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/services/class.sdk.services.paywall.plantuml
 *
 */

/**
 *
 * @enum
 * @since 4.9.0
 * @typedef {Object} SDK.Services.Paywall.PaymentPeriod
 * @desc Contains subscription payment period information.
 * @note This is a flexible enum.
 *
 * @property {String} year - Since `4.9.0`.
 * @property {String} month - Since `4.9.0`.
 * @property {String} day - Since `4.9.0`.
 * @property {String} season - Since `4.9.0`.
 * @property {String} none - Since `4.9.0`.
 *
 */
enum PaymentPeriod {
    year = 'YEAR',
    month = 'MONTH',
    day = 'DAY',
    season = 'SEASON',
    none = 'NONE'
}
export default PaymentPeriod;
