/**
 *
 * @module baseApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './logging/logger';
import AccessToken from './token/accessToken';

import AccessTokenProvider from './token/accessTokenProvider';

/**
 *
 * @access protected
 * @since 13.0.0
 *
 */
export default class BaseApi {
    /**
     *
     * @access protected
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @access protected
     * @since 29.0.0
     * @type {SDK.Token.AccessTokenProvider}
     *
     */
    public accessTokenProvider: AccessTokenProvider;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Token.AccessTokenProvider} options.accessTokenProvider
     *
     */
    public constructor(options: {
        logger: Logger;
        accessTokenProvider: AccessTokenProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    accessTokenProvider:
                        Types.instanceStrict(AccessTokenProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger, accessTokenProvider } = options;

        this.logger = logger;
        this.accessTokenProvider = accessTokenProvider;
    }

    /**
     *
     * @access public
     * @since 29.0.0
     * @desc Grabs `AccessToken` from the `AccessTokenProvider` instance.
     * @returns {SDK.Token.AccessToken}
     *
     */
    public get accessToken() {
        return this.accessTokenProvider.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.BaseApi';
    }
}
