/**
 *
 * @module entry
 * @desc {SDK.Advertising} entry point.
 *
 */

import AdvertisingIdProvider from './advertisingIdProvider';
import LimitAdTrackingEnabled from './limitAdTrackingEnabled';
import PrivacyOptOut from './privacyOptOut';

/**
 *
 * @typedef {Object} SDK.Advertising
 *
 * @property {AdvertisingIdProvider} AdvertisingIdProvider
 * @property {LimitAdTrackingEnabled} LimitAdTrackingEnabled
 *
 */
export default {
    AdvertisingIdProvider,
    LimitAdTrackingEnabled,
    PrivacyOptOut
};
