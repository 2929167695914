/**
 *
 * @module exceptionReference
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/sdkErrorCases.json
 * @see https://github.bamtech.co/userservices/orchestration-api/blob/master/OrchestrationApi.yaml#L99
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/Error-Handling.md#error-cases
 * @see https://docs.google.com/spreadsheets/d/1kHtlh7D08dArPp97iYTMHA2aIoBtK2bqBIbIUK1PJiM
 *
 */

import {
    AccountExceptions,
    CommerceExceptions,
    CommonExceptions,
    DrmExceptions,
    EligibilityExceptions,
    ExploreExceptions,
    InternalExceptions,
    MediaExceptions,
    OrchestrationExceptions,
    PurchaseExceptions,
    UserProfileExceptions,
    SocketExceptions,
    MethodExceptions
} from './exceptionDefinitions';

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Exception.ExceptionReference
 * @since 3.7.0
 * @desc Internal Exception reference, also known as "exceptionData".
 *
 * @property {Object<SDK.Services.Exception.AccountExceptions>} account
 * @property {Object<SDK.Services.Exception.CommerceExceptions>} commerce
 * @property {Object<SDK.Services.Exception.CommonExceptions>} common
 * @property {Object<SDK.Services.Exception.DrmExceptions>} drm
 * @property {Object<SDK.Services.Exception.EligibilityExceptions>} eligibility
 * @property {Object<SDK.Services.Exception.ExploreExceptions>} explore
 * @property {Object<SDK.Services.Exception.InternalExceptions>} internal
 * @property {Object<SDK.Services.Exception.MediaExceptions>} media
 * @property {Object<SDK.Services.Exception.OrchestrationExceptions>} orchestration
 * @property {Object<SDK.Services.Exception.PurchaseExceptions>} purchase
 * @property {Object<SDK.Services.Exception.UserProfileExceptions>} userProfile
 * @property {Object<SDK.Services.Exception.SocketExceptions>} socket
 * @property {Object<SDK.Services.Exception.MethodExceptions>} method
 *
 */
export default {
    account: AccountExceptions,
    commerce: CommerceExceptions,
    common: CommonExceptions,
    drm: DrmExceptions,
    eligibility: EligibilityExceptions,
    explore: ExploreExceptions,
    internal: InternalExceptions,
    media: MediaExceptions,
    orchestration: OrchestrationExceptions,
    purchase: PurchaseExceptions,
    userProfile: UserProfileExceptions,
    socket: SocketExceptions,
    method: MethodExceptions
};
