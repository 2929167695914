/**
 *
 * @module dustLogUtility
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../../logging/logger';
import LogTransaction from '../../../logging/logTransaction';
import LogLevel from '../../../logging/logLevel';

import parseUrl from '../../util/parseUrl';

import DustUrnReference from './dustUrnReference';
import DustCategory from './dustCategory';
import type ServiceException from '../../exception/serviceException';
import {
    ServiceInteraction,
    ServiceRequest
} from '../../../internal/dust/typedefs';

import ErrorReason from '../../exception/errorReason';
import { HttpCoreMethod, ServerResponse } from '../../providers/typedefs';
import {
    DustLogUtilityPayload,
    DustLogUtilityPayloadTypedef
} from './typedefs';

import { ServerData } from '../typedefs';
import {
    AMZ_CF_ID,
    AMZ_CF_POP,
    REQUEST_ID,
    SDK_REGION
} from '../../providers/shared/httpHeaderConstants';

type ExtraData = {
    category?: string;
    timestamp?: string;
    server?: ServerData;
    data?: object;
    error?: unknown;
    dataVersion?: string;
};
export type InterimLogEventExtraData = ExtraData & {
    urn: string;
    file: string;
    line: number;
    serviceRequests: Array<ServiceInteraction>;
    startTime: Date;
    totalDuration: number;
    sdkInstanceId: string;
    isEdge?: boolean;
};

export type InterimLogEvent = {
    extraData: ExtraData;
    isPublic: boolean;
    name: string;
    level: number;
};

/**
 *
 * @access private
 * @since 13.0.0
 * @param {Object} value
 * @desc Validates that the options contain either a `skipLogTransaction` or `logTransaction` property.
 * @returns {Boolean}
 *
 */
const validatorLogOrSkipLog = (value?: {
    skipLogTransaction?: boolean;
    logTransaction?: LogTransaction;
}) => {
    if (!value) {
        return false;
    }

    if (value.skipLogTransaction === true) {
        return true;
    }

    if (Check.instanceStrict(value.logTransaction, LogTransaction)) {
        return true;
    }

    return false;
};

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc Helper code that collects server and client data until it is assembled in DustSink#createDustEvent.
 *
 */
export default class DustLogUtility {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {String}
     * @desc The constructor name of the file where this event took place.
     *
     */
    private source: string;

    /**
     *
     * @access private
     * @type {String}
     * @desc The urn corresponding to this event.
     *
     */
    private urn: string;

    /**
     *
     * @access private
     * @type {Object}
     * @desc Stores client data like errors, provider info, query info etc.
     *
     */
    private data: object;

    /**
     *
     * @access private
     * @type {Object}
     * @desc The payload generated in the client.
     *
     */
    private payload?: DustLogUtilityPayload;

    /**
     *
     * @access private
     * @type {String}
     * @desc stores the category of this event
     * @note for `Glimpse` and `Personalization` events this should be supplied when creating `DustLogUtility` for
     * all other events it is constructed in `DustLogUtility.generateCategory()`.
     *
     */
    private category?: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Logging.LogTransaction|undefined}
     * @desc Used for facilitating Edge Dust events.
     * @note Either logTransaction or skipLogTransaction=true must be provided.
     *
     */
    public logTransaction?: LogTransaction;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     * @desc The full JSON key path of the SDK configuration service endpoint.
     *
     */
    private endpointKey?: string;

    /**
     *
     * @access private
     * @type {ServerData}
     * @desc Stores server data - only the method string gets passed in via the constructor.
     *
     */
    public server: ServerData;

    /**
     *
     * @access private
     * @type {Date}
     * @desc The timestamp of when the event was logged.
     *
     */
    private timestamp: Date;

    /**
     *
     * @access private
     * @type {String}
     * @desc Needs to be {name} for the logger but eventually turns into {event} - the urn for this dustEvent.
     * @note The user should be passing in a reference to the full urn or reference to the urn in `SDK.Services.Internal.Dust.DustUrnReference`.
     *
     */
    private name: string;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private level: number;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc Flag that indicates this is a `SDK.Internal.Dust.DustEvent` event - parsed in `SDK.Logging.Logger`.
     *
     */
    private isPublic: boolean;

    /**
     *
     * @access private
     * @type {HttpCoreMethod}
     * @desc HTTP method used in the request.
     *
     */
    private method: HttpCoreMethod;

    /**
     *
     * @access private
     * @type {Object|null}
     * @desc error object
     *
     */
    public error: Nullable<{
        code?: string;
        case?: string;
        reasons?: Array<ErrorReason>;
        cause: {
            message: string;
            transactionId: string;
        };
    }>;

    /**
     *
     * @access private
     * @since 21.0.0
     * @type {Object|undefined}
     *
     */
    private exception?: unknown;

    /**
     *
     * @access private
     * @since 20.1.0
     * @type {String|undefined}
     * @desc Version of data in event.
     *
     */
    private dataVersion?: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {String} options.source
     * @param {String} options.urn
     * @param {Object} [options.data={}]
     * @param {String} [options.method]
     * @param {DustLogUtilityPayload} [options.payload={}]
     * @param {String} [options.category]
     * @param {String} [options.endpointKey]
     * @param {SDK.Logging.LogTransaction} [options.logTransaction]
     * @param {Boolean} [options.skipLogTransaction]
     * @param {String} [options.dataVersion]
     * @throws {InvalidArgumentException}
     * @note Either logTransaction or skipLogTransaction=true must be provided for validatorLogOrSkipLog.
     *
     */
    public constructor(options: {
        logger: Logger;
        source: string;
        urn: string;
        data?: object;
        payload?: DustLogUtilityPayload;
        category?: string;
        endpointKey?: string;
        logTransaction?: LogTransaction;
        skipLogTransaction?: boolean;
        dataVersion?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    source: Types.nonEmptyString,
                    urn: Types.nonEmptyString,
                    data: Types.object().optional,
                    payload: Types.object(DustLogUtilityPayloadTypedef)
                        .optional,
                    category: Types.nonEmptyString.optional,
                    endpointKey: Types.nonEmptyString.optional,
                    dataVersion: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
            const logTransactionParams = {
                options: Types.custom(
                    validatorLogOrSkipLog,
                    ' { logTransaction: LogTransaction } instance or { skipLogTransaction: true }'
                )
            };

            typecheck(this, logTransactionParams, arguments);
        }

        const {
            logger,
            source,
            urn,
            data = {},
            payload,
            category,
            logTransaction,
            endpointKey,
            dataVersion
        } = options;

        const method = payload?.method as HttpCoreMethod;

        this.logger = logger;
        this.source = source;
        this.urn = urn;
        this.data = data;
        this.payload = payload;
        this.category = category;
        this.logTransaction = logTransaction;
        this.endpointKey = endpointKey;
        this.timestamp = new Date();
        this.name = this.urn;
        this.level = LogLevel.info;
        this.isPublic = true;
        this.error = null;
        this.dataVersion = dataVersion;
        this.server = {} as ServerData;

        if (method) {
            this.server.method = method;
        }

        /**
         *
         * @note This is called in the constructor to prevent a situation where
         * the client receives an error from services. An error from services
         * would bypass the call to `getResponseCode` which is where
         * `parsePayload` was called. Being called in the constructor ensures it
         * gets called regardless of if the client receives an error back from
         * services
         *
         */
        this.parsePayload();
    }

    /**
     *
     * @access public
     * @desc entry point for creating a DustEvent. This will piece together the first parts of the DustPayload and
     * send them through the logger flow until DustSink can assemble the final DustEvent.
     * @note (try/catch and finally) ensure this will always resolve, it is a safety mechanism to prevent any methods
     * in the app from failing while chaining this method
     * @returns {Promise<Void>}
     *
     */
    public log() {
        try {
            const { isPublic, logger, source } = this;

            if (!logger.dustEnabled) {
                return Promise.resolve();
            }

            const logEvent = this.generateLogEvent();

            return logger
                .log(source, logEvent, isPublic)
                .catch((ex) => {
                    return logger.warn(ex, logEvent);
                })
                .finally(() => {
                    return Promise.resolve();
                });
        } catch (ex) {
            return Promise.resolve();
        }
    }

    /**
     *
     * @access public
     * @since 4.3.1
     * @param {SDK.Services.Exception.ServiceException} error
     * @note must return an unmodified version of the error - the `cause` property is platform specific
     * @returns {Object}
     *
     */
    public captureError(error: unknown) {
        this.exception = error;

        const castError = (error ?? {}) as Partial<ServiceException>;

        this.error = {
            case: castError.data?.name,
            reasons: castError.reasons,
            cause: {
                message: castError.message ?? '',
                transactionId: castError.transactionId ?? ''
            }
        };

        return error;
    }

    /**
     *
     * @param {Object} data
     * @desc client generated data about the event like a query, the providerType (bamIdentity), etc and errors
     * @note this operation might overwrite previously stored information
     *
     */
    public logData(data = {}) {
        this.data = Object.assign(this.data, data);
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Object} options.response
     * @param {String} options.transactionId
     * @desc parses a response from the client
     *
     */
    public parseResponse(options: {
        response: ServerResponse;
        transactionId: string;
    }) {
        if (Check.object(options)) {
            const { timestamp } = this;
            const { status } = options.response;
            const { transactionId } = options;

            this.server.requestId = transactionId;
            this.server.statusCode = status;
            this.server.roundTripTime = +Date.now() - +timestamp;
        }
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {Object} options.response
     * @param {SDK.Services.Exception.ServiceException} [options.exception]
     * @desc parses a response from the client for Dust Edge Events
     *
     */
    public setServiceInteraction(options: {
        response: ServerResponse;
        exception?: ServiceException;
    }) {
        const { logTransaction: lt, timestamp, server, endpointKey } = this;
        const { response: serverResponse, exception } = options;
        const { status, headers } = serverResponse;
        const { host, path, method } = server;

        const logTransaction = lt as LogTransaction;

        const request: ServiceRequest = {
            host,
            path,
            method
        };
        const response = {
            requestId: headers.get(REQUEST_ID),
            region: headers.get(SDK_REGION),
            cloudFrontPop: headers.get(AMZ_CF_POP),
            cloudFrontId: headers.get(AMZ_CF_ID),
            statusCode: status,
            roundTripTime: +Date.now() - +timestamp
        };
        const timing = {
            requestStart: +timestamp - +logTransaction.startTime,
            requestEnd: +Date.now() - +logTransaction.startTime
        };
        const configuration = {
            endpoint: endpointKey!
        };
        const error = exception?.reasons;

        const serviceInteraction: ServiceInteraction = {
            request,
            response,
            timing,
            configuration,
            error
        };

        logTransaction.appendRequest(serviceInteraction);
    }

    /**
     *
     * @access private
     * @desc parses payload to get host and path
     * @note urlRegex parses the payload Url to be able to extract the host and path reliably
     *
     */
    private parsePayload() {
        const { host, path } = parseUrl(this.payload?.url);

        this.server.host = host!;
        this.server.path = path!;
    }

    /**
     *
     * @access private
     * @desc assembles an object used in DustSink where it gets transformed into a DustEvent
     * @returns {Object}
     *
     */
    private generateLogEvent() {
        const {
            data,
            error,
            isPublic,
            level,
            name,
            payload,
            server,
            timestamp,
            dataVersion
        } = this;

        const isServiceEvent = Check.nonEmptyObject(payload);
        const hasErrors = Check.assigned(error);

        const extraData: ExtraData = {
            category: this.generateCategory({ isServiceEvent, hasErrors }),
            timestamp: timestamp.toISOString(),
            dataVersion
        };

        const logEvent: InterimLogEvent = {
            extraData,
            isPublic,
            name,
            level
        };

        if (isServiceEvent) {
            extraData.server = server;
        }

        if (Check.nonEmptyObject(data)) {
            extraData.data = data;
        }

        if (hasErrors) {
            extraData.error = error;
        }

        return logEvent;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {Boolean} options.isServiceEvent
     * @param {Boolean} options.hasErrors
     * @desc sets the category of the event
     * @note this should only create a new category for general dust events - `PersonalizationEvent`'s and `GlimpseEvent`'s
     * should have their category created when the `DustLogUtility` instance is created
     * this is dynamic due to the fact that general Dust events can originate as a result of an API call or a Service
     * interaction and we don't know if it's successful until after the operation has completed
     * @example 'urn:bamtech:dust:bamsdk:event:service'
     *
     */
    private generateCategory(
        options: {
            isServiceEvent?: boolean;
            hasErrors?: boolean;
        } = {}
    ) {
        const { category } = this;

        if (Check.not.assigned(category)) {
            const { isServiceEvent, hasErrors } = options;

            const eventName = isServiceEvent ? 'service' : 'api';
            const eventType = hasErrors ? 'error' : 'event';

            return `${DustUrnReference.bamSdk}${DustCategory[eventName][eventType]}`;
        }

        return category;
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Object} options.dustUtilityCtorOptions
     * @param {Constructor} [options.DustLogUtilityCtorOverride]
     * @param {Function} options.action the action to execute while wrapping it's execution with DustLogUtility.
     * @desc This allows us to wrap some async code around with the common DustLogUtility's pattern of capturing errors and logging.
     * Prefer to use the @apiMethodDecorator pattern where possible but for cases we have to provide extra data/context
     * variables we can use this wrap method.
     * @returns {Promise<T>} Will return the result of executing the options.action passed in.
     * @example
     * return DustLogUtility.wrap({
     *     dustUtilityCtorOptions: {
     *         logger,
     *         source: ContentApi.name,
     *         urn: ContentApiDustUrnReference.query,
     *         data: {
     *             context: query.context,
     *             contentTransactionId
     *         }
     *     },
     *     action: async () => {
     *         return await contentApi.query(options);
     *     }
     * });
     *
     */
    public static async wrap<T>(options: {
        dustUtilityCtorOptions: ConstructorParameters<typeof DustLogUtility>[0];
        action: (dustLogUtility?: DustLogUtility) => T;
        DustLogUtilityCtorOverride: typeof DustLogUtility;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    dustUtilityCtorOptions: Types.nonEmptyObject,
                    action: Types.function,
                    DustLogUtilityCtorOverride: Types.function.optional
                })
            };

            typecheck(this, 'wrap', params, arguments);
        }

        const { dustUtilityCtorOptions, action } = options;

        const DustLogUtilityCtorOverride =
            options.DustLogUtilityCtorOverride || DustLogUtility;

        if (!dustUtilityCtorOptions.logger.dustEnabled) {
            return action();
        }

        const dustLogUtility = new DustLogUtilityCtorOverride(
            dustUtilityCtorOptions
        );

        let exception;

        try {
            return await action(dustLogUtility);
        } catch (ex) {
            exception = ex;

            dustLogUtility.captureError(exception);

            throw exception;
        } finally {
            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustLogUtility';
    }
}
