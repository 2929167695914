/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.AssetInsertionStrategyQos
 * @desc The asset insertion strategy used for this piece of media.
 *
 * @property {String} ssai - Since `18.0.0` - The service returns "SSAI".
 * @property {String} sgai - Since `18.0.0` - The service returns "SGAI".
 * @property {String} none - Since `25.0.0` - The service returns "NONE".
 *
 * @example
 * AssetInsertionStrategyQos.ssai // 'SSAI'
 *
 */
export enum AssetInsertionStrategyQos {
    ssai = 'SSAI',
    sgai = 'SGAI',
    none = 'NONE'
}

/**
 *
 * @enum
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Media.AudioRole
 * @desc This is a type 1 enum. Defines a complete set of all possible values.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 * @property {String} PRIMARY - Since `24.0.0`.
 * @property {String} NARRATION - Since `24.0.0`.
 *
 * @example
 * AudioRole.PRIMARY // 'PRIMARY'
 *
 */
export enum AudioRole {
    PRIMARY = 'PRIMARY',
    NARRATION = 'NARRATION'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.AudioType
 * @desc An enumeration of possible user-preferred audio types. Used to
 * differentiate atmos in playback scenario name.
 *
 * @property {String} atmos - Since `4.18.0` - Playlist responses contain atmos audio variants.
 *
 * @example
 * AudioType.atmos // 'ATMOS'
 *
 */

export enum AudioType {
    atmos = 'ATMOS'
}

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Media.DrmType
 *
 * @property {String} NONE - Used to signify that no DrmType is available.
 * @property {String} SILK - SILK is the existing encryption key mechanism.
 * @property {String} PLAYREADY - PlayReady is the Microsoft content encryption standard.
 * @property {String} WIDEVINE - Widevine is Google's content encryption standard.
 * @property {String} FAIRPLAY - FairPlay is the Apple content encryption standard.
 * @property {String} PRMNAGRA - Since `4.4.0` - Nagra is the Canal+ content encryption standard.
 *
 */

export enum DrmType {
    NONE = 'NONE',
    SILK = 'SILK',
    PLAYREADY = 'PLAYREADY',
    WIDEVINE = 'WIDEVINE',
    FAIRPLAY = 'FAIRPLAY',
    PRMNAGRA = 'PRMNAGRA'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.EncryptionMode
 * @desc An enumeration of DRM encryption types. Used to differentiate cbcs, ctr or silk in base device playback name.
 *
 * @property {String} cbcs - Since `4.18.0` - String used in base playback scenario.
 * @property {String} ctr - Since `4.18.0` - String used in base playback scenario.
 * @property {String} silk - Since `14.0.0` - String used in base playback scenario to indicate that the device requires silk playlists.
 *
 * @example
 * EncryptionMode.cbcs // 'cbcs'
 *
 */
export enum EncryptionMode {
    /**
     *
     * @note CBCS (short for Cipher Block Chaining Sample) is the common encryption mode for HLS / Apple based devices.
     *
     */
    cbcs = 'cbcs',
    /**
     *
     * @note CTR (short for counter) is the common encryption mode for Android and Browser Based devices.
     *
     */
    ctr = 'ctr',
    silk = 'silk'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.HdrType
 * @desc Defines the different types of HDR available.
 *
 * @property {String} dolbyVision - Since `4.18.0` - The dolby vision type.
 * @property {String} hdr10 - Since `4.18.0` - The Hdr10 type.
 *
 * @example
 * HdrType.hdr10 // 'HDR10'
 *
 */
export enum HdrType {
    dolbyVision = 'DOLBY_VISION',
    hdr10 = 'HDR10'
}

/**
 *
 * @access public
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionMode
 *
 * @property {String} SGAI - Since `18.0.0`.
 * @property {String} SSAI - Since `18.0.0`.
 *
 * @example
 * InsertionMode.SGAI // 'SGAI'
 *
 */
export enum InsertionMode {
    SGAI = 'SGAI',
    SSAI = 'SSAI'
}

/**
 *
 * @access public
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointActionType
 * @desc The supported types for actions within inserted content. This is an open enum.
 *
 * @property {String} SKIP - Since `24.0.0`.
 *
 * @example
 * SDK.Services.Media.InsertionPointActionType.SKIP // 'SKIP'
 *
 */
export enum InsertionPointActionType {
    SKIP = 'SKIP'
}

/**
 *
 * @access public
 * @since 19.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointContentSubType
 * @desc Sub-type of content to be inserted.
 * @note For PQoE reporting purposes, `BRAND_BUMPER`, `NOAH_CARD`, and `TUNE_IN_CARD` are all equivalent to `PresentationType: Bumper`.
 *
 * @property {String} BUMPER - Since `25.0.0`.
 * @property {String} BRAND_BUMPER - Since `24.0.0`.
 * @property {String} SLUG - Since `19.0.0`.
 * @property {String} CONTENT_PROMO - Since `24.0.0`.
 * @property {String} NOAH_CARD - Since `24.0.0` - The "NOAH Ads Exception Card" that functions as a disclaimer to customers on a Hulu ad-free plan (NOAH = NO Ads on Hulu) for content that requires ads to be shown, even to ad-free viewers.
 * @property {String} TUNE_IN_CARD - Since `24.0.0` - A tune-in card is a video bumper for some content that is broadcast on a linear partner channel that says something like "Tune in Sundays at 8pm EST to see new episodes of Simpsons".
 *
 * @example
 * SDK.Services.Media.InsertionPointContentSubType.SLUG // 'SLUG'
 *
 */
export enum InsertionPointContentSubType {
    BUMPER = 'BUMPER',
    BRAND_BUMPER = 'BRAND_BUMPER',
    SLUG = 'SLUG',
    CONTENT_PROMO = 'CONTENT_PROMO',
    NOAH_CARD = 'NOAH_CARD',
    TUNE_IN_CARD = 'TUNE_IN_CARD'
}

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointContentType
 *
 * @property {String} AUXILIARY_CONTENT - Since `18.0.0`.
 * @property {String} AD_SERVICE_CONTENT - Since `18.0.0`.
 *
 * @example
 * InsertionPointContentType.AUXILIARY_CONTENT // 'AUXILIARY_CONTENT'
 *
 */
export enum InsertionPointContentType {
    AUXILIARY_CONTENT = 'AUXILIARY_CONTENT',
    AD_SERVICE_CONTENT = 'AD_SERVICE_CONTENT'
}

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointPlacement
 *
 * @property {String} BUMPER_PREROLL - Since `18.0.0`.
 * @property {String} PREROLL - Since `18.0.0`.
 * @property {String} MIDROLL - Since `18.0.0`.
 *
 * @example
 * InsertionPointPlacement.BUMPER_PREROLL // 'BUMPER_PREROLL'
 *
 */
export enum InsertionPointPlacement {
    BUMPER_PREROLL = 'BUMPER_PREROLL',
    PREROLL = 'PREROLL',
    MIDROLL = 'MIDROLL'
}

/**
 *
 * @access public
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Media.InsertionPointVisualElementType
 * @desc The supported visual element types that can be displayed within inserted content.
 *
 * @property {String} PROMO_TEXT - Since `24.0.0`.
 *
 * @example
 * SDK.Services.Media.InsertionPointVisualElementType.PROMO_TEXT // 'PROMO_TEXT'
 *
 */
export enum InsertionPointVisualElementType {
    PROMO_TEXT = 'PROMO_TEXT'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.Media.MediaPlayheadStatus
 * @desc Provides the status of the playhead retrieval.
 *
 * @property {String} Success - A previous playhead position was found for the associated media and user.
 * @property {String} NoPlayhead - No playhead position was found for the associated media and user.
 * User has never played video before or cleared bookmarks.
 * @property {String} Unavailable - Unable to retrieve the playhead position for the associated media and user.
 * There was an error retrieving the playhead position.
 *
 */
export enum MediaPlayheadStatus {
    Success = 'Success',
    NoPlayhead = 'NoPlayhead',
    Unavailable = 'Unavailable'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.MediaQuality
 * @desc An enumeration of possible user-preferred media qualities.
 * Android, Apple, and Browser supported.
 *
 * @property {String} limited - Since `4.18.0` - Indicates using the limited base device mapping. eg `cbcs-limited`,
 * `ctr-limited`. Playlist responses are limited to 480p resolution, eg for metered networks.
 * @property {String} regular - Since `4.18.0` - Indicates using the regular base device mapping. eg `cbcs-regular`,
 * `ctr-regular`. Playlist responses are limited to 10,000k bitrate.
 * @property {String} high - Since `4.18.0` - Indicates using the high base device mapping. eg `cbcs-high`, `ctr-high`.
 * Playlist responses are limited to 100,000k bitrate.
 *
 * @example
 * MediaQuality.limited // 'limited'
 *
 */
export enum MediaQuality {
    limited = 'limited',
    regular = 'regular',
    high = 'high'
}

/**
 *
 * @access public
 * @enum
 * @since 2.0.0
 * @typedef {Object} SDK.Services.Media.PlaylistType
 * @desc Enumerates the available playlist types for media.
 *
 * @property {String} COMPLETE - The "complete" playlist type value.
 * @property {String} SLIDE - The "sliding" playlist type value.
 *
 * @example
 * PlaylistType.COMPLETE // 'complete'
 * PlaylistType.SLIDE // 'sliding'
 *
 */
export enum PlaylistType {
    COMPLETE = 'complete',
    SLIDE = 'sliding'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.Protocol
 * @desc Protocol strings used for filtering during playback selection
 *
 * @property {String} HTTP - Since `4.19.0`.
 * @property {String} HTTPS - Since `4.18.0`.
 *
 * @example
 * Protocol.HTTPS // 'HTTPS'
 *
 */
export enum Protocol {
    HTTPS = 'HTTPS',
    HTTP = 'HTTP'
}

/**
 *
 * @enum
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Media.ResolutionMax
 * @desc Resolution strings used for filtering during playback selection. eg `["1920x180","1280x720"]`
 *
 * @property {String} res1920x1080 - Since `4.18.0` - Primarily used for the HD multi master use case.
 * @property {String} res1280x720 - Since `4.18.0` - Primarily used for the restricted sw drm use case.
 *
 * @example
 * ResolutionMax.res1280x720 // '1280x720'
 *
 */
export enum ResolutionMax {
    res1920x1080 = '1920x1080',
    res1280x720 = '1280x720'
}

/**
 *
 * @access public
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.StreamingType
 *
 * @property {String} VOD - Since `18.0.0`.
 * @property {String} LIVE - Since `18.0.0`.
 *
 * @example
 * StreamingType.VOD // 'VOD'
 *
 */
export enum StreamingType {
    VOD = 'VOD',
    LIVE = 'LIVE'
}

/**
 *
 * @enum
 * @since 18.0.0
 * @typedef {Object} SDK.Services.Media.SubscriptionType
 * @desc The type of subscription used for this piece of media.
 *
 * @property {String} adsRequired - Since `18.0.0` - The service returns "ADS_REQUIRED".
 * @property {String} noAdsRequired - Since `18.0.0` - The service returns "NO_ADS_REQUIRED".
 *
 * @example
 * SubscriptionType.adsRequired // 'ADS_REQUIRED'
 *
 */
export enum SubscriptionType {
    adsRequired = 'ADS_REQUIRED',
    noAdsRequired = 'NO_ADS_REQUIRED'
}

/**
 *
 * @enum
 * @since 24.0.0
 * @typedef {Object} SDK.Services.Media.SubtitleRole
 * @desc This is a type 1 enum. Defines a complete set of all possible values.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 * @property {String} NORMAL - Since `24.0.0`.
 * @property {String} SDH - Since `24.0.0`.
 * @property {String} FORCED - Since `24.0.0`.
 *
 * @example
 * SubtitleRole.NORMAL // 'NORMAL'
 *
 */
export enum SubtitleRole {
    NORMAL = 'NORMAL',
    SDH = 'SDH',
    FORCED = 'FORCED'
}

/**
 *
 * @enum
 * @since 4.2.0
 * @typedef {Object} SDK.Services.Media.SupportedCodec
 * @desc Defines the supported video codecs.
 *
 * @property {String} h264
 * @property {String} h265
 *
 * @example
 * SupportedCodec.h264 // 'h.264'
 *
 */
export enum SupportedCodec {
    h264 = 'h.264',
    h265 = 'h.265'
}

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Media.ThumbnailPresentationType
 * @desc specifies the type of Thumbnail you are using
 * @note Flexible enum
 *
 * @property {String} BUMPER
 * @property {String} MAIN
 * @property {String} DUB_CARD
 *
 * @example
 * ThumbnailPresentationType.BUMPER // 'BUMPER'
 *
 */
export enum ThumbnailPresentationType {
    BUMPER = 'BUMPER',
    MAIN = 'MAIN',
    DUB_CARD = 'DUB_CARD'
}
