/**
 *
 * @module validationResultSuccess
 *
 */

import ValidationResult from './validationResult';

/**
 *
 * @access protected
 * @since 4.11.0
 * @desc Applicable if `ValidationResult.validationResultType` is `com.disneystreaming.sdp.http.Success`
 *
 */
export default class ValidationResultSuccess extends ValidationResult {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.ValidationResultSuccess';
    }
}
