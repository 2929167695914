/**
 *
 * @module logTransaction
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#edge-logging
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#sdk-instance-id
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from './logger';

import {
    ServiceInteraction,
    ServiceInteractionTypedef
} from '../internal/dust/typedefs';

import ServiceException from '../services/exception/serviceException';
import LogLevel from './logLevel';

/**
 *
 * @access protected
 * @since 13.0.0
 *
 */
export default class LogTransaction {
    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     *
     */
    private urn: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     *
     */
    private file: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Number|undefined}
     *
     */
    private line?: number;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Date}
     * @desc The timestamp of when the event was logged.
     *
     */
    public startTime: Date;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Number|null}
     * @desc The total duration the api took to run.
     * @note Date.now() - this.startTime
     *
     */
    private totalDuration: number | null;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Array<Object<SDK.Internal.Dust.ServiceInteraction>>}
     *
     */
    private serviceRequests: Array<ServiceInteraction>;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc flag that indicates this is a Dust type event.
     *
     */
    private isPublic: boolean;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {String}
     * @desc Root level GUID that groups all events within an instantiated version of an SDK.
     *
     */
    private sdkInstanceId: string;

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {Object} options
     * @param {Logger} options.logger
     * @param {String} options.urn
     * @param {String} options.file
     * @param {String} options.sdkInstanceId
     * @param {Number} [options.line]
     *
     */
    public constructor(options: {
        logger: Logger;
        urn: string;
        file: string;
        sdkInstanceId: string;
        line?: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    urn: Types.nonEmptyString,
                    file: Types.nonEmptyString,
                    sdkInstanceId: Types.nonEmptyString,
                    line: Types.number.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger, urn, file, line, sdkInstanceId } = options;

        this.logger = logger;
        this.urn = urn;
        this.file = file;
        this.sdkInstanceId = sdkInstanceId;
        this.line = line;

        this.startTime = new Date();
        this.totalDuration = null;
        this.serviceRequests = [];
        this.isPublic = true;
    }

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {Object} options
     * @param {String} options.urn
     * @param {String} options.file
     * @param {SDK.Logging.Logger} options.logger
     * @param {Function<T>} options.action
     * @returns Promise<T>
     *
     */
    public static async wrapLogTransaction<T>(options: {
        urn: string;
        file: string;
        logger: Logger;
        action: (logTransaction: LogTransaction) => Promise<T> | T;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    urn: Types.nonEmptyString,
                    file: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    action: Types.function
                })
            };

            typecheck(this, 'wrapLogTransaction', params, arguments);
        }

        const { urn, file, logger, action } = options;
        const { sdkInstanceId } = logger;

        const logTransaction = new LogTransaction({
            logger,
            urn,
            file,
            sdkInstanceId
        });

        let exception;

        try {
            return await action(logTransaction);
        } catch (ex) {
            exception = ex;

            throw ex;
        } finally {
            logTransaction.finalize(exception);
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object<SDK.Internal.Dust.ServiceInteraction>} request
     * @returns {Void}
     *
     */
    public appendRequest(request: ServiceInteraction) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.object(ServiceInteractionTypedef)
            };

            typecheck(this, 'appendRequest', params, arguments);
        }

        this.serviceRequests.push(request);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {SDK.Service.Exception.ServiceException} [error]
     * @returns {Promise<Void>}
     *
     */
    private finalize(error?: ServiceException | unknown) {
        this.totalDuration = Date.now().valueOf() - this.startTime.valueOf();

        try {
            const logEvent = this.generateLogEvent(error);

            this.logger
                .log(this.file, logEvent, this.isPublic)
                // @todo: issue: source should not be an object
                .catch((ex) => {
                    return this.logger.warn(ex, logEvent);
                });
        } catch (ex) {
            // This should be mostly a no-op error but attempt to at
            // least log it out so we can avoid missing unknown errors

            // eslint-disable-next-line no-console, custom-rules/no-unnecessary-optional-chain
            if (console?.error) {
                console.error(ex); // eslint-disable-line no-console
            }
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {SDK.Service.Exception.ServiceException} [error]
     * @desc Assembles a `Metric` object used in `EdgeSink` where it gets transformed into an `EdgeEvent`.
     * @returns {Metric}
     *
     */
    private generateLogEvent(error?: ServiceException | unknown) {
        const isEdge = true;

        const {
            isPublic,
            serviceRequests,
            file,
            line,
            urn,
            startTime,
            totalDuration,
            sdkInstanceId
        } = this;

        const logEvent = {
            extraData: {
                isEdge,
                file,
                line,
                urn,
                serviceRequests,
                startTime,
                totalDuration,
                sdkInstanceId,
                error
            },
            isPublic,
            name: urn,
            level: LogLevel.none
        };

        return logEvent;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.LogTransaction';
    }
}
