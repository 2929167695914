/**
 *
 * @module userActivity}Plugin
 * @desc {SDK.UserActivity.UserActivityPlugin} entry point.
 *
 */

import UserActivity from './entry';
import UserActivityApi from './userActivityApi';
import SocketPlugin from '../socket/socketPlugin';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'UserActivity', typeof UserActivity> = {
    pluginName: 'UserActivity',
    entry: UserActivity,
    dependencies: [SocketPlugin],

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { sessionManager, socketManager } = sdkSession.managers;
        const { monotonicTimestampProvider } = sdkSession;

        sdkSession.userActivityApi = new UserActivityApi({
            logger,
            sessionManager,
            socketManager,
            monotonicTimestampProvider
        });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
