/**
 *
 * @module refreshTokenRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';
import { refreshTokenQuery } from './queries.gql';

/**
 *
 * @since 10.0.0
 *
 */
export default class RefreshTokenRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: { refreshToken: string }) {
        const variables = {
            input
        };

        super({
            query: refreshTokenQuery,
            variables,
            operationName: 'refreshToken'
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.RefreshTokenRequest';
    }
}
