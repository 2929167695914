/**
 *
 * @module exploreApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/explore.md
 * @see https://wiki.disneystreaming.com/display/EXPDEL/Explore+API%3A+Schema+versions
 * @see https://github.bamtech.co/pages/fed-solutions/documentation/content/#explore-api
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import ExploreClient from '../services/explore/exploreClient';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import LogTransaction from '../logging/logTransaction';

import {
    DeeplinkQuery,
    DeeplinkQueryTypedef,
    DownloadMetadataInput,
    DownloadMetadataInputTypedef,
    ExploreQuery,
    ExploreQueryTypedef,
    GetPartnerContinueWatchingQuery,
    GetPartnerContinueWatchingQueryTypedef,
    PageQuery,
    PageQueryTypedef,
    PlayerExperienceQuery,
    PlayerExperienceQueryTypedef,
    SearchQuery,
    SearchQueryTypedef,
    SeasonQuery,
    SeasonQueryTypedef,
    SetQuery,
    SetQueryTypedef,
    UpNextQuery,
    UpNextQueryTypedef,
    UserStateInput,
    UserStateInputTypedef,
    WatchlistRequest,
    WatchlistRequestTypedef
} from './typedefs';

import {
    DeeplinkResponse,
    ExploreQueryResult,
    GetPartnerContinueWatchingResponse,
    PlayerExperienceResponse,
    PageResponse,
    SetResponse,
    SeasonResponse,
    UserStateResponse,
    UpNextResponse,
    SearchResponse,
    DownloadMetadataResponse
} from '../services/explore/typedefs';

import { ApiOptions } from '../typedefs';

import type Logger from '../logging/logger';
import type AccessTokenProvider from '../token/accessTokenProvider';

import { WatchListEventType } from '../services/explore/enums';

import SessionManager from '../session/sessionManager';
import MessageEnvelope from '../socket/messageEnvelope';
import SocketSchemaUrls from '../socket/socketSchemaUrls';

const DustUrn = DustUrnReference.explore.exploreApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 23.1.0
 * @desc The Explore API follows the principle that "A Page is a Page™."
 * This means that the API request for any details page has the same structure and behavior,
 * and the response schema is consistent across Series details, Movie details, and Event details.
 * In essence, a client doesn't need to know the specific contents of the page; it only needs to
 * understand how to render the Details fields based on the given page style. For instance, the
 * page itself doesn't distinguish whether the title field in the response belongs to a
 * series or a movie; it simply knows how to display a title within its designated page style.
 * @note The key concept here is that clients have a unified details page where they render the
 * response data according to the provided style(s). This approach enables server-side
 * experimentation and flexibility in delivering various types of content to the page, as well as
 * deriving the values presented on the page.
 *
 */
export default class ExploreApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Services.Explore.ExploreClient}
     *
     */
    private exploreClient: ExploreClient;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SessionManager}
     *
     */
    public sessionManager: SessionManager;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {String}
     *
     */
    private source: string;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Explore.ExploreClient} options.exploreClient
     * @param {SessionManager} options.sessionManager
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {String} options.source
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        logger: Logger;
        exploreClient: ExploreClient;
        sessionManager: SessionManager;
        accessTokenProvider: AccessTokenProvider;
        source: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    exploreClient: Types.instanceStrict(ExploreClient),
                    sessionManager: Types.instanceStrict(SessionManager),
                    source: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { exploreClient, sessionManager, source } = options;

        this.exploreClient = exploreClient;
        this.sessionManager = sessionManager;
        this.source = source;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {SDK.Services.Explore.PageQuery} query
     * @desc Queries the Explore service for Page data.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<PageResponse>} A promise that completes when the
     * operation has succeeded.
     *
     * @example <caption>Retrieving a Page.</caption>
     *
     * const queryOptions: PageQuery = {
     *     version: 'v1.0',
     *     pageId: 'entity-some-page-id'
     * };
     *
     * const pageResponse = await exploreApi.getPage(queryOptions);
     *
     * console.log(pageResponse);
     *
     */
    public async getPage(query: PageQuery): Promise<PageResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(PageQueryTypedef)
        }
    })
    public async getPage(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[PageQuery]>;

        return this.exploreClient.getPage({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {SDK.Services.Explore.SetQuery} query
     * @desc Queries the Explore service for Set data.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SetResponse>} A promise that completes when the
     * operation has succeeded.
     *
     * @example <caption>Retrieving a Set.</caption>
     *
     * const queryOptions: SetQuery = {
     *     version: 'v1.0',
     *     setId: 'some-set-id'
     * };
     *
     * const setResponse = await exploreApi.getSet(queryOptions);
     *
     * console.log(setResponse);
     *
     */
    public async getSet(query: SetQuery): Promise<SetResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(SetQueryTypedef)
        }
    })
    public async getSet(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[SetQuery]>;

        return this.exploreClient.getSet({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {SDK.Services.Explore.DeeplinkQuery} query
     * @desc Queries the Explore service for a deeplink to the indicated user experience within a Disney application.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<DeeplinkResponse>} A promise that completes when the
     * operation has succeeded.
     *
     * @example <caption>Retrieving a Deeplink.</caption>
     *
     * const queryOptions: DeeplinkQuery = {
     *     version: 'v1.0',
     *     refId: 'some-ref-id',
     *     refIdType: SDK.Services.Explore.RefIdType.encodedSeriesId
     * };
     *
     * const deeplinkResponse = await exploreApi.getDeeplink(queryOptions);
     *
     * console.log(deeplinkResponse);
     *
     */
    public async getDeeplink(query: DeeplinkQuery): Promise<DeeplinkResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(DeeplinkQueryTypedef)
        }
    })
    public async getDeeplink(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[DeeplinkQuery]>;

        return this.exploreClient.getDeeplink({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {SDK.Explore.UserStateInput} input
     * @desc Gets a map of personalization IDs (PIDs) to entity state for the active user.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<UserStateResponse>} A promise that completes when the operation has succeeded and
     * returns a map of personalization IDs (PIDs) to entity state for a user.
     *
     */
    public async getUserState(
        input: UserStateInput
    ): Promise<UserStateResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            input: Types.object(UserStateInputTypedef)
        }
    })
    public async getUserState(apiOptions: unknown) {
        const {
            logTransaction,
            args: [input]
        } = apiOptions as ApiOptions<[UserStateInput]>;

        return this.exploreClient.getUserState({
            input,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {SDK.Explore.PlayerExperienceQuery} query
     * @desc Queries the Explore service for the overlay data to decorate the player overlay.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Explore.PlayerExperienceResponse>} A promise that completes when the operation has succeeded and
     * returns the JSON object or string to be interpreted by the application.
     *
     */
    public async getPlayerExperience(
        query: PlayerExperienceQuery
    ): Promise<PlayerExperienceResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(PlayerExperienceQueryTypedef)
        }
    })
    public async getPlayerExperience(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[PlayerExperienceQuery]>;

        return this.exploreClient.getPlayerExperience({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {SDK.Explore.WatchlistRequest} request
     * @desc Adds content to the active user profile's watchlist.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the request has been submitted.
     *
     */
    public async addToWatchlist(request: WatchlistRequest): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(WatchlistRequestTypedef)
        }
    })
    public async addToWatchlist(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[WatchlistRequest]>;

        await this.sendUserAction(
            request,
            WatchListEventType.add,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {SDK.Explore.WatchlistRequest} request
     * @desc Removes content from the active user profile's watchlist.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} A promise that completes when the request has been submitted.
     *
     */
    public async removeFromWatchlist(request: WatchlistRequest): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(WatchlistRequestTypedef)
        }
    })
    public async removeFromWatchlist(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[WatchlistRequest]>;

        await this.sendUserAction(
            request,
            WatchListEventType.remove,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {SDK.Explore.SeasonQuery} query
     * @desc Queries the Explore service for Season data.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Explore.SeasonResponse>} A promise that completes when the operation has succeeded and
     * returns the JSON object or string to be interpreted by the application.
     *
     */
    public async getSeason(query: SeasonQuery): Promise<SeasonResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(SeasonQueryTypedef)
        }
    })
    public async getSeason(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[SeasonQuery]>;

        return this.exploreClient.getSeason({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 25.0.0
     * @param {SDK.Explore.UpNextQuery} query
     * @desc Queries the Explore service for the Up Next data.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Explore.UpNextResponse>} A promise that completes when the operation has succeeded and
     * returns the JSON object or string to be interpreted by the application.
     *
     */
    public async getUpNext(query: UpNextQuery): Promise<UpNextResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(UpNextQueryTypedef)
        }
    })
    public async getUpNext(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[UpNextQuery]>;

        return this.exploreClient.getUpNext({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 27.0.0
     * @param {SDK.Explore.DownloadMetadataInput} input
     * @desc Gets a map of avail IDs to download metadata for the active user.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<DownloadMetadataResponse>} A promise that completes when the operation has succeeded and
     * returns a map of avail IDs for the active user.
     *
     */
    public async getDownloadMetadata(
        input: DownloadMetadataInput
    ): Promise<DownloadMetadataResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            input: Types.object(DownloadMetadataInputTypedef)
        }
    })
    public async getDownloadMetadata(apiOptions: unknown) {
        const {
            logTransaction,
            args: [input]
        } = apiOptions as ApiOptions<[DownloadMetadataInput]>;

        return this.exploreClient.getDownloadMetadata({
            input,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 25.0.0
     * @param {SDK.Explore.SearchQuery} query - The query object that defines the query criteria of the data with properties `version` and `query`. Requires `GET` method.
     * @desc Queries the Explore service for search page data.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SearchResponse>} A promise that completes when the operation has succeeded and returns the JSON object or string to be interpreted by the application.
     *
     */
    public async search(query: SearchQuery): Promise<SearchResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(SearchQueryTypedef)
        }
    })
    public async search(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[SearchQuery]>;

        return this.exploreClient.search({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {SDK.Services.Explore.ExploreQuery} query - The query object that defines the query criteria and source (or context) of the data with properties supplied in the `variables`. Requires `GET` method.
     * @desc Queries the Explore service data.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>} A promise that completes when the
     * operation has succeeded.
     *
     * @example <caption>Using #query to get a Page.</caption>
     *
     * const queryOptions: ExploreQuery = {
     *     version: 'v1.0',
     *     context: 'getPage',
     *     variables: {
     *         pageId: 'hulu'
     *     }
     * };
     *
     * const result = await exploreApi.query(exploreQuery);
     *
     * console.log(result);
     *
     * @example <caption>Using #query to get a Set.</caption>
     *
     * const queryOptions: ExploreQuery = {
     *     version: 'v1.0',
     *     context: 'getSet',
     *     variables: {
     *         setId: 'set/standard-set-id'
     *     }
     * };
     *
     * const result = await exploreApi.query(exploreQuery);
     *
     * console.log(result);
     *
     * @example <caption>Using #query to get a Deeplink.</caption>
     *
     * const queryOptions: ExploreQuery = {
     *     version: 'v1.0',
     *     context: 'getDeeplink',
     *     exploreParams: {
     *         refId: 'some-ref-id',
     *         refIdType: SDK.Services.Explore.RefIdType.encodedSeriesId
     *    },
     * };
     *
     * const result = await exploreApi.query(exploreQuery);
     *
     * console.log(result);
     *
     */
    public async query<T>(query: ExploreQuery): ExploreQueryResult<T>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(ExploreQueryTypedef)
        }
    })
    public async query<T>(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[ExploreQuery]>;

        return this.exploreClient.query<T>({
            query,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     *
     */
    private async sendUserAction(
        request: WatchlistRequest,
        eventType: WatchListEventType,
        logTransaction: LogTransaction
    ) {
        const subject = await this.sessionManager.createMessageSubject();

        const message = new MessageEnvelope({
            eventType: DustUrn.watchlist,
            schemaUrl: SocketSchemaUrls.watchlist,
            data: {
                action_info_block: request.actionInfoBlock,
                page_info_block: request.pageInfoBlock,
                container_info_block: request.containerInfoBlock,
                item_info_block: request.itemInfoBlock,
                event_type: eventType,
                event_occurred_timestamp: new Date().toISOString()
            }
        });

        const { source, accessToken } = this;

        const messageEnvelope = message.getSocketMessage({
            subject,
            source
        });

        await this.exploreClient.sendUserAction({
            request: [messageEnvelope],
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 28.3.0
     * @param {GetPartnerContinueWatchingQuery} query - The query object that defines the query criteria and source (or context) of
     * the data with properties `version` and `limit`. Requires `GET` method.
     * @desc An API solely for Partner CW scenarios independent of core Explore API.
     * @throws {ExploreContentNotFoundException} Content for the request could not be found.
     * @throws {ExploreNotSupportedException} The operation is not currently supported.
     * @throws {ExploreInternalServiceErrorException} Could not complete the request because of an internal error.
     * @throws {InvalidRequestException} The query request was invalid or the context provided to ExploreQuery does not match a value in configs.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<GetPartnerContinueWatchingResponse>}
     *
     */
    public async getPartnerContinueWatching(
        query: GetPartnerContinueWatchingQuery
    ): Promise<GetPartnerContinueWatchingResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            query: Types.object(GetPartnerContinueWatchingQueryTypedef)
        }
    })
    public async getPartnerContinueWatching(apiOptions: unknown) {
        const {
            logTransaction,
            args: [query]
        } = apiOptions as ApiOptions<[GetPartnerContinueWatchingQuery]>;

        const accessToken = this.accessToken;

        return this.exploreClient.getPartnerContinueWatching({
            logTransaction,
            query,
            accessToken
        }) as Promise<GetPartnerContinueWatchingResponse>; // TODO: Update in 29.0.0 see if we can remove this type-cast
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Explore.ExploreApi';
    }
}
