/**
 *
 * @module playbackSelectionTracking
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @deprecated Deprecated as of version `28.0.0` and will be removed in version `29.0.0`. See release notes for replacement.
 * @since 4.18.0
 *
 */
export default class PlaybackSelectionTracking {
    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     *
     */

    public playbackSessionId?: string;
    /**
     *
     * @param {String} [playbackSessionId]
     *
     */
    public constructor(playbackSessionId?: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackSessionId: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.playbackSessionId = playbackSessionId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackSelectionTracking';
    }
}
