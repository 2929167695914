/**
 *
 * @module pngCompression
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.PNGCompression
 * @desc Compression strength for PNG images.
 * The parameter should be one of the following.
 *   0 - No Compression,
 *   1 - Default Compression.
 * If there's no compression parameter on a request to get a PNG variant then Default Compression is applied.
 *
 * @property {Number} none - Since `17.0.0` - No Compression.
 * @property {Number} default - Since `17.0.0` - Default Compression.
 *
 * @example
 * SDK.Ripcut.PNGCompression.none // 0
 *
 */
enum PNGCompression {
    none = 0,
    default = 1
}

export default PNGCompression;
