/**
 *
 * @module flexPlugin
 * @desc {SDK.Flex.FlexPlugin} entry point.
 *
 */

import FlexApi from './flexApi';

import FlexClient from '../services/flex/flexClient';
import FlexClientConfiguration from '../services/flex/flexClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Flex'> = {
    pluginName: 'Flex',

    /**
     *
     * @access protected
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.flexApi = new FlexApi({
            flexClient: new FlexClient({
                config: sdkSession.config.services.flex,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.flex = new FlexClientConfiguration({
            baseUrl: services?.flex?.client?.baseUrl,
            endpoints: serviceDefinitionsConfiguration.processEndpoints(
                services?.flex?.client?.endpoints
            )
        });
    }
};

/**
 *
 * @access public
 * @since 16.0.0
 *
 */
export default plugin;
