/**
 *
 * Service Clients / Shared
 *
 */

import { Types } from '@dss/type-checking';
import HttpHeaders from './shared/httpHeaders';
import {
    AUTHORIZATION,
    CONTENT_TYPE,
    REQUEST_ID
} from './shared/httpHeaderConstants';

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Providers.HttpOptions
 * @since 23.0.0
 * @desc The options for the HTTP request.
 * @property {String} [url]
 * @property {HttpHeaders} headers
 * @property {String} [body]
 * @property {String} [bodyType]
 * @property {String} [credentials]
 * @property {String} [method]
 * @property {String} [mode]
 * @property {Object} [tuningOptions]
 * @property {boolean} [tuningOptions.ignoreRequestIdValidation]
 * @property {Function} [preJsonParseProcessor]
 *
 */
export type HttpOptions = {
    url?: string;
    headers: HttpHeaders;
    body?: string;
    bodyType?: string;
    credentials?: string;
    method?: string;
    mode?: string;
    tuningOptions?: {
        ignoreRequestIdValidation?: boolean;
    };
    preJsonParseProcessor?: (data: string) => string;
};

/**
 *
 * @access private
 *
 */
export const HttpOptionsTypedef = {
    url: Types.string.optional,
    headers: Types.instanceStrict(HttpHeaders),
    body: Types.skip, // can be a string or a blob/uint array
    bodyType: Types.string.optional,
    credentials: Types.string.optional,
    method: Types.string.optional,
    mode: Types.string.optional,
    tuningOptions: Types.object({
        ignoreRequestIdValidation: Types.boolean.optional
    }).optional,
    preJsonParseProcessor: Types.function.optional
};

export type HttpCoreMethod =
    | 'GET'
    | 'POST'
    | 'PUT'
    | 'PATCH'
    | 'DELETE'
    | 'HEAD';

export type IEndpoint = {
    href: string;
    method: HttpCoreMethod;
    templated: boolean;
    timeout: number;
    ttl: number;
    headers: {
        Accept?: string;
        [CONTENT_TYPE]?: string;
        [AUTHORIZATION]?: string;
        [REQUEST_ID]?: string;
    };
    optionalHeaders?: Record<string, string>;
    rel?: string;
};

export type IEndpoints<T> = {
    [key in keyof T]: IEndpoint | undefined;
};

/**
 *
 * @typedef {Object} SDK.Services.Providers.GetPayloadResult
 * @property {String} url
 * @property {String} [body]
 * @property {String} [bodyType]
 * @property {HttpHeaders} headers
 * @property {HttpCoreMethod} method
 * @property {String} [credentials]
 * @property {String} [mode]
 *
 */
export type GetPayloadResult = {
    url: string;
    body?: string;
    bodyType?: string;
    headers: HttpHeaders;
    method?: HttpCoreMethod;
    credentials?: string;
    mode?: string;
};

/**
 *
 * @typedef {Object} SDK.Services.Providers.ServerResponse
 * @property {Number} status
 * @property {Headers} headers
 * @property {*} data
 * @property {String} [url]
 * @property {Object} [body]
 * @property {Number} [requestStartTime]
 * @property {Number} [requestEndTime]
 * @property {Number} [requestDuration]
 *
 */
export type ServerResponse<T = TodoAny> = {
    status: number;
    headers: Headers;
    data: T;
    url?: string;
    body?: object;
    requestStartTime?: number;
    requestEndTime?: number;
    requestDuration?: number;
};
