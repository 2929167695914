/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.CancellationType
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} voluntaryCancel - Since `4.13.0` - The service response will return 'VOLUNTARY_CANCEL'.
 * @property {String} involuntaryCancel - Since `4.13.0` - The service response will return 'INVOLUNTARY_CANCEL'.
 * @property {String} autoCancel - Since `4.13.0` - The service response will return 'AUTO_CANCEL'.
 * @property {String} abuseRevocation - Since `4.13.0` - The service response will return 'ABUSE_REVOCATION'.
 * @property {String} unknown - Since `4.13.0` - The service response will return 'UNKNOWN'.
 * @property {String} chargeback - Since `16.0.0` - The service response will return 'CHARGEBACK'.
 *
 */
export enum CancellationType {
    other = 'other',
    voluntaryCancel = 'VOLUNTARY_CANCEL',
    involuntaryCancel = 'INVOLUNTARY_CANCEL',
    autoCancel = 'AUTO_CANCEL',
    abuseRevocation = 'ABUSE_REVOCATION',
    unknown = 'UNKNOWN',
    chargeback = 'CHARGEBACK'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.RiskState
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} grace - Since `4.13.0` - The service response will return 'GRACE'.
 * @property {String} hold - Since `4.13.0` - The service response will return 'HOLD'.
 * @property {String} cancelled - Since `4.13.0` - The service response will return 'CANCELLED'.
 *
 */
export enum RiskState {
    other = 'other',
    grace = 'GRACE',
    hold = 'HOLD',
    cancelled = 'CANCELLED'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.SourceSubType
 * @note Flexible enum
 *
 * @property {String} giftSub - Since `4.13.0` - The service response will return 'GIFT_SUB'.
 * @property {String} giftCard - Since `4.13.0` - The service response will return 'GIFT_CARD'.
 * @property {String} paid - Since `4.13.0` - The service response will return 'PAID'.
 * @property {String} promoRedemption - Since `4.13.0` - The service response will return 'PROMO_REDEMPTION'.
 * @property {String} comp - Since `4.13.0` - The service response will return 'COMP'.
 * @property {String} temp - Since `4.13.0` - The service response will return 'TEMP'.
 * @property {String} initial - Since `4.13.0` - The service response will return 'INITIAL'.
 * @property {String} recurring - Since `4.13.0` - The service response will return 'RECURRING'.
 * @property {String} planSwitched - Since `4.13.0` - The service response will return 'PLAN_SWITCHED'.
 *
 */
export enum SourceSubType {
    giftSub = 'GIFT_SUB',
    giftCard = 'GIFT_CARD',
    paid = 'PAID',
    promoRedemption = 'PROMO_REDEMPTION',
    comp = 'COMP',
    temp = 'TEMP',
    initial = 'INITIAL',
    recurring = 'RECURRING',
    planSwitched = 'PLAN_SWITCHED'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.StackingStatus
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} doubleBilled - Since `4.13.0` - The service response will return 'DOUBLE_BILLED'.
 * @property {String} discounted - Since `4.13.0` - The service response will return 'DISCOUNTED'.
 * @property {String} stacked - Since `4.13.0` - The service response will return 'STACKED'.
 *
 */
export enum StackingStatus {
    other = 'other',
    doubleBilled = 'DOUBLE_BILLED',
    discounted = 'DISCOUNTED',
    stacked = 'STACKED'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.SubscriberPeriod
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} monthly - Since `4.13.0` - The service response will return 'MONTHLY'.
 * @property {String} annual - Since `4.13.0` - The service response will return 'ANNUAL'.
 * @property {String} multiAnnual - Since `4.13.0` - The service response will return 'MULTI_ANNUAL'.
 *
 */
export enum SubscriberPeriod {
    other = 'other',
    monthly = 'MONTHLY',
    annual = 'ANNUAL',
    multiAnnual = 'MULTI_ANNUAL'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.SubscriberStatus
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} active - Since `4.13.0` - The service response will return 'ACTIVE'.
 * @property {String} grace - Since `4.13.0` - The service response will return 'GRACE'.
 * @property {String} hold - Since `4.13.0` - The service response will return 'HOLD'.
 * @property {String} cancelled - Since `4.13.0` - The service response will return 'CANCELLED'.
 * @property {String} paused - Since `4.13.0` - The service response will return 'PAUSED'.
 * @property {String} churned - Since `4.13.0` - The service response will return 'CHURNED'.
 *
 */
export enum SubscriberStatus {
    other = 'other',
    active = 'ACTIVE',
    grace = 'GRACE',
    hold = 'HOLD',
    cancelled = 'CANCELLED',
    paused = 'PAUSED',
    churned = 'CHURNED'
}

/**
 *
 * @enum
 * @since 4.13.0
 * @typedef {Object} SDK.Services.Subscriber.SubscriptionState
 *
 * @property {String} other - Since `4.13.0` - Fallback, catch all value.
 * @property {String} active - Since `4.13.0` - The service response will return 'ACTIVE'.
 * @property {String} grace - Since `4.13.0` - The service response will return 'GRACE'.
 * @property {String} hold - Since `4.13.0` - The service response will return 'HOLD'.
 * @property {String} cancelled - Since `4.13.0` - The service response will return 'CANCELLED'.
 * @property {String} paused - Since `4.13.0` - The service response will return 'PAUSED'.
 * @property {String} churned - Since `4.13.0` - The service response will return 'CHURNED'.
 *
 */
export enum SubscriptionState {
    other = 'other',
    active = 'ACTIVE',
    grace = 'GRACE',
    hold = 'HOLD',
    cancelled = 'CANCELLED',
    paused = 'PAUSED',
    churned = 'CHURNED'
}
