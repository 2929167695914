/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import { BundleType } from '../subscription/enums';

import {
    CancellationType,
    RiskState,
    StackingStatus,
    SubscriberPeriod,
    SubscriberStatus,
    SubscriptionState
} from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Cancellation
 * @since 8.0.0
 * @property {String<SDK.Services.Subscriber.CancellationType>} [type]
 * @property {Boolean} [restartEligible] - Defines whether a user's subscription is eligible to be restarted or not.
 *
 */
export type Cancellation = {
    /**
     *
     * @note Serialized as the property "type".
     *
     */
    type?: CancellationType;
    /**
     *
     * @note If this subscription is eligible for restart or not.
     * @note Some subscriptions cannot be restarted due to business rules.
     * @note Can be returned for any subscription in a `CANCELLED` state.
     * @note Calculated based on the following rules:
     *   1. Any direct billing (D2C) subscription is *eligible* for restart by default.
     *   2. Any non direct billing subscription (not D2C) is *not eligible* for restart.
     *   3. Any direct billing subscription in a paused state is *not eligible* for restart - even if it is also pending cancellation.
     *
     */
    restartEligible?: boolean;
};

/**
 *
 * @access private
 *
 */
export const CancellationTypedef = {
    type: Types.in(CancellationType).optional,
    restartEligible: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.ProductEntitlement
 * @since 8.0.0
 * @property {Number} id
 * @property {String} name
 * @property {String} [desc]
 * @property {String} [partner]
 *
 */
export type ProductEntitlement = {
    id: number;
    name: string;
    desc?: string;
    partner?: string;
};

/**
 *
 * @access private
 *
 */
export const ProductEntitlementTypedef = {
    id: Types.number,
    name: Types.nonEmptyString,
    desc: Types.nonEmptyString.optional,
    partner: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Redeemed
 * @since 8.0.0
 * @property {String} campaignCode
 * @property {String} [redemptionCode]
 * @property {String} voucherCode
 *
 */
export type Redeemed = {
    campaignCode: string;
    redemptionCode?: string;
    voucherCode: string;
};

/**
 *
 * @access private
 *
 */
export const RedeemedTypedef = {
    campaignCode: Types.nonEmptyString,
    redemptionCode: Types.nonEmptyString.optional,
    voucherCode: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Stacking
 * @since 8.0.0
 * @property {String<SDK.Services.Subscriber.StackingStatus>} [status]
 * @property {Array<String>} overlappingSubscriptionProviders
 * @property {Boolean} previouslyStacked
 * @property {String} [previouslyStackedByProvider]
 *
 */
export type Stacking = {
    status?: StackingStatus;
    overlappingSubscriptionProviders: Array<string>;
    previouslyStacked: boolean;
    previouslyStackedByProvider?: string;
};

/**
 *
 * @access private
 *
 */
export const StackingTypedef = {
    status: Types.in(StackingStatus).optional,
    overlappingSubscriptionProviders: Types.array.of.nonEmptyString,
    previouslyStacked: Types.boolean,
    previouslyStackedByProvider: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Term
 * @since 8.0.0
 * @property {Date} [purchaseDate]
 * @property {Date} [startDate]
 * @property {Date} [expiryDate]
 * @property {Date} [nextRenewalDate]
 * @property {Date} [pausedDate]
 * @property {Date} [churnedDate]
 * @property {Boolean} [isFreeTrial]
 *
 */
export type Term = {
    purchaseDate?: Date;
    startDate?: Date;
    expiryDate?: Date;
    nextRenewalDate?: Date;
    pausedDate?: Date;
    churnedDate?: Date;
    isFreeTrial?: boolean;
};

/**
 *
 * @access private
 *
 */
export const TermTypedef = {
    purchaseDate: Types.date.optional,
    startDate: Types.date.optional,
    expiryDate: Types.date.optional,
    nextRenewalDate: Types.date.optional,
    pausedDate: Types.date.optional,
    churnedDate: Types.date.optional,
    isFreeTrial: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Trial
 * @since 8.0.0
 * @property {String} duration
 *
 */
export type Trial = {
    duration: string;
};

/**
 *
 * @access private
 *
 */
export const TrialTypedef = {
    duration: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.NextPhase
 * @since 18.0.0
 * @desc Indicates this subscription will switch to a new offer at next renewal time.
 * @property {String} sku - The product's sku.
 * @property {String} offerId - Offer identifier.
 * @property {String} [promotionId] - The promotion identifier, promotion represents partner specific metadata.
 * @property {String} campaignCode
 * @property {String} voucherCode
 *
 */
export type NextPhase = {
    sku: string;
    offerId: string;
    promotionId?: string;
    campaignCode: string;
    voucherCode: string;
};

/**
 *
 * @access private
 *
 */
export const NextPhaseTypedef = {
    sku: Types.nonEmptyString,
    offerId: Types.nonEmptyString,
    promotionId: Types.nonEmptyString.optional,
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Product
 * @since 8.0.0
 * @property {String} sku
 * @property {Number} id
 * @property {String} [name]
 * @property {Array<Object<SDK.Services.Subscriber.ProductEntitlement>>} entitlements
 * @property {Array<String>} [categoryCodes]
 * @property {Object<SDK.Services.Subscriber.Redeemed>} [redeemed]
 * @property {Boolean} [bundle]
 * @property {String<SDK.Services.Subscriber.SubscriberPeriod>} [subscriptionPeriod]
 * @property {Boolean} [earlyAccess]
 * @property {Object<SDK.Services.Subscriber.Trial>} [trial]
 * @property {String<SDK.Services.Subscriber.BundleType>} [bundleType]
 * @property {Object<SDK.Services.Subscriber.NextPhase>} [nextPhase] - Since 18.0.0 - Indicates this subscription will switch to a new offer at next renewal time.
 *
 */
export type Product = {
    sku: string;
    id: number;
    name?: string;
    entitlements: Array<ProductEntitlement>;
    categoryCodes?: Array<string>;
    redeemed?: Redeemed;
    bundle?: boolean;
    subscriptionPeriod?: SubscriberPeriod;
    earlyAccess?: boolean;
    trial?: Trial;
    bundleType?: BundleType;
    nextPhase?: NextPhase;
};

/**
 *
 * @access private
 *
 */
export const ProductTypedef = {
    sku: Types.nonEmptyString,
    id: Types.number,
    name: Types.nonEmptyString.optional,
    entitlements: Types.array.of.object(ProductEntitlementTypedef),
    categoryCodes: Types.array.of.nonEmptyString.optional,
    redeemed: Types.object(RedeemedTypedef).optional,
    bundle: Types.boolean.optional,
    subscriptionPeriod: Types.in(SubscriberPeriod).optional,
    earlyAccess: Types.boolean.optional,
    trial: Types.object(TrialTypedef).optional,
    bundleType: Types.in(BundleType).optional,
    nextPhase: Types.object(NextPhaseTypedef).optional
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.SubscriptionSource
 * @since 8.0.0
 * @property {String} sourceType
 * @property {String} sourceProvider
 * @property {String} sourceRef
 * @property {String<SDK.Services.Subscriber.SourceSubType>} [subType]
 *
 */
export type SubscriptionSource = {
    sourceType: string;
    sourceProvider: string;
    sourceRef: string;
};

/**
 *
 * @access private
 *
 */
export const SubscriptionSourceTypedef = {
    sourceType: Types.nonEmptyString,
    sourceProvider: Types.nonEmptyString,
    sourceRef: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.Subscription
 * @since 8.0.0
 * @desc Structure used by subscription API v2 (i.e. partners like D+ and S+).
 * @property {String} id
 * @property {String} groupId
 * @property {String<SDK.Services.Subscriber.SubscriptionState>} state
 * @property {String} partner
 * @property {String} paymentProvider
 * @property {Boolean} isEntitled
 * @property {Object<SDK.Services.Subscriber.SubscriptionSource>} source
 * @property {Object<SDK.Services.Subscriber.Product>} product
 * @property {Object<SDK.Services.Subscriber.Term>} term
 * @property {Object<SDK.Services.Subscriber.Cancellation>} [cancellation]
 * @property {Object<SDK.Services.Subscriber.Stacking>} [stacking]
 * @property {Boolean} canCancel
 *
 */
export type Subscription = {
    id: string;
    groupId: string;
    state: SubscriptionState;
    partner: string;
    paymentProvider: string;
    isEntitled: boolean;
    source: SubscriptionSource;
    product: Product;
    term: Term;
    cancellation?: Cancellation;
    stacking?: Stacking;
    canCancel: boolean;
};

/**
 *
 * @access private
 *
 */
export const SubscriptionTypedef = {
    id: Types.nonEmptyString,
    groupId: Types.nonEmptyString,
    state: Types.in(SubscriptionState),
    partner: Types.nonEmptyString,
    paymentProvider: Types.nonEmptyString,
    isEntitled: Types.boolean,
    source: Types.object(SubscriptionSourceTypedef),
    product: Types.object(ProductTypedef),
    term: Types.object(TermTypedef),
    cancellation: Types.object(CancellationTypedef).optional,
    stacking: Types.object(StackingTypedef).optional,
    canCancel: Types.boolean
};

/**
 *
 * @typedef {Object} SDK.Services.Subscriber.SubscriberInfo
 * @since 8.0.0
 * @property {String<SDK.Services.Subscriber.SubscriberStatus>} subscriberStatus
 * @property {String<SDK.Services.Subscriber.RiskState>} [subscriptionAtRisk]
 * @property {Boolean} overlappingSubscription
 * @property {Boolean} doubleBilled
 * @property {Array<String>} [doubleBilledProviders]
 * @property {Array<Object<SDK.Services.Subscriber.Subscription>>} subscriptions
 *
 */
export type SubscriberInfo = {
    subscriberStatus: SubscriberStatus;
    subscriptionAtRisk?: RiskState;
    overlappingSubscription: boolean;
    doubleBilled: boolean;
    doubleBilledProviders?: Array<string>;
    subscriptions: Array<Subscription>;
};

/**
 *
 * @access private
 *
 */
export const SubscriberInfoTypedef = {
    subscriberStatus: Types.in(SubscriberStatus),
    subscriptionAtRisk: Types.in(RiskState).optional,
    overlappingSubscription: Types.boolean,
    doubleBilled: Types.boolean,
    doubleBilledProviders: Types.array.of.nonEmptyString.optional,
    subscriptions: Types.array.of.object(SubscriptionTypedef)
};
