/**
 *
 * @module customerServiceManagerConfiguration
 *
 */

import CustomerServiceClientConfiguration from '../customerService/customerServiceClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing customer service.
 *
 */
export default class CustomerServiceManagerConfiguration extends ServiceConfiguration<CustomerServiceClientConfiguration> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.CustomerServiceManagerConfiguration';
    }
}
