/**
 *
 * @module geoLocation
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Represents a geographic location.
 *
 */
export default class GeoLocation {
    /**
     *
     * @access public
     * @type {Number}
     * @desc The longitude of the location.
     *
     */
    public longitude: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The latitude of the location.
     *
     */
    public latitude: number;

    /**
     *
     * @param {Number} longitude
     * @param {Number} latitude
     *
     */
    public constructor(longitude: number, latitude: number) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                longitude: Types.number,
                latitude: Types.number
            };

            typecheck(this, params, arguments);
        }

        this.longitude = longitude;

        this.latitude = latitude;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.GeoLocation';
    }
}
