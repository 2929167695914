/**
 *
 * @module httpMethod
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.HttpMethod
 *
 * @property {String} GET
 * @property {String} POST
 * @property {String} DELETE
 * @property {String} PATCH
 * @property {String} PUT
 * @property {String} HEAD
 *
 */
enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
    PUT = 'PUT',
    HEAD = 'HEAD'
}
export default HttpMethod;
