/**
 *
 * @module dateRegex
 *
 */

/**
 *
 * @see https://regexr.com/
 * @desc Verifies ISO 8601-compliant date format
 * @example
 * "2020-05-05T00:00:00.920Z"
 *
 */
const dateRegex =
    /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?(Z|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

/**
 *
 * @param {String} dateString
 * @desc tests a string to see if it represents a date that conforms to ISO-8601 standard
 * @returns {Boolean}
 *
 * @example dateRegex('2019-05-09T18:14:21.559Z');
 * @example dateRegex(new Date().toISOString());
 *
 */
export default (dateString: string) => {
    return dateRegex.test(dateString);
};
