import { deepGet } from 'bam-deep-get';

/**
 * @description Add a script to the head
 */
function addScript(src) {
    if (!src) {
        return false;
    }

    const aScript = document.createElement('script');
    aScript.setAttribute('src', src);
    document.head.appendChild(aScript);

    return true;
}

/**
 * @description Init 3rd party scripts that DCF will manage.
 */
function initVendors() {
    const launchScript = deepGet(window, 'espnTrackingConfig.adobeAnalytics.launchScript', null);
    const suppressAdobeLaunch = deepGet(window, 'espnTrackingConfig.suppressAdobeLaunch', false);

    if (!suppressAdobeLaunch) {
        addScript(launchScript);
    }
}

/**
 * @description Setup One Trust for script insertion
 */
export default function setupDCF() {
    if (window.Bootstrapper) {
        initVendors();
    } else {
        //wait and init when TMS is ready
        document.addEventListener('tms.ready', initVendors);
    }
    return true;
}
