/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    AudioRole,
    AssetInsertionStrategyQos,
    InsertionMode,
    InsertionPointPlacement,
    ResolutionMax,
    SubtitleRole,
    SubscriptionType,
    SupportedCodec
} from './enums';

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdSession
 * @since 18.0.0
 * @property {String} id - Since 18.0.0 - Session ID
 * @property {String} [getPodUrl] - Since 18.0.0 - Url to get the ad pod for the GetPod call.
 *
 */
export type AdSession = {
    id: string;
    getPodUrl?: string;
    hasSlugs?: boolean;
    responseCode?: number;
};

/**
 *
 * @access private
 *
 */
export const AdSessionTypedef = {
    id: Types.nonEmptyString,
    getPodUrl: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.InsertionPoint
 * @since 18.0.0
 * @desc Base class for SGAI or SSAI insertion points.
 * @property {String} id - Since 18.0.0 - insertion point ID.
 * @property {Number} offset - Since 18.0.0 - Time of insertion point in ms, relative to the beginning of the primary content.
 * @property {String<SDK.Services.Media.InsertionPointPlacement>} placement - Since 18.0.0 - insertion placement type.
 * @property {Number} plannedSlotCount - Since 18.0.0 - Total number of ad creative slots for a single ssai insertion point.
 * @property {Number} duration - Since 18.0.0 - Duration of combined content of insertion point in ms.
 * @property {Object} content - Since 18.0.0 - Content or ad items to be inserted, represented as SsaiVodAdServiceInsertionPointContent or SsaiVodAuxiliaryInsertionPointContent, determined by `InsertionPointContent.type`.
 * @note Properties `plannedSlotCount`, `duration` and `content` are in the Spec under SsaiVodInsertionPoint.
 *
 */
export type InsertionPoint = {
    id: string;
    offset: number;
    placement: InsertionPointPlacement;
    plannedSlotCount: number;
    duration: number;
    content: Record<string, TodoAny>;
};

/**
 *
 * @access private
 *
 */
export const InsertionPointTypedef = {
    id: Types.nonEmptyString,
    offset: Types.number,
    placement: Types.in(InsertionPointPlacement)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.Editorial
 * @since 24.0.0
 * @desc Editorial marker data for a piece of media.
 * @property {String} label - Since 24.0.0 - The label for this editorial.
 * @property {Number} offsetMillis - Since 24.0.0 - The millisecond offset from media start for this editorial.
 *
 */
export type Editorial = {
    label: string;
    offsetMillis: number;
};

/**
 *
 * @access private
 *
 */
export const EditorialTypedef = {
    label: Types.nonEmptyString,
    offsetMillis: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.Insertion
 * @since 18.0.0
 * @property {String<SDK.Services.Media.InsertionMode>} mode - Since 18.0.0 - ssai or sgai insertion mode.
 * @property {Object<SDK.Services.Media.AdSession>} [adSession] - Since 18.0.0 - Ad session details associated with the requested playback.
 * @property {Array<Object<SDK.Services.Media.InsertionPoint>>} [points] - Since 18.0.0 - Insertion point data represented as `SgaiVodInsertionPoint` or `SsaiVodInsertionPoint`, depending on `Insertion.mode`. This field is optional, its presence depends on the types of media sources included in the response.
 *
 */
export type Insertion = {
    mode: InsertionMode;
    adSession?: AdSession;
    points?: Array<InsertionPoint>;
};

/**
 *
 * @access private
 *
 */
export const InsertionTypedef = {
    mode: Types.in(InsertionMode),
    adSession: Types.object(AdSessionTypedef).optional,
    points: Types.array.of.object(InsertionPointTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.InsertionUrlInfo
 * @since 18.0.0
 * @param {String} [options.base] - Since 18.0.0 - Url without parameters, example: "http://vod-amc-sa-east-s3-1.media.dssott.com/hint"
 * @param {String} [options.queryParams] - Since 18.0.0 - Request parameters, example: "?r=720&v=1"
 *
 */
export type InsertionUrlInfo = {
    base?: string;
    queryParams?: string;
};

/**
 *
 * @access private
 *
 */
export const InsertionUrlInfoTypedef = {
    base: Types.nonEmptyString.optional,
    queryParams: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.ClientDecisions
 * @since 15.0.0
 * @property {Object} clientSettings
 * @property {String} clientGroupIds
 *
 */
export type ClientDecisions = {
    clientSettings: Record<string, unknown>;
    clientGroupIds: string;
};

/**
 *
 * @access private
 *
 */
export const ClientDecisionsTypedef = {
    clientSettings: Types.object().optional,
    clientGroupIds: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.ServerDecisions
 * @since 15.0.0
 * @property {Object} serverSettings
 * @property {String} serverGroupIds
 *
 */
export type ServerDecisions = {
    serverSettings: Record<string, unknown>;
    serverGroupIds: string;
};

/**
 *
 * @access private
 *
 */
export const ServerDecisionsTypedef = {
    serverSettings: Types.object().optional,
    serverGroupIds: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.UrlInfo
 * @since 18.0.0
 * @param {String} [options.base] - Since 18.0.0 - Scheme and host, example: 'https://disney.com'
 * @param {String} [options.path] - Since 18.0.0 - URL path, example: '/beacon'
 * @param {String} [options.queryParams] - Since 18.0.0 - Request parameters, example: '?x=1&y=2'
 * @param {Object} [options.tracking] - Since 18.0.0 - Tracking data
 * @param {String} [options.url] - Since 18.0.0 - Media URL composed on the server side.
 *
 */
export type UrlInfo = {
    base?: string;
    path?: string;
    queryParams?: string;
    tracking?: Record<string, unknown>;
    url?: string;
};

/**
 *
 * @access private
 *
 */
export const UrlInfoTypedef = {
    base: Types.nonEmptyString.optional,
    path: Types.nonEmptyString.optional,
    queryParams: Types.string.optional,
    tracking: Types.nonEmptyObject.optional,
    url: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.SourceInfo
 * @since 18.0.0
 * @param {Number} options.priority - Since 18.0.0 - CDN fallback priority
 * @param {Object<SDK.Services.Media.UrlInfo>} [options.slide] - Since 18.0.0 - `slide` playlist
 * @param {Object<SDK.Services.Media.UrlInfo>} [options.complete] - Since 18.0.0 - `complete` playlist
 * @param {Object<SDK.Services.Media.InsertionUrlInfo>} [options.insertion] - Since 18.0.0 - media insertion URL
 *
 */
export type SourceInfo = {
    priority: number;
    slide?: UrlInfo;
    complete?: UrlInfo;
    insertion?: InsertionUrlInfo;
};

/**
 *
 * @access private
 *
 */
export const SourceInfoTypedef = {
    priority: Types.integer,
    slide: Types.object(UrlInfoTypedef).optional,
    complete: Types.object(UrlInfoTypedef).optional,
    insertion: Types.object(InsertionUrlInfoTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.QosDecisions
 * @since 15.0.0
 * @property {Object<SDK.Services.Media.ClientDecisions>} clientDecisions
 * @property {Object<SDK.Services.Media.ServerDecisions>} serverDecisions
 *
 */
export type QosDecisions = {
    clientDecisions: ClientDecisions;
    serverDecisions: ServerDecisions;
};

/**
 *
 * @access private
 *
 */
export const QosDecisionsTypedef = {
    clientDecisions: Types.object(ClientDecisionsTypedef).optional,
    serverDecisions: Types.object(ServerDecisionsTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.QosDecisionsResponse
 * @since 15.0.0
 * @property {Object} complete
 * @property {String} slide
 *
 */
export type QosDecisionsResponse = {
    complete: QosDecisions;
    slide: QosDecisions;
};

/**
 *
 * @access private
 *
 */
export const QosDecisionsResponseTypedef = {
    complete: Types.object(QosDecisionsTypedef).optional,
    slide: Types.object(QosDecisionsTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdSessionQos
 * @since 18.0.0
 * @desc QoS metrics about the ad session.
 * @property {String} [id] - Since 18.0.0 - The ad session id.
 * @property {Number} responseCode - Since 18.0.0 - The response code from the createAdSession call to the ad server.
 * @property {Boolean} [hasSlugs] - Since 22.0.0 - Indicates whether the playlist contains slugs.
 * @property {String} [errorMessage] - Since 18.0.0 - The error message if the createAdSession call failed.
 *
 */
export type AdSessionQos = {
    id?: string;
    responseCode: number;
    hasSlugs?: boolean;
    errorMessage?: string;
};

/**
 *
 * @access private
 *
 */
export const AdSessionQosTypedef = {
    id: Types.nonEmptyString.optional,
    responseCode: Types.number,
    hasSlugs: Types.boolean.optional,
    errorMessage: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.GetPodsQos
 * @since 18.0.0
 * @desc QoS metrics about the getPods call.
 * @property {Number} responseCode - Since 18.0.0 - The response code from the getPods call to the ad server.
 * @property {String} [errorMessage] - Since 18.0.0 - The error message if the getPods call failed.
 *
 */
export type GetPodsQos = {
    responseCode: number;
    errorMessage?: string;
};

/**
 *
 * @access private
 *
 */
export const GetPodsQosTypedef = {
    responseCode: Types.number,
    errorMessage: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AdsQos
 * @since 18.0.0
 * @desc Ad-related QoS metrics for a piece of media.
 * @property {String<SDK.Services.Media.SubscriptionType>} subscriptionType - Since 18.0.0 - The type of subscription used for this piece of media.
 * @property {String<SDK.Services.Media.AssetInsertionStrategyQos>} assetInsertionStrategy - Since 18.0.0 - The asset insertion strategy used for this piece of media.
 * @property {Object<SDK.Services.Media.AdSessionQos>} [adSession] - Since 18.0.0 - The QOS data from the createAdSession call to the ad service.
 * @property {Object<SDK.Services.Media.GetPodsQos>} [getPods] - Since 18.0.0 - The QOS data from the getPods call to the ad service. (Only for SSAI clients).
 *
 */
export type AdsQos = {
    subscriptionType: SubscriptionType;
    assetInsertionStrategy: AssetInsertionStrategyQos;
    adSession?: AdSessionQos;
    getPods?: GetPodsQos;
};

/**
 *
 * @access private
 *
 */
export const AdsQosTypedef = {
    subscriptionType: Types.in(SubscriptionType),
    assetInsertionStrategy: Types.in(AssetInsertionStrategyQos),
    adSession: Types.object(AdSessionQosTypedef).optional,
    getPods: Types.object(GetPodsQosTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.AudioRendition
 * @since 28.0.0
 * @desc Describes an audio track contained in the stream.
 * @property {String|undefined} [name] - Since 28.0.0 - The name of the audio track.
 * @property {String|undefined} [language] - Since 28.0.0 - The language of the audio track.
 * @property {SDK.Services.Media.AudioRole|undefined} [role] - Since 28.0.0 - Denotes if audio is a narrative version of the language.
 *
 */
export type AudioRendition = {
    name?: string;
    language?: string;
    role?: AudioRole;
};

/**
 *
 * @access private
 *
 */
export const AudioRenditionTypedef = {
    name: Types.nonEmptyString.optional,
    language: Types.nonEmptyString.optional,
    role: Types.in(AudioRole).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.PlaybackSecurity
 * @since 28.0.0
 * @property {String} drmEndpointKey - Since 28.0.0.
 *
 */
export type PlaybackSecurity = {
    drmEndpointKey: string;
};

/**
 *
 * @access private
 *
 */
export const PlaybackSecurityTypedef = {
    drmEndpointKey: Types.nonEmptyString
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.SubtitleRendition
 * @since 28.0.0
 * @desc Describes a subtitle track contained in the stream.
 * @property {String|undefined} [name] - Since 28.0.0 - The name of the subtitle track.
 * @property {String|undefined} [language] - Since 28.0.0 - The language of the subtitle track.
 * @property {Boolean|undefined} [forced] - Since 28.0.0 - Indicates if the subtitle track should be forced to be shown.
 * @property {SDK.Services.Media.SubtitleRole} [role] - Since 28.0.0 - Denotes the type of subtitles available.
 *
 */
export type SubtitleRendition = {
    name?: string;
    language?: string;
    forced?: boolean;
    role?: SubtitleRole;
};

/**
 *
 * @access private
 *
 */
export const SubtitleRenditionTypedef = {
    name: Types.nonEmptyString.optional,
    language: Types.nonEmptyString.optional,
    forced: Types.boolean.optional,
    role: Types.in(SubtitleRole).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.PlaybackRenditions
 * @since 28.0.0
 * @property {Array<AudioRendition>} audio - Since 28.0.0 - Describes an audio track contained in the stream.
 * @property {Array<SubtitleRendition>} subtitles - Since 28.0.0 - Describes a subtitle track contained in the stream.
 *
 */
export type PlaybackRenditions = {
    audio: Array<AudioRendition>;
    subtitles: Array<SubtitleRendition>;
};

/**
 *
 * @access private
 *
 */
export const PlaybackRenditionsTypedef = {
    audio: Types.array.of.object(AudioRenditionTypedef),
    subtitles: Types.array.of.object(SubtitleRenditionTypedef)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.VideoResolution
 * @since 28.0.0
 * @property {Array<ResolutionMax>|undefined} [max] - Since 28.0.0 - `Array` of maximum supported video resolutions for the device.
 *
 */
export type VideoResolution = {
    max?: Array<ResolutionMax>;
};

/**
 *
 * @access private
 *
 */
export const VideoResolutionTypedef = {
    max: Types.array.of.in(ResolutionMax).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.CodecAttributes
 * @since 28.0.0
 * @property {Array<SupportedCodec>|undefined} [video] - Since 28.0.0 - `Array` of the supported video codecs.
 *
 */
export type CodecAttributes = {
    video?: Array<SupportedCodec>;
};

/**
 *
 * @access private
 *
 */
export const CodecAttributesTypedef = {
    video: Types.array.of.in(SupportedCodec).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.PlaybackSelectionTracking
 * @since 28.0.0
 * @property {String|undefined} [playbackSessionId] - Since 28.0.0 - The playback session ID
 *
 */
export type PlaybackSelectionTracking = {
    playbackSessionId?: string;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Services.Media.PlaybackRights
 * @since 28.3.0
 * @property {String} availId - Since `28.3.0` - The EVA identifier for the Avail.
 * @property {Object} tracking - Since `28.3.0`
 *
 */
export type PlaybackRights = {
    availId: string;
    tracking: {
        [key: string]: unknown;
        qoe?: unknown;
        conviva?: unknown;
    };
};

/**
 *
 * @access private
 *
 */
export const PlaybackRightsTypedef = {
    availId: Types.nonEmptyString,
    tracking: Types.object()
};

/**
 *
 * @access private
 *
 */
export const PlaybackSelectionTrackingTypedef = {
    playbackSessionId: Types.nonEmptyString.optional
};
