/**
 *
 * @module mediaClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Media.MediaClientEndpoint
 *
 * @property {String} mediaPayload
 * @property {String} mediaPayloadV5
 * @property {String} key
 * @property {String} playbackCookie
 * @property {String} spriteSheetThumbnails
 * @property {String} spriteSheetThumbnail
 * @property {String} bifThumbnails
 * @property {String} bifThumbnail
 *
 */
enum MediaClientEndpoint {
    mediaPayload = 'mediaPayload',
    mediaPayloadV5 = 'mediaPayloadV5',
    mediaPayloadV6 = 'mediaPayloadV6',
    key = 'key',
    playbackCookie = 'playbackCookie',
    spriteSheetThumbnails = 'spriteSheetThumbnails',
    spriteSheetThumbnail = 'spriteSheetThumbnail',
    bifThumbnails = 'bifThumbnails',
    bifThumbnail = 'bifThumbnail',
    checkProgramBoundaryPlaybackRights = 'checkProgramBoundaryPlaybackRights'
}

export default MediaClientEndpoint;
