/**
 *
 * @module commerceClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Commerce.CommerceClientEndpoint
 *
 * @property {String} priceOrder
 * @property {String} lookupByZipCode
 * @property {String} redeem
 * @property {String} submitOrderWithPaymentMethod
 * @property {String} updateOrder - Since `4.7.0`.
 * @property {String} getOrderStatus
 * @property {String} createPaymentMethod
 * @property {String} getDdcJwtToken - Since `4.8.0`.
 * @property {String} getDefaultPaymentMethod
 * @property {String} getPaymentMethod
 * @property {String} updatePaymentMethod
 * @property {String} listAllPaymentMethods
 * @property {String} createPaymentMethodEast
 * @property {String} createPaymentMethodWest
 * @property {String} createKlarnaPaymentMethod - Since `4.5.0`.
 * @property {String} resumeAccount - Since `4.7.0`.
 * @property {String} getPayPalCheckoutDetails - Since `4.8.0`.
 * @property {String} setPayPalCheckoutDetails - Since `4.8.0`.
 * @property {String} createIDealPaymentMethod - Since `4.11.0`.
 * @property {String} getIDealPaymentMethod - Since `4.11.0`.
 * @property {String} submitMercadoPayment - Since `4.19.0`.
 * @property {String} submitComcastPayment - Since `5.0.0`.
 * @property {String} planSwitch - Since `5.0.0`.
 * @property {String} submitIDealPayment
 * @property {String} shareDefaultPaymentMethod - Since `8.0.0`.
 * @property {String} restartSubscription - Since `9.0.0`.
 * @property {String} createComcastPaymentMethod - Since `10.1.0`.
 * @property {String} getComcastConsent - Since `10.1.0`.
 * @property {String} associateAuthValuesWithPaymentMethod - Since `12.0.0`.
 * @property {String} createPayPalBraintreeClientToken - Since `21.0.0`.
 * @property {String} getPayPalBraintreePaymentMethodId - Since `21.0.0`.
 * @property {String} createPayPalBraintreePaymentMethod - Since `21.0.0`.
 * @property {String} updatePayPalBraintreePaymentMethod - Since `21.0.0`.
 *
 */
enum CommerceClientEndpoint {
    priceOrder = 'priceOrder',
    lookupByZipCode = 'lookupByZipCode',
    redeem = 'redeem',
    submitOrderWithPaymentMethod = 'submitOrderWithPaymentMethod',
    updateOrder = 'updateOrder',
    getOrderStatus = 'getOrderStatus',
    createPaymentMethod = 'createPaymentMethod',
    getDdcJwtToken = 'getDdcJwtToken',
    getDefaultPaymentMethod = 'getDefaultPaymentMethod',
    getPaymentMethod = 'getPaymentMethod',
    updatePaymentMethod = 'updatePaymentMethod',
    listAllPaymentMethods = 'listAllPaymentMethods',
    createPaymentMethodEast = 'createPaymentMethodEast',
    createPaymentMethodWest = 'createPaymentMethodWest',
    createKlarnaPaymentMethod = 'createKlarnaPaymentMethod',
    resumeAccount = 'resumeAccount',
    getPayPalCheckoutDetails = 'getPayPalCheckoutDetails',
    setPayPalCheckoutDetails = 'setPayPalCheckoutDetails',
    createIDealPaymentMethod = 'createIDealPaymentMethod',
    getIDealPaymentMethod = 'getIDealPaymentMethod',
    submitMercadoPayment = 'submitMercadoPayment',
    submitComcastPayment = 'submitComcastPayment',
    planSwitch = 'planSwitch',
    submitIDealPayment = 'submitIDealPayment',
    shareDefaultPaymentMethod = 'shareDefaultPaymentMethod',
    restartSubscription = 'restartSubscription',
    createComcastPaymentMethod = 'createComcastPaymentMethod',
    getComcastConsent = 'getComcastConsent',
    associateAuthValuesWithPaymentMethod = 'associateAuthValuesWithPaymentMethod',
    createPayPalBraintreeClientToken = 'createPayPalBraintreeClientToken',
    getPayPalBraintreePaymentMethodId = 'getPayPalBraintreePaymentMethodId',
    createPayPalBraintreePaymentMethod = 'createPayPalBraintreePaymentMethod',
    updatePayPalBraintreePaymentMethod = 'updatePayPalBraintreePaymentMethod'
}

export default CommerceClientEndpoint;
