/**
 *
 * @module ripcutClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/ripcut.md
 *
 */

/* eslint-disable camelcase */

import { Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from '../../logging/logger';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import appendQuerystring from '../util/appendQuerystring';
import replaceUrl from '../util/replaceUrl';

import RipcutImageRequestMode from '../../ripcut/ripcutImageRequestMode';

import RipcutClientEndpoint from './ripcutClientEndpoint';
import RipcutClientConfiguration from './ripcutClientConfiguration';

import {
    RipcutQueryParams,
    RipcutQueryParamsTypedef
} from '../../ripcut/typedefs';

import HttpHeaders from '../providers/shared/httpHeaders';
import replaceHeaders from '../util/replaceHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';
import { SDK_ERROR } from '../providers/shared/httpHeaderConstants';

/**
 *
 * @access protected
 * @since 17.0.0
 * @desc Most content images are fetched from a backend service called Ripcut.
 *
 */
export default class RipcutClient extends ClientBase<RipcutClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Ripcut.RipcutClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: RipcutClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(RipcutClientConfiguration),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} [options.version]
     * @param {String} options.imageId
     * @param {SDK.Ripcut.RipcutImageRequestMode} options.imageRequestMode
     * @param {String} options.partnerId
     * @param {Object<RipcutQueryParams>} [options.queryParams]
     * @returns {Promise<SDK.Services.Ripcut.RipcutResult>} A promise that completes when the operation has succeeded.
     *
     */
    public async getImage(options: {
        version?: string;
        imageId: string;
        imageRequestMode: RipcutImageRequestMode;
        partnerId: string;
        queryParams?: RipcutQueryParams;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    version: Types.nonEmptyString.optional,
                    imageId: Types.nonEmptyString,
                    imageRequestMode: Types.in(RipcutImageRequestMode),
                    partnerId: Types.nonEmptyString,
                    queryParams: Types.object(RipcutQueryParamsTypedef).optional
                })
            };

            typecheck(this, 'getImage', params, arguments);
        }

        const {
            imageId,
            imageRequestMode,
            partnerId,
            queryParams,
            version = 'v1'
        } = options;

        const { logger } = this;

        const endpointKey = RipcutClientEndpoint[imageRequestMode];

        const payload = this.getPayload({
            endpointKey,
            data: {
                version,
                imageId,
                partnerId,
                queryParams
            },
            bodyType: 'arrayBuffer'
        });

        return super.request({
            payload,
            resultMapper: (response) => {
                const { data: image, headers } = response;

                const headerError = headers.get(SDK_ERROR);

                let error;

                try {
                    error = JSON.parse(headerError as string);
                } catch (ex) {
                    logger.error(
                        this.toString(),
                        `Could not parse error from "${SDK_ERROR}" header.`
                    );

                    error = null;
                }

                return {
                    image,
                    error
                };
            }
        });
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {String} [options.version]
     * @param {String} options.imageId
     * @param {SDK.Ripcut.RipcutImageRequestMode} options.imageRequestMode
     * @param {String} options.partnerId
     * @param {Object<SDK.Ripcut.RipcutQueryParams>} [options.queryParams]
     * @returns {String}
     *
     */
    public getImageUrl(options: {
        version?: string;
        imageId: string;
        imageRequestMode: RipcutImageRequestMode;
        partnerId: string;
        queryParams?: RipcutQueryParams;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    version: Types.nonEmptyString.optional,
                    imageId: Types.nonEmptyString,
                    imageRequestMode: Types.in(RipcutImageRequestMode),
                    partnerId: Types.nonEmptyString,
                    queryParams: Types.object(RipcutQueryParamsTypedef).optional
                })
            };

            typecheck(this, 'getImageUrl', params, arguments);
        }

        const {
            imageId,
            imageRequestMode,
            partnerId,
            queryParams,
            version = 'v1'
        } = options;

        const endpointKey = RipcutClientEndpoint[imageRequestMode];

        const { url } = this.getPayload({
            endpointKey,
            data: {
                version,
                imageId,
                partnerId,
                queryParams
            }
        });

        return url;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Ripcut.RipcutClientEndpoint} options.endpointKey
     * @param {Object} options.data
     * @param {Object} [options.bodyType]
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        endpointKey: RipcutClientEndpoint;
        data: {
            version: string;
            imageId: string;
            partnerId: string;
            queryParams?: RipcutQueryParams & { max?: string };
        };
        bodyType?: string;
    }) {
        const { endpointKey, data, bodyType } = options;
        const { imageId, partnerId, queryParams, version } = data;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { headers, method } = endpoint;

        // In the case of '/trim' we translate width/height into the trim's max query param and delete the width/height
        if (
            endpointKey === RipcutClientEndpoint.trim &&
            queryParams?.width &&
            queryParams.height
        ) {
            queryParams.max = `${queryParams.width}|${queryParams.height}`;
            delete queryParams.width;
            delete queryParams.height;
        }

        const href = replaceUrl(endpoint.href, { imageId, partnerId, version });

        const url = appendQuerystring(href, stringify(queryParams));

        const requestHeaders = replaceHeaders({}, headers);

        return {
            url,
            headers: new HttpHeaders(requestHeaders),
            method,
            bodyType
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Ripcut.RipcutClient';
    }
}
