/**
 *
 * @module mapHiveToQoeErrorCode
 *
 */

import { DSSHLSError } from '../../media/typedefs';
import { QoePlaybackError } from './enums';

/**
 *
 * @access private
 * @since 16.0.0
 * @type {Object}
 * @desc Maps error causes to Quality of Service QoePlaybackError codes
 *
 */
const ErrorCodeMap: Record<string | number, QoePlaybackError> = {
    // Network Errors
    5101: QoePlaybackError.networkFailure, // Device network error, usually related to DNS or the device network stack
    5102: QoePlaybackError.networkFailure, // Request timeout
    5103: QoePlaybackError.networkFailure, // Timeout while waiting for response headers
    5104: QoePlaybackError.networkFailure, // Timeout while downloading payload data
    5105: QoePlaybackError.networkFailure, // The underlying system unexpectedly aborted the request
    5106: QoePlaybackError.networkFailure, // HTTP server error (HTTP 5xx status code)
    5107: QoePlaybackError.networkFailure, // Resource not found error (HTTP 404 or 410)
    5108: QoePlaybackError.networkFailure, // Network authentication error (HTTP 401 or 403)
    5109: QoePlaybackError.networkFailure, // Unexpected HTTP response code
    5299: QoePlaybackError.networkFailure, // Content network fallback limit reached. Stream failed to start
    5401: QoePlaybackError.networkFailure, // The CDN fallback attempts limit was reached
    5411: QoePlaybackError.networkFailure, // Slow download progress
    // PQM Errors
    5400: QoePlaybackError.networkFailure, // Unknown PQM download failure
    5412: QoePlaybackError.networkFailure, // Long RTT of download progress
    5203: QoePlaybackError.networkFailure, // There was a problem buffering content
    5204: QoePlaybackError.networkFailure, // Presentation start timeout expired
    5206: QoePlaybackError.networkFailure, // Playback stalled; Buffering timeout expired; root cause unknown
    5286: QoePlaybackError.networkFailure, // Playback stalled; Buffering timeout expired; empty buffer
    5296: QoePlaybackError.networkFailure, // Playback stalled; Buffering timeout expired; low buffer

    // DRM Errors
    5300: QoePlaybackError.drmDecryption, // Unexpected media decryption error
    5301: QoePlaybackError.drmDecryption, // Decryption initialization failed
    5302: QoePlaybackError.drmOther, // CDM provisioning request failed
    5303: QoePlaybackError.drmDecryption, // Decryption key system configuration failed
    5304: QoePlaybackError.drmDecryption, // Decryption key system configuration is not supported
    5305: QoePlaybackError.drmOther, // Device network error during license request
    5306: QoePlaybackError.drmOther, // Request timeout during license request
    5307: QoePlaybackError.drmOther, // Response headers during license request
    5308: QoePlaybackError.drmOther, // Response body timeout during license request
    5309: QoePlaybackError.drmOther, // The underlying system unexpectedly aborted the license request
    5310: QoePlaybackError.drmOther, // HTTP server error (HTTP 5xx status code) during license request
    5311: QoePlaybackError.drmOther, // Resource not found error (HTTP 404 or 410) during license request
    5312: QoePlaybackError.drmOther, // Network authentication error (HTTP 401 or 403) during license request
    5313: QoePlaybackError.drmOther, // Unexpected HTTP response code during license request
    5314: QoePlaybackError.drmDecryption, // Content decryption module failure
    5315: QoePlaybackError.drmHdcp, // Decrypted output (HDCP) error
    5316: QoePlaybackError.drmDecryption, // Decryption certificate error
    5317: QoePlaybackError.drmDecryption, // Decryption module initialization failed
    5318: QoePlaybackError.drmOther, // Requester blacklisted by license server
    5319: QoePlaybackError.drmOther, // Requester downgraded by license server
    5320: QoePlaybackError.drmSecurityLevel, // Insufficient security level
    5321: QoePlaybackError.drmOther, // Parental controls restricted license failure
    5322: QoePlaybackError.upgradeRequired, // Platform client upgrade required
    5399: QoePlaybackError.drmOther, // Received an invalid license

    // Unexpected Errors
    5900: QoePlaybackError.unexpectedError, // Unexpected exception in a client application event handler
    5901: QoePlaybackError.unexpectedError, // Unexpected exception with no known cause
    5903: QoePlaybackError.unexpectedError, // A feature has been requested but is not available
    5904: QoePlaybackError.unexpectedError, // The master playlist could not be interpreted
    5905: QoePlaybackError.unexpectedError, // A media playlist could not be interpreted
    5940: QoePlaybackError.unexpectedError, // Unexpected exception during an audio track change
    5950: QoePlaybackError.unexpectedError, // Unexpected exception during subtitle processing
    5951: QoePlaybackError.unexpectedError, // Unexpected exception during a seek operation
    5952: QoePlaybackError.unexpectedError, // Unexpected exception while starting the player
    5953: QoePlaybackError.unexpectedError, // Unexpected exception while stopping the player
    5954: QoePlaybackError.unexpectedError, // Unexpected exception while buffering content
    5955: QoePlaybackError.unexpectedError, // Unexpected exception while downloading content

    // PQM Errors
    5499: QoePlaybackError.unexpectedError // Unexpected PQM internal error

    /**
     *
     * Fall back is always unknown so leave these undefined

    // Playback Errors
    5200: QoePlaybackError.unknown, // The content cannot is not compatible with this device
    5201: QoePlaybackError.unknown, // There was a problem while decoding and rendering content

    */
};

/**
 *
 * @access public
 * @since 16.0.0
 * @param {DSSHLSError} error - The cause of the playback error
 * @desc Maps an error during playback to a Quality of Service error code for reporting
 * @returns {String<QoePlaybackError>} The Quality of Service error code for the given error
 *
 */
export default function mapHiveToQoeErrorCode(error: DSSHLSError) {
    const { code } = error || {};

    const result = ErrorCodeMap[String(code)] || QoePlaybackError.unknown;

    return result;
}
