/**
 *
 * @module paywallClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/paywall.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/paywall.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import Logger from '../../logging/logger';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import Assertion from './assertion';
import Paywall from './paywall';
import PaywallClientConfiguration from './paywallClientConfiguration';
import PaywallClientEndpoint from './paywallClientEndpoint';
import Product from './product';
import LogTransaction from '../../logging/logTransaction';

import appendQuerystring from '../util/appendQuerystring';

import AccessToken from '../token/accessToken';
import PurchaseContext from './purchaseContext';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const PaywallClientDustUrnReference =
    DustUrnReference.services.paywall.paywallClient;

/**
 *
 * @access protected
 * @since 3.9.0
 * @desc Provides a data client that can be used to access paywall services.
 *
 */
export default class PaywallClient extends ClientBase<PaywallClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Paywall.PaywallClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: PaywallClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(PaywallClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.9.0
     * @param {SDK.Services.Token.AccessToken} accessToken
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @param {String} [purchaseContextId]
     * @param {String<SDK.Services.Paywall.PurchaseContext>} [purchaseContext]
     * @desc Retrieves paywall information contextually sensitive to the environment and requester.
     * @note PurchaseContext is a flexible enum.
     * @returns {Promise<SDK.Services.Paywall.Paywall>}
     *
     */
    public getPaywall(
        accessToken: AccessToken,
        logTransaction: LogTransaction,
        purchaseContextId?: string,
        purchaseContext?: PurchaseContext
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction),
                purchaseContextId: Types.nonEmptyString.optional,
                purchaseContext: Types.nonEmptyString.optional
            };

            typecheck(this, 'getPaywall', params, arguments);
        }

        const { logger } = this;

        const endpointKey = PaywallClientEndpoint.paywall;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                purchaseContext,
                purchaseContextId
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: PaywallClientDustUrnReference.getPaywall,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;
                const {
                    paywallHash,
                    skus,
                    reason,
                    context,
                    assertions,
                    currentSubscription
                } = data;

                const products = skus.map((sku: TodoAny) => Product.parse(sku));
                const processedAssertions = assertions.map(
                    (item: TodoAny) => new Assertion(item)
                );

                const paywall = new Paywall({
                    accountEntitlementContext: context,
                    paywallHash,
                    products,
                    assertions: processedAssertions,
                    reason,
                    currentSubscription
                });

                return paywall;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken]
     * @param {SDK.Services.Paywall.PaywallClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @param {String} [options.bodyType] - The expected response data type, executed after initial JSON attempt.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken?: AccessToken;
        endpointKey: PaywallClientEndpoint;
        data?: Record<string, unknown>;
        body?: Record<string, unknown>;
        bodyType?: string;
    }) {
        const { accessToken, endpointKey, body, data = {}, bodyType } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, headers, method } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        const url = appendQuerystring(href, queryString.stringify(data));

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: (accessToken as AccessToken).token
                    };
                }
            },
            headers
        );

        return {
            url,
            method,
            body: requestBody,
            bodyType,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Paywall.PaywallClient';
    }
}
