/* eslint-disable custom-rules/inferred-return-type */

/**
 *
 * @module playbackMetricsProvider
 *
 */

import { EventEmitter } from 'events';
import { Types, typecheck } from '@dss/type-checking';

import MediaSource from './mediaSource';
import PlaybackMetrics from './playbackMetrics';

import {
    PlaybackMode,
    PlaybackState
} from '../services/qualityOfService/enums';

import { SeekData, SeekDataTypedef } from './typedefs';

/**
 *
 * @see https://nodejs.org/api/events.html
 * @since 2.0.0
 *
 */
export default class PlaybackMetricsProvider extends EventEmitter {
    /**
     *
     * @access public
     * @type {String|undefined}
     * @since 7.0.0
     * @desc Used to indicate which stream url is currently being played in `cdnFallback` for QoE purposes.
     *
     */
    public currentStreamUrl?: string;

    /**
     *
     * @access public
     * @type {String|undefined|Array<SDK.Media.MediaSource>}
     * @since 2.0.0
     * @desc can be either a string or array of objects for backwards compatibility purposes.
     *
     */
    public playlistUri?: string | Array<MediaSource>;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @since 4.0.0
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @since 4.0.0
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access protected
     * @since 18.0.0
     * @type {Number}
     * @desc Used to track total time of media downloaded (gets reset with each heartbeat).
     * @note Used internally by the PlaybackTelemetryDispatcher
     *
     */
    public mediaDownloadTotalTime: number;

    /**
     *
     * @access protected
     * @since 18.0.0
     * @type {Number}
     * @desc Used to track how many times media gets downloaded (gets reset with each heartbeat).
     * @note Used internally by the PlaybackTelemetryDispatcher
     *
     */
    public mediaDownloadTotalCount: number;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {String<SDK.Services.QualityOfService.PlaybackMode>|undefined}
     * @desc Stores the initial `PlaybackMode` for the player.
     *
     */
    public initialPlaybackMode?: PlaybackMode;

    public constructor() {
        super();

        this.playlistUri = '';
        this.currentStreamUrl = '';
        this.mediaDownloadTotalTime = 0;
        this.mediaDownloadTotalCount = 0;

        this.seekData = {} as SeekData;
    }

    /**
     *
     * @access public
     * @desc Gets a snapshot of information about media playback.
     * @returns {PlaybackMetrics} - instance that contains a snapshot
     * of information about media playback.
     *
     */
    // eslint-disable-next-line custom-rules/inferred-return-type
    public getPlaybackMetrics(): PlaybackMetrics {
        throw new Error(
            `${this.toString()}.getPlaybackMetrics() - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @param {PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    // @ts-expect-error Property 'addListener' in type 'PlaybackMetricsProvider' is not assignable to the same property in base type 'EventEmitter'.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public addListener(listener: TodoAny) {
        throw new Error(
            `${this.toString()}.addListener(listener) - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @param {PlaybackEventListener} listener
     * @returns {Void}
     *
     */
    // @ts-expect-error Property 'removeListener' in type 'PlaybackMetricsProvider' is not assignable to the same property in base type 'EventEmitter'.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public removeListener(listener: TodoAny) {
        throw new Error(
            `${this.toString()}.removeListener(listener) - not-implemented`
        );
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher`. Implemented in each `PlayerAdapter` instance.
     * @returns {Object|undefined}
     *
     */
    public getHeartbeatData(): object | undefined {
        return {};
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher`. Implemented in each `PlayerAdapter` instance.
     * @returns {String}
     *
     */
    public getCdnName() {
        return 'null';
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher. Implemented in each `PlayerAdapter` instance.
     * @returns {Number|undefined}
     *
     */
    public getAudioBitrate(): number | undefined {
        return 0;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Helper method used by the `PlaybackTelemetryDispatcher. Implemented in each `PlayerAdapter` instance.
     * @returns {Number|undefined}
     *
     */
    public getMaxAllowedVideoBitrate(): number | undefined {
        return 0;
    }

    /**
     *
     * @access protected
     * @since 27.1.0
     * @desc Returns playback state enum.
     * @returns {PlaybackState|undefined}
     *
     */
    public getPlaybackState(): PlaybackState | undefined {
        return undefined;
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SeekData|undefined}
     * @desc set by the application via this.setSeekData();
     *
     */
    protected seekData?: SeekData;

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} data
     * @param {Number} data.seekSize - Indication that the user is seeking by a fixed time (size) e.g. +30, -30. This value is expected to be in seconds.
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [data.seekDirection] - Used for Live events
     * @param {String<SDK.Services.QualityOfService.PlaybackSeekCause>} data.seekCause
     * @desc sets the seekData property provided by the application.
     *
     */
    public setSeekData(seekData: SeekData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                seekData: Types.object(SeekDataTypedef)
            };

            typecheck.warn(this, 'setSeekData', params, arguments);
        }

        this.seekData = seekData;
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the time when the player has started to be initialized.
     *
     */
    public setInitializePlayerStartTime() {
        throw new Error(
            `${this.toString()}.setInitializePlayerStartTime() - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the duration for how long the player took to be initialized.
     *
     */
    public setInitializePlayerDuration() {
        throw new Error(
            `${this.toString()}.setInitializePlayerDuration() - not-implemented`
        );
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @desc returns the seekData property provided by the application.
     * @note this method is used by the `PlaybackTelemetryDispatcher` to send specific `SeekData` properties to QoE and must be provided by the application via the `setSeekData` method.
     * @returns {SeekData}
     *
     */
    public getSeekData() {
        return this.seekData || ({} as SeekData);
    }

    public clean() {
        this.seekData = undefined;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlaybackMetricsProvider';
    }
}
