/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

/**
 *
 * @typedef {Object} SDK.Flex.FlexOptions
 * @since 26.0.0
 * @property {String<SDK.Services.Flex.FlexClientEndpoint>} key - Used to determine the endpoint to hit. EX: "billingHistory", "subscriptionSummary", "cancelFlow"
 * @property {String} version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
 * @property {Object} [queryParams] - Since `29.0.0` - Parameters to be applied as query params to a request.
 * @property {Object} [requestBody] - Since `29.0.0` - Flex parameters to send as POST/PUT data with a request. The values from the map have to be JSON serializable.
 * @property {Function} [configOverride] - Override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen,
 * "execution" for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or
 * "execution" href value, changing the HTTP method, etc...
 * @property {Object} [headers] - Since `26.0.0` - Client-provided request headers.
 *
 */
export type FlexOptions = {
    key: string;
    version: string;
    queryParams?: Record<string, unknown>;
    requestBody?: Record<string, unknown>;
    configOverride?: (arg: TodoAny) => TodoAny;
    headers?: Record<string, string>;
};

/**
 *
 * @access private
 *
 */
export const FlexOptionsTypedef = {
    key: Types.nonEmptyString,
    version: Types.nonEmptyString,
    queryParams: Types.object().optional,
    requestBody: Types.object().optional,
    configOverride: Types.function.optional,
    headers: Types.object().optional
};
