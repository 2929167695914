/**
 *
 * @module horaValidationSource
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 14.0.0
 * @typedef {Object} SDK.Services.Internal.Telemetry.HoraValidationSource
 * @desc Specifies the source of the Hora validation response
 * @note Flexible enum
 *
 * @property {String} hora - Since `14.0.0`.
 * @property {String} sdp - Since `14.0.0`.
 * @property {String} root - Since `14.0.0`.
 * @property {String} edsMl - Since `16.0.0`.
 *
 * @example
 * SDK.Services.Internal.Telemetry.HoraValidationSource.hora // 'Hora'
 *
 */
export default {
    hora: 'Hora',
    sdp: 'Sdp',
    root: 'Root',
    edsMl: 'EdsMl'
};
