/**
 *
 * @module accessContextState
 *
 */

/**
 *
 * @desc The modes or permissions the token provides.
 *
 */
export default class AccessContextState {
    /**
     *
     * @access private
     * @type {Array<string>}
     * @desc Array of strings representing enabled / authenticated providers
     *
     */
    private modes: Array<string>;

    /**
     *
     * @param {Array} [modes=[]]
     *
     */
    public constructor(modes: Array<string> = []) {
        this.modes = modes;
    }

    /**
     *
     * @access public
     * @param {String} mode
     * @returns {Boolean}
     *
     */
    public hasMode(mode: string) {
        return this.modes.includes(mode);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.AccessContextState';
    }
}
