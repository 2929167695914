/**
 *
 * @module baseEnvironmentConfiguration
 *
 */

import VersionInfo from '../../versionInfo';
import type Device from './device';
import { sdkConfigVersion } from '../../_utils/bam-browser-sdk-configuration-util';

/**
 *
 * @access protected
 * @abstract
 * @since 4.8.0
 * @desc Provides configuration information that describes the current environment
 * and is used by the `ConfigurationClient` to retrieve the `SdkSessionConfiguration`
 * necessary to create an `SdkSession` instance.
 *
 */
export default abstract class BaseEnvironmentConfiguration {
    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the application runtime.
     *
     */
    public applicationRuntime: string;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String}
     * @desc Gets or sets the application runtime config segment.
     *
     */
    public applicationRuntimeConfigSegment: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the application version.
     * @note Used to populate the `DustApplicationPayload.version` field and application headers.
     *
     */
    public applicationVersion: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the device family (e.g. "browser").
     * @note Used in constructing the configuration url. This is the main SDK platform identifier.
     *
     */
    public deviceFamily: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     * @desc Override identifier for device family.
     * @note Used in cases where a different deviceFamily override is wanted,
     * for example, when constructing the SDK platform common header.
     *
     */
    private deviceFamilyOverride: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the device profile (e.g. "desktop", "sony").
     *
     */
    public deviceProfile: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the device runtime profile.
     * @note Optional internal use by some platforms
     *
     */
    public deviceRuntimeProfile: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Gets or sets the configuration version (e.g. "5.0").
     *
     */
    public configVersion: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public platform: string;

    /**
     *
     * @access private
     * @type {Object}
     *
     */
    public device: Record<string, TodoAny>;

    /**
     *
     * @param {Object} options
     * @param {String} [options.applicationRuntime]
     * @param {String} [options.applicationRuntimeConfigSegment]
     * @param {String} [options.applicationVersion]
     * @param {String} [options.platform]
     * @param {String} [options.deviceFamily]
     * @param {String} [options.deviceProfile]
     * @param {String} [options.deviceRuntimeProfile]
     * @param {String} [options.configVersion]
     * @param {Object} options.device
     *
     */
    public constructor(options: {
        applicationRuntime?: string;
        applicationRuntimeConfigSegment?: string;
        applicationVersion?: string;
        platform?: string;
        deviceFamily?: string;
        deviceProfile?: string;
        deviceRuntimeProfile?: string;
        configVersion?: string;
        device: Device;
    }) {
        const {
            applicationRuntime,
            applicationRuntimeConfigSegment,
            applicationVersion,
            platform,
            deviceFamily,
            deviceProfile,
            deviceRuntimeProfile,
            configVersion,
            device
        } = options || {};

        this.applicationRuntime = applicationRuntime || '';
        this.applicationVersion = applicationVersion || '';
        this.deviceFamily = deviceFamily || 'browser';
        this.deviceFamilyOverride = 'javascript';
        this.deviceProfile = deviceProfile || '';
        this.deviceRuntimeProfile = deviceRuntimeProfile || '';
        this.configVersion = configVersion || sdkConfigVersion;
        this.platform = platform || '';
        this.device = device;
        this.applicationRuntimeConfigSegment =
            applicationRuntimeConfigSegment || this.applicationRuntime;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     * @desc Constructed platform identifier `String`.
     * @note For example: `javascript/macosx/chrome`, `javascript/samsung/tizen`, etc...
     *
     */
    public get constructedPlatformIdentifier() {
        return `${this.deviceFamilyOverride}/${
            this.deviceProfile || 'fallbackDeviceProfile'
        }/${this.applicationRuntime || 'fallbackApplicationRuntime'}`;
    }

    /**
     *
     * @access public
     * @since 4.8.0
     * @type {String}
     * @desc Gets the shortened SDK version (e.g. "4.8").
     *
     */
    public get sdkVersion() {
        return VersionInfo.versionShort;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.BaseEnvironmentConfiguration';
    }
}
