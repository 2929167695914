/**
 *
 * @module initializationState
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { AccessTokenType } from 'sdk-types/types/orchestration-enums';

import DeviceGrant from './services/token/deviceGrant';
import Access from './token/access';
import AccessContextState from './token/accessContextState';
import SessionInfo from './services/session/sessionInfo';
import AccessContext from './services/token/accessContext';

/**
 *
 * @access public
 * @since 9.0.0
 *
 */
export default class InitializationState {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {SDK.Services.Device.DeviceGrant}
     *
     */
    public deviceGrant: DeviceGrant;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {SDK.Token.Access}
     *
     */
    public access: Access;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {SDK.Services.Session.SessionInfo}
     *
     */
    public sessionInfo: SessionInfo;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String|null}
     * @note Storage and services return null by default
     *
     */
    public accountDelegationRefreshToken: Nullable<string>;

    /**
     *
     * @access public
     * @since 26.0.0
     * @type {Object}
     *
     */
    public featureFlags: Record<string, unknown>;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Device.DeviceGrant} options.deviceGrant
     * @param {SDK.Token.Access} options.access
     * @param {SDK.Services.Session.SessionInfo} options.sessionInfo
     * @param {String|null} [options.accountDelegationRefreshToken=null]
     * @param {Object} options.featureFlags
     *
     */
    public constructor(options: {
        deviceGrant: DeviceGrant;
        access: Access;
        sessionInfo: SessionInfo;
        accountDelegationRefreshToken: Nullable<string>;
        featureFlags: Record<string, unknown>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    deviceGrant: Types.instanceStrict(DeviceGrant),
                    access: Types.instanceStrict(Access),
                    sessionInfo: Types.instanceStrict(SessionInfo),
                    accountDelegationRefreshToken:
                        Types.nonEmptyString.optional,
                    featureFlags: Types.object()
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            deviceGrant,
            access,
            sessionInfo,
            accountDelegationRefreshToken,
            featureFlags
        } = options;

        this.deviceGrant = deviceGrant;
        this.access = access;
        this.sessionInfo = sessionInfo;
        this.accountDelegationRefreshToken =
            accountDelegationRefreshToken || null;
        this.featureFlags = featureFlags;
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object} options
     * @param {Object} options.sdk - The data.extensions.sdk object returned from orchestration api.
     * @param {String} [options.region] - Region provided by the response header given on registerDevice orchestration mutation.
     * @param {String} [options.provider] - the provider string used as an `AccessContextState` mode.
     * @desc Factory helper to create an `InitializationState` class in the use-case of having orchestration extension data.
     * @returns {InitializationState}
     *
     */
    public static createWithSdkExtensionInfo(options: {
        sdk: {
            grant: {
                device: {
                    grantType: string;
                    assertion: string;
                };
            };
            accountDelegationRefreshToken: Nullable<{
                token: string;
            }>;
            token: {
                accessToken: string;
                tokenType: string;
                refreshToken: string;
                expiresIn: number;
                accessTokenType: AccessTokenType;
            };
            session: unknown;
            featureFlags?: Record<string, unknown>;
        };
        region: string;
        provider: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sdk: Types.object({
                        grant: Types.object({
                            device: Types.object()
                        }),
                        session: Types.nonEmptyObject,
                        featureFlags: Types.object().optional,
                        token: Types.object(),
                        accountDelegationRefreshToken: Types.object({
                            tokenType: Types.nonEmptyString,
                            token: Types.nonEmptyString
                        }).optional
                    }),
                    region: Types.nonEmptyString.optional,
                    provider: Types.nonEmptyString.optional
                })
            };

            typecheck(this, 'createWithSdkExtensionInfo', params, arguments);
        }

        const { region, sdk, provider } = options;
        const { grantType, assertion } = sdk.grant.device;
        const { token: accountDelegationRefreshToken = null } =
            sdk.accountDelegationRefreshToken || {};

        const deviceGrant = new DeviceGrant(grantType, assertion);

        const {
            accessToken: token,
            tokenType,
            refreshToken,
            expiresIn,
            accessTokenType
        } = sdk.token;

        const accessContext = new AccessContext({
            token,
            tokenType,
            refreshToken,
            expiresIn,
            region,
            accessTokenType
        });

        const modes: Array<string> = [];

        if (provider) {
            modes.push(provider);
        }

        const accessContextState = new AccessContextState(modes);

        const access = new Access(accessContext, accessContextState);

        const sessionInfo = SessionInfo.create(sdk.session);

        const featureFlags = sdk.featureFlags || {};

        return new InitializationState({
            deviceGrant,
            access,
            sessionInfo,
            accountDelegationRefreshToken,
            featureFlags
        });
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object} data - a `JSON` object serialized from a prior `InitializationState`
     * @desc Factory helper to create an `InitializationState` class from a previously serialized instance.
     * @returns {SDK.InitializationState}
     *
     */
    public static deserialize(data: {
        deviceGrant: {
            grantType: string;
            assertion: string;
        };
        sessionInfo: Nullable<unknown>;
        access: {
            context: ConstructorParameters<typeof AccessContext>[0];
            contextState: {
                modes: ConstructorParameters<typeof AccessContextState>[0];
            };
        };
        accountDelegationRefreshToken?: Nullable<string>;
        featureFlags: Record<string, unknown>;
    }) {
        const { featureFlags = {} } = data;
        const { grantType, assertion } = data.deviceGrant;

        const deviceGrant = new DeviceGrant(grantType, assertion);
        const accessContext = new AccessContext(data.access.context);
        const accessContextState = new AccessContextState(
            data.access.contextState.modes
        );
        const access = new Access(accessContext, accessContextState);
        const sessionInfo = SessionInfo.create(data.sessionInfo);
        const accountDelegationRefreshToken =
            data.accountDelegationRefreshToken || null;

        return new InitializationState({
            deviceGrant,
            access,
            sessionInfo,
            accountDelegationRefreshToken,
            featureFlags
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.InitializationState';
    }
}
