/**
 *
 * @module internalEvents
 *
 */

/**
 *
 * @access private
 * @typedef {Object} SDK.InternalEvents
 * @since 10.0.0
 * @desc Comprehensive list of private and/or internal events.
 *
 * @property {String} TokenRefreshFailed - Since `10.0.0` - Used to drive internal background logic after a token refresh failure.
 * @property {String} AccessChanged - Since `10.0.0` - Notifies internal structures that the access token has changed.
 * @property {String} SessionInfoChanged - Since `10.0.0` - Notifies internal structures that the session info has changed.
 * @property {String} UpdateAdEngine - Since `10.0.0` - Notifies internal structures that CDN Fallback needs to update AdEngine.
 * @property {String} ValidationResultsReceived - Since `16.1.0` - Notifies internal structures that a telemetry validation response was received.
 * @property {String} DeviceRegistered - Since `21.0.0` - Notifies internal structures that the device has been registered.
 *
 * @example
 * SDK.InternalEvents.TokenRefreshFailed // 'TokenRefreshFailed'
 *
 */
enum InternalEvents {
    /**
     *
     * @note Can be picked up by `SdkSession`, `CoreHttpClientProvider`, `SocketManager`, `EventBuffer`, and `PlaybackSession`.
     *
     */
    TokenRefreshFailed = 'TokenRefreshFailed',
    /**
     *
     * @note Emitted in the `processAccess` method in `TokenManager`.
     * @note Attaches the `accessToken` to the `PlayerAdapter` in `PlaybackSession`.
     * @note Initializes the `SocketManager` if it exists during `createSdkSession` construction.
     *
     */
    AccessChanged = 'AccessChanged',
    /**
     *
     * @note Emitted when the session info has changed based on a comparison.
     * @note The main task of this event is to bubble up and expose to `SdkSession` so that app devs can use it.
     *
     */
    SessionInfoChanged = 'SessionInfoChanged',
    /**
     *
     * @note Emitted when the multi-variant playlist has been fetched to indicate that the CDN values are final.
     * @note The main task of this event is to bubble up and expose to `PlaybackSession` to trigger an AdEngine update.
     *
     */
    UpdateAdEngine = 'UpdateAdEngine',
    /**
     *
     * @note Emitted when a telemetry validation response is received.
     *
     */
    ValidationResultsReceived = 'ValidationResultsReceived',
    /**
     *
     * @note Emitted when the device is registered.
     *
     */
    DeviceRegistered = 'DeviceRegistered'
}

export default InternalEvents;
