/**
 *
 * @module browserInfo
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/commerce.md#browser-info
 * @see https://github.bamtech.co/mercury/universal-payment-bridge/blob/master/docs/3ds/waiver/BR_MC_Debit_3DS_Waiver.md#browser-information
 * @see https://docs.adyen.com/checkout/3d-secure/api-reference#browserinfo
 *
 */

import { Types, typecheck } from '@dss/type-checking';

interface BrowserInfoOptions {
    acceptHeader: string;
    colorDepth: number;
    javaEnabled: boolean;
    language: string;
    screenHeight: number;
    screenWidth: number;
    timeZoneOffset: number;
    userAgent: string;
}

/**
 *
 * @since 4.12.0
 * @desc Object containing browser info such as screen height and width.
 * @note Sent over as serialized JSON via a X-BAMTech-Browser-Info header.
 * @note Web only, browser info data is extracted from the shopper.
 *
 */
export default class BrowserInfo {
    /**
     *
     * @access private
     * @since 4.12.0
     * @type {String}
     * @desc The accept header value of the shopper's browser.
     * @note Possible Values: text/html, application/xhtml+xml, application/xml;q=0.9,
     * image/webp, image/apng/;q=0.8
     *
     */
    private acceptHeader: string;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Number}
     * @desc The color depth of the shopper's browser in bits per pixel.
     * @note Use the browser's `screen.colorDepth` property to obtain the value.
     * @note Possible Values: 1, 4, 8, 15, 16, 24, 32, 48, etc...
     *
     */
    private colorDepth: number;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Boolean}
     * @desc Boolean value indicating if the shopper's browser is able to execute Java.
     * @note Possible Values: true, false
     * @note Use the browser's `navigator.javaEnabled()` function to obtain the value.
     *
     */
    private javaEnabled: boolean;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {String}
     * @desc The name of the browser language used by the shopper.
     * @note Usually a two letter country code.
     * @note Possible Values: US, CA, NL, FR, etc...
     * @note Use the browser's `navigator.language` property to obtain the value.
     *
     */
    private language: string;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Number}
     * @desc The pixel height of the shopper's screen.
     * @note Possible Values: 480, etc... (pixels)
     *
     */
    private screenHeight: number;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Number}
     * @desc The pixel width of the shopper's screen.
     * @note Possible Values: 640, etc... (pixels)
     *
     */
    private screenWidth: number;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {Number}
     * @desc Time difference between UTC time and the shopper's browser local time, in minutes.
     * @note Possible Values: -180, etc...
     *
     */
    private timeZoneOffset: number;

    /**
     *
     * @access private
     * @since 4.12.0
     * @type {String}
     * @desc The user agent value of the shopper's browser.
     * @note Possible Values: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.110 Safari/537.36
     * @note Use the browser's `navigator.userAgent` property to obtain the value.
     *
     */
    private userAgent: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.acceptHeader
     * @param {Number} options.colorDepth
     * @param {Boolean} options.javaEnabled
     * @param {String} options.language
     * @param {Number} options.screenHeight
     * @param {Number} options.screenWidth
     * @param {Number} options.timeZoneOffset
     * @param {String} options.userAgent
     *
     */
    public constructor(options: BrowserInfoOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    acceptHeader: Types.nonEmptyString,
                    colorDepth: Types.number,
                    javaEnabled: Types.boolean,
                    language: Types.nonEmptyString,
                    screenHeight: Types.number,
                    screenWidth: Types.number,
                    timeZoneOffset: Types.number,
                    userAgent: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            acceptHeader,
            colorDepth,
            javaEnabled,
            language,
            screenHeight,
            screenWidth,
            timeZoneOffset,
            userAgent
        } = options;

        this.acceptHeader = acceptHeader;
        this.colorDepth = colorDepth;
        this.javaEnabled = javaEnabled;
        this.language = language;
        this.screenHeight = screenHeight;
        this.screenWidth = screenWidth;
        this.timeZoneOffset = timeZoneOffset;
        this.userAgent = userAgent;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Commerce.BrowserInfo';
    }
}
