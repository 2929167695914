/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import LocationType from './locationType';

/**
 *
 * @typedef {Object} SDK.Services.Session.HomeLocation
 * @since 11.0.0
 * @property {String} countryCode
 * @property {Boolean} [adsSupported] - since `16.1.0`
 *
 */
export type HomeLocation = {
    countryCode: string;
    adsSupported?: boolean;
};

/**
 *
 * @access private
 *
 */
export const HomeLocationTypedef = {
    countryCode: Types.nonEmptyString,
    adsSupported: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Session.ParentalControls
 * @since 11.0.0
 * @property {Object} liveAndUnratedContent
 * @property {Boolean} liveAndUnratedContent.enabled - For Star, indicates whether or not the profile has the ability to view live and unrated content. For other partners, this field will not be included.
 *
 */
export type ParentalControls = {
    liveAndUnratedContent: {
        enabled: boolean;
    };
};

/**
 *
 * @access private
 *
 */
export const ParentalControlsTypedef = {
    liveAndUnratedContent: Types.object({
        enabled: Types.boolean
    })
};

/**
 *
 * @typedef {Object} SDK.Services.Session.PortabilityLocation
 * @since 11.0.0
 * @property {String} countryCode
 * @property {SDK.Services.Session.LocationType} type
 *
 */
export type PortabilityLocation = {
    countryCode: string;
    type: string;
};

/**
 *
 * @access private
 *
 */
export const PortabilityLocationTypedef = {
    countryCode: Types.nonEmptyString,
    type: Types.keyIn(LocationType)
};

/**
 *
 * @typedef {Object} SDK.Services.Session.PreferredMaturityRating
 * @since 11.0.0
 * @property {Number} impliedMaturityRating
 * @property {String} ratingSystem
 *
 */
export type PreferredMaturityRating = {
    impliedMaturityRating: number;
    ratingSystem: string;
};

/**
 *
 * @access private
 *
 */
export const PreferredMaturityRatingTypedef = {
    impliedMaturityRating: Types.number,
    ratingSystem: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionAccountInfo
 * @since 11.0.0
 * @property {String} id
 *
 */
export type SessionAccountInfo = {
    id: string;
};

/**
 *
 * @access private
 *
 */
export const SessionAccountInfoTypedef = {
    id: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionDeviceInfo
 * @since 11.0.0
 * @property {String} id
 * @property {String} platform
 *
 */
export type SessionDeviceInfo = {
    id: string;
    platform: string;
};

/**
 *
 * @access private
 *
 */
export const SessionDeviceInfoTypedef = {
    id: Types.nonEmptyString,
    platform: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionEntitlement
 * @since 11.0.0
 * @property {String} name
 *
 */
export type SessionEntitlement = {
    name: string;
};

/**
 *
 * @access private
 *
 */
export const SessionEntitlementTypedef = {
    name: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionExperimentAssignment
 * @since 11.0.0
 * @property {String} variantId
 * @property {Number} version
 * @property {String} featureId
 *
 */
export type SessionExperimentAssignment = {
    variantId: string;
    version?: number;
    featureId: string;
};

/**
 *
 * @access private
 *
 */
export const SessionExperimentAssignmentTypedef = {
    variantId: Types.nonEmptyString,
    version: Types.number.optional,
    featureId: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionFeatures
 * @since 16.1.0
 * @property {Boolean} coPlay
 * @property {Boolean} download
 * @property {Boolean} noAds
 *
 */
export type SessionFeatures = {
    coPlay: boolean;
    download: boolean;
    noAds: boolean;
};

/**
 *
 * @access private
 *
 */
export const SessionFeaturesTypedef = {
    coPlay: Types.boolean,
    download: Types.boolean,
    noAds: Types.boolean
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionIdentity
 * @since 11.0.0
 * @property {String} id
 *
 */
export type SessionIdentity = {
    id: string;
};

/**
 *
 * @access private
 *
 */
export const SessionIdentityTypedef = {
    id: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionLocation
 * @since 11.0.0
 * @property {SDK.Services.Session.LocationType} type
 * @property {String} [zipCode]
 * @property {String} [countryCode]
 * @property {Number} [dma]
 * @property {Number} [asn]
 * @property {String} [regionName]
 * @property {String} [connectionType]
 * @property {Boolean} [adsSupported] - since `16.1.0`
 * @property {Number} [designatedMarketArea] - DEPRECATED use `dma`
 *
 */
export type SessionLocation = {
    type: string;
    zipCode?: string;
    countryCode?: string;
    dma?: number;
    asn?: number;
    regionName?: string;
    connectionType?: string;
    adsSupported?: boolean;

    designatedMarketArea?: number; // DEPRECATED in 15.0.0
};

/**
 *
 * @access private
 *
 */
export const SessionLocationTypedef = {
    type: Types.nonEmptyString,
    zipCode: Types.string.optional,
    countryCode: Types.string.optional,
    dma: Types.number.optional,
    asn: Types.number.optional,
    regionName: Types.string.optional,
    connectionType: Types.string.optional,
    adsSupported: Types.boolean.optional,

    designatedMarketArea: Types.number.optional // DEPRECATED in 15.0.0
};

/**
 *
 * @typedef {Object} SDK.Services.Session.SessionProfileInfo
 * @since 11.0.0
 * @property {String} id - The identifier for the session profile.
 * @property {Object<SDK.Services.Session.ParentalControls>} [parentalControls] - The parental controls for the session profile.
 *
 */
export type SessionProfileInfo = {
    id: string;
    parentalControls?: ParentalControls;
};

/**
 *
 * @access private
 *
 */
export const SessionProfileInfoTypedef = {
    id: Types.nonEmptyString,
    parentalControls: Types.object(ParentalControlsTypedef).optional
};
