/**
 *
 * @module reauthorizationFailure
 *
 */

import TokenRefreshFailure from './token/tokenRefreshFailure';

/**
 *
 * @desc The event raised when automatic token refresh fails.
 *
 */
export default class ReauthorizationFailure extends TokenRefreshFailure {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.ReauthorizationFailure';
    }
}
