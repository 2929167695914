/**
 *
 * @module userActivityApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/user-activity.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/UserActivityApi.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { UserActivityEvent, UserActivityEventTypedef } from './typedefs';
import EventSchemataProvider from './eventSchemataProvider';

import ErrorEventData from '../services/qualityOfService/errorEventData';
import QoeError from '../services/qualityOfService/qoeError';

import DustLogUtility from '../services/internal/dust/dustLogUtility';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustCategory from '../services/internal/dust/dustCategory';
import SessionManager from '../session/sessionManager';
import SocketManager from '../socket/socketManager';

import Logger from '../logging/logger';

import { getDataVersion } from '../media/qoeEventVersionInfo';
import { UserActivityEventCategory } from './enums';
import {
    IMonotonicTimestampProvider,
    MonotonicTimestampProviderTypedef
} from '../providers/typedefs';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;
const PersonalizationApiDustUrnReference =
    DustUrnReference.userActivity.personalization;

/**
 *
 * @access public
 * @desc The User Activity Api is a public interface for DUST. This api will allow app devs to send custom events,
 * related to user activity while using an app.
 * @since 3.4.0
 *
 */
export default class UserActivityApi {
    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @type {Object}
     * @desc global tracking parameters for Glimpse that we try to apply to all Glimpse payloads
     * @note event specific tracking parameters will overwrite what is in here
     * @note spec says public but we mark this private because we want you to use `this.setTrackingParameters`
     *
     */
    private trackingParameters: Record<string, unknown>;

    /**
     *
     * @access public
     * @type {SDK.UserActivity.EventSchemataProvider}
     * @desc stores and uses schemata to generate event payloads
     * @readonly
     *
     */

    public schemataProvider: EventSchemataProvider;

    /**
     *
     * @access private
     * @since 23.0.0
     * @type {IMonotonicTimestampProvider}
     * @desc The monotonic timestamp provider used to generate timestamps for events.
     *
     */
    private monotonicTimestampProvider: IMonotonicTimestampProvider;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {SessionManager} options.sessionManager
     * @param {SocketManager} options.socketManager
     *
     */
    public constructor(options: {
        logger: Logger;
        sessionManager: SessionManager;
        socketManager: SocketManager;
        monotonicTimestampProvider: IMonotonicTimestampProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sessionManager: Types.instanceStrict(SessionManager),
                    socketManager: Types.instanceStrict(SocketManager),
                    monotonicTimestampProvider: Types.object(
                        MonotonicTimestampProviderTypedef
                    ),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            sessionManager,
            socketManager,
            monotonicTimestampProvider,
            logger
        } = options;

        this.sessionManager = sessionManager;
        this.socketManager = socketManager;
        this.trackingParameters = {};
        this.schemataProvider = new EventSchemataProvider();
        this.monotonicTimestampProvider = monotonicTimestampProvider;
        this.logger = logger;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {String} rewardToken - The reward token associated with this content recommendation.
     * @param {String} contentId - The ID of content being rewarded, by the defined action.
     * @param {String} action - The action of the reward.
     * @param {Array<String>} recommendedContentIds - Array of content id(s) that were recommended and viewable
     * when the user action occurred.
     * @desc Posts an event for a users content recommendation reward.
     * @returns {Promise<Object>} returns the payload that is sent to dust (for debugging purposes only)
     *
     */
    public async sendContentReward(
        rewardToken: string,
        contentId: string,
        action: string,
        recommendedContentIds: Array<string>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                rewardToken: Types.nonEmptyString,
                contentId: Types.nonEmptyString,
                action: Types.nonEmptyString,
                recommendedContentIds: Types.array.of.nonEmptyString
            };

            typecheck(this, 'sendContentReward', params, arguments);
        }

        const { logger } = this;

        const data = {
            rewardToken,
            rewardContentId: contentId,
            action,
            recommendedContentIds
        };

        if (logger.dustEnabled) {
            const urn =
                PersonalizationApiDustUrnReference.contentRecommendationAward;

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.personalization,
                logger,
                source: this.toString(),
                urn,
                skipLogTransaction: true,
                data
            });

            dustLogUtility.log();
        }

        return data;
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {SDK.UserActivity.UserActivityEvent} event
     * @param {Object} [parameters]
     * @param {SDK.UserActivity.UserActivityEventCategory} [category]
     * @param {String} [dataVersion]
     * @note we provide an additional `_failures` array on the debugging payload that is returned so the application
     * developer can determine if any schema values were not generated properly
     * @note generally we avoid using underscore pre-fixed names like `_failures` but in this case it's done to ensure
     * we don't accidentally overwrite a value provided by the app dev that is called `failures`
     * @returns {Promise<Object>} returns the payload that is sent to dust (for debugging purposes only)
     *
     */
    public async trackEvent(
        event: UserActivityEvent,
        parameters = {},
        category?: UserActivityEventCategory,
        dataVersion?: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                event: Types.object(UserActivityEventTypedef),
                parameters: Types.object().optional,
                category: Types.in(UserActivityEventCategory).optional,
                version: Types.nonEmptyString.optional
            };

            typecheck(this, 'trackEvent', params, arguments);
        }

        const { logger, schemataProvider } = this;

        const globalTrackingData = {
            ...this.trackingParameters,
            ...parameters
        };
        const schemata = schemataProvider.getSchemata(event);
        const dataPayload = schemata.generateEventPayload(globalTrackingData);

        if (logger.dustEnabled) {
            const urn = event.eventUrn;

            const categoryMap = {
                [UserActivityEventCategory.AppQoe]: DustCategory.qoe,
                [UserActivityEventCategory.Glimpse]: DustCategory.glimpse
            };

            const mappedCategory =
                categoryMap[category as UserActivityEventCategory] ||
                DustCategory.glimpse;

            const dustLogUtility = new DustLogUtility({
                category: mappedCategory,
                logger,
                source: this.toString(),
                urn,
                skipLogTransaction: true,
                data: {
                    ...dataPayload,
                    _failures: undefined // make sure `_failures` is not included
                },
                dataVersion
            });

            dustLogUtility.log();
        }

        return dataPayload;
    }

    /**
     *
     * @access public
     * @since 3.8.0
     * @param {Object} trackingParameters
     * @desc merges the input to this function with the trackingParameters field on the UserActivityApi - this will
     * overwrite any existing duplicate keys that currently exist
     * @note this method is not in the spec - we provide a setter so app devs don't modify the UserActivityApi instance
     * directly
     *
     */
    public setTrackingParameters(trackingParameters: Record<string, unknown>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingParameters: Types.object()
            };

            typecheck(this, 'setTrackingParameters', params, arguments);
        }

        this.trackingParameters = Object.assign(
            this.trackingParameters,
            trackingParameters
        );
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {String} featureId
     * @desc Used to track a user's exposure to WeaponX experiments.
     * @returns {Promise<Void>}
     *
     */
    public async trackExperimentExposure(featureId: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                featureId: Types.nonEmptyString
            };

            typecheck(this, 'trackExperimentExposure', params, arguments);
        }

        const { sessionManager, socketManager } = this;

        const sessionInfo = await sessionManager.getInfo();
        const { id, account, device, experiments } = sessionInfo;

        const participantId = Check.assigned(account) ? account?.id : device.id;
        const feature = experiments[featureId];
        const { version: featureVersion, variantId } = feature;
        const sessionId = id;
        const exposureTimestamp = new Date().toISOString();

        const options = {
            participantId,
            featureId,
            featureVersion,
            variantId,
            sessionId,
            exposureTimestamp
        };

        return socketManager.sendExposureExperiment(options);
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.QualityOfService.QoeError} qoeError - The qoeError containing the necessary error details.
     * @descAdds the qoeError to a queue to be logged at a configurable interval. This error may be sourced from the app, sdk, or service.
     * @returns {Promise<Void>}
     * @note App devs should use this method whenever the application encounters an error. This includes when the
     * SDK returns an error either from an internal SDK or service issue.
     *
     */
    public logQoeError(qoeError: QoeError) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                qoeError: Types.instanceStrict(QoeError)
            };

            typecheck(this, 'logQoeError', params, arguments);
        }

        const { logger } = this;
        const analyticsProvider = logger.analyticsProvider;

        let dictionaryVersion;
        let data = {};

        if (logger.dustEnabled) {
            if (analyticsProvider) {
                dictionaryVersion = analyticsProvider.getDictionaryVersion();
                data = analyticsProvider.getCommonProperties();
            }

            const monotonicTimestamp =
                this.monotonicTimestampProvider.getTimestamp();

            const errorEventData = new ErrorEventData({
                ...qoeError,
                dictionaryVersion,
                data,
                monotonicTimestamp,
                mediaFetchSucceeded: true
            });

            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn: QualityOfServiceDustUrnReference.error,
                data: {
                    ...errorEventData
                },
                skipLogTransaction: true,
                dataVersion: getDataVersion(
                    QualityOfServiceDustUrnReference.error
                )
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.UserActivityApi';
    }
}
