/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    BillingAddress,
    BillingAddressTypedef,
    PayerAddress,
    PayerAddressTypedef,
    PayerName,
    PayerNameTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.Mercado.PayerInfo
 * @since 9.0.0
 * @property {String} [payer] - Payer's email address
 * @property {String} payerId - Payer Identification (alpha-numeric)
 * @property {Object<SDK.Commerce.PayerName>} payerName - Object containing the full name of the payer.
 * @property {Object<SDK.Commerce.PayerAddress>} payerAddress - Object containing the address of the payer.
 *
 */
export interface PayerInfo {
    payer?: string;
    payerId: string;
    payerName: PayerName;
    payerAddress: PayerAddress;
}

/**
 *
 * @access private
 *
 */
export const PayerInfoTypedef = {
    payer: Types.nonEmptyString.optional,
    payerId: Types.nonEmptyString,
    payerName: Types.object(PayerNameTypedef),
    payerAddress: Types.object(PayerAddressTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.Mercado.MercadoPaymentMethod
 * @since 9.0.0
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#extend-params
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {String} orderCode - Identifier for an order. Used for repeat billing.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 * @property {Object<SDK.Commerce.PayerInfo>} [payerInfo] - Mercado user information.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface MercadoPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    usage: string;
    isShared?: boolean;
    orderCode: string;
    billingAddress: BillingAddress;
    payerInfo?: PayerInfo;
}

/**
 *
 * @access private
 *
 */
export const MercadoPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    orderCode: Types.nonEmptyString,
    billingAddress: Types.object(BillingAddressTypedef),
    payerInfo: Types.object(PayerInfoTypedef).optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.Mercado.MercadoPaymentRequest
 * @since 4.19.0
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress
 * @property {String} cancelUrl - If the shopper hits cancel whilst on the Mercado Pago screen they will be sent to this location.
 * @property {String} failureUrl - If the shopper gets REFUSED, they will be sent to this location.
 * @property {String} pendingUrl - If the payment cannot be authorized in realtime i.e. the bank is not online then it will be held in a pending status until updated to authorized or refused.
 * @property {String} successUrl - If the shopper gets AUTHORIZED, they will be sent to this location.
 * @property {String} [campaignCode] - Campaign Code required to calculate price of the order.
 * @property {String} [voucherCode] - Voucher Code required to calculate price of the order.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {Array<String>} [skus] - Array of sku values associated with the payment request.
 * @property {Boolean} [isDefault] - Flag used to determine if this payment method should be used for future transactions.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 *
 */
export interface MercadoPaymentRequest {
    billingAddress: BillingAddress;
    successUrl: string;
    cancelUrl: string;
    pendingUrl: string;
    failureUrl: string;
    campaignCode?: string;
    voucherCode?: string;
    redemptionCode?: string;
    offerId?: string;
    isDefault?: boolean;
    skus?: Array<string>;
    usage: string;
}

/**
 *
 * @access private
 *
 */
export const MercadoPaymentRequestTypedef = {
    billingAddress: Types.object(BillingAddressTypedef),
    successUrl: Types.nonEmptyString,
    cancelUrl: Types.nonEmptyString,
    pendingUrl: Types.nonEmptyString,
    failureUrl: Types.nonEmptyString,
    campaignCode: Types.nonEmptyString.optional,
    voucherCode: Types.nonEmptyString.optional,
    redemptionCode: Types.nonEmptyString.optional,
    offerId: Types.nonEmptyString.optional,
    isDefault: Types.boolean.optional,
    skus: Types.array.of.nonEmptyString.optional,
    usage: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Mercado.MercadoUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} customerToken - The user's customer token.
 * @property {Object<SDK.Commerce.PayerInfo>} payerInfo - Mercado user information.
 *
 */
export interface MercadoUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    customerToken: string;
    payerInfo: PayerInfo;
}

/**
 *
 * @access private
 *
 */
export const MercadoUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    customerToken: Types.nonEmptyString,
    payerInfo: Types.object(PayerInfoTypedef)
};
