/**
 *
 * @module telemetryResponse
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Model describing the response from the telemetry service
 * optionally containing both information and instructions.
 *
 */
export default class TelemetryResponse {
    /**
     *
     * @access public
     * @type {Number|undefined}
     * @desc Gets the optional time to indicate the client should
     * back-off for a fixed time period before sending telemetry data.
     *
     */
    public replyAfter?: number;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc Gets a unique tracking identifier for any API request.
     *
     */
    public requestId?: string;

    /**
     *
     * @param {Object} options
     * @param {Number} [options.replyAfter]
     * @param {String} [options.requestId]
     *
     */
    public constructor(options: { replyAfter?: number; requestId?: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    replyAfter: Types.number.optional,
                    requestId: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { replyAfter, requestId } = options;

        this.replyAfter = replyAfter;
        this.requestId = requestId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Telemetry.TelemetryResponse';
    }
}
