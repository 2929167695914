/**
 *
 * @module bootstrapConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ConfigurationHostName from './configurationHostName';
import ServiceEnvironmentName from './serviceEnvironmentName';
import Platform from './platform';
import {
    Application,
    ApplicationTypedef,
    BootstrapConfigurationExtras,
    BootstrapConfigurationExtrasTypedef
} from './typedefs';

import PlatformProfileReference from './platformProfileReference';

/**
 *
 * @access public
 * @desc Provides configuration information used by the ConfigurationClient to
 * retrieve the SdkSessionConfiguration necessary to create a SdkSession instance.
 *
 */
export default class BootstrapConfiguration {
    /**
     *
     * @access public
     * @type {String}
     * @desc The client ID to use the SDK against.
     *
     */
    public clientId: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc The public application key for the SDK.
     *
     */
    public clientApiKey: string;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ServiceEnvironmentName}
     * @desc The environment that the SDK services should run in.
     *
     */
    public environment: ServiceEnvironmentName;

    /**
     *
     * @access public
     * @type {Boolean}
     * @desc A value that indicates whether or not to enable debug mode for the SDK.
     *
     */
    public debugEnabled: boolean;

    /**
     *
     * @access public
     * @type {String|null}
     * @desc The application runtime used for device.
     *
     */
    public platform: Nullable<string>;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ConfigurationHostName}
     * @desc The configuration host name to use ("dev", "release" or "staging") - defaults to `ConfigurationHostName.release`
     *
     */
    public configHostName: ConfigurationHostName;

    /**
     *
     * @access public
     * @type {Application}
     * @desc Application driven values used for SDK configuration.
     *
     */
    public application: Application;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc Can be used to override the base url for integration/unit testing purposes in case one wants to self-host configs for testing purposed.
     *
     */
    public configHostOverride?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Can be used to override the config url. For testing purposes only; should be used in conjunction with `debugEnabled` = `true`.
     *
     */
    public configHostUrlOverride?: string;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {Object}
     * @desc Optional extra values to be provided to by the platform for customizing SDK functionality.
     *
     */
    public extras?: BootstrapConfigurationExtras;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {String|undefined}
     * @desc The account delegation refresh token.
     *
     */
    public accountDelegationRefreshToken?: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.clientApiKey
     * @param {SDK.Services.Configuration.ServiceEnvironmentName} options.environment
     * @param {Object} options.application - Used to populate the `DustApplicationPayload.version` field and application headers.
     * @param {Object} options.application.version
     * @param {Object} options.application.id
     * @param {Object} options.application.name
     * @param {Boolean} [options.debugEnabled=false]
     * @param {String} [options.platform=null]
     * @param {SDK.Services.Configuration.ConfigurationHostName} [options.configHostName=ConfigurationHostName.release]
     * @param {String} [options.configHostOverride]
     * @param {String} [options.configHostUrlOverride]
     * @param {String} [options.accountDelegationRefreshToken]
     * @param {Object} [options.extras]
     * @param {Boolean} [options.extras.useMemoryFirst]
     * @param {Boolean} [options.extras.useStorageCompression]
     *
     */
    public constructor(options: {
        clientId: string;
        clientApiKey: string;
        environment: ServiceEnvironmentName;
        application: Application;
        debugEnabled?: boolean;
        platform?: keyof typeof PlatformProfileReference;
        configHostName?: ConfigurationHostName;
        configHostOverride?: string;
        configHostUrlOverride?: string;
        accountDelegationRefreshToken?: string;
        extras?: BootstrapConfigurationExtras;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    clientApiKey: Types.nonEmptyString,
                    environment: Types.in(ServiceEnvironmentName),
                    application: Types.object(ApplicationTypedef),
                    debugEnabled: Types.boolean.optional,
                    platform: Types.in(Platform).optional,
                    configHostName: Types.in(ConfigurationHostName).optional,
                    useStorageCompression: Types.boolean.optional,
                    configHostOverride: Types.nonEmptyString.optional,
                    configHostUrlOverride: Types.nonEmptyString.optional,
                    accountDelegationRefreshToken:
                        Types.nonEmptyString.optional,
                    extras: Types.object(BootstrapConfigurationExtrasTypedef)
                        .optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            clientId,
            clientApiKey,
            environment,
            debugEnabled = false,
            platform = null,
            configHostName = ConfigurationHostName.release,
            application,
            configHostOverride,
            configHostUrlOverride,
            accountDelegationRefreshToken
        } = options;

        this.clientId = clientId;
        this.clientApiKey = clientApiKey;
        this.environment = environment;
        this.debugEnabled = debugEnabled;
        this.platform = platform;
        this.configHostName = configHostName;
        this.application = application;
        this.configHostOverride = configHostOverride;
        this.configHostUrlOverride = configHostUrlOverride;
        this.accountDelegationRefreshToken = accountDelegationRefreshToken;

        const extras = options.extras || {};

        this.extras = extras;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.BootstrapConfiguration';
    }
}
