/**
 *
 * @module monotonicTimestampProvider
 *
 */

import monotonicClock from '../services/util/monotonicClock';
import { IMonotonicTimestampProvider } from '../providers/typedefs';

/**
 *
 * @access public
 * @since 22.0.0
 * @desc A provider for accessing the current monotonic timestamp with millisecond precision.
 * The intent of this provider is to provide a way capture values that can be used to calculate accurate time intervals across events, as other timestamps are subject to local clock changes and may fluctuate.
 * The provider implementation should be passed in by client applications while creating the SdkSession to ensure the startTimestamp is recorded as early as possible.
 * The underlying clock used for this should be monotonic and should continue to tick even when the device is in sleep mode (where possible).
 * Platforms without a clock implementation that fully meets the requirements should utilize a timer whereby the value of the timestamp strictly increases across events in the same session,
 * even if the timer only operates in the foreground or has other limitations.
 *
 */
export default class MonotonicTimestampProvider
    implements IMonotonicTimestampProvider
{
    private static startTimestamp: number = monotonicClock.timestamp();

    /**
     *
     * @access public
     * @since 20.0.0
     * @desc Returns a timestamp in milliseconds relative to the `startTimestamp`
     * initialized within the manager. The value of the timestamp strictly
     * increases.
     * @returns {Number} As an Integer
     *
     */
    public getTimestamp() {
        const timestamp = Math.round(
            monotonicClock.timestamp() -
                MonotonicTimestampProvider.startTimestamp
        );

        return timestamp;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.MonotonicTimestampProvider';
    }
}
