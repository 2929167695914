/**
 *
 * @module accountEntitlementContext
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/services/class.sdk.services.paywall.plantuml
 *
 */

/**
 *
 * @enum
 * @since 3.9.0
 * @typedef {Object} SDK.Services.Paywall.AccountEntitlementContext
 * @desc Defines the accountEntitlementContext types
 *
 * @property {String} accountActiveEntitlement - Since `3.9.0`.
 * @property {String} accountExpiredEntitlement - Since `3.9.0`.
 * @property {String} accountNeverEntitled - Since `3.9.0`.
 * @property {String} anonymous - Since `3.9.0`.
 * @property {String} accountOnBillingHold - Since `4.7.0`.
 *
 */
enum AccountEntitlementContext {
    accountActiveEntitlement = 'account_active_entitlement',
    accountExpiredEntitlement = 'account_expired_entitlement',
    accountNeverEntitled = 'account_never_entitled',
    anonymous = 'anonymous',
    accountOnBillingHold = 'account_on_billing_hold'
}
export default AccountEntitlementContext;
