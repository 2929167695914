/**
 *
 * @module browserDeviceAttributeProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';

import Device from '../services/providers/device';
import DeviceAttributes from './deviceAttributes';
import DeviceAttributeProvider from './deviceAttributeProvider';

/**
 *
 * @access public
 * @since 8.0.0
 * @desc Provides a default browser implementation of the {DeviceAttributeProvider}.
 *
 */
export default class BrowserDeviceAttributeProvider extends DeviceAttributeProvider {
    /**
     *
     * @access public
     * @type {SDK.Services.Providers.Device}
     *
     */
    public device: Device;

    /**
     *
     * @param {Logger} [logger]
     * @param {String} [userAgent]
     *
     */
    public constructor(logger?: Logger, userAgent?: string) {
        super(logger);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional,
                userAgent: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.device = new Device(userAgent);
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @desc An interface method. Retrieves application-provided device attributes.
     * @returns {SDK.Device.DeviceAttributes}
     *
     */
    public override getDeviceAttributes() {
        const device = this.device;

        return new DeviceAttributes({
            osDeviceIds: [],
            manufacturer: device.details.family,
            // model: unknownAttributeValue,
            brand: 'web',
            operatingSystem: device.platform || 'UnknownWeb',
            operatingSystemVersion: device.platformVersion,
            browserName: device.browser || null,
            browserVersion: device.browserVersion || null,
            deviceLanguage: device.browserLanguage || undefined
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Device.BrowserDeviceAttributeProvider';
    }
}
