/**
 *
 * @module oneTimePasscodeRequestReason
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/identity-bam.md#one-time-passcodes
 *
 */

/**
 *
 * @enum
 * @since 3.9.0
 * @typedef {Object} SDK.Services.Identity.Bam.OneTimePasscodeRequestReason
 * @desc Enumerates the known keys for one time passcode request reason.
 *
 * @property {String} authenticate - The "Login" key.
 * @property {String} forgotPassword - The "ForgotPassword" key.
 * @property {String} changeEmail - Since `4.8.0` - The "ChangeEmail" key.
 * @property {String} changePassword - Since `4.9.0` - The "ChangePassword" key.
 *
 * @example
 * OneTimePassCodeRequestReason.authenticate // 'Login'
 *
 */
enum OneTimePasscodeRequestReason {
    authenticate = 'Login',
    forgotPassword = 'ForgotPassword',
    changeEmail = 'ChangeEmail',
    changePassword = 'ChangePassword'
}
export default OneTimePasscodeRequestReason;
