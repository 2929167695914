/**
 *
 * @module getValueForKeyOrValue
 *
 */

/**
 *
 * @since 11.0.0
 * @param {Object} obj - Object to search keys/values for `valueOrKey`
 * @param {String} valueOrKey - the value to find within `obj`
 * @param {String} defaultOverride - the default value to return in case `obj` does not contain `valueOrKey`
 * @desc This will take an object and if it's key exists it returns that key's value otherwise it looks through the values to find it and returns it's associated value (essentially proving it )
 * @returns {String|undefined}
 *
 */
export default function getValueForKeyOrValue(
    obj: Record<string, unknown>,
    valueOrKey: TodoAny,
    defaultOverride?: TodoAny
) {
    // key is in object - return value
    if (valueOrKey in obj) {
        return obj[valueOrKey];
    }

    // find key if value exists
    const foundKey = Object.keys(obj).find((key) => obj[key] === valueOrKey);

    if (foundKey) {
        return obj[foundKey];
    }

    return defaultOverride;
}
