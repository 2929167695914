/**
 *
 * @module customerServiceClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.CustomerService.CustomerServiceClientEndpoint
 *
 * @property {String} createSupportCode
 *
 */
enum CustomerServiceClientEndpoint {
    createSupportCode = 'createSupportCode'
}

export default CustomerServiceClientEndpoint;
