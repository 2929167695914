/**
 *
 * @module mediaPlayhead
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { MediaPlayheadStatus } from './enums';

/**
 *
 * @since 4.0.0
 * @desc Represents the position of the associated media when it was last viewed by the current user.
 *
 */
export default class MediaPlayhead {
    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Number|undefined}
     * @desc Gets the position of the playhead in the last session for the associated media.
     *
     */
    public position?: number;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {String|undefined}
     * @desc Gets the timestamp when the playhead was last recorded.
     * @note Comes from the last_updated (PBO V5) or last_modified (PBO v7)
     * property in json in the ISO8601 format with UTC offset, like 2019-05-30T13:24:13-04:00
     * Note: only applies if accept header is "application/vnd.media-service+json; version=3
     * @note If using the v1 or v2 accept header: "application/vnd.media-service+json; version=1"
     * or "application/vnd.media-service+json; version=2", this value comes from last_modified
     * in a format like Tue, 11 Apr 2017 15:35:17 GMT (corresponds to a pattern EEE, dd MMM yyyy HH:mm:ss z)
     *
     */
    public lastUpdated?: string;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.Media.MediaPlayheadStatus}
     * @desc Gets or sets the status of retrieving the last playhead.
     *
     */
    public status: MediaPlayheadStatus;

    /**
     *
     * @param {Object} options
     * @param {Number} [options.position]
     * @param {String} [options.lastUpdated]
     * @param {SDK.Services.Media.MediaPlayheadStatus} options.status
     *
     */
    public constructor(options: {
        position?: number;
        lastUpdated?: string;
        status: MediaPlayheadStatus;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    position: Types.number.optional,
                    lastUpdated: Types.dateString.optional,
                    status: Types.in(MediaPlayheadStatus)
                })
            };

            typecheck(this, params, arguments);
        }

        const { position, lastUpdated, status } = options;

        this.position = position;
        this.lastUpdated = lastUpdated;
        this.status = status;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaPlayhead';
    }
}
