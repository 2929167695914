/**
 *
 * @module playbackTelemetryDispatcher
 * @see QoE events https://helios.eds.us-east-1.bamgrid.net/
 * @see Qoe Validation (paste a dust event into here to validate one at a time)
 *          https://hora.us-east-1.bamgrid.net/swagger/index.html#/Validation/post_validate_
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import {
    AdPodRequestedEventTypedef,
    AdPodFetchedEventTypedef,
    AdMultivariantFetchedEventTypedef,
    AdVariantFetchedEventTypedef,
    AdPlaybackStartedEventTypedef,
    AdPlaybackEndedEventTypedef,
    BitrateChangedEventTypedef,
    MultivariantPlaylistFallbackEvent,
    MultivariantPlaylistFallbackEventTypedef,
    MultivariantPlaylistFetchedEventTypedef,
    PlaybackEndedEventTypedef,
    PlaybackInitializedEventTypedef,
    PlaybackPausedEventTypedef,
    PlaybackReadyEventTypedef,
    PlaybackResumedEventTypedef,
    PlaybackSeekEndedEventTypedef,
    PlaybackSeekStartedEventTypedef,
    PlaybackStartedEventTypedef,
    PresentationTypeChangedEventTypedef,
    RebufferingEndedEventTypedef,
    RebufferingStartedEventTypedef,
    VariantPlaylistFetchedEventTypedef,
    NonFatalPlaybackErrorEventTypedef,
    PlaybackStartedEvent,
    PlaybackPausedEvent,
    PlaybackResumedEvent,
    RebufferingStartedEvent,
    RebufferingEndedEvent,
    PlaybackEndedEvent,
    PlaybackInitializedEvent,
    PlaybackReadyEvent,
    PlaybackSeekStartedEvent,
    PlaybackSeekEndedEvent,
    BitrateChangedEvent,
    VariantPlaylistFetchedEvent,
    AudioBitrateChangedEvent,
    MultivariantPlaylistFetchedEvent,
    PresentationTypeChangedEvent,
    SnapshotCreatedEvent,
    AdPodRequestedEvent,
    AdPodFetchedEvent,
    AdMultivariantFetchedEvent,
    AdVariantFetchedEvent,
    AdPlaybackStartedEvent,
    AdPlaybackEndedEvent,
    NonFatalPlaybackErrorEvent,
    PlaybackErrorInfo,
    DrmKeyFetchedEvent,
    DrmKeyFetchedEventTypedef,
    ViewingEnvironmentChangedEvent,
    InterstitialPlaybackEndedEvent,
    InterstitialPlaybackStartedEvent,
    InterstitialPlaybackEndedEventTypedef,
    InterstitialPlaybackStartedEventTypedef,
    PlaybackCustomEvent
    // ViewingEnvironmentChangedEventTypedef
} from './typedefs';

import {
    AdActivity,
    AdInsertionType,
    ApplicationContext,
    ErrorSource,
    HeartbeatSampleType,
    InterstitialActivity,
    PlaybackActivity,
    PlaybackMode,
    ProductType,
    StartupActivity,
    PresentationType,
    PodPosition,
    ErrorLevel,
    PlaybackState,
    MediaSegmentTypeMap,
    SkipType,
    PlaybackSeekCause,
    InterstitialInsertionType,
    NetworkType
} from '../services/qualityOfService/enums';

import { PlaylistType } from '../services/media/enums';

import Logger from '../logging/logger';
import PlatformMetricsProvider from '../platform/platformMetricsProvider';
import MediaItem from './mediaItem';
import Playlist from './playlist';
import PlaybackEventListener from './playbackEventListener';
import PlaybackTelemetryConfiguration from './playbackTelemetryConfiguration';
import PlaybackMetricsProvider from './playbackMetricsProvider';

import ErrorEventData from '../services/qualityOfService/errorEventData';
import InterstitialEventData from '../services/qualityOfService/interstitialEventData';
import PlaybackAdEventData from '../services/qualityOfService/playbackAdEventData';
import PlaybackEventData from '../services/qualityOfService/playbackEventData';
import PlaybackHeartbeatEventData from '../services/qualityOfService/playbackHeartbeatEventData';
import PlaybackStartupEventData from '../services/qualityOfService/playbackStartupEventData';

import DssWebPlayerAdapter from './playerAdapter/dssWebPlayerAdapter';

import TelemetryManager from '../internal/telemetry/telemetryManager';
import StreamSampleTelemetryEvent from '../internal/telemetry/streamSampleTelemetryEvent';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustCategory from '../services/internal/dust/dustCategory';

import { MediaAnalyticsKey, MediaLocatorType } from './enums';
import PlaybackContext from '../services/media/playbackContext';

import type MediaDescriptor from './mediaDescriptor';

import {
    IMonotonicTimestampProvider,
    MonotonicTimestampProviderTypedef
} from '../providers/typedefs';

import {
    QosDecisions,
    QosDecisionsResponse,
    ClientDecisions,
    ServerDecisions
} from '../services/media/typedefs';

import { getDataVersion } from './qoeEventVersionInfo';
import { createInvalidStateException } from '../services/util/errorHandling/createException';
import { DEFAULT_ZERO_GUID } from '../constants';
import uuidv4 from '../services/util/uuidv4';
import PlaybackCustomEventData from '../services/qualityOfService/playbackCustomEventData';

const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;

/**
 *
 * @desc Handles stream sample and other player telemetry events
 * @since 2.0.0
 *
 */
export default class PlaybackTelemetryDispatcher extends PlaybackEventListener {
    /**
     *
     * @access private
     * @type {SDK.Media.PlaybackTelemetryConfiguration}
     *
     */
    private config: PlaybackTelemetryConfiguration;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.TelemetryManager}
     *
     */
    private manager: TelemetryManager;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Platform.PlatformMetricsProvider}
     *
     */
    private platformMetricsProvider: PlatformMetricsProvider;

    /**
     *
     * @access private
     * @type {Object}
     *
     */
    private serverData: object;

    /**
     *
     * @access private
     * @type {String|null}
     *
     */
    private sessionId: Nullable<string>;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private interval: number;

    /**
     *
     * @access private
     * @type {Number}
     *
     */
    private intervalId = -1;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    private isInitialized: boolean;

    /**
     *
     * @access private
     * @type {SDK.Media.MediaItem|null}
     *
     */
    private mediaItem: Nullable<MediaItem>;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Media.Playlist|null}
     *
     */
    private playlist: Nullable<Playlist>;

    /**
     *
     * @access private
     * @type {Boolean}
     *
     */
    private isReleased: boolean;

    /**
     *
     * @access private
     * @since 4.17.0
     * @type {Object}
     *
     */
    private extraData: TodoAny;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number}
     * @desc Total number of video start failures (VSF).
     *
     */
    private totalVst: number;

    /**
     *
     * @access private
     * @since 15.2.1
     * @type {Number|undefined}
     * @desc The last time that a heartbeat sample was generated.
     *
     */
    private heartbeatSampleTime?: number;

    /**
     *
     * @access private
     * @since 20.0.0
     * @type {Object}
     * @desc Information on presentation.
     *
     */
    private presentationTypeCache: TodoAny;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc used to enable dust logging
     *
     */
    private qoeEnabled: boolean;

    /**
     *
     * @access private
     * @since 20.1.0
     * @type {Object}
     * @desc Used to keep track of which variant URIs were last fetched
     *
     */
    private variantFetchedUris: TodoAny;

    /**
     *
     * @access private
     * @since 22.0.0
     * @type {Boolean}
     * @desc Used to keep track of when playback has started.
     *
     */
    private hasPlaybackStarted: boolean;

    /**
     *
     * @access private
     * @since 22.0.0
     * @type {IMonotonicTimestampProvider}
     * @desc The monotonic timestamp provider used to generate timestamps for events.
     *
     */
    private monotonicTimestampProvider: IMonotonicTimestampProvider;

    /**
     *
     * @access private
     * @since 27.0.0
     * @type {Number|undefined}
     * @desc The max attained bitrate that occurred during playback.
     *
     */
    private maxAttainedBitrate?: Nullable<number>;

    /**
     *
     * @access private
     * @since 27.0.0
     * @type {PlaybackMode|undefined}
     * @desc The window mode in which content is being played.
     *
     */
    private playbackMode?: PlaybackMode;

    /**
     *
     * @access private
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     *
     */
    private programBoundaryInfoBlock?: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Media.PlaybackTelemetryConfiguration} options.playbackTelemetryConfiguration
     * @param {SDK.Internal.Telemetry.TelemetryManager} options.telemetryManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Platform.PlatformMetricsProvider} options.platformMetricsProvider
     * @param {IMonotonicTimestampProvider} options.monotonicTimestampProvider
     *
     */
    public constructor(options: {
        playbackTelemetryConfiguration: PlaybackTelemetryConfiguration;
        telemetryManager: TelemetryManager;
        logger: Logger;
        platformMetricsProvider: PlatformMetricsProvider;
        monotonicTimestampProvider: IMonotonicTimestampProvider;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackTelemetryConfiguration: Types.instanceStrict(
                        PlaybackTelemetryConfiguration
                    ),
                    telemetryManager: Types.instanceStrict(TelemetryManager),
                    logger: Types.instanceStrict(Logger),
                    platformMetricsProvider: Types.instanceStrict(
                        PlatformMetricsProvider
                    ),
                    monotonicTimestampProvider: Types.object(
                        MonotonicTimestampProviderTypedef
                    )
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            playbackTelemetryConfiguration,
            telemetryManager,
            logger,
            platformMetricsProvider,
            monotonicTimestampProvider
        } = options;

        this.config = playbackTelemetryConfiguration;
        this.manager = telemetryManager;
        this.logger = logger;
        this.platformMetricsProvider = platformMetricsProvider;
        this.serverData = {};
        this.sessionId = null;
        this.interval = this.config.streamSampleInterval;
        this.intervalId = -1;
        this.isInitialized = false;
        this.mediaItem = null;
        this.playlist = null;
        this.isReleased = false;
        this.extraData = {};
        this.totalVst = 0;
        this.heartbeatSampleTime = undefined;
        this.presentationTypeCache = {
            presentationType: PresentationType.main
        };
        this.qoeEnabled = !this.manager.qoeBuffer.disabled;
        this.variantFetchedUris = {};
        this.hasPlaybackStarted = false;
        this.monotonicTimestampProvider = monotonicTimestampProvider;
        this.programBoundaryInfoBlock = undefined;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Media.PlaybackMetricsProvider} options.playbackMetricsProvider
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Media.Playlist} options.playlist
     * @param {Object} [options.extraData={}]
     * @desc Initializes the dispatcher with a provider and a mediaItem.
     *
     */
    public init(options: {
        playbackMetricsProvider: PlaybackMetricsProvider;
        mediaItem: MediaItem;
        playlist: Playlist;
        extraData?: object;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackMetricsProvider: Types.instanceStrict(
                        PlaybackMetricsProvider
                    ),
                    mediaItem: Types.instanceStrict(MediaItem),
                    playlist: Types.instanceStrict(Playlist),
                    extraData: Types.object().optional
                })
            };

            typecheck(this, 'init', params, arguments);
        }

        const {
            playbackMetricsProvider,
            mediaItem,
            playlist,
            extraData = {}
        } = options;

        const logger = this.logger;
        const isReleased = this.isReleased;

        const telemetryTrackingData = playlist.getTrackingData(
            MediaAnalyticsKey.telemetry
        );

        if (Check.emptyObject(telemetryTrackingData)) {
            return;
        }

        if (isReleased) {
            throw createInvalidStateException(
                'The dispatcher has been released and cannot be reused.'
            );
        }

        try {
            playbackMetricsProvider.addListener(this);

            this.serverData = telemetryTrackingData;
            this.extraData = extraData;

            this.sessionId =
                mediaItem.playbackContext?.playbackSessionId ?? uuidv4();

            this.isInitialized = true;
            this.playlist = playlist;
            this.mediaItem = mediaItem;

            logger.info(this.toString(), 'Initialized.');

            this.logQoeEvent(
                QualityOfServiceDustUrnReference.playbackStartup,
                this.createPlaybackStartupEventData(
                    StartupActivity.preparing,
                    {},
                    playbackMetricsProvider
                )
            );
        } catch (ex) {
            logger.error(this.toString(), ex);
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @desc Cleans up the dispatcher and prevents further use.
     * @returns {Promise<Void>}
     *
     */
    public async release(playbackMetricsProvider: PlaybackMetricsProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playbackMetricsProvider: Types.instanceStrict(
                    PlaybackMetricsProvider
                )
            };

            typecheck(this, 'release', params, arguments);
        }

        const logger = this.logger;

        if (this.isReleased) {
            logger.warn(this.toString(), 'Already released.');

            return;
        }

        try {
            playbackMetricsProvider.removeListener(this);

            await this.manager.qoeBuffer.drain();

            this.clearStreamInterval();

            this.isInitialized = false;
            this.isReleased = true;
            this.mediaItem = null;
            this.playlist = null;
            this.presentationTypeCache = null;
            this.hasPlaybackStarted = false;
            this.programBoundaryInfoBlock = undefined;

            logger.warn(this.toString(), 'Released.');
        } catch (ex) {
            logger.error(this.toString(), ex);

            throw ex;
        }
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackStartedEvent>} args
     *
     */
    public override onPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: PlaybackStartedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackStartedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackStarted', params, arguments);
        }

        this.hasPlaybackStarted = true;

        const { currentBitratePeak } = provider.getPlaybackMetrics();

        this.setMaxAttainedBitrate(currentBitratePeak);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.started,
                args,
                provider
            )
        );

        this.startRecordingSamples(provider);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackPausedEvent>} args
     *
     */
    public override onPlaybackPaused(
        provider: PlaybackMetricsProvider,
        args: PlaybackPausedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackPausedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackPaused', params, arguments);
        }

        const sampleType = HeartbeatSampleType.state;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.paused,
                args,
                provider
            )
        );

        this.clearStreamInterval();
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackResumedEvent>} args
     *
     */
    public override onPlaybackResumed(
        provider: PlaybackMetricsProvider,
        args: PlaybackResumedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackResumedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackResumed', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.resumed,
                args,
                provider
            )
        );

        this.startRecordingSamples(provider);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingStartedEvent>} args
     *
     */
    public override onRebufferingStarted(
        provider: PlaybackMetricsProvider,
        args: RebufferingStartedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(RebufferingStartedEventTypedef)
            };

            typecheck.warn(this, 'onRebufferingStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.rebufferingStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.RebufferingEndedEvent>} args
     *
     */
    public override onRebufferingEnded(
        provider: PlaybackMetricsProvider,
        args: RebufferingEndedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(RebufferingEndedEventTypedef)
            };

            typecheck.warn(this, 'onRebufferingEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.rebufferingEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackEndedEvent>} args
     *
     */
    public override async onPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: PlaybackEndedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackEndedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackEnded', params, arguments);
        }

        const sampleType = HeartbeatSampleType.state;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(PlaybackActivity.ended, args, provider)
        );

        this.clearStreamInterval();

        this.heartbeatSampleTime = undefined;
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackInitializedEvent>} args
     *
     */
    public override onPlaybackInitialized(
        provider: PlaybackMetricsProvider,
        args: PlaybackInitializedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackInitializedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackInitialized', params, arguments);
        }

        this.playbackMode = provider.initialPlaybackMode;

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.initialized,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 27.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} args
     *
     */
    public override onPlaybackModeChanged(
        provider: PlaybackMetricsProvider,
        args: PlaybackMode
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.in(PlaybackMode)
            };

            typecheck.warn(this, 'onPlaybackModeChanged', params, arguments);
        }

        this.playbackMode = args;

        const sampleType = HeartbeatSampleType.user;
        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackReadyEvent>} args
     *
     */
    public override onPlaybackReady(
        provider: PlaybackMetricsProvider,
        args: PlaybackReadyEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackReadyEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackReady', params, arguments);
        }

        const {
            playbackRequestedAtTimestamp,
            artificialDelayDuration = 0,
            isNsaIncludedInVst = true
        } = this.mediaItem?.playbackContext || {};

        if (
            playbackRequestedAtTimestamp !== null &&
            playbackRequestedAtTimestamp !== undefined
        ) {
            this.totalVst = Date.now() - playbackRequestedAtTimestamp;

            if (isNsaIncludedInVst) {
                this.totalVst -= artificialDelayDuration;
            }
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.ready,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekStartedEvent>} args
     *
     */
    public override onPlaybackSeekStarted(
        provider: PlaybackMetricsProvider,
        args: PlaybackSeekStartedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackSeekStartedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackSeekStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.seekStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackSeekEndedEvent>} args
     *
     */
    public override onPlaybackSeekEnded(
        provider: PlaybackMetricsProvider,
        args: PlaybackSeekEndedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PlaybackSeekEndedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackSeekEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.seekEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     *
     */
    public override onAudioChanged(provider: PlaybackMetricsProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider)
            };

            typecheck.warn(this, 'onAudioChanged', params, arguments);
        }

        const sampleType = HeartbeatSampleType.user;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AudioBitrateChangedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public override onAudioBitrateChanged(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: AudioBitrateChangedEvent
    ) {
        // no-op
    }

    /**
     *
     * @access public
     * @since 19.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.SnapshotCreatedEvent>} args
     * @throws {Error} This method is not implemented
     * @returns {Void}
     *
     */
    public override onSnapshotCreated(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        provider: PlaybackMetricsProvider,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        args: SnapshotCreatedEvent
    ) {
        // no-op
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     *
     */
    public override onSubtitleChanged(provider: PlaybackMetricsProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider)
            };

            typecheck.warn(this, 'onSubtitleChanged', params, arguments);
        }

        const sampleType = HeartbeatSampleType.user;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.BitrateChangedEvent>} args
     *
     */
    public override onBitrateChanged(
        provider: PlaybackMetricsProvider,
        args: BitrateChangedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(BitrateChangedEventTypedef)
            };

            typecheck.warn(this, 'onBitrateChanged', params, arguments);
        }

        const { bitratePeak, bitrateAvg } = args;

        const options = {
            ...args,
            videoBitrate: bitratePeak,
            videoAverageBitrate: bitrateAvg
        };

        const { currentBitratePeak } = provider.getPlaybackMetrics();

        this.setMaxAttainedBitrate(currentBitratePeak);

        const playbackState = provider.getPlaybackState();

        if (playbackState === PlaybackState.playing) {
            const sampleType = HeartbeatSampleType.responsive;

            const playbackHeartbeatEventData =
                this.createPlaybackHeartbeatEventData(provider, sampleType);

            this.onPlaybackSampled(playbackHeartbeatEventData);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.bitrateChanged,
                options,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEvent>} args
     *
     */
    public override onMultivariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: MultivariantPlaylistFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(MultivariantPlaylistFetchedEventTypedef)
            };

            typecheck.warn(
                this,
                'onMultivariantPlaylistFetched',
                params,
                arguments
            );
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.masterFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     *
     */
    public override onVariantPlaylistFetched(
        provider: PlaybackMetricsProvider,
        args: VariantPlaylistFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(VariantPlaylistFetchedEventTypedef)
            };

            typecheck.warn(this, 'onVariantPlaylistFetched', params, arguments);
        }

        if (this.shouldReportVariantFetched(args)) {
            if (this.hasPlaybackStarted) {
                this.logQoeEvent(
                    QualityOfServiceDustUrnReference.playback,
                    this.createPlaybackEventData(
                        PlaybackActivity.variantFetched,
                        args,
                        provider
                    )
                );
            } else {
                this.logQoeEvent(
                    QualityOfServiceDustUrnReference.playbackStartup,
                    this.createPlaybackStartupEventData(
                        StartupActivity.variantFetched,
                        args,
                        provider
                    )
                );
            }
        }
    }

    /**
     *
     * @access protected
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackHeartbeatEventData} args
     *
     */
    public onPlaybackSampled(args: TodoAny) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                args: Types.instanceStrict(PlaybackHeartbeatEventData)
            };

            typecheck.warn(this, 'onPlaybackSampled', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackHeartbeat,
            args
        );
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onDrmKeyFetched(
        provider: PlaybackMetricsProvider,
        args: DrmKeyFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(DrmKeyFetchedEventTypedef)
            };

            typecheck.warn(this, 'onDrmKeyFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackStartup,
            this.createPlaybackStartupEventData(
                StartupActivity.drmKeyFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.PlaybackStartupEventData} args
     * @note Due to how reattempts are tracked, properties that are set to
     * undefined need to be removed to prevent data being overwritten with undefined.
     *
     */
    public override onPlaybackReattempt(
        provider: PlaybackMetricsProvider,
        args: PlaybackStartupEventData
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.instanceStrict(PlaybackStartupEventData)
            };

            typecheck.warn(this, 'onPlaybackReattempt', params, arguments);
        }

        const reattemptData = {} as Record<
            keyof PlaybackStartupEventData,
            unknown
        >;

        Object.keys(args).forEach((key) => {
            const value = args[key];

            if (value !== undefined) {
                reattemptData[key] = value;
            }
        });

        if (this.hasPlaybackStarted) {
            const {
                cdnName,
                cdnVendor,
                cdnWithOrigin,
                playbackError: error,
                playbackErrorDetail: errorDetail
            } = reattemptData;

            this.onMultivariantPlaylistFallback(provider, {
                cdnName,
                cdnVendor,
                cdnWithOrigin,
                error,
                errorDetail
            } as MultivariantPlaylistFallbackEvent);
        } else {
            this.logQoeEvent(
                QualityOfServiceDustUrnReference.playbackStartup,
                this.createPlaybackStartupEventData(
                    StartupActivity.reattempt,
                    reattemptData,
                    provider
                )
            );
        }
    }

    /**
     *
     * @access protected
     * @since 7.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onSuccessfulPlaylistLoad(
        provider: PlaybackMetricsProvider,
        args: TodoAny
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object()
            };

            typecheck.warn(this, 'onSuccessfulPlaylistLoad', params, arguments);
        }

        const tracking = args.tracking;

        if (this.mediaItem) {
            this.mediaItem.priorityTracking = args.priority;
        }

        if (tracking) {
            const telemetryData = tracking.telemetry;

            if (telemetryData) {
                const trackingInfo = this.playlist?.getTrackingInfo(
                    MediaAnalyticsKey.telemetry
                );

                this.serverData = Object.assign(
                    {},
                    trackingInfo,
                    telemetryData
                );
            }
        }
    }

    /**
     *
     * @access public
     * @since 15.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public onPlaybackError(
        provider: PlaybackMetricsProvider,
        args: PlaybackErrorInfo
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object()
            };

            typecheck.warn(this, 'onPlaybackError', params, arguments);
        }

        const { analyticsProvider } = this.logger;
        const { playbackContext, payload, descriptor } = this.mediaItem || {};
        const { productType, contentKeys, offline, interactionId } =
            playbackContext || {};
        const { videoPlayerName, videoPlayerVersion } = provider || {};
        const {
            isFatal,
            adSlotData: adSlot,
            adPodData: adPod,
            adPodPlacement: adPlacement
        } = args;

        // HACK - Ignoring non-fatal errors to un-block a go-live of QoE due to too many events flowing through the system - We'll have to solve this differently (possibly using sampling/rate-limiting in the future) but ignore for now RE: SDKMRJS-4916
        if (!isFatal) {
            return;
        }

        const adInsertionType =
            AdInsertionType[
                (
                    descriptor as MediaDescriptor
                ).assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];
        const presentationType = this.presentationTypeCache.presentationType;
        const applicationContext =
            presentationType === PresentationType.ad
                ? ApplicationContext.ad
                : ApplicationContext.player;

        const { adSession, subscriptionType: adSubscriptionType } =
            payload?.stream.adsQos || {};

        const { id: sessionId = DEFAULT_ZERO_GUID, hasSlugs } = adSession || {};

        const playbackMetrics = provider.getPlaybackMetrics();
        const { currentBitrate, currentBitrateAvg } = playbackMetrics;
        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        let dictionaryVersion;
        let adSessionId;
        let subscriptionType;
        let adSlotData;
        let adPodData;
        let adPodPlacement;

        if (analyticsProvider) {
            dictionaryVersion = analyticsProvider.getDictionaryVersion();
        }

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = sessionId;
            subscriptionType = adSubscriptionType;
        }

        if (applicationContext === ApplicationContext.ad) {
            adSlotData = adSlot;
            adPodData = adPod;
            adPodPlacement = adPlacement;
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            new ErrorEventData({
                applicationContext,
                source: ErrorSource.player,
                productType,
                videoBitrate: currentBitrate,
                videoAverageBitrate: currentBitrateAvg,
                audioBitrate: provider.getAudioBitrate(),
                maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
                cdnName: provider.getCdnName(),
                dictionaryVersion,
                contentKeys,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                ...args,
                adSlotData,
                adPodPlacement,
                adPodData,
                hasSlugs,
                monotonicTimestamp,
                videoPlayerName,
                videoPlayerVersion,
                localMedia: offline,
                interactionId,
                mediaFetchSucceeded: true,
                qoe,
                maxAttainedBitrate: this.maxAttainedBitrate,
                programBoundaryInfoBlock: this.programBoundaryInfoBlock
            })
        );
    }

    /**
     *
     * @access public
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEvent>} args
     *
     */
    public onAdBeaconError(
        provider: PlaybackMetricsProvider,
        args: NonFatalPlaybackErrorEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(NonFatalPlaybackErrorEventTypedef)
            };

            typecheck.warn(this, 'onAdBeaconError', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            this.createAdErrorEventData(provider, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.NonFatalPlaybackErrorEvent>} args
     *
     */
    public onAdRequestedError(
        provider: PlaybackMetricsProvider,
        args: NonFatalPlaybackErrorEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(NonFatalPlaybackErrorEventTypedef)
            };

            typecheck.warn(this, 'onAdRequestedError', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.error,
            this.createAdErrorEventData(provider, args)
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.AdPodRequestedEvent>} args
     *
     */
    public override onAdPodRequested(
        provider: PlaybackMetricsProvider,
        args: AdPodRequestedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdPodRequestedEventTypedef)
            };

            typecheck.warn(this, 'onAdPodRequested', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adPodRequested,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onAdPodFetched(
        provider: PlaybackMetricsProvider,
        args: AdPodFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdPodFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdPodFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adPodFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onAdMultivariantFetched(
        provider: PlaybackMetricsProvider,
        args: AdMultivariantFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdMultivariantFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdMultivariantFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adMultivariantFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onAdVariantFetched(
        provider: PlaybackMetricsProvider,
        args: AdVariantFetchedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdVariantFetchedEventTypedef)
            };

            typecheck.warn(this, 'onAdVariantFetched', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adVariantFetched,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onAdPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: AdPlaybackStartedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdPlaybackStartedEventTypedef)
            };

            typecheck.warn(this, 'onAdPlaybackStarted', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adPlaybackStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onAdPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: AdPlaybackEndedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(AdPlaybackEndedEventTypedef)
            };

            typecheck.warn(this, 'onAdPlaybackEnded', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playbackAd,
            this.createPlaybackAdEventData(
                AdActivity.adPlaybackEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 20.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} args
     *
     */
    public override onPresentationTypeChanged(
        provider: PlaybackMetricsProvider,
        args: PresentationTypeChangedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(PresentationTypeChangedEventTypedef)
            };

            typecheck.warn(
                this,
                'onPresentationTypeChanged',
                params,
                arguments
            );
        }

        this.presentationTypeCache = args;

        const { currentBitratePeak } = provider.getPlaybackMetrics();

        this.setMaxAttainedBitrate(currentBitratePeak);

        const sampleType = HeartbeatSampleType.responsive;
        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.ViewingEnvironmentChangedEvent>} args
     *
     */
    public override onViewingEnvironmentChanged(
        provider: PlaybackMetricsProvider, // eslint-disable-line
        args: ViewingEnvironmentChangedEvent // eslint-disable-line
    ) {
        /* istanbul ignore else */
        // if (__SDK_TYPECHECK__) {
        //     const params = {
        //         provider: Types.instanceStrict(PlaybackMetricsProvider),
        //         args: Types.object(ViewingEnvironmentChangedEventTypedef)
        //     };
        //     typecheck.warn(
        //         this,
        //         'onViewingEnvironmentChanged',
        //         params,
        //         arguments
        //     );
        // }
        // no-op
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.InterstitialPlaybackStartedEvent>} args
     *
     */
    public override onInterstitialPlaybackStarted(
        provider: PlaybackMetricsProvider,
        args: InterstitialPlaybackStartedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(InterstitialPlaybackStartedEventTypedef)
            };

            typecheck.warn(
                this,
                'onInterstitialPlaybackStarted',
                params,
                arguments
            );
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.interstitial,
            this.createInterstitialEventData(
                InterstitialActivity.interstitialStarted,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.InterstitialPlaybackEndedEvent>} args
     *
     */
    public override onInterstitialPlaybackEnded(
        provider: PlaybackMetricsProvider,
        args: InterstitialPlaybackEndedEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(InterstitialPlaybackEndedEventTypedef)
            };

            typecheck.warn(
                this,
                'onInterstitialPlaybackEnded',
                params,
                arguments
            );
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.interstitial,
            this.createInterstitialEventData(
                InterstitialActivity.interstitialEnded,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.MultivariantPlaylistFallbackEvent>} args
     *
     */
    public override onMultivariantPlaylistFallback(
        provider: PlaybackMetricsProvider,
        args: MultivariantPlaylistFallbackEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(MultivariantPlaylistFallbackEventTypedef)
            };

            typecheck.warn(
                this,
                'onMultivariantPlaylistFallback',
                params,
                arguments
            );
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.playback,
            this.createPlaybackEventData(
                PlaybackActivity.reattempt,
                args,
                provider
            )
        );
    }

    /**
     *
     * @access private
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @returns {Promise<Void>}
     *
     */
    private startRecordingSamples(
        playbackMetricsProvider: PlaybackMetricsProvider
    ) {
        return this.timedRecordStreamSample(playbackMetricsProvider);
    }

    /**
     *
     * @access private
     * @param {SDK.Media.PlaybackMetricsProvider} playbackMetricsProvider
     * @returns {Promise<Void>}
     *
     */
    private timedRecordStreamSample(
        playbackMetricsProvider: PlaybackMetricsProvider
    ) {
        const { isInitialized, interval } = this;

        if (!isInitialized) {
            return Promise.resolve();
        }

        this.clearStreamInterval();

        return this.recordStreamSample(playbackMetricsProvider).then(() => {
            this.intervalId = setTimeout(() => {
                this.timedRecordStreamSample(playbackMetricsProvider);
            }, interval * 1000) as unknown as number;
        });
    }

    /**
     *
     * @access public
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Boolean} [isManual=false]
     * @returns {Promise<Void>}
     *
     */
    public async recordStreamSample(
        provider: PlaybackMetricsProvider,
        isManual = false
    ) {
        if (!this.isInitialized) {
            return;
        }

        const { serverData, mediaItem, extraData, manager } = this;

        const metrics = provider.getPlaybackMetrics();

        const playbackSessionId = this.sessionId;
        const { playbackContext } = mediaItem || {};
        const { currentPlayhead, currentBitrate } = metrics;
        const { interactionId } = playbackContext || {};

        const playhead = Check.number(currentPlayhead)
            ? Math.floor(currentPlayhead / 1000)
            : null;

        if (!isManual) {
            const sampleType = HeartbeatSampleType.periodic;

            const playbackHeartbeatEventData =
                this.createPlaybackHeartbeatEventData(provider, sampleType);

            this.onPlaybackSampled(playbackHeartbeatEventData);
        }

        if (
            !(
                manager.config.extras.preferQoeOverStreamSamples &&
                playbackContext?.mediaLocatorType ===
                    MediaLocatorType.resourceId
            )
        ) {
            const streamSampleTelemetryEvent = new StreamSampleTelemetryEvent({
                playbackSessionId: playbackSessionId as string,
                playhead: playhead as number,
                bitrate: currentBitrate as number,
                serverData,
                interactionId,
                extraData
            });

            manager.streamSampleBuffer.postEvent(streamSampleTelemetryEvent);
        }
    }

    /**
     *
     * @access public
     * @since 28.4.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {String} programBoundaryInfoBlock
     *
     */
    public override updateProgramMetadata(
        provider: PlaybackMetricsProvider,
        programBoundaryInfoBlock: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                programBoundaryInfoBlock: Types.nonEmptyString
            };

            typecheck.warn(this, 'updateProgramMetadata', params, arguments);
        }

        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        const sampleType = HeartbeatSampleType.responsive;

        const playbackHeartbeatEventData =
            this.createPlaybackHeartbeatEventData(provider, sampleType);

        this.onPlaybackSampled(playbackHeartbeatEventData);
    }

    /**
     *
     * @access private
     * @desc Clears the stream interval for sending telemetry events
     *
     */
    private clearStreamInterval() {
        clearTimeout(this.intervalId);
        this.intervalId = -1;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Gets the qos decision object based on the current playlist type.
     *
     */
    private getQosDecision(
        qosDecisions:
            | QosDecisionsResponse
            | { slide?: undefined; complete?: undefined }
    ) {
        if (this.playlist?.playlistType === PlaylistType.SLIDE) {
            return qosDecisions.slide;
        }

        return qosDecisions.complete;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {String<SDK.Services.QualityOfService.PlaybackActivity>} playbackActivity
     * @param {Object} eventData
     * @param {PlaybackMetricsProvider} provider
     * @desc returns a PlaybackEventData instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackEventData}
     *
     */
    private createPlaybackEventData(
        playbackActivity: PlaybackActivity,
        eventData: TodoAny,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { playbackContext, descriptor, payload } = this.mediaItem || {};
        const { videoPlayerName, videoPlayerVersion } = provider || {};
        const mediaSegmentType =
            MediaSegmentTypeMap[
                eventData?.rendition?.type as keyof typeof MediaSegmentTypeMap
            ];

        const {
            contentKeys = {},
            productType,
            offline = false,
            interactionId,
            data
        } = playbackContext || {};

        const streamUrl = provider.currentStreamUrl || provider.playlistUri;
        const presentationType = this.presentationTypeCache.presentationType;
        const adInsertionType =
            AdInsertionType[
                descriptor?.assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];
        const metrics = provider.getPlaybackMetrics();
        const { adMetadata, liveLatencyAmount, segmentPosition } = metrics;
        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        let adSessionId;
        let subscriptionType;
        let adPodPlacement;
        let adPodData;
        let adSlotData;
        let adPlayheadPosition;
        let cdnVendor;
        let cdnWithOrigin;

        if (playbackActivity === PlaybackActivity.ended) {
            const qosData = this.playlist?.getTrackingInfo(
                MediaAnalyticsKey.qos
            );

            cdnVendor = qosData?.cdnVendor ?? 'null';
            cdnWithOrigin = qosData?.cdnWithOrigin ?? 'null';
        }

        const { adSession, subscriptionType: adSubscriptionType } =
            payload?.stream.adsQos || {};

        const { id: sessionId = DEFAULT_ZERO_GUID, hasSlugs } = adSession || {};

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = sessionId;
            subscriptionType = adSubscriptionType;
        }

        if (presentationType === PresentationType.ad && adMetadata) {
            ({ adPodPlacement, adPodData, adSlotData, adPlayheadPosition } =
                adMetadata);
        }

        let skipType = provider.getSeekData().skipType;

        if (
            (playbackActivity === PlaybackActivity.seekStarted ||
                playbackActivity === PlaybackActivity.seekEnded) &&
            eventData.cause === PlaybackSeekCause.seek
        ) {
            skipType ||= SkipType.skipGeneral;
        }

        return new PlaybackEventData({
            maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
            playbackActivity,
            productType,
            streamUrl,
            cdnName: Check.function(provider.getCdnName)
                ? provider.getCdnName()
                : 'null',
            cdnVendor,
            cdnWithOrigin,
            contentKeys,
            presentationType,
            adInsertionType,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition,
            monotonicTimestamp,
            hasSlugs,
            videoPlayerName,
            videoPlayerVersion,
            localMedia: offline,
            interactionId,
            qoe,
            data,
            maxAttainedBitrate: this.maxAttainedBitrate,
            mediaSegmentType,
            liveLatencyAmount,
            segmentPosition,
            adMetadata,
            skipType,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.1
     * @param {SDK.Services.QualityOfService.AdInsertionType} adInsertionType
     * @desc Helper to determine if adsQos exists and adInsertionType is `none`
     * @returns {Boolean}
     *
     */
    private canUseQosInfo(adInsertionType: AdInsertionType) {
        return adInsertionType !== AdInsertionType.none;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {String<SDK.Services.QualityOfService.StartupActivity>} startupActivity
     * @param {Object} eventData
     * @param {PlayerAdapter} provider
     * @desc Returns a `PlaybackStartupEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackStartupEventData|undefined}
     *
     */
    private createPlaybackStartupEventData(
        startupActivity: TodoAny,
        eventData: TodoAny,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { playbackContext, payload, descriptor } = this.mediaItem || {};
        const { videoPlayerName, videoPlayerVersion } = provider;

        const { attributes } = payload?.stream || {};

        const {
            contentKeys = {},
            productType,
            isPreBuffering: mediaPreBuffer,
            offline: localMedia,
            startupContext,
            interactionId
        } = playbackContext || {};

        const cpuPercentage =
            this.platformMetricsProvider.availableCpuPercentage();
        const freeMemory = this.platformMetricsProvider.availableMemoryMb();
        const playbackIntent = Check.assigned(playbackContext)
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              playbackContext!.playbackIntent
            : null;
        const streamVariants = this.mediaItem?.getVariants();
        const adInsertionType =
            AdInsertionType[
                descriptor?.assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];

        const {
            adSession,
            subscriptionType: adSubscriptionType,
            getPods
        } = payload?.stream.adsQos || {};

        const {
            id: sessionId = DEFAULT_ZERO_GUID,
            responseCode,
            hasSlugs
        } = adSession || {};

        const insertion = payload?.stream.insertion;
        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        const streamUrl =
            eventData.streamUrl ||
            provider.currentStreamUrl ||
            provider.playlistUri ||
            undefined;

        let adSessionId;
        let subscriptionType;
        let totalPodCount = 0;
        let totalSlotCount: number | undefined;
        let totalAdLength = 0;
        let createAdSessionResponseCode;
        let getPodsResponseCode;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = sessionId;
            subscriptionType = adSubscriptionType;
            createAdSessionResponseCode = responseCode;

            if (adInsertionType === AdInsertionType.ssai) {
                getPodsResponseCode = getPods?.responseCode;
            }
        }

        if (insertion && insertion.points) {
            totalPodCount = insertion.points.length;

            if (adInsertionType === AdInsertionType.ssai) {
                insertion.points.forEach((insertionPoint: TodoAny) => {
                    const { plannedSlotCount, duration } = insertionPoint;

                    totalSlotCount = totalSlotCount
                        ? totalSlotCount + plannedSlotCount
                        : plannedSlotCount;
                    totalAdLength = totalAdLength
                        ? totalAdLength + duration
                        : duration;
                });
            }
        }

        return new PlaybackStartupEventData({
            startupActivity,
            productType,
            playbackIntent,
            mediaPreBuffer,
            streamUrl,
            videoPlayerName,
            videoPlayerVersion,
            localMedia,
            cpuPercentage,
            freeMemory,
            contentKeys,
            attributes,
            streamVariants,
            // @ts-ignore TODO fix qos not on provider (it's on sub-providers)
            qos: provider.qos || {},
            startupContext,
            adInsertionType,
            subscriptionType,
            adSessionId,
            totalPodCount,
            totalSlotCount,
            totalAdLength,
            createAdSessionResponseCode,
            getPodsResponseCode,
            monotonicTimestamp,
            hasSlugs,
            interactionId,
            qoe,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {Number} currentPlayhead
     * @desc Returns the duration in milliseconds since the last player heartbeat
     * @returns {Number}
     *
     */
    private getPlaybackDuration(currentPlayhead?: Nullable<number>) {
        const current = currentPlayhead || 0;
        const originalTime = this.heartbeatSampleTime ?? current;

        this.heartbeatSampleTime = current;

        return current - originalTime;
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @param {PlaybackMetricsProvider} provider
     * @param {SDK.Services.QualityOfService.HeartbeatSampleType} sampleType
     * @desc Returns a `PlaybackHeartbeatEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackHeartbeatEventData|undefined}
     *
     */
    private createPlaybackHeartbeatEventData(
        provider: PlaybackMetricsProvider,
        sampleType: HeartbeatSampleType
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const {
            platformMetricsProvider,
            totalVst,
            maxAttainedBitrate,
            playbackMode,
            programBoundaryInfoBlock
        } = this;

        const cpuPercentage = platformMetricsProvider.availableCpuPercentage();
        const freeMemory = platformMetricsProvider.availableMemoryMb();
        const metrics = provider.getPlaybackMetrics();

        const { playbackContext, payload, descriptor } = this.mediaItem || {};
        const {
            currentPlayhead,
            currentBitratePeak,
            currentBitrateAvg,
            adMetadata
        } = metrics;

        const {
            productType,
            artificialDelayDuration,
            contentKeys,
            offline,
            interactionId,
            data
        } = playbackContext || {};

        const {
            presentationType,
            playlistAudioTrackType,
            playlistTimedTextTrackType
        } = this.presentationTypeCache;

        const adInsertionType =
            AdInsertionType[
                descriptor?.assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];

        const { adSession, subscriptionType: adSubscriptionType } =
            payload?.stream.adsQos || {};

        const { id: sessionId = DEFAULT_ZERO_GUID, hasSlugs } = adSession || {};

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        let adSessionId;
        let subscriptionType;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = sessionId;
            subscriptionType = adSubscriptionType;
        }

        const playbackDuration = this.getPlaybackDuration(currentPlayhead);

        let playbackHeartbeatEventData;
        let adPodPlacement;
        let adSlotData;
        let adPodData;
        let adPlayheadPosition;

        if (presentationType === PresentationType.ad) {
            ({ adPodPlacement, adPodData, adSlotData, adPlayheadPosition } =
                adMetadata || {});
        }

        const heartbeatData = provider.getHeartbeatData();
        const { videoPlayerName, videoPlayerVersion } = provider;

        if (Check.instanceStrict(provider, DssWebPlayerAdapter)) {
            playbackHeartbeatEventData = new PlaybackHeartbeatEventData({
                ...metrics,
                ...heartbeatData,
                productType,
                playbackDuration,
                cdnName: provider.getCdnName(),
                cpuPercentage,
                freeMemory,
                artificialDelayDuration,
                contentKeys,
                sampleType,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                adPodPlacement,
                adPodData,
                adSlotData,
                adPlayheadPosition,
                hasSlugs,
                videoPlayerName,
                videoPlayerVersion,
                localMedia: offline,
                qoe,
                interactionId,
                data,
                playlistAudioTrackType,
                playlistTimedTextTrackType,
                maxAttainedBitrate,
                playbackMode,
                monotonicTimestamp,
                programBoundaryInfoBlock
            });
        } else {
            playbackHeartbeatEventData = new PlaybackHeartbeatEventData({
                ...metrics,
                ...heartbeatData,
                productType,
                playbackDuration,
                totalVst,
                playheadPosition: currentPlayhead,
                videoBitrate: currentBitratePeak,
                videoAverageBitrate: currentBitrateAvg,
                audioBitrate: provider.getAudioBitrate(),
                maxAllowedVideoBitrate: provider.getMaxAllowedVideoBitrate(),
                cdnName: provider.getCdnName(),
                cpuPercentage,
                freeMemory,
                artificialDelayDuration,
                contentKeys,
                sampleType,
                // networkType,
                mediaDownloadTotalCount: provider.mediaDownloadTotalCount,
                mediaDownloadTotalTime: provider.mediaDownloadTotalTime,
                presentationType,
                adInsertionType,
                adSessionId,
                subscriptionType,
                adPodPlacement,
                adPodData,
                adSlotData,
                adPlayheadPosition,
                hasSlugs,
                videoPlayerName,
                videoPlayerVersion,
                localMedia: offline,
                interactionId,
                qoe,
                data,
                playlistAudioTrackType,
                playlistTimedTextTrackType,
                maxAttainedBitrate,
                playbackMode,
                monotonicTimestamp,
                programBoundaryInfoBlock
            });

            (provider as TodoAny).mediaDownloadTotalCount = 0;
            (provider as TodoAny).mediaDownloadTotalTime = 0;
        }

        return playbackHeartbeatEventData;
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {String<SDK.Services.QualityOfService.AdActivity>} adActivity
     * @param {Object} eventData
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @desc Returns a `PlaybackAdEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackAdEventData|undefined}
     *
     */
    private createPlaybackAdEventData(
        adActivity: AdActivity,
        eventData: TodoAny,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const { videoPlayerName, videoPlayerVersion } = provider;
        const { playbackContext, payload, descriptor } = this.mediaItem || {};

        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const { adSession, subscriptionType: adSubscriptionType } =
            payload?.stream.adsQos || {};

        const { id: sessionId = DEFAULT_ZERO_GUID, hasSlugs } = adSession || {};

        const adInsertionType =
            AdInsertionType[
                descriptor?.assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];

        const {
            contentKeys = {},
            productType,
            interactionId,
            data
        } = playbackContext || {};

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        let adStartupData;
        let networkType;

        if (eventData.serverRequest) {
            let startTimestamp = monotonicTimestamp;

            if (adActivity !== AdActivity.adPodFetched) {
                startTimestamp =
                    monotonicTimestamp - eventData.serverRequest.roundTripTime;
            }

            adStartupData = {
                startTimestamp: startTimestamp || 0,
                requestDuration: eventData.serverRequest.roundTripTime || 0
            };

            networkType = eventData.serverRequest.networkType;
        }

        let adSessionId;
        let subscriptionType;

        if (this.canUseQosInfo(adInsertionType)) {
            adSessionId = sessionId;
            subscriptionType = adSubscriptionType;
        }

        return new PlaybackAdEventData({
            adActivity,
            adSessionId,
            adInsertionType,
            subscriptionType,
            productType,
            contentKeys,
            networkType,
            monotonicTimestamp,
            adStartupData,
            hasSlugs,
            videoPlayerName,
            videoPlayerVersion,
            interactionId,
            qoe,
            data,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock,
            ...eventData
        });
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object} eventData
     * @desc Returns a `PlaybackAdEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.ErrorEventData|undefined}
     *
     */
    private createAdErrorEventData(
        provider: PlaybackMetricsProvider,
        eventData: TodoAny
    ) {
        const {
            error: errorName,
            errorLevel,
            applicationContext = ApplicationContext.ad,
            errorDetail: errorMessage
        } = eventData;

        const { payload, descriptor, playbackContext } = this.mediaItem || {};
        const { subscriptionType, adSession } = payload?.stream.adsQos || {};
        const { id: adSessionId = DEFAULT_ZERO_GUID, hasSlugs } =
            adSession || {};
        const { assetInsertionStrategy } = descriptor || {};
        const { productType, interactionId, offline, data } =
            playbackContext || {};
        const { videoPlayerName, videoPlayerVersion } = provider || {};
        const { adMetadata } = provider.getPlaybackMetrics();

        const adInsertionType =
            assetInsertionStrategy &&
            AdInsertionType[
                assetInsertionStrategy.toLowerCase() as AdInsertionType
            ];

        const podPosition = adMetadata?.adPodPlacement.podPosition;

        let presentationType = PresentationType.ad;

        // we don't get the ad, so `presentationType` should change
        if (errorLevel === ErrorLevel.error) {
            if (podPosition === PodPosition.preroll) {
                presentationType = PresentationType.unknown;
            } else if (podPosition === PodPosition.midroll) {
                presentationType = PresentationType.main;
            }
        }

        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        return new ErrorEventData({
            ...adMetadata,
            adInsertionType,
            adSessionId,
            applicationContext,
            errorLevel,
            errorMessage,
            errorName,
            isFatal: false,
            presentationType,
            subscriptionType,
            productType,
            source: ErrorSource.player,
            hasSlugs,
            monotonicTimestamp,
            videoPlayerName,
            videoPlayerVersion,
            localMedia: offline,
            interactionId,
            qoe,
            data,
            maxAttainedBitrate: this.maxAttainedBitrate,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock
        });
    }

    /**
     *
     * @access private
     * @since 28.1.0
     * @param {InterstitialActivity} interstitialActivity
     * @param {Object} eventData
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @desc Returns a `InterstitialEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.InterstitialEventData|undefined}
     *
     */
    private createInterstitialEventData(
        interstitialActivity: InterstitialActivity,
        eventData:
            | InterstitialPlaybackStartedEvent
            | InterstitialPlaybackEndedEvent,
        provider: PlaybackMetricsProvider
    ) {
        if (!this.isInitialized) {
            return undefined;
        }

        const monotonicTimestamp =
            this.monotonicTimestampProvider.getTimestamp();

        const qoe = this.playlist?.getTrackingInfo(MediaAnalyticsKey.qoe);

        const { videoPlayerName, videoPlayerVersion } = provider;
        const { playbackContext, payload } = this.mediaItem || {};
        const { subscriptionType, adSession } = payload?.stream.adsQos || {};
        const { hasSlugs } = adSession || {};

        const {
            contentKeys = {},
            data,
            productType,
            interactionId
        } = playbackContext || {};

        return new InterstitialEventData({
            ...eventData,
            interstitialActivity,
            interstitialInsertionType: InterstitialInsertionType.ssai,
            data,
            contentKeys,
            interactionId,
            monotonicTimestamp,
            networkType: NetworkType.unknown,
            productType,
            mediaFetchSucceeded: true,
            videoPlayerName,
            videoPlayerVersion,
            hasSlugs,
            subscriptionType,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock,
            qoe
        });
    }

    /**
     *
     * @access private
     * @since 28.2.0
     * @param {Object<SDK.Media.PlaybackCustomEvent>} eventData
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @desc Returns a `PlaybackCustomEventData` instance to be used by the `logQoeEvent` method.
     * @returns {SDK.Services.QualityOfService.PlaybackCustomEventData}
     *
     */
    private createPlaybackCustomEventData(
        eventData: PlaybackCustomEvent,
        provider: PlaybackMetricsProvider
    ) {
        const metrics = provider.getPlaybackMetrics();

        const { playbackContext } = this.mediaItem || {};

        const { interactionId, data } = playbackContext || {};

        return new PlaybackCustomEventData({
            ...metrics,
            ...eventData,
            playbackSessionId: this.sessionId!,
            data,
            interactionId,
            programBoundaryInfoBlock: this.programBoundaryInfoBlock
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Services.Media.PlaybackContext} playbackContext
     * @returns {Object}
     *
     */
    private getCommonProperties(playbackContext?: PlaybackContext) {
        const { analyticsProvider } = this.logger;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let data = Check.assigned(playbackContext) ? playbackContext!.data : {};

        if (analyticsProvider) {
            const commonProperties = analyticsProvider.getCommonProperties(
                playbackContext?.playbackSessionId
            );

            data = {
                ...data,
                ...commonProperties
            };
        }

        return data;
    }

    /**
     *
     * @access private
     * @param {String} urn
     * @param {Object} eventData
     *
     */
    private logQoeEvent(urn: string, eventData: TodoAny) {
        if (!this.isInitialized) {
            return;
        }

        const { logger, qoeEnabled } = this;

        const { playbackContext, payload } = this.mediaItem || {};

        const commonData = this.getCommonProperties(playbackContext);

        const playbackSessionId = playbackContext?.playbackSessionId;

        const { qosDecisions = {} } = payload?.stream || {};

        const qosDecision =
            this.getQosDecision(qosDecisions) || ({} as QosDecisions);

        const {
            clientDecisions = {} as ClientDecisions,
            serverDecisions = {} as ServerDecisions
        } = qosDecision;

        if (qoeEnabled && Check.nonEmptyString(playbackSessionId)) {
            const dataVersion = getDataVersion(urn);
            const dustLogUtility = new DustLogUtility({
                category: DustCategory.qoe,
                logger,
                source: this.toString(),
                urn,
                data: {
                    ...eventData,
                    ...commonData,
                    playbackSessionId,
                    clientGroupIds: clientDecisions.clientGroupIds,
                    serverGroupIds: serverDecisions.serverGroupIds
                },
                skipLogTransaction: true,
                dataVersion
            });

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     * @since 20.1.0
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} args
     * @desc Determines whether conditions allow for this variantFetched event to be reported.
     * @note In order to reduce the number of variantFetched events sent during live playback, the following filtering
     * rules are implemented:
     * 1. Errors are reported without filtering during VOD playback.
     * 2. During live playback, one error event is reported for each variant manifest that fails to load, even if the
     *    given variant fails repeatedly. This state resets when the same variant is fetched successfully at least once.
     * 3. During VOD playback, an event is generated every time a variant m3u8 is loaded successfully.
     * 4. During live playback of linear or event streams, the player reloads the active video, audio, and subtitle
     *    variants frequently. Successful loading events should only be reported during startup, and when a new variant
     *    is loaded due to ABR logic.
     * @returns {Boolean}
     *
     */
    private shouldReportVariantFetched(args: TodoAny) {
        let shouldReport = true;

        const { variantFetchedUris, mediaItem } = this;
        const { playbackContext } = mediaItem || {};
        const { productType } = playbackContext || {};

        // filtering should only apply to Live events
        if (productType === ProductType.live) {
            const { details: { type = undefined, url = undefined } = {} } =
                args || {};

            if (Check.assigned(type) && Check.assigned(url)) {
                const lowerType = type.toLowerCase();

                // report variantFetched if the variant URI has changed
                shouldReport = variantFetchedUris[lowerType] !== url;

                // keep track of the url for this variant type
                variantFetchedUris[lowerType] = url;
            }
        }

        return shouldReport;
    }

    /**
     *
     * @access private
     * @since 27.0.0
     * @param {Number|undefined} [currentBitratePeak]
     * @desc A helper to set `maxAttainedBitrate` when `currentBitratePeak` is present and greater than the cached value.
     *
     */
    private setMaxAttainedBitrate(currentBitratePeak?: Nullable<number>) {
        const bitratePeak = currentBitratePeak ?? 0;

        this.maxAttainedBitrate = this.maxAttainedBitrate || bitratePeak;

        if (this.maxAttainedBitrate < bitratePeak) {
            this.maxAttainedBitrate = bitratePeak;
        }
    }

    /**
     *
     * @access public
     * @since 28.2.0
     * @param {SDK.Media.PlaybackMetricsProvider} provider
     * @param {Object<SDK.Media.PlaybackCustomEvent>} args
     *
     */
    public override onPlaybackCustomEvent(
        provider: PlaybackMetricsProvider,
        args: PlaybackCustomEvent
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.instanceStrict(PlaybackMetricsProvider),
                args: Types.object(InterstitialPlaybackEndedEventTypedef)
            };

            typecheck.warn(this, 'onPlaybackCustomEvent', params, arguments);
        }

        this.logQoeEvent(
            QualityOfServiceDustUrnReference.custom,
            this.createPlaybackCustomEventData(args, provider)
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlaybackTelemetryDispatcher';
    }
}
