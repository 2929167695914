/**
 *
 * @module serviceCapacityErrorHandler
 *
 */

import ErrorCode from '../../../exception/errorCode';
import ErrorReason from '../../../exception/errorReason';
import { IErrorHandler } from './IErrorHandler';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Service capacity error handler.
 * @returns {Object}
 *
 */
const ServiceCapacityErrorHandler: IErrorHandler = {
    is: (options) => {
        const { isError, headers } = options;

        if (isError && headers?.get) {
            const code = headers.get(
                ErrorCode.serviceCapacity.header as string
            );

            if (code === ErrorCode.serviceCapacity.code) {
                return true;
            }
        }

        return false;
    },
    process: () => {
        return {
            errorReasons: [
                new ErrorReason({
                    code: ErrorCode.serviceCapacity.code,
                    description:
                        ErrorCode.serviceCapacity.exceptionData.message,
                    backoffSeconds: 120
                })
            ]
        };
    }
};

export default ServiceCapacityErrorHandler;
