/**
 *
 * @module exploreClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/explore.md
 * @see https://github.bamtech.co/pages/services-commons/api-registry/redoc/ExploreService.html
 * @see https://github.bamtech.co/services-commons/api-registry/tree/main/specs/cxd/exploreapi
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import HttpHeaders from '../providers/shared/httpHeaders';
import replaceHeaders from '../util/replaceHeaders';
import appendQuerystring from '../util/appendQuerystring';

import ExploreClientEndpoint from './exploreClientEndpoint';
import ExploreClientConfiguration from './exploreClientConfiguration';

import ExceptionReference from '../exception/exceptionReference';

import DustLogUtility from '../internal/dust/dustLogUtility';

import {
    DeeplinkQuery,
    DeeplinkQueryTypedef,
    DownloadMetadataInput,
    DownloadMetadataInputTypedef,
    ExploreParams,
    ExploreQuery,
    ExploreQueryTypedef,
    GetPartnerContinueWatchingQuery,
    GetPartnerContinueWatchingQueryTypedef,
    PageQuery,
    PageQueryTypedef,
    PlayerExperienceQuery,
    PlayerExperienceQueryTypedef,
    SearchQuery,
    SearchQueryTypedef,
    SeasonQuery,
    SeasonQueryTypedef,
    SetQuery,
    SetQueryTypedef,
    UpNextQuery,
    UpNextQueryTypedef,
    UserStateInput,
    UserStateInputTypedef
} from '../../explore/typedefs';

import { ExploreQueryResult } from './typedefs';

import { createSimpleException } from '../util/errorHandling/createException';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const ExploreClientDustUrnReference =
    DustUrnReference.services.explore.exploreClient;

/**
 *
 * @access protected
 * @since 23.1.0
 * @desc Provides a data client that can be used to access Explore services.
 *
 */
export default class ExploreClient extends ClientBase<ExploreClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Explore.ExploreClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: ExploreClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(ExploreClientConfiguration),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {Object} options
     * @param {SDK.Explore.PageQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for Page data.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/page-api.smithy
     * @returns {Promise<PageResponse>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getPage(options: {
        query: PageQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(PageQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPage', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const {
            version,
            pageId,
            enhancedContainersLimit,
            limit,
            exploreParams
        } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getPage,
            version,
            variables: {
                pageId
            },
            exploreParams: {
                ...exploreParams,
                enhancedContainersLimit,
                limit
            }
        };

        return this.query<PageQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {Object} options
     * @param {SDK.Explore.SetQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for Set data.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/set-api.smithy
     * @returns {Promise<SetResponse>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getSet(options: {
        query: SetQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(SetQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSet', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { version, setId, offset, limit, exploreParams } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getSet,
            version,
            variables: {
                setId
            },
            exploreParams: {
                ...exploreParams,
                offset,
                limit
            }
        };

        return this.query<SetQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {Object} options
     * @param {SDK.Explore.DeeplinkQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for a deeplink to the indicated user experience within a Disney application.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/deeplink-api.smithy
     * @returns {Promise<DeeplinkResponse>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getDeeplink(options: {
        query: DeeplinkQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(DeeplinkQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getDeeplink', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { action, version, refId, refIdType } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getDeeplink,
            version,
            exploreParams: {
                action,
                refId,
                refIdType
            }
        };

        return this.query<DeeplinkQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {Object} options
     * @param {SDK.Explore.UserStateInput} options.input
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Gets a map of personalization IDs (PIDs) to entity state for the active user.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/user-state-api.smithy
     * @returns {Promise<UserStateResponse>}
     *
     */
    public async getUserState(options: {
        input: UserStateInput;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    input: Types.object(UserStateInputTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getUserState', params, arguments);
        }

        const { input, accessToken, logTransaction } = options;
        const { pids, version } = input;

        const query: ExploreQuery = {
            context: ExploreClientEndpoint.getUserState,
            version,
            exploreParams: {
                pids
            }
        };

        return this.query<UserStateInput>({
            query,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {Object} options
     * @param {SDK.Explore.PlayerExperienceQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for the overlay data to decorate the player overlay.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/player-experience-api.smithy
     * @returns {Promise<SDK.Services.Explore.PlayerExperienceResponse>}
     *
     */
    public async getPlayerExperience(options: {
        query: PlayerExperienceQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(PlayerExperienceQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPlayerExperience', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { availId, version } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getPlayerExperience,
            version,
            variables: {
                availId
            }
        };

        return this.query<PlayerExperienceQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {Object} options
     * @param {Array<Object>} options.request
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc This is a generic method to handle all events sent to the envelope endpoint.
     * @note note The envelope endpoint requires payloads to be in a collection.
     * @returns {Promise<Void>}
     *
     */
    public async sendUserAction(options: {
        request: Array<object>;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.array.of.object(),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'sendUserAction', params, arguments);
        }

        const { logger } = this;
        const { request, accessToken, logTransaction } = options;

        const payload = this.getPayload({
            accessToken,
            endpointKey: ExploreClientEndpoint.messageEnvelope,
            data: {
                params: request
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ExploreClientDustUrnReference.sendUserAction,
            payload,
            data: {
                request
            },
            endpointKey: ExploreClientEndpoint.messageEnvelope,
            logTransaction
        });

        await super.request({
            payload,
            dustLogUtility
        });
    }

    /**
     *
     * @access public
     * @since 24.0.0
     * @param {Object} options
     * @param {SDK.Explore.SeasonQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for Season data.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/season-api.smithy
     * @returns {Promise<SDK.Services.Explore.SeasonResponse>}
     *
     */
    public async getSeason(options: {
        query: SeasonQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(SeasonQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSeason', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { version, seasonId, limit, offset, exploreParams } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getSeason,
            version,
            variables: {
                seasonId
            },
            exploreParams: {
                ...exploreParams,
                limit,
                offset
            }
        };

        return this.query<SeasonQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 25.0.0
     * @param {Object} options
     * @param {SDK.Explore.SearchQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for search page data.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/search-api.smithy
     * @returns {Promise<SearchResponse>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async search(options: {
        query: SearchQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(SearchQueryTypedef),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'search', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { version, query: queryString } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.search,
            version,
            exploreParams: {
                query: queryString
            }
        };

        return this.query<SearchQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 25.0.0
     * @param {Object} options
     * @param {SDK.Explore.UpNextQuery} options.query
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Queries the Explore service for the Up Next data.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/up-next-api.smithy
     * @returns {Promise<SDK.Services.Explore.UpNextResponse>}
     *
     */
    public async getUpNext(options: {
        query: UpNextQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(UpNextQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getUpNext', params, arguments);
        }

        const { query, accessToken, logTransaction } = options;
        const { version, contentId } = query;

        const exploreQuery: ExploreQuery = {
            context: ExploreClientEndpoint.getUpNext,
            version,
            exploreParams: {
                contentId
            }
        };

        return this.query<UpNextQuery>({
            query: exploreQuery,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 27.0.0
     * @param {Object} options
     * @param {SDK.Explore.DownloadMetadataInput} options.input
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Gets a map of avail IDs to download metadata for the active user.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/download-metadata-api.smithy
     * @returns {Promise<DownloadMetadataResponse>}
     *
     */
    public async getDownloadMetadata(options: {
        input: DownloadMetadataInput;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    input: Types.object(DownloadMetadataInputTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getDownloadMetadata', params, arguments);
        }

        const { input, accessToken, logTransaction } = options;
        const { availIds, version } = input;

        const query: ExploreQuery = {
            context: ExploreClientEndpoint.getDownloadMetadata,
            version,
            exploreParams: {
                availIds
            }
        };

        return this.query<DownloadMetadataInput>({
            query,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 28.3.0
     * @param {Object} options
     * @param {GetPartnerContinueWatchingQuery} options.query
     * @param {AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Gets a map of avail IDs to download metadata for the active user.
     * @see https://github.bamtech.co/services-commons/api-registry/blob/main/specs/cxd/exploreapi/partner-continue-watching-api.smithy
     * @returns {Promise<GetPartnerContinueWatchingResponse>}
     *
     */
    public async getPartnerContinueWatching(options: {
        query: GetPartnerContinueWatchingQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(GetPartnerContinueWatchingQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPartnerContinueWatching', params, arguments);
        }

        const {
            query: continueWatchingQuery,
            accessToken,
            logTransaction
        } = options;

        const { version, limit, exploreParams } = continueWatchingQuery;

        const query: ExploreQuery = {
            context: ExploreClientEndpoint.getPartnerContinueWatching,
            version,
            exploreParams: {
                ...exploreParams,
                limit
            }
        };

        return this.query<GetPartnerContinueWatchingQuery>({
            query,
            accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 23.1.0
     * @param {Object} options
     * @param {SDK.Explore.ExploreQuery} options.query - The query object that defines the query criteria and source (or context) of the data with properties supplied in the `variables`. Requires `GET` method.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object>} A promise that completes when the
     * operation has succeeded.
     * @note All the convenience methods are wrappers around this method.
     * @note This method supports `getPage`, `getSet`, `getDeeplink`, and unknown content related queries.
     * It does not support other functionality like `addToWatchlist`, `removeFromWatchlist`.
     *
     */
    public async query<T>(options: {
        query: ExploreQuery;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    query: Types.object(ExploreQueryTypedef),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'query', params, arguments);
        }

        const { logger } = this;
        const { query, accessToken, logTransaction } = options;
        const { context, version, variables = {}, exploreParams = {} } = query;
        const endpointKey = context;

        const dustUrn =
            ExploreClientDustUrnReference[
                context as keyof typeof ExploreClientDustUrnReference
            ] || ExploreClientDustUrnReference.query;

        if (!this.isKeyInConfig(endpointKey)) {
            throw createSimpleException({
                description: `${this.toString()} ${endpointKey} does not exist in SDK configs, unknown context.`,
                exceptionData: ExceptionReference.explore.invalidRequest
            });
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                variables: {
                    ...variables,
                    version
                },
                params: this.processParams(exploreParams, endpointKey)
            }
        });

        logger.info(this.toString(), `Query: ${context}.`);

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: dustUrn,
            payload,
            data: {
                context
            },
            endpointKey,
            logTransaction
        });

        return super.request<
            { data: ExploreQueryResult<T> },
            ExploreQueryResult<T>
        >({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                return response.data.data;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} params
     * @param {String} endpointKey
     * @desc Adds defaults from extras if they exist and are not already defined.
     * @note If neither exist, the service will use its own defaults.
     * @returns {Object|undefined}
     *
     */
    private processParams(params: ExploreParams, endpointKey: string) {
        const { config } = this;
        const { extras } = config;

        if (params) {
            if (extras) {
                const extra: Record<string, unknown> =
                    extras[endpointKey] ?? {};

                Object.keys(extra).forEach((key) => {
                    params[key] ??= extra[key];
                });
            }

            return params;
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {String} options.endpointKey
     * @param {Object} options.data - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: string;
        data: {
            variables?: {
                version: string;
                [key: string]: unknown;
            };
            params?: ExploreParams | Array<object>;
        };
    }) {
        const { accessToken, endpointKey, data } = options;
        const { endpoints } = this.config;

        const endpoint = endpoints[endpointKey];

        const { headers, href, method, templated } = endpoint as IEndpoint;
        const { variables, params } = data;

        let requestHref = href;
        let requestBody;
        let url;

        if (templated && variables) {
            Object.entries(variables).forEach(([key, value]) => {
                requestHref = requestHref.replace(
                    new RegExp(`{${key}}`, 'g'),
                    encodeURIComponent(value as string)
                );
            });
        }

        url = requestHref;

        if (Check.assigned(params)) {
            if (method === HttpMethod.GET) {
                url = appendQuerystring(requestHref, stringify(params));
            } else {
                requestBody = JSON.stringify(params);
            }
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @since 23.1.0
     * @param {String} key
     * @returns {Boolean}
     *
     */
    private isKeyInConfig(key: string) {
        return !!this.config.endpoints[key];
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Explore.ExploreClient';
    }
}
