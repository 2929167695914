/**
 *
 * @module entry
 * @desc {SDK.Services.Content} entry point.
 *
 */

import QueryBuilder from './queryBuilder';
import RestQuery from './restQuery';

/**
 *
 * @typedef {Object} SDK.Services.Content
 *
 * @property {QueryBuilder} QueryBuilder
 * @property {RestQuery} RestQuery
 *
 */
export default {
    QueryBuilder,
    RestQuery
};
