/* eslint-disable camelcase */

/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import EdgeEvent from '../internal/dust/edgeEvent';
import FlowControlPolicy from './messages/flowControlPolicy';
import { ErrorCodeDescription } from './messages/messageProcessorBase';
import MessageEnvelope from './messageEnvelope';

export type SocketMessageAcknowledgment = {
    eventId: string;
    status: string;
    retriesExhausted: boolean;
};

export type RawSocketMessage = {
    source: string;
    time: string;
    id: string;
    datacontenttype: string;
    schemaurl: string;
    type: string;
    data?: {
        accessToken?: unknown;
        application?: unknown;
        errors?: Array<ErrorCodeDescription>;
        eventId?: string;
        device?: unknown;
        policies?: Nullable<Array<FlowControlPolicy>>;
        retriesExhausted?: boolean;
        sessionId?: string;
        status?: string;
        action_info_block?: string;
        page_info_block?: string;
        container_info_block?: string;
        item_info_block?: string;
        event_type?: string;
        event_occurred_timestamp?: string;
    } & Partial<EdgeEvent>;
    subject: string | null;
};

/**
 *
 * @access private
 * @since 28.0.0
 *
 */
export const RawSocketMessageTypedef = {
    source: Types.nonEmptyString,
    time: Types.nonEmptyString,
    id: Types.nonEmptyString,
    datacontenttype: Types.nonEmptyString,
    schemaurl: Types.nonEmptyString,
    type: Types.nonEmptyString,
    data: Types.object().optional,
    subject: Types.nonEmptyString.optional
};

export type QueueReadyMessage = {
    envelope: MessageEnvelope;
    sessionId?: string;
};

/**
 *
 * @access private
 * @since 28.0.0
 *
 */
export const QueueReadyMessageTypedef = {
    envelope: Types.instanceStrict(MessageEnvelope),
    sessionId: Types.nonEmptyString.optional
};
