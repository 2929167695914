/**
 *
 * @module hasLocalStorage
 *
 */

import getScope from './getScope';

/**
 *
 * @access public
 * @since 16.0.0
 * @desc Detects if the current platform supports localStorage
 * @returns {Boolean}
 *
 */
export default () => {
    const key = 'storage_feature_test';

    try {
        const scope: TodoAny = getScope();
        const localStorage = scope.localStorage;

        localStorage.setItem(key, 'yes');

        if (localStorage.getItem(key) === 'yes') {
            localStorage.removeItem(key);
            // localStorage is enabled

            return true;
        }
    } catch (e) {
        // no-op - localStorage is disabled or does not exist
    }

    return false;
};
