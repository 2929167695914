/**
 *
 * @module errorEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/error/v1/error.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import ErrorReason from '../exception/errorReason';

import {
    AdPodPlacement,
    AdPodData,
    AdSlotData,
    AdPodDataTypedef,
    AdPodPlacementTypedef,
    AdSlotDataTypedef
} from './typedefs';

import {
    AdInsertionType,
    PresentationType,
    ApplicationContext,
    ErrorLevel,
    ErrorSource,
    NetworkType,
    QoePlaybackError,
    ProductType
} from './enums';

import uuidv4 from '../util/uuidv4';

/**
 *
 * @since 13.0.0
 * @desc This event should be sent out every time there is an error in the application as soon as it's known.
 * @note [Current Supported Version](https://helios.eds.us-east-1.bamgrid.net/events/error/1.7.0)
 * @note urn:dss:event:qoe:client:error:v1
 *
 */
export default class ErrorEventData {
    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ApplicationContext|undefined}
     * @desc Used to distinguish where in the application the error is being sent from.
     * @note `player` should be sent when an error occurs anywhere in the video player, beginning from playback intent to playback ended.
     * @note `ad` should be sent when an error is related to ads, both inside and outside the video player.
     *
     */
    public applicationContext?: ApplicationContext;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean|undefined}
     * @desc Indicates if the error was fatal to the application.
     * @note When `applicationContext` is `player` or `ad`, `isFatal` = TRUE would mean errors that lead to playback stopping or being blocked.
     *
     */
    public isFatal?: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ErrorSource|undefined}
     * @desc Defines where the error originated from.
     *
     */
    public source?: ErrorSource;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc Client generated ID of the unique error.
     *
     */
    public errorId: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ErrorLevel|undefined}
     * @desc The error impact level.
     *
     */
    public errorLevel?: ErrorLevel;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.QoePlaybackError}
     * @desc The name of the error.
     * @note If `applicationContext` is not `player` or `ad`, set to `PlaybackError::Unknown` by default.
     * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
     * @note For ad-specific errors such as `adBeaconError` or `adServerError`, source from the associated `SDK.Media.AdPlaybackEndedEvent.AdErrorData`.
     *
     */
    public errorName: QoePlaybackError;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The name of the error.
     * @desc Client generated ID of the stream/playback session.
     * @note Required if `applicationContext` is `player` or `ad`.
     *
     */
    public playbackSessionId?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ProductType|undefined}
     * @desc The Product type, Live or VOD.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
     * @note Required if `applicationContext` is `player`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public playheadPosition: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The peak video bitrate of the current presentationType, in bps.
     * @note Required if `applicationContext` is `player`.
     * @note The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged` and
     * `PlaybackEventListener.onPresentationTypeChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The average video bitrate of the current presentationType, in bps.
     * @note Required if `applicationContext` is `player`.
     * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged`
     * and `PlaybackEventListener.onPresentationTypeChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoAverageBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
     * last played before the event, as reported in the HLS or DASH manifest.
     * @note Required if `applicationContext` is `player`. If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
     * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged`
     * and `PlaybackEventListener.onPresentationTypeChanged` event.
     *
     */
    public audioBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The highest video bitrate (in bps) available for the currently selected presentationType in the current DASH/HLS
     * manifest that is allowed to play. The user may not reach this bitrate in their session.
     * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
     * resolution caps based on video window resolution/orientation.
     * @note The value should default to 0 if unavailable or unknown.
     * @note Required if `applicationContext` is `player`.
     * @note Source from the cached `maxAllowedVideoBitrate` value.
     *
     */
    public maxAllowedVideoBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc This should be the epoch time in milliseconds of the video.
     * @note Required if `applicationContext` is `player` and `productType` is `Live`.
     * @note Source from the `SDK.Media.PlaybackEndedEvent`.
     *
     */
    public segmentPosition: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc The CDN running the server.
     * @note Required if `applicationContext` is `player`.
     * @note A string value of "null" needs to be provided if not run by a CDN.
     *
     */
    public cdnName: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.NetworkType}
     * @desc The type of network connection currently in use by the client.
     * @note Required if `applicationContext` is `player` or `ad`.
     *
     */
    public networkType: NetworkType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
     * @note Required if `applicationContext` is `player` and `productType` is `Live`.
     * @note Source from the `SDK.Media.PlaybackEndedEvent`.
     *
     */
    public liveLatencyAmount: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The localization key the client used to lookup the user-facing error string in the strings dictionary.
     * @note Required if fatal error. Temporarily optional if `applicationContext` is `player`.
     * @note Ideally, this would be supplied for any fatal error. However there may be platform/timing constraints
     * @note on getting this information from the application. For now, supply this value if it's available.
     * @note Source from the `QoeError` passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
     *
     */
    public errorLocalizationKey?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The dictionary version the client used to look up the errorLocalizationKey (user-facing error string)
     * when errorLocalizationKey is a resource key for user-facing errors.
     * @note Required if fatal error.
     * @note Source from the `SDK.Logging.AnalyticsProvider`.
     *
     */
    public dictionaryVersion?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.Exception.ErrorReason|undefined}
     * @desc Information about the reason(s) the error occurred. When the error occurred is an SDK error, use the SDK error cause.
     * @note Source from the `QoeError` passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
     *
     */
    public underlyingSdkError?: ErrorReason;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Supporting error text for additional context. Source from the `Bam.Sdk.Media.PlaybackEndedEvent` or associated `QoeError`
     * passed in via `SDK.UserActivity.UserActivityApi.logQoeError`.
     * @note For ad-specific errors such as `adBeaconError` or `adServerError`, source from the associated `Bam.Sdk.Media.AdPlaybackEndedEvent.AdErrorData`.
     *
     */
    public errorMessage?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when testing PQM.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public clientGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public serverGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Object<String, String>}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String<SDK.Services.Media.ThumbnailPresentationType>}
     * @desc The type of presentation currently being played.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
     * event or the `Bam.Sdk.Media.PlaybackStartedEvent` object.
     *
     */
    public presentationType: PresentationType;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.AdInsertionType|undefined}
     * @desc The way ads are inserted into the stream.
     * @note Source by converting the `SDK.Media.MediaDescriptor.AssetInsertionStrategy`.
     *
     */
    public adInsertionType?: AdInsertionType;

    /**
     *
     * @access public
     * @since 22.0.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the playlist contains slugs.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.hasSlugs field.
     * @note Default to false if unavailable.
     *
     */
    public hasSlugs?: boolean;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that is entitled for playback.
     * @note Required if `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Random UUID assigned by the ad server.
     * @note Required if `applicationContext` is `ad`, and `adInsertionType` != `none`.
     * @note Source from the SDK.Services.MediaPayloadStream.AdsQos.adSession.id field.
     * @note There is no penalty for sending `adSessionId` if `applicationContext` = `player`.
     *
     */
    public adSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
     * @desc Placement information relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adPodPlacement?: AdPodPlacement;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
     * @desc Metadata relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public adPodData?: AdPodData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
     * @desc Metadata relevant to ad slots.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the current `SDK.Media.PlaybackMetrics` object provided by the `SDK.Media.PlaybackMetricsProvider.
     *
     */
    public adSlotData?: AdSlotData;

    /**
     *
     * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
     * @access public
     * @since 20.0.1
     * @type {String}
     *
     */
    public periodType: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `SDK.Logging.MonotonicTimestampProvider.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String|undefined}
     * @desc The name of the video player used to play a stream.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the `Bam.Sdk.Media.PlayerAdapter`.
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String|undefined}
     * @desc The version of the video player used to play a stream.
     * @note Required if `applicationContext` is `player` or `ad`.
     * @note Source from the `Bam.Sdk.Media.PlayerAdapter`.
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Boolean|undefined}
     * @desc True if the media is being played from a local source, e.g. it has been downloaded for offline playback or was bundled in the application.
     * @note Conditionally required if applicationContext = `ad` or `player`.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public localMedia?: boolean;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The `interactionId` will correspond with one of the defined `interactionTypes`. The `interactionId` will be used as
     * the primary key for all interactions.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for
     * all playback events when `interactionId` is not null.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Boolean}
     * @desc A flag indicating if the media fetch request to the playback orchestration service succeeded.
     * @note SDKs can determine this value if the media fetch to the PBO service was successful or not.
     * @note Conditionally required if `applicationContext` is `ad` or `player`.
     *
     */
    public mediaFetchSucceeded?: boolean;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {Number|undefined}
     * @desc As defined by the BANDWIDTH (where available) of the highest bitrate variant attained during a
     * viewer session while playing primary content. For HLS Manifest, use the 'BANDWIDTH' value and for
     * DASH, use the '@bandwidth' value.
     * @note Should be updated at least once per period (per heartbeat).
     * @note The SDK should keep track of the highest `videoBitrate` value from the `PlaybackEventListener.onPlaybackStarted`, `PlaybackEventListener.onBitrateChanged`, and `PlaybackEventListener.onPresentationTypeChanged` events.
     * @note If value is 0 or not available return 0.
     * @note Conditionally required if playbackActivity is `ended`.
     * @note Conditionally required if applicationContext = `ad` or `player`.
     *
     */
    public maxAttainedBitrate?: number;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String|undefined}
     * @desc Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
     * @note Conditionally required where `platformId` = `realitydevice`.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     * @note The SDK should keep track of the most recent `viewingEnvironment` value, initially from the `PlaybackContextOptions.viewingEnvironment`
     * field and updates through the `PlaybackEventListener.onViewingEnvironmentChanged` event.
     *
     */
    public viewingEnvironment?: string;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @param {Object} [options={}]
     * @param {SDK.Services.QualityOfService.ApplicationContext} options.applicationContext
     * @param {Boolean} [options.isFatal]
     * @param {SDK.Services.QualityOfService.ErrorSource} [options.source]
     * @param {SDK.Services.QualityOfService.ErrorLevel} [options.errorLevel]
     * @param {SDK.Services.QualityOfService.QoePlaybackError} [options.errorName]
     * @param {String} [options.playbackSessionId]
     * @param {SDK.Services.QualityOfService.ProductType} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {SDK.Services.QualityOfService.NetworkType} [options.networkType]
     * @param {Number} [options.liveLatencyAmount]
     * @param {String} [options.errorLocalizationKey]
     * @param {String} [options.dictionaryVersion]
     * @param {SDK.Services.Exception.ErrorReason} [options.underlyingSdkError]
     * @param {String} [options.errorMessage]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {String<SDK.Services.Media.ThumbnailPresentationType>} [options.presentationType=PresentationType.unknown]
     * @param {SDK.Services.QualityOfService.AdInsertionType} [options.adInsertionType=AdInsertionType.none]
     * @param {Boolean} [options.hasSlugs=false]
     * @param {SDK.Services.Media.SubscriptionType|undefined} [options.subscriptionType]
     * @param {String|undefined} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData>} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData>} [options.adSlotData]
     * @param {Number} [options.monotonicTimestamp]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {Boolean} [options.localMedia=false]
     * @param {String} [options.interactionId]
     * @param {Boolean} [options.mediaFetchSucceeded=true]
     * @param {Object} [options.qoe={}]
     * @param {Number} [options.maxAttainedBitrate=0]
     * @param {String} [options.viewingEnvironment]
     * @param {String} [options.programBoundaryInfoBlock]
     *
     */
    public constructor(options?: {
        applicationContext: ApplicationContext;
        isFatal?: boolean;
        source?: ErrorSource;
        errorLevel?: ErrorLevel;
        errorName?: QoePlaybackError;
        playbackSessionId?: string;
        productType?: ProductType;
        playheadPosition?: Nullable<number>;
        videoBitrate?: Nullable<number>;
        videoAverageBitrate?: Nullable<number>;
        audioBitrate?: number;
        maxAllowedVideoBitrate?: number;
        segmentPosition?: number;
        cdnName?: string;
        networkType?: NetworkType;
        liveLatencyAmount?: Nullable<number>;
        errorLocalizationKey?: string;
        dictionaryVersion?: string;
        underlyingSdkError?: ErrorReason;
        errorMessage?: string;
        contentKeys?: Record<string, unknown>;
        clientGroupIds?: string;
        serverGroupIds?: string;
        data?: TodoAny;
        presentationType?: PresentationType;
        adInsertionType?: AdInsertionType;
        hasSlugs?: boolean;
        subscriptionType?: string;
        adSessionId?: string;
        adPodPlacement?: AdPodPlacement;
        adPodData?: AdPodData;
        adSlotData?: AdSlotData;
        monotonicTimestamp?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
        localMedia?: boolean;
        interactionId?: string;
        mediaFetchSucceeded: boolean;
        qoe?: Record<string, unknown>;
        maxAttainedBitrate?: Nullable<number>;
        viewingEnvironment?: string;
        programBoundaryInfoBlock?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    applicationContext: Types.in(ApplicationContext),
                    isFatal: Types.boolean.optional,
                    source: Types.in(ErrorSource).optional,
                    errorLevel: Types.in(ErrorLevel).optional,
                    errorName: Types.in(QoePlaybackError).optional,
                    playbackSessionId: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    errorLocalizationKey: Types.nonEmptyString.optional,
                    dictionaryVersion: Types.nonEmptyString.optional,
                    underlyingSdkError:
                        Types.instanceStrict(ErrorReason).optional,
                    errorMessage: Types.nonEmptyString.optional,
                    contentKeys: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    data: Types.object().optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    hasSlugs: Types.boolean.optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    monotonicTimestamp: Types.number.optional,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    localMedia: Types.boolean.optional,
                    interactionId: Types.nonEmptyString.optional,
                    mediaFetchSucceeded: Types.boolean,
                    qoe: Types.object().optional,
                    maxAttainedBitrate: Types.number.optional,
                    viewingEnvironment: Types.nonEmptyString.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            applicationContext,
            isFatal,
            source,
            errorLevel,
            errorName,
            playbackSessionId,
            productType,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            networkType,
            liveLatencyAmount,
            errorLocalizationKey,
            dictionaryVersion,
            underlyingSdkError,
            errorMessage,
            clientGroupIds,
            serverGroupIds,
            contentKeys,
            data = {},
            presentationType,
            adInsertionType,
            hasSlugs,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            monotonicTimestamp,
            videoPlayerName,
            videoPlayerVersion,
            localMedia,
            interactionId,
            mediaFetchSucceeded,
            qoe = {},
            maxAttainedBitrate,
            viewingEnvironment,
            programBoundaryInfoBlock
        } = options || {};

        this.applicationContext = applicationContext;
        this.isFatal = isFatal ?? undefined;
        this.source = source;
        this.errorId = uuidv4();
        this.errorLevel = errorLevel;
        this.errorName = this.setErrorName(applicationContext, errorName);
        this.playbackSessionId = playbackSessionId;
        this.productType = productType;
        this.playheadPosition = Math.floor(playheadPosition ?? -1);
        this.videoBitrate = videoBitrate ?? 0;
        this.videoAverageBitrate = videoAverageBitrate ?? 0;
        this.audioBitrate = audioBitrate ?? 0;
        this.maxAllowedVideoBitrate = maxAllowedVideoBitrate ?? 0;

        this.segmentPosition = Check.number(segmentPosition)
            ? Math.floor(segmentPosition)
            : 0;

        this.cdnName = cdnName || 'null';
        this.networkType = networkType || NetworkType.unknown;

        this.liveLatencyAmount = Check.number(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : 0;

        this.errorLocalizationKey = errorLocalizationKey;
        this.dictionaryVersion = dictionaryVersion;
        this.underlyingSdkError = underlyingSdkError;
        this.errorMessage = errorMessage;
        this.clientGroupIds = clientGroupIds;
        this.serverGroupIds = serverGroupIds;
        this.contentKeys = contentKeys || {};
        this.presentationType = presentationType || PresentationType.unknown;
        this.adInsertionType = adInsertionType || AdInsertionType.none;
        this.hasSlugs = hasSlugs || false;
        this.subscriptionType = subscriptionType || '';
        this.adSessionId = adSessionId;
        this.adPodPlacement = adPodPlacement;
        this.adPodData = adPodData;
        this.adSlotData = adSlotData;
        this.periodType = 'deprecated';
        this.monotonicTimestamp = monotonicTimestamp;
        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.interactionId = interactionId;
        this.localMedia =
            applicationContext === ApplicationContext.ad ||
            applicationContext === ApplicationContext.player
                ? localMedia
                : undefined;
        this.mediaFetchSucceeded = mediaFetchSucceeded ?? true;
        this.maxAttainedBitrate = maxAttainedBitrate ?? 0;
        this.viewingEnvironment = viewingEnvironment;
        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        this.setData(data);
        this.setData(qoe);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: object) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {SDK.Services.QualityOfService.ApplicationContext} [appContext]
     * @param {SDK.Services.QualityOfService.QoePlaybackError} [errorName=QoePlaybackError.unknown]
     * @desc Assigns `errorName` property.
     * @returns {SDK.Services.QualityOfService.QoePlaybackError}
     *
     */
    private setErrorName(
        appContext?: ApplicationContext,
        errorName = QoePlaybackError.unknown
    ) {
        if (
            appContext !== ApplicationContext.player &&
            appContext !== ApplicationContext.ad
        ) {
            return QoePlaybackError.unknown;
        }

        return errorName;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.ErrorEventData';
    }
}
