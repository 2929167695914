/**
 *
 * @module offDeviceTokenRefreshEvent
 *
 */

import type { OffDeviceRedemptionFlow } from '../packages/sdk-types/types/orchestration-enums';

/**
 *
 * @desc Provides an event that can share an `Error` in the case an offDeviceTokenRefresh socket message fails to process.
 * Additionally, to support Welch Connected Devices flows, this event also contains a actionGrant and redemption flow.
 *
 */
export default class OffDeviceTokenRefreshEvent {
    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Error|undefined}
     *
     */
    public exception?: Error;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {String|undefined}
     *
     */
    public actionGrant?: string;

    /**
     *
     * @access public
     * @since 26.0.0
     * @type {String|undefined}
     *
     */
    public refreshToken?: string;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Orchestration.OffDeviceRedemptionFlow|undefined}
     *
     */
    public offDeviceRedemptionFlow?: OffDeviceRedemptionFlow;

    /**
     *
     * @access public
     * @since 26.0.0
     * @type {String|undefined}
     *
     */
    public offerId?: string;

    /**
     *
     * @param {Object} options
     * @param {Error} [options.exception]
     * @param {String} [options.actionGrant]
     * @param {SDK.Services.Orchestration.OffDeviceRedemptionFlow} [options.offDeviceRedemptionFlow]
     *
     */
    public constructor(options: {
        exception?: Error;
        actionGrant?: string;
        offDeviceRedemptionFlow?: OffDeviceRedemptionFlow;
        refreshToken?: string;
        offerId?: string;
    }) {
        const {
            exception,
            actionGrant,
            offDeviceRedemptionFlow,
            refreshToken,
            offerId
        } = options;

        this.exception = exception;
        this.actionGrant = actionGrant;
        this.offDeviceRedemptionFlow = offDeviceRedemptionFlow;
        this.refreshToken = refreshToken;
        this.offerId = offerId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.OffDeviceTokenRefreshEvent';
    }
}
