/**
 *
 * @module paywallClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 3.9.0
 * @typedef {Object} SDK.Services.Paywall.PaywallClientEndpoint
 *
 * @property {String} paywall - Since `3.9.0`.
 *
 */
enum PaywallClientEndpoint {
    paywall = 'paywall'
}
export default PaywallClientEndpoint;
