/**
 *
 * @module accessToken
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access public
 * @desc Provides the interface used to exchange tokens to provide access to service requests.
 *
 */
export default class AccessToken {
    /**
     *
     * @access public
     * @param {String} token
     * @desc The access token issued by the authorization server.
     *
     */
    public token: string;

    /**
     *
     * @access public
     * @since 4.6.0
     * @param {String|null} region
     * @desc The region where the accessToken was generated.
     *
     */
    public region: Nullable<string>;

    /**
     *
     * @param {String} token
     * @param {String} [region=null]
     *
     */
    public constructor(token: string, region: Nullable<string> = null) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                token: Types.nonEmptyString,
                region: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.token = token;
        this.region = region;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Token.AccessToken';
    }
}
