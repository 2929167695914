/**
 *
 * @module productStatus
 *
 */

/**
 *
 * @enum
 * @since 3.0.1
 * @typedef {Object} SDK.Subscription.ProductStatus
 * @desc Defines the different status a Product can be in.
 *
 * @property {String} AVAILABLE - The product is currently available.
 * @property {String} UNKNOWN - The product status is unknown.
 * @property {String} UNAVAILABLE - The product is currently unavailable.
 *
 * @example
 * ProductStatus.AVAILABLE // 'AVAILABLE'
 *
 */
enum ProductStatus {
    AVAILABLE = 'AVAILABLE',
    UNKNOWN = 'UNKNOWN',
    UNAVAILABLE = 'UNAVAILABLE'
}

export default ProductStatus;
