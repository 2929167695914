/**
 *
 * @module entry
 * @desc {SDK.QRCode} entry point.
 *
 */

import QRCodeFormat from './qrCodeFormat';

/**
 *
 * @typedef {Object} SDK.QRCode
 *
 * @property {QRCodeFormat} QRCodeFormat
 *
 */
export default {
    QRCodeFormat
};
