/**
 *
 * @module getFetchStatus
 *
 */

import { FetchStatus } from './enums';

/**
 *
 * @since 24.0.0
 * @param {String} fetchStatus
 * @desc Converts an input string to the properly cased enum or fallback to `FetchStatus.complete`
 * @returns {FetchStatus}
 *
 */
export default function getFetchStatus(fetchStatus: string) {
    const lowerFetchStatus = (fetchStatus || '').toLowerCase();

    const key = Object.keys(FetchStatus).find((x) => {
        return x.toLowerCase() === lowerFetchStatus;
    });

    if (key) {
        return FetchStatus[key];
    }

    return FetchStatus.completed;
}
