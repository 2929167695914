/**
 *
 * @module invoiceClient
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import { InvoiceTypedef, PaginatedInvoicesResponseTypedef } from './typedefs';

import Logger from '../../logging/logger';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';

import InvoiceClientConfiguration from './invoiceClientConfiguration';
import InvoiceClientEndpoint from './invoiceClientEndpoint';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../logging/logTransaction';

import appendQuerystring from '../util/appendQuerystring';
import AccessToken from '../token/accessToken';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const InvoiceClientDustUrnReference =
    DustUrnReference.services.invoice.invoiceClient;

/**
 *
 * @access protected
 * @since 4.12.0
 * @desc Provides a data client that can be used to access invoice services.
 *
 */
export default class InvoiceClient extends ClientBase<InvoiceClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Invoice.InvoiceClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: InvoiceClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(InvoiceClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {Object} options
     * @param {String} options.link - path used to get a specific invoice.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.Invoice>>}
     *
     */
    public getInvoice(options: {
        link: string;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    link: Types.nonEmptyString,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getInvoice', params, arguments);
        }

        const { link, accessToken, logTransaction } = options;
        const { logger } = this;

        const endpointKey = InvoiceClientEndpoint.getInvoice;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                link
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: InvoiceClientDustUrnReference.getInvoice,
            payload,
            endpointKey,
            logTransaction
        });

        return this.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(InvoiceTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            }
        });
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {Object} options
     * @param {String} [options.pageToken] - token containing the information necessary to retrieve the next page of results.
     * @param {Number} [options.pageSize] - The maximum number of results to return in a single response.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @returns {Promise<Object<SDK.Services.Invoice.PaginatedInvoicesResponse>>}
     *
     */
    public getPaginatedInvoices(options: {
        pageToken?: string;
        pageSize?: number;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    pageToken: Types.nonEmptyString.optional,
                    pageSize: Types.number.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPaginatedInvoices', params, arguments);
        }

        const { pageToken, pageSize, accessToken, logTransaction } = options;
        const { logger } = this;

        const endpointKey = InvoiceClientEndpoint.getPaginatedInvoices;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                pageToken,
                pageSize
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: InvoiceClientDustUrnReference.getPaginatedInvoices,
            payload,
            endpointKey,
            logTransaction
        });

        return this.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data } = response;

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        data: Types.object(PaginatedInvoicesResponseTypedef)
                    };

                    typecheck.warn(responseType, [data]);
                }

                return data;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Invoice.InvoiceClientEndpoint} options.endpointKey - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {Object} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: InvoiceClientEndpoint;
        data: { link?: string; pageToken?: string; pageSize?: number };
        body?: object;
    }) {
        const { accessToken, endpointKey, data = {}, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, headers, method } = endpoint;
        const requestBody = body ? JSON.stringify(body) : '';

        let url;

        if (endpointKey === InvoiceClientEndpoint.getPaginatedInvoices) {
            url = appendQuerystring(href, queryString.stringify(data));
        }

        if (endpointKey === InvoiceClientEndpoint.getInvoice) {
            url = `${href}${data.link}`;
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: url as string,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Invoice.InvoiceClient';
    }
}
