/**
 *
 * @module mediaPlaybackSelectionPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import PlaybackSelectionProperties from './playbackSelectionProperties';

/**
 *
 * @since 4.18.0
 *
 */
export default class MediaPlaybackSelectionPayload {
    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Media.PlaybackSelectionProperties}
     *
     */
    public playback: PlaybackSelectionProperties;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {String|undefined}
     * @desc An encoded string identifying the media.
     * @note Required for PBO v7 requests. Source from `Bam.Sdk.Media.MediaLocator.id`.
     *
     */
    public playbackId?: string;

    /**
     *
     * @param {SDK.Services.Media.PlaybackSelectionProperties} playback
     *
     */
    public constructor(
        playback: PlaybackSelectionProperties,
        playbackId?: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playback: Types.instanceStrict(PlaybackSelectionProperties),
                playbackId: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.playback = playback;
        this.playbackId = playbackId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaPlaybackSelectionPayload';
    }
}
