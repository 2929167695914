/**
 *
 * @module memoryStore
 *
 */

import { IStore } from './IStore'; // eslint-disable-line no-unused-vars

/**
 *
 * @access protected
 * @since 16.0.0
 * @note In this implementation `key` is expected to be a String, which differs
 * from other implementations that accept various types i.e. Number, undefined, etc...
 *
 */
export default class MemoryStore implements IStore {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Storage reference.
     *
     */
    public store: TodoAny;

    /**
     *
     * @access public
     * @since 16.0.0
     *
     */
    public constructor() {
        this.store = {};
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @param {*} value
     * @returns {Promise<Void>}
     *
     */
    public async setItem(key: string, value: TodoAny) {
        this.store[key] = value;
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @returns {Promise<*>}
     *
     */
    public async getItem(key: string) {
        return this.store[key];
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {String} key
     * @returns {Promise<Void>}
     *
     */
    public async removeItem(key: string) {
        delete this.store[key];
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Store.MemoryStore';
    }
}
