/**
 *
 * @module events
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/managers/class.sdk.plantuml
 *
 */

/**
 *
 * @access public
 * @typedef {Object} SDK.Events
 * @since 10.0.0
 * @desc Comprehensive list of public events emitted by the SDK.
 *
 * @property {String} ReauthorizationFailure - Since `10.0.0` - The event raised when automatic token refresh fails.
 * @property {String} OffDeviceTokenRefresh - Since `10.0.0` - Provides an event that can share an `Error` in the case
 * an `offDeviceTokenRefresh` socket message fails to process. Additionally, to support Welch Connected Devices flows,
 * this event also contains a `actionGrant` and redemption flow.
 * @property {String} AccessChanged - Since `10.0.0` - The event raised each time a access token is updated.
 * @property {String} SessionInfoChanged - Since `10.0.0` - Emitted by the `SdkSession` when the session info
 * associated with the underlying access token has changed.
 * @property {String} MediaFailure - Since `10.0.0` - Occurs when there was an error requesting a
 * DRM license or certificate which will result in playback failure.
 * @property {String} AgeVerificationChanged - Since `12.0.0` - Occurs when an age verification request or redemption
 * is completed successfully or fails with an error.
 * @property {String} FeatureFlagsChanged - Since `15.0.0` - Emitted by SdkSession when the feature flags associated
 * with the session has changed.
 * @property {String} AccountDelegationRefreshTokenChanged - Since `16.0.0` - Emitted by SdkSession when the account
 * delegation refresh token has changed.
 * @property {String} ValidationResultsReceived - Since `16.1.0` - Emitted by SdkSession when a telemetry validation
 * response is received.
 *
 * @example
 * SDK.Events.AccessChanged // 'AccessChanged'
 *
 */

enum Events {
    ReauthorizationFailure = 'ReauthorizationFailure',
    OffDeviceTokenRefresh = 'OffDeviceTokenRefresh',
    AccessChanged = 'AccessChanged',
    SessionInfoChanged = 'SessionInfoChanged',
    MediaFailure = 'MediaFailure',
    AgeVerificationChanged = 'AgeVerificationChanged',
    FeatureFlagsChanged = 'FeatureFlagsChanged',
    AccountDelegationRefreshTokenChanged = 'AccountDelegationRefreshTokenChanged',
    ValidationResultsReceived = 'ValidationResultsReceived'
}

export default Events;
