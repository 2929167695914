/**
 *
 * @module clientBase
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { GetPayloadResult, ServerResponse } from './providers/typedefs';
import DustLogUtility from './internal/dust/dustLogUtility';
import CoreHttpClientProvider from './providers/shared/coreHttpClientProvider';
import handleServiceResponse from './util/handleServiceResponse';
import ServiceClientConfiguration from './configuration/serviceClientConfiguration';
import Logger from '../logging/logger';

/**
 *
 * @access protected
 * @desc Shared base feature client.
 *
 */
export default class ClientBase<TClientConfig> {
    /**
     *
     * @access private
     * @since 24.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @access private
     * @since 24.0.0
     * @type {ServiceClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    public config: TClientConfig;

    /**
     *
     * @access protected
     * @since 24.0.0
     * @type {CoreHttpClientProvider}
     *
     */
    protected httpClient: CoreHttpClientProvider;

    /**
     *
     * @param {Object} options
     * @param {Object} options.endpoints
     * @param {Object} options.clientDustUrnReference
     *
     */
    public constructor(options: {
        logger: Logger;
        config: TClientConfig;
        httpClient: CoreHttpClientProvider;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    config: Types.instanceStrict(ServiceClientConfiguration),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                        .optional
                })
            };

            typecheck(this, params, arguments);
        }
        const { logger, config, httpClient } = options;

        this.logger = logger;
        this.config = config;
        this.httpClient = httpClient;
    }

    /**
     *
     * @access protected
     * @since 24.0.0
     * @param {Object} options
     * @param {GetPayloadResult} options.payload
     * @param {DustLogUtility} [options.dustLogUtility]
     * @param {String} [options.handleServiceResponseMethodName]
     * @param {Boolean} [options.skipHandleServiceResponse=false]
     * @param {Function} options.resultMapper
     * @returns <T> the result of executing the request, passing the response through the `resultMapper`
     *
     */
    public request<
        TServiceResponseData = ApprovedAny,
        TFinalMappedResult = ApprovedAny
    >(options: {
        payload: GetPayloadResult;
        dustLogUtility?: DustLogUtility;
        resultMapper?: (
            response: ServerResponse<TServiceResponseData>
        ) => TFinalMappedResult;
        handleServiceResponseMethodName?: string;
        skipHandleServiceResponse?: boolean;
    }) {
        const {
            payload,
            dustLogUtility,
            resultMapper = () => {
                return undefined;
            },
            handleServiceResponseMethodName,
            skipHandleServiceResponse = false
        } = options;

        return this.httpClient
            .request<TServiceResponseData>(payload)
            .then((response) => {
                if (skipHandleServiceResponse) {
                    return response;
                }

                return handleServiceResponse({
                    response,
                    dustLogUtility,
                    methodName: handleServiceResponseMethodName
                });
            })
            .then((response) => {
                return resultMapper(response) as TFinalMappedResult;
            })
            .finally(() => {
                dustLogUtility?.log();
            });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.ClientBase';
    }
}
