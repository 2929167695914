/**
 *
 * @module tokenClientConfiguration
 *
 */

import { IEndpoints } from '../providers/typedefs';
import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

import TokenClientExtrasMap from './tokenClientExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the TokenClient.
 *
 */
export default class TokenClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    TokenClientExtrasMap
> {
    /**
     *
     * @param {Object} options
     * @param {String} options.baseUrl
     * @param {Object} options.endpoints
     * @param {TokenClientExtrasMap} options.extras
     *
     */
    public constructor(options: {
        baseUrl: string;
        endpoints: IEndpoints<Record<string, never>>;
        extras: TokenClientExtrasMap;
    }) {
        super(options);

        this.extras = options.extras;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.TokenClientConfiguration';
    }
}
