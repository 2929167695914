/**
 *
 * @module playbackVariant
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.0.0
 *
 */
export default class PlaybackVariant {
    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public bandwidth: Nullable<number>;

    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public resolution: Nullable<string>;

    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public videoBytes: Nullable<number>;

    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public maxAudioRenditionBytes: Nullable<number>;

    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public maxSubtitleRenditionBytes: Nullable<number>;

    /**
     *
     * @access public
     * @type {Number|null}
     *
     */
    public audioChannels: Nullable<number>;

    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public videoRange: Nullable<string>;

    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public videoCodec: Nullable<string>;

    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public audioType: Nullable<string>;

    /**
     *
     * @access public
     * @type {String|null}
     *
     */
    public audioCodec: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.13.0
     * @type {Number|null}
     *
     */
    public averageBandwidth: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.13.0
     * @type {Number|null}
     *
     */
    public frameRate: Nullable<number>;

    /**
     *
     * @param {Object} [options={}]
     * @param {Number|null} [options.bandwidth=null]
     * @param {String|null} [options.resolution=null]
     * @param {Number|null} [options.videoBytes=null]
     * @param {Number|null} [options.maxAudioRenditionBytes=null]
     * @param {Number|null} [options.maxSubtitleRenditionBytes=null]
     * @param {Number|null} [options.audioChannels=null]
     * @param {String|null} [options.videoRange=null]
     * @param {String|null} [options.videoCodec=null]
     * @param {String|null} [options.audioType=null]
     * @param {String|null} [options.audioCodec=null]
     * @param {Number|null} [options.averageBandwidth=null]
     * @param {Number|null} [options.frameRate=null]
     *
     */
    public constructor(options?: {
        bandwidth?: Nullable<number>;
        resolution?: Nullable<string>;
        videoBytes?: Nullable<number>;
        maxAudioRenditionBytes?: Nullable<number>;
        maxSubtitleRenditionBytes?: Nullable<number>;
        audioChannels?: Nullable<number>;
        videoRange?: Nullable<string>;
        videoCodec?: Nullable<string>;
        audioType?: Nullable<string>;
        audioCodec?: Nullable<string>;
        averageBandwidth?: Nullable<number>;
        frameRate?: Nullable<number>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    bandwidth: Types.number.optional,
                    resolution: Types.nonEmptyString.optional,
                    videoBytes: Types.number.optional,
                    maxAudioRenditionBytes: Types.number.optional,
                    maxSubtitleRenditionBytes: Types.number.optional,
                    audioChannels: Types.number.optional,
                    videoRange: Types.nonEmptyString.optional,
                    videoCodec: Types.nonEmptyString.optional,
                    audioType: Types.nonEmptyString.optional,
                    audioCodec: Types.nonEmptyString.optional,
                    averageBandwidth: Types.number.optional,
                    frameRate: Types.number.optional
                }).optional
            };

            typecheck(this, params, arguments);
        }

        const {
            bandwidth,
            resolution,
            videoBytes,
            maxAudioRenditionBytes,
            maxSubtitleRenditionBytes,
            audioChannels,
            videoRange,
            videoCodec,
            audioType,
            audioCodec,
            averageBandwidth,
            frameRate
        } = options || {};

        this.bandwidth = bandwidth ?? null;
        this.resolution = resolution ?? null;
        this.videoBytes = videoBytes ?? null;
        this.maxAudioRenditionBytes = maxAudioRenditionBytes ?? null;
        this.maxSubtitleRenditionBytes = maxSubtitleRenditionBytes ?? null;
        this.audioChannels = audioChannels ?? null;
        this.videoRange = videoRange ?? null;
        this.videoCodec = videoCodec ?? null;
        this.audioType = audioType ?? null;
        this.audioCodec = audioCodec ?? null;
        this.averageBandwidth = averageBandwidth ?? null;
        this.frameRate = frameRate ?? null;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackVariant';
    }
}
