/**
 *
 * @module handleServerTime
 *
 */

import CoreStorageProvider from '../providers/shared/coreStorageProvider';
import { SERVER_TIME_KEY } from '../providers/shared/storageConstants';
import type { ServerResponse } from '../providers/typedefs';

/**
 *
 * @access protected
 * @since 28.1.0
 * @param options
 * @param {Function} options.getTimestamp
 * @param {CoreStorageProvider} options.storage
 * @desc Stores the server time and handles the response from the server.
 * @returns {(response: ServerResponse) => ServerResponse} resolves the response or rejects with an exception
 *
 */
export default function handleServerTime(options: {
    getTimestamp: () => number;
    storage: CoreStorageProvider;
}) {
    const { getTimestamp, storage } = options;

    return (response: ServerResponse) => {
        /**
         *
         * @note logic for SDK.SdkSession#getServerTime
         *
         */
        const date = response.headers.get('Date');
        const retrievedTime = getTimestamp();

        storage.set(SERVER_TIME_KEY, JSON.stringify({ date, retrievedTime }));

        return response;
    };
}
