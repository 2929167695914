/**
 *
 * @module dustCategory
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#categories
 *
 */

/**
 *
 * @access protected
 * @since 3.4.0
 * @typedef {Object} SDK.Services.Internal.Dust.DustCategory
 * @desc Internal DUST API category reference
 *
 */
export default {
    api: {
        event: ':event:sdk',
        error: ':error:sdk'
    },
    service: {
        event: ':event:service',
        error: ':error:service'
    },
    glimpse: 'urn:bamtech:dust:bamsdk:event:glimpse',
    personalization: 'urn:bamtech:dust:bamsdk:event:app',
    qoe: 'urn:bamtech:dust:bamsdk:event:qoe'
};
