/**
 *
 * @module entry
 * @desc {SDK.Services.Account} entry point.
 *
 */

import Account from './account';
import AccountGrant from './accountGrant';
import Identity from './identity';
import UserProfile from './userProfile';
import CreateAccountResult from './createAccountResult';

/**
 *
 * @typedef {Object} SDK.Services.Account
 *
 * @property {Account} Account
 * @property {AccountGrant} AccountGrant
 * @property {Identity} Identity
 * @property {UserProfile} UserProfile
 * @property {CreateAccountResult} CreateAccountResult
 *
 */
export default {
    Account,
    AccountGrant,
    Identity,
    UserProfile,
    CreateAccountResult
};
