/**
 *
 * @module widevineDrmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md#widevine
 *
 */

import { Check, Types } from '@dss/type-checking';

import Events from '../events';
import DrmProvider from './drmProvider';
import WidevineCertificateStorage from './widevineCertificateStorage';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import type DrmClient from '../services/drm/drmClient';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';
import type MediaItem from '../media/mediaItem';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';
import { ApiOptions } from '../typedefs';

import { DrmType } from '../services/media/enums';

const DustUrn = DustUrnReference.drm.widevineDrmProvider;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access protected
 * @since 3.2.0
 * @desc Widevine is Google's content encryption standard.
 *
 */
export default class WidevineDrmProvider extends DrmProvider {
    /**
     *
     * @access private
     * @type {SDK.Drm.WidevineCertificateStorage}
     * @desc references the Storage API to get/set a certificate
     *
     */
    private certificateStorage: WidevineCertificateStorage;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Drm.DrmClient} options.drmClient
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String} [options.endpointKey]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     *
     */
    public constructor(options: {
        drmClient: DrmClient;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        endpointKey?: DrmClientEndpoint;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        super({
            ...options,
            type: DrmType.WIDEVINE
        });

        this.certificateStorage = new WidevineCertificateStorage({
            clientId: this.drmClient.clientId,
            environment: this.drmClient.environment,
            storage: this.drmClient.storage,
            logger: this.logger
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {ArrayBuffer} buffer
     * @desc Gets a license required for decrypting Widevine protected content.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public async getWidevineLicense(buffer: Uint8Array): Promise<ArrayBuffer>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            buffer: Types.arrayBufferView
        }
    })
    public async getWidevineLicense(apiOptions: unknown) {
        const {
            logTransaction,
            args: [buffer]
        } = apiOptions as ApiOptions<[Uint8Array]>;

        const { mediaItem, endpointKey, accessToken } = this;

        try {
            return await this.drmClient.getWidevineLicense({
                buffer,
                mediaItem,
                endpointKey,
                accessToken,
                logTransaction
            });
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'Widevine license request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    /**
     *
     * @access public
     * @param {IGNORE-PARAMS}
     * @desc Gets a certificate required for decrypting Widevine protected content. First we attempt to retrieve the
     * certificate from storage, if that does not exist we make a new request for a new certificate, store that and
     * then return the certificate.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public async getWidevineCertificate(): Promise<Uint8Array>;

    @apiMethodDecorator()
    public async getWidevineCertificate(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        const { mediaItem, videoPlayerName, videoPlayerVersion, accessToken } =
            this;

        try {
            const storedCertificate =
                await this.certificateStorage.getStoredCertificate();

            if (Check.assigned(storedCertificate)) {
                return storedCertificate;
            }

            const certificate = await this.drmClient.getWidevineCertificate({
                mediaItem,
                videoPlayerName,
                videoPlayerVersion,
                accessToken,
                logTransaction
            });

            await this.certificateStorage.storeCertificate(certificate);

            return await this.certificateStorage.getStoredCertificate();
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'Widevine certificate request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.WidevineDrmProvider';
    }
}
