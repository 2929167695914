/**
 *
 * @module telemetryManagerConfiguration
 *
 */

import TelemetryClientConfiguration from '../internal/telemetry/telemetryClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';
import TelemetryManagerExtrasMap from './telemetryManagerExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing telemetry.
 *
 */
export default class TelemetryManagerConfiguration extends ServiceConfiguration<
    TelemetryClientConfiguration,
    TelemetryManagerExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.TelemetryManagerConfiguration';
    }
}
