/**
 *
 * @module payPalApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    CreateBraintreePaymentMethodRequest,
    CreateBraintreePaymentMethodRequestTypedef,
    UpdateBraintreePaymentMethodRequest,
    UpdateBraintreePaymentMethodRequestTypedef
} from './typedefs';

import {
    ClientTokenResponse,
    PaymentMethodResponse
} from '../../services/commerce/typedefs';

import CommerceClient from '../../services/commerce/commerceClient';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';

import { ApiOptions } from '../../typedefs';

import type Logger from '../../logging/logger';
import type AccessTokenProvider from '../../token/accessTokenProvider';

const DustUrn = DustUrnReference.commerce.payPalApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.8.0
 * @desc Provides ability to access PayPal data.
 *
 */
export default class PayPalApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {CommerceClient}
     *
     */
    private commerceClient: CommerceClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {CommerceClient} options.commerceClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commerceClient: CommerceClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceClient: Types.instanceStrict(CommerceClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceClient } = options;

        this.commerceClient = commerceClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @desc Creates a new PayPal client token.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.ClientTokenResponse>} Returns a new Braintree client token.
     *
     */
    public async createBraintreeToken(): Promise<ClientTokenResponse>;

    @apiMethodDecorator()
    public async createBraintreeToken(apiOptions?: unknown) {
        const { logTransaction } = apiOptions as ApiOptions;

        return this.commerceClient.createBraintreeToken(
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {String} billingAgreementId - PayPal billing agreement id.
     * @desc Retrieves the Braintree payment method id for the PayPal payment method.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.PaymentMethodResponse>} - Returns a PayPal payment method id.
     *
     */
    public async getBraintreePaymentMethodId(
        billingAgreementId: string
    ): Promise<PaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            billingAgreementId: Types.nonEmptyString
        }
    })
    public async getBraintreePaymentMethodId(apiOptions: unknown) {
        const {
            logTransaction,
            args: [billingAgreementId]
        } = apiOptions as ApiOptions<[string]>;

        return this.commerceClient.getBraintreePaymentMethodId(
            billingAgreementId,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.CreateBraintreePaymentMethodRequest>} request - Data use to create a PayPal v2 payment method.
     * @desc Creates a PayPal v2 payment method to be used via Braintree.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Commerce.PaymentMethodResponse>} Returns a PayPal payment method.
     *
     */
    public async createBraintreePaymentMethod(
        request: CreateBraintreePaymentMethodRequest
    ): Promise<PaymentMethodResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(CreateBraintreePaymentMethodRequestTypedef)
        }
    })
    public async createBraintreePaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[CreateBraintreePaymentMethodRequest]>;

        return this.commerceClient.createBraintreePaymentMethod(
            request,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *s
     * @access public
     * @since 21.0.0
     * @param {Object<SDK.Commerce.PayPal.UpdateBraintreePaymentMethodRequest>} request - request Information required to begin a PayPal transaction.
     * @desc Begins a PayPal transaction.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Void>} Response contains the PayPal token used to complete the transaction.
     *
     */
    public async updateBraintreePaymentMethod(
        request: UpdateBraintreePaymentMethodRequest
    ): Promise<void>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(UpdateBraintreePaymentMethodRequestTypedef)
        }
    })
    public async updateBraintreePaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[UpdateBraintreePaymentMethodRequest]>;

        await this.commerceClient.updateBraintreePaymentMethod(
            request,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.PayPal.PayPalApi';
    }
}
