/**
 *
 * @module typedefs
 *
 */
/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import { PaymentMethodType, UpdateOrderType } from '../services/commerce/enums';

/**
 *
 * @typedef {Object} SDK.Commerce.AffiliateTracking
 * @since 9.0.0
 * @property {String} [affiliateId] - Unique identifier to identify an affiliate partner.
 * @property {String} [partnerId] - Affiliate partner name/id.
 * @property {String} [source] - Source of affiliate request.
 *
 */
export interface AffiliateTracking {
    affiliateId?: string;
    partnerId?: string;
    source?: string;
}

/**
 *
 * @access private
 *
 */
export const AffiliateTrackingTypedef = {
    affiliateId: Types.nonEmptyString.optional,
    partnerId: Types.nonEmptyString.optional,
    source: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.BillingAddress
 * @since 8.0.0
 * @property {String} [line1] - First line of address. Typically for street information.
 * @property {String} [line2] - Second line of address. Typically for apartment / unit number information.
 * @property {String} [line3] - Third line of address.
 * @property {String} [city] - City. Required for select regions.
 * @property {String} [state] - State / County / Province / Region. Required for select regions.
 * @property {String} country - Country. See ISO 3166-1 alpha-2 for format.
 * @property {String} [postalCode] - ZIP or postal code. Required for select regions.
 *
 */
export interface BillingAddress {
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    state?: string;
    country: string;
    postalCode?: string;
}

/**
 *
 * @access private
 *
 */
export const BillingAddressTypedef = {
    line1: Types.nonEmptyString.optional,
    line2: Types.nonEmptyString.optional,
    line3: Types.nonEmptyString.optional,
    city: Types.nonEmptyString.optional,
    state: Types.nonEmptyString.optional,
    country: Types.nonEmptyString,
    postalCode: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.CallbackUrl
 * @since 8.0.0
 * @property {String} cancelUrl - If the shopper hits cancel whilst on the Klarna screen they
 * will be sent to this location.
 * @property {String} pendingUrl - If the payment cannot be authorized in realtime i.e. the
 * bank is not online then it will be held in a pending status until updated to authorized or refused.
 * @property {String} successUrl - If the shopper gets AUTHORIZED, they will be sent to this location.
 * @property {String} failureUrl - If the shopper gets REFUSED, they will be sent to this location.
 *
 */
export interface CallbackUrl {
    cancelUrl: string;
    pendingUrl: string;
    successUrl: string;
    failureUrl: string;
}

/**
 *
 * @access private
 *
 */
export const CallbackUrlTypedef = {
    cancelUrl: Types.nonEmptyString,
    pendingUrl: Types.nonEmptyString,
    successUrl: Types.nonEmptyString,
    failureUrl: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.LineItem
 * @since 9.0.0
 * @property {String} sku - The sku of the product to be purchased.
 *
 */
export interface LineItem {
    sku: string;
}

/**
 *
 * @access private
 *
 */
export const LineItemTypedef = {
    sku: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.OrderCampaign
 * @since 9.0.0
 * @property {String} [campaignCode] - Campaign Code required to calculate price of the order.
 * @property {String} [voucherCode] - Voucher Code required to calculate price of the order.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 *
 */
export interface OrderCampaign {
    campaignCode?: string;
    voucherCode?: string;
    redemptionCode?: string;
}

/**
 *
 * @access private
 *
 */
export const OrderCampaignTypedef = {
    campaignCode: Types.nonEmptyString.optional,
    voucherCode: Types.nonEmptyString.optional,
    redemptionCode: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayerAddress
 * @since 9.0.0
 * @property {String} payerCountry
 * @property {String} [payerPostalCode]
 * @property {String} [payerCity]
 * @property {String} [payerState]
 *
 */
export interface PayerAddress {
    payerCountry: string;
    payerPostalCode?: string;
    payerCity?: string;
    payerState?: string;
}

/**
 *
 * @access private
 *
 */
export const PayerAddressTypedef = {
    payerCountry: Types.nonEmptyString,
    payerPostalCode: Types.nonEmptyString.optional,
    payerCity: Types.nonEmptyString.optional,
    payerState: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PayerName
 * @since 9.0.0
 * @property {String} firstName
 * @property {String} [middleName]
 * @property {String} lastName
 * @property {String} [suffix]
 *
 */
export interface PayerName {
    firstName: string;
    middleName?: string;
    lastName: string;
    suffix?: string;
}

/**
 *
 * @access private
 *
 */
export const PayerNameTypedef = {
    firstName: Types.nonEmptyString,
    middleName: Types.nonEmptyString.optional,
    lastName: Types.nonEmptyString,
    suffix: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PriceOrderRequestAddress
 * @since 9.0.0
 * @property {String} [zipCode] - Zip code for the address.
 * @property {String} [city] - City for the address.
 * @property {String} [state] - State for the address.
 * @property {String} [country] - Country for the address.
 *
 */
export interface PriceOrderRequestAddress {
    zipCode?: string;
    city?: string;
    state?: string;
    country?: string;
}

/**
 *
 * @access private
 *
 */
export const PriceOrderRequestAddressTypedef = {
    zipCode: Types.nonEmptyString.optional,
    city: Types.nonEmptyString.optional,
    state: Types.nonEmptyString.optional,
    country: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.RedeemRequest
 * @since 9.0.0
 * @property {String} redemptionCode - Code used to entitle a user without any payment information.
 * @property {Array<String>} skuList - Array of sku values to be redeemed with the request.
 *
 */
export interface RedeemRequest {
    redemptionCode: string;
    skuList: Array<string>;
}

/**
 *
 * @access private
 *
 */
export const RedeemRequestTypedef = {
    redemptionCode: Types.nonEmptyString,
    skuList: Types.array.of.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.RestartSubscriptionRequest
 * @since 9.0.0
 * @property {String} subscriptionId - Identifier to the subscription to be restarted.
 *
 */
export interface RestartSubscriptionRequest {
    subscriptionId: string;
}

/**
 *
 * @access private
 *
 */
export const RestartSubscriptionRequestTypedef = {
    subscriptionId: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.StrongCustomerAuth
 * @since 9.0.0
 * @property {String} sessionId - Unique identifier received from 3DS Authentication Partner.
 * @property {String} returnUrl - The URL on your website that will be invoked when the challenge is complete.
 *
 */
export interface StrongCustomerAuth {
    sessionId: string;
    returnUrl: string;
}

/**
 *
 * @access private
 *
 */
export const StrongCustomerAuthTypedef = {
    sessionId: Types.nonEmptyString,
    returnUrl: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.SwitchContextFrom - Info for the product being switched from.
 * @since 9.0.0
 * @property {String} [subscriptionId] - ID of the subscription being switched from.
 *
 */
export interface SwitchContextFrom {
    subscriptionId?: string;
}

/**
 *
 * @access private
 *
 */
export const SwitchContextFromTypedef = {
    subscriptionId: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.SwitchContextTo - Info for the product being switched to.
 * @since 9.0.0
 * @property {String} [offerId] - ID of the offer.
 * @property {String} [promotionId] - ID of the promotion.
 * @property {String} sku - SKU for the product being switched to.
 * @property {String} campaignCode - Campaign code for the product being switched to.
 * @property {String} voucherCode - Voucher code for the product being switched to.
 * underlying subscription.
 *
 */
export interface SwitchContextTo {
    offerId?: string;
    promotionId?: string;
    sku: string;
    campaignCode: string;
    voucherCode: string;
}

/**
 *
 * @access private
 *
 */
export const SwitchContextToTypedef = {
    offerId: Types.nonEmptyString.optional,
    promotionId: Types.nonEmptyString.optional,
    sku: Types.nonEmptyString,
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.UpdateOrderRequest
 * @since 9.0.0
 * @property {String<SDK.Services.Commerce.UpdateOrderType>} operation - Describes the kind of patch. Example values: SCA_SUCCESS, IDEAL_SUCCESS or REDIRECT_SUCCESS.
 * @property {String} guid - Unique identifier to identify an order.
 *
 */
export interface UpdateOrderRequest {
    operation: UpdateOrderType;
    guid: string;
}

/**
 *
 * @access private
 *
 */
export const UpdateOrderRequestTypedef = {
    operation: Types.in(UpdateOrderType),
    guid: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.PaymentMethod
 * @since 9.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise. See here for more details.
 * @property {String} country - Card country. See ISO 3166-1 alpha-2.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface PaymentMethod {
    paymentMethodId: string;
    type: PaymentMethodType;
    walletId: string;
    metadata?: object;
    partner: string;
    identityId?: string;
}

/**
 *
 * @access private
 *
 */
export const PaymentMethodTypedef = {
    paymentMethodId: Types.nonEmptyString,
    type: Types.in(PaymentMethodType),
    walletId: Types.nonEmptyString,
    metadata: Types.object().optional,
    partner: Types.nonEmptyString,
    identityId: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.UpdatePaymentMethodBase
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 *
 */
export interface UpdatePaymentMethodBase {
    paymentMethodId: string;
    type: PaymentMethodType;
}

/**
 *
 * @access private
 *
 */
export const UpdatePaymentMethodBaseTypedef = {
    paymentMethodId: Types.nonEmptyString,
    type: Types.in(PaymentMethodType)
};

/**
 *
 * @note Types below require other object types defined in this file.
 *
 */

/**
 *
 * @typedef {Object} SDK.Commerce.AccountResumeRequest
 * @since 9.0.0
 * @property {String} paymentMethodId - Identifier to retrieve payment information.
 * @property {Array<String>} subscriptions - Array of subscription identifiers of an account to resume that was put on hold due to billing failures.
 * @property {String} [cardSecurityCode] - CSC / CVV number of a credit card.
 * @property {Object<SDK.Commerce.StrongCustomerAuth>} [sca] - Additional information that is only required for 3DS security challenges.
 * @property {boolean} [isImmediateRetry] - Used to determine if user requested immediate retry of payment.
 *
 */
export interface AccountResumeRequest {
    paymentMethodId: string;
    subscriptions: Array<string>;
    cardSecurityCode?: string;
    sca?: StrongCustomerAuth;
    isImmediateRetry?: boolean;
}

/**
 *
 * @access private
 *
 */
export const AccountResumeRequestTypedef = {
    paymentMethodId: Types.nonEmptyString,
    subscriptions: Types.array.of.nonEmptyString,
    cardSecurityCode: Types.nonEmptyString.optional,
    sca: Types.object(StrongCustomerAuthTypedef).optional,
    isImmediateRetry: Types.boolean.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.SwitchContext
 * @since 9.0.0
 * @property {Object<SDK.Commerce.SwitchContextFrom>} from - Info for the product being switched from.
 * @property {Object<SDK.Commerce.SwitchContextTo>} to - Info for the product being switched to.
 *
 */
export interface SwitchContext {
    from: SwitchContextFrom;
    to: SwitchContextTo;
}

/**
 *
 * @access private
 *
 */
export const SwitchContextTypedef = {
    from: Types.object(SwitchContextFromTypedef),
    to: Types.object(SwitchContextToTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.PlanSwitchRequest
 * @since 5.0.0
 * @property {String} paymentMethodId - Identifier to retrieve payment information.
 * @property {String} [cardSecurityCode] - CSC/CVV number of a credit card.
 * @property {Object<SDK.Commerce.StrongCustomerAuth>} [sca] - Additional information that is only required
 * for 3DS security challenges.
 * @property {Object<SDK.Commerce.SwitchContext>} switchContext - Switch context containing products and
 * underlying subscription.
 *
 */
export interface PlanSwitchRequest {
    paymentMethodId: string;
    cardSecurityCode?: string;
    sca?: StrongCustomerAuth;
    switchContext: SwitchContext;
}

/**
 *
 * @access private
 *
 */
export const PlanSwitchRequestTypedef = {
    paymentMethodId: Types.nonEmptyString,
    cardSecurityCode: Types.nonEmptyString.optional,
    sca: Types.object(StrongCustomerAuthTypedef).optional,
    switchContext: Types.object(SwitchContextTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.SubmitOrderWithPaymentMethodRequest
 * @since 9.0.0
 * @property {Object<SDK.Commerce.AffiliateTracking>} [affiliateTracking] - Affiliated partner id and source.
 * @property {Array<Object<SDK.Commerce.LineItem>>} lineItems - Products to be purchased in this order.
 * @property {String} paymentMethodId - Payment method ID retrieved from a request to create or get a payment method.
 * @property {Array<Object<SDK.Commerce.OrderCampaign>>} orderCampaigns - Information relevant to special pricing considerations for an order.
 * @property {Object<SDK.Commerce.StrongCustomerAuth>} [sca] - Additional information that is only required for 3DS security challenges.
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {Object} [attributes] - Optional context to apply to purchase order.
 *
 */
export interface SubmitOrderWithPaymentMethodRequest {
    affiliateTracking?: AffiliateTracking;
    lineItems: Array<LineItem>;
    paymentMethodId: string;
    orderCampaigns: Array<OrderCampaign>;
    sca?: StrongCustomerAuth;
    offerId?: string;
    attributes?: object;
}

/**
 *
 * @access private
 *
 */
export const SubmitOrderWithPaymentMethodRequestTypedef = {
    affiliateTracking: Types.object(AffiliateTrackingTypedef).optional,
    lineItems: Types.array.of.object(LineItemTypedef),
    paymentMethodId: Types.nonEmptyString,
    orderCampaigns: Types.array.of.object(OrderCampaignTypedef),
    sca: Types.object(StrongCustomerAuthTypedef).optional,
    offerId: Types.nonEmptyString.optional,
    attributes: Types.object().optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.PriceOrderRequest
 * @since 9.0.0
 * @property {String} [offerId] - OfferId associated to the product identifiers. This is an optional field.
 * @property {Array<String>} [productIdentifiers] - List of identifiers (sku, catalog, productId).
 * @property {Number} [identifierType] - This field indicates type of productIdentifiers being sent. Service accepted values are 0, 1, 2 for sku, catalog and productId.
 * @property {String} [campaignCode] - Campaign Code required to calculate price of the order.
 * @property {String} [voucherCode] - Voucher Code required to calculate price of the order.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 * @property {Object<SDK.Commerce.PriceOrderRequestAddress>} [address] - Optional address information for the location the order originates from. If provided, the order's will reflect location-based taxes.
 *
 */
export interface PriceOrderRequest {
    offerId?: string;
    productIdentifiers?: Array<string>;
    identifierType?: number;
    campaignCode?: string;
    voucherCode?: string;
    redemptionCode?: string;
    address?: PriceOrderRequestAddress;
}

/**
 *
 * @access private
 *
 */
export const PriceOrderRequestTypedef = {
    offerId: Types.nonEmptyString.optional,
    productIdentifiers: Types.array.of.nonEmptyString.optional,
    identifierType: Types.number.optional,
    campaignCode: Types.nonEmptyString.optional,
    voucherCode: Types.nonEmptyString.optional,
    redemptionCode: Types.nonEmptyString.optional,
    address: Types.object(PriceOrderRequestAddressTypedef).optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.OrderSubmissionRequest
 * @since 9.0.0
 * @property {Object<SDK.Commerce.AffiliateTracking>} [affiliateTracking] - Affiliated partner id and source.
 * @property {String} [cardSecurityCode] - CSC/CVV number of a credit card.
 * @property {Array<Object<SDK.Commerce.LineItem>>} lineItems - Products to be purchased in this order.
 * @property {String} paymentMethodId - Payment method ID retrieved from a request to create or get a payment method.
 * @property {Array<Object<SDK.Commerce.OrderCampaign>>} orderCampaigns - Information relevant to special pricing considerations for an order.
 * @property {Object<SDK.Commerce.StrongCustomerAuth>} [sca] - Additional information that is only required for 3DS security challenges.
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {Object} [attributes] - Optional context to apply to purchase order.
 *
 */
export interface OrderSubmissionRequest {
    affiliateTracking?: AffiliateTracking;
    cardSecurityCode?: string;
    lineItems: Array<LineItem>;
    paymentMethodId: string;
    orderCampaigns: Array<OrderCampaign>;
    sca?: StrongCustomerAuth;
    offerId?: string;
    attributes?: object;
}

/**
 *
 * @access private
 *
 */
export const OrderSubmissionRequestTypedef = {
    affiliateTracking: Types.object(AffiliateTrackingTypedef).optional,
    cardSecurityCode: Types.nonEmptyString.optional,
    lineItems: Types.array.of.object(LineItemTypedef),
    paymentMethodId: Types.nonEmptyString,
    orderCampaigns: Types.array.of.object(OrderCampaignTypedef),
    sca: Types.object(StrongCustomerAuthTypedef).optional,
    offerId: Types.nonEmptyString.optional,
    attributes: Types.object().optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.AssociateAuthValuesWithPaymentMethodRequest
 * @since 12.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String} [dateOfBirth] - Date of birth of the user. This must be formatted as YYYY-MM-DD, 10 characters including the dashes.
 * @property {String} [pin] - Two-digit PIN used to authorize a transaction.
 *
 */
export interface AssociateAuthValuesWithPaymentMethodRequest {
    paymentMethodId: string;
    dateOfBirth?: string;
    pin?: string;
}

/**
 *
 * @access private
 *
 */
export const AssociateAuthValuesWithPaymentMethodRequestTypedef = {
    paymentMethodId: Types.nonEmptyString,
    dateOfBirth: Types.nonEmptyString.optional,
    pin: Types.nonEmptyString.optional
};
