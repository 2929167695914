/**
 *
 * @module privacyOptOut
 *
 */

/**
 *
 * @access public
 * @since 23.1.0
 * @typedef {Object} SDK.Advertising.PrivacyOptOut
 * @desc Indicates if a user has opted their device out of sharing personal information.
 *
 * @property {String} YES - Since `23.1.0` - User has opted out.
 * @property {String} NO - Since `23.1.0` - User has not opted out.
 * @property {String} ERROR - Since `23.1.0` - Failed to retrieve opt out information.
 *
 * @example
 * SDK.Advertising.PrivacyOptOut.ERROR // 'ERROR'
 *
 */
enum PrivacyOptOut {
    YES = 'YES',
    NO = 'NO',
    ERROR = 'ERROR'
}

export default PrivacyOptOut;
