/**
 *
 * @module accessTokenUpdater
 *
 */

/* istanbul ignore file */

/**
 *
 *
 */
export default class AccessTokenUpdater {
    /**
     *
     * @access private
     * @param {Object} [options={}]
     * @param {Boolean} [options.forceRefresh=false] - Forces a refresh regardless of the token's current expiration
     * @param {String} [options.reason] - The reason why the token is being refreshed.
     * @desc Refreshes the access token if it is about to expire
     * @returns {Promise<Void>}
     *
     */
    // eslint-disable-next-line
    refreshAccessToken(options: {
        forceRefresh?: boolean;
        reason: string;
    }): Promise<void> {
        return new Promise((resolve, reject) => {
            return reject(new Error('not-implemented'));
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.AccessTokenUpdater';
    }
}
