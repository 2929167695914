/**
 *
 * @module contentClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Provides extra data used by the `ContentClient`.
 *
 */
export default class ContentClientExtrasMap {
    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets the maximum size of the URL that can be used for `GET` operations.
     *
     */
    public urlSizeLimit: number;

    /**
     *
     * @param {Number} urlSizeLimit
     *
     */
    public constructor(urlSizeLimit: number) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                urlSizeLimit: Types.positive
            };

            typecheck.warn(this, params, arguments);
        }

        this.urlSizeLimit = urlSizeLimit;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Content.ContentClientExtrasMap';
    }
}
