/**
 *
 * @module receivedMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';
import { RawSocketMessage, SocketMessageAcknowledgment } from '../typedefs';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.11.1
 * @desc Handles message acknowledgement flows
 *
 */
export default class ReceivedMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.11.1
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageType = SocketUrns.socketManager.received;

        super(messageType);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 4.11.1
     * @param {Object} messageData
     * @desc Raises each message out of the SDK through the `SocketManager` `EventEmitter`
     * @note `message.accessToken` is deleted as to not send it on a regular retry message
     *
     */
    public process(messageData: RawSocketMessage) {
        const { eventId, status, retriesExhausted } = messageData.data ?? {};

        // This is the equivalent to receiving a pong so tell the pingPongMessageProcessor to reset it's ping timer
        this.socketManager.pingPongMessageProcessor.reset();

        if (!eventId) {
            return;
        }

        const socketMessageAcknowledgment = {
            eventId,
            status,
            retriesExhausted
        } as SocketMessageAcknowledgment;

        this.socketManager.envelopeMessageQueue.processAcknowledgment(
            (message) => this.socketManager.sendSocketMessage(message),
            (accessToken, message) => {
                this.socketManager.sendMessageWithSessionAuthentication({
                    message,
                    accessToken
                });
            },
            socketMessageAcknowledgment
        );
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.ReceivedMessageProcessor';
    }
}
