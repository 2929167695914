/**
 *
 * @module contentPlugin
 * @desc {SDK.Content.ContentPlugin} entry point.
 *
 */

import Content from './entry';
import ContentService from '../services/content/entry';

import ContentApi from './contentApi';
import ContentClient from '../services/content/contentClient';

import ContentClientConfiguration from '../services/content/contentClientConfiguration';
import ContentClientExtrasMap from '../services/content/contentClientExtrasMap';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Content', typeof Content, typeof ContentService> = {
    pluginName: 'Content',
    entry: Content,
    service: ContentService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.contentApi = new ContentApi({
            contentClient: new ContentClient({
                config: sdkSession.config.services.content,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { endpoints, extras, baseUrl } = services?.content?.client;

        serviceDefinitionsConfiguration.content =
            new ContentClientConfiguration({
                baseUrl,
                endpoints:
                    serviceDefinitionsConfiguration.processEndpoints(endpoints),
                extras: new ContentClientExtrasMap(extras.urlSizeLimit)
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
