/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import PlaybackVariant from '../services/media/playbackVariant';

import {
    AudioRendition,
    AudioRenditionTypedef,
    SubtitleRendition,
    SubtitleRenditionTypedef
} from '../services/media/typedefs';

import { PlaylistType } from '../services/media/enums';

import ServerRequest from '../services/qualityOfService/serverRequest';

import {
    ApplicationContext,
    AudioTrackType,
    BufferType,
    ErrorLevel,
    InterstitialEndedCause,
    InterstitialPlacement,
    InterstitialType,
    MediaSegmentType,
    PlaybackMode,
    PlaybackExitedCause,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackSeekCause,
    PlayerSeekDirection,
    PresentationType,
    QoePlaybackError,
    SeekDirection,
    SkipType,
    TimedTextTrackType
} from '../services/qualityOfService/enums';

import {
    AdPodData,
    AdPodDataTypedef,
    AdPodPlacement,
    AdPodPlacementTypedef,
    AdSlotData,
    AdSlotDataTypedef,
    AdVideoData,
    AdSubtitleData,
    AdAudioData,
    AdVideoDataTypedef,
    AdAudioDataTypedef,
    AdSubtitleDataTypedef,
    AdErrorData,
    AdErrorDataTypedef
} from '../services/qualityOfService/typedefs';

import MediaSource from './mediaSource';
import PlaybackSelectionAdTracking from '../services/media/playbackSelectionAdTracking';

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdMetadata
 * @since 19.0.0
 * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the current ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} adPodPlacement - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} adPodData - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} adSlotData - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Number} adPlayheadPosition - Since `19.0.0` - The location of the current ad playhead, measured as a millisecond offset
 * from the start time of the current ad pod.
 *
 */
export interface AdMetadata {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adPlayheadPosition?: number;
    mediaId?: string;
    adSubtitleData?: {
        subtitleVisibility: boolean;
        playlistSubtitleLanguage?: string;
        playlistSubtitleName?: string;
    };
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    mediaUrl?: string;
}

/**
 *
 * @access private
 *
 */
export const AdMetadataTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef),
    adPodData: Types.object(AdPodDataTypedef),
    adSlotData: Types.object(AdSlotDataTypedef),
    adPlayheadPosition: Types.number,
    mediaId: Types.string.optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    mediaUrl: Types.string.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AudioBitrateChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onAudioBitrateChanged event.
 * @property {Number} [bitrateAvg] - The average audio bitrate the player switched to (in bps).
 *
 */
export interface AudioBitrateChangedEvent {
    bitrateAvg?: number;
}

/**
 *
 * @access private
 *
 */
export const AudioBitrateChangedEventTypedef = {
    bitrateAvg: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.BitrateChangedEvent
 * @since 13.0.0
 * @desc Provides details about the onBitrateChanged event.
 * @property {Number} [bitrateAvg] - The average bitrate the player switched to, for the currently selected presentationType, in bps.
 * @property {Number} [bitratePeak] - The peak bitrate the player switched to, for the currently selected presentationType, in bps.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface BitrateChangedEvent {
    bitrateAvg?: Nullable<number>;
    bitratePeak?: Nullable<number>;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const BitrateChangedEventTypedef = {
    bitrateAvg: Types.number.optional,
    bitratePeak: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.MultivariantPlaylistFetchedEvent
 * @since 15.0.0
 * @desc Provides details about the onMultivariantPlaylistFetched event.
 * @property {String<SDK.Services.Media.PlaylistType>} [playlistLiveType] - The variety of live playlist
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Data about a request/response to a server.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time.
 * @property {Number} [multivariantFetchedStartTime] - The client epoch timestamp in milliseconds when the multivariant playlist fetch begins.
 * @property {Number} [multivariantFetchedDuration] - The location of the current playhead, measured as a millisecond offset from the start time.
 * @property {Number|null} [mediaStartBitrate] - The starting bitrate for the media content.
 *
 */
export interface MultivariantPlaylistFetchedEvent {
    playlistLiveType?: PlaylistType;
    serverRequest?: ServerRequest;
    playheadPosition?: number;
    multivariantFetchedStartTime?: number;
    multivariantFetchedDuration?: number;
    mediaStartBitrate?: Nullable<number>;
}

/**
 *
 * @access private
 *
 */
export const MultivariantPlaylistFetchedEventTypedef = {
    playlistLiveType: Types.in(PlaylistType).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    playheadPosition: Types.number.optional,
    multivariantFetchedStartTime: Types.number.optional,
    multivariantFetchedDuration: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackEnded event.
 * @note For CDN fallback logic - when the PlayerAdapter picks up the onPlaybackEnded event and the cause is PlaybackExitedCause.error
 * and the error is PlaybackError.serviceError, the SDK is expected to call PlayerAdapter.setSource with the return value of Playlist.getNextUrl.
 * @property {String<SDK.Services.QualityOfService.PlaybackExitedCause>} [cause] - The reason for exiting playback.
 * @property {String<SDK.Services.QualityOfService.QoePlaybackError>} [error] - The error describing the failure. Null if no error occured.
 * @property {String} [errorDetail] - Supporting error text for `error` property where this can be provided for additional context. If `error` == `adBeaconError`,
 * this field should be populated with the `beacon_url` that failed.
 * @property {String<SDK.Services.QualityOfService.ApplicationContext>} [applicationContext] - Since `19.0.0` - The failure context. Null if no error occurred.
 * This is expected to be either `player` or `ad`.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. This should be supplied if playback ended due
 * to an error related to an `ad` or while preparing/playing an ad. Required if the current `applicationContext` is `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackEndedEvent {
    cause?: PlaybackExitedCause;
    error?: Nullable<QoePlaybackError>;
    errorDetail?: string;
    applicationContext?: ApplicationContext;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
    cdnRequestedTrail?: Array<string>;
    cdnFailedTrail?: Array<string>;
    cdnFallbackCount?: number;
    isCdnFallback?: boolean;
}

/**
 *
 * @access private
 *
 */
export const PlaybackEndedEventTypedef = {
    cause: Types.in(PlaybackExitedCause).optional,
    error: Types.in(QoePlaybackError).optional,
    errorDetail: Types.nonEmptyString.optional,
    applicationContext: Types.in(ApplicationContext).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackInitializedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackInitialized event.
 * @property {SDK.Services.Media.PlaybackVariant} [variant] - The variant that playback is starting with.
 * @property {SDK.Services.Media.AudioRendition} [audio] - Selected audio rendition.
 * @property {SDK.Services.Media.SubtitleRendition} [subtitle] - Selected subtitle rendition. Null if not selected.
 * @property {Number} [startupBitrate] - Starting peak bitrate (in bps), if set by application. If value is 0 or not available return 0.
 * @property {Number} [startupAverageBitrate] - Starting average bitrate (in bps), if set by application. If value is 0 or not available return 0.
 * @property {Boolean} [areSubtitlesVisible] - Subtitle visibility.
 * @property {String} [streamUrl] - This should be retrieved from whichever `PrioritizedUrl` is active on the `Playlist`.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [initializePlayerStartTime] - Since `23.0.0` - The client epoch timestamp in milliseconds when the creation of the non-UI player stack begins.
 * @property {Number} [initializePlayerDuration] - Since `23.0.0` - The total time in milliseconds it took to create the non-UI player stack.
 * @property {SDK.Services.QualityOfService.AudioTrackType} [playlistAudioTrackType] - The selected audio track type.
 * @property {SDK.Services.QualityOfService.TimedTextTrackType} [playlistTimedTextTrackType] - The selected timed text track type. Null if not selected or subtitles are disabled.
 *
 */
export interface PlaybackInitializedEvent {
    variant?: PlaybackVariant;
    audio?: AudioRendition;
    subtitle?: SubtitleRendition;
    startupBitrate?: Nullable<number>;
    startupAverageBitrate?: Nullable<number>;
    areSubtitlesVisible?: boolean;
    streamUrl?: string | Array<MediaSource>;
    playheadPosition?: Nullable<number>;
    initializePlayerStartTime?: number;
    initializePlayerDuration?: number;
    playlistAudioTrackType?: AudioTrackType;
    playlistTimedTextTrackType?: TimedTextTrackType;
    playbackMode?: PlaybackMode;
}

/**
 *
 * @access private
 *
 */
export const PlaybackInitializedEventTypedef = {
    variant: Types.instanceStrict(PlaybackVariant).optional,
    audio: Types.object(AudioRenditionTypedef).optional,
    subtitle: Types.object(SubtitleRenditionTypedef).optional,
    startupBitrate: Types.number.optional,
    areSubtitlesVisible: Types.boolean.optional,
    streamUrl: Types.nonEmptyString.optional,
    playheadPosition: Types.number.optional,
    initializePlayerStartTime: Types.number.optional,
    initializePlayerDuration: Types.number.optional,
    playlistAudioTrackType: Types.in(AudioTrackType).optional,
    playlistTimedTextTrackType: Types.in(TimedTextTrackType).optional,
    playbackMode: Types.in(PlaybackMode).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackPausedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackPaused event.
 * @property {String<SDK.Services.QualityOfService.PlaybackPausedCause>} [cause] - The reason for pausing playback.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackPausedEvent {
    cause?: PlaybackPausedCause;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackPausedEventTypedef = {
    cause: Types.in(PlaybackPausedCause).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackReadyEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackReady event.
 * @property {Number} [bufferSegmentDuration] - The amount of video currently fully buffered in milliseconds.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} readyPlayerStartTime - The client epoch timestamp in milliseconds when the platform-appropriate playback measurement begins.
 * @property {Number} readyPlayerDuration - The total time in milliseconds it took to load the first media segment for all elementary streams (audio, video, and text) active at the start of playback.
 * @property {Number|null} [mediaStartBitrate] - The starting bitrate for the media content.
 */
export interface PlaybackReadyEvent {
    bufferSegmentDuration?: number;
    playheadPosition?: number;
    readyPlayerStartTime: number;
    readyPlayerDuration: number;
    mediaStartBitrate?: Nullable<number>;
}

/**
 *
 * @access private
 *
 */
export const PlaybackReadyEventTypedef = {
    bufferSegmentDuration: Types.number.optional,
    playheadPosition: Types.number.optional,
    readyPlayerStartTime: Types.number,
    readyPlayerDuration: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackResumedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackResumed event.
 * @property {String<SDK.Services.QualityOfService.PlaybackResumedCause>} [cause] - The reason for resuming playback.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackResumedEvent {
    cause?: PlaybackResumedCause;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackResumedEventTypedef = {
    cause: Types.in(PlaybackResumedCause).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackSeekEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackSeekEnded event.
 * @property {String<SDK.Services.QualityOfService.PlaybackSeekCause>} [cause] - Reason playback seeked.
 * @property {String<SDK.Services.QualityOfService.PlayerSeekDirection>} [playerSeekDirection] - Where the intended seek position is relative to current position.
 * @property {Number} [seekDistance] - How far the player is attempting to seek in stream time, in milliseconds.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 * @property {Object{SDK.Services.QualityOfService.SkipType}} [skipType] - Since `28.0.0` - This field is used to indicate user engagement on skip functionality during playback.
 *
 */
export interface PlaybackSeekEndedEvent {
    cause?: PlaybackSeekCause;
    playerSeekDirection?: PlayerSeekDirection;
    seekDistance?: number;
    seekDirection?: SeekDirection;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
    skipType?: SkipType;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSeekEndedEventTypedef = {
    cause: Types.in(PlaybackSeekCause).optional,
    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
    seekDistance: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional,
    skipType: Types.in(SkipType).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackSeekStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackSeekStarted event.
 * @property {String<SDK.Services.QualityOfService.PlaybackSeekCause>} [cause] - Reason playback seeked.
 * @property {String<SDK.Services.QualityOfService.PlayerSeekDirection>} [playerSeekDirection] - Where the intended seek position is relative to current position.
 * @property {Number} [seekDistance] - How far the player is attempting to seek in stream time, in milliseconds.
 * @property {String<SDK.Services.QualityOfService.SeekDirection} [seekDirection] - Since `26.0.0` - Used for Live events.
 * @property {Number} [seekSize] - Since `26.0.0` - Indication that the user is seeking by a fixed time (size) e.g. +30, -30.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 * @property {String{SDK.Services.QualityOfService.SkipType}} [skipType] - Since `28.0.0` - This field is used to indicate user engagement on skip functionality during playback.
 *
 */
export interface PlaybackSeekStartedEvent {
    cause?: PlaybackSeekCause;
    playerSeekDirection?: PlayerSeekDirection;
    seekDistance?: number;
    seekDirection?: SeekDirection;
    seekSize?: number;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
    skipType?: SkipType;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSeekStartedEventTypedef = {
    cause: Types.in(PlaybackSeekCause).optional,
    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
    seekDistance: Types.number.optional,
    seekDirection: Types.in(SeekDirection).optional,
    seekSize: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional,
    skipType: Types.in(SkipType).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onPlaybackStarted event.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [videoBitrate] - Nominal peak (encoded) bitrate of the currently selected presentationType or of the presentationType/variant last played, as
 * reported in the HLS or DASH manifest.
 * @property {Number} [videoAverageBitrate] - Nominal average (encoded) bitrate of the currently selected presentationType or of the presentationType/variant last
 * played, as reported in the HLS or DASH manifest.
 * @property {Number} [audioBitrate] - Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant
 * last played before the event, as reported in the HLS or DASH manifest.
 * @property {Number} [maxAllowedVideoBitrate] - The highest video bitrate (in bps) available for the currently selected presentationType in the current DASH/HLS
 * manifest that is allowed to play. The user may not reach this bitrate in their session.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {String<SDK.Services.QualityOfService.PresentationType>} [presentationType] - Since `19.0.0` - The type of presentation currently being played. Return unknown
 * if in playing state but presentation type is unknown.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface PlaybackStartedEvent {
    playheadPosition?: Nullable<number>;
    videoBitrate?: number;
    videoAverageBitrate?: number;
    audioBitrate?: number;
    maxAllowedVideoBitrate?: number;
    segmentPosition?: number;
    presentationType?: PresentationType;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const PlaybackStartedEventTypedef = {
    playheadPosition: Types.number.optional,
    videoBitrate: Types.number.optional,
    videoAverageBitrate: Types.number.optional,
    audioBitrate: Types.number.optional,
    maxAllowedVideoBitrate: Types.number.optional,
    segmentPosition: Types.number.optional,
    presentationType: Types.in(PresentationType).optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.RebufferingEndedEvent
 * @since 13.0.0
 * @desc Provides details about the onRebufferingEndedEvent event.
 * @property {String<SDK.Services.QualityOfService.BufferType>} [bufferType] - The type of buffering that is happening.
 * @property {Number} [duration] - The amount of wall clock time, in milliseconds, that occurred during rebuffering.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface RebufferingEndedEvent {
    bufferType?: BufferType;
    duration?: number;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const RebufferingEndedEventTypedef = {
    bufferType: Types.in(BufferType).optional,
    duration: Types.number.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.RebufferingStartedEvent
 * @since 13.0.0
 * @desc Provides details about the onRebufferingStarted event.
 * @property {String<SDK.Services.QualityOfService.BufferType>} [bufferType] - The type of buffering that is happening.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [segmentPosition] - This should be the epoch time in milliseconds of the video.
 * @property {Number} [liveLatencyAmount] - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `presentationType` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface RebufferingStartedEvent {
    bufferType?: BufferType;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const RebufferingStartedEventTypedef = {
    bufferType: Types.in(BufferType).optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.VariantPlaylistFetchedEvent
 * @since 13.0.0
 * @desc Provides details about the onVariantPlaylistFetched event.
 * @property {Number} [playlistAverageBandwidth] - Value of `AVERAGE-BANDWIDTH` attribute of variant playlist (if a video variant).
 * @property {Number} [playlistBandwidth] - Value of `BANDWIDTH` attribute of variant playlist.
 * @property {String} [playlistChannels] - Value of `CHANNELS` attribute of variant playlist (if an audio variant).
 * @property {String} [playlistName] - Value of `NAME` attribute (for audio and subtitle variants).
 * @property {String} [playlistLanguage] - Value of `LANGUAGE` attribute (for audio and subtitle variants).
 * @property {String<SDK.Services.QualityOfService.MediaSegmentType>} [mediaSegmentType] - What type of content a media segment contains.
 * @property {String} [playlistResolution] - Resolution as defined by a variant playlist.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Data about a request/response to a server.
 * @property {Number} [playheadPosition] - The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
 * @property {Number} [variantFetchedStartTime] - The client epoch timestamp in milliseconds when the variant playlist fetch begins.
 * @property {Number} [variantFetchedDuration] - The total time in milliseconds it took to load the variant playlist, including network request and local parse time.
 *
 */
export interface VariantPlaylistFetchedEvent {
    playlistAverageBandwidth?: Nullable<number>;
    playlistBandwidth?: Nullable<number>;
    playlistChannels?: string;
    playlistName?: string;
    playlistLanguage?: string;
    mediaSegmentType?: MediaSegmentType;
    playlistResolution?: string;
    serverRequest?: ServerRequest;
    playheadPosition?: number;
    variantFetchedStartTime?: number;
    variantFetchedDuration?: number;
}

/**
 *
 * @access private
 *
 */
export const VariantPlaylistFetchedEventTypedef = {
    playlistAverageBandwidth: Types.number.optional,
    playlistBandwidth: Types.number.optional,
    playlistChannels: Types.nonEmptyString.optional,
    playlistName: Types.nonEmptyString.optional,
    playlistLanguage: Types.nonEmptyString.optional,
    mediaSegmentType: Types.in(MediaSegmentType).optional,
    playlistResolution: Types.nonEmptyString.optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    playheadPosition: Types.number.optional,
    variantFetchedStartTime: Types.number.optional,
    variantFetchedDuration: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PresentationTypeChangedEvent
 * @since 19.0.0
 * @desc Provides details about the onPresentationTypeChanged event.
 * @property {SDK.Services.QualityOfService.PresentationType} [presentationType] - Since `19.0.0` - The new presentation type that the player has transitioned to.
 * @property {Number} [videoBitrate] - Since 19.0.0 - Nominal peak (encoded) bitrate of the currently selected presentationType or
 * of the presentationType/variant last played, as reported in the HLS or DASH manifest. For HLS Manifest, use the 'BANDWIDTH' value
 * and for DASH, use the '@bandwidth' value. If value is not available, default to 0.
 * @property {Number} [videoAverageBitrate] - Since 19.0.0 - Nominal average (encoded) bitrate of the currently selected presentationType
 * or of the presentationType/variant last played, as reported in the HLS or DASH manifest. For HLS Manifest, use the 'AVERAGE-BANDWIDTH' value.
 * If value is not available, default to 0.
 * @property {Number} [audioBitrate] - Since 19.0.0 - Nominal average (encoded) bitrate of the currently selected audio representation or of the audio
 * representation/variant last played before the event, as reported in the HLS or DASH manifest. If value is not available, default to 0. If value is muxed,
 * send the bitrate of the combined content.
 * @property {Number} [audioChannels] - Since 19.0.0 - Value of `CHANNELS` attribute of the selected variant playlist (if an audio variant).
 * @property {String} [audioCodec] - Since 19.0.0 - The audio codec of the selected variant playlist.
 * @property {SDK.Services.Media.AudioRendition} [audio] - Since `19.0.0` - The audio codec of the selected variant playlist.
 * @property {SDK.Services.Media.SubtitleRendition} [subtitle] - Since `19.0.0` - Selected subtitle rendition. Null if not selected or subtitles are disabled.
 * @property {Boolean} [areSubtitlesVisible] - Since 19.0.0 - Subtitle visibility
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since 22.0.0 - Metadata for the currently presented ad.
 * @property {SDK.Services.QualityOfService.AudioTrackType} [playlistAudioTrackType] - Since 23.0.0 - The selected audio track type.
 * @property {SDK.Services.QualityOfService.TimedTextTrackType} [playlistTimedTextTrackType] - Since 23.0.0 - The selected timed text track type. Null if not selected or subtitles are disabled.
 *
 */
export interface PresentationTypeChangedEvent {
    presentationType?: PresentationType;
    videoBitrate?: number;
    videoAverageBitrate?: number;
    audioBitrate?: number;
    audioChannels?: number;
    audioCodec?: string;
    audio?: AudioRendition;
    subtitle?: SubtitleRendition;
    areSubtitlesVisible?: boolean;
    adMetadata?: AdMetadata;
    playlistAudioTrackType?: AudioTrackType;
    playlistTimedTextTrackType?: TimedTextTrackType;
    areSubtitlesEnabled?: boolean;
}

/**
 *
 * @access private
 *
 */
export const PresentationTypeChangedEventTypedef = {
    presentationType: Types.in(PresentationType).optional,
    videoBitrate: Types.number.optional,
    videoAverageBitrate: Types.number.optional,
    audioBitrate: Types.number.optional,
    audioChannels: Types.number.optional,
    audioCodec: Types.nonEmptyString.optional,
    audio: Types.object(AudioRenditionTypedef).optional,
    subtitle: Types.object(SubtitleRenditionTypedef).optional,
    areSubtitlesVisible: Types.boolean.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional,
    playlistAudioTrackType: Types.in(AudioTrackType).optional,
    playlistTimedTextTrackType: Types.in(TimedTextTrackType).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.NonFatalPlaybackErrorEvent
 * @since 19.0.0
 * @desc Provides details about the onNonFatalPlaybackError event.
 * @property {SDK.Media.QoePlaybackError} [error] - Since `19.0.0` - The error describing the non-fatal playback error.
 * @property {SDK.Services.QualityOfService.ErrorLevel} [errorLevel] - Since `19.0.0` - The error impact level.
 * @property {SDK.Services.QualityOfService.ApplicationContext} [applicationContext] - Since `19.0.0` - This is expected to be either `player` or `ad`.
 * @property {String} [errorDetail] - Since `19.0.0` - Supporting error text for `error` property where this can be provided for additional context.
 * Unless requested otherwise, set to native platform error message if available. If `error` == `adBeaconError`, this field should be populated with the `beacon_url` that failed.
 * @property {Number} [playheadPosition] - Since `19.0.0` - The location of the current playhead, measured as a millisecond offset from the start time.  -1 if value is not available.
 * @property {Number} [segmentPosition] - Since `19.0.0` - This should be the epoch time in milliseconds of the video. Required if productType is 'Live'.
 * @property {Number} [liveLatencyAmount] - Since `19.0.0` - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * Required if productType is 'Live'.
 * @property {Object<SDK.Media.AdMetadata>} [adMetadata] - Since `19.0.0` - Metadata for the currently presented ad. Required if the current `applicationContext` is
 * `ad` and `adInsertionType` != `none`.
 *
 */
export interface NonFatalPlaybackErrorEvent {
    error?: QoePlaybackError;
    errorLevel?: ErrorLevel;
    applicationContext?: ApplicationContext;
    errorDetail?: string;
    playheadPosition?: number;
    segmentPosition?: number;
    liveLatencyAmount?: number;
    adMetadata?: AdMetadata;
}

/**
 *
 * @access private
 *
 */
export const NonFatalPlaybackErrorEventTypedef = {
    error: Types.in(QoePlaybackError).optional,
    errorLevel: Types.in(ErrorLevel).optional,
    applicationContext: Types.in(ApplicationContext).optional,
    errorDetail: Types.nonEmptyString.optional,
    playheadPosition: Types.number.optional,
    segmentPosition: Types.number.optional,
    liveLatencyAmount: Types.number.optional,
    adMetadata: Types.object(AdMetadataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.SnapshotCreatedEvent
 * @since 19.0.0
 * @desc Provides details about the created `SDK.QualityOfService.SnapshotEvent`.
 * @property {Object<SDK.Services.QualityOfService.SnapshotEvent>} [snapshotEvent] - Since `19.0.0` - Subtitle language of selected subtitle rendition.
 *
 */
export interface SnapshotCreatedEvent {
    snapshotEvent?: Record<string, unknown>;
}

/**
 *
 * @access private
 *
 */
export const SnapshotCreatedEventTypedef = {
    snapshotEvent: Types.object().optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPodRequestedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPodRequested event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 *
 */
export interface AdPodRequestedEvent {
    adPodPlacement?: AdPodPlacement;
}

/**
 *
 * @access private
 *
 */
export const AdPodRequestedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPodFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPodRequested event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about a request/response to a server.
 *
 */
export interface AdPodFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    serverRequest?: ServerRequest;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdPodFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdMultivariantFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdMultivariantFetched event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about a request/response to a server.
 *
 */
export interface AdMultivariantFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    serverRequest?: ServerRequest;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdMultivariantFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdVariantFetchedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdVariantFetched event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {SDK.Services.QualityOfService.ServerRequest} [serverRequest] - Since `19.0.0` - Data about a request/response to a server.
 * @property {String<SDK.Services.QualityOfService.MediaSegmentType>} [mediaSegmentType] - Since `19.0.0` - The type of variant downloaded.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 *
 */
export interface AdVariantFetchedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    serverRequest?: ServerRequest;
    mediaSegmentType?: MediaSegmentType;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
    startTimestamp?: number;
}

/**
 *
 * @access private
 *
 */
export const AdVariantFetchedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    mediaSegmentType: Types.in(MediaSegmentType).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional,
    startTimestamp: Types.number.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPlaybackStartedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPlaybackStarted event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 *
 */
export interface AdPlaybackStartedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
}

/**
 *
 * @access private
 *
 */
export const AdPlaybackStartedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.AdPlaybackEndedEvent
 * @since 19.0.0
 * @desc Provides details about the onAdPlaybackEnded event.
 * @property {Object<SDK.Services.QualityOfService.AdPodPlacement>} [adPodPlacement] - Since `19.0.0` - Placement information relevant to ad pods.
 * @property {Object<SDK.Services.QualityOfService.AdPodData>} [adPodData] - Since `19.0.0` - Metadata relevant to the fetched ad pod.
 * @property {Object<SDK.Services.QualityOfService.AdSlotData>} [adSlotData] - Since `19.0.0` - Metadata relevant to the fetched ad.
 * @property {Object<SDK.Services.QualityOfService.AdVideoData>} [adVideoData] - Since `19.0.0` - Descriptive information about the downloaded video variant.
 * Required if `mediaSegmentType = video`.
 * @property {Object<SDK.Services.QualityOfService.AdAudioData>} [adAudioData] - Since `19.0.0` - Descriptive information about the downloaded audio variant.
 * Required if `mediaSegmentType = audio`.
 * @property {Object<SDK.Services.QualityOfService.AdSubtitleData>} [adSubtitleData] - Since `19.0.0` - Descriptive information about the downloaded subtitle variant.
 * Required if `mediaSegmentType = subtitle`.
 * @property {String<SDK.Services.QualityOfService.PlaybackExitedCause>} [cause] - Since `19.0.0` - The reason for ending ad playback.
 * @property {Object<SDK.Services.QualityOfService.AdErrorData>} [adErrorData] - Since `19.0.0` - Metadata relevant to ad-related errors. Required if `cause` = `error`.
 *
 */
export interface AdPlaybackEndedEvent {
    adPodPlacement?: AdPodPlacement;
    adPodData?: AdPodData;
    adSlotData?: AdSlotData;
    adVideoData?: AdVideoData;
    adAudioData?: AdAudioData;
    adSubtitleData?: AdSubtitleData;
    cause?: PlaybackExitedCause;
    adErrorData?: AdErrorData;
}

/**
 *
 * @access private
 *
 */
export const AdPlaybackEndedEventTypedef = {
    adPodPlacement: Types.object(AdPodPlacementTypedef).optional,
    adPodData: Types.object(AdPodDataTypedef).optional,
    adSlotData: Types.object(AdSlotDataTypedef).optional,
    adVideoData: Types.object(AdVideoDataTypedef).optional,
    adAudioData: Types.object(AdAudioDataTypedef).optional,
    adSubtitleData: Types.object(AdSubtitleDataTypedef).optional,
    cause: Types.in(PlaybackExitedCause).optional,
    adErrorData: Types.object(AdErrorDataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.VariantStream
 * @since 22.0.0
 * @desc Provides details about the VariantStream object.
 * @property {String} type - The type constant.
 * @property {Number} index - The ordered index value for this variant after sorting by bitrate in ascending order.
 * @property {String} label - A human readable string to refer to this rendition.
 * @property {Number} peakBitrate - The published peak bitrate (BANDWIDTH attribute) in bits per second.
 * @property {Number} averageBitrate - The published avarage bitrate (AVERAGE-BANDWIDTH attribute) in bits per second.
 * @property {String} videoCodec - The published video codec.
 * @property {Array<String>} audioCodecs - The list of audio codecs associated with this variant stream.
 * @property {Number} width - The published resolution width.
 * @property {Number} height - The published resolution height.
 * @property {String} url - The fully resolved source URL for this rendition playlist.
 * @property {String} audioGroup - The audio group ID which this variant is associated with.
 * @property {Number} encodedFrameRate - The encoded framerate as published for this variant stream in the multi-variant playlist.
 * @property {String} videoRange - The video range attribute as published for this variant in the multi-variant playlist.
 * @property {Object} attributes - The full attribute list parsed from the HLS multi-variant playlist.
 *
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#variantstream
 */
export type VariantStream = {
    type: string;
    index: number;
    label: string;
    peakBitrate: number;
    averageBitrate: number;
    videoCodec: string;
    audioCodecs: Array<string>;
    width: number;
    height: number;
    url: string;
    audioGroup: string;
    encodedFrameRate: number;
    videoRange: string;
    attributes: Record<string, unknown>;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.NetworkMetricsEventData
 * @since 22.0.0
 * @desc Provides details about the NetworkMetricsEventData event data.
 * @property {Number} perceivedBitrate - The perceived bitrate (bits per second) of the network as observed by the ABR Controller.
 * @property {Number} throughput - The current network throughput (bits per second) Calculated using video segments only.
 * @property {Number} avgSegmentLatency - The current average segment network latency in milliseconds.
 * @property {Number} currentVariantIndex - The index of the currently chosen variant stream.
 * @property {VariantStream} currentVariant - The currently chosen variant stream.
 *
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#networkmetrics
 */
export type NetworkMetricsEventData = {
    perceivedBitrate: number;
    throughput: number;
    avgSegmentLatency: number;
    currentVariantIndex: number;
    currentVariant: VariantStream;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackMetricsEventData
 * @since 22.0.0
 * @desc Provides details about the PlaybackMetricsEventData event data.
 * @property {Number} mainContentTime
 * @property {Number} mainContentDuration
 * @property {Number} currentTime - The current position on the media timeline in seconds.
 * @property {Number} duration - The duration of the presentation in seconds.
 * @property {Number} encodedFrameRate - The encoded frame rate published in the playlist.
 * @property {Number} frameRate - The current measured frame rate.
 * @property {Number} droppedFramesCount - The total number of dropped frames.
 * @property {Object} bufferedRange
 * @property {Number} bufferedRange.start - Timeline position in seconds
 * @property {Number} bufferedRange.end - Timeline position in seconds
 * @property {Object} seekableRange
 * @property {Number} seekableRange.start - Timeline position in seconds
 * @property {Number} seekableRange.end - Timeline position in seconds
 * @property {Number} seekableRange.pdtStart - Presentation date time in milliseconds since the epoch.
 * @property {Number} seekableRange.pdtEnd - Presentation date time in milliseconds since the epoch.
 * @property {Number} seekableRange.duration - Range duration in seconds.
 *
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/data-types.html#playbackmetrics
 */
export type PlaybackMetricsEventData = {
    mainContentTime: number;
    mainContentDuration: number;
    currentTime: number;
    duration: number;
    encodedFrameRate: number;
    frameRate: number;
    droppedFramesCount: number;
    bufferedRange: {
        start: number;
        end: number;
    };
    seekableRange: {
        start: number;
        end: number;
        pdtStart: number;
        pdtEnd: number;
        duration: number;
    };
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackErrorInfo
 * @since 22.0.0
 * @desc Provides error details onPlaybackError event.
 * @property {Boolean} isFatal - The error describing the non-fatal playback error.
 * @property {SDK.Services.QualityOfService.QoePlaybackError} errorName - The name of the error.
 * @property {String} [errorMessage] - The error message.
 * @property {SDK.Services.QualityOfService.ErrorLevel} [errorLevel] - Since `19.0.0` - The error impact level.
 * @property {Number} [playheadPosition] - Since `22.0.0` - The location of the current playhead, measured as a millisecond offset from the start time.  -1 if value is not available.
 * @property {Number} [segmentPosition] - Since `22.0.0` - This should be the epoch time in milliseconds of the video. Required if productType is 'Live'.
 * @property {Number} [liveLatencyAmount] - Since `22.0.0` - Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
 * @property {SDK.Media.AdSlotData} [adSlotData] - Since `22.0.0`
 * @property {SDK.Media.AdPodData} [adPodData] - Since `22.0.0`
 * @property {SDK.Media.AdPodPlacement} [adPodPlacement] - Since `22.0.0`
 *
 */
export type PlaybackErrorInfo = {
    isFatal: boolean;
    errorName: QoePlaybackError;
    errorMessage?: string;
    errorLevel?: ErrorLevel;
    playheadPosition?: Nullable<number>;
    segmentPosition?: number;
    liveLatencyAmount?: Nullable<number>;
    adSlotData?: AdSlotData;
    adPodData?: AdPodData;
    adPodPlacement?: AdPodPlacement;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.DrmKeyFetchedEvent
 * @since 23.0.0
 * @desc Provides details about the onDrmKeyFetched event.
 * @note This event type does not exist in the SDK spec.
 * @property {String} [drmKeyId] - Since `23.0.0` - ID representing the DRM Key. Obtained from the HLS Media Playlist, a new key is signalled by EXT-X-KEY tags.
 * @property {SDK.Services.QualityOfService.ServerRequest} serverRequest - Since `23.0.0` - Data about a request/response to a server.
 * @property {Number} fetchLicenseStartTime - Since `23.0.0` - The client epoch timestamp in milliseconds when the DRM license request begins.
 * @property {Number} fetchLicenseDuration - Since `23.0.0` - The total time in milliseconds it took to acquire the DRM license.
 *
 */
export type DrmKeyFetchedEvent = {
    drmKeyId?: string;
    serverRequest: ServerRequest;
    fetchLicenseStartTime: number;
    fetchLicenseDuration: number;
};

/**
 *
 * @access private
 *
 */
export const DrmKeyFetchedEventTypedef = {
    drmKeyId: Types.nonEmptyString.optional,
    serverRequest: Types.instanceStrict(ServerRequest).optional,
    fetchLicenseStartTime: Types.number,
    fetchLicenseDuration: Types.number
};

/**
 *
 * @typedef {Object} PlaylistEvent
 * @since 24.0.0
 * @property {UrlString} url - Since `24.0.0`.
 * @property {String} assetId - Since `24.0.0`.
 *
 */
export type PlaylistEvent = {
    url: UrlString;
    assetId: string;
};

/**
 *
 * @typedef {Error} DSSHLSError
 * @since 16.0.0
 * @see https://github.bamtech.co/pages/dss-hls/documentation/source/api/error-codes.html#error-data-properties
 *
 */
export type DSSHLSError = {
    fatal?: boolean;
    code?: number | undefined | null;
    cause?: string | number;
    trace?: string;
    error?: TodoAny;
    message?: string;
    json?: string;
};

/**
 *
 * @typedef {Object} SeekData
 * @since 28.0.0
 * @property {Number} [seekSize] - Since `28.0.0`.
 * @property {SeekDirection} [seekDirection] - Since `28.0.0`.
 * @property {PlaybackSeekCause} [seekCause] - Since `28.0.0`.
 * @property {Number} [seekDistance] - Since `28.0.0`.
 * @property {Number} [distance] - Since `28.0.0`.
 *
 */
export type SeekData = {
    seekSize?: number;
    seekDirection?: SeekDirection;
    seekCause?: PlaybackSeekCause;
    seekDistance?: number;
    distance?: number;
    skipType?: SkipType;
};

/**
 *
 * @access private
 *
 */
export const SeekDataTypedef = {
    seekSize: Types.number,
    seekDirection: Types.in(SeekDirection).optional,
    seekCause: Types.in(PlaybackSeekCause).optional,
    seekDistance: Types.number.optional,
    distance: Types.number.optional,
    skipType: Types.in(SkipType).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.TimeUpdateEventData
 * @since 27.1.2
 * @desc Provides details about the `TimeUpdateEventData` event data.
 * @property {Number} currentTime - The current position on the media timeline in seconds.
 * @property {Number} currentPDT - The current presentation date time in milliseconds since the epoch
 * @property {Number} mainContentTime - The main content current time in seconds.
 * @property {Number} mainContentPDT - The main content current program date time in milliseconds.
 *
 * @see https://github.bamtech.co/pages/vpe-media-extension-library/documentation/source/api/events.html#timeupdateevent
 *
 */
export type TimeUpdateEventData = {
    currentTime: number;
    currentPDT: number;
    mainContentTime: number;
    mainContentPDT: number;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.ViewingEnvironmentChangedEvent
 * @since 28.0.0
 * @desc Provides details about the onViewingEnvironmentChanged event.
 * @property {String} viewingEnvironment - Since `28.0.0` - Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
 *
 */
export type ViewingEnvironmentChangedEvent = {
    viewingEnvironment: string;
};

/**
 *
 * @access private
 *
 */
export const ViewingEnvironmentChangedEventTypedef = {
    viewingEnvironment: Types.nonEmptyString
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.InterstitialPlaybackStartedEvent
 * @since 28.1.0
 * @desc Provides details about the onInterstitialPlaybackStarted event.
 * @property {InterstitialType} interstitialType - Since `28.1.0` - Type of interstitial.
 * @property {InterstitialPlacement} interstitialPlacement - Since `28.1.0` - Placement information.
 * @property {String} interstitialId - Since `28.1.0` - Interstitial ID (UUID).
 * @property {Number} interstitialPlannedLength - Since `28.1.0` - Reported duration of the interstitial in milliseconds.
 * @property {Number} interstitialPlayhead - Since `28.1.0` - Playhead position, measured as a millisecond offset from the beginning of the interstitial, or 0 if not available.
 *
 */
export type InterstitialPlaybackStartedEvent = {
    interstitialType: InterstitialType;
    interstitialPlacement: InterstitialPlacement;
    interstitialId: string;
    interstitialPlannedLength: number;
    interstitialPlayhead: number;
};

/**
 *
 * @access private
 *
 */
export const InterstitialPlaybackStartedEventTypedef = {
    interstitialType: Types.in(InterstitialType),
    interstitialPlacement: Types.in(InterstitialPlacement),
    interstitialId: Types.nonEmptyString,
    interstitialPlannedLength: Types.number,
    interstitialPlayhead: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.InterstitialPlaybackEndedEvent
 * @since 28.1.0
 * @desc Provides details about the onInterstitialPlaybackEnded event.
 * @property {InterstitialType} interstitialType - Since `28.1.0` - Type of interstitial.
 * @property {InterstitialPlacement} interstitialPlacement - Since `28.1.0` - Placement information.
 * @property {String} interstitialId - Since `28.1.0` - Interstitial ID (UUID).
 * @property {Number} interstitialPlannedLength - Since `28.1.0` - Reported duration of the interstitial in milliseconds.
 * @property {Number} interstitialPlayhead - Since `28.1.0` - Playhead position, measured as a millisecond offset from the beginning of the interstitial, or 0 if not available.
 * @property {String<PlaybackExitedCause>} interstitialPlayhead - Since `28.1.0` - The reason for ending interstitial playback. Required if `interstitialActivity` is `interstitialEnded`.
 * @property {AdErrorData|undefined} [errorData] - Since `28.1.0` - errors if one occurs while the interstitial is playing.
 *
 */
export type InterstitialPlaybackEndedEvent = {
    interstitialType: InterstitialType;
    interstitialPlacement: InterstitialPlacement;
    interstitialId: string;
    interstitialPlannedLength: number;
    interstitialPlayhead: number;
    cause: InterstitialEndedCause;
    errorData?: AdErrorData;
};

/**
 *
 * @access private
 *
 */
export const InterstitialPlaybackEndedEventTypedef = {
    interstitialType: Types.in(InterstitialType),
    interstitialPlacement: Types.in(InterstitialPlacement),
    interstitialId: Types.nonEmptyString,
    interstitialPlannedLength: Types.number,
    interstitialPlayhead: Types.number,
    cause: Types.in(PlaybackExitedCause),
    errorData: Types.object(AdErrorDataTypedef).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.MultivariantPlaylistFallbackEvent
 * @since 28.1.0
 * @desc Provides details about the `onMultivariantPlaylistFallback` event.
 * @note SDKs are expected to update the following cached values when CDN fallback occurs, and report them on subsequent
 * PQoE events when required: `cdnName`, `cdnVendor`, `cdnWithOrigin`, `cdnRequestedTrail`, `cdnFailedTrail`, `cdnFallbackCount`.
 * @property {String} cdnName - Since `28.1.0` - The CDN running the server.
 * @property {String} cdnVendor - Since `28.1.0` - The vendor of the CDN. e.g. VERIZON.
 * @property {String} cdnWithOrigin - Since `28.1.0` - Name of the CDN, appended with Origin e.g. LEVEL3-SECURE.
 * @property {QoePlaybackError|null} [error] - Since `28.1.0` - The error describing the failure. Null if no error occurred.
 * @property {String} [errorDetail] - Since `28.1.0` - Supporting error text for `error` property where this can be provided for additional context.
 *
 */
export type MultivariantPlaylistFallbackEvent = {
    cdnName: string;
    cdnVendor: string;
    cdnWithOrigin: string;
    error?: Nullable<QoePlaybackError>;
    errorDetail?: string;
};

/**
 *
 * @access private
 *
 */
export const MultivariantPlaylistFallbackEventTypedef = {
    cdnName: Types.nonEmptyString,
    cdnVendor: Types.nonEmptyString,
    cdnWithOrigin: Types.nonEmptyString,
    error: Types.in(QoePlaybackError).optional,
    errorDetail: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackCustomEvent
 * @since 28.2.0
 * @desc Provides additional diagnostic information from the player intended to be included in a PQoE `playbackCustom` event.
 * @note This event may not be implemented by all platforms.
 * @property {String} playbackDiagnostics - Since `28.2.0` - The CDN running the server.
 *
 */
export type PlaybackCustomEvent = {
    playbackDiagnostics: string;
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackRightsTracking
 * @since 28.3.0
 * @desc Used for identifying the playback session for playback rights.
 * @property {String} [playbackSessionId] - Since `28.3.0` - The playback session identifier. For PROGRAM_BOUNDARY checks, this will be the playbackSessionId used for initiating playback of the linear stream.
 *
 */
export type PlaybackRightsTracking = {
    playbackSessionId?: string;
};

/**
 *
 * @access private
 *
 */
export const PlaybackRightsTrackingTypedef = {
    playbackSessionId: Types.nonEmptyString.optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackRightsCheck
 * @since 28.3.0
 * @desc Tracking for playback rights check.
 * @property {PlaybackRightsTracking} tracking - Since `28.3.0` - Contains information to identify the playback session for playback rights.
 * @property {PlaybackSelectionAdTracking} [adTracking] - Since `28.2.0` - Ads tracking information.
 *
 */
export type PlaybackRightsCheck = {
    tracking: PlaybackRightsTracking;
    adTracking?: PlaybackSelectionAdTracking;
};

/**
 *
 * @access private
 *
 */
export const PlaybackRightsCheckTypedef = {
    tracking: Types.object(PlaybackRightsTrackingTypedef),
    adTracking: Types.instanceStrict(PlaybackSelectionAdTracking).optional
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.VersionedId
 * @since 28.3.0
 * @desc The versioned ALID to check for playback rights.
 * @property {String} id - Since `28.3.0` - The EVA identifier for the ALID.
 * @property {Number} version - Since `28.3.0` - The EVA version identifier for the ALID.
 *
 */
export type VersionedId = {
    id: string;
    version: number;
};

/**
 *
 * @access private
 *
 */
export const VersionedIdTypedef = {
    id: Types.nonEmptyString,
    version: Types.number
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.Airing
 * @since 28.3.0
 * @desc The linear airing to check for playback rights.
 * @property {String} airingId - Since `28.3.0` - The identifier for the Airing on the Linear Channel.
 * @property {VersionedId} alid - Since `28.3.0` - The EVA version identifier for the ALID associated with the Airing.
 * @property {Array<VersionedId>} avails - Since `28.3.0` - The EVA avails associated with the Airing. This should normally be a single Avail, but there may be cases where multiple are present.
 *
 */
export type Airing = {
    airingId: string;
    alid: VersionedId;
    avails: Array<VersionedId>;
};

/**
 *
 * @access private
 *
 */
export const AiringTypedef = {
    airingId: Types.nonEmptyString,
    alid: Types.object(VersionedIdTypedef),
    avails: Types.array.of.object(VersionedIdTypedef)
};

/**
 *
 * @access public
 * @typedef {Object} SDK.Media.PlaybackRightsProgramBoundaryCheck
 * @since 28.3.0
 * @desc Information used for checking playback rights.
 * @property {Airing} airing - Since `28.3.0` - The linear airing to check for playback rights.
 * @property {PlaybackRightsCheck} playbackRights - Since `28.3.0` - Tracking information for the playback rights check.
 * @property {String} playbackId - Since `28.3.0` - This is an encoded object with information on the asset to playback. See PlaybackResourceBlock for structure of the decoded object.
 *
 */
export type PlaybackRightsProgramBoundaryCheck = {
    airing: Airing;
    playbackRights: PlaybackRightsCheck;
    playbackId: string;
};

/**
 *
 * @access private
 *
 */
export const PlaybackRightsProgramBoundaryCheckTypedef = {
    airing: Types.object(AiringTypedef),
    playbackRights: Types.object(PlaybackRightsCheckTypedef),
    playbackId: Types.nonEmptyString
};

/**
 *
 * @access private
 *
 */
export const PlaybackCustomEventTypedef = {
    playbackDiagnostics: Types.nonEmptyString
};
