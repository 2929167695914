/**
 *
 * @module playbackMetrics
 *
 */

import { Types, antiCorrupt } from '../services/util/antiCorruption';

import { PlaybackState } from '../services/qualityOfService/enums';

interface PlaybackMetricsOptions {
    currentBitrate?: Nullable<number>;
    currentPlayhead?: number;
    currentThroughput?: Nullable<number>;
    currentBitrateAvg?: Nullable<number>;
    currentBitratePeak?: Nullable<number>;
    playheadProgramDateTime?: Nullable<number>;
    seekableRangeEndProgramDateTime?: Nullable<number>;
    isLiveEdge?: Nullable<boolean>;
    bufferSegmentDuration?: number;
    mediaBytesDownloaded?: Nullable<number>;
    mediaDownloadTotalTime?: Nullable<number>;
    mediaDownloadTotalCount?: Nullable<number>;
    playbackState?: Nullable<PlaybackState>;
    segmentPosition?: Nullable<number>;
    liveLatencyAmount?: number;
    playbackDuration?: Nullable<number>;
    maxAllowedVideoBitrate?: Nullable<number>;
    adMetadata?: TodoAny;
}

/**
 *
 * @desc Contains a snapshot of information about media playback.
 * @since 2.0.0
 *
 */
export default class PlaybackMetrics {
    /**
     *
     * @access public
     * @type {Number|null} Greater than 0.
     * @desc Gets or sets the current bitrate in Kbps.
     * @todo look into the possibility of storing this value in bps.
     *
     */
    public currentBitrate?: Nullable<number>;

    /**
     *
     * @access public
     * @type {Number} Equal or greater than 0.
     * @desc The current playhead of the main content.
     * @note value is in milliseconds.
     * @note the assigned is different here because 0 is a valid value when currentPlayhead is not null.
     *
     */
    public currentPlayhead?: number;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {Number|null} Greater than 0.
     * @desc The current user throughput reported, if sent by the application (measure in bits per second),
     * regardless of the presentationType.
     *
     */
    public currentThroughput?: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Number|null} Greater than 0.
     * @desc Gets or sets the current bitrate average (in bps).
     *
     */
    public currentBitrateAvg?: Nullable<number>;

    /**
     *
     * @access public
     * @since 4.15.0
     * @type {Number|null} Greater than 0.
     * @desc Gets or sets the current bitrate peak (in bps).
     *
     */
    public currentBitratePeak?: Nullable<number>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {Number|null} Greater than 0.
     * @desc Gets or sets the current playhead, measured as a millisecond offset from the start time.
     *
     */
    public playheadProgramDateTime?: Nullable<number>;

    /**
     *
     * @access public
     * @since 10.0.0
     * @type {Number|null} Greater than 0.
     * @desc Value of the PROGRAM-DATE-TIME of the latest playlist segment + segment length in epoch millis
     * (i.e. end of last segment). Used to support live latency calculation.
     * @note Required if productType is 'Live'.
     *
     */
    public seekableRangeEndProgramDateTime?: Nullable<number>;

    /**
     *
     * @access public
     * @since 9.0.0
     * @type {Boolean|null}
     * @desc Indication that the content being played is at the live edge.
     * @note Required if productType is 'Live'.
     *
     */
    public isLiveEdge?: Nullable<boolean>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number}
     * @desc Amount of video currently fully buffered in milliseconds. Best version is accessed via a getter provided by logic player.
     * Much-better-than-nothing version is the last value provided in the TimeUpdated event (where the biggest problem it
     * has is not updating while the player is paused, but heartbeat is still reporting).
     * @note If this value is not available at the time the event is being sent, please pass 0.
     *
     */
    public bufferSegmentDuration?: number;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|null}
     * @desc Sum of all bytes downloaded for all the following resource types:
     * Manifests, video/audio segments, timed text (subtitle) segments or sidecar files, thumbnail images or I-frame segments.
     * The value should preferably represent bytes downloaded (not played), but if that data is not available it’s acceptable
     * to represent bytes played instead. If data about all listed resource types is not available, clients should use best effort to include
     * as many listed resource types as possible.
     * @note The SDK is responsible for calculating the bytes downloaded since the last heartbeat event.
     *
     */
    public mediaBytesDownloaded?: Nullable<number>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|undefined}
     * @desc Sum of time spent for all media bytes downloaded.
     * @note "All media" here means all media resources, mainly including video segments and audio segments.
     * @note The SDK is responsible for calculating the time spent downloading media bytes since the last heartbeat event.
     *
     */
    public mediaDownloadTotalTime?: Nullable<number>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|undefined}
     * @desc Number of all downloaded (including successfully and failed) video and audio segments.
     *
     */
    public mediaDownloadTotalCount?: Nullable<number>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {SDK.Services.QualityOfService.PlaybackState|null}
     * @desc The current `PlaybackState` of the player.
     *
     */
    public playbackState?: Nullable<PlaybackState>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|null}
     * @desc This should be the epoch time in milliseconds of the video.
     * @note Required if productType is 'Live'.
     *
     */
    public segmentPosition?: Nullable<number>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|null}
     * @desc Latency of the current playback position, in milliseconds, with the live head (live head - current position, >= 0).
     * @note Required if productType is 'Live'.
     *
     */
    public liveLatencyAmount?: number;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Number|null}
     * @desc Duration of playback.
     *
     */
    public playbackDuration?: Nullable<number>;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Number}
     * @desc The highest video bitrate (in bps) available for the currently selected presentationType in the
     * current DASH/HLS manifest that is allowed to play. The user may not reach this bitrate in their session.
     * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
     * resolution caps based on video window resolution/orientation.
     * @note The value should default to 0 if unavailable or unknown.
     * @note This value must be relevant to the current presentationType.
     *
     */
    public maxAllowedVideoBitrate?: Nullable<number>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Media.AdMetadata>}
     * @desc Metadata for the currently presented ad.
     * @note Required if the current `presentationType` is `ad` and `adInsertionType` != `none`.
     *
     */
    public adMetadata?: TodoAny;

    /**
     *
     * @param {Object} options
     * @param {Number} [options.currentBitrate=null]
     * @param {Number} [options.currentPlayhead=0]
     * @param {Number} [options.currentThroughput=null]
     * @param {Number} [options.currentBitrateAvg=null]
     * @param {Number} [options.currentBitratePeak=null]
     * @param {Number} [options.playheadProgramDateTime=null]
     * @param {Number} [options.seekableRangeEndProgramDateTime=null]
     * @param {Boolean} [options.isLiveEdge=null]
     * @param {Number} [options.bufferSegmentDuration=0]
     * @param {Number} [options.mediaBytesDownloaded=null]
     * @param {Number} [options.mediaDownloadTotalTime]
     * @param {Number} [options.mediaDownloadTotalCount]
     * @param {SDK.Services.QualityOfService.PlaybackState} [options.playbackState=null]
     * @param {Number} [options.segmentPosition=null]
     * @param {Number} [options.liveLatencyAmount=0]
     * @param {Number} [options.playbackDuration=null]
     * @param {Number} [options.maxAllowedVideoBitrate=0]
     * @param {Object<SDK.Media.AdMetadata>} [options.adMetadata]
     *
     */
    public constructor(options: PlaybackMetricsOptions) {
        const params = {
            currentBitrate: Types.integerGreaterOrEqual(0).default(null),
            currentPlayhead: Types.integerGreaterOrEqual(0).default(0),
            currentThroughput: Types.integerGreaterOrEqual(0).default(null),
            currentBitrateAvg: Types.integerGreaterOrEqual(0).default(null),
            currentBitratePeak: Types.integerGreaterOrEqual(0).default(null),
            playheadProgramDateTime:
                Types.integerGreaterOrEqual(0).default(null),
            seekableRangeEndProgramDateTime:
                Types.integerGreaterOrEqual(0).default(null),
            isLiveEdge: Types.boolean.default(null),
            bufferSegmentDuration: Types.integer.default(0),
            mediaBytesDownloaded: Types.number.default(null),
            mediaDownloadTotalTime: Types.number.default(undefined),
            mediaDownloadTotalCount: Types.number.default(undefined),
            playbackState: Types.in(PlaybackState).default(null),
            segmentPosition: Types.integer.default(null),
            liveLatencyAmount: Types.integer.default(0),
            playbackDuration: Types.integerGreaterOrEqual(0).default(null),
            maxAllowedVideoBitrate: Types.integerGreaterOrEqual(0).default(0),
            adMetadata: Types.object.default(undefined)
        };

        const parsedOptions: PlaybackMetricsOptions = antiCorrupt(
            this,
            options,
            params
        );

        const {
            currentBitrate,
            currentPlayhead,
            currentThroughput,
            currentBitrateAvg,
            currentBitratePeak,
            playheadProgramDateTime,
            seekableRangeEndProgramDateTime,
            isLiveEdge,
            bufferSegmentDuration,
            mediaBytesDownloaded,
            mediaDownloadTotalTime,
            mediaDownloadTotalCount,
            playbackState,
            segmentPosition,
            liveLatencyAmount,
            playbackDuration,
            maxAllowedVideoBitrate,
            adMetadata
        } = parsedOptions;

        this.currentBitrate = currentBitrate;
        this.currentPlayhead = currentPlayhead;
        this.currentThroughput = currentThroughput;
        this.currentBitrateAvg = currentBitrateAvg;
        this.currentBitratePeak = currentBitratePeak;
        this.playheadProgramDateTime = playheadProgramDateTime;
        this.seekableRangeEndProgramDateTime = seekableRangeEndProgramDateTime;
        this.isLiveEdge = isLiveEdge;
        this.bufferSegmentDuration = bufferSegmentDuration;
        this.mediaBytesDownloaded = mediaBytesDownloaded;
        this.mediaDownloadTotalTime = mediaDownloadTotalTime;
        this.mediaDownloadTotalCount = mediaDownloadTotalCount;
        this.playbackState = playbackState;
        this.segmentPosition = segmentPosition;
        this.liveLatencyAmount = liveLatencyAmount;
        this.playbackDuration = playbackDuration;
        this.maxAllowedVideoBitrate = maxAllowedVideoBitrate;
        this.adMetadata = adMetadata;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.PlaybackMetrics';
    }
}
