/**
 *
 * @module qrCodePlugin
 * @desc {SDK.QRCode.QRCodePlugin} entry point.
 *
 */

import QRCode from './entry';

import QRCodeClientConfiguration from '../services/qrCode/qrCodeClientConfiguration';
import QRCodeClient from '../services/qrCode/qrCodeClient';

import QRCodeApi from './qrCodeApi';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'QRCode', typeof QRCode> = {
    pluginName: 'QRCode',
    entry: QRCode,

    /**
     *
     * @access protected
     * @since 26.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.qrCodeApi = new QRCodeApi({
            qrCodeClient: new QRCodeClient({
                config: sdkSession.config.services.qrCode,
                logger
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 26.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.qrCode = new QRCodeClientConfiguration({
            baseUrl: services?.qrcode?.client?.baseUrl,
            endpoints: serviceDefinitionsConfiguration.processEndpoints(
                services?.qrcode?.client?.endpoints
            )
        });
    }
};

/**
 *
 * @access public
 * @since 26.0.0
 *
 */
export default plugin;
