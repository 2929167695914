/**
 *
 * @module orchestrationExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 9.0.0
 *
 */
export default class OrchestrationExtrasMap {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {Boolean}
     * @desc Gets or sets the `enableUpdateDeviceOS` value.
     *
     */
    public enableUpdateDeviceOS: boolean;

    /**
     *
     * @param {Object} [options={}]
     * @param {Boolean} [options.enableUpdateDeviceOS=false]
     *
     */
    public constructor(options?: { enableUpdateDeviceOS: boolean }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    enableUpdateDeviceOS: Types.boolean
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const { enableUpdateDeviceOS } = options || {};

        this.enableUpdateDeviceOS = enableUpdateDeviceOS || false;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Orchestration.OrchestrationExtrasMap';
    }
}
