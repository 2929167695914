/**
 *
 * @module tokenRefreshFailure
 *
 */

import ServiceException from '../services/exception/serviceException';

/**
 *
 * @class TokenRefreshFailure
 * @desc Data that accompanies the (internal) event raised when automatic token refresh fails.
 *
 */
export default class TokenRefreshFailure {
    /**
     *
     * @access public
     * @type {SDK.Services.ServiceException}
     *
     */
    public error: ServiceException;

    /**
     *
     * @access public
     * @since 6.1.0
     * @type {Boolean}
     * @note Expected to be true if the event is raised after calling `sdkSession.reauthorize()`, otherwise it
     * will default to `false`.
     *
     */
    public hasResetToAnonymousToken: boolean;

    /**
     *
     * @param {SDK.Services.ServiceException} error
     * @param {Boolean} [hasResetToAnonymousToken=false]
     *
     */
    public constructor(
        error: ServiceException,
        hasResetToAnonymousToken = false
    ) {
        this.error = error;

        this.hasResetToAnonymousToken = hasResetToAnonymousToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.TokenRefreshFailure';
    }
}
