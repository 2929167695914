/**
 *
 * @module offDeviceRedemptionFlowServiceMap
 *
 */

/**
 *
 * @since 4.18.0
 * @typedef {Object} SDK.Services.Orchestration.offDeviceRedemptionFlowServiceMap
 * @desc Hint about what flow was taken on the web side, i.e. user registration or user login.
 * @note allows us to map the service edge event enum, normalize it to match the same values in the orchestration spec
 *
 * @property {String} Register - Since `25.0.0`.
 * @property {String} Login - Since `25.0.0`.
 * @property {String} PlanSwitch - Since `25.0.0`.
 *
 */
enum offDeviceRedemptionFlowServiceMap {
    Register = 'REGISTER',
    Login = 'LOGIN',
    PlanSwitch = 'PLAN_SWITCH'
}

export default offDeviceRedemptionFlowServiceMap;
