/**
 *
 * @module productEntitlement
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Represents the entitlements associated with a product a user is subscribed to.
 *
 */
export default class ProductEntitlement {
    /**
     *
     * @access public
     * @type {Number}
     * @desc The id of the product entitlement.
     *
     */
    public id: number;

    /**
     *
     * @access public
     * @type {String}
     * @desc The name of the product entitlement.
     *
     */
    public name: string;

    /**
     *
     * @access public
     * @type {String|undefined}
     * @desc The description of the product entitlement.
     *
     */
    public description?: string;

    /**
     *
     * @access public
     * @since 4.1.1
     * @type {String|undefined}
     * @desc The partner this entitlement is related to
     *
     */
    public partner?: string;

    /**
     *
     * @param {Object} options
     * @param {Number} options.id
     * @param {String} options.name
     * @param {String} [options.desc]
     * @param {String} [options.partner]
     *
     */
    public constructor(options: {
        id: number;
        name: string;
        desc?: string;
        partner?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    desc: Types.nonEmptyString.optional,
                    id: Types.number,
                    name: Types.nonEmptyString,
                    partner: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { id, name, desc, partner } = options;

        this.id = id;
        this.name = name;
        this.description = desc;
        this.partner = partner;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Subscription.ProductEntitlement';
    }
}
