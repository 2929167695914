/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    BillingAddress,
    BillingAddressTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ComcastPaymentMethod
 * @since 9.0.0
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#extend-params
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} msoPartner - The MSO partner associated with the account.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface ComcastPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    usage: string;
    isShared?: boolean;
    billingAddress: BillingAddress;
    msoPartner: string;
}

/**
 *
 * @access private
 *
 */
export const ComcastPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    billingAddress: Types.object(BillingAddressTypedef),
    msoPartner: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ComcastPaymentRequest
 * @since 5.0.0
 * @property {String} oat - Obfuscated account token (from comcast).
 * @property {String} consentPresentedTime - Time of when consent was presented to the user, in milliseconds since the epoch.
 * @property {String} msoPartner - The MSO partner associated with the account (e.g cox or comcast).
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {String} [campaignCode] - Campaign Code use to calculate price of the order.
 * @property {String} [voucherCode] - Voucher Code use to calculate price of the order.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 * @property {Array<String>} skus - Array of sku values associated with the payment request.
 *
 */
export interface ComcastPaymentRequest {
    oat: string;
    consentPresentedTime: string;
    msoPartner: string;
    offerId?: string;
    campaignCode?: string;
    voucherCode?: string;
    redemptionCode?: string;
    skus: Array<string>;
}

/**
 *
 * @access private
 *
 */
export const ComcastPaymentRequestTypedef = {
    oat: Types.nonEmptyString,
    consentPresentedTime: Types.nonEmptyString,
    msoPartner: Types.nonEmptyString,
    offerId: Types.nonEmptyString.optional,
    campaignCode: Types.nonEmptyString.optional,
    voucherCode: Types.nonEmptyString.optional,
    redemptionCode: Types.nonEmptyString.optional,
    skus: Types.array.of.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ComcastUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} consentToken - Consent token provided by comcast.
 * @property {String} msoPartner - The MSO partner associated with the account.
 * @property {Object<SDK.Commerce.BillingAddress>} [billingAddress] - Object that contains the users country code.
 *
 */
export interface ComcastUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    consentToken: string;
    msoPartner: string;
    billingAddress?: BillingAddress;
}

/**
 *
 * @access private
 *
 */
export const ComcastUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    consentToken: Types.nonEmptyString,
    msoPartner: Types.nonEmptyString,
    billingAddress: Types.object(BillingAddressTypedef).optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.CreateComcastPaymentMethodRequest
 * @since 10.1.0
 * @property {String} consentToken - Consent token provided by comcast.
 * @property {String} msoPartner - The MSO partner associated with the account.
 * @property {String} obfuscatedAccountToken - Obfuscated account token.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 *
 */
export interface CreateComcastPaymentMethodRequest {
    consentToken: string;
    msoPartner: string;
    obfuscatedAccountToken: string;
    billingAddress: BillingAddress;
}

/**
 *
 * @access private
 *
 */
export const CreateComcastPaymentMethodRequestTypedef = {
    consentToken: Types.nonEmptyString,
    msoPartner: Types.nonEmptyString,
    obfuscatedAccountToken: Types.nonEmptyString,
    billingAddress: Types.object(BillingAddressTypedef)
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ComcastConsentRequest
 * @since 10.1.0
 * @property {String} [campaignCode] - Campaign Code use to calculate price of the order.
 * @property {String} [consentPresentedTime] - Time of when consent was presented to the user, in milliseconds since the epoch. Defaults to current time.
 * @property {String} msoPartner - The MSO partner associated with the account (ex: `comcast`).
 * @property {String} oat - Obfuscated account token from comcast.
 * @property {String} [offerId] - Identifier to apply applicable offer.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 * @property {String} [requestedTime] - Time of when consent was requested from the Commerce platform, in milliseconds since the epoch. Defaults to current time.
 * @property {Array<String>} skus - Array of sku values associated with the payment request.
 * @property {String} [voucherCode] - Voucher Code use to calculate price of the order.
 *
 */
export interface ComcastConsentRequest {
    campaignCode?: string;
    consentPresentedTime?: string;
    msoPartner: string;
    oat: string;
    offerId?: string;
    redemptionCode?: string;
    requestedTime?: string;
    skus: Array<string> | string;
    voucherCode?: string;
}

/**
 *
 * @access private
 *
 */
export const ComcastConsentRequestTypedef = {
    campaignCode: Types.nonEmptyString.optional,
    consentPresentedTime: Types.nonEmptyString.optional,
    msoPartner: Types.nonEmptyString,
    oat: Types.nonEmptyString,
    offerId: Types.nonEmptyString.optional,
    redemptionCode: Types.nonEmptyString.optional,
    requestedTime: Types.nonEmptyString.optional,
    skus: Types.array.of.nonEmptyString,
    voucherCode: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ProductDetail
 * @since 10.1.0
 * @property {String} chargeAmount - The maximum chargeable amount for any given transaction given this consent.
 * @property {String} chargeCategory - Charge category (ex: `PaidSub`).
 * @property {String} description - Description of the product.
 * @property {String} [endDate] - Time of when the product entitlement period ends. (optional when `chargeCategory` = `1T`).
 * @property {String} [frequency] - The product frequency specified in ISO 8601 format. (ex: `P1M`).
 * @property {String} productCategory - Product category. (ex: `vidStreamSub`).
 * @property {String} productPrice - The full/recurring price of the product.
 * @property {String} sku - The Partner-defined SKU of the product.
 * @property {String} startDate - Time of when the product entitlement period begins.
 *
 */
export interface ProductDetail {
    chargeAmount: string;
    chargeCategory: string;
    description: string;
    endDate?: string;
    frequency?: string;
    productCategory: string;
    productPrice: string;
    sku: string;
    startDate: string;
}

/**
 *
 * @access private
 *
 */
export const ProductDetailTypedef = {
    chargeAmount: Types.nonEmptyString,
    chargeCategory: Types.nonEmptyString,
    description: Types.nonEmptyString,
    endDate: Types.nonEmptyString.optional,
    frequency: Types.nonEmptyString.optional,
    productCategory: Types.nonEmptyString,
    productPrice: Types.nonEmptyString,
    sku: Types.nonEmptyString,
    startDate: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Comcast.ComcastConsentResponse
 * @since 10.1.0
 * @property {String} appId - Commerce defined app ID provided to OTT partners (ex: `disneyPlus` or `ESPNPlus`).
 * @property {String} consentPresentedTime - Time of when consent was presented to the user, in milliseconds since.
 * @property {String} currency - Three letter currency codes specified in ISO 4217 (ex: `USD`).
 * @property {String} msoPartner - The MSO partner associated with the account (ex: `comcast`).
 * @property {String} oat - Obfuscated Account Token.
 * @property {Array<Object<SDK.Commerce.Comcast.ProductDetail>>} productDetails - Array of product information associated with the purchase.
 * @property {String} requestedTime - Time of when consent was requested from the Commerce platform, in milliseconds since the epoch.
 * @property {String} totalChargeAmount - The total amount charged. The sum of all `chargeAmount` values in the `productDetails` array.
 *
 */
export interface ComcastConsentResponse {
    appId: string;
    consentPresentedTime: string;
    currency: string;
    msoPartner: string;
    oat: string;
    productDetails: Array<ProductDetail>;
    requestedTime: string;
    totalChargeAmount: string;
}

/**
 *
 * @access private
 *
 */
export const ComcastConsentResponseTypedef = {
    appId: Types.nonEmptyString,
    consentPresentedTime: Types.nonEmptyString,
    currency: Types.nonEmptyString,
    msoPartner: Types.nonEmptyString,
    oat: Types.nonEmptyString,
    productDetails: Types.array.of.object(ProductDetailTypedef),
    requestedTime: Types.nonEmptyString,
    totalChargeAmount: Types.nonEmptyString
};
