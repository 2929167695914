/**
 *
 * @module bifThumbnailSet
 *
 */

import ThumbnailSet from './thumbnailSet';

export default class BifThumbnailSet extends ThumbnailSet {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Media.BifThumbnailSet';
    }
}
