/**
 *
 * @module customerServiceClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/customer-service-code.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import CustomerServiceClientConfiguration from './customerServiceClientConfiguration';
import CustomerServiceClientEndpoint from './customerServiceClientEndpoint';
import AccessToken from '../token/accessToken';
import LogTransaction from '../../logging/logTransaction';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const CustomerServiceClientDustUrnReference =
    DustUrnReference.services.customerService.customerServiceClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access customer services.
 *
 */
export default class CustomerServiceClient extends ClientBase<CustomerServiceClientConfiguration> {
    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.CustomerService.CustomerServiceClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: CustomerServiceClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(CustomerServiceClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Creates a customer service code for this user for use in support.
     * @returns {Promise<String>}
     *
     */
    public async createSupportCode(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken)
            };

            typecheck(this, 'createSupportCode', params, arguments);
        }

        const { logger } = this;

        const endpointKey = CustomerServiceClientEndpoint.createSupportCode;

        const payload = this.getPayload({
            accessToken,
            endpointKey
        });

        logger.info(
            this.toString(),
            'Requesting customer service support code.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: CustomerServiceClientDustUrnReference.createSupportCode,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                return String(response.data);
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - the access token
     * @param {SDK.Services.Account.AccountClientEndpoint} options.rel - endpoint to be referenced.
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @param {Object} [options.body] - body to be serialized and passed with the request.
     * @returns {GetPayloadResult} The payload for the client request.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: CustomerServiceClientEndpoint;
        body?: object;
    }) {
        const { accessToken, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey];

        const { href, headers, method } = endpoint as IEndpoint;

        const requestBody = body ? JSON.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        return {
            url: href,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.CustomerService.CustomerServiceClient';
    }
}
