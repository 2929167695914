/**
 *
 * @module telemetryClientConfiguration
 *
 */

import ServiceClientConfiguration from '../../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the TelemetryClient
 *
 */
export default class TelemetryClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.TelemetryClientConfiguration';
    }
}
