/**
 *
 * @module serviceDefinitionsConfiguration
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import * as SdkPlugins from '../../sdkPlugins';

import OrchestrationManagerConfiguration from './orchestrationManagerConfiguration';
import OrchestrationClientConfiguration from '../orchestration/orchestrationClientConfiguration';
import OrchestrationExtrasMap from '../orchestration/orchestrationExtrasMap';
import SubscriptionClientConfiguration from '../subscription/subscriptionClientConfiguration';
import TelemetryClientConfiguration from '../internal/telemetry/telemetryClientConfiguration';
import TelemetryManagerConfiguration from './telemetryManagerConfiguration';
import TelemetryManagerExtrasMap from './telemetryManagerExtrasMap';
import TokenClientConfiguration from '../token/tokenClientConfiguration';
import TokenClientExtrasMap from '../token/tokenClientExtrasMap';
import TokenManagerConfiguration from './tokenManagerConfiguration';
import TokenManagerExtrasMap from './tokenManagerExtrasMap';
import TelemetryBufferConfiguration from './telemetryBufferConfiguration';

import RetryPolicy from './retryPolicy';
import ServiceEndpoint from './serviceEndpoint';
import type AccountClientConfiguration from '../account/accountClientConfiguration';
import type AdEngineClientConfiguration from '../media/adEngine/adEngineClientConfiguration';
import type ContentClientConfiguration from '../content/contentClientConfiguration';
import type CommerceClientConfiguration from '../commerce/commerceClientConfiguration';
import type CustomerServiceManagerConfiguration from './customerServiceManagerConfiguration';
import type DrmClientConfiguration from '../drm/drmClientConfiguration';
import type EligibilityClientConfiguration from '../eligibility/eligibilityClientConfiguration';
import type EntitlementClientConfiguration from '../entitlement/entitlementClientConfiguration';
import type ExploreClientConfiguration from '../explore/exploreClientConfiguration';
import type InvoiceClientConfiguration from '../invoice/invoiceClientConfiguration';
import type MediaManagerConfiguration from './mediaManagerConfiguration';
import type PaywallClientConfiguration from '../paywall/paywallClientConfiguration';
import type ExternalActivationClientConfiguration from '../externalActivation/externalActivationClientConfiguration';
import type RipcutClientConfiguration from '../ripcut/ripcutClientConfiguration';
import type SocketManagerConfiguration from './socketManagerConfiguration';
import type OrchestrationClientEndpoint from '../orchestration/orchestrationClientEndpoint';
import type QRCodeClientConfiguration from '../qrCode/qrCodeClientConfiguration';

import { IPluginTypes } from '../../IPlugin';
import { IEndpoint, IEndpoints } from '../providers/typedefs';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary to communicate with services.
 *
 */
export default class ServiceDefinitionsConfiguration {
    /**
     *
     * @access private
     * @type {Object}
     *
     */
    private commonHeaders: object;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {TodoAny}
     *
     */
    public commonValues: TodoAny;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Account.AccountClientConfiguration}
     * @desc Gets or sets the configuration information necessary for account.
     *
     */
    public account: AccountClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Media.AdEngine.AdEngineClientConfiguration}
     * @desc Gets or sets the configuration information necessary for adEngine.
     *
     */
    public adEngine: AdEngineClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Commerce.CommerceClientConfiguration}
     * @desc Gets or sets the configuration information necessary for commerce.
     *
     */
    public commerce: CommerceClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Content.ContentClientConfiguration}
     * @desc Gets or sets the configuration information necessary for content.
     *
     */
    public content: ContentClientConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.CustomerServiceManagerConfiguration}
     * @desc Gets or sets the configuration information necessary for managing customer service.
     *
     */
    public customerService: CustomerServiceManagerConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Drm.DrmClientConfiguration}
     * @desc Gets or sets the configuration information necessary for Drm capabilities.
     *
     */
    public drm: DrmClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Eligibility.EligibilityClientConfiguration}
     * @desc Gets or sets the configuration information necessary for eligibility.
     *
     */
    public eligibility: EligibilityClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Entitlement.EntitlementClientConfiguration}
     * @desc Gets or sets the configuration information necessary for entitlement.
     *
     */
    public entitlement: EntitlementClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.ExternalActivation.ExternalActivationClientConfiguration}
     *
     */
    public externalActivation: ExternalActivationClientConfiguration;

    /**
     *
     * @access public
     * @since 23.1.0
     * @type {SDK.Services.Explore.ExploreClientConfiguration}
     * @desc Gets or sets the configuration information necessary for explore.
     *
     */
    public explore: ExploreClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Invoice.InvoiceClientConfiguration}
     * @desc Gets or sets the configuration information necessary for invoice.
     *
     */
    public invoice: InvoiceClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Subscription.SubscriptionClientConfiguration}
     * @desc Gets or sets the configuration information necessary for subscriptions.
     *
     */
    public subscription: SubscriptionClientConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.TokenManagerConfiguration}
     * @desc Gets or sets the configuration information necessary for managing token exchange.
     *
     */
    public token: TokenManagerConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.MediaManagerConfiguration}
     * @desc Gets or sets the configuration information necessary for managing media.
     *
     */
    public media: MediaManagerConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.TelemetryManagerConfiguration}
     * @desc Gets or sets the configuration information necessary for managing telemetry.
     *
     */
    public telemetry: TelemetryManagerConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.OrchestrationManagerConfiguration}
     * @desc Gets or sets the configuration information necessary for managing orchestration.
     *
     */
    public orchestration: OrchestrationManagerConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Paywall.PaywallClientConfiguration}
     * @desc Gets or sets the configuration information necessary for managing paywall.
     *
     */
    public paywall: PaywallClientConfiguration;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.SocketManagerConfiguration}
     *
     */
    public socket: SocketManagerConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.Ripcut.RipcutClientConfiguration}
     * @desc Gets or sets the configuration information necessary for Ripcut.
     *
     */
    public ripcut: RipcutClientConfiguration;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.QRCode.QRCodeClientConfiguration}
     * @desc Gets or sets the configuration information necessary for qrCode.
     *
     */
    public qrCode: QRCodeClientConfiguration;

    /**
     * @access private
     * @since 23.0.0
     * @type {Object}
     * @desc JSON services config object returned from configuration service. Can be used for late-bound plugins.
     *
     */
    private services: Record<string, unknown>;

    /**
     *
     * @param {Object} options
     * @param {Object} options.services - JSON services config object returned from configuration service.
     * @param {Object} [options.commonHeaders={}]
     * @param {Object} [options.commonValues={}]
     *
     */
    public constructor(options: {
        services: Record<string, unknown>;
        commonHeaders?: object;
        commonValues?: object;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    services: Types.nonEmptyObject,
                    commonHeaders: Types.object().optional,
                    commonValues: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { services, commonHeaders = {}, commonValues = {} } = options;

        this.commonHeaders = commonHeaders || {};
        this.commonValues = commonValues || {};
        this.account = {} as AccountClientConfiguration;
        this.adEngine = {} as AdEngineClientConfiguration;
        this.commerce = {} as CommerceClientConfiguration;
        this.content = {} as ContentClientConfiguration;
        this.customerService = {} as CustomerServiceManagerConfiguration;
        this.drm = {} as DrmClientConfiguration;
        this.eligibility = {} as EligibilityClientConfiguration;
        this.entitlement = {} as EntitlementClientConfiguration;
        this.explore = {} as ExploreClientConfiguration;
        this.invoice = {} as InvoiceClientConfiguration;
        this.subscription = {} as SubscriptionClientConfiguration;
        this.token = {} as TokenManagerConfiguration;
        this.media = {} as MediaManagerConfiguration;
        this.telemetry = {} as TelemetryManagerConfiguration;
        this.orchestration = {} as OrchestrationManagerConfiguration;
        this.paywall = {} as PaywallClientConfiguration;
        this.externalActivation = {} as ExternalActivationClientConfiguration;
        this.socket = {} as SocketManagerConfiguration;
        this.ripcut = {} as RipcutClientConfiguration;
        this.qrCode = {} as QRCodeClientConfiguration;

        this.services = services;

        this.processServices(services);
    }

    /**
     *
     * @access private
     * @param {Object} services - JSON services config object returned from configuration service.
     * @desc Initialize SdkSessionConfiguration services.
     *
     */
    private processServices(services: TodoAny) {
        const { subscription, token, telemetry, orchestration } = services;

        const { client: tokenClient, extras: tokenExtras } = token;
        const { endpoints: tokenClientEndpoints, extras: tokenClientExtras } =
            tokenClient;

        const {
            refreshThreshold,
            subjectTokenTypes,
            disableTokenRefresh,
            autoRefreshRetryPolicy: tokenAutoRefreshRetryPolicy
        } = tokenExtras;

        const { extras: telemetryExtras } = telemetry;

        const autoRefreshRetryPolicy = new RetryPolicy(
            tokenAutoRefreshRetryPolicy
        );

        /**
         *
         * @type {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration}
         *
         */
        const telemetryClientConfiguration = new TelemetryClientConfiguration({
            baseUrl: telemetry?.client?.baseUrl,
            endpoints: this.processEndpoints(telemetry?.client?.endpoints)
        });

        /**
         *
         * @type {SDK.Services.Token.TokenClientConfiguration}
         *
         */
        const tokenClientConfiguration = new TokenClientConfiguration({
            baseUrl: tokenClient.baseUrl,
            endpoints: this.processEndpoints(tokenClientEndpoints),
            extras: new TokenClientExtrasMap(tokenClientExtras)
        });

        /**
         *
         * @type {SDK.Services.Orchestration.OrchestrationClientConfiguration}
         *
         */
        const orchestrationClientConfiguration =
            new OrchestrationClientConfiguration({
                baseUrl: orchestration?.client?.baseUrl,
                endpoints: this.processEndpoints(
                    orchestration.client.endpoints
                ) as IEndpoints<typeof OrchestrationClientEndpoint>
            });

        SdkPlugins.getPlugins().forEach((plugin) => {
            this.applyConfigToPlugin(plugin);
        });

        const eventBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.eventBufferConfiguration
        );
        const streamSampleBufferConfiguration =
            new TelemetryBufferConfiguration(
                telemetryExtras.streamSampleBufferConfiguration
            );
        const glimpseBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.glimpseBufferConfiguration
        );
        const qoeBufferConfiguration = new TelemetryBufferConfiguration(
            telemetryExtras.qoeBufferConfiguration
        );
        const dustEnvelopeBufferConfiguration =
            new TelemetryBufferConfiguration(
                telemetryExtras.dustEnvelopeBufferConfiguration
            );

        /**
         *
         * @type {SDK.Services.Subscription.SubscriptionClientConfiguration}
         *
         */
        this.subscription = new SubscriptionClientConfiguration({
            baseUrl: subscription?.client?.baseUrl,
            endpoints: this.processEndpoints(subscription?.client?.endpoints)
        });

        /**
         *
         * @type {SDK.Services.Configuration.TelemetryManagerConfiguration}
         *
         */
        this.telemetry = new TelemetryManagerConfiguration({
            client: telemetryClientConfiguration,
            extras: new TelemetryManagerExtrasMap({
                eventBufferConfiguration,
                streamSampleBufferConfiguration,
                glimpseBufferConfiguration,
                qoeBufferConfiguration,
                fastTrack: telemetryExtras.fastTrack,
                prohibited: telemetryExtras.prohibited,
                permitAppDustEvents: telemetryExtras.permitAppDustEvents,
                dustEnvelopeBufferConfiguration,
                isEventsAtEdgeEnabled: telemetryExtras.isEventsAtEdgeEnabled
            }),
            disabled: telemetry.disabled
        });

        /**
         *
         * @type {SDK.Services.Configuration.TokenManagerConfiguration}
         *
         */
        this.token = new TokenManagerConfiguration({
            client: tokenClientConfiguration,
            extras: new TokenManagerExtrasMap({
                refreshThreshold,
                autoRefreshRetryPolicy,
                subjectTokenTypes,
                disableTokenRefresh
            }),
            disabled: token.disabled
        });

        /**
         *
         * @type {SDK.Services.Configuration.OrchestrationManagerConfiguration}
         *
         */
        this.orchestration = new OrchestrationManagerConfiguration({
            client: orchestrationClientConfiguration,
            extras: new OrchestrationExtrasMap(orchestration.extras),
            disabled: orchestration.disabled
        });
    }

    /**
     *
     * @access protected
     * @param {Object} [endpoints={}]
     * @desc creates service endpoint instance for each item
     * @returns {Object}
     *
     */
    public processEndpoints(endpoints: IEndpoints<Record<string, never>> = {}) {
        const { commonHeaders } = this;

        const serviceEndpoints = {} as IEndpoints<Record<string, never>>;

        Object.keys(endpoints).forEach((key) => {
            const endpoint = endpoints[key] as IEndpoint;

            endpoint.headers = endpoint.headers
                ? Object.assign(endpoint.headers, commonHeaders)
                : commonHeaders;
            endpoint.rel = key;

            serviceEndpoints[endpoint.rel as string] = new ServiceEndpoint(
                endpoint
            ) as IEndpoint;
        });

        return serviceEndpoints;
    }

    /**
     *
     * @access public
     * @since 23.0.0
     * @param {IPluginTypes} plugin - Plugin to apply config to.
     *
     */
    public applyConfigToPlugin(plugin: IPluginTypes) {
        plugin.applyConfig?.(this, this.services);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceDefinitionsConfiguration';
    }
}
