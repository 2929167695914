/**
 *
 * @module productRule
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Represents the rules on a product a user is subscribed to.
 *
 */
export default class ProductRule {
    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Gets the description (optional).
     *
     */
    private description?: string;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Gets the ID (optional).
     *
     */
    private id?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.description]
     * @param {String} [options.id]
     *
     */
    public constructor(options: { description?: string; id?: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    description: Types.string.optional,
                    id: Types.string.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { description, id } = options;

        this.description = description;
        this.id = id;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.ProductRule';
    }
}
