/* eslint-disable max-classes-per-file */
import { NodeEnv } from '../typedefs';

export type DebugLog = {
    callDateTime?: number;
    eventData?: unknown;
    key?: string;
    nativeProp?: string;
    nativeFunction?: string;
    result?: unknown;
};

type Debugger = {
    debugLogs: Array<DebugLog>;
    debug: (debugLog: DebugLog) => void;
    getProxyHandler: () => ProxyHandler<Record<string, () => unknown>>;
    downloadDebugLogs: () => void;
    NativePlayerProxy():
        | (<T extends Class>(
              constructor: T
          ) => {
              new (...args: Array<ApprovedAny>): T;
          } & T)
        | (() => undefined);
    EventCapture(events: Record<string, string>):
        | (<T extends Class>(
              constructor: T
          ) => {
              new (...args: Array<ApprovedAny>): T;
          } & T)
        | (() => undefined);
    isEnabled: () => boolean;
};

// static class
export default {
    /**
     *
     * @access public
     * @since 22.0.0
     * @type {Array<Object>}
     * @desc Used to store player debug logs.
     *
     */
    debugLogs: [],

    /**
     *
     * @access private
     * @since 22.0.0
     * @param {Object} debugLog
     * @desc If logging isn't disabled, adds log to `this.debugLogs`.
     *
     *
     */
    debug(debugLog: DebugLog) {
        this.debugLogs.push({ ...debugLog, callDateTime: Date.now() });
    },

    /**
     *
     * @access private
     * @since 22.0.0
     * @returns {Function} Proxy Handler
     *
     */
    getProxyHandler() {
        if (process.env.NODE_ENV === NodeEnv.Test) {
            const debug = this.debug.bind(this);

            const ignoreList = [
                'toJSON',
                'on',
                'off',
                'eventHandlerKeys',
                'eventHandlerValues',
                'once',
                'xhrSetupCallback',
                'setupXhrCallback',
                'controller',
                'getClass'
            ];

            return {
                get(target, prop: string, receiver) {
                    const ignoreProp =
                        ignoreList.includes(prop) || prop.startsWith('_');
                    const result = Reflect.get(target, prop, receiver);

                    if (ignoreProp) {
                        return result;
                    }

                    // Calling functions
                    if (typeof target[prop] === 'function') {
                        return new Proxy(target[prop], {
                            apply(applyTarget, thisArg, args) {
                                const altResult = Reflect.apply(
                                    applyTarget,
                                    thisArg,
                                    args
                                );

                                debug({
                                    nativeFunction: prop,
                                    result: altResult
                                });

                                return altResult;
                            }
                        });
                    }

                    debug({
                        nativeProp: prop,
                        result
                    });

                    return result;
                },
                set(target, nativeProp: string, value) {
                    const ignoreProp =
                        ignoreList.includes(nativeProp) ||
                        nativeProp.startsWith('_');
                    const result = Reflect.set(target, nativeProp, value);

                    if (ignoreProp) {
                        return result;
                    }

                    debug({
                        nativeProp,
                        result: result ? value : undefined
                    });

                    return result;
                }
            } as ProxyHandler<Record<string, () => unknown>>;
        }

        return {
            get(target: object, prop: string, receiver: unknown) {
                return Reflect.get(target, prop, receiver);
            },
            set(target: object, nativeProp: string, value: unknown) {
                return Reflect.set(target, nativeProp, value);
            }
        };
    },

    /**
     *
     * @access public
     * @since 22.0.0
     * @desc Downloads `this.debugLogs` to a file named `logs.json`.
     *
     */
    downloadDebugLogs() {
        // let text = '[';
        // const chunkSize = 5000;
        // for (let i = 0; i < this.debugLogs.length; i += chunkSize) {
        //     text += JSON.stringify(
        //         this.debugLogs.slice(i, i + chunkSize)
        //     ).slice(1, -1);
        //     if (i + chunkSize < this.debugLogs.length) text += ',';
        // }
        // text += ']';
        // const blob = new Blob([text], { type: 'text/plain' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'logs.json';
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(url);
    },

    /**
     *
     * @access private
     * @since 22.0.0
     * @returns {Function} Decorator
     * @desc Returns decorator that proxies nativePlayer if `enableDebugLogs` is true.
     *
     */
    NativePlayerProxy() {
        if (this.isEnabled()) {
            const getProxyHandler = this.getProxyHandler.bind(this);

            return <T extends Class>(constructor: T) => {
                return class extends constructor {
                    public constructor(...args: Array<ApprovedAny>) {
                        const [params] = args;

                        super(params);

                        const {
                            nativePlayer: originalNativePlayer,
                            enableDebugLogs
                        } = params;

                        if (enableDebugLogs) {
                            this.nativePlayer = new Proxy(
                                originalNativePlayer,
                                getProxyHandler() as ProxyHandler<
                                    Record<string, () => unknown>
                                >
                            );
                        }
                    }

                    public nativePlayer;
                };
            };
        }

        return () => {
            return undefined;
        };
    },

    /**
     *
     * @access private
     * @since 22.0.0
     * @returns {Function} Decorator
     * @desc Returns decorator that captures events if `enableDebugLogs` is true.
     *
     */
    EventCapture(events: Record<string, string>) {
        if (this.isEnabled()) {
            const formatDebugLog = (key: string) => (eventData: unknown) =>
                this.debug({ key, eventData });

            return <T extends Class>(constructor: T) => {
                return class extends constructor {
                    public constructor(...args: Array<ApprovedAny>) {
                        const [params] = args;

                        super(params);

                        const {
                            nativePlayer: originalNativePlayer,
                            enableDebugLogs
                        } = params;

                        if (enableDebugLogs) {
                            Object.keys(events).forEach((event) => {
                                if (event) {
                                    originalNativePlayer.on(
                                        event,
                                        formatDebugLog(event)
                                    );
                                }
                            });
                        }
                    }

                    public nativePlayer: ApprovedAny;
                };
            };
        }

        return () => {
            return undefined;
        };
    },

    /**
     *
     * @access private
     * @since 28.0.0
     * @returns {Boolean}
     * @desc Returns true if `NODE_ENV` is `test` or `development`.
     *
     */
    isEnabled() {
        return (
            process.env.NODE_ENV === NodeEnv.Test ||
            process.env.NODE_ENV === NodeEnv.Development
        );
    }
} as Debugger;
