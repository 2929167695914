/**
 *
 * @module invoiceApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import InvoiceClient from '../services/invoice/invoiceClient';

import {
    Invoice,
    PaginatedInvoicesResponse
} from '../services/invoice/typedefs';

import { ApiOptions } from '../typedefs';

import type Logger from '../logging/logger';
import type AccessTokenProvider from '../token/accessTokenProvider';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';

const DustUrn = DustUrnReference.invoice.invoiceApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.12.0
 * @desc Provides ability to access invoice data
 *
 */
export default class InvoiceApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {InvoiceClient}
     *
     */
    private invoiceClient: InvoiceClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {InvoiceClient} options.invoiceClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        invoiceClient: InvoiceClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    invoiceClient: Types.instanceStrict(InvoiceClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { invoiceClient } = options;

        this.invoiceClient = invoiceClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} link - path used to get a specific invoice.
     * @desc Returns a specific invoice belonging to the given account
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Invoice.Invoice>>}
     *
     */
    public async getInvoice(link: string): Promise<Invoice>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            link: Types.nonEmptyString
        }
    })
    public async getInvoice(apiOptions: unknown) {
        const {
            logTransaction,
            args: [link]
        } = apiOptions as ApiOptions<[string]>;

        return this.invoiceClient.getInvoice({
            link,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access public
     * @since 4.12.0
     * @param {String} [pageToken] - token containing the information necessary to retrieve the next page of results.
     * @param {Number} [pageSize] - The maximum number of results to return in a single response.
     * @desc Returns a list of invoices for the given account, in date descending order
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Invoice.PaginatedInvoicesResponse>>}
     *
     */
    public async getPaginatedInvoices(
        pageToken?: string,
        pageSize?: number
    ): Promise<PaginatedInvoicesResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            pageToken: Types.nonEmptyString.optional,
            pageSize: Types.number.optional
        }
    })
    public async getPaginatedInvoices(apiOptions?: unknown) {
        const {
            logTransaction,
            args: [pageToken, pageSize]
        } = apiOptions as ApiOptions<[string?, number?]>;

        return this.invoiceClient.getPaginatedInvoices({
            pageToken,
            pageSize,
            accessToken: super.accessToken,
            logTransaction
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Invoice.InvoiceApi';
    }
}
