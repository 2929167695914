/**
 *
 * @module entry
 * @desc {SDK.Services.Internal} entry point
 *
 */

import Telemetry from './telemetry/entry';
import Dust from './dust/entry';

import TokenUpdater from '../tokenUpdater';
import PlatformProviders from '../providers/platformProviders';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Internal
 *
 * @property {Telemetry} Telemetry
 * @property {Dust} Dust
 *
 */
export default {
    Telemetry,
    Dust,
    TokenUpdater,
    PlatformProviders
};
