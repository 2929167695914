/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @access public
 * @since 5.0.0
 * @typedef {Object} SDK.Services.Subscription.BundleType
 * @desc Defines the different types of bundle.
 *
 * @property {String} noah - Since `5.0.0`.
 * @property {String} sash - Since `5.0.0`.
 *
 */
export enum BundleType {
    noah = 'NOAH',
    sash = 'SASH'
}

/**
 *
 * @enum
 * @access public
 * @since 4.7.0
 * @typedef {Object} SDK.Services.Subscription.SubscriptionCancellationReason
 * @desc Defines the different subscription cancellation reasons that are supported.
 *
 * @property {String} other - Since `4.7.0` - Fallback, catch all value.
 * @property {String} voluntaryCancel - Since `4.7.0` - The service response will return 'VOLUNTARY_CANCEL'.
 * @property {String} involuntaryCancel - Since `4.7.0` - The service response will return 'INVOLUNTARY_CANCEL'.
 * @property {String} unknownCancel - Since `4.7.0` - The service response will return 'UNKNOWN_CANCEL'.
 * @property {String} abuseRevocation - Since `4.7.0` - The service response will return 'ABUSE_REVOCATION'.
 * @property {String} planSwitched - Since `4.7.0` - The service response will return 'PLAN_SWITCHED'.
 *
 */
export enum SubscriptionCancellationReason {
    other = 'other',
    voluntaryCancel = 'VOLUNTARY_CANCEL',
    involuntaryCancel = 'INVOLUNTARY_CANCEL',
    unknownCancel = 'UNKNOWN_CANCEL',
    abuseRevocation = 'ABUSE_REVOCATION',
    planSwitched = 'PLAN_SWITCHED'
}

/**
 *
 * @enum
 * @access public
 * @since 4.7.0
 * @typedef {Object} SDK.Services.Subscription.SubscriptionCancellationStatus
 * @desc Defines the different subscription cancellation statuses that are supported.
 *
 * @property {String} other - Since `4.7.0` - Fallback, catch all value.
 * @property {String} grace - Since `4.7.0` - The service response will return 'GRACE'.
 * @property {String} billingHold - Since `4.7.0` - The service response will return 'BILLING_HOLD'.
 *
 */
export enum SubscriptionCancellationStatus {
    other = 'other',
    grace = 'GRACE',
    billingHold = 'BILLING_HOLD'
}

/**
 *
 * @enum
 * @access public
 * @typedef {Object} SubscriptionExpiryType
 *
 * @property {String} UNKNOWN
 * @property {String} EXPIRY
 * @property {String} NO_EXPIRY
 *
 */
export enum SubscriptionExpiryType {
    UNKNOWN = 'UNKNOWN',
    EXPIRY = 'EXPIRY',
    NO_EXPIRY = 'NO_EXPIRY'
}

/**
 *
 * @enum
 * @access public
 * @since 4.2.4
 * @typedef {Object} SDK.Services.Subscription.SubscriptionFreeTrialStatus
 * @desc Whether or not the subscription is in a free trial.
 * The status may be unknown if the client that created the subscription
 * cannot know for sure if the subscription is in a free trial or not.
 * For D2C subscriptions we should know with certainty if the subscription
 * is in free trial or not.
 * @note Closed enum, when dealing with a unrecognized value use unknown
 *
 * @property {String} unknown - Since `4.2.4` - The service response will return 'UNKNOWN'.
 * @property {String} active - Since `4.2.4` - The service response will return 'ACTIVE'.
 * @property {String} inactive - Since `4.2.4` - The service response will return 'INACTIVE'.
 *
 */
export enum SubscriptionFreeTrialStatus {
    unknown = 'UNKNOWN',
    active = 'ACTIVE',
    inactive = 'INACTIVE'
}

/**
 *
 * @enum
 * @access public
 * @access private
 * @typedef {Object} SDK.Services.Subscription.SubscriptionSourceSubType
 *
 * @property {String} GIFT_SUB - The subscription was initiated with a gift subscription.
 * @property {String} GIFT_CARD - The subscription was initiated with a gift card.
 * @property {String} PAID - The subscription was paid for directly.
 * @property {String} PROMO_REDEMPTION - The subscription was initiated with a promotion.
 * @property {String} COMP - The subscription was initiated by compensation.
 * @property {String} TEMP - The subscription is temporary.
 * @property {String} INITIAL - Since `4.2.3`.
 * @property {String} RECURRING - Since `4.9.0`.
 * @property {String} PLAN_SWITCHED - Since `4.9.0`.
 * @property {String} OTHER - The subscription source sub type was not present or is unrecognized.
 *
 */
export enum SubscriptionSourceSubType {
    GIFT_SUB = 'GIFT_SUB',
    GIFT_CARD = 'GIFT_CARD',
    PAID = 'PAID',
    PROMO_REDEMPTION = 'PROMO_REDEMPTION',
    COMP = 'COMP',
    TEMP = 'TEMP',
    INITIAL = 'INITIAL',
    RECURRING = 'RECURRING',
    PLAN_SWITCHED = 'PLAN_SWITCHED',
    OTHER = 'OTHER'
}

/**
 *
 * @enum
 * @access private
 * @typedef {Object} SDK.Services.Subscription.SubscriptionSourceType
 *
 * @property {String} D2C - Direct to consumer: the consumer bought the subscription directly from DSS.
 * @property {String} IAP - In-app purchase: the subscription was bought through a native store such as the Apple App Store or Google Play.
 * @property {String} CST - Customer service: the subscription was granted to the user by DSS customer service.
 * @property {String} EXT - Since `4.1.2` - The subscription was obtained via an external source, for instance a superbundle purchased from Hulu.
 * @property {String} MVPD - Since `7.0.0` - The subscription source is from a Multichannel Video Programming Distributor (MVPD).
 * @property {String} OPS - Since `4.9.0` - The subscription source is from a customer service tool.
 * @property {String} thirdParty - Since `28.2.0` - The subscription source is from a third party. Service returns "3P". Should also be serialized as the string "3P".
 *
 */
export enum SubscriptionSourceType {
    D2C = 'D2C',
    IAP = 'IAP',
    CST = 'CST',
    EXT = 'EXT',
    MVPD = 'MVPD',
    OPS = 'OPS',
    thirdParty = '3P'
}

/**
 *
 * @enum
 * @access public
 * @typedef {Object} SDK.Services.Subscription.SubscriptionStatusSubType
 *
 * @property {String} PENDING_VOLUNTARY_CANCEL
 * @property {String} PENDING_INVOLUNTARY_CANCEL
 * @property {String} VOLUNTARY_CANCEL
 * @property {String} INVOLUNTARY_CANCEL
 * @property {String} LAPSED
 *
 */
export enum SubscriptionStatusSubType {
    PENDING_VOLUNTARY_CANCEL = 'PENDING_VOLUNTARY_CANCEL',
    PENDING_INVOLUNTARY_CANCEL = 'PENDING_INVOLUNTARY_CANCEL',
    VOLUNTARY_CANCEL = 'VOLUNTARY_CANCEL',
    INVOLUNTARY_CANCEL = 'INVOLUNTARY_CANCEL',
    LAPSED = 'LAPSED'
}

/**
 *
 * @enum
 * @access public
 * @typedef {Object} SDK.Services.Subscription.SubscriptionStatusType
 *
 * @property {String} SUBSCRIBED
 * @property {String} UNSUBSCRIBED
 *
 */
export enum SubscriptionStatusType {
    SUBSCRIBED = 'SUBSCRIBED',
    UNSUBSCRIBED = 'UNSUBSCRIBED'
}

/**
 *
 * @enum
 * @access private
 * @typedef {Object} SDK.Services.Subscription.SubscriptionType
 *
 * @property {String} BAM_IDENTITY
 * @property {String} BEARER
 * @property {String} EXTERNAL_IDENTITY
 *
 */
export enum SubscriptionType {
    BAM_IDENTITY = 'BAM_IDENTITY',
    BEARER = 'BEARER',
    EXTERNAL_IDENTITY = 'EXTERNAL_IDENTITY'
}
