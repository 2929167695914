/**
 *
 * @module entry
 * @desc {SDK.Services.Configuration} entry point.
 *
 */

import BootstrapConfiguration from './bootstrapConfiguration';
import ConfigurationClientConfiguration from './configurationClientConfiguration';
import ConfigurationClientExtrasMap from './configurationClientExtrasMap';
import ConfigurationClientHostParams from './configurationClientHostParams';
import ConfigurationHostName from './configurationHostName';
import OrchestrationManagerConfiguration from './orchestrationManagerConfiguration';
import Platform from './platform';
import PlatformProfileReference from './platformProfileReference';
import SdkSessionConfiguration from './sdkSessionConfiguration';
import ServiceConfiguration from './serviceConfiguration';
import ServiceEnvironmentName from './serviceEnvironmentName';
import ServiceDefinitionsConfiguration from './serviceDefinitionsConfiguration';
import TelemetryManagerConfiguration from './telemetryManagerConfiguration';
import TelemetryManagerExtrasMap from './telemetryManagerExtrasMap';
import TokenManagerConfiguration from './tokenManagerConfiguration';
import TokenManagerExtrasMap from './tokenManagerExtrasMap';
import TelemetryBufferConfiguration from './telemetryBufferConfiguration';

import RetryPolicy from './retryPolicy';
import HttpMethod from './httpMethod';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Configuration
 *
 * @property {BootstrapConfiguration} BootstrapConfiguration
 * @property {ConfigurationClientConfiguration} ConfigurationClientConfiguration
 * @property {ConfigurationClientExtrasMap} ConfigurationClientExtrasMap
 * @property {ConfigurationClientHostParams} ConfigurationClientHostParams
 * @property {SDK.Services.Configuration.ConfigurationHostName} ConfigurationHostName
 * @property {OrchestrationManagerConfiguration} OrchestrationManagerConfiguration
 * @property {PlatformProfileReference} PlatformProfileReference
 * @property {SDK.Services.Configuration.Platform} Platform
 * @property {SdkSessionConfiguration} SdkSessionConfiguration
 * @property {ServiceConfiguration} ServiceConfiguration
 * @property {SDK.Services.Configuration.ServiceEnvironmentName} ServiceEnvironmentName
 * @property {ServiceDefinitionsConfiguration} ServiceDefinitionsConfiguration
 * @property {TelemetryManagerConfiguration} TelemetryManagerConfiguration
 * @property {TelemetryManagerExtrasMap} TelemetryManagerExtrasMap
 * @property {TokenManagerConfiguration} TokenManagerConfiguration
 * @property {TokenManagerExtrasMap} TokenManagerExtrasMap
 * @property {TelemetryBufferConfiguration} TelemetryBufferConfiguration
 *
 * @property {RetryPolicy} RetryPolicy
 * @property {SDK.Services.Configuration.HttpMethod} HttpMethod
 *
 */
export default {
    BootstrapConfiguration,
    ConfigurationClientConfiguration,
    ConfigurationClientExtrasMap,
    ConfigurationClientHostParams,
    ConfigurationHostName,
    OrchestrationManagerConfiguration,
    Platform,
    PlatformProfileReference,
    SdkSessionConfiguration,
    ServiceConfiguration,
    ServiceEnvironmentName,
    ServiceDefinitionsConfiguration,
    TelemetryManagerConfiguration,
    TelemetryManagerExtrasMap,
    TokenManagerConfiguration,
    TokenManagerExtrasMap,
    TelemetryBufferConfiguration,

    RetryPolicy,
    HttpMethod
};
