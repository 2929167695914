/**
 *
 * @module paywall
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import AccountEntitlementContext from './accountEntitlementContext';

import Assertion from './assertion';
import Product from './product';
import Reason from './reason';
import { CurrentSubscription } from './typedefs';

/**
 *
 * @since 3.9.0
 * @desc Contains sku, campaign and account context information for a given country, platform and account/device.
 *
 */
export default class Paywall {
    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String<SDK.Services.Paywall.AccountEntitlementContext>|String}
     * @desc Account contexts such as new, existing etc.
     *
     */
    public accountEntitlementContext: AccountEntitlementContext | string;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String}
     * @desc A hexadecimal encoded MD5 hash of country and account context, source string should be
     * context+country_code (i.e anonymous).
     *
     */
    public paywallHash: string;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {Array<SDK.Services.Paywall.Product>}
     * @desc The list of products represented by the paywall.
     *
     */
    public products: Array<Product>;

    /**
     *
     * @access public
     * @since 4.4.0
     * @type {Array<SDK.Services.Paywall.Assertion>}
     * @desc Items that a consumer needs to take action on (i.e. subscriber agreements)
     *
     */
    public assertions: Array<Assertion>;

    /**
     *
     * @access public
     * @since 4.13.0
     * @type {SDK.Services.Paywall.Reason}
     * @desc Supplemental reason for the returning SKU or reason for no returning SKU
     * @note When requesting early access SKUs, this value will represent the state of that SKU for the user.
     * @note When requesting non-early access SKUs, this value will be either `purchaseAllowed` or `platformUnavailable`.
     *
     */
    public reason: Reason;

    /**
     *
     * @access public
     * @since 11.0.0
     * @type {Object<SDK.Services.Paywall.CurrentSubscription>|undefined}
     * @desc User's current subscription.
     *
     */
    public currentSubscription?: typeof CurrentSubscription;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Paywall.AccountEntitlementContext>} options.accountEntitlementContext
     * @param {String} options.paywallHash
     * @param {Array<SDK.Services.Paywall.Product>} options.products
     * @param {Array<SDK.Services.Paywall.Assertion>} options.assertions
     * @param {SDK.Services.Paywall.Reason} options.reason
     * @param {Object<SDK.Services.Paywall.CurrentSubscription>} [options.currentSubscription]
     *
     */
    public constructor(options: {
        accountEntitlementContext: AccountEntitlementContext;
        paywallHash: string;
        products: Array<Product>;
        assertions: Array<Assertion>;
        reason: Reason;
        currentSubscription?: typeof CurrentSubscription;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accountEntitlementContext: Types.nonEmptyString,
                    paywallHash: Types.nonEmptyString,
                    products: Types.array.of.instanceStrict(Product),
                    assertions: Types.array.of.instanceStrict(Assertion),
                    reason: Types.nonEmptyString,
                    currentSubscription:
                        Types.object(CurrentSubscription).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            accountEntitlementContext,
            paywallHash,
            products,
            assertions,
            reason,
            currentSubscription
        } = options;

        this.accountEntitlementContext = accountEntitlementContext;

        this.paywallHash = paywallHash;

        this.products = products;

        this.assertions = assertions;

        this.reason =
            Object.values(Reason).find((p) => p === reason) || Reason.other;

        this.currentSubscription = currentSubscription;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.Paywall';
    }
}
