/**
 *
 * @module commerceClientConfiguration
 *
 */

import CommerceClientExtrasMap from './commerceClientExtrasMap';

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the CommerceClient.
 *
 */
export default class CommerceClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    CommerceClientExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Commerce.CommerceClientConfiguration';
    }
}
