/**
 *
 * @module refreshTokenDelegation
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md#delegation-grants
 *
 */

import DelegationToken from './delegationToken';

/**
 *
 * @access public
 * @since 4.9.0
 *
 */
export default class RefreshTokenDelegation extends DelegationToken {
    /**
     *
     * @access public
     * @type {String}
     * @since 4.9.0
     *
     */
    public refreshToken: string;

    /**
     *
     * @param {String} refreshToken
     *
     */
    public constructor(refreshToken: string) {
        const subject = refreshToken;
        const subjectType = 'urn:ietf:params:oauth:token-type:refresh_token';

        super(subject, subjectType);

        this.refreshToken = refreshToken;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.RefreshTokenDelegation';
    }
}
