/**
 *
 * @module gender
 *
 */

/**
 *
 * @deprecated Deprecated as of version `28.1.0`. This enum is no longer in use and will be removed in version `29.0.0`.
 * @enum
 * @typedef {Object} SDK.Account.Gender
 * @desc Specifies the gender assigned to the account.
 *
 * @property {String} female
 * @property {String} male
 * @property {String} unspecified
 *
 * @example
 * Gender.female // 'female'
 *
 */
enum Gender {
    female = 'female',
    male = 'male',
    unspecified = 'unspecified'
}

export default Gender;
