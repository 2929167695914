/**
 *
 * @module mediaThumbnailLinks
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import MediaThumbnailLink from './mediaThumbnailLink';

/**
 *
 *
 */
export default class MediaThumbnailLinks {
    /**
     *
     * @access public
     * @type {SDK.Services.Media.MediaThumbnailLink|null}
     * @desc Response from the playlist service used to fetch a thumbnail presentation.
     *
     */
    public bif: Nullable<MediaThumbnailLink>;

    /**
     *
     * @access public
     * @type {SDK.Services.Media.MediaThumbnailLink|null}
     * @desc Response from the playlist service used to fetch a thumbnail presentation.
     *
     */
    public spritesheet: Nullable<MediaThumbnailLink>;

    /**
     *
     * @param {Object} thumbnails
     * @note If the playlist service response contains a `thumbnails` object we will try to construct one or both of
     * the properties in this file. These properties will be used when attempting to fetch thumbnails.
     * @note both `spritesheet` and `bif` are optional
     *
     */
    public constructor(thumbnails: Record<string, TodoAny>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                thumbnails: Types.object()
            };

            typecheck(this, params, arguments);
        }

        const { bif, spritesheet } = thumbnails;

        this.bif = Check.assigned(bif) ? new MediaThumbnailLink(bif) : null;
        this.spritesheet = Check.assigned(spritesheet)
            ? new MediaThumbnailLink(spritesheet)
            : null;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaThumbnailLinks';
    }
}
