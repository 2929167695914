/**
 *
 * @module socketClientExtrasMap
 *
 */

/**
 *
 * @access protected
 *
 */
export default class SocketClientExtrasMap {
    /**
     *
     * @access public
     * @type {Object}
     * @desc contains a set of protocolHeaders
     *
     */
    public protocolHeaders: Record<string, string>;

    /**
     *
     * @access public
     * @type {Object}
     * @desc a set of regional endpoints
     *
     */
    public regionalEndpointMapping: Record<string, string>;

    /**
     *
     * @access public
     * @type {Array<String>}
     * @desc a list of supported protocols used to choose an entry from `this.protocolHeaders`
     *
     */
    public supportedProtocols: Array<string>;

    /**
     *
     * @access public
     * @type {Object}
     * @desc a list of common headers
     *
     */
    public headers: Record<string, string>;
    /**
     *
     * @param {Object} options
     * @param {Object} [options.protocolHeaders={}]
     * @param {Object} [options.regionalEndpointMapping={}]
     * @param {Array<String>} [options.supportedProtocols=[]]
     * @param {Object} [options.headers={}]
     *
     */
    public constructor(options: {
        protocolHeaders?: Record<string, string>;
        regionalEndpointMapping?: Record<string, string>;
        supportedProtocols?: Array<string>;
        headers?: Record<string, string>;
    }) {
        const {
            protocolHeaders = {},
            regionalEndpointMapping = {},
            supportedProtocols = [],
            headers = {}
        } = options;

        /**
         *
         * @access public
         * @type {Object}
         * @desc contains a set of protocolHeaders
         *
         */
        this.protocolHeaders = protocolHeaders;

        /**
         *
         * @access public
         * @type {Object}
         * @desc a set of regional endpoints
         *
         */
        this.regionalEndpointMapping = regionalEndpointMapping;

        /**
         *
         * @access public
         * @type {Array<String>}
         * @desc a list of supported protocols used to choose an entry from `this.protocolHeaders`
         *
         */
        this.supportedProtocols = supportedProtocols;

        /**
         *
         * @access public
         * @type {Object}
         * @desc a list of common headers
         *
         */
        this.headers = headers;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Socket.SocketClientExtrasMap';
    }
}
