/**
 *
 * @module socketConnectionStateMapping
 *
 */

import webSocketStates from './webSocketStates';
import socketConnectionState from './socketConnectionState';

/**
 *
 * @desc Maps WebSocket.readyState values to the SDK `SocketConnectionState` equivalent
 *
 */
export default {
    [webSocketStates.OPEN]: socketConnectionState.connected,
    [webSocketStates.CONNECTING]: socketConnectionState.connecting,
    [webSocketStates.CLOSED]: socketConnectionState.closed
};
