/**
 *
 * @module mediaPayloadStream
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    AdsQos,
    AdsQosTypedef,
    Editorial,
    EditorialTypedef,
    Insertion,
    InsertionTypedef,
    PlaybackRenditions,
    PlaybackRenditionsTypedef,
    QosDecisionsResponse,
    QosDecisionsResponseTypedef,
    SourceInfo,
    SourceInfoTypedef
} from './typedefs';

import PlaybackAttributes from './playbackAttributes';
import PlaybackVariant from './playbackVariant';

import { StreamingType } from './enums';

/**
 *
 * @access private
 * @since 4.0.0
 *
 */
export default class MediaPayloadStream {
    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Object<SDK.Services.Media.AdsQos>|undefined}
     * @desc Qos metrics for the ads in this stream.
     * @note Currently optional because it does not exist yet in the service.
     *
     */
    public adsQos?: AdsQos;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Array<SourceInfo>}
     * @desc Media sources and source-specific analytics data.
     *
     */
    public sources: Array<SourceInfo>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Array<PlaybackVariant>}
     * @desc Video variants.
     *
     */
    public variants: Array<PlaybackVariant>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {PlaybackRenditions}
     *
     */
    public renditions: PlaybackRenditions;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.Media.PlaybackAttributes|undefined}
     *
     */
    public attributes?: PlaybackAttributes;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Object<SDK.Services.Media.QosDecisionsResponse>}
     *
     */
    public qosDecisions: QosDecisionsResponse;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {StreamingType}
     * @desc Indicates VOD or LIVE streaming.
     *
     */
    public streamingType: StreamingType;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {Insertion|undefined}
     * @desc SGAI/SSAI media insertion data.
     *
     */
    public insertion?: Insertion;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Array<SDK.Services.Media.Editorial>|undefined}
     * @desc Editorial marker data.
     *
     */
    public editorial?: Array<Editorial>;

    /**
     *
     * @param {Object} options
     * @param {Object<SDK.Services.Media.AdsQos>} [options.adsQos] - Qos metrics for the ads in this stream.
     * @param {Array<SDK.Services.Media.SourceInfo>} options.sources - Media sources and source-specific analytics data.
     * @param {Array<SDK.Services.Media.PlaybackVariant>} options.variants - Video variants.
     * @param {SDK.Services.Media.PlaybackRenditions} options.renditions - Audio and subtitle renditions.
     * @param {SDK.Services.Media.PlaybackAttributes} [options.attributes] - Media Attributes
     * @param {Object<SDK.Services.Media.QosDecisionsResponse>} [options.qosDecisions] - QoS data for slide and complete playlists.
     * @param {String<SDK.Services.Media.StreamingType>} options.streamingType - Indicates vod or live streaming.
     * @param {Object<SDK.Services.Media.Insertion>} [options.insertion] - SGAI/SSAI media insertion data.
     * @param {Array<SDK.Services.Media.Editorial>} [options.editorial] - Editorial marker data.
     *
     */
    public constructor(options: {
        adsQos?: AdsQos;
        sources: Array<SourceInfo>;
        variants: Array<PlaybackVariant>;
        renditions: PlaybackRenditions;
        attributes?: PlaybackAttributes;
        qosDecisions?: QosDecisionsResponse;
        streamingType: StreamingType;
        insertion?: Insertion;
        editorial?: Array<Editorial>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    adsQos: Types.object(AdsQosTypedef).optional,
                    sources: Types.array.of.object(SourceInfoTypedef),
                    variants: Types.array.of.instanceStrict(PlaybackVariant),
                    renditions: Types.object(PlaybackRenditionsTypedef),
                    attributes:
                        Types.instanceStrict(PlaybackAttributes).optional,
                    qosDecisions: Types.object(QosDecisionsResponseTypedef)
                        .optional,
                    streamingType: Types.in(StreamingType),
                    insertion: Types.object(InsertionTypedef).optional,
                    editorial: Types.array.of.object(EditorialTypedef).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            adsQos,
            sources,
            variants,
            renditions,
            attributes,
            qosDecisions,
            streamingType,
            insertion,
            editorial
        } = options;

        this.adsQos = adsQos;
        this.sources = sources;
        this.variants = variants;
        this.renditions = renditions;
        this.attributes = attributes;
        this.qosDecisions = qosDecisions ?? ({} as QosDecisionsResponse);
        this.streamingType = streamingType;
        this.insertion = insertion;
        this.editorial = editorial;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaPayloadStream';
    }
}
