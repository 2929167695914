/**
 *
 * @module playbackSelectionProperties
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import PlaybackSelectionAttributes from './playbackSelectionAttributes';
import PlaybackSelectionAdTracking from './playbackSelectionAdTracking';

import {
    PlaybackSelectionTracking,
    PlaybackSelectionTrackingTypedef
} from './typedefs';

/**
 *
 * @since 4.18.0
 *
 */
export default class PlaybackSelectionProperties {
    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Media.PlaybackSelectionAttributes}
     *
     */
    public attributes: PlaybackSelectionAttributes;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Services.Media.PlaybackSelectionAdTracking|undefined}
     *
     */
    public adTracking?: PlaybackSelectionAdTracking;

    /**
     *
     * @access public
     * @since 4.18.0
     * @type {SDK.Services.Media.PlaybackSelectionTracking|undefined}
     *
     */
    public tracking?: PlaybackSelectionTracking;

    /**
     *
     * @param {SDK.Services.Media.PlaybackSelectionAttributes} attributes
     * @param {SDK.Services.Media.PlaybackSelectionAdTracking} [adTracking]
     * @param {SDK.Services.Media.PlaybackSelectionTracking} [tracking]
     *
     */
    public constructor(
        attributes: PlaybackSelectionAttributes,
        adTracking?: PlaybackSelectionAdTracking,
        tracking?: PlaybackSelectionTracking
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                attributes: Types.instanceStrict(PlaybackSelectionAttributes),
                adTracking: Types.instanceStrict(PlaybackSelectionAdTracking)
                    .optional,
                tracking: Types.object(PlaybackSelectionTrackingTypedef)
                    .optional
            };

            typecheck(this, params, arguments);
        }

        this.attributes = attributes;
        this.adTracking = adTracking;
        this.tracking = tracking;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackSelectionProperties';
    }
}
