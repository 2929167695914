/**
 *
 * @module entry
 * @desc {SDK.Internal} entry point.
 *
 */

import Dust from './dust/entry';
import Telemetry from './telemetry/entry';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Internal
 *
 * @property {Dust} Dust
 * @property {Telemetry} Telemetry
 *
 */
export default {
    Dust,
    Telemetry
};
