/**
 *
 * @module entry
 * @desc {SDK.Services.Explore} entry point.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/services/class.sdk.services.explore.plantuml
 * @see https://devflow.jenkins.us-east-1.bamgrid.net/job/SDK-Doc/job/spec-sdk/job/master/lastStableBuild/artifact/dist/uml/architecture/services/Explore.svg
 *
 */

import { RefIdType, ActionType } from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Explore
 *
 * @property {ActionType} ActionType
 * @property {RefIdType} RefIdType
 * @property {ProgressState} ProgressState
 *
 */
export default {
    ActionType,
    RefIdType
};
