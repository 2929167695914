/**
 *
 * @module entry
 * @desc {SDK.Services.Identity} entry point.
 *
 */

import Bam from './bam/entry';

/**
 *
 * @typedef {Object} SDK.Services.Identity
 *
 * @property {Bam} Bam
 *
 */
export default {
    Bam
};
