/**
 *
 * @module entry
 * @desc {SDK.Device} entry point.
 *
 */

import BrowserDeviceAttributeProvider from './browserDeviceAttributeProvider';
import DeviceAttributes from './deviceAttributes';
import DeviceAttributeProvider from './deviceAttributeProvider';
import DeviceMetadata from './deviceMetadata';
import OsDeviceId from './osDeviceId';
import OsDeviceIdType from './osDeviceIdType';

/**
 *
 * @typedef {Object} SDK.Device
 *
 * @property {BrowserDeviceAttributeProvider} BrowserDeviceAttributeProvider
 * @property {DeviceAttributes} DeviceAttributes
 * @property {DeviceAttributeProvider} DeviceAttributeProvider
 * @property {DeviceMetadata} DeviceMetadata
 * @property {OsDeviceId} OsDeviceId
 * @property {OsDeviceIdType} OsDeviceIdType
 *
 */
export default {
    BrowserDeviceAttributeProvider,
    DeviceAttributes,
    DeviceAttributeProvider,
    DeviceMetadata,
    OsDeviceId,
    OsDeviceIdType
};
