/**
 *
 * @module dustSink
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';

import TelemetryManager from '../telemetry/telemetryManager';

import DustEvent from './dustEvent';
import DustServerPayload from '../../services/internal/dust/dustServerPayload';
import DustClientPayload from '../../services/internal/dust/dustClientPayload';
import DustDevicePayload from '../../services/internal/dust/dustDevicePayload';
import DustApplicationPayload from '../../services/internal/dust/dustApplicationPayload';
import DustSdkPayload from '../../services/internal/dust/dustSdkPayload';
import DustCategory from '../../services/internal/dust/dustCategory';
import LogSink from '../../logging/logSink';
import ExceptionReference from '../../services/exception/exceptionReference';
import LogEvent from '../../logging/logEvent';
import type { Application } from '../../services/configuration/typedefs';
import { createSimpleException } from '../../services/util/errorHandling/createException';
import { ServerData } from '../../services/internal/typedefs';

type DustSinkEnvironment = {
    device: DustDevicePayload;
    sdk: DustSdkPayload;
};

/**
 *
 * @access protected
 * @desc Sink implementation that logs to Dust Service via TelemetryManager.postEvent
 *
 */
export default class DustSink extends LogSink {
    /**
     *
     * @access private
     * @type {Array<LogEvent>}
     * @desc Holds dust events until the `TelemetryManager` is available and then it is no longer used.
     *
     */
    private queue: Array<LogEvent>;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.TelemetryManager|null}
     *
     */
    private telemetryManager: Nullable<TelemetryManager>;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc True when `SDK.Internal.Telemetry.TelemetryManager.EventBuffer` is ready for use.
     *
     */
    private eventBufferReady: boolean;

    /**
     *
     * @access private
     * @type {Object|null}
     * @desc Static environment properties for dust reporting.
     * @note `environment.sdk: SDK.Services.Internal.Dust.DustSdkPayload`
     * @note `environment.device: SDK.Services.Internal.Dust.DustDevicePayload`
     *
     */
    private environment: Nullable<DustSinkEnvironment>;

    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Dust.DustApplicationPayload|null}
     * @desc Contains information required for `SDK.Services.Internal.Dust.DustApplicationPayload`.
     *
     */
    private application: Nullable<DustApplicationPayload>;

    /**
     *
     * @access private
     * @since 4.2.0
     * @type {Boolean}
     * @desc Determines if dust logs should be queued or sent to the Telemetry service.
     *
     */
    private enabled: boolean;

    /**
     *
     * @access private
     * @since 4.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Boolean}
     *
     */
    public initialized: boolean;

    /**
     *
     * @param {SDK.Logging.Logger} logger
     *
     */
    public constructor(logger: Logger) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger)
            };

            typecheck(this, params, arguments);
        }

        this.queue = [];
        this.telemetryManager = null;
        this.eventBufferReady = false;
        this.environment = null;
        this.application = null;
        this.enabled = false;
        this.logger = logger;
        this.initialized = false;
    }

    /**
     *
     * @access public
     * @param {Object} event - The event to be logged.
     * @desc receives an event from logger.log and stores it in a queue or creates a dust event and posts it to
     * either telemetryManager.eventBuffer.postEvent or telemetryManager.trackingBuffer.postEvent, depending
     * on the event's category.
     * @note `event.isPublic` indicates this is a `Dust-like` event, i.e. not a `StreamSample` event
     * @returns {Promise<Void>}
     *
     */
    public log(event: LogEvent) {
        const { enabled, queue } = this;
        const extraData = LogEvent.getExtraData(event);

        if (event.isPublic && !extraData.isEdge && queue) {
            if (enabled) {
                this.postEvent(event);
            } else {
                queue.push(event);
            }
        }

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @desc enables the DustSink and empties the queue
     * @desc enables the DustSink to start sending events to the `EventBuffer`
     * @note called from `SdkSession.enableDustSink`
     *
     */
    public enableDustSink() {
        this.enabled = true;
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @desc empties the sink and tries to send all of the stored events to the respective `EventBuffer`
     * @note called from `SdkSession.enableDustSink`
     *
     */
    public emptySink() {
        const { queue } = this;

        while (queue.length) {
            this.postEvent(queue.shift() as LogEvent);
        }
    }

    /**
     *
     * @access public
     * @desc disabled the dust sink and invalidates the queue
     * @note called from `SdkSession.createSdkSession`
     * @note this disables the `DustSink` for the remainder of the SDK lifecycle and erases the queue
     *
     */
    public disableDustSink() {
        this.enabled = false;
        this.queue = [];
    }

    /**
     *
     * @access public
     * @since 4.2.0
     * @param {Object} options
     * @param {TelemetryManager} options.telemetryManager
     * @param {Object} options.environment
     * @param {Object} options.environment.device
     * @param {Object} options.environment.sdk
     * @param {Object} options.application
     * @throws {InvalidDustConfigurationException}
     * @note thrown if an incorrect or incomplete `application` is provided with
     * `SDK.Services.Configuration.BootstrapConfiguration` and the SDK Client Config via or if `environment` cannot be
     * determined or if the `environment` cannot be determined
     * @note merge `application` last allowing Application Developers to override what is in the config
     * @note `application` is optional, but if it is provided it must have a `version` property at a minimum
     * @note this method must be called before `this.enableDustSink`
     *
     */
    public initializeDustSink(options: {
        telemetryManager: TelemetryManager;
        environment: DustSinkEnvironment;
        application: Application;
    }) {
        try {
            /* istanbul ignore else */
            if (__SDK_TYPECHECK__) {
                const params = {
                    options: Types.object({
                        telemetryManager:
                            Types.instanceStrict(TelemetryManager),
                        environment: Types.object({
                            device: Types.nonEmptyObject,
                            sdk: Types.nonEmptyObject
                        }),
                        application: Types.nonEmptyObject.optional
                    })
                };

                typecheck(this, 'initializeDustSink', params, arguments);
            }

            const { telemetryManager, environment, application } = options;

            this.application = new DustApplicationPayload(application);

            this.environment = {
                device: new DustDevicePayload(environment.device),
                sdk: new DustSdkPayload(environment.sdk.version)
            };

            this.telemetryManager = telemetryManager;
            this.initialized = true;
        } catch (exception) {
            throw createSimpleException({
                description: (exception as Error).message,
                exceptionData:
                    ExceptionReference.internal.invalidDustConfiguration
            });
        }
    }

    /**
     *
     * @access private
     * @param {Object} event
     * @desc creates an instance of `SDK.Internal.Dust.DustEvent`
     * @note currently we do not support `transactionId`
     * @returns {SDK.Internal.Dust.DustEvent}
     *
     */
    private createDustEvent(event: LogEvent) {
        const { environment, application, logger } = this;
        const { device, sdk } = environment as DustSinkEnvironment;
        const extraData = LogEvent.getExtraData(event);

        const correlationIds = Check.nonEmptyObject(logger.correlationIds)
            ? logger.correlationIds
            : undefined;

        const {
            category = 'urn:bamtech:dust:bamsdk:unknown', // For testing purposes. Ideally we should never see this.
            timestamp,
            error,
            dataVersion,
            server = {} as ServerData
        } = extraData;

        const data = { ...extraData.data } as Record<string, unknown>;

        let dustServerPayload = {};

        if (error) {
            data.error = error;
        }

        if (Check.nonEmptyObject(server)) {
            dustServerPayload = new DustServerPayload(server);
        }

        const dustClientPayload = new DustClientPayload({
            application: application as DustApplicationPayload,
            device,
            sdk,
            category,
            correlationIds,
            data,
            event: event.message,
            timestamp,
            dataVersion
        });

        return new DustEvent({
            server: dustServerPayload as DustServerPayload,
            client: dustClientPayload
        });
    }

    /**
     *
     * @access private
     * @param {Object} event - The event to be posted.
     * @desc calls postEvent on the EventBuffer that is appropriate for the event supplied. Depending on the
     * event's category, this could be either the telemetryManager.eventBuffer or telemetryManager.trackingBuffer.
     * @note telemetryManager.trackingBuffer.postEvent and telemetryManager.eventBuffer.postEvent fire
     * asynchronous methods but we do not wait for them to resolve.
     *
     */
    private postEvent(event: LogEvent) {
        try {
            const { telemetryManager } = this;
            const extraData = LogEvent.getExtraData(event);
            const { category } = extraData;

            const dustEvent = this.createDustEvent(event);

            if (telemetryManager) {
                if (category === DustCategory.qoe) {
                    telemetryManager.qoeBuffer.postEvent(dustEvent);
                } else if (category === DustCategory.glimpse) {
                    telemetryManager.trackingBuffer.postEvent(dustEvent);
                } else {
                    telemetryManager.eventBuffer.postEvent(dustEvent);
                }
            }
        } catch (exception) {
            this.logger.warn(
                this.toString(),
                `Error creating SDK.Internal.Dust.DustEvent ${
                    (exception as Error).message
                }`
            );
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Dust.DustSink';
    }
}
