/**
 *
 * @module typedefs
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#types
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';
import QRCodeFormat from './qrCodeFormat';

/**
 *
 * @typedef {Object} SDK.QRCode.QRCodeQueryParams
 * @since 26.0.0
 * @desc Parameters specifying QR Code details, attached as query params in the QR Code URL.
 * @property {String} url - The url value to be scannable in the QR code.
 * @property {Number} size - The square dimensions (in pixels) of the QR Code.
 * @property {QRCodeFormat} format - The image format to deliver the QR code as.
 *
 */
export type QRCodeQueryParams = {
    url: string;
    size: number;
    format: QRCodeFormat;
};

/**
 *
 * @access private
 *
 */
export const QRCodeQueryParamsTypedef = {
    url: Types.string,
    size: Types.number,
    format: Types.in(QRCodeFormat)
};
