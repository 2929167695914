/**
 *
 * @module horaValidatedTelemetryResponse
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    PlaybackActivity,
    StartupActivity
} from '../../qualityOfService/enums';

import TelemetryResponse from './telemetryResponse';

import { HoraValidationResultTypedef, HoraValidationResult } from './typedefs';

/**
 *
 * @access protected
 * @since 14.0.0
 * @desc Model describing the response from the Hora telemetry validation service.
 *
 */
export default class HoraValidatedTelemetryResponse extends TelemetryResponse {
    /**
     *
     * @access protected
     * @since 14.0.0
     * @type {Array<Object<SDK.Services.Internal.Telemetry.HoraValidationResult>>}
     *
     */
    public results: Array<HoraValidationResult>;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {String<SDK.Services.QualityOfService.PlaybackActivity>|undefined}
     *
     */
    protected playbackActivity?: PlaybackActivity;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {String<SDK.Services.QualityOfService.StartupActivity>|undefined}
     *
     */
    protected startupActivity?: StartupActivity;

    /**
     *
     * @access protected
     * @since 16.0.0
     * @type {String|undefined}
     * @note The event URN for the associated data in `DustClientPayload.event`
     *
     */
    protected event?: string;

    /**
     *
     * @param {Object} options
     * @param {Array<Object<SDK.Services.Internal.Telemetry.HoraValidationResult>>} options.results
     * @param {String<SDK.Services.QualityOfService.PlaybackActivity>} [options.playbackActivity]
     * @param {String<SDK.Services.QualityOfService.StartupActivity>} [options.startupActivity]
     * @param {String} [options.event]
     * @param {Number} [options.replyAfter]
     * @param {String} [options.requestId]
     *
     */
    public constructor(options: {
        results: Array<HoraValidationResult>;
        playbackActivity?: PlaybackActivity;
        startupActivity?: StartupActivity;
        event?: string;
        replyAfter?: number;
        requestId?: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    results: Types.array.of.object(HoraValidationResultTypedef),
                    playbackActivity: Types.in(PlaybackActivity).optional,
                    startupActivity: Types.in(StartupActivity).optional,
                    event: Types.nonEmptyString.optional,
                    replyAfter: Types.skip,
                    requestId: Types.skip
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { results, playbackActivity, startupActivity, event } = options;

        this.results = results;
        this.playbackActivity = playbackActivity;
        this.startupActivity = startupActivity;
        this.event = event;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse';
    }
}
