/**
 *
 * @module activationToken
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access public
 *
 */
export default class ActivationToken {
    /**
     *
     * @access public
     * @type {String}
     * @desc A JWT token based on user's super bundle subscription.
     *
     */
    public token: string;

    /**
     *
     * @param {String} token
     *
     */
    public constructor(token: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                token: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.token = token;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.ExternalActivation.ActivationToken';
    }
}
