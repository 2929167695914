/* istanbul ignore next */
/* eslint-disable func-names */

/**
 *
 * @module antiCorruption
 * @since 16.0.0
 *
 * @desc AntiCorruption ensures that optional param values are of a defined type. If not, it either uses a defined default value or throws an error.
 * Types.integer rounds the value to make sure it's an integer. If it's NaN, it uses the default value
 *
 * The default value is defined as such:
 * `0` for Types.integer.default(0)
 * `null` for Types.integerGreater(1).default(null)
 * `undefined` for Types.boolean.default()
 *
 */

import { Check, Types as CheckingTypes, typecheck } from '@dss/type-checking';

type Parser = {
    (
        classInstance: string | object,
        param: number | string | boolean | object,
        value: unknown
    ): unknown;
    hasArgs?: boolean;
};

type AnonymousParser<T = TodoAny> = {
    (defaultValue?: Nullable<T>): Parser;
    hasArgs?: boolean;
};

/**
 *
 * @access protected
 * @since 16.0.0
 * @param {Object|String} classInstance Instance of a class, object or name.
 * @param {Object} data
 * @param {Object} parserConfig
 * @desc Anti-corrupts data according to a given parser config. If data is NaN, converts to null.
 * @throws {Error} When data is invalid (except NaN)
 * @returns {Object} Returns cleaned data.
 *
 */
function antiCorrupt<T extends Record<string, unknown>>(
    classInstance: object | string,
    data: unknown,
    parserConfig: Record<string, Parser | AnonymousParser | void> = {}
) {
    /* istanbul ignore else */
    if (__SDK_TYPECHECK__) {
        typecheck(classInstance, { options: CheckingTypes.object().optional }, [
            data
        ]);
    }

    if (Check.not.object(data)) {
        return {} as T;
    }

    return Object.entries(parserConfig).reduce((prev, curr) => {
        const param = curr[0];
        const value = (data as Record<string, unknown>)[param];
        const parse = parserConfig[param];

        if (Check.function(parse) && Check.assigned(parse.hasArgs)) {
            if (parse.hasArgs) {
                prev[param] = parse(classInstance, param, value);
            } else {
                prev[param] = (parse as AnonymousParser)()(
                    classInstance,
                    param,
                    value
                );
            }
        }

        return prev;
    }, {} as Record<string, unknown>) as T;
}

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Returns the value number or 0 otherwise.
 * @throws {Error} If data type is not Number.
 * @returns {Number}
 *
 */
function number() {
    throw TypeError('Correct usage: Types.number.default(0)');

    // if we want Types.number to exist and execute typecheck as non optional
    // return function parse(classInstance, param, value) {
    //     // check isNaN
    //     if (Number.isNaN(value)) {
    //         return 0;
    //     }

    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         typecheck(classInstance, { [param]: CheckingTypes.number }, [value]);
    //     }

    //     // logic to set default
    //     return Check.number(value) ? value : 0;

    // };
}

number.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @param {Number|null} defaultValue - A default value.
 * @desc Returns the value number or `defaultValue` otherwise.
 * @throws {Error} If data type is not Number.
 * @returns {*}
 *
 */

number.default = function (defaultValue: Nullable<number>) {
    const parse = (
        classInstance: object | string,
        param: string,
        value: unknown
    ) => {
        // check isNaN
        if (Number.isNaN(value)) {
            return defaultValue;
        }

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(
                classInstance,
                { [param]: CheckingTypes.number.optional },
                [value]
            );
        }

        // logic to set default
        return Check.number(value) ? value : defaultValue;
    };

    parse.hasArgs = true;

    return parse;
} as AnonymousParser<number>;

number.default.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Returns the rounded (integer) number or null otherwise.
 * @throws {Error} If data type is not Number.
 * @returns {Number}
 */
function integer() {
    throw TypeError('Correct usage: Types.integer.default(0)');

    // if we want Types.integer to exist and execute typecheck as non optional
    // return function parse(classInstance, param, value) {
    //     // check isNaN
    //     if (Number.isNaN(value)) {
    //         return 0;
    //     }

    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         typecheck(classInstance, { [param]: CheckingTypes.number }, [value]);
    //     }

    //     // logic to set default
    //     return Check.number(value) ? Math.floor(value) : 0;

    // };
}

integer.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Returns the rounded (integer) number or `defaultValue` otherwise.
 * @throws {Error} If data type is not Number.
 * @returns {*}
 */
integer.default = function (defaultValue: Nullable<number>) {
    const parse = (
        classInstance: object | string,
        param: string,
        value: unknown
    ) => {
        // check isNaN
        if (Number.isNaN(value)) {
            return defaultValue;
        }

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(
                classInstance,
                { [param]: CheckingTypes.number.optional },
                [value]
            );
        }

        // logic to set default
        return Check.number(value) ? Math.floor(value) : defaultValue;
    };

    parse.hasArgs = true;

    return parse;
} as AnonymousParser<number>;

integer.default.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @desc Returns the value string or empty otherwise.
 * @throws {Error} If data type is not String.
 * @returns {String}
 *
 */
function string() {
    throw TypeError("Correct usage: Types.string.default('')");

    // if we want Types.string to exist and execute typecheck as non optional
    // return function parse(classInstance, param, value) {

    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         typecheck(classInstance, { [param]: CheckingTypes.string }, [value]);
    //     }

    //     // logic to set default
    //     return Check.string(value) ? value : '';

    // };
}

string.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @param {*} defaultValue - A default value.
 * @desc Returns the value string or `defaultValue` otherwise.
 * @throws {Error} If data type is not String.
 * @returns {*}
 */
string.default = function (defaultValue: Nullable<string>) {
    const parse = (
        classInstance: object | string,
        param: string,
        value: unknown
    ) => {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(
                classInstance,
                { [param]: CheckingTypes.string.optional },
                [value]
            );
        }

        // logic to set default
        return Check.string(value) ? value : defaultValue;
    };

    parse.hasArgs = true;

    return parse;
} as AnonymousParser<string>;

string.default.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @desc Returns the value object or empty otherwise.
 * @throws {Error} If data type is not Object.
 * @returns {Object}
 *
 */
function object() {
    throw TypeError('Correct usage: Types.object.default({})');

    // if we want Types.object to exist and execute typecheck as non optional
    // return function parse(classInstance, param, value) {

    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         typecheck(classInstance, { [param]: CheckingTypes.object() }, [value]);
    //     }

    //     // logic to set default
    //     return Check.object(value) ? value : {};

    // };
}

object.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @param {*} defaultValue - A default value.
 * @desc Returns the value object or `defaultValue` otherwise.
 * @throws {Error} If data type is not Object.
 * @returns {*}
 *
 */
object.default = function (defaultValue: Nullable<object>) {
    const parse = (
        classInstance: object | string,
        param: string,
        value: unknown
    ) => {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(
                classInstance,
                { [param]: CheckingTypes.object().optional },
                [value]
            );
        }

        // logic to set default
        return Check.object(value) ? value : defaultValue;
    };

    parse.hasArgs = true;

    return parse;
} as AnonymousParser<object>;

object.default.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Returns the boolean value or false otherwise.
 * @returns {Boolean}
 */
function boolean() {
    throw TypeError('Correct usage: Types.boolean.default(false)');

    // if we want Types.boolean to exist and execute typecheck as non optional
    // return function parse(classInstance, param, value) {

    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         typecheck(classInstance, { [param]: CheckingTypes.boolean }, [value]);
    //     }

    //     // logic to set default
    //     return Check.boolean(value) ? value : false;

    // };
}

boolean.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.1.0
 * @param {*} defaultValue - A default value.
 * @desc Returns the value object or `defaultValue` otherwise.
 * @throws {Error} If data type is not Object.
 * @returns {*}
 */
boolean.default = function (defaultValue: Nullable<boolean>) {
    const parse = (
        classInstance: object | string,
        param: string,
        value: unknown
    ) => {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            typecheck(
                classInstance,
                { [param]: CheckingTypes.boolean.optional },
                [value]
            );
        }

        // logic to set default
        return Check.boolean(value) ? value : defaultValue;
    };

    parse.hasArgs = true;

    return parse;
} as AnonymousParser<boolean>;

boolean.default.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Returns the rounded number if it's greater than x
 * @throws {Error} If data type is not Number.
 * @param {Number} x - Integer to compare with
 * @returns {*}
 *
 */
function integerGreater(x: number) {
    const parse = () => {
        throw TypeError('Correct usage: Types.integerGreater(1).default(1)');

        // if we want Types.integerGreater(x) to exist and execute typecheck as non optional
        // // check isNaN
        // if (Number.isNaN(value)) {
        //     return x;
        // }

        // /* istanbul ignore else */
        // if (__SDK_TYPECHECK__) {
        //     typecheck(classInstance, { [param]: CheckingTypes.number }, [value]);
        // }

        // // logic to set default
        // if (Check.number(value)) {
        //     const int = Math.floor(value);

        //     return int > x ? int : x;
        // }

        // return x;
    };

    parse.hasArgs = true;

    parse.default = function (defaultValue: Nullable<number>) {
        const p = (
            classInstance: object | string,
            param: string,
            value: unknown
        ) => {
            if (Number.isNaN(value)) {
                return defaultValue;
            }

            /* istanbul ignore else */
            if (__SDK_TYPECHECK__) {
                typecheck(
                    classInstance,
                    { [param]: CheckingTypes.number.optional },
                    [value]
                );
            }

            // logic to set default
            if (Check.number(value)) {
                const int = Math.floor(value);

                return int > x ? int : defaultValue;
            }

            return defaultValue;
        };

        p.hasArgs = true;

        return p;
    } as AnonymousParser<number>;

    parse.default.hasArgs = true;

    return parse;
}

integerGreater.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @param {Number} x - Integer to compare with
 * @desc Returns the rounded number if it's greater or equal to x
 * @throws {Error} If data type is not Number.
 * @returns {*}
 *
 */
function integerGreaterOrEqual(x: number) {
    const parse = () => {
        throw TypeError(
            'Correct usage: Types.integerGreaterOrEqual(1).default(1)'
        );

        // if we want Types.integerGreaterOrEqual(x) to exist and execute typecheck as non optional
        // if (Number.isNaN(value)) {
        //     return x;
        // }

        // /* istanbul ignore else */
        // if (__SDK_TYPECHECK__) {
        //     typecheck(classInstance, { [param]: CheckingTypes.number }, [value]);
        // }

        // // logic to set default
        // if (Check.number(value)) {
        //     const int = Math.floor(value);

        //     return int >= x ? int : x;
        // }

        // return x;
    };

    parse.hasArgs = true;

    parse.default = function (defaultValue: Nullable<number>) {
        const p = (
            classInstance: object | string,
            param: string,
            value: unknown
        ) => {
            if (Number.isNaN(value)) {
                return defaultValue;
            }

            /* istanbul ignore else */
            if (__SDK_TYPECHECK__) {
                typecheck(
                    classInstance,
                    { [param]: CheckingTypes.number.optional },
                    [value]
                );
            }

            // logic to set default
            if (Check.number(value)) {
                const int = Math.floor(value);

                return int >= x ? int : defaultValue;
            }

            return defaultValue;
        };

        p.hasArgs = true;

        return p;
    } as AnonymousParser<number>;

    parse.default.hasArgs = true;

    return parse;
}

integerGreaterOrEqual.hasArgs = false;

/**
 *
 * @access protected
 * @since 16.0.0
 * @param {Object} EnumType - Enum type to compare with.
 * @param {*} [defaultValue='other'] - Default value of `EnumType`.
 * @desc Returns the enum value
 * @throws {Error} If data type is not `EnumType`.
 * @returns {*}
 *
 */
function enumList(EnumType: Record<string, unknown>) {
    const parse = () => {
        throw TypeError("Correct usage: Types.in({}).default('none')");

        // if we want Types.in(x) to exist and execute typecheck as non optional
        // /* istanbul ignore else */
        // if (__SDK_TYPECHECK__) {
        //     typecheck(classInstance, { [param]: CheckingTypes.in(EnumType) }, [value]);
        // }

        // // logic to set default
        // return Check.assigned(value) ? value : defaultValue;
    };

    parse.hasArgs = true;

    parse.default = function (defaultValue: Nullable<string>) {
        const p = (
            classInstance: object | string,
            param: string,
            value: unknown
        ) => {
            /* istanbul ignore else */
            if (__SDK_TYPECHECK__) {
                typecheck(
                    classInstance,
                    { [param]: CheckingTypes.in(EnumType).optional },
                    [value]
                );
            }

            // logic to set default
            return Check.assigned(value) ? value : defaultValue;
        };

        p.hasArgs = true;

        return p;
    } as AnonymousParser<string>;

    parse.default.hasArgs = true;

    return parse;
}

enumList.hasArgs = false;

const Types = {
    number,
    boolean,
    integer,
    integerGreater,
    integerGreaterOrEqual,
    in: enumList,
    string,
    object
};

export { Types, antiCorrupt };
