/**
 *
 * @module analyticsProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md#analyticsprovider
 *
 */

/**
 *
 * @since 13.0.0
 * @desc An interface for application-provided analytics properties. The SDK will query the provider for these values when reporting QoE events.
 *
 */
export default abstract class AnalyticsProvider {
    /**
     *
     * @abstract
     * @access public
     * @since 13.0.0
     * @param {String} [playbackSessionId] - The playback session ID to get common properties for. (NOTE: not all occurrences may have a `playbackSessionId`).
     * @desc Gets common parameters from the client for QoE reporting.
     * @note Example client "common properties" are `activitySessionId`, `experiments` and `experimentKeys`.
     * @returns {Object}
     *
     */
    public abstract getCommonProperties(playbackSessionId?: string): object;

    /**
     *
     * @abstract
     * @access public
     * @since 13.0.0
     * @param {String} [playbackSessionId] - The playback session ID to get common properties for. (NOTE: not all occurrences may have a `playbackSessionId`).
     * @desc Gets the dictionary version of the client used to look up an error localization key.
     * @returns {String}
     *
     */
    public abstract getDictionaryVersion(playbackSessionId?: string): string;

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.AnalyticsProvider';
    }
}
