/**
 *
 * @module entitlementApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/entitlement.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import BaseApi from '../baseApi';

import AccessTokenProvider from '../token/accessTokenProvider';
import EntitlementClient from '../services/entitlement/entitlementClient';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import Logger from '../logging/logger';

import { ApiOptions } from '../typedefs';
import { ServerResponse } from '../services/providers/typedefs';

const DustUrn = DustUrnReference.entitlement.entitlementApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.9.0
 * @desc Provides ability to get entitlement information.
 *
 */
export default class EntitlementApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Services.Entitlement.EntitlementClient}
     *
     */
    private entitlementClient: EntitlementClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Entitlement.EntitlementClient} options.entitlementClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        entitlementClient: EntitlementClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    entitlementClient: Types.instanceStrict(EntitlementClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { entitlementClient } = options;

        this.entitlementClient = entitlementClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {String} mediaId - The mediaId to verify account has sufficient entitlements to watch.
     * @desc Requests DRM service to verify this account has the necessary entitlements to watch this content.
     * @throws {AgeNotVerifiedException} The age for the users profile has not been verified and needs to be before proceeding.
     * @throws {AgeNotVerifiedKrException} The age for the users profile has not been verified and needs to be before proceeding. The user is located in South Korea.
     * @throws {BlackoutException} The current user is not allowed to access the media for the requested license because they are in a blackout location.
     * @throws {KidsModeEnabledException} The current user is not allowed to access the media due to parental controls restrictions on the account.
     * @throws {LoginRequiredException} The requested license can only be obtained after authentication.
     * @throws {MediaUnavailableException} The requested media is not available.
     * @throws {NotEntitledException} The current user does not have sufficient privileges to access the media for the requested license.
     * @throws {ParentalControlsRestrictedException} The current user is not allowed to access the media for the requested license due to parental controls restrictions on the profile.
     * @throws {ProfilePersonalInfoMissingException} The user has not yet validated or provided personal info in their profile, required for ad serving.
     * @throws {ProfileMissingException} There was no active profile in the token or the service was unable to use it.
     * @throws {ProfilePinMissingException} The request requires a pin to be set on the profile but no pin has been set.
     * @throws {ProfilePinExpiredException} The profile pin has expired and needs to be updated before proceeding.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object>}
     *
     */
    public async verifyMediaRights(
        mediaId: string
    ): Promise<ServerResponse<ApprovedAny>>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            mediaId: Types.nonEmptyString
        }
    })
    public async verifyMediaRights(apiOptions: unknown) {
        const {
            logTransaction,
            args: [mediaId]
        } = apiOptions as ApiOptions<[string]>;

        return this.entitlementClient.verifyMediaRights(
            mediaId,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Entitlement.EntitlementApi';
    }
}
