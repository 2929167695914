/**
 *
 * @module platformMetricsProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';

/**
 *
 * @since 7.0.0
 * @desc Defines the contract for a class that can provide the ability to get platform metrics.
 * The SDK ships with a default implementation of the PlatformMetricsProvider that returns
 * falsy values.
 *
 */
export default class PlatformMetricsProvider {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Logging.Logger} [logger=Logging]
     *
     */
    public constructor(logger?: Logger) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional
            };

            typecheck(this, params, arguments);
        }

        this.logger = logger || Logger.instance;

        this.logger.log(this.toString(), 'Created provider');
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @desc Requests the available CPU resource as a whole number percentage.
     * @returns {Number|null}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    // eslint-disable-next-line custom-rules/inferred-return-type
    public availableCpuPercentage(): Nullable<number> {
        return null;
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @desc Requests the available system memory in MB.
     * @returns {Number|null}
     * @note The SDK ships with a default implementation that returns a falsy value.
     *
     */
    // eslint-disable-next-line custom-rules/inferred-return-type
    public availableMemoryMb(): Nullable<number> {
        return null;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Platform.PlatformMetricsProvider';
    }
}
