/**
 *
 * @module streamSamplePayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import StreamSampleClientPayload from './streamSampleClientPayload';
import TelemetryPayload from './telemetryPayload';

/**
 *
 * @access protected
 * @since 3.5.0
 * @implements {TelemetryPayload}
 *
 */
export default class StreamSamplePayload extends TelemetryPayload {
    /**
     *
     * @param {Object} options
     * @param {Object} options.server
     * @param {SDK.Services.Internal.Telemetry.StreamSampleClientPayload} options.client
     *
     */
    public constructor(options: {
        server: object;
        client: StreamSampleClientPayload;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    server: Types.object(),
                    client: Types.instanceStrict(StreamSampleClientPayload)
                })
            };

            typecheck(this, params, arguments);
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.StreamSamplePayload';
    }
}
