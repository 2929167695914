/**
 *
 * @module accessState
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessStateData from './accessStateData';

/**
 *
 * @desc Represents the state of the SDK to allow it to be transferred to another device.
 *
 */
export default class AccessState {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public version: string;

    /**
     *
     * @access public
     * @type {AccessStateData}
     *
     */
    public data: AccessStateData;

    /**
     *
     * @param {String} version
     * @param {AccessStateData} data
     *
     */
    public constructor(version: string, data: AccessStateData) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                version: Types.nonEmptyString,
                data: Types.instanceStrict(AccessStateData)
            };

            typecheck(this, params, arguments);
        }

        this.version = version;

        this.data = data;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.AccessState';
    }
}
