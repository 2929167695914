/**
 *
 * @module entry
 * @desc {SDK.Content} entry point.
 *
 */

import GraphQlQuery from './graphQlQuery';
import GraphQlPersistedQuery from './graphQlPersistedQuery';
import ContentQuery from './contentQuery';
import SearchOverrides from './searchOverrides';

import Search from './search/entry';

/**
 *
 * @typedef {Object} SDK.Content
 *
 * @property {SDK.Content.GraphQlQuery} GraphQlQuery
 * @property {SDK.Content.GraphQlPersistedQuery} GraphQlPersistedQuery
 * @property {SDK.Content.ContentQuery} ContentQuery
 * @property {SDK.Content.SearchOverrides} SearchOverrides
 *
 * @property {SDK.Content.Search} Search
 *
 */
export default {
    GraphQlQuery,
    GraphQlPersistedQuery,
    ContentQuery,
    SearchOverrides,

    Search
};
