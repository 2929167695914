/**
 *
 * @module commerceManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import RetryPolicy from './retryPolicy';

interface CommerceManagerExtrasMapOptions {
    retryPolicy: RetryPolicy;
    checkOrderStatusDelay?: number;
    validateOrderNonRetryCodes?: Array<number>;
}

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class CommerceManagerExtrasMap {
    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.RetryPolicy}
     * @desc Gets or sets the retry policy to help drive the retry behavior for service failures.
     *
     */
    public retryPolicy: RetryPolicy;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Time it takes to make a service request to check order status (in seconds), it must be 4 or higher.
     *
     */
    public checkOrderStatusDelay: number;

    /**
     *
     * @access public
     * @since 15.0.0
     * @type {Array<Number>}
     * @desc List of status codes for validateOrder method to skip retry.
     *
     */
    public validateOrderNonRetryCodes: Array<number>;

    /**
     *
     * @param {Object} options
     * @param {RetryPolicy} options.retryPolicy
     * @param {Number} [options.checkOrderStatusDelay=4.0]
     * @param {Array<Number>} [options.validateOrderNonRetryCodes=[400, 422, 429]]
     *
     */
    public constructor(options: CommerceManagerExtrasMapOptions) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    retryPolicy: Types.instanceStrict(RetryPolicy),
                    checkOrderStatusDelay: Types.greaterOrEqual(4).optional,
                    validateOrderNonRetryCodes: Types.array.of.number.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            retryPolicy,
            checkOrderStatusDelay = 4,
            validateOrderNonRetryCodes
        } = options || {};

        this.retryPolicy = retryPolicy;
        this.checkOrderStatusDelay =
            checkOrderStatusDelay < 4 ? 4 : checkOrderStatusDelay;
        this.validateOrderNonRetryCodes = validateOrderNonRetryCodes || [
            400, 422, 429
        ];
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} [extras] - JSON services.commerce.extras config object returned from configuration service.
     * @desc Parses JSON into CommerceManagerExtrasMap
     * @returns {CommerceManagerExtrasMap}
     *
     */
    public static parse(extras?: {
        retryPolicy?: TodoAny;
        checkOrderStatusDelay?: number;
        validateOrderNonRetryCodes?: Array<number>;
    }) {
        const {
            checkOrderStatusDelay,
            validateOrderNonRetryCodes,
            retryPolicy
        } = extras || {};

        const options = {
            retryPolicy: new RetryPolicy(retryPolicy || {}),
            checkOrderStatusDelay,
            validateOrderNonRetryCodes
        };

        return new CommerceManagerExtrasMap(options);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.CommerceManagerExtrasMap';
    }
}
