/**
 *
 * @module entry
 * @desc {SDK.Services.Paywall} entry point.
 *
 */

import AccountEntitlementContext from './accountEntitlementContext';
import Assertion from './assertion';
import Campaign from './campaign';
import DocumentCode from './documentCode';
import IntroPrice from './introPrice';
import PaymentPeriod from './paymentPeriod';
import Paywall from './paywall';
import PaywallSubscription from './paywallSubscription';
import Product from './product';
import ProductType from './productType';
import PurchaseContext from './purchaseContext';
import TimeUnit from './timeUnit';
import Reason from './reason';

/**
 *
 * @typedef {Object} SDK.Services.Paywall
 *
 * @property {SDK.Services.Paywall.AccountEntitlementContext} AccountEntitlementContext
 * @property {Assertion} Assertion
 * @property {Campaign} Campaign
 * @property {SDK.Services.Paywall.DocumentCode} DocumentCode
 * @property {IntroPrice} IntroPrice
 * @property {SDK.Services.Paywall.PaymentPeriod} PaymentPeriod
 * @property {Paywall} Paywall
 * @property {SDK.Services.Paywall.PaywallSubscription} PaywallSubscription
 * @property {Product} Product
 * @property {SDK.Services.Paywall.ProductType} ProductType
 * @property {SDK.Services.Paywall.PurchaseContext} PurchaseContext
 * @property {SDK.Services.Paywall.TimeUnit} TimeUnit
 * @property {SDK.Services.Paywall.Reason} Reason
 *
 */
export default {
    AccountEntitlementContext,
    Assertion,
    Campaign,
    DocumentCode,
    IntroPrice,
    PaymentPeriod,
    Paywall,
    PaywallSubscription,
    Product,
    ProductType,
    PurchaseContext,
    TimeUnit,
    Reason
};
