/**
 *
 * @module telemetryManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/telemetry.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../../logging/logger';
import DiagnosticFeature from '../../diagnosticFeature';
import InternalEvents from '../../internalEvents';
import TelemetryManagerConfiguration from '../../services/configuration/telemetryManagerConfiguration';
import TelemetryClient from '../../services/internal/telemetry/telemetryClient';
import TokenManager from '../../token/tokenManager';
import type EnvironmentConfiguration from '../../services/providers/browser/environmentConfiguration';
import { IEndpoint } from '../../services/providers/typedefs';
import EventBufferTelemetryPayload from './eventBufferTelemetryPayload';
import EventBufferMessageEnvelope from './eventBufferMessageEnvelope';
import EnvelopeMessageQueue from '../envelopeMessageQueue';

/**
 *
 * @access protected
 * @desc Provides a manager that can be used to access post telemetry events.
 *
 */
export default class TelemetryManager extends EventEmitter {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.EventBuffer}
     * @desc used for `StreamSampleEvents`
     *
     */
    public streamSampleBuffer: EventBufferTelemetryPayload;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.EventBuffer}
     * @desc used for `Dust` events
     *
     */
    public eventBuffer: EventBufferTelemetryPayload;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.EventBuffer}
     * @desc used for `UserActivity` related events
     *
     */
    public trackingBuffer: EventBufferTelemetryPayload;

    /**
     *
     * @access private
     * @type {SDK.Internal.Telemetry.EventBuffer}
     * @desc used for `QoE` events
     *
     */
    public qoeBuffer: EventBufferTelemetryPayload;

    /**
     *
     * @access protected
     * @type {SDK.Services.Configuration.TelemetryManagerConfiguration}
     * @desc Telemetry manager configuration.
     *
     */
    public config: TelemetryManagerConfiguration;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {SDK.Internal.Telemetry.EventBufferMessageEnvelope}
     * @desc Buffer for any MessageEnvelope events.
     *
     */
    public dustEnvelopeBuffer: EventBufferMessageEnvelope;

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Configuration.TelemetryManagerConfiguration} options.telemetryManagerConfiguration
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Internal.Telemetry.TelemetryClient} options.telemetryClient
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Internal.Telemetry.EnvelopeMessageQueue} options.envelopeMessageQueue
     * @param {String} options.sourceUrn
     *
     */
    public constructor(options: {
        telemetryManagerConfiguration: TelemetryManagerConfiguration;
        tokenManager: TokenManager;
        telemetryClient: TelemetryClient;
        environmentConfiguration: EnvironmentConfiguration;
        logger: Logger;
        envelopeMessageQueue: EnvelopeMessageQueue;
        sourceUrn: string;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    telemetryManagerConfiguration: Types.instanceStrict(
                        TelemetryManagerConfiguration
                    ),
                    tokenManager: Types.instanceStrict(TokenManager),
                    telemetryClient: Types.instanceStrict(TelemetryClient),
                    logger: Types.instanceStrict(Logger),
                    envelopeMessageQueue:
                        Types.instanceStrict(EnvelopeMessageQueue),
                    sourceUrn: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            telemetryManagerConfiguration,
            tokenManager,
            telemetryClient,
            logger,
            envelopeMessageQueue,
            sourceUrn
        } = options;

        const {
            extras: {
                dustEnvelopeBufferConfiguration,
                eventBufferConfiguration,
                fastTrack,
                glimpseBufferConfiguration,
                prohibited,
                qoeBufferConfiguration,
                streamSampleBufferConfiguration
            }
        } = telemetryManagerConfiguration;

        this.logger = logger;
        this.config = telemetryManagerConfiguration;

        this.streamSampleBuffer = new EventBufferTelemetryPayload({
            bufferConfiguration: streamSampleBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.postEvent as IEndpoint,
            telemetryClient,
            logger,
            fastTrack,
            prohibited,
            bufferName: 'StreamSampleBuffer'
        });

        this.eventBuffer = new EventBufferTelemetryPayload({
            bufferConfiguration: eventBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent as IEndpoint,
            telemetryClient,
            logger,
            fastTrack,
            prohibited,
            diagnosticFeature: DiagnosticFeature.glimpseValidation,
            bufferName: 'EventBuffer'
        });

        this.trackingBuffer = new EventBufferTelemetryPayload({
            bufferConfiguration: glimpseBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent as IEndpoint,
            telemetryClient,
            logger,
            fastTrack,
            prohibited,
            diagnosticFeature: DiagnosticFeature.glimpseValidation,
            bufferName: 'TrackingBuffer'
        });

        this.qoeBuffer = new EventBufferTelemetryPayload({
            bufferConfiguration: qoeBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints.dustEvent as IEndpoint,
            telemetryClient,
            logger,
            fastTrack,
            prohibited,
            diagnosticFeature: DiagnosticFeature.qoeValidation,
            bufferName: 'QoEBuffer'
        });

        this.dustEnvelopeBuffer = new EventBufferMessageEnvelope({
            bufferConfiguration: dustEnvelopeBufferConfiguration,
            tokenManager,
            endpoint: telemetryClient.config.endpoints
                .envelopeEvent as IEndpoint,
            telemetryClient,
            logger,
            fastTrack,
            prohibited,
            diagnosticFeature: DiagnosticFeature.glimpseValidation,
            envelopeMessageQueue,
            sourceUrn
        });

        this.trackingBuffer.on(
            InternalEvents.ValidationResultsReceived,
            (validation) => {
                this.emit(InternalEvents.ValidationResultsReceived, validation);
            }
        );

        this.qoeBuffer.on(
            InternalEvents.ValidationResultsReceived,
            (validation) => {
                this.emit(InternalEvents.ValidationResultsReceived, validation);
            }
        );

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @desc gives us a way to be sure telemetry is disabled in tests
     *
     */
    public async dispose() {
        await Promise.all([
            this.qoeBuffer.dispose(),
            this.trackingBuffer.dispose(),
            this.eventBuffer.dispose(),
            this.streamSampleBuffer.dispose(),
            this.dustEnvelopeBuffer.dispose()
        ]);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Telemetry.TelemetryManager';
    }
}
