/**
 *
 * @module bamHlsErrorMapping
 *
 */

import ErrorCode from '../services/exception/errorCode';

/**
 *
 * @access private
 * @since 4.3.0
 * @typedef {Object} SDK.Media.BamHlsErrorMapping
 * @desc Maps BamHls errors to SDK Error Cases.
 *
 */
export default {
    drmLicenseRequestFailed: ErrorCode.authorizationExpired,
    drmLicenseExpired: ErrorCode.invalidGrant,
    drmHdcpError: ErrorCode.mediaNotPlayable,
    drmEmeMissing: ErrorCode.mediaNotPlayable,
    drmCdmInternalError: ErrorCode.mediaNotPlayable,
    drmNoSupportedKeySystem: ErrorCode.mediaNotPlayable,
    drmNoSupportedKeySystemConfiguration: ErrorCode.unspecifiedDrmError,
    drmEncryptionSchemeNotSupported: ErrorCode.mediaNotPlayable,
    drmVariantKeySystemMissing: ErrorCode.keyNotFound,
    drmMediakeysChangeFail: ErrorCode.unspecifiedDrmError,
    drmMultipleSessionsNotSupported: ErrorCode.unspecifiedDrmError,
    drmEmptyLicenseRequest: ErrorCode.unspecifiedDrmError,
    drmParentalControlsRestricted: ErrorCode.parentalControlsRestricted,
    drmLicenseForbidden: ErrorCode.upgradeRequired,
    default: ErrorCode.unspecifiedDrmError
};
