/**
 *
 * @module adEngineClientConfiguration
 *
 */

import AdEngineClientExtrasMap from './adEngineClientExtrasMap';
import ServiceClientConfiguration from '../../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the AdEngineClient.
 *
 */
export default class AdEngineClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    AdEngineClientExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Media.AdEngine.AdEngineClientConfiguration';
    }
}
