/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.Commerce.OrderStatus
 * @desc represents the status of an order
 *
 * @property {String} paymentOk - Order processed successfully and user is given subscription/entitlement. In this case
 * user got charged either full amount or discounted amount.
 * @property {String} temporaryDirectAccess - User is given TDA (Temporary Direct Access) when there was hiccup in one
 * of the services (internal or external) in order processing flow.
 * @property {String} authOk - Order processed successfully and user is given subscription/entitlement (except in case
 * of payment update where there is no subscription/entitlement). AUTH OK could be in case of either free trial or payment update.
 * @property {String} success - Order processed successfully.
 * @property {String} redirect - User needs to be redirected to external sites for payment validation or verification (3DS, IDEAL).

 *
 * @note the service returns values that do not match the SDK keys in the spec
 *
 */
export enum OrderStatus {
    paymentOk = 'PAYMENT_OK',
    temporaryDirectAccess = 'TDA',
    authOk = 'AUTH_OK',
    success = 'SUCCESS',
    redirect = 'REDIRECT'
}

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Commerce.PaymentCardType
 * @desc An enumeration of recognized payment methods.
 * @note These values are returned from the service in UPPERCASE.
 *
 * @property {String} DEBIT
 * @property {String} CREDIT
 *
 */
export enum PaymentCardType {
    DEBIT = 'DEBIT',
    CREDIT = 'CREDIT'
}

/**
 *
 * @enum
 * @typedef {Object} SDK.Services.Commerce.PaymentMethodType
 * @desc An enumeration of recognized payment methods.
 * @note These values are returned from the service in PascalCase
 *
 * @property {String} ComcastPayment - Since `9.0.0`.
 * @property {String} DisneyRewards - Since `9.0.0`.
 * @property {String} IDealPayment
 * @property {String} KlarnaPayment - Since `9.0.0`.
 * @property {String} MercadoPagoPayment
 * @property {String} PaymentCard
 * @property {String} PayPalExpress
 *
 */
export enum PaymentMethodType {
    ComcastPayment = 'ComcastPayment',
    DisneyRewards = 'DisneyRewards',
    IDealPayment = 'IDealPayment',
    KlarnaPayment = 'KlarnaPayment',
    MercadoPagoPayment = 'MercadoPagoPayment',
    PaymentCard = 'PaymentCard',
    PayPalExpress = 'PayPalExpress'
}

/**
 *
 * @enum
 * @since 10.0.0
 * @typedef {Object} SDK.Services.Commerce.PaymentType
 * @desc An enumeration of payment method endpoint paths.
 *
 * @property {String} comcastPayments
 * @property {String} idealPayments
 * @property {String} klarnaPayments
 * @property {String} mercadopagoPayments
 * @property {String} paypalExpress
 * @property {String} paymentCards
 *
 */
export enum PaymentType {
    comcastPayments = 'comcast-payments',
    idealPayments = 'ideal-payments',
    klarnaPayments = 'klarna-payments',
    mercadopagoPayments = 'mercadopago-payments',
    paypalExpress = 'paypal-express',
    paymentCards = 'payment-cards'
}

/**
 *
 * @enum
 * @since 4.7.0
 * @typedef {Object} SDK.Services.Commerce.UpdateOrderType
 * @desc An enumeration of order types used by v2 order service
 *
 * @property {String} scaSuccess
 * @property {String} iDealSuccess
 * @property {String} redirectSuccess
 *
 */
export enum UpdateOrderType {
    scaSuccess = 'SCA_SUCCESS',
    iDealSuccess = 'IDEAL_SUCCESS',
    redirectSuccess = 'REDIRECT_SUCCESS'
}
