/**
 *
 * @module temporaryDefaultAccessActivationStatus
 *
 */

/**
 *
 * @access public
 * @since 4.2.0
 * @typedef {Object} SDK.Services.ExternalActivation.TemporaryDefaultAccessActivationStatus
 * @desc Represents the activation status of temporary default access.
 *
 * @property {String} activationSucceeded
 * @property {String} activationRetryingWithTda
 * @property {String} activationRetryingWithoutTda
 * @property {String} activationFailedWithTda
 * @property {String} activationFailedWithoutTda
 *
 */

enum TemporaryDefaultAccessActivationStatus {
    activationSucceeded = 'ACTIVATION_SUCCEEDED',
    activationRetryingWithTda = 'ACTIVATION_RETRYING_WITH_TDA',
    activationRetryingWithoutTda = 'ACTIVATION_RETRYING_WITHOUT_TDA',
    activationFailedWithTda = 'ACTIVATION_FAILED_WITH_TDA',
    activationFailedWithoutTda = 'ACTIVATION_FAILED_WITHOUT_TDA'
}

export default TemporaryDefaultAccessActivationStatus;
