/**
 *
 * @module dustClientPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import DustDevicePayload from './dustDevicePayload';
import DustApplicationPayload from './dustApplicationPayload';
import DustSdkPayload from './dustSdkPayload';
import SessionInfo from '../../session/sessionInfo';

import uuidv4 from '../../util/uuidv4';

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc describes the client payload which contains all non-server related data
 *
 */
export default class DustClientPayload {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    public category: string;

    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Dust.DustDevicePayload}
     *
     */
    public device: DustDevicePayload;

    /**
     *
     * @access private
     * @type {Object}
     *
     */
    public data: TodoAny;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public event: string;

    /**
     *
     * @access private
     * @type {String}
     * @desc RFC4122 UUID used to identify this payload
     * @note typecheck is not needed for this because it's generated directly and not passed into the constructor
     *
     */
    public eventId: string;

    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Dust.DustApplicationPayload}
     *
     */
    public application: DustApplicationPayload;

    /**
     *
     * @access private
     * @type {SDK.Services.Internal.Dust.DustSdkPayload}
     *
     */
    public sdk: DustSdkPayload;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @note the JS SDK does not currently support this property
     *
     */
    public transactionId?: string;

    /**
     *
     * @access private
     * @type {Object|undefined}
     * @note the JS SDK does not currently support this property
     *
     */
    public correlationIds?: unknown;

    /**
     *
     * @access private
     * @type {String}
     * @note ISO 8601/RFC-3339 timestamp
     *
     */
    public timestamp?: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The schema version of the data event if the event supports versioning.
     * @note Omit from serialization if this value is null.
     *
     */
    public version?: string;

    /**
     *
     * @access private
     * @since 20.1.0
     * @type {String|undefined}
     * @desc version of data in event
     *
     */
    public dataVersion?: string;

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.category
     * @param {SDK.Services.Internal.Dust.DustDevicePayload} options.device
     * @param {Object} [options.data={}]
     * @param {String} options.event
     * @param {SDK.Services.Internal.Dust.DustApplicationPayload} options.application
     * @param {SDK.Services.Internal.Dust.DustSdkPayload} options.sdk
     * @param {String} [options.transactionId]
     * @param {Object} [options.correlationIds]
     * @param {String} [options.timestamp=new Date().toISOString()]
     * @param {String|undefined} [options.version]
     * @param {String|undefined} [options.dataVersion]
     * @note `options.data` contains several types of aggregated data such as playbackActivity, startupActivity...
     *
     */
    public constructor(options: {
        category: string;
        device: DustDevicePayload;
        data?: unknown;
        event: string;
        application: DustApplicationPayload;
        sdk: DustSdkPayload;
        transactionId?: string;
        correlationIds?: unknown;
        timestamp?: string;
        version?: string;
        dataVersion?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    category: Types.nonEmptyString,
                    device: Types.instanceStrict(DustDevicePayload),
                    data: Types.object().optional,
                    event: Types.nonEmptyString,
                    application: Types.instanceStrict(DustApplicationPayload),
                    sdk: Types.instanceStrict(DustSdkPayload),
                    transactionId: Types.string.optional,
                    correlationIds: Types.object().optional,
                    timestamp: Types.dateString.optional,
                    version: Types.nonEmptyString.optional,
                    dataVersion: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            category,
            device,
            data = {} as TodoAny,
            event,
            application,
            sdk,
            transactionId,
            correlationIds,
            timestamp = new Date().toISOString(),
            version,
            dataVersion
        } = options;

        this.category = category;
        this.device = device;
        this.data = data;

        // JS SDK takes an array of session experiments, turns them into an object
        // we have to turn them back into an array to pass Qoe validation.
        if (data && data?.experiments) {
            data.experiments = SessionInfo.convertSessionExperimentsToArray(
                data?.experiments
            );
        }

        this.event = event;
        this.eventId = uuidv4();
        this.application = application;
        this.sdk = sdk;
        this.transactionId = transactionId;
        this.correlationIds = correlationIds;
        this.timestamp = timestamp;
        this.version = version;
        this.dataVersion = dataVersion;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustClientPayload';
    }
}
