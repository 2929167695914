/**
 *
 * @module webSocketStates
 *
 */

/**
 *
 * @enum
 * @access public
 * @since 4.9.0
 * @desc Helper enum for mapping between WebSocket enum/name values.
 *
 */
enum WebSocketStates {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3
}

export default WebSocketStates;
