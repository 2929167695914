/**
 *
 * @module ripcutApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/ripcut.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import SessionManager from '../session/sessionManager';
import RipcutClient from '../services/ripcut/ripcutClient';

import { RipcutImageRequest, RipcutImageRequestTypedef } from './typedefs';
import { createInvalidStateException } from '../services/util/errorHandling/createException';

import type AccessTokenProvider from '../token/accessTokenProvider';
import type Logger from '../logging/logger';

/**
 *
 * @access public
 * @since 17.0.0
 * @desc Provides an api for retrieving images from Ripcut.
 *
 */
export default class RipcutApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {RipcutClient}
     *
     */
    private ripcutClient: RipcutClient;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Ripcut.RipcutClient} options.ripcutClient
     * @param {SessionManager} options.sessionManager
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        ripcutClient: RipcutClient;
        sessionManager: SessionManager;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    ripcutClient: Types.instanceStrict(RipcutClient),
                    sessionManager: Types.instanceStrict(SessionManager)
                })
            };

            typecheck(this, params, arguments);
        }

        const { ripcutClient, sessionManager } = options;

        this.ripcutClient = ripcutClient;
        this.sessionManager = sessionManager;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object} options
     * @param {String} [options.version] - Version for the API, example: "v1", which is the default.
     * @param {String} options.imageId - The Id of the requested image.
     * @param {SDK.Ripcut.RipcutImageRequestMode} options.imageRequestMode - Specifies image retrieval option such as
     * compositing or scaling logic to be applied by Ripcut to the returned image.
     * @param {Object<RipcutQueryParams>} [options.queryParams] - Parameters specifying image details such as
     * scaling/formatting/label placement, attached as query params in the request.
     * @desc Returns the result/image from the request to Ripcut and any error also returned.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Ripcut.RipcutResult>} A promise that completes when the operation has succeeded.
     * @note We are deviating from the spec in the naming of `imageRequestMode` and `queryParams`, but the functionality is the same.
     *
     */
    public async getImage(options: RipcutImageRequest) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object(RipcutImageRequestTypedef)
            };

            typecheck(this, 'getImage', params, arguments);
        }

        const { partnerName: partnerId } = await this.sessionManager.getInfo();

        return this.ripcutClient.getImage({ ...options, partnerId });
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object} options
     * @param {String} [options.version] - Version for the API, example: "v1", which is the default.
     * @param {String} options.imageId - The Id of the requested image.
     * @param {SDK.Ripcut.RipcutImageRequestMode} options.imageRequestMode - Specifies image retrieval option such as
     * compositing or scaling logic to be applied by Ripcut to the returned image.
     * @param {Object<RipcutQueryParams>} [options.queryParams] - Parameters specifying image details such as
     * scaling/formatting/label placement, attached as query params in the request.
     * @desc Returns an image url string. This method uses cached values for the SDK configuration and the required partner parameter.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {String}
     *
     * @example <caption>Use the trim endpoint by specifying the width/height, and specifying the imageRequestMode of trim</caption>
     * const queryParams = { width: 110, height: 120, format: 'jpeg' };
     * const url = ripcutApi.getImageUrl({
     *     imageId,
     *     imageRequestMode: RipcutImageRequestMode.trim,
     *     queryParams
     * });
     *
     */
    public getImageUrl(options: RipcutImageRequest) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object(RipcutImageRequestTypedef)
            };

            typecheck(this, 'getImageUrl', params, arguments);
        }

        const sessionInfo = this.sessionManager.storage.sessionInfo;

        if (sessionInfo) {
            const partnerId = sessionInfo.partnerName;

            return this.ripcutClient.getImageUrl({ ...options, partnerId });
        }

        throw createInvalidStateException(
            `${this.toString()} There is no cached session info available.`
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Ripcut.RipcutApi';
    }
}
