/**
 *
 * @module subscriptionStatus
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { SubscriptionStatusSubType, SubscriptionStatusType } from './enums';

/**
 *
 * @desc Represents the status a subscription can have.
 *
 */
export default class SubscriptionStatus {
    /**
     *
     * @access public
     * @type {String<SDK.Services.Subscription.SubscriptionStatusSubType>|undefined}
     * @desc Gets the subtype of the status (optional).
     *
     */
    public subType?: SubscriptionStatusSubType;

    /**
     *
     * @access public
     * @type {String<SDK.Services.Subscription.SubscriptionStatusType>}
     * @desc Gets the type of the status.
     *
     */
    public type: SubscriptionStatusType;

    /**
     *
     * @param {Object} options
     * @param {String} [options.subType]
     * @param {String} options.type
     *
     */
    public constructor(options: {
        subType?: SubscriptionStatusSubType;
        type: SubscriptionStatusType;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    subType: Types.nonEmptyString.optional,
                    type: Types.nonEmptyString
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { subType, type } = options;

        this.subType = subType ? SubscriptionStatusSubType[subType] : undefined;
        this.type = SubscriptionStatusType[type];
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.SubscriptionStatus';
    }
}
