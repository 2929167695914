/**
 *
 * @module createException
 *
 */

import ErrorReason from '../../exception/errorReason';
import ExceptionReference from '../../exception/exceptionReference';
import ServiceException from '../../exception/serviceException';

import { Exception } from '../../exception/exceptionDefinitions';
import { ServerResponse } from '../../providers/typedefs';
import HttpStatus from './httpStatus';
import {
    CONTENT_TYPE,
    RATE_LIMIT_DURATION,
    REQUEST_ID
} from '../../providers/shared/httpHeaderConstants';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Returns an exception based on the supplied parameters.
 * @note Defaults exceptionData to an empty object if it is null or undefined. This will ensure a generic
 * ServiceException is created as a fallback.
 * @returns {ServiceException}
 *
 */
export default function createException(
    errors: Array<ErrorReason>,
    response: ServerResponse,
    exceptionData?: Exception
) {
    const { status, headers } = response;

    const transactionId = headers.get(REQUEST_ID);
    const contentType = headers.get(CONTENT_TYPE);

    let throttleTimeout;

    if (exceptionData === ExceptionReference.common.temporarilyThrottled) {
        const duration = headers.get(RATE_LIMIT_DURATION);

        throttleTimeout = Number(duration);

        if (Number.isNaN(throttleTimeout)) {
            throttleTimeout = undefined;
        }
    }

    if (!exceptionData) {
        const isTransaction = typeof transactionId === 'string';
        const isHtmlContentType =
            typeof contentType === 'string' &&
            contentType.toLowerCase() === 'text/html';

        if (status >= 500) {
            exceptionData = ExceptionReference.common.temporarilyUnavailable;
        } else if (
            status === HttpStatus.FORBIDDEN &&
            !isTransaction &&
            isHtmlContentType
        ) {
            exceptionData = ExceptionReference.common.requestBlocked;
        } else if (status === HttpStatus.OK && errors.length) {
            exceptionData = ExceptionReference.common.unexpectedError;

            const error = errors[0];

            if (typeof error.description === 'string') {
                exceptionData.message = error.description;
            }
        } else {
            exceptionData = ExceptionReference.common.unexpectedError;
        }
    }

    return new ServiceException({
        transactionId: transactionId ?? undefined,
        reasons: errors,
        exceptionData,
        status,
        throttleTimeout
    });
}

/**
 *
 * @access public
 * @since 24.0.0
 * @param {Object} options
 * @param {Object} [options.exceptionData]
 * @param {String} [options.description]
 * @param {String} [options.code]
 * @param {Number} [options.backoffSeconds]
 * @param {Number} [options.data]
 * @returns {SDK.Services.Exception.ServiceException} A new `ServiceException` instance.
 */
export function createSimpleException(options: {
    exceptionData?: Exception;
    description?: string;
    code?: string;
    backoffSeconds?: number;
    data?: Record<string, Record<string, unknown>>;
}) {
    const { exceptionData, description, code, backoffSeconds, data } = options;

    const reasons = [
        new ErrorReason({
            description,
            code,
            backoffSeconds,
            data
        })
    ];
    const exception = new ServiceException({
        reasons,
        exceptionData
    });

    return exception;
}

/**
 *
 * @access public
 * @since 24.0.0
 * @param {String} description
 * @desc A passthrough to `createSimpleException`
 * with `exceptionData` as `ExceptionReference.common.invalidState`
 * @returns {SDK.Services.Exception.ServiceException} A new `ServiceException` instance.
 */
export function createInvalidStateException(description: string) {
    return createSimpleException({
        exceptionData: ExceptionReference.common.invalidState,
        description
    });
}
