/**
 *
 * @module endpoint
 *
 */

import ServiceEndpoint from './services/configuration/serviceEndpoint';

export default class Endpoint extends ServiceEndpoint {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Endpoint';
    }
}
