/**
 *
 * @module ripcutPlugin
 * @desc {SDK.Ripcut.RipcutPlugin} entry point.
 *
 */

import Ripcut from './entry';
import RipcutApi from './ripcutApi';

import RipcutClient from '../services/ripcut/ripcutClient';
import RipcutClientConfiguration from '../services/ripcut/ripcutClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Ripcut', typeof Ripcut> = {
    pluginName: 'Ripcut',
    entry: Ripcut,

    /**
     *
     * @access protected
     * @since 17.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider, managers } = sdkSession;
        const { sessionManager } = managers;

        sdkSession.ripcutApi = new RipcutApi({
            ripcutClient: new RipcutClient({
                config: sdkSession.config.services.ripcut,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            sessionManager,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 17.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.ripcut = new RipcutClientConfiguration({
            baseUrl: services?.ripcut?.client?.baseUrl,
            endpoints: serviceDefinitionsConfiguration.processEndpoints(
                services?.ripcut?.client?.endpoints
            )
        });
    }
};

/**
 *
 * @access public
 * @since 17.0.0
 *
 */
export default plugin;
