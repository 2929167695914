/**
 *
 * @module appendQuerystring
 *
 */

import { Check } from '@dss/type-checking';

/**
 *
 * @param {String} href
 * @param {String} queryString
 * @desc Returns a url with a query string. Handles case where query string already exists.
 * @returns {String}
 *
 */
export default (href: Nullable<string>, queryString: Nullable<string>) => {
    let url = '';

    if (Check.string(href)) {
        url = href;
    }

    if (Check.nonEmptyString(queryString as string)) {
        const prefix = url.includes('?') ? '&' : '?';

        return url.concat(`${prefix}${queryString}`);
    }

    return url;
};
