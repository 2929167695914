/**
 *
 * @module entry
 * @desc {SDK.Services.Commerce} entry point.
 *
 */

import {
    OrderStatus,
    PaymentCardType,
    PaymentMethodType,
    PaymentType,
    UpdateOrderType
} from './enums';

import BrowserInfo from './browserInfo';

/**
 *
 * @typedef {Object} SDK.Services.Commerce
 * @property {BrowserInfo} BrowserInfo
 * @property {SDK.Services.Commerce.OrderStatus} OrderStatus
 * @property {SDK.Services.Commerce.PaymentCardType} PaymentCardType
 * @property {SDK.Services.Commerce.PaymentMethodType} PaymentMethodType
 * @property {SDK.Services.Commerce.PaymentType} PaymentType
 * @property {SDK.Services.Commerce.UpdateOrderType} UpdateOrderType
 *
 */
export default {
    BrowserInfo,
    OrderStatus,
    PaymentCardType,
    PaymentMethodType,
    PaymentType,
    UpdateOrderType
};
