/**
 *
 * @module mercadoApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    MercadoPaymentRequest,
    MercadoPaymentRequestTypedef
} from './typedefs';

import { PaymentRedirectResponse } from '../../services/commerce/typedefs';

import CommerceClient from '../../services/commerce/commerceClient';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';
import { ApiOptions } from '../../typedefs';

import type Logger from '../../logging/logger';
import type AccessTokenProvider from '../../token/accessTokenProvider';

const DustUrn = DustUrnReference.commerce.mercadoApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 4.19.0
 * @desc Provides ability to access mercado pago data.
 *
 */
export default class MercadoApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {CommerceClient}
     *
     */
    private commerceClient: CommerceClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {CommerceClient} options.commerceClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        commerceClient: CommerceClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceClient: Types.instanceStrict(CommerceClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceClient } = options;

        this.commerceClient = commerceClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.19.0
     * @param {Object<SDK.Commerce.Mercado.MercadoPaymentRequest>} request - request Information used to retrieve a paymentMethodId.
     * @desc Completes a Mercado Pago transaction and returns a `paymentMethodId` and `redirectUrl`.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<PaymentRedirectResponse>} paymentMethodId and redirect url.
     *
     */
    public async submitPayment(
        request: MercadoPaymentRequest
    ): Promise<PaymentRedirectResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(MercadoPaymentRequestTypedef)
        }
    })
    public async submitPayment(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request]
        } = apiOptions as ApiOptions<[MercadoPaymentRequest]>;

        return this.commerceClient.submitMercadoPayment(
            request,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.Mercado.MercadoApi';
    }
}
