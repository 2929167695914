/**
 *
 * @module entry
 * @desc {SDK.Content.Search} entry point.
 *
 */

import SearchQuery from './searchQuery';

/**
 *
 * @typedef {Object} SDK.Content.Search
 *
 * @property {SDK.Content.Search.SearchQuery} SearchQuery
 *
 */
export default {
    SearchQuery
};
