import bandwagon from 'btm-bandwagon/bandwagon';
import { Location as getLocation } from 'bam-url/dist/url';
import BroadcastService from './broadcast.service';
import { BroadcastServiceActions } from './actions';

class SyndicatedDispatcherService {
    static instance: SyndicatedDispatcherService;
    private _broadcastService: BroadcastService;

    constructor(broadcastService: BroadcastService) {
        this._broadcastService = broadcastService;
    }

    public static getInstance(broadcastService: BroadcastService) {
        if (!SyndicatedDispatcherService.instance) {
            SyndicatedDispatcherService.instance = new SyndicatedDispatcherService(broadcastService);
        }

        return SyndicatedDispatcherService.instance;
    }

    public initialize() {
        const loc = getLocation();
        const containerId = loc.hash.split('#id=')[1];
        const syndicatedPlayerDispatcher = bandwagon.getInstanceByKey(containerId);

        syndicatedPlayerDispatcher.openChannel(window.parent, containerId);

        syndicatedPlayerDispatcher.one('channel::ready', (config) => {
            (window as any).syndicatedPlayerDispatcher = syndicatedPlayerDispatcher;
    
            this._broadcastService.emit(BroadcastServiceActions.SyndicatedPlayer.ready, {
                config,
                syndicatedPlayerDispatcher
            });
        });
    }
}

export default SyndicatedDispatcherService;