/**
 *
 * @module mediaPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import MediaPayloadStream from './mediaPayloadStream';
import MediaPlayhead from './mediaPlayhead';
import MediaThumbnailLinks from './mediaThumbnailLinks';

/**
 *
 * @desc Represents the media payload response from the playlist service.
 *
 */
export default class MediaPayload {
    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.Media.MediaPayloadStream}
     * @desc Gets the "complete" media stream URL.
     *
     */
    public stream: MediaPayloadStream;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {Object}
     * @desc Gets additional information used to track the media.
     *
     */
    public tracking: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 4.0.0
     * @type {SDK.Services.Media.MediaPlayhead}
     * @desc Gets playhead information from the previous playback session for the current user.
     *
     */
    public playhead: MediaPlayhead;

    /**
     *
     * @access public
     * @since 3.8.0
     * @type {SDK.Services.Media.MediaThumbnailLinks|undefined}
     * @desc Gets thumbnail information for the media.
     *
     */
    public thumbnails?: MediaThumbnailLinks;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Media.MediaPayloadStream} options.stream
     * @param {Object} options.tracking
     * @param {SDK.Services.Media.MediaPlayhead} options.playhead
     * @param {SDK.Services.Media.MediaThumbnailLinks} [options.thumbnails]
     *
     */
    public constructor(options: {
        stream: MediaPayloadStream;
        tracking: Record<string, unknown>;
        playhead: MediaPlayhead;
        thumbnails?: MediaThumbnailLinks;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    stream: Types.instanceStrict(MediaPayloadStream),
                    tracking: Types.object(),
                    playhead: Types.instanceStrict(MediaPlayhead),
                    thumbnails:
                        Types.instanceStrict(MediaThumbnailLinks).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { stream, tracking, playhead, thumbnails } = options;

        this.stream = stream;
        this.tracking = tracking;
        this.playhead = playhead;
        this.thumbnails = thumbnails;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaPayload';
    }
}
