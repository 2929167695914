/**
 *
 * @module socketManager
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/edge.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../logging/logger';
import WebSocketStates from './webSocketStates';
import AuthenticatedMessageProcessor from './messages/authenticatedMessageProcessor';
import FlowControlErrorMessageProcessor from './messages/flowControlErrorMessageProcessor';
import ForwardAllMessagesProcessor from './messages/forwardAllMessagesProcessor';
import PingPongMessageProcessor from './messages/pingPongMessageProcessor';
import OffDeviceTokenRefreshMessageProcessor from './messages/offDeviceTokenRefreshMessageProcessor';
import ReconnectMessageProcessor from './messages/reconnectMessageProcessor';
import RefreshMessageProcessor from './messages/refreshMessageProcessor';
import ReceivedMessageProcessor from './messages/receivedMessageProcessor';
import UnauthenticatedMessageProcessor from './messages/unauthenticatedMessageProcessor';
import AgeVerificationMessageProcessor from './messages/ageVerificationMessageProcessor';
import SocketConnectionState from './socketConnectionState';
import MessageEnvelope from './messageEnvelope';
import SocketEvents from './socketEvents';
import PlatformProviders from '../services/providers/platformProviders';
import InternalEvents from '../internalEvents';

import SocketManagerConfiguration from '../services/configuration/socketManagerConfiguration';
import SocketClientConfiguration from '../services/socket/socketClientConfiguration';
import BootstrapConfiguration from '../services/configuration/bootstrapConfiguration';
import DeviceAttributeProvider from '../device/deviceAttributeProvider';
import DeviceAttributes from '../device/deviceAttributes';

import SocketSchemaUrls from './socketSchemaUrls';
import DustUrnReference from '../services/internal/dust/dustUrnReference';

import TokenManager from '../token/tokenManager';
import AccessToken from '../services/token/accessToken';
import SessionManager from '../session/sessionManager';
import ServiceException from '../services/exception/serviceException';
import ProviderException from '../services/exception/providerException';
import type EnvironmentConfiguration from '../services/providers/browser/environmentConfiguration';
import type RetryPolicy from '../services/configuration/retryPolicy';
import type FlowControlPolicy from './messages/flowControlPolicy';
import type MessageProcessorBase from './messages/messageProcessorBase';

import { RawSocketMessage } from './typedefs';
import EnvelopeMessageQueue from '../internal/envelopeMessageQueue';

import appendQuerystring from '../services/util/appendQuerystring';
import { createSimpleException } from '../services/util/errorHandling/createException';
import uuidv4 from '../services/util/uuidv4';

import { REQUEST_ID } from '../services/providers/shared/httpHeaderConstants';

const SocketUrns = DustUrnReference.socket;

const socketCloseCodes = {
    normalClose: 1000,
    policyViolation: 1008,
    internalError: 1011,
    missingAuthenticationEvent: 4000,
    invalidAuthFrame: 4001
};

const connectingStates = [WebSocketStates.OPEN, WebSocketStates.CONNECTING];

declare global {
    namespace NodeJS {
        interface Global {
            WebSocket: WebSocket;
        }
    }
}

type PossibleWebSocket = Nullable<WebSocket>;
type RawSocketMessageWithData = Event & { data?: string };

/**
 *
 * @access private
 * @since 4.9.1
 * @desc Asserts WebSockets are supported
 * @note Based off Modernizr: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/websockets.js
 *
 */
function assertWebsocketSupport() {
    let supports = false;

    try {
        supports =
            'WebSocket' in global &&
            global.WebSocket.CLOSING === WebSocketStates.CLOSING;
    } catch (e) {
        /* no-op */
    }

    if (!supports) {
        throw new ProviderException(
            'WebSockets are not supported in this environment'
        );
    }
}

/**
 *
 * @access protected
 * @since 4.6.0
 * @desc Manages socket connections between the SDK and Edge Service
 *
 */
export default class SocketManager extends EventEmitter {
    /**
     *
     * @access private
     * @since 4.6.0
     * @type {SDK.Services.Configuration.SocketManagerConfiguration}
     *
     */
    private config: SocketManagerConfiguration;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {SDK.Services.Socket.SocketClientConfiguration}
     * @desc Contains the headers and protocol information necessary for establishing a socket connection.
     * @note The JS SDK normally stores a client instance here but the socket implementation is a special case
     * and we only store the configuration not a fully functioning client.
     *
     */
    private clientConfig: SocketClientConfiguration;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SessionManager}
     *
     */
    private sessionManager: SessionManager;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Services.Configuration.BootstrapConfiguration}
     *
     */
    private bootstrapConfiguration: BootstrapConfiguration;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Device.DeviceAttributeProvider}
     *
     */
    private deviceAttributeProvider: DeviceAttributeProvider;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     *
     */
    private deviceProfile: string;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {WebSocket|null}
     * @desc Holds a reference to the current socket or `null`.
     *
     */
    private socket: Nullable<PossibleWebSocket>;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {WebSocket|null}
     * @desc Stores a reference to the old socket until it can safely be closed.
     *
     */
    public oldSocket: PossibleWebSocket;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {Array<String>}
     * @desc Contains a list of supported protocols.
     *
     */
    private protocolHeaders: Array<string>;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {String}
     * @desc The query string used when constructing the websocket url.
     * @note Will contain common headers required for authenticating the connection.
     *
     */
    private queryString: string;

    /**
     *
     * @access public
     * @since 4.6.0
     * @type {Boolean}
     * @desc A flag to determine if the events at edge feature is enabled.
     *
     */
    public enabled: boolean;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {String}
     * @desc Stores the source used when opening a socket connection.
     *
     */
    public source: string;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {String}
     * @desc The url used when making a socket connection.
     *
     */
    private url: string;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {Array<Number>}
     * @desc A set of codes indicating if the JS SDK should attempt to reconnect in the event of a server-initiated
     * close.
     *
     */
    private reconnectCodes: Array<number>;

    /**
     *
     * @access private
     * @since 4.6.2
     * @type {Array<Number>}
     * @desc A set known error codes.
     *
     */
    private errorCodes: Array<number>;

    /**
     *
     * @access private
     * @since 4.6.2
     * @type {Object}
     * @desc Codes that identify the reason for the socket being closed.
     *
     */
    private closeCodes: Record<string, number>;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {Number}
     * @desc A flag that prevents the JS SDK from making too many unsuccessful connections at one time.
     *
     */
    public retryAttempts: number;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {SDK.Services.Configuration.RetryPolicy}
     * @desc Socket specific retry policy.
     *
     */
    public retryPolicy: RetryPolicy;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {Number}
     * @desc The delay when attempting to open a new socket connection if a previous connection abruptly closes.
     * @note Time in milliseconds.
     *
     */
    public retryDelay: number;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {Number}
     * @desc When the user calls `#start` - how long to wait for a currently `CONNECTING` connection to switch to open before we bail out.
     * @note Time in milliseconds.
     *
     */
    private userStartAttemptTimeout: number;

    /**
     *
     * @access private
     * @since 4.11.0
     * @type {Array<SDK.Socket.FlowControlPolicy>|null}
     * @desc In memory cache for Flow Control Policies for reconnects and retries.
     *
     */
    private policies: Array<FlowControlPolicy> | null;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Socket.PingPongMessageProcessor}
     * @desc Handles pinging the server to actively detect the connection state.
     *
     */
    public pingPongMessageProcessor: PingPongMessageProcessor;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     * @desc Interval in milliseconds to wait before trying to determine whether a reconnection attempt is needed
     * @note 20 seconds is more than enough time to allow for existing reconnection attempts to complete. This value
     * can probably be dropped to as low as 5 seconds if more frequent disconnection alerts are needed.
     *
     */
    private reconnectionTimerInterval: number;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {Array<String>}
     * @desc Current eventIds in the buffer.
     *
     */
    private duplicateEventBuffer: Array<string>;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     * @desc Current ID of the reconnection timer.
     *
     */
    private reconnectionTimerId?: ReturnType<typeof setTimeout>;

    private ignoreConnectionStateChanges = false;

    public envelopeMessageQueue: EnvelopeMessageQueue;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.SocketManagerConfiguration} options.config
     * @param {SDK.Services.Socket.SocketClientConfiguration} options.clientConfig
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SessionManager} options.sessionManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration
     * @param {SDK.Device.DeviceAttributeProvider} options.deviceAttributeProvider
     * @param {String} options.sourceUrn
     *
     */
    public constructor(options: {
        config: SocketManagerConfiguration;
        clientConfig: SocketClientConfiguration;
        tokenManager: TokenManager;
        sessionManager: SessionManager;
        logger: Logger;
        environmentConfiguration: EnvironmentConfiguration;
        bootstrapConfiguration: BootstrapConfiguration;
        deviceAttributeProvider: DeviceAttributeProvider;
        sourceUrn: string;
        envelopeMessageQueue: EnvelopeMessageQueue;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(SocketManagerConfiguration),
                    clientConfig: Types.instanceStrict(
                        SocketClientConfiguration
                    ),
                    tokenManager: Types.instanceStrict(TokenManager),
                    sessionManager: Types.instanceStrict(SessionManager),
                    logger: Types.instanceStrict(Logger),
                    environmentConfiguration: Types.instanceStrict(
                        PlatformProviders.EnvironmentConfiguration
                    ),
                    bootstrapConfiguration: Types.instanceStrict(
                        BootstrapConfiguration
                    ),
                    deviceAttributeProvider: Types.instanceStrict(
                        DeviceAttributeProvider
                    ),
                    sourceUrn: Types.nonEmptyString,
                    envelopeMessageQueue:
                        Types.instanceStrict(EnvelopeMessageQueue)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            config,
            clientConfig,
            tokenManager,
            sessionManager,
            logger,
            environmentConfiguration,
            bootstrapConfiguration,
            deviceAttributeProvider,
            sourceUrn,
            envelopeMessageQueue
        } = options;
        const { deviceProfile } = environmentConfiguration;

        this.config = config;
        this.clientConfig = clientConfig;
        this.tokenManager = tokenManager;
        this.sessionManager = sessionManager;
        this.bootstrapConfiguration = bootstrapConfiguration;
        this.deviceAttributeProvider = deviceAttributeProvider;
        this.deviceProfile = deviceProfile;
        this.logger = logger;
        this.socket = null;
        this.oldSocket = null;
        this.protocolHeaders = [];
        this.queryString = '';
        this.enabled = !this.config.disabled;
        this.source = sourceUrn;
        this.url = '';
        this.reconnectCodes = [
            socketCloseCodes.policyViolation,
            socketCloseCodes.internalError,
            socketCloseCodes.missingAuthenticationEvent,
            socketCloseCodes.invalidAuthFrame
        ];
        this.errorCodes = [1013];
        this.closeCodes = {
            CLIENT_REQUESTED: socketCloseCodes.normalClose
        };
        this.retryAttempts = 0;
        this.retryPolicy = this.config.extras.retryPolicy;
        this.retryDelay = this.retryPolicy.retryBasePeriod * 1000;
        this.init = this.init.bind(this);
        this.userStartAttemptTimeout = 4000;
        this.policies = null;
        this.envelopeMessageQueue = envelopeMessageQueue;
        this.pingPongMessageProcessor = new PingPongMessageProcessor({
            socketManager: this,
            config,
            logger
        });
        this.reconnectionTimerInterval = 20000;
        this.reconnectionTimerId = undefined;
        this.duplicateEventBuffer = [];

        this.tokenManager.on(
            InternalEvents.TokenRefreshFailed,
            (tokenRefreshFailed) => {
                // Avoid disabling sockets when we still have a valid anonymous token state
                if (tokenRefreshFailed?.hasResetToAnonymousToken) {
                    return;
                }

                this.enabled = false;
            }
        );

        this.tokenManager.on(InternalEvents.AccessChanged, () => {
            this.enabled = !this.config.disabled;
        });

        this.tokenManager.on(InternalEvents.DeviceRegistered, () => {
            // SDKs should only send JWT tokens in
            // SessionAuthentication events for the same
            // deviceId/partner/sdk-source as used to open the connection.
            // Start a new connection if the device is registered.
            this.reconnect();
        });

        this.sessionManager.storage.on(
            InternalEvents.SessionInfoChanged,
            async (sessionInfoChangedEvent) => {
                const accessToken = this.accessToken?.token || '';
                const sessionId = sessionInfoChangedEvent.newSessionInfo?.id;

                if (sessionId) {
                    await this.sendSessionAuthentication({
                        sessionId,
                        accessToken
                    });
                }
            }
        );

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.6.0
     * @param {SDK.Services.Token.AccessToken} token
     * @note region should come from the `x-bamtech-region` header when a token is obtained for the first time - the
     * token can be anonymous
     * @returns {Void}
     *
     */
    public async init(token: AccessToken) {
        if (!this.enabled) {
            this.emitConnectionState(SocketConnectionState.disabled);

            return;
        }

        try {
            /* istanbul ignore else */
            if (__SDK_TYPECHECK__) {
                const params = {
                    token: Types.instanceStrict(AccessToken)
                };

                typecheck(this, 'init', params, arguments);
            }

            assertWebsocketSupport();

            const { protocolHeaders, supportedProtocols, headers } =
                this.clientConfig.extras;

            // Set when the socket is opened
            delete headers[REQUEST_ID];

            this.protocolHeaders = Object.keys(protocolHeaders)
                .filter((key) => supportedProtocols.includes(key))
                .map((key) => protocolHeaders[key]);

            this.queryString = Object.keys(headers)
                .map((key) => `${key}=${headers[key]}`)
                .join('&');

            // TEST-ONLY: Adds ability to test failure messages...
            // this.queryString += '&dss-pool=chaos';

            this.setUrl(token.region as string);

            this.logger.info('SocketManager', 'Initiated.');

            this.emit(SocketEvents.message, {
                origin: 'init'
            });
        } catch (ex) {
            this.logger.error('SocketManager', ex);

            this.emit(SocketEvents.exception, {
                origin: 'init',
                data: {
                    error: ex
                }
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Set up a timer that will start the reconnection process if the socket gets closed
     * @returns {Void}
     *
     */
    private checkIfReconnectionNeeded() {
        this.reconnectionTimerId = setTimeout(() => {
            if (this.isSocketInReadyState(WebSocketStates.CLOSED)) {
                this.beginReconnecting();
            }

            this.checkIfReconnectionNeeded();
        }, this.reconnectionTimerInterval);
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {Number<WebSocketStates>} state
     * @desc Determines if there is a socket and if it has the provided `WebSocketStates`
     * @returns {Boolean} Returns true if the socket's `readyState` matches the passed in `WebSocketStates`
     *
     */
    public isSocketInReadyState(state: WebSocketStates) {
        return this.socket?.readyState === state;
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows clients to start up a websocket connection manually
     * @throws {SDK.Services.Exception.ServiceException}
     * @returns {Promise<Void>} Resolves if the socket was OPEN when operation was completed, or it will `reject` if not opened properly.
     *
     */
    public async start() {
        const { logger } = this;
        const ctor = this.toString();

        if (!this.enabled) {
            let errorMessage =
                'SDK Sockets are disabled until a valid AccessToken is acquired.';

            if (this.config.disabled) {
                errorMessage = 'SDK Sockets are disabled in the SDK config.';
            }

            logger.error(ctor, errorMessage);

            throw new ServiceException(errorMessage);
        }

        let hasFailedDueToTimeout = false;

        this.checkIfReconnectionNeeded();

        /**
         *
         * @desc async function that attempts to wait for a socket to open and only resolves
         * when it has, completes if a we surpass the abort timer
         * @returns {Promise<Boolean>} returns true if the socket is connected, false if in a state other than connected or timed out waiting for it to get connected.
         *
         */
        const tryWaitForSocketToOpen = () => {
            return new Promise<string | undefined>((resolve, reject) => {
                let abortTimeoutId: ReturnType<typeof setTimeout>;

                /**
                 *
                 * @param {String} errorMessage - The `errorMessage` to create a `ServiceException`
                 * with and `reject` the parent promise - otherwise `resolve` the `Promise`.
                 * @desc Clears out the timer reference, rejects with an exception or resolves on success.
                 *
                 */
                function finalizeWithResult(errorMessage?: string) {
                    clearTimeout(abortTimeoutId);

                    if (errorMessage) {
                        logger.error(ctor, errorMessage);

                        const error = new ServiceException(errorMessage);

                        reject(error);
                    } else {
                        resolve(undefined);
                    }
                }

                // avoid an infinite timer loop
                if (hasFailedDueToTimeout) {
                    return;
                }

                if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
                    logger.info(ctor, 'SDK Sockets already connected.');

                    finalizeWithResult();

                    return;
                }

                if (this.isSocketInReadyState(WebSocketStates.CLOSING)) {
                    finalizeWithResult('SDK Sockets closing.');

                    return;
                }

                if (this.isSocketInReadyState(WebSocketStates.CLOSED)) {
                    finalizeWithResult('SDK Sockets closed.');

                    return;
                }

                // @note Wait for 100 ms to see if the socket opened. This should be
                // enough time for things to happen, but not to slow as to
                // stall the result.
                setTimeout(async () => {
                    try {
                        const result = await tryWaitForSocketToOpen();

                        finalizeWithResult(result);
                    } catch (ex) {
                        reject(ex);
                    }
                }, 100);

                // Just in case the socket doesn't switch to OPEN within a
                // reasonable time - we'll not get stuck waiting forever
                // and blowing up our call stack.
                abortTimeoutId = setTimeout(() => {
                    const isSocketInReadyState = this.isSocketInReadyState(
                        WebSocketStates.OPEN
                    );

                    if (isSocketInReadyState) {
                        return;
                    }

                    hasFailedDueToTimeout = true;

                    finalizeWithResult(
                        'SDK Sockets timed out waiting for current connection to open'
                    );
                }, this.userStartAttemptTimeout);
            });
        };

        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            logger.info(ctor, 'SDK Sockets already connected.');

            return undefined;
        }

        if (this.isSocketInReadyState(WebSocketStates.CONNECTING)) {
            logger.log(ctor, 'SDK Sockets Still connecting...');

            return tryWaitForSocketToOpen();
        }

        return this.openSocket();
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Allows clients to stop a websocket connection manually
     * @returns {Boolean} Returns true if there was an open socket that was closed. False for other reasons such as there was no socket or it is in a state that cannot be closed such as already CLOSED or CLOSING.
     *
     */
    public stop() {
        if (this.reconnectionTimerId) {
            clearInterval(this.reconnectionTimerId);
        }
        this.reconnectionTimerId = undefined;
        this.policies = null;
        this.duplicateEventBuffer = [];

        this.pingPongMessageProcessor.stop();

        return this.closeSocket(this.socket);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {Object} [options]
     * @param {Number} [options.pingInterval]
     * @param {Number} [options.pongTimeout]
     * @param {Number} [options.pingMaxAttempts]
     * @desc Enables pinging the socket server
     * @returns {Void}
     *
     */
    public enablePing(options?: {
        pingInterval?: number;
        pongTimeout?: number;
        pingMaxAttempts?: number;
    }) {
        this.pingPongMessageProcessor.enable(options);
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @desc Disables pinging the socket server
     * @returns {Void}
     *
     */
    public disablePing() {
        this.pingPongMessageProcessor.disable();
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Starts pinging the socket server
     *
     */
    public startPing() {
        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            this.pingPongMessageProcessor.start();
        }
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Stops pinging the socket server
     *
     */
    public stopPing() {
        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            this.pingPongMessageProcessor.stop();
        }
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @param {String} region
     * @desc sets or resets the base url for the web socket connection
     * @note must be called before calling `this.openSocket` for the first time and any time a reconnection is requested
     *
     */
    private setUrl(region: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                region: Types.nonEmptyString
            };

            typecheck(this, 'setUrl', params, arguments);
        }

        const key = this.clientConfig.extras.regionalEndpointMapping[region];
        const href = this.clientConfig.endpoints[key]?.href || '';

        this.url = appendQuerystring(href, this.queryString);

        this.emit(SocketEvents.message, {
            origin: 'setUrl',
            data: {
                url: this.url
            }
        });
    }

    /**
     *
     * @access public
     * @since 4.18.0
     * @param {String} [region]
     * @desc starts the reconnection process
     *
     */
    public async reconnect(region?: string) {
        this.ignoreConnectionStateChanges = true;

        // verify if we have a fresh token otherwise we'll try to
        // connect the new socket - it'll fail with expired token
        // and we'll have to refresh before reconnecting again this
        // check can possibly save an extra socket connection attempt
        await this.tokenManager.refreshAccessToken();

        if (Check.assigned(region)) {
            this.setUrl(region as string);
        } else {
            this.closeExistingSocket();
        }

        try {
            this.retryAttempts = 0;
            this.beginReconnecting();
        } finally {
            this.ignoreConnectionStateChanges = false;
        }
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc starts the reconnection process
     *
     */
    public beginReconnecting() {
        this.logger.info(this.toString(), 'Beginning reconnection attempt');

        this.retryAttempts++;
        this.retryDelay *= this.retryPolicy.retryMultiplier;

        if (this.retryDelay > this.retryPolicy.retryMaxPeriod * 1000) {
            this.retryDelay = this.retryPolicy.retryMaxPeriod * 1000;
        }

        setTimeout(async () => {
            try {
                await this.openSocket();
            } catch (ex) {
                this.logger.error(this.toString(), 'Failed to open socket');
            }
        }, this.retryDelay);
    }

    /**
     *
     * @access private
     * @param {Object} messageData
     * @since 4.6.0
     * @desc checks for an authentication event
     *
     */
    private isAuthenticated(messageData: RawSocketMessage) {
        return messageData.type === SocketUrns.socketManager.authenticated;
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @param {Event} messageEvent
     * @desc handles errors from the socket - most likely these only occur when connecting/disconnecting
     *
     */
    private onError(messageEvent: RawSocketMessageWithData) {
        const messageData = this.parseMessage(messageEvent);

        this.logger.error('SocketManager', messageData);

        this.emit(SocketEvents.exception, {
            origin: 'onError',
            data: messageData
        });
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @param {CloseEvent} messageEvent
     * @desc handles close events from the socket
     * @note close events are assumed to be caused by an error unless otherwise determined
     *
     */
    private onClose(messageEvent: CloseEvent) {
        const { code, wasClean, reason, type, timeStamp, isTrusted } =
            messageEvent;

        let messageType = SocketEvents.message;
        let error;

        let isReconnecting = false;

        this.logger.info('SocketManager', messageEvent);

        if (this.reconnectCodes.includes(code) && this.canRetry()) {
            this.pingPongMessageProcessor.stop();
            isReconnecting = true;

            this.beginReconnecting();
        } else if (this.errorCodes.includes(code)) {
            messageType = SocketEvents.exception;

            error = createSimpleException({
                code: String(code),
                description: reason
            });
        }

        // Only emit the closed event when we are not reconnecting
        if (
            !isReconnecting &&
            this.isSocketInReadyState(WebSocketStates.CLOSED)
        ) {
            this.emitConnectionState(SocketConnectionState.closed);
        }

        this.emit(messageType, {
            origin: 'onClose',
            data: {
                code,
                wasClean,
                reason,
                type,
                timeStamp,
                isTrusted,
                error
            }
        });
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @desc determines if the JS SDK can continue to retry making a socket connection
     * @returns {Boolean}
     *
     */
    private canRetry() {
        return this.retryAttempts < this.retryPolicy.retryMaxAttempts;
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @param {RawSocketMessageWithData} messageEvent
     * @desc safely attempts to parse `messageEvent.data` which should be a serialized JSON object
     * @returns {Object} parsed message data
     *
     */
    private parseMessage(messageEvent: RawSocketMessageWithData) {
        const parsedMessage = { data: {} };

        this.tryParseMessage(messageEvent, parsedMessage);

        return parsedMessage.data;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {MessageEvent} messageEvent
     * @param {Object} parsedMessage - an empty object that this method appends a `data` property.
     * @desc safely attempts to parse `messageEvent.data` which should be a serialized JSON object
     * @returns {Boolean} returns true/false if parsing was successful/not as well as appends the result into the parsedMessage.data argument passed in or an error object if not.
     *
     */
    private tryParseMessage(
        messageEvent: RawSocketMessageWithData,
        parsedMessage: Record<string, unknown>
    ) {
        try {
            parsedMessage.data = JSON.parse(messageEvent.data as string);

            return true;
        } catch (ex) {
            const error = createSimpleException({
                description: ex as string,
                exceptionData: ex as Error
            });

            const data = { error };

            this.emit(SocketEvents.exception, {
                origin: 'parseMessage',
                data
            });

            parsedMessage.data = data;

            return false;
        }
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @desc sends an authentication frame immediately after opening to authenticate the connection
     *
     */
    private async onOpen() {
        // To avoid this error
        // > Uncaught DOMException: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.
        // Wait for the socket to be finished connecting.
        if (this.isSocketInReadyState(WebSocketStates.CONNECTING)) {
            setTimeout(this.onOpen.bind(this), 100);
        } else {
            // Only copy the specific properties through
            const { id, name, version } =
                this.bootstrapConfiguration.application;
            const application = {
                id,
                name,
                version
            };
            const accessToken = this.accessToken?.token;
            const device = this.getDeviceInfo();

            const socketEvent = new MessageEnvelope({
                eventType: SocketUrns.socketManager.authentication,
                schemaUrl: SocketSchemaUrls.authentication,
                data: {
                    accessToken,
                    application,
                    device
                }
            });

            const messageSent = await this.sendMessage(socketEvent);

            this.logger.info(
                this.toString(),
                'Opening socket - sending authentication frame'
            );

            this.emit(SocketEvents.message, {
                origin: 'onOpen',
                data: messageSent
            });
        }
    }

    /**
     *
     * @access private
     * @returns {Object}
     *
     */
    private getDeviceInfo() {
        const attributes = this.deviceAttributeProvider.getDeviceAttributes();

        const { manufacturer, operatingSystem, operatingSystemVersion } =
            DeviceAttributes.normalizeAttributes(attributes);

        let { model } = attributes;

        model ??= this.deviceProfile;

        return {
            // required
            manufacturer,
            model,
            operatingSystem,
            operatingSystemVersion,

            // optional
            modelFamily: attributes.modelFamily // NOTE: This is currently only populated for Roku. example: '7000x' Others are to send `null`.
        };
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @param {MessageEnvelope} messageEnvelope - the event to send
     * @desc Serializes the data into an Edge service envelope and sends it over the socket connection.
     * @returns {Promise<Object>} Will return an Object representing the full message envelope sent through the socket.
     *
     */
    public async sendMessage(messageEnvelope: MessageEnvelope) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                socketEvent: Types.instanceStrict(MessageEnvelope)
            };

            typecheck(this, 'sendMessage', params, arguments);
        }

        const subject = await this.sessionManager.createMessageSubject();

        const message = messageEnvelope.getSocketMessage({
            source: this.source,
            subject
        });

        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            const tokenAwareMessageEnvelope = {
                message: messageEnvelope,
                accessToken: this.accessToken?.token
            };

            this.envelopeMessageQueue.trackMessage(tokenAwareMessageEnvelope);

            this.sendSocketMessage(message);
        } else {
            this.logger.log(
                this.toString(),
                'Socket is not open yet. Adding this message to the queue'
            );

            this.envelopeMessageQueue.enqueue(messageEnvelope);
        }

        return message;
    }

    /**
     *
     * @access public
     * @since 4.11.1
     * @param {Object} rawEvent
     * @desc will send a raw message to the socket - a raw messages is raw JSON blob that is a result of calling MessageEnvelope#getSocketMessage(...)
     *
     */
    public sendSocketMessage(rawEvent: RawSocketMessage) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                rawEvent: Types.nonEmptyObject
            };

            typecheck(this, 'sendSocketMessage', params, arguments);
        }

        this.socket?.send(JSON.stringify(rawEvent));
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @param {Array<SDK.Socket.FlowControlPolicy>} policies
     * @desc Request a specific flow rate of events from the Event-Edge platform
     * @returns {Promise<Void>}
     *
     */
    public async requestFlowControl(policies: Array<FlowControlPolicy>) {
        this.policies = policies;

        await this.sendFlowControl();
    }

    /**
     *
     * @access public
     * @since 4.6.0
     * @desc closes the old socket connection
     * @returns {Boolean} Returns true if the socket is in a state that can be closed, false if in a state that cannot be closed such as already CLOSED or CLOSING.
     *
     */
    public closeExistingSocket() {
        const { oldSocket, logger } = this;

        logger.info('SocketManager', 'closeExistingSocket');

        this.oldSocket = null;

        return this.closeSocket(oldSocket);
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {WebSocket} socket - the socket to be closed if it is in a closable state.
     * @desc closes the specified socket connection
     * @returns {Boolean} Returns true if the socket is in a state that can be closed, false if in a state that cannot be closed such as already CLOSED or CLOSING.
     *
     */
    private closeSocket(possibleSocket: PossibleWebSocket) {
        this.logger.log(this.toString(), 'closeSocket');

        if (typeof WebSocket !== 'undefined') {
            if (Check.instanceStrict(possibleSocket, WebSocket)) {
                const socket = possibleSocket as WebSocket;

                if (connectingStates.includes(socket.readyState)) {
                    this.logger.log(
                        this.toString(),
                        `closing with readyState being: ${
                            WebSocketStates[socket.readyState]
                        }`
                    );
                    socket.close(this.closeCodes.CLIENT_REQUESTED);

                    return true;
                }
            }
        }

        this.emit(SocketEvents.message, {
            origin: 'closeSocket',
            data: {
                readyState: possibleSocket?.readyState
            }
        });

        return false;
    }

    /**
     *
     * @access protected
     * @since 4.12.0
     * @desc sends all events that were waiting for the socket connection to open
     *
     */
    public async flushAwaitingConnectionBuffer() {
        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            if (this.envelopeMessageQueue.hasMessagesToSend) {
                this.logger.info(
                    this.toString(),
                    `Socket is open. Sending ${this.envelopeMessageQueue.messageLength} messages that were waiting in the queue now.`
                );
            }

            const subject = await this.sessionManager.createMessageSubject();

            while (this.envelopeMessageQueue.hasMessagesToSend) {
                const messageEnvelope = this.envelopeMessageQueue.dequeue();

                if (messageEnvelope) {
                    const rawEvent = messageEnvelope.getSocketMessage({
                        source: this.source
                    });

                    // There are some disconnected states where a subject cannot be generated
                    // so if it is missing, we tack it on right before sending.
                    if (rawEvent) {
                        rawEvent.subject ??= subject;
                    }

                    this.sendSocketMessage(rawEvent);
                }
            }
        }
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param  {String<SDK.Socket.SocketConnectionState>} socketConnectionState
     * @desc Will emit the `connectionStateChanged` if not in the transition of a socket reconnection workflow.
     * @emits {SDK.Socket.SocketEvents.connectionStateChanged} Connection state changed event
     *
     */
    public emitConnectionState(socketConnectionState: SocketConnectionState) {
        if (this.ignoreConnectionStateChanges) {
            return;
        }

        this.emit(SocketEvents.connectionStateChanged, socketConnectionState);
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @returns {Number} The current `socket.readyState` or if there is no socket `WebSocketStates.CLOSED`
     *
     */
    public get currentSocketReadyState() {
        return this.socket?.readyState ?? WebSocketStates.CLOSED;
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @note binding is necessary due to the callbacks being called within the context of the websocket
     * @note X-Request-Id is unique to each socket connection
     * @returns {Promise<Void>}
     *
     */
    private openSocket() {
        return new Promise((resolve, reject) => {
            const { url, protocolHeaders, pingPongMessageProcessor } = this;
            let hasFirstMessageProcessed = false;

            /**
             *
             * @desc When the auth frame completes this resolves the promise before the fall-back rejection code can run.
             *
             */
            function preResolveResolve() {
                hasFirstMessageProcessed = true;
                resolve(undefined);
            }

            const authenticatedMessageProcessor =
                new AuthenticatedMessageProcessor(this, preResolveResolve);
            const reconnectMessageProcessor = new ReconnectMessageProcessor(
                this
            );
            const refreshMessageProcessor = new RefreshMessageProcessor(this);
            const receivedMessageProcessor = new ReceivedMessageProcessor(this);
            const forwardAllMessagesProcessor = new ForwardAllMessagesProcessor(
                this
            );
            const flowControlErrorMessagesProcessor =
                new FlowControlErrorMessageProcessor(this);
            const offDeviceTokenRefreshMessageProcessor =
                new OffDeviceTokenRefreshMessageProcessor({
                    socketManager: this,
                    tokenManager: this.tokenManager,
                    logger: this.logger
                });
            const ageVerificationMessageProcessor =
                new AgeVerificationMessageProcessor(this);
            const unauthenticatedMessageProcessor =
                new UnauthenticatedMessageProcessor({
                    socketManager: this,
                    tokenManager: this.tokenManager,
                    logger: this.logger
                });

            const messageProcessors = [
                authenticatedMessageProcessor,
                receivedMessageProcessor,
                unauthenticatedMessageProcessor,
                reconnectMessageProcessor,
                refreshMessageProcessor,
                forwardAllMessagesProcessor,
                flowControlErrorMessagesProcessor,
                pingPongMessageProcessor,
                offDeviceTokenRefreshMessageProcessor,
                ageVerificationMessageProcessor
            ];

            this.emitConnectionState(SocketConnectionState.connecting);

            this.oldSocket = this.socket;
            this.socket = new WebSocket(
                `${url}&${REQUEST_ID}=${uuidv4()}`,
                protocolHeaders
            );
            this.socket.onmessage = async (
                messageEvent: RawSocketMessageWithData
            ) => {
                await this.processSocketMessage(
                    messageProcessors,
                    messageEvent
                );

                // First message coming through should be the auth frame
                // we don't want to attempt the below logic for all future messages
                // but just the first time through.
                if (!hasFirstMessageProcessed) {
                    hasFirstMessageProcessed = true;

                    // the AuthenticatedMessageProcessor should call resolve for us so this reject
                    // won't do anything - but we call it here just in case the first time through.
                    const error = new ServiceException(
                        'Authentication frame not received'
                    );

                    // If the first message is not `authenticated` then expose this to the client to help diagnose the issue
                    this.emit(SocketEvents.exception, {
                        origin: 'openSocket',
                        data: {
                            info: 'Expected auth frame, but received',
                            actual: messageEvent
                        }
                    });

                    reject(error);
                }
            };
            this.socket.onopen = this.onOpen.bind(this);
            this.socket.onclose = this.onClose.bind(this);
            this.socket.onerror = (message: RawSocketMessageWithData) => {
                // NOTE: the first error will reject the promise (good) - but future
                // errors will still be reported through the onError emitter...
                reject(message);

                this.onError.call(this, message);
            };

            this.emit(SocketEvents.message, {
                origin: 'openSocket',
                data: {
                    url,
                    protocolHeaders
                }
            });
        });
    }

    /**
     * @access private
     * @param {Array<MessageProcessorBase>} messageProcessors
     * @param {Object} messageEvent
     * @desc Will run the message through each messageProcessor
     * @returns {Promise<Void>}
     *
     */
    private async processSocketMessage(
        messageProcessors: Array<MessageProcessorBase>,
        messageEvent: RawSocketMessageWithData
    ) {
        const { duplicateEventBuffer } = this;
        const parsedMessage = {};
        const messageDeduplicationStoreSize =
            this.config.extras.messageDeduplicationStoreSize;

        if (this.tryParseMessage(messageEvent, parsedMessage)) {
            const messageData = (parsedMessage as TodoAny).data;
            const eventId = messageData.id;

            if (eventId && duplicateEventBuffer.includes(eventId)) {
                this.logger.info(
                    this.toString(),
                    `Message not processed by SDK due to duplicate event ${messageEvent}`
                );

                return;
            }

            // TODO: we should find a way to log socket messages
            // but for now - doing this triggers the logging infrastructure to modify the message which we don't want.
            // this.logger.info('SocketManager', JSON.stringify(messageData));

            let wasMessageProcessed = false;

            const workload = messageProcessors.map(async (messageProcessor) => {
                try {
                    if (messageProcessor.is(messageData)) {
                        await messageProcessor.process(messageData);

                        if (eventId) {
                            if (
                                duplicateEventBuffer.length >=
                                (messageDeduplicationStoreSize ?? 0)
                            ) {
                                duplicateEventBuffer.shift();
                            }

                            duplicateEventBuffer.push(eventId);
                        }

                        wasMessageProcessed = true;
                    }
                } catch (ex) {
                    this.logger.error(
                        this.toString(),
                        `Failed to process message, ${messageEvent.data}, ${ex}`
                    );
                }
            });

            await Promise.all(workload);

            if (!wasMessageProcessed) {
                this.logger.warn(
                    this.toString(),
                    `Message not processed by SDK ${messageEvent.data}`
                );
            }

            this.emit(SocketEvents.message, {
                origin: 'onMessage',
                data: messageData
            });
        } else {
            this.logger.error(
                this.toString(),
                `Failed to parse message: ${messageEvent.data}`
            );
        }
    }

    /**
     *
     * @access protected
     * @since 4.11.0
     * @desc send the flow control policies if any.
     * @returns {Promise<Void>}
     *
     */
    public async sendFlowControl() {
        const { policies } = this;

        if (policies && this.isSocketInReadyState(WebSocketStates.OPEN)) {
            const messageEnvelope = new MessageEnvelope({
                eventType: SocketUrns.socketManager.flowControl,
                schemaUrl: SocketSchemaUrls.flowControl,
                data: {
                    policies
                }
            });

            await this.sendMessage(messageEnvelope);
        }
    }

    /**
     *
     * @access protected
     * @since 29.0.0
     * @param {Object} options
     * @param {String} options.sessionId
     * @param {String} options.accessToken
     * @desc Sends the sessionAuthentication frame.
     * @returns {Promise<Void>}
     *
     */
    public async sendSessionAuthentication(options: {
        sessionId: string;
        accessToken: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    sessionId: Types.nonEmptyString,
                    accessToken: Types.nonEmptyString
                })
            };

            typecheck(this, 'sendSessionAuthentication', params, arguments);
        }

        const sessionAuthenticationSocketEvent = new MessageEnvelope({
            eventType: SocketUrns.socketManager.sessionAuthentication,
            schemaUrl: SocketSchemaUrls.sessionAuthentication,
            data: options
        });

        await this.sendMessage(sessionAuthenticationSocketEvent);
    }

    /**
     *
     * @access protected
     * @since 29.0.0
     * @param {Object} options
     * @param {MessageEnvelope} options.message
     * @param {String} options.accessToken
     * @desc Sends EdgeEvent for Dust over Sockets
     * @returns {Promise<Void>}
     *
     */
    public async sendMessageWithSessionAuthentication(options: {
        message: MessageEnvelope;
        accessToken: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    message: Types.instanceStrict(MessageEnvelope),
                    accessToken: Types.nonEmptyString
                })
            };

            typecheck(
                this,
                'sendMessageWithSessionAuthentication',
                params,
                arguments
            );
        }
        //
        // Per the spec:
        //     > an acknowledgement has been received with a status of `rejected.envelope-subject-invalid`,
        //     > indicating an unrecognized `sessionId` and `profileId` in an event's subject. The SDK should
        //     > send this event with the access token associated with the invalid `sessionId` if possible,
        //     > and then retry the rejected event.
        //
        // Is why we re-send the message after the `sessionAuthenticationSocketEvent`
        //
        // Is why we retrieve a new subject, the current one is innately invalid.
        //
        const { message, accessToken } = options;

        const sessionIdKey = 'sessionId=';

        const newSubject = await this.sessionManager.createMessageSubject();

        const rawMessage = message.getSocketMessage({
            source: this.source,
            subject: newSubject
        });

        const subjects = (rawMessage.subject || '').split(',');

        const sessionIdString = subjects.find((subject) =>
            subject.includes(sessionIdKey)
        );

        const sessionId = (sessionIdString || '').slice(sessionIdKey.length);
        const sessionAuthenticationOptions = { sessionId, accessToken };

        if (this.isSocketInReadyState(WebSocketStates.OPEN)) {
            await this.sendSessionAuthentication(sessionAuthenticationOptions);
            this.sendSocketMessage(rawMessage);
        } else {
            this.once(SocketConnectionState.connected, async () => {
                await this.sendSessionAuthentication(
                    sessionAuthenticationOptions
                );
                this.sendSocketMessage(rawMessage);
            });
        }
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param {Object} options
     * @desc send the exposure event message.
     * @returns {Promise<Void>}
     *
     */
    public async sendExposureExperiment(options: TodoAny) {
        const messageEnvelope = new MessageEnvelope({
            eventType: SocketUrns.socketManager.exposure,
            schemaUrl: SocketSchemaUrls.exposure,
            data: {
                source: this.source,
                ...options
            }
        });

        await this.sendMessage(messageEnvelope);
    }

    /**
     *
     * @access private
     * @since 4.6.0
     * @desc Grabs a fresh AccessToken from the TokenManager instance.
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken();
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    private override toString() {
        return 'SDK.Socket.SocketManager';
    }
}
