/**
 *
 * @module orchestrationClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 4.17.0
 * @typedef {Object} SDK.Services.Orchestration.OrchestrationClientEndpoint
 *
 * @property {String} query - Since `4.17.0`.
 * @property {String} registerDevice - Since `8.0.0`.
 * @property {String} exchangeDeviceGrantForAccessToken - Since `10.0.0`.
 * @property {String} refreshToken - Since `10.0.0`.
 *
 */
enum OrchestrationClientEndpoint {
    query = 'query',
    registerDevice = 'registerDevice',
    exchangeDeviceGrantForAccessToken = 'exchangeDeviceGrantForAccessToken',
    refreshToken = 'refreshToken'
}

export default OrchestrationClientEndpoint;
