/**
 *
 * @module authCookieProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import AccessToken from '../services/token/accessToken';
import LogTransaction from '../logging/logTransaction';
import type MediaManager from './mediaManager';

/**
 *
 * @since 3.1.0
 * @desc Defines the contract for providing a key required for decrypting media.
 * @note Facade for {SDK.Media.MediaManager}
 *
 */
export default class AuthCookieProvider {
    /**
     *
     * @access private
     * @type {SDK.Media.MediaManager}
     *
     */
    public provider: MediaManager;

    /**
     *
     * @param {SDK.Media.MediaManager} provider
     *
     */
    public constructor(provider: MediaManager) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                provider: Types.object({
                    createAuthCookie: Types.function // not importing MediaManager due to circular import reference
                })
            };

            typecheck(this, params, arguments);
        }

        /**
         *
         * @access private
         * @type {SDK.Media.MediaManager}
         *
         */
        this.provider = provider;
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.AccessToken} accessToken - The current access token.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Gets the current encryption key in the form of a cookie via the key service.
     * @note The cookieEnabled flag is mainly used for platforms that need cookies (i.e. LG) and use SILK.
     * @throws {InvalidArgumentException}
     * @returns {Promise<Void>}
     *
     */
    public createCookie(
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'createCookie', params, arguments);
        }

        const cookieEnabled = this.provider.config.extras.cookieEnabled;

        if (cookieEnabled) {
            return this.provider.createAuthCookie(accessToken, logTransaction);
        }

        return Promise.resolve();
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.AuthCookieProvider';
    }
}
