/**
 *
 * @module invoiceClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Invoice.InvoiceClientEndpoint
 *
 * @property {String} getInvoice
 * @property {String} getPaginatedInvoices
 *
 */
enum InvoiceClientEndpoint {
    getInvoice = 'getInvoice',
    getPaginatedInvoices = 'getPaginatedInvoices'
}

export default InvoiceClientEndpoint;
