/**
 *
 * @module presentation
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { ThumbnailPresentationType } from './enums';

/**
 *
 *
 */
export default class Presentation {
    /**
     *
     * @access public
     * @type {String<SDK.Services.Media.ThumbnailPresentationType>}
     * @desc The presentation type.
     * @note It is entirely possible that the service returns a `String` that isn't in our enum.
     *
     */
    public presentationType: ThumbnailPresentationType;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The number of thumbnails available for this presentation.
     *
     */
    public thumbnailCount: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The milliseconds offset in the media file for the presentation which these thumbnails represent.
     *
     */
    public offsetMilliseconds: number;

    /**
     *
     * @access public
     * @type {Array<String>}
     * @desc List of ordered urls to the spritesheets themselves.
     *
     */
    public paths: Array<string>;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Services.Media.ThumbnailPresentationType>} options.presentationType
     * @param {Number} options.thumbnailCount
     * @param {Number} options.offsetMilliseconds
     * @param {Array<String>} options.paths
     *
     */
    public constructor(options: {
        presentationType: ThumbnailPresentationType;
        thumbnailCount: number;
        offsetMilliseconds: number;
        paths: Array<string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    presentationType: Types.nonEmptyString, // Types.in(ThumbnailPresentationType).or.nonEmptyString,
                    thumbnailCount: Types.number,
                    offsetMilliseconds: Types.number,
                    paths: Types.array.of.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { presentationType, thumbnailCount, offsetMilliseconds, paths } =
            options;

        this.presentationType = presentationType;
        this.thumbnailCount = thumbnailCount;
        this.offsetMilliseconds = offsetMilliseconds;
        this.paths = paths;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.Presentation';
    }
}
