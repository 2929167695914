/**
 *
 * @module entry
 * @desc {SDK.Services.Identity.Bam} entry point.
 *
 */

import OneTimePasscodeRequestReason from './oneTimePasscodeRequestReason';

/**
 *
 * @typedef {Object} SDK.Services.Identity.Bam
 *
 * @property {SDK.Services.Identity.Bam.OneTimePasscodeRequestReason} OneTimePasscodeRequestReason
 *
 */
export default {
    OneTimePasscodeRequestReason
};
