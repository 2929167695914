/**
 *
 * @module mediaThumbnailLink
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 *
 */
export default class MediaThumbnailLink {
    /**
     *
     * @access public
     * @type {String}
     * @desc href for a given thumbnail presentation.
     *
     */
    public href: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc The name of the relationship - a HATEOAS header.
     *
     */
    public rel: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc The method used to fetch the thumbnail presentation - should always be GET.
     *
     */
    public method: string;

    /**
     *
     * @access public
     * @type {Object|undefined}
     * @desc Custom headers for this thumbnail service - will be merged with headers from the SDK config.
     *
     */
    public headers?: Record<string, string>;

    /**
     *
     * @param {Object} options
     * @param {String} options.href
     * @param {String} options.rel
     * @param {String} options.method
     * @param {Object} [options.headers]
     *
     */
    public constructor(options: {
        href: string;
        rel: string;
        method: string;
        headers?: Record<string, string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    href: Types.nonEmptyString,
                    rel: Types.nonEmptyString,
                    method: Types.nonEmptyString,
                    headers: Types.object().optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { href, rel, method, headers } = options;

        this.href = href;
        this.rel = rel;
        this.method = method;
        this.headers = headers;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.MediaThumbnailLink';
    }
}
