/**
 *
 * @module circularReplacer
 *
 */

/**
 *
 * @desc Returns a replacer function for JSON.stringify that avoids circular dependency.
 * @returns {Function}
 *
 * @example
 * const data = {};
 * data.circularData = data;
 *
 * JSON.stringify(data, circularReplacer());
 *
 */
export default () => {
    const seen = new WeakSet();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return undefined;
            }
            seen.add(value);
        }

        return value;
    };
};
