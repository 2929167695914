/**
 *
 * @module diagnosticFeature
 *
 */

/**
 *
 * @enum
 * @since 4.11.0
 * @typedef {Object} SDK.DiagnosticFeature
 * @desc Specifies the feature that will have its events validated.
 *
 * @property {String} glimpseValidation - Since `4.11.0` - Should only be used for debugging purposes; causes
 * Glimpse events to be validated. When enabled, validation errors will be surfaced through the logging system and
 * the events will not be recorded for analytics purposes.
 * @property {String} qoeValidation - Since `14.0.0` - Should only be used for debugging purposes; causes
 * QoE events to be validated. When enabled, validation errors will be surfaced through the logging system
 * and the events will not be recorded for analytics purposes.
 *
 * @example
 * DiagnosticFeature.glimpseValidation // 'glimpseValidation'
 *
 */
enum DiagnosticFeature {
    glimpseValidation = 'glimpseValidation',
    qoeValidation = 'qoeValidation'
}

export default DiagnosticFeature;
