/**
 *
 * @module telemetryClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/telemetry.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../../logging/logger';
import handleServiceResponse from '../../util/handleServiceResponse';
import replaceHeaders from '../../util/replaceHeaders';

import TelemetryClientEndpoint from './telemetryClientEndpoint';
import TelemetryClientConfiguration from './telemetryClientConfiguration';
import TelemetryPayload from './telemetryPayload';
import TelemetryResponse from './telemetryResponse';
import HoraValidatedTelemetryResponse from './horaValidatedTelemetryResponse';
import DustLogUtility from '../dust/dustLogUtility';
import DustUrnReference from '../dust/dustUrnReference';
import CoreHttpClientProvider from '../../providers/shared/coreHttpClientProvider';
import LogTransaction from '../../../logging/logTransaction';
import AccessToken from '../../token/accessToken';
import HttpHeaders from '../../providers/shared/httpHeaders';
import { IEndpoint, ServerResponse } from '../../providers/typedefs';
import ClientBase from '../../clientBase';
import {
    RawSocketMessage,
    RawSocketMessageTypedef,
    SocketMessageAcknowledgment
} from '../../../socket/typedefs';

import {
    EVENT_TRANSPORT_TIMESTAMP,
    HORA_PROXY,
    REPLY_AFTER,
    REQUEST_ID
} from '../../providers/shared/httpHeaderConstants';

const TelemetryClientDustUrnReference =
    DustUrnReference.services.internal.telemetry.telemetryClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access telemetry services.
 *
 */
export default class TelemetryClient extends ClientBase<TelemetryClientConfiguration> {
    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Internal.Telemetry.TelemetryClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: TelemetryClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(TelemetryClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.telemetryPayloads - The collection of
     * @param {Boolean} options.useProxy - Internal proxy option for event validation.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * TelemetryPayload(s) to send.
     * @desc Posts an event collection to the telemetry service.
     * @returns {Promise<SDK.Services.Internal.Telemetry.TelemetryResponse>} Optional information and/or instructions
     * about the telemetry service. TelemetryResponse could also be HoraValidatedTelemetryResponse when useProxy is true.
     *
     */
    public postEvents(
        options: {
            accessToken: AccessToken;
            telemetryPayloads: Array<TelemetryPayload>;
            useProxy: boolean;
        },
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    telemetryPayloads:
                        Types.array.of.instanceStrict(TelemetryPayload),
                    useProxy: Types.boolean
                }),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'postEvents', params, arguments);
        }

        const { accessToken, telemetryPayloads, useProxy } = options;

        const { logger } = this;

        const endpointKey = TelemetryClientEndpoint.postEvent;

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            body: telemetryPayloads,
            data: {
                useProxy
            }
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: TelemetryClientDustUrnReference.postEvents,
            payload,
            endpointKey,
            logTransaction
        });

        logger.info(
            this.toString(),
            `Posting telemetry events: Count: ${telemetryPayloads.length}`
        );

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                if (useProxy) {
                    return this.createHoraValidatedTelemetryResponse(
                        response,
                        telemetryPayloads
                    );
                }

                return this.createTelemetryResponse(response);
            }
        });
    }

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.telemetryPayloads - The collection of
     * TelemetryPayload(s) to send.
     * @param {Boolean} options.useProxy - Internal proxy option for event validation.
     * @desc Posts a dust event collection to the telemetry service.
     * @note do NOT post dustEvents to the dustSink
     * @returns {Promise<SDK.Services.Internal.Telemetry.TelemetryResponse>} Optional information and/or instructions
     * about the telemetry service. TelemetryResponse could also be HoraValidatedTelemetryResponse when useProxy is true.
     *
     */
    public postDustEvents(options: {
        accessToken: AccessToken;
        telemetryPayloads: Array<TelemetryPayload>;
        useProxy: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    telemetryPayloads:
                        Types.array.of.instanceStrict(TelemetryPayload),
                    useProxy: Types.boolean
                })
            };

            typecheck(this, 'postDustEvents', params, arguments);
        }

        const { accessToken, telemetryPayloads, useProxy } = options;

        const { logger } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.dustEvent,
            body: telemetryPayloads,
            data: {
                useProxy
            }
        });

        logger.info(
            this.toString(),
            `Posting dust events: Count: ${telemetryPayloads.length}`
        );

        return super.request({
            payload,
            resultMapper: (response) => {
                if (useProxy) {
                    return this.createHoraValidatedTelemetryResponse(
                        response,
                        telemetryPayloads
                    );
                }

                return this.createTelemetryResponse(response);
            }
        });
    }

    /**
     *
     * @access protected
     * @since 28.0.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {Array<SDK.Socket.RawSocketMessage>} options.messageEnvelopes - The collection of TelemetryPayload(s) to send.
     * @param {Boolean} options.useProxy - Internal proxy option for event validation.
     * @desc Posts a dust event collection to the telemetry service.
     * @note do NOT post dustEvents to the dustSink
     * @returns {Promise<SDK.Services.Internal.Telemetry.TelemetryResponse>} Optional information and/or instructions
     * about the telemetry service. `TelemetryResponse` could also be `HoraValidatedTelemetryResponse` when `useProxy` is true.
     *
     */
    public postDustEnvelopes(options: {
        accessToken: AccessToken;
        messageEnvelopes: Array<RawSocketMessage>;
        useProxy: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    messageEnvelopes: Types.array.of.object(
                        RawSocketMessageTypedef
                    ),
                    useProxy: Types.boolean
                })
            };

            typecheck(this, 'postDustEnvelopes', params, arguments);
        }

        const { accessToken, messageEnvelopes, useProxy } = options;

        const { logger } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.envelopeEvent,
            body: messageEnvelopes,
            data: {
                useProxy
            }
        });

        logger.info(
            this.toString(),
            `Posting dust envelopes: Count: ${messageEnvelopes.length}`
        );

        return super.request({
            payload,
            skipHandleServiceResponse: true,
            resultMapper: async (response) => {
                return this.mapMessageEnvelopeResult<
                    Array<SocketMessageAcknowledgment>
                >(response);
            }
        });
    }

    // /**
    //  *
    //  * @access protected
    //  * @since 28.0.0
    //  * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
    //  * @param {Array<SDK.Socket.RawSocketMessage>} messageEnvelopes - The collection of
    //  * TelemetryPayload(s) to validate.
    //  * @desc Validates a QoE event collection to the telemetry service.
    //  * @returns {Promise<SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse>}
    //  *
    //  */
    // public validateDustEnvelopes(
    //     accessToken: AccessToken,
    //     messageEnvelopes: Array<RawSocketMessage>
    // ) {
    //     /* istanbul ignore else */
    //     if (__SDK_TYPECHECK__) {
    //         const params = {
    //             accessToken: Types.instanceStrict(AccessToken),
    //             messageEnvelopes: Types.array.of.object(RawSocketMessageTypedef)
    //         };

    //         typecheck(this, 'validateDustEnvelopes', params, arguments);
    //     }

    //     const { logger } = this;

    //     const payload = this.getPayload({
    //         accessToken,
    //         endpointKey: TelemetryClientEndpoint.validateDustEnvelope,
    //         body: messageEnvelopes
    //     });

    //     logger.info(
    //         this.toString(),
    //         `Validating Dust Envelopes: Count: ${messageEnvelopes.length}`
    //     );

    //     return super.request({
    //         payload,
    //         skipHandleServiceResponse: true,
    //         resultMapper: async (response) => {
    //             return this.mapMessageEnvelopeResult(response);
    //         }
    //     });
    // }

    /**
     *
     * @access protected
     * @since 14.0.0
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} telemetryPayloads - The collection of
     * TelemetryPayload(s) to validate.
     * @desc Validates a QoE event collection to the telemetry service.
     * @returns {Promise<SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse>}
     *
     */
    public horaEventValidation(
        accessToken: AccessToken,
        telemetryPayloads: Array<TelemetryPayload>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                accessToken: Types.instanceStrict(AccessToken),
                telemetryPayloads:
                    Types.array.of.instanceStrict(TelemetryPayload)
            };

            typecheck(this, 'horaEventValidation', params, arguments);
        }

        const { logger } = this;

        const payload = this.getPayload({
            accessToken,
            endpointKey: TelemetryClientEndpoint.validateQoeEvent,
            body: telemetryPayloads
        });

        logger.info(
            this.toString(),
            `Validating QoE events: Count: ${telemetryPayloads.length}`
        );

        return super.request({
            payload,
            skipHandleServiceResponse: true,
            resultMapper: async (response) => {
                return this.mapResult(response, telemetryPayloads);
            }
        });
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} response - Http Response
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} [telemetryPayloads]
     * @desc Creates a new HoraValidatedTelemetryResponse object
     * @returns {SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse}
     *
     */
    private createHoraValidatedTelemetryResponse(
        response: ServerResponse,
        telemetryPayloads?: Array<TelemetryPayload>
    ) {
        const { headers, data } = response;

        const replyAfter = this.getReplyAfter(headers);
        const requestId = this.getRequestId(headers);

        const dustClientPayload = telemetryPayloads?.[0]?.client;

        const { event } = dustClientPayload || {};
        const { playbackActivity, startupActivity } =
            dustClientPayload?.data || {};

        const horaValidatedTelemetryResponse =
            new HoraValidatedTelemetryResponse({
                replyAfter,
                requestId,
                playbackActivity,
                startupActivity,
                event,
                // turn into array to follow convention by ValidatedTelemetryResponse.results
                results: [data]
            });

        return horaValidatedTelemetryResponse;
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} response - Http Response
     * @desc Creates a new TelemetryResponse object
     * @returns {SDK.Services.Internal.Telemetry.TelemetryResponse}
     *
     */
    private createTelemetryResponse(response: ServerResponse) {
        const { headers } = response;

        const replyAfter = this.getReplyAfter(headers);
        const requestId = this.getRequestId(headers);

        return new TelemetryResponse({ replyAfter, requestId });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Internal.Telemetry.TelemetryClientEndpoint} options.endpointKey
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} options.body
     * @param {Object} [options.data={}]
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: TelemetryClientEndpoint;
        body: Array<TelemetryPayload | RawSocketMessage>;
        data?: TodoAny;
    }) {
        const { accessToken, endpointKey, body, data = {} } = options;

        const { useProxy } = data;
        const { href, headers, optionalHeaders, method } = this.config
            .endpoints[endpointKey] as IEndpoint;

        const requestBody = JSON.stringify(body);

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                },
                [EVENT_TRANSPORT_TIMESTAMP]: () => {
                    return { replacer: '{time}', value: new Date() };
                },
                [HORA_PROXY]: () => {
                    return { value: useProxy };
                }
            },
            headers,
            optionalHeaders
        );

        return {
            url: href,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     * @access private
     * @since 16.0.0
     * @param {Object} headers - Http Headers
     * @desc Gets the request Id from a given response header
     * @note Indicates a unique tracking identifier
     * @returns {String}
     *
     */
    private getRequestId(headers: Headers) {
        return headers.get(REQUEST_ID) ?? undefined;
    }

    /**
     * @access private
     * @since 16.0.0
     * @param {Object} headers - Http Headers
     * @desc Gets the reply after from a given response header
     * @note Indicates the buffer should retry some action after a defined amount of time
     * @returns {Number}
     *
     */
    private getReplyAfter(headers: Headers) {
        return Number(headers.get(REPLY_AFTER));
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @param {ServerResponse} response - The response to map.
     * @param {Array<SDK.Services.Internal.Telemetry.TelemetryPayload>} telemetryPayloads - The collection of
     * TelemetryPayload(s) to validate.
     * @desc A helper to map response results.
     * @returns {SDK.Services.Internal.Telemetry.HoraValidatedTelemetryResponse}
     *
     */
    private async mapResult(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response: ServerResponse<any>,
        telemetryPayload?: Array<TelemetryPayload>
    ) {
        const isAcceptedResponse =
            response.status <= 400 &&
            response.headers.get('content-type') === 'application/json';

        if (!isAcceptedResponse) {
            await handleServiceResponse({ response });
        }

        return this.createHoraValidatedTelemetryResponse(
            response,
            telemetryPayload
        );
    }

    /**
     *
     * @access private
     * @since 28.0.0
     * @param response - The response to map.
     * @returns {T}
     *
     */
    private async mapMessageEnvelopeResult<T>(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response: ServerResponse<T>
    ) {
        const isAcceptedResponse =
            response.status <= 400 &&
            response.headers.get('content-type') === 'application/json';

        if (!isAcceptedResponse) {
            await handleServiceResponse({ response });
        }

        return response.data;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.TelemetryClient';
    }
}
