/**
 *
 * @module entry
 * @desc {SDK.Token} entry point.
 *
 */

import GeoLocation from './geoLocation';
import Providers from '../providers/platformProviders';

import AccessContextState from './accessContextState';
import Access from './access';
import AccessTokenUpdater from './accessTokenUpdater';
import AccessToken from './accessToken';
import AccessState from './accessState';
import AccessStateData from './accessStateData';
import TokenRefreshFailure from './tokenRefreshFailure';

/**
 *
 * @typedef {Object} SDK.Token
 *
 * @property {GeoLocation} GeoLocation
 * @property {GeoProvider} GeoProvider
 *
 * @property {TokenRefreshFailure} TokenRefreshFailure
 * @property {AccessContextState} AccessContextState
 * @property {Access} Access
 * @property {AccessTokenUpdater} AccessTokenUpdater
 * @property {AccessToken} AccessToken
 * @property {GeoProvider} GeoProvider
 * @property {GeoLocation} GeoLocation
 * @property {AccessState} AccessState
 * @property {AccessStateData} AccessStateData
 *
 */
export default {
    GeoLocation,
    get GeoProvider() {
        return Providers.GeoProvider;
    },
    AccessContextState,
    Access,
    AccessTokenUpdater,
    AccessToken,
    AccessState,
    AccessStateData,
    TokenRefreshFailure
};
