/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';
import PlatformProfileReference from './platformProfileReference';

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.CdnFallbackConfiguration
 * @since 20.0.0
 * @property {Boolean} isEnabled
 * @property {Number} defaultTimeout
 * @property {Number} fallbackLimit
 *
 */
export interface CdnFallbackConfiguration {
    isEnabled: boolean;
    defaultTimeout: number;
    fallbackLimit: number;
}

/**
 *
 * @access private
 *
 */
export const CdnFallbackConfigurationTypedef = {
    isEnabled: Types.boolean,
    defaultTimeout: Types.number,
    fallbackLimit: Types.number
};

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.CdnFallbackConfiguration
 * @since 20.0.0
 * @property {Number} minimumBatchSize
 */
export interface PlaybackSnapshotEventConfiguration {
    minimumBatchSize: number;
}

/**
 *
 * @access private
 *
 */
export const PlaybackSnapshotEventConfigurationTypedef = {
    minimumBatchSize: Types.number
};

/**
 *
 * @access private
 * @typedef {Object} SDK.Services.Configuration.Application
 * @since 21.0.0
 * @property {String} version
 * @property {String} id
 * @property {String} name
 */
export interface Application {
    version: string;
    id: string;
    name: string;
}

/**
 *
 * @access private
 *
 */
export const ApplicationTypedef = {
    version: Types.nonEmptyString,
    id: Types.nonEmptyString,
    name: Types.nonEmptyString
};

/**
 *
 * @access public
 * @since 23.0.0
 * @property {Object} commonHeaders - Collection of headers to be used for all service requests.
 * @property {Object} commonValues - Collection of values to be used for all service requests.
 * @property {Object} services
 * @property {SDK.Services.Configuration.Application} application
 * @desc Defines the root configuration object for the SDK configuration payload.
 *
 */
export type SdkConfigRoot = {
    commonHeaders: Record<string, string>;
    commonValues?: Record<string, unknown>;
    services: Record<string, unknown>;
    application: Application;
};

/**
 *
 * @access public
 * @since 28.0.0
 * @property {String} deviceProfile - The device profile to use for the configuration.
 * @property {String} applicationRuntime - The application runtime to use for the configuration. `generic` is a special value that will use one of the known generic configurations.
 * @property {String} deviceRuntimeProfile - The device runtime profile to use for the configuration.
 * @property {String} platform - The platform to use for the configuration.
 *
 */
export type PlatformProfile = {
    deviceProfile: string;
    applicationRuntime: 'generic' | keyof typeof PlatformProfileReference;
    deviceRuntimeProfile: string;
    platform: string;
};

/**
 *
 * @access private
 *
 */
export const PlatformProfileTypedef = {
    deviceProfile: Types.nonEmptyString,
    applicationRuntime: Types.nonEmptyString,
    deviceRuntimeProfile: Types.nonEmptyString,
    platform: Types.nonEmptyString
};

/**
 *
 * @access public
 * @since 28.0.0
 * @property {Boolean} [useMemoryFirst] - Whether or not to front load any storage mechanism with memory cache.
 * @property {Boolean} [useStorageCompression] - Whether or not to use compression when storing the configuration in local storage.
 * @property {Object} [configExtensions] - Extra values to be provided to by the platform for customizing SDK config loading functionality.
 * @property {Function} [configExtensions.postConfig] - A function that will be called after the configuration is loaded allowing an app team to late bound hack the config (not ideal, but put in as last ditch hack if needed).
 * @property {PlatformProfile} [configExtensions.platformOverride] - A platform profile to override the default or derived platform profile.
 * @property {Object} [configExtensions.configTokenReplacements] - A set of values that the SDK will use to token replace within a generic configuration.
 * @property {string} configExtensions.configTokenReplacements.platformId - The platform id to use for token replacement.
 *
 */
export type BootstrapConfigurationExtras = {
    useMemoryFirst?: boolean;
    useStorageCompression?: boolean;
    configExtensions?: {
        postConfig?: (config: SdkConfigRoot) => SdkConfigRoot;
        platformOverride?: PlatformProfile;
        configTokenReplacements?: {
            platformId: string;
        };
    };
};

/**
 *
 * @access private
 *
 */
export const BootstrapConfigurationExtrasTypedef = {
    useMemoryFirst: Types.boolean.optional,
    useStorageCompression: Types.boolean.optional,
    configExtensions: Types.object({
        postConfig: Types.function.optional,
        platformOverride: Types.object(PlatformProfileTypedef).optional,
        configTokenReplacements: Types.object({
            platformId: Types.nonEmptyString
        }).optional
    }).optional
};

/**
 *
 * @access public
 * @since 28.0.0
 * @property {Number} [maxSize]
 *
 */
export type UnacknowledgedEventBuffer = {
    maxSize?: number;
};

/**
 *
 * @access private
 *
 */
export const UnacknowledgedEventBufferTypedef = {
    maxSize: Types.number.optional
};
