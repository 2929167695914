/**
 *
 * @module playReadyMessage
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc Encapsulates the message sent to the PlayReady license service.
 *
 */
export default class PlayReadyMessage {
    /**
     *
     * @access private
     * @type {Object}
     * @desc The body sent to the getPlayReadyLicense endpoint
     *
     */
    public body: Record<string, unknown>;

    /**
     *
     * @access private
     * @type {Object}
     * @desc Map of headers sent to the getPlayReadyLicense endpoint
     *
     */
    private headers: Record<string, string>;

    /**
     *
     * @param {Object} [body={}]
     * @param {Object} [headers={}]
     *
     */
    public constructor(
        body?: Record<string, unknown>,
        headers?: Record<string, string>
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                body: Types.object().optional,
                headers: Types.object().optional
            };

            typecheck(this, params, arguments);
        }
        this.body = body || {};
        this.headers = headers || {};
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Drm.PlayReadyMessage';
    }
}
