/**
 *
 * @module bamHlsPlayerAdapter
 * @desc PlayerAdapter for bam-hls devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/API.md#drm-configuration
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/production-drm/demo/index.html
 * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/development/API.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import BamHlsErrorMapping from '../bamHlsErrorMapping';

import PublicEvents from '../../events';
import Logger from '../../logging/logger';
import PlayerAdapter from '../playerAdapter';
import PlaybackMetrics from '../playbackMetrics';
import PlaybackEventListener from '../playbackEventListener';
import Playlist from '../playlist';
import DrmProvider from '../../drm/drmProvider';

import PlaybackVariant from '../../services/media/playbackVariant';

import { DrmType, PlaylistType } from '../../services/media/enums';

import {
    FetchStatus,
    PlaybackExitedCause,
    PlaybackPausedCause,
    PlaybackResumedCause
} from '../../services/qualityOfService/enums';

import ServerRequest from '../../services/qualityOfService/serverRequest';

import circularReplacer from '../../services/util/circularReplacer';
import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import { AUTHORIZATION } from '../../services/providers/shared/httpHeaderConstants';
import {
    createSimpleException,
    createInvalidStateException
} from '../../services/util/errorHandling/createException';

import EventListenerProvider from '../eventListenerProvider';

type Events = Record<string, string>;
type States = Record<string, number>;
type DataTypes = Record<string, string>;
type PlaybackStates = Record<string, string>;

type HlsStream = {
    Events: Events;
    States: States;
    DataTypes: DataTypes;
    PlaybackStates: PlaybackStates;
};

/**
 *
 * @desc Interface used to communicate with the media player.
 * @note BAM internal web player adapter
 *
 */
export default class BamHlsPlayerAdapter extends PlayerAdapter<BamHlsNativePlayer> {
    /**
     *
     * @access public
     * @type {Array<SDK.Drm.DrmProvider>}
     * @desc Set of DRM providers
     *
     */
    public drmProviders: Array<DrmProvider>;

    /**
     *
     * @access private
     * @type {HlsStream}
     *
     */
    private hlsStatic: HlsStream;

    /**
     *
     * @access private
     * @type {HlsStream.Events}
     * @note Events enum
     *
     */
    private Events: Events;

    /**
     *
     * @access private
     * @type {HlsStream.States}
     * @note States Enum
     *
     */
    private States: States;

    /**
     *
     * @access private
     * @since 4.6.0
     * @type {HlsStream.PlaybackStates}
     * @note States Enum
     *
     */
    private PlaybackStates: PlaybackStates;

    /**
     *
     * @access private
     * @type {HlsStream.DataTypes}
     * @note DataTypes Enum
     *
     */
    private DataTypes: DataTypes;

    /**
     *
     * @access public
     * @type {SDK.Logging.Logger}
     *
     */
    public override logger: Logger;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {Boolean}
     * @desc Indicates when player is buffering.
     *
     */
    private isBuffering: boolean;

    /**
     *
     * @access private
     * @since 5.0.0
     * @type {Boolean}
     * @desc Indicates when application is backgrounded or foregrounded.
     *
     */
    private isApplicationBackgrounded: boolean;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Function}
     * @desc Keeps a reference to the PLAYBACK_ENDED event bound to the PlayerAdapter's 'this'
     *
     */
    private boundPlaybackEndedEvent: TodoAny;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer An instance of the web-based bam-video-players
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The BamHlsPlayerAdapter requires nativePlayer?.on && nativePlayer?.off
     *
     */
    public constructor(options: {
        nativePlayer: BamHlsNativePlayer;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.object({
                        on: Types.function
                    })
                })
            };

            typecheck(this, params, arguments);
        }

        this.drmProviders = [];
        this.hlsStatic = this.nativePlayer?.getClass() as HlsStream;
        this.Events = this.hlsStatic.Events;
        this.States = this.hlsStatic.States;
        this.PlaybackStates = this.hlsStatic.PlaybackStates;
        this.DataTypes = this.hlsStatic.DataTypes;
        this.logger = Logger.instance;
        this.isBuffering = false;
        this.isApplicationBackgrounded = false;
        this.offHandlerRemovalList = [];
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    public override setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer?.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        return new Promise<void>((resolve) => {
            this.playlistUri = playlist.streamUri;

            return resolve();
        });
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    public setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 5.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    public setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        super.clean();

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];
        this.removeListener(listener);

        this.boundHandlers = {};
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls STREAM_ERROR event
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, Events } = this;

        const {
            playbackStartedEvent,
            playbackEndedEvent,
            playbackPausedEvent,
            playbackResumedEvent,
            playbackInitializedEvent,
            playbackReadyEvent,
            bitrateChangedEvent,
            rebufferingStartedEvent,
            rebufferingEndedEvent,
            subtitleChangedEvent,
            multivariantPlaylistFetchedEvent,
            variantPlaylistFetchedEvent,
            audioChangedEvent,
            onStreamError
        } = this;

        const {
            PLAYBACK_PLAYING,
            PLAYBACK_ENDED,
            PLAYBACK_PAUSED,
            STREAM_STATE_CHANGED,
            STREAM_PLAYBACK,
            STREAM_CANPLAYTHROUGH,
            STREAM_VARIANTS_CHANGED,
            STREAM_BUFFERING_STARTED,
            STREAM_BUFFERING_FINISHED,
            STREAM_CURRENTSUBTITLERENDITION_CHANGED,
            MANIFEST_LOADED,
            VARIANT_UPDATED,
            STREAM_CURRENTAUDIOTRACK_CHANGED,
            STREAM_ERROR
        } = Events;

        if (nativePlayer && nativePlayer.on) {
            this.listener = listener;
            this.eventListenerProvider = new EventListenerProvider({
                onHandler: nativePlayer.on,
                offHandler: nativePlayer.off,
                logger: this.logger
            });

            this.eventListenerProvider.addEventHandler(
                this,
                PLAYBACK_PLAYING,
                playbackStartedEvent
            );
            this.boundPlaybackEndedEvent =
                this.eventListenerProvider.addEventHandler(
                    this,
                    PLAYBACK_ENDED,
                    playbackEndedEvent
                );
            this.eventListenerProvider.addEventHandler(
                this,
                PLAYBACK_PAUSED,
                playbackPausedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_STATE_CHANGED,
                playbackResumedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_PLAYBACK,
                playbackInitializedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_CANPLAYTHROUGH,
                playbackReadyEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_VARIANTS_CHANGED,
                bitrateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_BUFFERING_STARTED,
                rebufferingStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_BUFFERING_FINISHED,
                rebufferingEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_CURRENTSUBTITLERENDITION_CHANGED,
                subtitleChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_CURRENTAUDIOTRACK_CHANGED,
                audioChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MANIFEST_LOADED,
                multivariantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                VARIANT_UPDATED,
                variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_ERROR,
                onStreamError
            );
        } else {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access protected
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    public async setDrmProviders(drmProviders: Array<DrmProvider>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer, setDrmConfiguration } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await (
            nativePlayer as BamHlsNativePlayer
        ).getDrmCapabilities();

        const { keySystems } = drmCapabilities;

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            if (
                !keySystems?.[drmProviders[drmProvidersIndex]?.type]?.supported
            ) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        (nativePlayer as BamHlsNativePlayer).drmConfiguration =
            setDrmConfiguration.bind(this)();
    }

    /**
     *
     * @access protected
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        const currentPlayhead = this.nativePlayer?.currentTime;

        let currentBitrate;
        let currentBitrateAvg;
        let currentBitratePeak;
        let currentThroughput;

        if (Check.not.number(currentPlayhead)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer playhead data`;

            this.logger.warn(this.toString(), errorMsg);
        }

        if (Check.not.function(this.nativePlayer?.getNetworkMetrics)) {
            const errorMsg = `${this.toString()}.getPlaybackMetrics() unable to get NativePlayer bitrate data`;

            this.logger.warn(this.toString(), errorMsg);
        } else {
            const metrics = (
                this.nativePlayer as BamHlsNativePlayer
            ).getNetworkMetrics();

            currentBitrate = metrics.chosenBitrate / 1000; // converting to Kbps
            currentBitrateAvg = metrics.chosenBitrate;
            currentBitratePeak = metrics.chosenMaxBitrate;
            currentThroughput = metrics.throughput;
        }

        return new PlaybackMetrics({
            currentBitrate,
            currentPlayhead,
            currentBitrateAvg,
            currentBitratePeak,
            currentThroughput
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    public override getCdnName() {
        return 'null';
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    private constructErrorCaseFromBamHlsError(exception: {
        cause: keyof typeof BamHlsErrorMapping;
    }) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;

        const { code, exceptionData } = errorCode || {};

        return createSimpleException({ code, exceptionData });
    }

    /**
     *
     * @access private
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    private setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        (nativePlayer as BamHlsNativePlayer).xhrSetupCallback = (
            xhr: XMLHttpRequest,
            url: string,
            type: keyof typeof DataTypes
        ) => {
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader(AUTHORIZATION, accessToken as string);
                } else if (isAdEngineCall && ssess) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    private setDrmConfiguration() {
        const { drmProviders } = this;

        const keySystems = [];

        for (const drmProvider of drmProviders) {
            const keySystem = drmProvider.type;
            const licenseRequestUri = drmProvider.licenseRequestUri;

            keySystems.push({
                keySystem,
                licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                /**
                 *
                 * @note bam-hls calls `Promise.resolve(this._keySystemConfig.serverCertificate)`
                 * where serverCertificate is a reference to this function, in EME.js.
                 * We need to use an IIFE here so we setup a promise since they are not executing this function
                 * only expecting a Promise(cert), Promise(undefined), cert or undefined
                 *
                 */
                serverCertificate: ((innerKeySystem) => {
                    return () => {
                        if (innerKeySystem === DrmType.FAIRPLAY) {
                            // @ts-expect-error Property 'getFairPlayCertificate' does not exist on type 'DrmProvider'.
                            return drmProvider.getFairPlayCertificate();
                        }

                        if (innerKeySystem === DrmType.WIDEVINE) {
                            // @ts-expect-error Property 'getWidevineCertificate' does not exist on type 'DrmProvider'.
                            return drmProvider.getWidevineCertificate();
                        }

                        // bam-hls expects undefined so they don't attempt to process this as a certificate
                        return Promise.resolve(undefined);
                    };
                })(keySystem)
            });
        }

        return {
            keySystems
        };
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the PLAYBACK_PLAYING event
     *
     */
    private playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the PLAYBACK_PAUSED event
     *
     */
    private playbackPausedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        let cause;

        if (this.isApplicationBackgrounded) {
            cause = PlaybackPausedCause.applicationBackgrounded;
        } else if (this.isBuffering) {
            cause = PlaybackPausedCause.stall;
        } else {
            cause = PlaybackPausedCause.user;
        }

        this.onPlaybackPaused({
            cause,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_STATE_CHANGED event
     *
     */
    private playbackResumedEvent() {
        const { nativePlayer, States, PlaybackStates } = this;
        const playbackMetrics = this.getPlaybackMetrics();

        if (
            nativePlayer?.state === States.PLAYBACK &&
            nativePlayer.playbackState === PlaybackStates.PLAYING
        ) {
            const cause = PlaybackResumedCause.user;

            this.onPlaybackResumed({
                cause,
                playheadPosition: playbackMetrics.currentPlayhead
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_PLAYBACK event
     *
     */
    private playbackInitializedEvent() {
        const { nativePlayer, playlistUri: streamUrl } = this;
        const {
            subtitleRenditions = [],
            areSubtitlesEnabled = false,
            currentSubtitleRendition,
            audioTracks = [],
            variants = [],
            currentAudioTrack = 0
        } = nativePlayer as BamHlsNativePlayer;

        const playbackMetrics = this.getPlaybackMetrics();

        const subtitleRendition =
            subtitleRenditions[currentSubtitleRendition] || {};
        const audioRendition = audioTracks[currentAudioTrack] || {};

        const metrics = (
            nativePlayer as BamHlsNativePlayer
        ).getNetworkMetrics();
        const chosenVariant = metrics.chosenVariant;

        const variant = variants[chosenVariant] || ({} as Variant);

        const {
            audioCodec = [] as Variant['audioCodec'],
            videoCodec,
            videoRange,
            frameRate,
            attrs = {} as Variant['attrs']
        } = variant;
        const codec = audioCodec.join() || null;

        const { BANDWIDTH: bandwidth, RESOLUTION: resolution } = attrs;
        const averageBandwidth = attrs['AVERAGE-BANDWIDTH'];
        const frameRateAttr = attrs['FRAME-RATE'];

        let frameRateNumber;

        if (Check.assigned(frameRate)) {
            frameRateNumber = Number(frameRate);
        } else if (Check.assigned(frameRateAttr)) {
            frameRateNumber = Number(frameRateAttr);
        }

        const playbackVariant = new PlaybackVariant({
            bandwidth: Check.assigned(bandwidth) ? Number(bandwidth) : null,
            resolution,
            videoBytes: null,
            maxAudioRenditionBytes: null,
            maxSubtitleRenditionBytes: null,
            audioChannels: null,
            videoRange,
            videoCodec,
            audioType: null,
            audioCodec: codec,
            averageBandwidth: Check.assigned(averageBandwidth)
                ? Number(averageBandwidth)
                : null,
            frameRate: frameRateNumber
        });

        this.onPlaybackInitialized({
            variant: playbackVariant,
            audio: audioRendition,
            subtitle: subtitleRendition,
            areSubtitlesVisible: areSubtitlesEnabled,
            startupBitrate: null,
            startupAverageBitrate: null,
            streamUrl,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_CANPLAYTHROUGH event
     *
     */
    private playbackReadyEvent() {
        // noop
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_VARIANTS_CHANGED event
     * @note bitrateType is not included in the BitrateChangedEventData because there is no way to determine its origin
     * based on how the manifest is parsed.
     *
     */
    private bitrateChangedEvent() {
        const playbackMetrics = this.getPlaybackMetrics();

        this.onBitrateChanged({
            bitrateAvg: playbackMetrics.currentBitrateAvg,
            bitratePeak: playbackMetrics.currentBitratePeak,
            playheadPosition: playbackMetrics.currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {String} [error]
     * @note Handles the PLAYBACK_ENDED and STREAM_ERROR events
     *
     */
    private playbackEndedEvent(error: string) {
        const { nativePlayer } = this;
        const { isEnded } = nativePlayer as BamHlsNativePlayer;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        let cause;

        if (Check.assigned(error)) {
            cause = PlaybackExitedCause.error;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        this.onPlaybackEnded({
            cause,
            playheadPosition: currentPlayhead,
            errorDetail: error
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_BUFFERING_STARTED event
     *
     */
    private rebufferingStartedEvent() {
        this.isBuffering = true;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        this.onRebufferingStarted({
            playheadPosition: currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_BUFFERING_FINISHED event
     *
     */
    private rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackMetrics = this.getPlaybackMetrics();

        const { currentPlayhead } = playbackMetrics;

        this.onRebufferingEnded({
            playheadPosition: currentPlayhead
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_CURRENTSUBTITLERENDITION_CHANGED event
     *
     */
    private subtitleChangedEvent() {
        this.onSubtitleChanged();
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @note Handles the STREAM_CURRENTAUDIOTRACK_CHANGED event
     *
     */
    private audioChangedEvent() {
        this.onAudioChanged();
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MANIFEST_LOADED event
     *
     */
    private multivariantPlaylistFetchedEvent(eventData: {
        requestSynopsis: { url?: string };
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventData: Types.object()
            };

            typecheck.warn(
                this,
                'multivariantPlaylistFetchedEvent',
                params,
                arguments
            );
        }

        const { nativePlayer } = this;
        const { isLive, isSlidingWindow } = nativePlayer as BamHlsNativePlayer;

        if (Check.object(eventData)) {
            const { requestSynopsis = {} } = eventData;
            const { url } = requestSynopsis;
            const { host, path } = parseUrl(url);

            let playlistLiveType;

            if (isLive && isSlidingWindow) {
                playlistLiveType = PlaylistType.SLIDE;
            } else {
                playlistLiveType = PlaylistType.COMPLETE;
            }

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed
            });

            this.onMultivariantPlaylistFetched({
                playlistLiveType,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} eventData
     * @note Handles the VARIANT_UPDATED event
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer?.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    private variantPlaylistFetchedEvent(eventData: {
        level: number;
        details: {
            url?: string;
        };
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                eventData: Types.object()
            };

            typecheck.warn(
                this,
                'variantPlaylistFetchedEvent',
                params,
                arguments
            );
        }

        const { nativePlayer } = this;
        const { variants = [] } = nativePlayer as BamHlsNativePlayer;

        if (Check.object(eventData)) {
            const { level, details = {} } = eventData;
            const variant = variants[level] || ({} as Variant);
            const { attrs = {} as Variant['attrs'], loadError } = variant;

            const {
                BANDWIDTH: bandwidth,
                CHANNELS: channels,
                NAME: name,
                LANGUAGE: language,
                RESOLUTION: resolution
            } = attrs;

            const averageBandwidth = attrs['AVERAGE-BANDWIDTH'];
            const { url } = details;
            const { host, path } = parseUrl(url);
            const status = loadError === 0 ? FetchStatus.completed : null;

            const serverRequest = new ServerRequest({
                host,
                path,
                status
            });

            this.onVariantPlaylistFetched({
                playlistAverageBandwidth: Check.assigned(averageBandwidth)
                    ? Number(averageBandwidth)
                    : null,
                playlistBandwidth: Check.assigned(bandwidth)
                    ? Number(bandwidth)
                    : null,
                playlistChannels: channels,
                playlistName: name,
                playlistLanguage: language,
                playlistResolution: resolution,
                serverRequest
            });
        }
    }

    private onStreamError = (exception: {
        fatal: boolean;
        messageDetailed: string;
        error: string;
        cause: keyof typeof BamHlsErrorMapping;
    }) => {
        const { STREAM_ERROR } = this.Events;
        const { fatal = false, messageDetailed = STREAM_ERROR } =
            exception || {};

        if (fatal && exception.error === 'DRM_FAILED') {
            const errorCase = this.constructErrorCaseFromBamHlsError(exception);

            this.emit(PublicEvents.MediaFailure, errorCase);

            let error;

            try {
                error = JSON.stringify(errorCase, circularReplacer());
            } catch (exx) {
                error = 'Cannot stringify original error';
            }

            this.boundPlaybackEndedEvent.call(this, error);

            // removes listener on fatal error
            return this.removeListener(this.listener);
        }

        if (fatal && Check.assigned(this.listener)) {
            this.boundPlaybackEndedEvent.call(this, messageDetailed);

            // removes listener on fatal error
            return this.removeListener(this.listener);
        }

        return undefined;
    };

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.BamHlsPlayerAdapter';
    }
}
