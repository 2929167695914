/**
 *
 * @module graphErrorHandler
 *
 */

import { Check } from '@dss/type-checking';
import { IErrorHandler } from './IErrorHandler';
import ErrorReason from '../../../exception/errorReason';

type Extensions = {
    code: string;
};

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Handles errors returned by the Orchestration / GraphQl service.
 * @returns {Object}
 *
 */
const GraphErrorHandler: IErrorHandler = {
    is: (options) => {
        const { data, isSuccessStatus } = options;
        const { errors = [], data: orchestrationData } = data || {};

        const { extensions = {} as Extensions } = errors.length
            ? errors[0]
            : {};

        if (
            isSuccessStatus &&
            typeof extensions.code === 'string' &&
            Check.not.nonEmptyObject(orchestrationData)
        ) {
            return true;
        }

        return false;
    },
    process: (options) => {
        const { errors = [] } = options.data || {};
        const {
            message = '',
            extensions = {} as Extensions,
            data = undefined
        } = errors[0] || {};

        return {
            errorReasons: [
                new ErrorReason({
                    code: extensions.code,
                    description: message,
                    data
                })
            ],
            _isGraphError: true
        };
    }
};

export default GraphErrorHandler;
