/**
 *
 * @module mediaLocator
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/playback-mapping.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { MediaLocatorType } from './enums';

/**
 *
 * @access public
 * @since 24.0.0
 * @desc An object that provides a way to locate media.
 *
 */
export default class MediaLocator {
    /**
     *
     * @access public
     * @since 24.0.0
     * @type {SDK.Media.MediaLocatorType}
     * @desc Enum to indicate the type of the media locator.
     *
     */
    public mediaLocatorType: MediaLocatorType;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {String}
     * @desc The value of the media locator.
     *
     */
    public value: string;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {String}
     * @desc ID for cache resolution of every cached `MediaItem`.
     * @note Since offline downloads is not a requirement on JS, contentId is optional.
     *
     */
    public id: string;

    /**
     *
     * @param {Object} options
     * @param {String<SDK.Media.MediaLocatorType>} options.mediaLocatorType - Enum to indicate the type of the media locator.
     * @param {String} options.value - The value of the media locator.
     *
     */
    public constructor(options: {
        mediaLocatorType: MediaLocatorType;
        value: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaLocatorType: Types.in(MediaLocatorType),
                    value: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { mediaLocatorType, value } = options;

        this.mediaLocatorType = mediaLocatorType;
        this.value = value;

        if (mediaLocatorType === MediaLocatorType.mediaId) {
            const mediaId = { mediaId: value };

            this.id = btoa(JSON.stringify(mediaId));
        } else {
            this.id = value;
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.MediaLocator';
    }
}
