/**
 *
 * @module adEngineRegex
 *
 */

const adEngineRegex = new RegExp('//aeng(-test)?');

/**
 *
 * @param {String} url
 * @desc tests .ts chunk url strings to determine if this is an AdEngine request
 * @returns {Boolean}
 *
 * @example adEngineRegex(('http://www.nope.com')) => false)
 * @example adEngineRegex(('https://aeng.svcs.plus.espn.com') => true)
 *
 */
export default (url: string) => {
    return adEngineRegex.test(url);
};
