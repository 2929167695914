/**
 *
 * @module serviceConfiguration
 *
 */

/**
 *
 * @access protected
 * @desc Provides base ServiceConfiguration
 *
 */
export default class ServiceConfiguration<
    TClient = Nullable<Record<string, never>>,
    TExtras = Nullable<Record<string, never>>
> {
    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ServiceClientConfiguration}
     *
     */
    public client: TClient;

    /**
     *
     * @access public
     * @type {TExtras}
     *
     */
    public extras: TExtras;

    /**
     *
     * @access public
     * @type {Boolean}
     *
     */
    public disabled: boolean;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.ServiceClientConfiguration} [options.client]
     * @param {Object} [options.extras={}]
     * @param {Boolean} [options.disabled=false]
     *
     */
    public constructor(options: {
        client?: TClient;
        extras?: TExtras;
        disabled?: boolean;
    }) {
        const { client, extras, disabled } = options;

        this.client = client || ({} as TClient);
        this.extras = extras || ({} as TExtras);
        this.disabled = disabled || false;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceConfiguration';
    }
}
