/**
 *
 * @module eligibilityStatus
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/eligibility.md
 *
 */

/**
 *
 * @access public
 * @since 4.2.0
 * @typedef {Object} SDK.Services.Eligibility.EligibilityStatus
 * @desc Defines the different eligibility status.
 * @example
 * EligibilityStatus.fullPrice // 'FULL_PRICE'
 *
 * @property {String} redirect - the service returns `REDIRECT` when a user who has already purchased a subscription attempts to purchase a super bundle
 * @property {String} ineligible - the service returns `INELIGIBLE`
 * @property {String} discount - the service returns `DISCOUNT`. Deprecated as of `21.1.0` and will be removed in a future version.
 * @property {String} canceled - the service returns `CANCELED`. Deprecated as of `21.1.0` and will be removed in a future version.
 * @property {String} fullPrice - the service returns `FULL_PRICE`
 *
 * @note the service returns values that do not match the SDK keys in the spec
 *
 */
enum EligibilityStatus {
    redirect = 'REDIRECT',
    ineligible = 'INELIGIBLE',
    discount = 'DISCOUNT',
    canceled = 'CANCELED',
    fullPrice = 'FULL_PRICE'
}

export default EligibilityStatus;
