/**
 *
 * @module graphQlPersistedQuery
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import queryString from 'query-string';
import HttpMethod from '../services/configuration/httpMethod';
import QueryBuilder from '../services/content/queryBuilder';

import appendQuerystring from '../services/util/appendQuerystring';

export default class GraphQlPersistedQuery extends QueryBuilder {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public queryId: string;

    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public variables: unknown;

    /**
     *
     * @param {String} queryId
     * @param {Object} [variables={}]
     *
     */
    public constructor(queryId: string, variables?: unknown) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                queryId: Types.nonEmptyString,
                variables: Types.object().optional
            };

            typecheck(this, params, arguments);
        }

        this.queryId = queryId;
        this.variables = variables || {};
    }

    /**
     *
     * @access private
     * @param {String} url - The URL for which to build the query.
     * @param {SDK.Services.Configuration.HttpMethod} method - The method for which to build the query.
     * @desc Returns the URL for the query.
     * @note When the SDK makes a request for a PQ it must include 'core/' as part of the queryId.
     * @see https://search-api-sdk-mock.bamgrid.com/svc/search/v2/graphql/persisted/queries
     * @returns {String} The URL for the query.
     *
     */
    public override createQueryUrl(url: string, method: HttpMethod) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                url: Types.nonEmptyString,
                method: Types.in(HttpMethod)
            };

            typecheck(this, params, arguments);
        }

        const { queryId, variables } = this;

        let queryUrl = url.replace(/\{queryId\}/, queryId);

        if (
            method === HttpMethod.GET &&
            Check.nonEmptyObject(variables as object)
        ) {
            const queryStringParams = {
                variables: JSON.stringify(variables)
            };

            const urlQueryString = queryString.stringify(queryStringParams);

            queryUrl = appendQuerystring(queryUrl, urlQueryString);
        }

        return queryUrl;
    }

    /**
     *
     * @access private
     * @desc Returns the post content for the query.
     * @returns {String} The post content for the query.
     *
     */
    public override createPostContent() {
        const { queryId: id, variables } = this;

        return JSON.stringify({ id, variables });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Content.GraphQlPersistedQuery';
    }
}
