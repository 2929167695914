/**
 *
 * @module access
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import AccessContextState from './accessContextState';
import AccessContext from '../services/token/accessContext';

/**
 *
 * @desc An interface with a reference to AccessContext and AccessContextState, used to
 * group the two as they are expected to be sent over and stored together.
 *
 */
export default class Access {
    /**
     *
     * @access public
     * @type {SDK.Services.Token.AccessContext}
     *
     */
    public context: AccessContext;

    /**
     *
     * @access public
     * @type {AccessContextState}
     *
     */
    public contextState: AccessContextState;

    /**
     *
     * @param {SDK.Services.Token.AccessContext} context
     * @param {AccessContextState} contextState
     *
     */
    public constructor(
        context: AccessContext,
        contextState: AccessContextState
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                context: Types.instanceStrict(AccessContext),
                contextState: Types.instanceStrict(AccessContextState)
            };

            typecheck(this, params, arguments);
        }

        this.context = context;

        this.contextState = contextState;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Token.Access';
    }
}
