/**
 *
 * @module sdkSessionConfiguration
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/logging.md#service-calls
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ServiceDefinitionsConfiguration from './serviceDefinitionsConfiguration';
import PlatformProviders from '../providers/platformProviders';
import EnvironmentConfiguration from '../providers/browser/environmentConfiguration';

import type { Application, SdkConfigRoot } from './typedefs';
import {
    APPLICATION_VERSION,
    REQUEST_ID,
    SDK_PLATFORM,
    SDK_VERSION
} from '../providers/shared/httpHeaderConstants';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary to create an SdkSession instance.
 *
 */
export default class SdkSessionConfiguration {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Gets or sets the collection of headers to be used for all service requests.
     * @note Common header structure:
     *
     *   "commonHeaders": {
     *     "X-Application-Version": "{applicationVersion}",
     *     "X-Request-ID": "{requestID}",
     *     "X-BAMSDK-Client-ID": "disney-svod-3d9324fc",
     *     "X-BAMSDK-Platform": "{SDKPlatform}",
     *     "X-BAMSDK-Version": "{SDKVersion}",
     *     "X-DSS-Edge-Accept": "vnd.dss.edge+json; version=2"
     *   }
     *
     */
    public commonHeaders: Record<string, string>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Object}
     * @desc Gets or sets the collection of values to be used for all service requests.
     * @note Common values structure:
     *
     *   "commonValues": {
     *     "platformId": "macbook"
     *   }
     *
     */
    public commonValues: Record<string, unknown>;

    /**
     *
     * @access public
     * @type {SDK.Services.Configuration.ServiceDefinitionsConfiguration}
     *
     */
    public services: ServiceDefinitionsConfiguration;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {Object}
     *
     */
    public application: Application;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Boolean}
     * @desc Flag to determine whether the config data was fetched via the app or SDK.
     * @note Will be false unless a #getConfiguration method is provided to #createSdkSession.
     *
     */
    public wasFetchedViaExternalGetter: boolean;

    /**
     *
     * @param {Object} options
     * @param {Object} options.commonHeaders
     * @param {Object} [options.commonValues]
     * @param {Object} options.services
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @param {Object} options.application
     *
     */
    public constructor(
        options: SdkConfigRoot & {
            environmentConfiguration: EnvironmentConfiguration;
        }
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commonHeaders: Types.nonEmptyObject,
                    commonValues: Types.nonEmptyObject.optional,
                    services: Types.nonEmptyObject,
                    environmentConfiguration: Types.instanceStrict(
                        PlatformProviders.EnvironmentConfiguration
                    ),
                    application: Types.nonEmptyObject
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            application,
            commonHeaders,
            commonValues,
            environmentConfiguration,
            services
        } = options;

        this.commonHeaders = {};
        this.commonValues = {};
        this.services = {} as ServiceDefinitionsConfiguration;
        this.application = application;
        this.wasFetchedViaExternalGetter = false;

        /**
         *
         * @desc process plain config Object and create configuration instances
         *
         */
        this.processConfig(
            {
                commonHeaders,
                commonValues,
                services,
                application
            },
            environmentConfiguration
        );
    }

    /**
     *
     * @access private
     * @param {Object} config - JSON configuration object returned from configuration service endpoint.
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} environmentConfiguration
     * @desc Initialize SdkSessionConfiguration by processing config object.
     *
     */
    private processConfig(
        config: SdkConfigRoot,
        environmentConfiguration: EnvironmentConfiguration
    ) {
        const { commonHeaders, commonValues = {}, services } = config;
        const {
            sdkVersion,
            applicationVersion,
            constructedPlatformIdentifier
        } = environmentConfiguration;

        this.commonHeaders = commonHeaders;
        this.commonValues = commonValues;

        const versionHeader = this.commonHeaders[SDK_VERSION].replace(
            /\{SDKVersion\}/gi,
            sdkVersion
        );
        const platformHeader = this.commonHeaders[SDK_PLATFORM].replace(
            /\{SDKPlatform\}/gi,
            constructedPlatformIdentifier
        );
        const appVersionHeader = this.commonHeaders[
            APPLICATION_VERSION
        ].replace(/\{applicationVersion\}/gi, applicationVersion);

        /**
         *
         * @desc The sdk version which does not include a "v" prefix, e.g. `1.0.2` (major.minor for the JS SDK, e.g. 13.0)
         *
         */
        this.commonHeaders[SDK_VERSION] = versionHeader;

        /**
         *
         * @desc The SDK platform is constructed from `deviceFamily`, `applicationRuntime`, and `deviceProfile`, e.g. `microsoft/uwp/xbox`
         * @note Javascript will have `applicationRuntime` and `deviceProfile` swapped to align with the config url
         * @note Javascript will use the value of `javascript` for `deviceFamily` instead of `browser`
         * @note Javascript example: `javascript/samsung/tizen`
         * @example
         *   SDK: "{deviceFamily}/{applicationRuntime}/{deviceProfile}"
         *   JS SDK: "javascript/{deviceProfile}/{applicationRuntime}"
         *
         */
        this.commonHeaders[SDK_PLATFORM] = platformHeader;

        /**
         *
         * @desc The application version provided by the application developer and will be required.
         *
         */
        this.commonHeaders[APPLICATION_VERSION] = appVersionHeader;

        /**
         *
         * @since 22.0.0
         * @desc A client-generated request ID that can be used to identify this request in service and SDK logs.
         * @note This header is not included in the configs.
         * @note The SDK uses the value of the `X-Request-ID` response header to cross-index Ash events with Edge HTTP logs. As a consequence of using a response header the SDK cannot provide this cross-reference if it does not receive a network response for some reason (often a timeout or a network disconnection). Providing a client-generated ID in the request headers allows for cross-referencing of Ash events and Edge HTTP logs even in the event that a network response is not received.
         *
         */
        this.commonHeaders[REQUEST_ID] = '';

        this.services = new ServiceDefinitionsConfiguration({
            services,
            commonHeaders,
            commonValues
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.SdkSessionConfiguration';
    }
}
