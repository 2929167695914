/**
 *
 * @module orchestrationManagerConfiguration
 *
 */

import OrchestrationClientConfiguration from '../orchestration/orchestrationClientConfiguration';
import OrchestrationExtrasMap from '../orchestration/orchestrationExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing orchestration.
 *
 */
export default class OrchestrationManagerConfiguration extends ServiceConfiguration<
    OrchestrationClientConfiguration,
    OrchestrationExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.OrchestrationManagerConfiguration';
    }
}
