/**
 *
 * @module playbackSession
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/MediaApi.md
 * @see https://github.bamtech.co/sdk-distribution/bam-sdk/blob/master/Features/MediaApi.md#playback-scenarios-and-ad-insertion-strategy
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playback-session.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/playhead.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../logging/logger';
import InternalEvents from '../internalEvents';
import AdEngineClient from '../services/media/adEngine/adEngineClient';
import DrmClient from '../services/drm/drmClient';
import TokenManager from '../token/tokenManager';

import MediaItem from './mediaItem';
import Playlist from './playlist';
import PlaybackTelemetryDispatcher from './playbackTelemetryDispatcher';
import AuthCookieProvider from './authCookieProvider';

import FairPlayDrmProvider from '../drm/fairPlayDrmProvider';
import NagraDrmProvider from '../drm/nagraDrmProvider';
import PlayReadyDrmProvider from '../drm/playReadyDrmProvider';
import SilkDrmProvider from '../drm/silkDrmProvider';
import WidevineDrmProvider from '../drm/widevineDrmProvider';

import PlayerAdapter from './playerAdapter';
import BamWebPlayerAdapter from './playerAdapter/bamWebPlayerAdapter';
import CafPlayerAdapter from './playerAdapter/cafPlayerAdapter';
import BamHlsPlayerAdapter from './playerAdapter/bamHlsPlayerAdapter';
import DssHlsPlayerAdapter from './playerAdapter/dssHlsPlayerAdapter';
import MelHivePlayerAdapter from './playerAdapter/melHivePlayerAdapter';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import DustLogUtility from '../services/internal/dust/dustLogUtility';
import LogTransaction from '../logging/logTransaction';

import { MediaAnalyticsKey } from './enums';
import MediaPlayhead from '../services/media/mediaPlayhead';
import AccessToken from '../token/accessToken';
import MediaDescriptor from './mediaDescriptor';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';
import PlaybackAttributes from '../services/media/playbackAttributes';
import TokenRefreshFailure from '../token/tokenRefreshFailure';
import AccessChangedEvent from '../accessChangedEvent';

import { PlaybackSecurity } from '../services/media/typedefs';

import { DrmType } from '../services/media/enums';

const DustUrn = DustUrnReference.media.playbackSession;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

type DrmProviderOptions = { mediaItem: MediaItem; security?: PlaybackSecurity };

/**
 *
 * @since 2.0.0
 * @desc Encapsulates the playlist request and player adapter preparation.
 *
 */
export default class PlaybackSession {
    /**
     *
     * @access private
     * @type {SDK.Token.TokenManager}
     *
     */
    private tokenManager: TokenManager;

    /**
     *
     * @access private
     * @type {SDK.Services.Drm.DrmClient}
     *
     */
    private drmClient: DrmClient;

    /**
     *
     * @access private
     * @type {PlaybackTelemetryDispatcher}
     *
     */
    private telemetryDispatcher: PlaybackTelemetryDispatcher;

    /**
     *
     * @access private
     * @type {PlayerAdapter}
     *
     */
    private playerAdapter: PlayerAdapter;

    /**
     *
     * @access private
     * @type {AuthCookieProvider}
     *
     */
    private authCookieProvider: AuthCookieProvider;

    /**
     *
     * @access private
     * @type {SDK.Services.Media.AdEngine.AdEngineClient}
     *
     */
    private adEngineClient: AdEngineClient;

    /**
     *
     * @access private
     * @type {Array<SDK.Services.Media.DrmType>}
     *
     */
    private priorityDrms?: Nullable<Array<DrmType>>;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {SDK.Media.MediaItem|undefined}
     *
     */
    private mediaItem?: MediaItem;

    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    public logger: Logger;

    /**
     *
     * @param {Object} options
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Services.Drm.DrmClient} options.drmClient
     * @param {PlaybackTelemetryDispatcher} options.telemetryDispatcher
     * @param {SDK.Logging.Logger} options.logger
     * @param {PlayerAdapter} options.playerAdapter
     * @param {AuthCookieProvider} options.authCookieProvider
     * @param {SDK.Services.Media.AdEngine.AdEngineClient} options.adEngineClient
     * @param {Array<SDK.Services.Media.DrmType>} [options.priorityDrms=null]
     * @param {SDK.Media.MediaItem} [options.mediaItem]
     *
     */
    public constructor(options: {
        tokenManager: TokenManager;
        drmClient: DrmClient;
        telemetryDispatcher: PlaybackTelemetryDispatcher;
        logger: Logger;
        playerAdapter: PlayerAdapter;
        authCookieProvider: AuthCookieProvider;
        adEngineClient: AdEngineClient;
        priorityDrms?: Nullable<Array<DrmType>>;
        mediaItem?: MediaItem;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    tokenManager: Types.instanceStrict(TokenManager),
                    drmClient: Types.instanceStrict(DrmClient),
                    telemetryDispatcher: Types.instanceStrict(
                        PlaybackTelemetryDispatcher
                    ),
                    logger: Types.instanceStrict(Logger),
                    playerAdapter: Types.instanceStrict(PlayerAdapter),
                    authCookieProvider:
                        Types.instanceStrict(AuthCookieProvider),
                    adEngineClient: Types.instanceStrict(AdEngineClient),
                    priorityDrms: Types.array.of.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            tokenManager,
            drmClient,
            telemetryDispatcher,
            logger,
            playerAdapter,
            authCookieProvider,
            adEngineClient,
            priorityDrms = null,
            mediaItem
        } = options;

        this.tokenManager = tokenManager;
        this.drmClient = drmClient;
        this.telemetryDispatcher = telemetryDispatcher;
        this.playerAdapter = playerAdapter;
        this.authCookieProvider = authCookieProvider;
        this.adEngineClient = adEngineClient;
        this.priorityDrms = priorityDrms;
        this.mediaItem = mediaItem;
        this.logger = logger;

        /**
         *
         * @desc listen to {SDK.Token.TokenManager} events
         * @note Binding the event callbacks to ensure `this` is accurate.
         *
         */
        this.onTokenRefreshFailed = this.onTokenRefreshFailed.bind(this);
        this.onAccessChanged = this.onAccessChanged.bind(this);
        this.bindTokenProviderEvents();

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {MediaItem} options.mediaItem
     * @param {Object} [options.extraData={}] - Extra data to track during the playback session.
     * @param {Number} [options.artificialDelayDuration] - The duration, in milliseconds, of an artificial delay
     * @param {Boolean} [options.isNsaIncludedInVst=true] - Bool used to determine whether artificialDelayDuration should be removed from `totalVst`
     * that occurred / will occur during the startup sequence of playing content, such as displaying the Negative Stereotype Advisory warning.
     * @desc Uses provided payload to prepare the player. Assigns to local payload variable.
     * @note `isNsaIncludedInVst` does not exist in the SDK spec and is JS specific.
     * @returns {Promise<SDK.Media.Playlist>} - containing information about the media that was prepared.
     *
     */
    public async prepare(options: {
        mediaItem: MediaItem;
        extraData?: Record<string, unknown>;
        artificialDelayDuration?: number;
        isNsaIncludedInVst?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaItem: Types.instanceStrict(MediaItem),
                    extraData: Types.object().optional,
                    artificialDelayDuration: Types.number.optional,
                    isNsaIncludedInVst: Types.boolean.optional
                })
            };

            typecheck(this, 'prepare', params, arguments);
        }

        const { logger } = this;
        const {
            mediaItem,
            extraData = {},
            artificialDelayDuration,
            isNsaIncludedInVst = true
        } = options;

        if (mediaItem.playbackContext) {
            if (artificialDelayDuration) {
                mediaItem.playbackContext.artificialDelayDuration =
                    artificialDelayDuration;
            }

            mediaItem.playbackContext.isNsaIncludedInVst = isNsaIncludedInVst;
        }

        this.mediaItem = mediaItem;

        const urn = DustUrn.prepare;

        return LogTransaction.wrapLogTransaction({
            urn,
            file: this.toString(),
            logger,
            /**
             *
             * @param {SDK.Logging.LogTransaction} logTransaction
             *
             */
            action: async (logTransaction) => {
                const dustLogUtility = new DustLogUtility({
                    logger,
                    source: this.toString(),
                    urn,
                    data: {
                        streamUrl: mediaItem.descriptor.locator.id
                    },
                    logTransaction
                });

                const {
                    telemetryDispatcher,
                    playerAdapter,
                    authCookieProvider,
                    tokenManager
                } = this;
                const { playhead = {} } = mediaItem;
                const { position, status } = playhead as MediaPlayhead;

                const accessToken = tokenManager.getAccessToken();

                logger.info(this.toString(), 'Execute #prepare');
                logger.info(
                    this.toString(),
                    `Playlist type: ${mediaItem.preferredPlaylistType}`
                );

                if (Check.assigned(position)) {
                    logger.info(
                        this.toString(),
                        `Playhead position: "${position}"`
                    );
                }

                if (Check.assigned(status)) {
                    logger.info(
                        this.toString(),
                        `Playhead status: "${status}"`
                    );
                }

                let playlist: Playlist;
                let playbackAttributes;

                try {
                    await this.updatePlayerAdapter();

                    const preferredPlaylist =
                        await mediaItem.getPreferredPlaylist();

                    playlist = preferredPlaylist;
                    playbackAttributes = preferredPlaylist.attributes;

                    telemetryDispatcher.init({
                        playbackMetricsProvider: playerAdapter,
                        mediaItem,
                        playlist,
                        extraData
                    });

                    if (Check.assigned(playbackAttributes)) {
                        await this.setDrm(
                            playbackAttributes as PlaybackAttributes,
                            mediaItem
                        );
                    }

                    playerAdapter.on(
                        InternalEvents.UpdateAdEngine,
                        (priority) => {
                            this.updateAdEngineCookies({
                                mediaItem,
                                playlist,
                                priority,
                                logTransaction
                            });
                        }
                    );

                    await this.updateAdEngineCookies({
                        mediaItem,
                        playlist,
                        logTransaction
                    });
                    await authCookieProvider.createCookie(
                        accessToken as AccessToken,
                        logTransaction
                    );

                    logger.info(
                        this.toString(),
                        `Setting source on PlayerAdapter, ${playlist.streamUri}`
                    );

                    await playerAdapter.setSource(playlist);

                    logger.info(
                        this.toString(),
                        'Successfully set source on PlayerAdapter'
                    );

                    return playlist;
                } catch (exception) {
                    dustLogUtility.captureError(exception);

                    throw exception;
                } finally {
                    dustLogUtility.log();
                }
            }
        });
    }

    /**
     *
     * @access public
     * @desc Initiates an extra, immediate stream sample collection,
     * based on the player's current playhead if stream samples are enabled for the playback session.
     * @returns {Promise<Void>}
     *
     */
    public collectStreamSample() {
        const { telemetryDispatcher, playerAdapter } = this;

        const isManual = true;

        return telemetryDispatcher.recordStreamSample(playerAdapter, isManual);
    }

    /**
     *
     * @access public
     * @since 28.4.0
     * @desc Signals that a program boundary has been crossed. This method is expected to be invoked when a program boundary is crossed _after a successful playback rights check_, not when a playback rights check for the next program succeeds.
     * @param {String} programBoundaryInfoBlock - An opaque encoded string originally sourced from the `PlaybackRights.tracking.qoe` map returned from a successful `MediaApi.checkPlaybackRights()` invocation.
     * @returns {Promise<void>} A promise fulfilled when the SDK caches the `programBoundaryInfoBlock` and creates a corresponding `playbackHeartbeat` PQoE event.
     *
     */
    public updateProgramMetadata(programBoundaryInfoBlock: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                programBoundaryInfoBlock: Types.nonEmptyString
            };

            typecheck.warn(this, 'updateProgramMetadata', params, arguments);
        }

        return this.telemetryDispatcher.updateProgramMetadata(
            this.playerAdapter,
            programBoundaryInfoBlock
        );
    }

    /**
     *
     * @access public
     * @desc Stops the authorization refresh listener, stops playback listener, clears the payload value,
     * and clears out event listeners to avoid memory leaks when creating playback sessions.
     * @returns {Promise<Void>}
     *
     */
    @apiMethodDecorator()
    public async release() {
        const { logger, playerAdapter, telemetryDispatcher, tokenManager } =
            this;
        const { onTokenRefreshFailed, onAccessChanged } = this;

        logger.info(this.toString(), 'Release.');

        tokenManager.off(
            InternalEvents.TokenRefreshFailed,
            onTokenRefreshFailed
        );
        tokenManager.off(InternalEvents.AccessChanged, onAccessChanged);

        await playerAdapter.fatalErrorPromise;

        await telemetryDispatcher.release(playerAdapter);

        playerAdapter.removeAllListeners(InternalEvents.UpdateAdEngine);

        return playerAdapter.clean();
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Media.Playlist} options.playlist
     * @param {Number} [options.priority]
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc if adEngine is enabled and adEngine data exists try to update adEngine cookies
     * @returns {Promise<Void>}
     *
     */
    private updateAdEngineCookies(options: {
        mediaItem: MediaItem;
        playlist: Playlist;
        priority?: number;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    mediaItem: Types.instanceStrict(MediaItem),
                    playlist: Types.instanceStrict(Playlist),
                    priority: Types.number.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'updateAdEngineCookies', params, arguments);
        }

        const { mediaItem, playlist, priority, logTransaction } = options;
        const { adEngineClient, playerAdapter } = this;
        const { descriptor = {} as MediaDescriptor } = mediaItem;
        const { adTargeting } = descriptor;

        const adEngine = playlist.getTrackingData(
            MediaAnalyticsKey.adEngine,
            priority
        );

        if (!adEngineClient.disabled && Check.nonEmptyObject(adEngine)) {
            const { cdn, corigin, fguid: ssess } = adEngine;

            /**
             *
             * @note ssess is mapped from fguid - identifies the unique user
             *
             */
            const adEngineOptions = {
                ...{
                    cdn,
                    corigin,
                    ssess
                },
                ...adTargeting
            };

            // stores adEngine data to be used with adEngine .ts segment requests by the nativePlayer - might be ignored
            playerAdapter.setAdEngineData({ ssess, ...adEngine });

            // getCookies also exists but since we don't need to return the Cookie[] to the caller it is not used
            return adEngineClient.updateCookies(
                adEngineOptions,
                this.accessToken,
                logTransaction
            );
        }

        // reset to an empty object if adEngine data is disabled or not returned with the playlist response
        playerAdapter.setAdEngineData({});

        return Promise.resolve();
    }

    /**
     *
     * @access private
     * @param {Array<String>} drms - Array of Drm types.
     * @param {Object} [options] - options for the DrmProvider
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {SDK.Services.Media.PlaybackSecurity} [options.security] - Security of playback attributes.
     * @desc Creates SDK.Drm.DrmProvider instances based on the provided data set
     * @returns {Array<SDK.Drm.DrmProvider>}
     *
     */
    private createDrmProviders(
        drms: Array<string>,
        options?: DrmProviderOptions
    ) {
        return drms
            .map((drm) => this.createDrmProvider(drm, options))
            .filter((drm) => !!drm);
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {String} type - Drm type.
     * @param {Object} [options] - options for the DrmProvider
     * @param {SDK.Media.MediaItem} options.mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @param {SDK.Services.Media.PlaybackSecurity} [options.security] - Security of playback attributes.
     * @desc Creates one SDK.Drm.DrmProvider instance based on the provided type
     * @returns {DK.Drm.DrmProvider|undefined}
     *
     */
    private createDrmProvider(type: string, options?: DrmProviderOptions) {
        const { mediaItem, security } = options || ({} as DrmProviderOptions);
        const { drmEndpointKey } = security || {};

        const { logger, drmClient, tokenManager, playerAdapter } = this;
        const { videoPlayerName, videoPlayerVersion } = playerAdapter;
        const opts = {
            drmClient,
            tokenManager,
            logger,
            type,
            mediaItem,
            endpointKey: drmEndpointKey as DrmClientEndpoint,
            videoPlayerName,
            videoPlayerVersion
        };

        let provider;

        switch (type) {
            case DrmType.FAIRPLAY:
                provider = new FairPlayDrmProvider(opts);
                break;

            case DrmType.PLAYREADY:
                provider = new PlayReadyDrmProvider(opts);
                break;

            case DrmType.WIDEVINE:
                provider = new WidevineDrmProvider(opts);
                break;

            case DrmType.PRMNAGRA:
                provider = new NagraDrmProvider(opts);
                break;

            case DrmType.SILK:
                provider = new SilkDrmProvider(opts);
                break;

            // no default
        }

        if (provider) {
            logger.info(this.toString(), `Created ${provider} instance`);
        } else {
            logger.error(
                this.toString(),
                `Could not create Drm provider of type: ${type}`
            );
        }

        return provider;
    }

    /**
     *
     * @access private
     * @param {Array<String>} drms
     * @desc Additional filtering for provided drms. This is especially useful for
     * testing, in certain cases the drms returned may not be supported by a given
     * platform, breaking the logic in the PlayerAdapter.
     * @returns {Array<String>}
     *
     */
    private filterDrms(drms: Array<string>) {
        const { playerAdapter } = this;

        const hasBamWebPlayerAdapter = Check.instanceStrict(
            playerAdapter,
            BamWebPlayerAdapter
        );
        const hasCafPlayerAdapter = Check.instanceStrict(
            playerAdapter,
            CafPlayerAdapter
        );
        const hasBamHlsPlayerAdapter = Check.instanceStrict(
            playerAdapter,
            BamHlsPlayerAdapter
        );
        const hasDssHlsPlayerAdapter = Check.instanceStrict(
            playerAdapter,
            DssHlsPlayerAdapter
        );
        const hasMelHivePlayerAdapter = Check.instanceStrict(
            playerAdapter,
            MelHivePlayerAdapter
        );

        const hasNeedForFilter = [
            hasBamWebPlayerAdapter,
            hasCafPlayerAdapter,
            hasBamHlsPlayerAdapter,
            hasDssHlsPlayerAdapter
        ];

        // Skip filter logic completely for PlayerAdapter(s) that don't need it
        if (!hasNeedForFilter.includes(true)) {
            return drms;
        }

        // Filter drms, return a new Array with supported drms for a particular PlayerAdapter
        return drms.filter((drm) => {
            if (hasCafPlayerAdapter) {
                return drm !== DrmType.FAIRPLAY && drm !== DrmType.PLAYREADY;
            }

            if (
                hasBamWebPlayerAdapter ||
                hasBamHlsPlayerAdapter ||
                hasDssHlsPlayerAdapter ||
                hasMelHivePlayerAdapter
            ) {
                return drm !== DrmType.SILK;
            }

            return drm;
        });
    }

    /**
     *
     * @access private
     * @param {Array<String>} [drms]
     * @param {Object} [options] - options for the DrmProvider
     * @desc Sets SDK.Drm.DrmProvider instances on a given PlayerAdapter
     * @returns {Promise<Void>}
     *
     */
    private async setDrmProviders(
        drms?: Array<string>,
        options?: DrmProviderOptions
    ) {
        const { playerAdapter, priorityDrms } = this;

        const hasDrms = Check.nonEmptyArray(drms);
        const hasDrmProvider = Check.assigned(playerAdapter.drmProvider);
        const hasDrmProviders = Check.nonEmptyArray(
            (playerAdapter as TodoAny).drmProviders
        );

        if (hasDrmProvider || hasDrmProviders) {
            return;
        }

        let selectedDrms;

        if (Check.assigned(priorityDrms)) {
            selectedDrms = priorityDrms as Array<DrmType>;

            if (hasDrms) {
                // Check drms vs priority drm for logging a warning message
                selectedDrms.forEach((priorityDrm) => {
                    const found = (drms as Array<string>).find(
                        (drm) => drm === priorityDrm
                    );

                    if (!found) {
                        this.logger.warn(
                            this.toString(),
                            `${priorityDrm} is not part of the supported DRMs.`
                        );
                    }
                });
            }
        } else {
            // Process the provided drms data set
            // create a DrmProvider instance for each DrmType
            if (hasDrms) {
                selectedDrms = this.filterDrms(drms as Array<string>);
            } else {
                // Provide a default DrmProvider {SilkDrmProvider}
                selectedDrms = this.filterDrms([DrmType.SILK]);
            }
        }

        if (
            !hasDrmProviders &&
            Check.function((playerAdapter as TodoAny).setDrmProviders)
        ) {
            // API based check, PlayerAdapter(s) that need a group
            // of DrmProvider(s) expose the drmProviders property and #setDrmProviders method
            const providers = this.createDrmProviders(selectedDrms, options);

            await (playerAdapter as TodoAny).setDrmProviders(providers);
        } else if (!hasDrmProvider) {
            // Standard check, assign a DrmProvider if one isn't
            // already associated with a given PlayerAdapter
            const provider = this.createDrmProvider(selectedDrms[0], options);

            await playerAdapter.setDrmProvider(provider);
        }
    }

    /**
     *
     * @access private
     * @param {PlaybackAttributes} playbackAttributes
     * @param {SDK.Media.MediaItem} mediaItem - Includes context information about the playback attempt used to report playback analytics.
     * @desc Bootstraps necessary DRM logic
     * @returns {Promise<Void>}
     *
     */
    private async setDrm(
        playbackAttributes: PlaybackAttributes,
        mediaItem: MediaItem
    ) {
        const { logger, playerAdapter } = this;
        const { audioSegmentTypes, videoSegmentTypes, drms, security } =
            playbackAttributes;

        if (Check.function((playerAdapter as TodoAny).updateSegmentFormat)) {
            logger.info(this.toString(), 'Update segment types.');
            logger.info(
                this.toString(),
                `Audio segment types: ${JSON.stringify(audioSegmentTypes)}`
            );
            logger.info(
                this.toString(),
                `Video segment types: ${JSON.stringify(videoSegmentTypes)}`
            );

            if (Check.assigned(mediaItem)) {
                logger.info(this.toString(), 'MediaItem set.');
                logger.info(
                    this.toString(),
                    `MediaItem: ${JSON.stringify(mediaItem)}`
                );
            }

            await (playerAdapter as TodoAny).updateSegmentFormat(
                audioSegmentTypes,
                videoSegmentTypes
            );
        }

        const options = {
            mediaItem,
            security
        };

        return this.setDrmProviders(drms, options);
    }

    /**
     *
     * @access private
     * @desc Updates the PlayerAdapter instance and ensures the
     * stored accessToken property is fresh
     * @returns {Promise<Void>}
     *
     */
    private async updatePlayerAdapter() {
        this.playerAdapter.accessToken = this.accessToken.token;
    }

    /**
     *
     * @access private
     * @param {SDK.Token.TokenRefreshFailure} tokenRefreshFailure
     * @desc Callback for the `TokenRefreshFailed` event.
     *
     */
    private onTokenRefreshFailed(tokenRefreshFailure: TokenRefreshFailure) {
        this.playerAdapter.onAccessFailed(tokenRefreshFailure);
    }

    /**
     *
     * @access private
     * @param {SDK.AccessChangedEvent} accessChangedEvent
     * @desc Callback for the `AccessChanged` event.
     *
     */
    private onAccessChanged(accessChangedEvent: AccessChangedEvent) {
        return LogTransaction.wrapLogTransaction({
            file: this.toString(),
            urn: DustUrnReference.media.playbackSession.onAccessChanged,
            logger: this.logger,
            /**
             *
             * @param {SDK.Logging.LogTransaction} logTransaction
             *
             */
            action: async (logTransaction) => {
                const { accessToken } = accessChangedEvent;

                // attaches access token property on base PlayerAdapter
                this.playerAdapter.onAccessChanged(accessToken.token);

                return this.authCookieProvider.createCookie(
                    accessToken,
                    logTransaction
                );
            }
        });
    }

    /**
     *
     * @access private
     * @desc listens and reacts to {SDK.Token.TokenManager} events
     *
     */
    private bindTokenProviderEvents() {
        const { tokenManager, onTokenRefreshFailed, onAccessChanged } = this;

        tokenManager.on(
            InternalEvents.TokenRefreshFailed,
            onTokenRefreshFailed
        );
        tokenManager.on(InternalEvents.AccessChanged, onAccessChanged);
    }

    /**
     *
     * @access private
     * @desc Grabs a fresh AccessToken from the AccessTokenProvider instance
     * @returns {SDK.Token.AccessToken}
     *
     */
    private get accessToken() {
        return this.tokenManager.getAccessToken() as AccessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Media.PlaybackSession';
    }
}
