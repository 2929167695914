/**
 *
 * @module tokenClient
 * @desc Provides a data client that can be used to access token services.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md
 * @see https://github.bamtech.co/api-services-eng/token-service/blob/master/doc/api/WEB.md
 * @see https://github.bamtech.co/api-services-eng/token-service/tree/master/doc/api/token-exchange
 * @see https://github.bamtech.co/api-services-eng/token-service/blob/master/doc/api/token-exchange/ACCESS-TOKEN.md
 * @see https://tools.ietf.org/html/rfc6749
 * @see https://jwt.io/ (decode, verify and generate JSON Web Tokens)
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import queryString from 'query-string';

import Logger from '../../logging/logger';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import AccessContext from './accessContext';
import TokenRequest from './tokenRequest';
import TokenClientEndpoint from './tokenClientEndpoint';
import TokenClientConfiguration from './tokenClientConfiguration';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import LogTransaction from '../../logging/logTransaction';
import HttpHeaders from '../providers/shared/httpHeaders';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';
import { SDK_REGION } from '../providers/shared/httpHeaderConstants';

const TokenClientDustUrnReference = DustUrnReference.services.token.tokenClient;

/**
 *
 * @access protected
 * @desc Stateless client object that is capable of calling the service to
 * exchange a TokenRequest for an AccessContext.
 *
 */
export default class TokenClient extends ClientBase<TokenClientConfiguration> {
    /**
     *
     * @access private
     * @type {{ [platform]: string }}
     * @desc Utility Object for storing platform data.
     *
     */
    private platformData: {
        platform?: string;
    };

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Token.TokenClientConfiguration} options.config
     * @param {String} [options.platformId]
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: TokenClientConfiguration;
        platformId?: string;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(TokenClientConfiguration),
                    platformId: Types.nonEmptyString.optional,
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { platformId } = options;

        this.platformData = {
            platform: platformId || this.config.extras.platformId
        };

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {SDK.Services.Token.TokenRequest} tokenRequest - An object that contains the
     * information required for making an exchange request.
     * @param {String} apiKey - The API key to provide as authorization for the request.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Attempts to exchange a token for an access token.
     * @returns {Promise<SDK.Services.Token.AccessContext>} Result indicating activation success and any active
     * entitlements.
     *
     */
    public async exchange(
        tokenRequest: TokenRequest,
        apiKey: string,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                tokenRequest: Types.instanceStrict(TokenRequest),
                apiKey: Types.nonEmptyString,
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'exchange', params, arguments);
        }

        const { logger, platformData } = this;
        const { grantType, tokenData, latitude, longitude } = tokenRequest;

        const endpointKey = TokenClientEndpoint.exchange;

        const payload = this.getPayload({
            apiKey,
            endpointKey,
            body: {
                ...{
                    grant_type: grantType,
                    latitude,
                    longitude
                },
                ...tokenData,
                ...platformData
            }
        });

        logger.info(
            this.toString(),
            `Exchange token request:
            originating grantType: "${tokenData.subject_token_type}",
            latitude: "${latitude}",
            longitude: "${longitude}",
            platform: "${platformData.platform}"
        `.replace(/(\s(?=\s))/g, '')
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: TokenClientDustUrnReference.exchange,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data, headers } = response;
                const {
                    access_token: token,
                    token_type: tokenType,
                    refresh_token: refreshToken,
                    expires_in: expiresIn
                } = data;

                /**
                 *
                 * access_token - The access token issued by the authorization server.
                 * token_type - The type of the Access Token. Will always be bearer.
                 * expires_in - The lifetime in seconds of the Access Token. Also known as the Access Token Expiration.
                 * refresh_token - The Refresh Token, which can be used to obtain a new Access Token.
                 *
                 */
                return new AccessContext({
                    token,
                    tokenType,
                    refreshToken,
                    expiresIn,
                    region: headers.get(SDK_REGION)
                });
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.apiKey
     * @param {SDK.Services.Token.TokenClientEndpoint} options.endpointKey
     * @param {Object} options.body
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        apiKey: string;
        endpointKey: TokenClientEndpoint;
        body: object;
    }) {
        const { apiKey, endpointKey, body } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const { href, headers, method } = endpoint;
        const requestBody = body ? queryString.stringify(body) : '';

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return { replacer: '{apiKey}', value: apiKey };
                }
            },
            headers
        );

        return {
            url: href,
            method,
            body: requestBody,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.TokenClient';
    }
}
