/**
 *
 * @module tokenUpdater
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import type AccessToken from './token/accessToken';
import type TokenManager from '../token/tokenManager';

/**
 *
 * @protected
 * @since 7.0.0
 * @desc Provides access to `TokenManager` functionality for items within the services namespace.
 *
 */
export default class TokenUpdater {
    /**
     *
     * @access private
     * @since 7.0.0
     * @type {Function}
     * @desc The `TokenManager#updateAccessToken` method.
     *
     */
    public updateAccessToken: PromiseNoop;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {Function}
     * @desc The `TokenManager#refreshAccessToken` method.
     *
     */
    public refreshAccessToken: PromiseNoop;

    /**
     *
     * @access private
     * @since 7.0.0
     * @type {Function}
     * @desc The `TokenManager#getAccessToken` method.
     *
     */
    public getAccessToken: () => Nullable<AccessToken>;

    /**
     *
     * @access protected
     * @since 8.0.0
     * @type {SDK.Token.TokenManager}
     * @note This value is attached after it's created.
     *
     */
    public tokenManager: Nullable<TokenManager>;

    public once: (...args: Array<unknown>) => void;
    public emit: (...args: Array<unknown>) => void;

    /**
     *
     * @param {Object} options
     * @param {Function} options.updateAccessToken
     * @param {Function} options.refreshAccessToken
     * @param {Function} options.getAccessToken
     * @param {Function} options.once
     * @param {Function} options.emit
     *
     */
    public constructor(options: {
        updateAccessToken: PromiseNoop;
        refreshAccessToken: PromiseNoop;
        getAccessToken: () => Nullable<AccessToken>;
        once: (...args: Array<unknown>) => void;
        emit: (...args: Array<unknown>) => void;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    updateAccessToken: Types.function,
                    refreshAccessToken: Types.function,
                    getAccessToken: Types.function,
                    once: Types.function,
                    emit: Types.function
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            updateAccessToken,
            refreshAccessToken,
            getAccessToken,
            once,
            emit
        } = options;

        this.once = once;
        this.emit = emit;
        this.updateAccessToken = updateAccessToken;
        this.refreshAccessToken = refreshAccessToken;
        this.getAccessToken = getAccessToken;
        this.tokenManager = null;
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @type {Promise|null}
     *
     */
    public get currentTokenRefreshPromise() {
        return this.tokenManager?.currentTokenRefreshPromise || null;
    }

    /**
     *
     * @access protected
     * @since 8.0.0
     * @param {Promise|null} value
     * @type {Promise}
     *
     */
    public set currentTokenRefreshPromise(value: Nullable<Promise<unknown>>) {
        if (this.tokenManager) {
            this.tokenManager.currentTokenRefreshPromise = value;
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.TokenUpdater';
    }
}
