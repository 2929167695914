/**
 *
 * @module configurationHostName
 *
 */

/**
 *
 * @access public
 * @enum
 * @typedef {Object} SDK.Services.Configuration.ConfigurationHostName
 * @desc Enum for the possible host name values in the ConfigurationClientConfiguration
 *
 * @property {String} dev
 * @property {String} release - Since `29.0.0`. Formerly known as `prod` (renamed to align with SDK configuration nomenclature).
 * @property {String} staging - Since `4.10.0`.
 *
 */

enum ConfigurationHostName {
    dev = 'dev',
    release = 'release',
    staging = 'staging'
}

export default ConfigurationHostName;
