/**
 *
 * @module playerAdapter
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import DrmProvider from '../drm/drmProvider';

import Logger from '../logging/logger';
import PlaybackStartupEventData from '../services/qualityOfService/playbackStartupEventData';
import TokenRefreshFailure from '../token/tokenRefreshFailure';
import PlaybackEventListener from './playbackEventListener';

import PlaybackMetricsProvider from './playbackMetricsProvider';
import type Playlist from './playlist';
import type {
    MultivariantPlaylistFetchedEvent,
    PlaybackErrorInfo,
    PlaybackInitializedEvent,
    PlaybackReadyEvent,
    DrmKeyFetchedEvent,
    PlaybackStartedEvent,
    PlaybackPausedEvent,
    PlaybackResumedEvent,
    RebufferingStartedEvent,
    RebufferingEndedEvent,
    PlaybackEndedEvent,
    PlaybackSeekEndedEvent,
    BitrateChangedEvent,
    PlaybackSeekStartedEvent,
    VariantPlaylistFetchedEvent,
    NonFatalPlaybackErrorEvent,
    AdPodRequestedEvent,
    AdPodFetchedEvent,
    AdMultivariantFetchedEvent,
    AdPlaybackStartedEvent,
    PresentationTypeChangedEvent,
    AdVariantFetchedEvent,
    SnapshotCreatedEvent,
    AdPlaybackEndedEvent,
    InterstitialPlaybackEndedEvent,
    InterstitialPlaybackStartedEvent,
    PlaybackCustomEvent
} from './typedefs';

import { PodPosition, PlaybackMode } from '../services/qualityOfService/enums';

import { AdMetadata } from './typedefs';

import { DEFAULT_ZERO_GUID } from '../constants';

import EventListenerProvider from './eventListenerProvider';

export type HlsPlaylist = {
    url: string;
    priority: number;
    tracking: {
        conviva: Record<string, string>;
        telemetry: Record<string, string>;
        adEngine: Record<string, string>;
        qos: Record<string, string>;
    };
};

const defaultAdMetadata = {
    adSlotData: {
        adMediaId: DEFAULT_ZERO_GUID,
        slotNumber: 0,
        plannedLength: 0
    },
    adPodPlacement: {
        podPosition: PodPosition.preroll
    },
    adPodData: {
        plannedSlotCount: 0,
        plannedLength: 0
    },
    adPlayheadPosition: 0,
    mediaId: DEFAULT_ZERO_GUID,
    adSubtitleData: {
        subtitleVisibility: false
    }
};

/**
 *
 * @abstract
 * @desc Interface used to communicate with the media player.
 *
 */
export default class PlayerAdapter<
    TNativePlayer extends NativePlayer = NativePlayer
> extends PlaybackMetricsProvider {
    /**
     *
     * @access private
     * @type {NativePlayer}
     * @since 2.0.0
     *
     */
    public nativePlayer: Nullable<TNativePlayer>;

    /**
     *
     * @access private
     * @type {SDK.Media.PlaybackEventListener}
     * @since 2.0.0
     *
     */
    public listener?: PlaybackEventListener;

    /**
     *
     * @access public
     * @type {SDK.Drm.DrmProvider}
     * @since 3.2.0
     *
     */
    public drmProvider?: Nullable<DrmProvider>;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public accessToken?: Nullable<string>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 2.0.0
     *
     */
    protected boundHandlers: Record<string, Noop>;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Array<Function>}
     *
     */
    public offHandlerRemovalList: Array<Noop>;

    /**
     *
     * @access private
     * @type {Number}
     * @since 2.0.0
     * @desc Value provided if the metric (playhead or bitrate) is not available.
     *
     */
    private unavailableMetric?: Nullable<number>;

    /**
     *
     * @access private
     * @type {Object}
     * @since 3.6.0
     * @desc used to store adEngine data which is populated from the playlist service in playerAdapter.prepare()
     *
     */
    protected adEngineData: { ssess?: string };

    /**
     *
     * @access private
     * @type {Object}
     * @since 7.0.0
     * @desc used to determine cdnFallback settings
     *
     */
    public cdnFallback: {
        isEnabled: boolean;
        fallbackLimit: number;
        defaultTimeout: number;
    };

    /**
     *
     * @access public
     * @type {Promise<Void>}
     * @since 23.0.0
     * @desc Used to store fatal errors to be called later before releasing listeners.
     *
     */
    public fatalErrorPromise: Nullable<Promise<void>>;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {Object}
     * @desc Used to store various data related to ads.
     *
     */
    protected adData: {
        data: Record<string, AdMetadata | undefined>;
        adMetadata: AdMetadata;
    };

    /**
     *
     * @access private
     * @since 15.2.0
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Media.EventListenerProvider}
     *
     */
    protected eventListenerProvider: EventListenerProvider;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     *
     */
    public constructor(options: {
        nativePlayer: TNativePlayer;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    nativePlayer: Types.assigned,
                    videoPlayerName: Types.nonEmptyString,
                    videoPlayerVersion: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { nativePlayer, videoPlayerName, videoPlayerVersion } = options;

        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.nativePlayer = nativePlayer;
        this.listener = undefined;
        this.drmProvider = null;
        this.accessToken = null;
        this.boundHandlers = {};
        this.unavailableMetric = null;
        this.adEngineData = {};
        this.cdnFallback = {} as PlayerAdapter['cdnFallback'];
        this.fatalErrorPromise = null;

        this.adData = {
            data: {},
            adMetadata: defaultAdMetadata
        };

        this.logger = Logger.instance;
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, custom-rules/inferred-return-type
    public setSource(playlist: Playlist): Promise<void> {
        return Promise.reject(
            new Error(
                `${this.toString()}.setSource(playlist) - not-implemented`
            )
        );
    }

    /**
     *
     * @access public
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, custom-rules/inferred-return-type
    public setDrmProvider(drmProvider?: DrmProvider): Promise<void> {
        return Promise.reject(
            new Error(
                `${this.toString()}.setDrmProvider(drmProvider) - not-implemented`
            )
        );
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} [listener] - The instance of the {@link PlaybackEventListener} to use.
     * @desc Removes the listener after removing the individual player event handlers that had previously been added.
     * @returns {Void}
     *
     */
    public override removeListener(listener?: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener).optional
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        const isCorrectListener =
            Check.assigned(this.listener) && this.listener === listener;

        if (isCorrectListener && this.offHandlerRemovalList.length > 0) {
            this.listener = undefined;

            this.offHandlerRemovalList.forEach((offRemoval) => {
                offRemoval();
            });

            this.offHandlerRemovalList = [];
        }
    }

    /**
     *
     * @access public
     * @param {String} accessToken
     * @desc Notifies the player adapter that the access token has been updated.
     * Used for key service authentication.
     * @returns {Promise<Void>}
     *
     */
    public onAccessChanged(accessToken: string) {
        if (Check.string(accessToken) && Check.nonEmptyString(accessToken)) {
            this.accessToken = accessToken;
        }

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @param {SDK.Token.TokenRefreshFailure} tokenRefreshFailure
     * @desc Notifies the application that the token refresh has failed.
     * Key decryption will fail on next attempt.
     * @returns {Promise<SDK.Token.TokenRefreshFailure>}
     *
     */
    public onAccessFailed(tokenRefreshFailure: TokenRefreshFailure) {
        return Promise.resolve(tokenRefreshFailure);
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        super.clean();
    }

    /**
     *
     * @access public
     * @since 27.0.0
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} playbackMode
     * @desc Sets the Playback Mode.
     *
     */
    public setPlaybackMode(playbackMode: PlaybackMode) {
        this.onPlaybackModeChanged(playbackMode);
    }

    /**
     *
     * @access private
     * @param {Object} adEngineData
     * @desc overwrites local adEngine object in playbackSession.prepare()
     * @note used in xhrCallbacks for bam-hls and other platforms that need to reuse adEngine data
     *
     */
    public setAdEngineData(adEngineData: PlayerAdapter['adEngineData']) {
        this.adEngineData = adEngineData;
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackStartupEventData} args
     *
     */
    public onPlaybackStarted(args: PlaybackStartedEvent) {
        this.listener?.onPlaybackStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackPausedEventData} args
     *
     */
    public onPlaybackPaused(args: PlaybackPausedEvent) {
        this.listener?.onPlaybackPaused(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackResumedEventData} args
     *
     */
    public onPlaybackResumed(args: PlaybackResumedEvent) {
        this.listener?.onPlaybackResumed(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.RebufferingStartedEventData} args
     *
     */
    public onRebufferingStarted(args: RebufferingStartedEvent) {
        this.listener?.onRebufferingStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.RebufferingEndedEventData} args
     *
     */
    public onRebufferingEnded(args: RebufferingEndedEvent) {
        this.listener?.onRebufferingEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackEndedEventData} args
     *
     */
    public async onPlaybackEnded(args: PlaybackEndedEvent) {
        await this.listener?.onPlaybackEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackInitializedEventData} args
     *
     */
    public onPlaybackInitialized(args: PlaybackInitializedEvent) {
        this.listener?.onPlaybackInitialized(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.PlaybackReadyEventData} args
     *
     */
    public onPlaybackReady(args: PlaybackReadyEvent) {
        this.listener?.onPlaybackReady(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Media.PlaybackSeekStartedEvent} args
     *
     */
    public onPlaybackSeekStarted(args: PlaybackSeekStartedEvent) {
        this.listener?.onPlaybackSeekStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Media.PlaybackSeekEndedEvent} args
     *
     */
    public onPlaybackSeekEnded(args: PlaybackSeekEndedEvent) {
        this.listener?.onPlaybackSeekEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     *
     */
    public onAudioChanged() {
        this.listener?.onAudioChanged(this);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     *
     */
    public onSubtitleChanged() {
        this.listener?.onSubtitleChanged(this);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.BitrateChangedEventData} args
     *
     */
    public onBitrateChanged(args: BitrateChangedEvent) {
        this.listener?.onBitrateChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {SDK.Services.QualityOfService.MultivariantPlaylistFetchedEvent} args
     *
     */
    public onMultivariantPlaylistFetched(
        args: MultivariantPlaylistFetchedEvent
    ) {
        this.listener?.onMultivariantPlaylistFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {SDK.Services.QualityOfService.VariantPlaylistFetchedEventData} args
     *
     */
    public onVariantPlaylistFetched(args: VariantPlaylistFetchedEvent) {
        this.listener?.onVariantPlaylistFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {SDK.Services.QualityOfService.DrmKeyFetchedEventData} args
     *
     */
    public onDrmKeyFetched(args: DrmKeyFetchedEvent) {
        this.listener?.onDrmKeyFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {HlsPlaylist} args
     *
     */
    public onSuccessfulPlaylistLoad(args: HlsPlaylist) {
        this.listener?.onSuccessfulPlaylistLoad(this, args);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} args
     *
     */
    public onPlaybackReattempt(args: PlaybackStartupEventData) {
        this.listener?.onPlaybackReattempt(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} args
     *
     */
    public onAdBeaconError(args: NonFatalPlaybackErrorEvent) {
        this.listener?.onAdBeaconError(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.2
     * @param {Object} args
     *
     */
    public onAdRequestedError(args: NonFatalPlaybackErrorEvent) {
        this.listener?.onAdRequestedError(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPodRequested(args: AdPodRequestedEvent) {
        this.listener?.onAdPodRequested(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPodFetched(args: AdPodFetchedEvent) {
        this.listener?.onAdPodFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdMultivariantFetched(args: AdMultivariantFetchedEvent) {
        this.listener?.onAdMultivariantFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPlaybackStarted(args: AdPlaybackStartedEvent) {
        this.listener?.onAdPlaybackStarted(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdPlaybackEnded(args: AdPlaybackEndedEvent) {
        this.listener?.onAdPlaybackEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onPresentationTypeChanged(args: PresentationTypeChangedEvent) {
        this.listener?.onPresentationTypeChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 20.0.0
     * @param {Object} args
     *
     */
    public onAdVariantFetched(args: AdVariantFetchedEvent) {
        this.listener?.onAdVariantFetched(this, args);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} args
     *
     */
    public onPlaybackError(args: PlaybackErrorInfo) {
        this.listener?.onPlaybackError(this, args);
    }

    /**
     *
     * @access private
     * @since 27.0.0
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} args
     *
     */
    public onPlaybackModeChanged(args: PlaybackMode) {
        this.listener?.onPlaybackModeChanged(this, args);
    }

    /**
     *
     * @access private
     * @since 22.0.0
     * @param {Object} args
     *
     */
    public onSnapshotCreated(args: SnapshotCreatedEvent) {
        this.listener?.onSnapshotCreated(this, args);
    }

    /**
     *
     * @access private
     * @since 28.1.0
     * @param {Object} args
     *
     */
    public onInterstitialPlaybackEnded(args: InterstitialPlaybackEndedEvent) {
        this.listener?.onInterstitialPlaybackEnded(this, args);
    }

    /**
     *
     * @access private
     * @since 28.1.0
     * @param {Object} args
     *
     */
    public onInterstitialPlaybackStarted(
        args: InterstitialPlaybackStartedEvent
    ) {
        this.listener?.onInterstitialPlaybackStarted(this, args);
    }

    /**
     *
     * @access protected
     * @desc Normalizes playhead to ensure it is not negative or NaN
     * @returns {Number|null}
     *
     */
    public normalizePlayhead(playhead: number) {
        return playhead >= 0 ? playhead : this.unavailableMetric ?? null;
    }

    /**
     *
     * @access protected
     * @since 23.0.0
     * @desc Returns the `nativePlayer.currentInterstitial` or an empty `Object`
     * @returns {Object}
     *
     */
    public getCurrentInterstitial() {
        const { currentInterstitial } = this.nativePlayer || {};

        return currentInterstitial || ({} as Interstitial);
    }

    /**
     *
     * @access private
     * @since 28.2.0
     * @param {Object} args
     *
     */
    public onPlaybackCustomEvent(args: PlaybackCustomEvent) {
        this.listener?.onPlaybackCustomEvent(this, args);
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @desc Sets the `AdSlotData` in `AdMetadata`, from `AdData`, by `currentInterstitial`.
     *
     */
    protected setAdSlotData() {
        const currentInterstitial = this.getCurrentInterstitial();

        if (currentInterstitial.currentAsset) {
            const { index: slotNumber, mediaId: adMediaId } =
                currentInterstitial.currentAsset;

            const adDataData = this.getAdDataData(adMediaId, slotNumber);

            if (adDataData) {
                const { adSlotData = defaultAdMetadata.adSlotData } =
                    adDataData;

                this.adData.adMetadata.adSlotData = adSlotData;
            }
        }
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @param {String} adMediaId
     * @param {Number} slotNumber
     * @desc Retrieves stored adDataData.
     * @returns {Object|undefined}
     *
     */
    protected getAdDataData(adMediaId: string, slotNumber: number) {
        const metadataKey = this.getMetadataKey(adMediaId, slotNumber);

        return this.adData.data[metadataKey];
    }

    /**
     *
     * @access private
     * @since 29.0.0
     * @param {String} adMediaId
     * @param {Number} slotNumber
     * @desc Combines `adMediaId` and `slotNumber` to form a unique key.
     * @returns {String}
     *
     */
    protected getMetadataKey(adMediaId: string, slotNumber: number) {
        return `${adMediaId}-${slotNumber}`;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter';
    }
}
