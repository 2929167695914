/**
 *
 * @module flowControlPolicy
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import FlowControlPolicyType from './flowControlPolicyType';

/**
 *
 * @since 4.11.0
 * @desc Flow Control Policy that contains a `FlowControlPolicyType` that is associated with a given set of `EventTypes`.
 *
 */
export default class FlowControlPolicy {
    /**
     *
     * @access public
     * @since 4.11.0
     * @type {SDK.Socket.FlowControlPolicyType}
     * @desc Flow Control policy enum used by the SDK clients to set the associated
     * flow rate behavior for a specified event type
     *
     */
    public policy: FlowControlPolicyType;

    /**
     *
     * @access public
     * @since 4.11.0
     * @type {Array<String>}
     * @desc Array of event type urn
     *
     */
    public eventTypes: Array<string>;

    /**
     *
     * @param {Object} options
     * @param {SDK.Socket.FlowControlPolicyType} options.policy
     * @param {Array<String>} options.eventTypes
     *
     */
    public constructor(options: {
        policy: FlowControlPolicyType;
        eventTypes: Array<string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    policy: Types.in(FlowControlPolicyType),
                    eventTypes: Types.array.of.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { policy, eventTypes } = options;

        this.policy = policy;
        this.eventTypes = eventTypes;
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public toString() {
        return 'SDK.Socket.Messages.FlowControlPolicy';
    }
}
