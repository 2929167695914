/**
 *
 * @module playbackTelemetryConfiguration
 *
 */

import StreamSampleConfiguration from '../services/media/streamSampleConfiguration';

/**
 *
 * @since 2.0.0
 *
 */
export default class PlaybackTelemetryConfiguration extends StreamSampleConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlaybackTelemetryConfiguration';
    }
}
