/**
 *
 * @module httpHeaders
 *
 */

/**
 *
 * @access protected
 * @desc Provides an abstraction over an Object of HTTP headers that allows for case-insensitive read/write via get/set methods.
 *
 */
export default class HttpHeaders {
    /**
     *
     * @since 4.9.0
     * @type {Object}
     * @desc Optional hash map of HTTP headers.
     *
     */
    public headers: Record<string, string>;

    /**
     *
     * @param {Object} [headers]
     * @desc Construct an object with key/value pairs of HTTP headers.
     *
     */
    public constructor(headers?: Record<string, string>) {
        this.headers = {} as Record<string, string>;

        // copy in the headers (but standardize on lower-case key values)
        Object.keys(headers as Record<string, string>).forEach((key) => {
            this.headers[key.toLowerCase()] = (
                headers as Record<string, string>
            )[key];
        });
    }

    /**
     *
     * @param {String} key
     * @returns the value|undefined of the requested HTTP header key
     *
     */
    public get(key: string) {
        return this.headers[key.toLowerCase()];
    }

    /**
     *
     * @param {String} key
     * @param {String} value
     *
     */
    public set(key: string, value: string) {
        this.headers[key.toLowerCase()] = value;
    }

    /**
     *
     * @param {String} key
     * @since 28.0.0
     *
     */
    public delete(key: string) {
        delete this.headers[key.toLowerCase()];
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {String} key
     * @returns {Boolean}
     *
     */
    public has(key: string) {
        if (typeof key !== 'string') {
            return false;
        }

        return key.toLowerCase() in this.headers;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Shared.HttpHeaders';
    }
}
