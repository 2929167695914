/**
 *
 * @module subscriptionClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Subscription.SubscriptionClientEndpoint
 *
 * @property {String} getSubscriptions
 * @property {String} linkSubscriptionsFromDevice
 * @property {String} getSubscriberInfo - Since `4.13.0`.
 * @property {String} getAccountSubscription - Since `4.13.0`.
 *
 */
enum SubscriptionClientEndpoint {
    getSubscriptions = 'getSubscriptions',
    linkSubscriptionsFromDevice = 'linkSubscriptionsFromDevice',
    getSubscriberInfo = 'getSubscriberInfo',
    getAccountSubscription = 'getAccountSubscription'
}

export default SubscriptionClientEndpoint;
