/**
 *
 * @module experimentsDetails
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { ExperimentPlatform } from './enums';

/**
 *
 * @since 6.0.0
 * @desc This object contains the different variables that are required for experimentKeys when >= 1 experiment is in
 * progress.
 *
 */
export default class ExperimentsDetails {
    /**
     *
     * @access public
     * @since 6.0.0
     * @type {SDK.Services.QualityOfService.ExperimentPlatform}
     * @desc Name of testing platform used to run A/B tests, primarily on Marketing Landing Pages (MLPs).
     *
     */
    public experimentPlatform: ExperimentPlatform;

    /**
     *
     * @access public
     * @since 6.0.0
     * @type {String}
     * @desc The experiment ID value generated by Optimizely or the feature ID value generated by Weapon X.
     *
     */
    public experimentFeatureId: string;

    /**
     *
     * @access public
     * @since 6.0.0
     * @type {String}
     * @desc This value will replicate the experiment ID value generated by Optimizely or the feature ID value
     * generated by Weapon X.
     *
     */
    public experimentId: string;

    /**
     *
     * @access public
     * @since 6.0.0
     * @type {String}
     * @desc The variation ID value generated by Optimizely or the variant ID value generated by Weapon X.
     *
     */
    public variantId: string;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.QualityOfService.ExperimentPlatform} options.experimentPlatform
     * @param {String} options.experimentFeatureId
     * @param {String} options.experimentId
     * @param {String} options.variantId
     *
     */
    public constructor(options: {
        experimentPlatform: ExperimentPlatform;
        experimentFeatureId: string;
        experimentId: string;
        variantId: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    experimentPlatform: Types.in(ExperimentPlatform),
                    experimentFeatureId: Types.nonEmptyString,
                    experimentId: Types.nonEmptyString,
                    variantId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            experimentPlatform,
            experimentFeatureId,
            experimentId,
            variantId
        } = options;

        this.experimentPlatform = experimentPlatform;
        this.experimentFeatureId = experimentFeatureId;
        this.experimentId = experimentId;
        this.variantId = variantId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.ExperimentsDetails';
    }
}
