/**
 *
 * @module tokenClientEndpoint
 *
 */

/**
 *
 * @enum
 * @access protected
 * @typedef {Object} SDK.Services.Token.TokenClientEndpoint
 *
 * @property {String} exchange
 *
 */
enum TokenClientEndpoint {
    exchange = 'exchange'
}

export default TokenClientEndpoint;
