/**
 *
 * @module enums
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 *
 */

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.AdActivity
 * @desc Used to identify what ad-related activity is occurring in playback. This is a type 1 enum.
 *
 * @property {String} adPodRequested - Since `19.0.0` - When clients make a request via getPodRequest to the Disney Ad Server, applicable to SGAI-supporting clients only.
 * @property {String} adPodFetched - Since `19.0.0` - When clients receive a response from the Disney Ad Server for getPodRequest, applicable to SGAI-supporting clients only.
 * @property {String} adMultivariantFetched - Since `19.0.0` - When a multivariant playlist has been downloaded, applicable to SGAI-supporting clients only.
 * @property {String} adVariantFetched - Since `19.0.0` - When a variant playlist has been downloaded, applicable to SGAI-supporting clients only.
 * @property {String} adPlaybackStarted - Since `19.0.0` - When the first frame has been displayed for each ad slot, applicable to all ad-supporting client.
 * @property {String} adPlaybackEnded - Since `19.0.0` - When playback completes for any reason (e.g., successful playback to end, user exit before/during playback,
 * or playback failure) for each ad slot, applicable to all ad-supporting clients.
 *
 * @example
 * AdActivity.adPodRequested // 'adPodRequested'
 *
 */
export enum AdActivity {
    adPodRequested = 'adPodRequested',
    adPodFetched = 'adPodFetched',
    adMultivariantFetched = 'adMultivariantFetched',
    adVariantFetched = 'adVariantFetched',
    adPlaybackStarted = 'adPlaybackStarted',
    adPlaybackEnded = 'adPlaybackEnded'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.AdInsertionType
 * @desc Used to identify the way ads are inserted into the stream. This is a type 1 enum.
 *
 * @property {String} ssai - Since `19.0.0` - Server-side insertion.
 * @property {String} sgai - Since `19.0.0` - Server-guided client-side insertion.
 * @property {String} none - Since `19.0.0` - No ads inserted.
 *
 * @example
 * AdInsertionType.ssai // 'ssai'
 *
 */
export enum AdInsertionType {
    ssai = 'ssai',
    sgai = 'sgai',
    none = 'none'
}

/**
 *
 * @enum
 * @since 23.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ApplicationProtocol
 * @desc Defines the supported application protocols for resource fetching during playback. This is a type 1 enum.
 *
 * @property {String} http - Since `23.0.0` - The HTTP protocol.
 * @property {String} https - Since `23.0.0` - The HTTPS protocol.
 *
 * @example
 * ApplicationProtocol.https // 'https'
 *
 */
export enum ApplicationProtocol {
    http = 'http',
    https = 'https'
}

/**
 *
 * @enum
 * @since 23.0.0
 * @typedef {Object} SDK.Services.QualityOfService.AudioTrackType
 * @desc The type of the selected audio track. This is a type 1 enum.
 *
 * @property {String} primary - Since `23.0.0` - Indicates audio (not descriptive audio).
 * @property {String} descriptive - Since `23.0.0` - Indicates descriptive audio.
 * @property {String} unknown - Since `23.0.0` - Reserved for when the audio track type cannot be derived for any reason.
 *
 * @example
 * SDK.Services.QualityOfService.AudioTrackType.primary // 'primary'
 *
 */
export enum AudioTrackType {
    primary = 'primary',
    descriptive = 'descriptive',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 23.0.0
 * @typedef {Object} SDK.Services.QualityOfService.TimedTextTrackType
 * @desc The type of the selected timed text track. This is a type 1 enum.
 *
 * @property {String} normal - Since `23.0.0` - Regular subtitles.
 * @property {String} forced - Since `23.0.0` - Forced narratives.
 * @property {String} sdh - Since `23.0.0` - Subtitles for the Deaf and Hard of Hearing.
 * @property {String} cc - Since `23.0.0` - Closed captions.
 * @property {String} unknown - Since `23.0.0` - Reserved for when the timed text track type cannot be derived for any reason.
 *
 * @example
 * SDK.Services.QualityOfService.TimedTextTrackType.normal // 'normal'
 *
 */
export enum TimedTextTrackType {
    normal = 'normal',
    forced = 'forced',
    sdh = 'sdh',
    cc = 'cc',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 27.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackMode
 * @desc Window mode in which content is being played. This is a type 1 enum.
 *
 * @property {String} fullScreen - Since `27.0.0` - Full screen mode.
 * @property {String} pip - Since `27.0.0` - Picture-in-picture.
 * @property {String} windowed - Since `27.0.0` - Windowed mode.
 * @example
 * PlaybackMode.fullScreen // 'fullScreen'
 *
 */
export enum PlaybackMode {
    fullScreen = 'fullScreen',
    pip = 'pip',
    windowed = 'windowed'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PodPosition
 * @desc The position of the requested or played ad pod. This is a type 1 enum.
 *
 * @property {String} preroll - Since `19.0.0` - Before content.
 * @property {String} midroll - Since `19.0.0` - During content.
 * @property {String} postroll - Since `19.0.0` - After content.
 *
 * @example
 * PodPosition.preroll // 'preroll'
 *
 */
export enum PodPosition {
    preroll = 'preroll',
    midroll = 'midroll',
    postroll = 'postroll'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PresentationType
 * @desc A playback timeline consistent of smaller units of different content types or presentations. This property indicates the type currently playing.
 * @note The type is indicated in the manifest (when ads are interleaved) or implied from the playlist (when non-interleaved).
 * @note If bumper or dub card is not available / distinguishable, you can pass `main` in its place.
 * @note Default to `unknown` if in playing state but content type is unknown, or if value is not available.
 * @note This is a type 1 enum.
 *
 * @property {String} ad - Since `19.0.0`
 * @property {String} bumper - Since `19.0.0`
 * @property {String} main - Since `19.0.0`
 * @property {String} dubcard - Since `19.0.0`
 * @property {String} promo - Since `19.0.0`
 * @property {String} slug - Since `21.0.0`
 * @property {String} unknown - Since `19.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.PresentationType.ad // 'ad'
 *
 */
export enum PresentationType {
    ad = 'ad',
    bumper = 'bumper',
    main = 'main',
    dubcard = 'dubcard',
    promo = 'promo',
    slug = 'slug',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotEventType
 * @desc Used to identify what kind of event the player dispatched.
 *
 * @property {String} qosFragment - Since `19.0.0` - A video or audio media segment was downloaded.
 * @property {String} qosPlaylist - Since `19.0.0` - A multivariant or variant playlist was downloaded.
 * @property {String} seeking - Since `19.0.0` - The player started seeking.
 * @property {String} seeked - Since `19.0.0` - The player finished seeking.
 * @property {String} waiting - Since `19.0.0` - The player started rebuffering.
 * @property {String} canPlay - Since `19.0.0` - The player stopped rebuffering and has enough buffered data to start/resume playback.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotEventType.qosFragment // 'qosFragment'
 *
 */
export enum SnapshotEventType {
    qosFragment = 'qosFragment',
    qosPlaylist = 'qosPlaylist',
    seeking = 'seeking',
    seeked = 'seeked',
    waiting = 'waiting',
    canPlay = 'canPlay'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotNextAction
 * @desc Defines how player should proceed when handling upcoming downloads.
 *
 * @property {String} fail - Since `19.0.0` - Stop playback and report fatal failures.
 * @property {String} next - Since `19.0.0` - Continue to execute the next download behavior.
 * @property {String} retry - Since `19.0.0` - Retry the last download.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotNextAction.fail // 'fail'
 *
 */
export enum SnapshotNextAction {
    fail = 'fail',
    next = 'next',
    retry = 'retry'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotDownloadDataType
 * @desc Defines the various types of downloaded data resources that are tracked by a SnapshotEvent.
 *
 * @property {String} audio - Since `19.0.0` - This corresponds to either an audio variant playlist type or an audio fragment.
 * @property {String} multivariantPlaylist - Since `19.0.0` - This corresponds to the multivariant playlist being downloaded.
 * @property {String} video - Since `19.0.0` - This corresponds to either a video variant playlist type or a video fragment.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotDownloadDataType.audio // 'audio'
 *
 */
export enum SnapshotDownloadDataType {
    audio = 'audio',
    multivariantPlaylist = 'multivariantPlaylist',
    video = 'video'
}

/**
 *
 * @enum
 * @since 19.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotDownloadFailure
 * @desc Defines the download failure types of a SnapshotDownloadDataType that are tracked by a SnapshotEvent.
 *
 * @property {String} abort - Since `19.0.0` - The player proactively aborted the current download.
 * @property {String} error - Since `19.0.0` - non-2xx HTTP code.
 * @property {String} fragmentMissing - Since `19.0.0` - The player is trying to fetch a missing media resource (especially for Live streaming).
 * @property {String} playlistNotUpdated - Since `19.0.0` - The playlist doesn't update in a timely fashion.
 * @property {String} timeout - Since `19.0.0` - The download timed-out.
 * @property {String} unknown - Since `19.0.0` - Unknown failures that cannot be categorized.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotDownloadFailure.abort // 'abort'
 *
 */
export enum SnapshotDownloadFailure {
    abort = 'abort',
    error = 'error',
    fragmentMissing = 'fragmentMissing',
    playlistNotUpdated = 'playlistNotUpdated',
    timeout = 'timeout',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 20.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SnapshotFragmentType
 * @desc Defines the different fragment types that can be downloaded for a SnapshotDownloadDataType.
 *
 * @property {String} init - Since `20.0.0` - Downloading init sections.
 * @property {String} media - Since `20.0.0` - Downloading media segments.
 *
 * @example
 * SDK.Services.QualityOfService.SnapshotFragmentType.init // 'init'
 *
 */
export enum SnapshotFragmentType {
    init = 'init',
    media = 'media'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ApplicationContext
 * @desc Used to distinguish where in the application the error is being sent from.
 * @note `player` should be sent when an error occurs anywhere in the video player for most playback (content, bumpers, dub cards, etc.),
 * beginning from playback intent to playback ended.
 * @note `ad` should be sent when an error is related to ads, both inside and outside the video player.
 *
 * @property {String} player - Since `13.0.0`
 * @property {String} account - Since `13.0.0`
 * @property {String} login - Since `13.0.0`
 * @property {String} onboarding - Since `13.0.0`
 * @property {String} ad - Since `19.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.ApplicationContext.player // 'player'
 *
 */
export enum ApplicationContext {
    player = 'player',
    account = 'account',
    login = 'login',
    onboarding = 'onboarding',
    ad = 'ad'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.BufferType
 * @desc Describes type of buffering that is happening.
 *
 * @property {String} initializing - `Since 13.0.0` - When the user starts playing some playback (content or ads).
 * @property {String} seeking - `Since 13.0.0` - When the user seeks to a determined time on the playback (content or ads).
 * @property {String} resuming - `Since 13.0.0` - When the user resumes a playback (content or ads) that has paused.
 * @property {String} buffering - `Since 13.0.0` - When the type of playback changes, e.g. ad to content, content to bumper, etc.
 * @property {String} rebuffering - `Since 13.0.0` - When the user’s network connection slows down.
 * @property {String} stalePlaylist - `Since 13.0.0` - When one or more HLS variant refreshes (or MPD updates) fail to add new segments
 * to the end of the playback timeline resulting in buffer underrun.
 * @property {String} segmentGap - `Since 13.0.0` - When one or more segments in an HLS variant (or one or more fragments in the MPD)
 * are marked as Gaps (or missing) in the active buffer zone which would result in a jump by playback into the live
 * head holdback zone and the player prevents it by buffering instead.
 * @property {String} segmentDownloadFailure - `Since 13.0.0` - When one or more segments in an HLS variant (or one or more fragments)
 * fail to download in the active buffer zone which would result in a jump by playback into the live head holdback zone
 * and the player prevents it by buffering instead.
 * @property {String} unknown - Since 20.0.0 - The default value for `bufferType`, indicating that a `bufferType` can not be determined.
 *
 * @example
 * SDK.Services.QualityOfService.BufferType.initializing // 'initializing'
 *
 */
export enum BufferType {
    initializing = 'initializing',
    seeking = 'seeking',
    resuming = 'resuming',
    buffering = 'buffering',
    rebuffering = 'rebuffering',
    stalePlaylist = 'stalePlaylist',
    segmentGap = 'segmentGap',
    segmentDownloadFailure = 'segmentDownloadFailure',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ErrorLevel
 * @desc Impact level. In addition to indicating the level of impact that an event may have on the client, it also affects the verbosity of error reporting.
 *
 * @property {String} info - `Since 13.0.0` - Events which are never expected to lead to serious malfunction but may still be interesting for troubleshooting purposes.
 * @property {String} warn - `Since 13.0.0` - Events which are unlikely to be problematic on their own but repeated or frequent occurrences may indicate a serious problem.
 * @property {String} error - `Since 13.0.0` - Events which may result in serious malfunction, loss of function or termination.
 *
 * @example
 * SDK.Services.QualityOfService.ErrorLevel.warn // 'warn'
 *
 */
export enum ErrorLevel {
    info = 'info',
    warn = 'warn',
    error = 'error'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ErrorSource
 * @desc Defines where the error originated from.
 *
 * @property {String} app - `Since 13.0.0` - App errors come directly from the client and are present in the app itself (i.e. incorrect email format used for log in).
 * @property {String} player - `Since 13.0.0` - Player errors come directly from the player itself (i.e. HDCP errors).
 * @property {String} sdk - `Since 13.0.0` - SDK errors are ones rooted in the sdk (i.e. JSON serialization errors, misuse of the SDK).
 * @property {String} service - `Since 13.0.0` - Service errors are ones coming from back end service errors (i.e. unknown email used for log in).
 *
 * @example
 * SDK.Services.QualityOfService.ErrorSource.app // 'app'
 *
 */
export enum ErrorSource {
    app = 'app',
    player = 'player',
    sdk = 'sdk',
    service = 'service'
}

/**
 *
 * @enum
 * @since 6.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ExperimentPlatform
 * @desc Name of the testing platform used to run A/B tests, primarily on Marketing Landing Pages (MLPs).
 *
 * @property {String} optimizely - Since `6.0.0`.
 * @property {String} weaponX - Since `6.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.ExperimentPlatform.weaponX // 'weapon_x'
 *
 */
export enum ExperimentPlatform {
    optimizely = 'optimizely',
    weaponX = 'weapon_x'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.FetchStatus
 * @desc Defines the different types of fetch status.
 *
 * @property {String} completed - Since `4.0.0`.
 * @property {String} cancelled - Since `4.0.0`.
 * @property {String} noNetwork - Since `4.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.FetchStatus.completed // 'completed'
 *
 */
export enum FetchStatus {
    completed = 'completed',
    cancelled = 'cancelled',
    noNetwork = 'noNetwork'
}

/**
 *
 * @enum
 * @since 16.0.0
 * @typedef {Object} SDK.Services.QualityOfService.HeartbeatSampleType
 * @desc Defines the different types of playback heartbeat events.
 *
 * @property {String} periodic - Since `16.0.0` - An event that is produced on a ~30-second timer (along with an initial heartbeat on start / resume) should have type "periodic".
 * @property {String} user - Since `16.0.0` - A heartbeat that has been created in response to a user action that changes a property should have type "user".
 * @property {String} responsive - Since `16.0.0` - A heartbeat that has been created in response to changes to any other change in the property (e.g. the variant fetch changed the quality of the stream in any way) should have a "responsive" sample type.
 * @property {String} state - Since `16.0.0` - Anything that happens along with any other state change in playback (e.g. prior to pause or ended) should have type "state".
 *
 * @example
 * SDK.Services.QualityOfService.HeartbeatSampleType.periodic // 'periodic'
 *
 */
export enum HeartbeatSampleType {
    periodic = 'periodic',
    user = 'user',
    responsive = 'responsive',
    state = 'state'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.HttpMethod
 * @desc Defines the different types of http methods.
 *
 * @property {String} get - Since `4.0.0`.
 * @property {String} post - Since `4.0.0`.
 * @property {String} put - Since `4.0.0`.
 * @property {String} delete - Since `4.0.0`.
 * @property {String} patch - Since `4.0.0`.
 * @property {String} head - Since `22.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.HttpMethod.get // 'get'
 *
 */
export enum HttpMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
    head = 'head'
}

/**
 *
 * @enum
 * @since 28.1.0
 * @typedef {Object} SDK.Services.QualityOfService.InterstitialActivity
 * @desc Identifies an interstitial-related playback activity.
 *
 * @property {String} interstitialStarted - Since `28.1.0` - The first frame has been displayed for an interstitial.
 * @property {String} interstitialEnded - Since `28.1.0` - Playback has ended for any reason (e.g., successful playback to end, user exit before/during playback, or playback failure).
 *
 * @example
 * SDK.Services.QualityOfService.InterstitialActivity.interstitialStarted // 'interstitialStarted'
 *
 */
export enum InterstitialActivity {
    interstitialStarted = 'interstitialStarted',
    interstitialEnded = 'interstitialEnded'
}

/**
 *
 * @enum
 * @since 29.0.0
 * @typedef {Object} SDK.Services.QualityOfService.InterstitialEndedCause
 * @desc Identifies an interstitial-related playback activity.
 *
 * @property {String} applicationExit - Since `29.0.0` - To be used when the application was terminated while playing an interstitial.
 * @property {String} applicationBackground - Since `29.0.0` - To be used when the application was sent to background during interstitial playback. Note if playback continues in PiP, it is not considered to have ended.
 * @property {String} user - Since `29.0.0` - To be used when the user exits the player while playing an interstitial.
 * @property {String} error - Since `29.0.0` - To be used when a fatal error occurs which prevents playback from continuing.
 * @property {String} playedToEnd - Since `29.0.0` - To be used when the interstitial plays to completion.
 * @property {String} skip - Since `29.0.0` - When the user skips the interstitial.
 * @property {String} seek - Since `29.0.0` - When the user seeks to a specific point by tapping on the timeline or dragging the playhead to a new position on the timeline.
 * @property {String} scrub - Since `29.0.0` - When a new position outside of the interstitial is reached via linear reverse or forward playback at a non-1x rate.
 *
 * @example
 * SDK.Services.QualityOfService.InterstitialEndedCause.applicationExit // 'applicationExit'
 *
 */
export enum InterstitialEndedCause {
    applicationExit = 'applicationExit',
    applicationBackground = 'applicationBackground',
    user = 'user',
    error = 'error',
    playedToEnd = 'playedToEnd',
    skip = 'skip',
    seek = 'seek',
    scrub = 'scrub'
}

/**
 *
 * @enum
 * @since 28.1.0
 * @typedef {Object} SDK.Services.QualityOfService.InterstitialInsertionType
 * @desc Identifies the insertion type.
 *
 * @property {String} ssai - Since `28.1.0` - Server-side insertion.
 * @property {String} sgai - Since `28.1.0` - Server-guided client-side insertion.
 *
 * @example
 * SDK.Services.QualityOfService.InterstitialInsertionType.ssai // 'ssai'
 *
 */
export enum InterstitialInsertionType {
    ssai = 'ssai',
    sgai = 'sgai'
}

/**
 *
 * @enum
 * @since 28.1.0
 * @typedef {Object} SDK.Services.QualityOfService.InterstitialPlacement
 * @desc The position of the requested or played interstitial. This is a type 1 enum.
 *
 * @property {String} preroll - Since `28.1.0` - Before content.
 * @property {String} midroll - Since `28.1.0` - During content.
 * @property {String} postroll - Since `28.1.0` - After content.
 *
 * @example
 * SDK.Services.QualityOfService.InterstitialPlacement.preroll // 'preroll'
 *
 */
export enum InterstitialPlacement {
    preroll = 'preroll',
    midroll = 'midroll',
    postroll = 'postroll'
}

/**
 *
 * @enum
 * @since 28.1.0
 * @typedef {Object} SDK.Services.QualityOfService.InterstitialType
 * @desc The type of interstitial being tracked.
 *
 * @property {String} promo - Since `28.1.0` - In-stream promo.
 *
 * @example
 * SDK.Services.QualityOfService.InterstitialType.promo // 'promo'
 *
 */
export enum InterstitialType {
    promo = 'promo'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.MediaFetchError
 * @desc Defines the different types errors for media fetch.
 *
 * @property {String} ageNotVerified - Since `9.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * @property {String} ageNotVerifiedKr - Since `12.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * The user is located in South Korea.
 * @property {String} authenticationExpired - Since `4.0.0` - Authentication has expired and needs to be updated before proceeding.
 * @property {String} authorizationExpired - Since `4.0.0` - DSS user token expired.
 * @property {String} blackout - Since `4.0.0` - Failure due to user being blacked out from watching content.
 * @property {String} concurrency - Since `4.0.0` - Concurrency violation.
 * @property {String} contentNotAvailable - Since `4.0.0` - Failure due to content no longer being available.
 * @property {String} gdprConsentConflict - Since `26.0.0` - The account has not been cleared for consent.
 * @property {String} locationAcquisitionError - Since `9.0.0` - Unable to get user location due to location service being disabled.
 * NOTE: Only applicable on certain platforms.
 * @property {String} locationNotAllowed - Since `9.0.0` - User is not allowed to use the app in their current location.
 * Some products prohibit the use of the app in certain locations. NOTE: Only applicable for certain products.
 * @property {String} loginRequired - Since `9.0.0` - The requested media can only be viewed after authentication.
 * @property {String} mediaNotAllowed - Since `9.0.0` - The current user is not allowed to access the media for the requested license.
 * @property {String} networkFailure - Since `9.0.0` - Any failure due to a network related problem.
 * @property {String} notEntitled - Since `4.0.0` - Failure due to user not being entitled to watch content.
 * @property {String} parentalControlsRestricted - Since `9.0.0` - The current user is not allowed to access the media
 * due to parental controls restrictions on the profile.
 * @property {String} pcon - Since `4.0.0` - Failure due to user being locked out of content due to parental controls.
 * @property {String} pinExpired - Since `9.0.0` - The profile pin has expired and needs to be updated before proceeding.
 * @property {String} profileMissing - Since `9.0.0` - There was no active profile in the token or the service was unable to use it.
 * @property {String} profilePersonalInfoMissing - Since `23.0.0` - The user has not yet validated or provided personal info in their profile, required for ad serving
 * @property {String} profilePinMissing - Since `9.0.0` - The request requires a pin to be set on the profile but no pin has been set.
 * @property {String} requestBlocked - Since `9.0.0` - Device IP address is blacklisted.
 * @property {String} requestTemporarilyBlocked - Since `9.0.0` - Device IP address is temporarily blocked.
 * @property {String} temporarilyThrottled - Since `9.0.0` - Device IP address is temporarily throttled.
 * @property {String} temporarilyUnavailable - Since `9.0.0` - Server is too busy and rejected request.
 * @property {String} unexpectedError - Since `9.0.0` - An unexpected error has occurred.
 * @property {String} upgradeRequired - Since `9.0.0` - The service received a `X-BAMTech-R21-Unsupported` header and
 * determined this profile did not meet the requirements to play the requested media.
 * @property {String} unknown - Since `4.0.0` - All other errors.
 *
 * @example
 * SDK.Services.QualityOfService.MediaFetchError.networkFailure // 'networkFailure'
 *
 */
export enum MediaFetchError {
    ageNotVerified = 'ageNotVerified',
    ageNotVerifiedKr = 'ageNotVerifiedKr',
    authenticationExpired = 'authenticationExpired',
    authorizationExpired = 'authorizationExpired',
    blackout = 'blackout',
    concurrency = 'concurrency',
    contentNotAvailable = 'contentNotAvailable',
    gdprConsentConflict = 'gdprConsentConflict',
    locationAcquisitionError = 'locationAcquisitionError',
    locationNotAllowed = 'locationNotAllowed',
    loginRequired = 'loginRequired',
    mediaNotAllowed = 'mediaNotAllowed',
    networkFailure = 'networkFailure',
    notEntitled = 'notEntitled',
    parentalControlsRestricted = 'parentalControlsRestricted',
    pcon = 'pcon',
    pinExpired = 'pinExpired',
    profileMissing = 'profileMissing',
    profilePersonalInfoMissing = 'profilePersonalInfoMissing',
    profilePinMissing = 'profilePinMissing',
    requestBlocked = 'requestBlocked',
    requestTemporarilyBlocked = 'requestTemporarilyBlocked',
    temporarilyThrottled = 'temporarilyThrottled',
    temporarilyUnavailable = 'temporarilyUnavailable',
    unexpectedError = 'unexpectedError',
    upgradeRequired = 'upgradeRequired',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.MediaSegmentType
 * @desc Defines the different types of playlists.
 *
 * @property {String} video - Since `4.0.0` - Video
 * @property {String} audio - Since `4.0.0` - Audio
 * @property {String} subtitle - Since `4.0.0` - Subtitle
 * @property {String} iframe - Since `4.0.0` - Iframe
 *
 * @example
 * SDK.Services.QualityOfService.MediaSegmentType.video // 'video'
 *
 */
export enum MediaSegmentType {
    video = 'video',
    audio = 'audio',
    subtitle = 'subtitle',
    iframe = 'iframe'
}

/**
 *
 * @since 27.1.1
 * @desc Maps player variant types to `MediaSegmentType`
 * @note This is needed because the player sends 'subtitles'
 *
 * @property {String} video - Since `27.1.1` - Video
 * @property {String} audio - Since `27.1.1` - Audio
 * @property {String} subtitles - Since `27.1.1` - Subtitle
 * @property {String} subtitle - Since `27.1.1` - Subtitle
 * @property {String} iframe - Since `27.1.1` - IFrame
 *
 * @example
 * SDK.Services.QualityOfService.MediaSegmentTypeMap.subtitles // MediaSegmentType.subtitle
 *
 */
export const MediaSegmentTypeMap = {
    video: MediaSegmentType.video,
    audio: MediaSegmentType.audio,
    subtitles: MediaSegmentType.subtitle,
    subtitle: MediaSegmentType.subtitle,
    iframe: MediaSegmentType.iframe
};

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.NetworkError
 * @desc Defines the different types of network errors.
 *
 * @property {String} dns - Since `4.0.0` - A DNS failure.
 * @property {String} timeout - Since `4.0.0` - A request timeout.
 * @property {String} notConnected - Since `4.0.0` - Network connectivity error.
 * @property {String} prohibited - Since `4.0.0` - Server certificate validation error.
 * @property {String} unknown - Since `4.0.0` - All other errors.
 *
 * @example
 * SDK.Services.QualityOfService.NetworkError.dns // 'dns'
 *
 */
export enum NetworkError {
    dns = 'dns',
    timeout = 'timeout',
    notConnected = 'notConnected',
    prohibited = 'prohibited',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.NetworkType
 * @desc Defines the different types of networks.
 *
 * @property {String} cellular3g - Since `4.0.0`.
 * @property {String} cellular4g - Since `4.0.0`.
 * @property {String} cellular5g - Since `4.0.0`.
 * @property {String} wifi - Since `4.0.0`.
 * @property {String} ethernet - Since `4.0.0`.
 * @property {String} unknown - Since `4.0.0`.
 *
 * @example
 * SDK.Services.QualityOfService.NetworkType.wifi // 'wifi'
 *
 */
export enum NetworkType {
    cellular3g = '3g',
    cellular4g = '4g',
    cellular5g = '5g',
    wifi = 'wifi',
    ethernet = 'ethernet',
    unknown = 'unknown'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackActivity
 * @desc Describes the current playback activity of the player when sending a `urn:dss:event:playback:event:v1`.
 *
 * @property {String} bitrateChanged - `Since 13.0.0` - Indicates that the video bitrate has changed for the piece of content.
 * @property {String} ended - `Since 13.0.0` - Indicates that the video playback experience ends for any reason.
 * @property {String} paused - `Since 13.0.0` - Indicates that playback has paused.
 * @property {String} reattempt - `Since 28.1.0` - Indicates a systematic reattempt of playback due to non-fatal playback cases, including CDN fallback, after playback has started (VPF save).
 * @property {String} rebufferingEnded - `Since 13.0.0` - Indicates that the player has stopped rebuffering.
 * @property {String} rebufferingStarted - `Since 13.0.0` - Indicates that the player has started rebuffering.
 * @property {String} resumed - `Since 13.0.0` - Indicates that playback has resumed after pausing but not after buffering.
 * @property {String} seekEnded - `Since 13.0.0` - Indicates that the seek operation has completed.
 * @property {String} seekStarted - `Since 13.0.0` - Indicates that a seek has started (no other user-confirmation is needed).
 * @property {String} started - `Since 13.0.0` - Indicates that playback has started.
 * @property {String} variantFetched - `Since 27.0.0` - Indicates that audio, subtitle, or bitrate changes require a new variant.
 * @note Should not be sent for manifest loading events reported via StartupActivity.variantFetched.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackActivity.started // 'started'
 *
 */
export enum PlaybackActivity {
    bitrateChanged = 'bitrateChanged',
    ended = 'ended',
    paused = 'paused',
    reattempt = 'reattempt',
    rebufferingEnded = 'rebufferingEnded',
    rebufferingStarted = 'rebufferingStarted',
    resumed = 'resumed',
    seekEnded = 'seekEnded',
    seekStarted = 'seekStarted',
    started = 'started',
    variantFetched = 'variantFetched'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackExitedCause
 * @desc Defines the different end causes of playback.
 *
 * @property {String} applicationExit - Since `4.0.0` - When the application was terminated during playback.
 * @property {String} applicationBackground - Since `4.0.0` - When the application was sent to background during playback.
 * Note if playback continues in PiP, it is not considered to have ended.
 * @property {String} user - Since `4.0.0` - When the user exits the player.
 * @property {String} error - Since `4.0.0` - When a fatal error occurs which prevents playback from continuing.
 * @property {String} playedToEnd - Since `4.0.0` - When the media plays to completion and the player automatically closes.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackExitedCause.applicationExit // 'applicationExit'
 *
 */
export enum PlaybackExitedCause {
    applicationExit = 'applicationExit',
    applicationBackground = 'applicationBackground',
    user = 'user',
    error = 'error',
    playedToEnd = 'playedToEnd'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackIntent
 * @desc Defines the different types of playback intent.
 *
 * @property {String} autoplay - Since `4.0.0` - When the application automatically plays a video. e.g. the frontmost cell of a content carousel.
 * @property {String} userAction - Since `4.0.0` - When the user clicks or taps on a video to begin playback.
 * @property {String} autoAdvance - Since `4.0.0` - When a new media item automatically plays after the previous has
 * ended. e.g. when viewing a series season or playlist.
 * @property {String} background - Since `4.0.0` - When video is part of a UI element, such as a hero on a series detail page.
 * @property {String} pip - Since `4.0.0` - When the user explicitly enables picture in picture, or when implicitly enabled via backgrounding the app.
 * @property {String} transferred - Since `4.0.0` - When the user transfers the video to Chromecast or Airplay.
 * @property {String} userActionRestartButton - Since `4.8.0` - When the user clicks the restart button to resume playback from where they left off.
 * @property {String} tileFocus - Since `4.8.0` - When the user moves focus to a tile to begin playback, such as animated brand tiles.
 * @property {String} feedSwitch - Since `9.0.0` - To be used to indicate that a user has switched between feeds in live content.
 * @property {String} nextEpisode - Since `24.0.0` - To be used when the user clicks the "Next Episode" button.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackIntent.autoplay // 'autoplay'
 *
 */
export enum PlaybackIntent {
    autoplay = 'autoplay',
    userAction = 'userAction',
    autoAdvance = 'autoAdvance',
    background = 'background',
    pip = 'pip',
    transferred = 'transferred',
    userActionRestartButton = 'userActionRestartButton',
    tileFocus = 'tileFocus',
    feedSwitch = 'feedSwitch',
    nextEpisode = 'nextEpisode'
}

/**
 *
 * @enum
 * @since 5.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackPausedCause
 * @desc Reason playback paused.
 *
 * @property {String} user - Since `5.0.0` - If the user pressed pause.
 * @property {String} stall - Since `5.0.0` - If the player paused due to insufficient buffer.
 * @property {String} applicationBackgrounded - Since `5.0.0` - When the application was sent to background during playback.
 * Note if playback continues in PiP, it is not considered backgrounded.
 *
 */
export enum PlaybackPausedCause {
    user = 'user',
    stall = 'stall',
    applicationBackgrounded = 'applicationBackgrounded'
}

/**
 *
 * @enum
 * @since 5.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackResumedCause
 * @desc Reason playback resumed.
 *
 * @property {String} user - Since `5.0.0` - If the user pressed play from a paused state.
 * @property {String} stall - Since `5.0.0` - If the player buffered enough to resume playback.
 * @property {String} applicationForegrounded - Since `5.0.0` - Where the application is brought to foreground
 * after being previously background.
 * @property {String} app - Since `23.0.0` - If a user has paused live content and exceeds available window,
 * they will be unpaused and start at end of the window.
 *
 */
export enum PlaybackResumedCause {
    user = 'user',
    stall = 'stall',
    applicationForegrounded = 'applicationForegrounded',
    app = 'app'
}

/**
 *
 * @enum
 * @since 4.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackSeekCause
 * @desc Defines the different types of playlists.
 *
 * @property {String} app - Since `4.0.0` - When the player jumps to live or the application decides the target playhead.
 * @property {String} seek - Since `4.0.0` - To be used when the user seeks to a specific point by tapping on the
 * timeline or dragging the playhead to a new position on the timeline.
 * @property {String} scrub - Since `4.0.0` - When a new position is reached via linear reverse or forward playback at a non-1x rate.
 * @property {String} skip - Since `4.0.0` - When the user skips to a milestone (intro skip, recap end).
 * @property {String} startAtBookmark - Since `23.0.0` - When there is a seek event sent prior to playback started, which can occur on instances like 'continue watching'.
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackSeekCause.app // 'app'
 *
 */
export enum PlaybackSeekCause {
    app = 'app',
    seek = 'seek',
    scrub = 'scrub',
    skip = 'skip',
    startAtBookmark = 'startAtBookmark'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaybackState
 * @desc Defines the current state of playback.
 *
 * @property {String} buffering - `Since 13.0.0`
 * @property {String} finished - `Since 13.0.0`
 * @property {String} paused - `Since 13.0.0`
 * @property {String} playing - `Since 13.0.0`
 * @property {String} seeking - `Since 15.2.3`
 * @property {String} notready - `Since 27.1.0`
 *
 * @example
 * SDK.Services.QualityOfService.PlaybackState.buffering // 'buffering'
 *
 */
export enum PlaybackState {
    buffering = 'buffering',
    finished = 'finished',
    paused = 'paused',
    playing = 'playing',
    seeking = 'seeking',
    notready = 'notready'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlayerSeekDirection
 * @desc Information about the player seek direction.
 *
 * @property {String} forward - `Since 13.0.0` - User is seeking towards live playback.
 * @property {String} backward - `Since 13.0.0` - User is seeking backwards from live event.
 * @property {String} same - `Since 13.0.0` - Odd edge-case here. But some players don't prevent you from seeking to the current position.
 *
 * @example
 * SDK.Services.QualityOfService.PlayerSeekDirection.forward // 'forward'
 *
 */
export enum PlayerSeekDirection {
    forward = 'forward',
    backward = 'backward',
    same = 'same'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.PlaylistBitrateType
 * @desc Used to distinguish what the playlist bitrate type is (average or peak).
 *
 * @property {String} average - `Since 13.0.0`
 * @property {String} peak - `Since 13.0.0`
 *
 * @example
 * SDK.Services.QualityOfService.PlaylistBitrateType.average // 'average'
 *
 */
export enum PlaylistBitrateType {
    average = 'average',
    peak = 'peak'
}

/**
 *
 * @access protected
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.ProductType
 * @desc Defines the Product type, Live or VOD.
 *
 * @property {String} live - `Since 13.0.0` - Indicates that the content type is Live.
 * @property {String} vod - `Since 13.0.0` - Indicates that the content type is VOD.
 *
 * @example
 * SDK.Services.QualityOfService.ProductType.live // 'Live'
 *
 */
export enum ProductType {
    live = 'Live',
    vod = 'VOD'
}

/**
 *
 * @enum
 * @since 16.0.0
 * @typedef {Object} SDK.Services.QualityOfService.QoePlaybackError
 * @desc Defines the different types of playback startup, playback, and ad errors that can be reported for QoE.
 * @note This enum is currently the superset of values from a PlaybackError and MediaFetchError.
 * @note Fallback enum, defines a set of known values, as well as a `catch all` value. SDK Type 2 enum.
 *
 * @property {String} drmHdcp - Since `16.0.0` - All HDCP related errors.
 * @property {String} drmDecryption - Since `16.0.0` - DRM decryption error.
 * @property {String} drmExpiredLicense - Since `16.0.0` - DRM license expired.
 * @property {String} drmSecurityLevel - Since `16.0.0` - DRM security level error.
 * @property {String} drmOther - Since `16.0.0` - All other DRM errors.
 * @property {String} networkFailure - Since `16.0.0` - Any failure due to a network related problem.
 * @property {String} serviceError - Since `16.0.0` - Failure due to HTTP errors when requesting media.
 * @property {String} authorizationExpired - Since `16.0.0` - DSS user token expired.
 * @property {String} concurrency - Since `16.0.0` - Concurrency violation.
 * @property {String} pcon - Since `16.0.0` - Failure due to user being locked out of content due to parental controls.
 * @property {String} blackout - Since `16.0.0` - Failure due to user being blacked out from watching content.
 * @property {String} notEntitled - Since `16.0.0` - Failure due to user not being entitled to watch content.
 * @property {String} contentNotAvailable - Since `16.0.0` - Failure due to content no longer being available.
 * @property {String} ageNotVerified - Since `16.0.0` - The age for the users profile has not been verified and needs to be before proceeding.
 * @property {String} authenticationExpired - Since `16.0.0` - Authentication has expired and needs to be updated before proceeding.
 * @property {String} locationAcquisitionError - Since `16.0.0` - Unable to get user location due to location service being disabled. NOTE Only applicable on certain platforms.
 * @property {String} locationNotAllowed - Since `16.0.0` - User is not allowed to use the app in their current location. Some products prohibit the use of the app in certain locations. NOTE. Only applicable for certain products.
 * @property {String} loginRequired - Since `16.0.0` - The requested media can only be viewed after authentication.
 * @property {String} mediaNotAllowed - Since `16.0.0` - The current user is not allowed to access the media for the requested license.
 * @property {String} parentalControlsRestricted - Since `16.0.0` - The current user is not allowed to access the media due to parental controls restrictions on the profile.
 * @property {String} pinExpired - Since `16.0.0` - The profile pin has expired and needs to be updated before proceeding.
 * @property {String} profileMissing - Since `16.0.0` - There was no active profile in the token or the service was unable to use it.
 * @property {String} profilePersonalInfoMissing - Since `23.0.0` - The user has not yet validated or provided personal info in their profile, required for ad serving
 * @property {String} profilePinMissing - Since `16.0.0` - The request requires a pin to be set on the profile but no pin has been set.
 * @property {String} requestBlocked - Since `16.0.0` - Device IP address is blacklisted.
 * @property {String} requestTemporarilyBlocked - Since `16.0.0` - Device IP address is temporarily blocked.
 * @property {String} temporarilyThrottled - Since `16.0.0` - Device IP address is temporarily throttled.
 * @property {String} temporarilyUnavailable - Since `16.0.0` - Server is too busy and rejected request.
 * @property {String} unexpectedError - Since `16.0.0` - An unexpected error has occurred.
 * @property {String} upgradeRequired - Since `16.0.0` - The service received a `X-BAMTech-R21-Unsupported` header and determined this profile did not meet the requirements to play the requested media.
 * @property {String} unknown - Since `16.0.0` - All other errors.
 * @property {String} adBeaconError - Since `19.0.0` - Client failed to send ad beacon to ad server.
 * @property {String} adServerError - Since `19.0.0` - Client failed to request from ad server or ad server response can't be parsed.
 * @property {String} gdprConsentConflict - Since `27.0.0` - The account has not been cleared for consent.
 *
 * @example
 * SDK.Services.QualityOfService.QoePlaybackError.drmHdcp // 'drmHdcp'
 *
 */
export enum QoePlaybackError {
    drmHdcp = 'drmHdcp',
    drmDecryption = 'drmDecryption',
    drmExpiredLicense = 'drmExpiredLicense',
    drmSecurityLevel = 'drmSecurityLevel',
    drmOther = 'drmOther',
    networkFailure = 'networkFailure',
    serviceError = 'serviceError',
    authorizationExpired = 'authorizationExpired',
    concurrency = 'concurrency',
    pcon = 'pcon',
    blackout = 'blackout',
    notEntitled = 'notEntitled',
    contentNotAvailable = 'contentNotAvailable',
    ageNotVerified = 'ageNotVerified',
    authenticationExpired = 'authenticationExpired',
    locationAcquisitionError = 'locationAcquisitionError',
    locationNotAllowed = 'locationNotAllowed',
    loginRequired = 'loginRequired',
    mediaNotAllowed = 'mediaNotAllowed',
    parentalControlsRestricted = 'parentalControlsRestricted',
    pinExpired = 'pinExpired',
    profileMissing = 'profileMissing',
    profilePersonalInfoMissing = 'profilePersonalInfoMissing',
    profilePinMissing = 'profilePinMissing',
    requestBlocked = 'requestBlocked',
    requestTemporarilyBlocked = 'requestTemporarilyBlocked',
    temporarilyThrottled = 'temporarilyThrottled',
    temporarilyUnavailable = 'temporarilyUnavailable',
    unexpectedError = 'unexpectedError',
    upgradeRequired = 'upgradeRequired',
    unknown = 'unknown',
    adBeaconError = 'adBeaconError',
    adServerError = 'adServerError',
    gdprConsentConflict = 'gdprConsentConflict'
}

/**
 *
 * @enum
 * @since 9.0.0
 * @typedef {Object} SDK.Services.QualityOfService.SeekDirection
 * @desc Defines the different directions of seek.
 *
 * @property {String} fromLiveEdge - Since `9.0.0` - User is seeking backwards from live event.
 * @property {String} toLiveEdge - Since `9.0.0` - User is seeking towards live playback.
 *
 * @example
 * SDK.Services.QualityOfService.SeekDirection.toLiveEdge // 'toLiveEdge'
 *
 */
export enum SeekDirection {
    fromLiveEdge = 'fromLiveEdge',
    toLiveEdge = 'toLiveEdge'
}

/**
 *
 * @enum
 * @since 13.0.0
 * @typedef {Object} SDK.Services.QualityOfService.StartupActivity
 * @desc Describes the current startup activity of the player when sending a `urn:dss:event:client:playback:startup:v1`.
 *
 * @property {String} requested - `Since 13.0.0` - Indicates that the user has initiated playback.
 * @property {String} ready - `Since 13.0.0` - Indicates that enough media has been buffered for playback to begin.
 * @property {String} initialized - `Since 13.0.0` - Indicates that the player is initialized with a playlist.
 * @property {String} fetched - `Since 13.0.0` - Indicates that a call to the Playlist Service was requested to get information about a stream.
 * @property {String} masterFetched - `Since 13.0.0` - Indicates that a fetch of the master m3u8 has been requested.
 * @property {String} variantFetched - `Since 13.0.0` - Indicates that a fetch of a variant m3u8 has been requested.
 * @property {String} drmCertificateFetched - `Since 13.0.0` - Indicates that a fetch of a DRM certificate has been requested.
 * @property {String} drmKeyFetched - `Since 13.0.0` - Indicates that a fetch of a DRM key has been requested.
 * @property {String} reattempt - `Since 13.0.0` - Indicates a subsequent systematic reattempt playback due to non fatal playback cases, including CDN fallback.
 * @property {String} preparing - `Since 23.0.0` - Indicates that the playback session is being prepared and the player is initializing.
 * This activity should be sent just once per playback session, even in CDN fallback scenarios.
 * @note This event should be sent by SDKs when `Bam.Sdk.Media.PlaybackSession.prepare()` is called.
 *
 * @example
 * SDK.Services.QualityOfService.StartupActivity.requested // 'requested'
 *
 */
export enum StartupActivity {
    requested = 'requested',
    ready = 'ready',
    initialized = 'initialized',
    fetched = 'fetched',
    masterFetched = 'masterFetched',
    variantFetched = 'variantFetched',
    drmCertificateFetched = 'drmCertificateFetched',
    drmKeyFetched = 'drmKeyFetched',
    reattempt = 'reattempt',
    preparing = 'preparing'
}

/**
 *
 * @enum SkipType
 * @since 28.0.0
 * @desc This enum is used to indicate user engagement on skip functionality during playback.
 * @typedef {Object} SDK.Services.QualityOfService.SkipType
 * @property {String} skipIntro - Since `28.0.0` - To be used when the player jumps over the intro.
 * @property {String} skipRecap - Since `28.0.0` - To be used when the player jumps over the recap.
 * @property {String} skipGeneral - Since `28.0.0` - To be used for all other skips types (i.e. +/- 10s, skipPromo).
 * @property {String} unknown - Since `28.0.0` - Default value where skip type can't be derived.
 *
 */
export enum SkipType {
    skipIntro = 'skipIntro',
    skipRecap = 'skipRecap',
    skipGeneral = 'skipGeneral',
    unknown = 'unknown'
}
