/**
 *
 * @module dustDevicePayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc describes the device payload
 *
 */
export default class DustDevicePayload {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    public os: string;

    /**
     *
     * @access private
     * @type {String}
     *
     */
    public model: string;

    /**
     *
     * @access private
     * @since 12.0.0
     * @type {String|undefined}
     *
     */
    public brand?: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String|null}
     *
     */
    public modelFamily: Nullable<string>;

    /**
     *
     * @access private
     * @since 15.1.0
     * @type {String|undefined}
     *
     */
    public platformId?: string;

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.os
     * @param {String} options.model
     * @param {String} [options.brand]
     * @param {String} [options.modelFamily=null]
     * @param {String} [options.platformId]
     *
     */
    public constructor(options: {
        os: string;
        model: string;
        brand?: string;
        modelFamily?: Nullable<string>;
        platformId?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    os: Types.nonEmptyString,
                    model: Types.nonEmptyString,
                    brand: Types.nonEmptyString.optional,
                    modelFamily: Types.nonEmptyString.optional,
                    platformId: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { os, model, brand, modelFamily, platformId } = options;

        this.os = os;
        this.model = model;
        this.brand = brand;
        this.modelFamily = modelFamily || null;
        this.platformId = platformId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustDevicePayload';
    }
}
