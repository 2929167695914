/**
 *
 * @module processServerErrors
 *
 */

import ErrorReason from '../../exception/errorReason';

import flexErrorHandler from './handlers/flexErrorHandler';
import genericErrorHandler from './handlers/genericErrorHandler';
import graphErrorHandler from './handlers/graphErrorHandler';
import serviceCapacityErrorHandler from './handlers/serviceCapacityErrorHandler';
import stringErrorHandler from './handlers/stringErrorHandler';

import { ServerErrorData } from './typedefs';

type ProcessOutput = {
    errorReasons: Array<ErrorReason>;
    _isGraphError?: boolean;
};

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Normalizes the error data returned by the service and populates the errors array.
 * @note The if checks are in a hierarchical order of highest to lowest priority. Since we push onto the errors array
 * the highest priority error will be in the [0] slot for ErrorCase creation, but still enables us to wrap up all the additional
 * errors into the ErrorReasons array.
 *
 */
export default function processServerErrors(
    data: ServerErrorData,
    headers: Headers,
    isError: boolean,
    isSuccessStatus: boolean
) {
    const errors: Array<ErrorReason> = [];

    const handlers = [
        serviceCapacityErrorHandler,
        flexErrorHandler,
        graphErrorHandler,
        stringErrorHandler,
        genericErrorHandler
    ];

    const options = {
        data,
        headers,
        isError,
        isSuccessStatus
    };

    let isGraphError = false;

    handlers.forEach((handler) => {
        if (handler.is(options)) {
            const output: ProcessOutput = handler.process(options);
            const { errorReasons, _isGraphError } = output;

            errorReasons.forEach((error: ErrorReason) => {
                errors.push(error);
            });

            if (_isGraphError) {
                isGraphError = true;
            }
        }
    });

    return { errors, isGraphError };
}
