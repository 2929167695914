/**
 *
 * @module getKeyForKeyValue
 *
 */

/**
 *
 * @access private
 * @since 11.0.0
 * @param {Object} obj - Object to search keys/values for `valueOrKey`
 * @param {String} valueOrKey - The value to find within `obj`
 * @desc This will take an object and if it's key exists it returns that key otherwise it looks through the values to find it and returns it's associated key.
 * @returns {String|undefined}
 *
 */
export default function getKeyForKeyValue(
    obj: Record<string, unknown>,
    valueOrKey: TodoAny
) {
    if (valueOrKey in obj) {
        return valueOrKey;
    }

    const foundType = Object.entries(obj).find(([, value]) => {
        return value === valueOrKey;
    });

    if (foundType) {
        return foundType[0];
    }

    return undefined;
}
