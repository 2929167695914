/**
 *
 * @module sessionInfoChangedEvent
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import SessionInfo from './services/session/sessionInfo';

/**
 *
 * @since 4.9.0
 * @desc Emitted by SdkSession when the session info associated with the underlying access
 * token has changed, providing access to both the old and new SessionInfo objects.
 *
 */
export default class SessionInfoChangedEvent {
    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Session.SessionInfo|undefined}
     *
     */
    public oldSessionInfo?: SessionInfo;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {SDK.Session.SessionInfo|undefined}
     *
     */
    public newSessionInfo?: SessionInfo;

    /**
     *
     * @param {SDK.Session.SessionInfo} [oldSessionInfo]
     * @param {SDK.Session.SessionInfo} [newSessionInfo]
     *
     */
    public constructor(
        oldSessionInfo?: SessionInfo,
        newSessionInfo?: SessionInfo
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                oldSessionInfo: Types.instanceStrict(SessionInfo).optional,
                newSessionInfo: Types.instanceStrict(SessionInfo).optional
            };

            typecheck(this, params, arguments);
        }

        this.oldSessionInfo = oldSessionInfo;
        this.newSessionInfo = newSessionInfo;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.SessionInfoChangedEvent';
    }
}
