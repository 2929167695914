/**
 *
 * @module subscriptionFreeTrial
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { SubscriptionFreeTrialStatus } from './enums';

/**
 *
 * @since 4.2.4
 * @desc Free trial information for the subscription.
 *
 */
export default class SubscriptionFreeTrial {
    /**
     *
     * @access public
     * @since 4.2.4
     * @type {String<SDK.Services.Subscription.SubscriptionFreeTrialStatus>}
     * @desc Whether or not the subscription is in a free trial.
     * The status may be unknown if the client that created the subscription
     * cannot know for sure if the subscription is in a free trial or not.
     * For D2C subscriptions we should know with certainty if the subscription
     * is in free trial or not.
     *
     */
    public status: SubscriptionFreeTrialStatus;

    /**
     *
     * @access public
     * @since 4.2.4
     * @type {String|undefined}
     * @desc The projected end date of the subscription's free trial.
     * @note ("YYYY-MM-DDThh:mm:ss.sssZ") Should conform to ISO-8601.
     *
     */
    public endDate?: ISO_8601;

    /**
     *
     * @param {Object} options
     * @param {String} options.status
     * @param {String} [options.endDate]
     *
     */
    public constructor(options: { status: string; endDate?: ISO_8601 }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    status: Types.nonEmptyString,
                    endDate: Types.dateString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { status, endDate } = options;

        this.status =
            SubscriptionFreeTrialStatus[
                status.toLowerCase() as keyof typeof SubscriptionFreeTrialStatus
            ] || SubscriptionFreeTrialStatus.unknown;
        this.endDate = endDate;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.SubscriptionFreeTrial';
    }
}
