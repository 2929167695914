/**
 *
 * @module edgeSink
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#edge-logging
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#sdk-instance-id
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';

import DustUrnReference from '../../services/internal/dust/dustUrnReference';

import EdgeEvent from './edgeEvent';

import LogSink from '../../logging/logSink';
import LogEvent from '../../logging/logEvent';
import MessageEnvelope from '../../socket/messageEnvelope';
import SocketSchemaUrls from '../../socket/socketSchemaUrls';
import EnvelopeMessageRouter from '../envelopeMessageRouter';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @desc Sink implementation that logs to DUST Service via `SocketManager.sendMessage`.
 *
 */
export default class EdgeSink extends LogSink {
    /**
     *
     * @access private
     * @since 28.0.0
     * @type {EnvelopeMessageRouter}
     *
     */
    private envelopeMessageRouter: EnvelopeMessageRouter;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc Determines if dust logs are to be considered for queuing or sending to the Socket service.
     * @note The isEnabled boolean will discard all events if false (enabled by default).
     *
     */
    public isEnabled: boolean;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Logging.Logger} logger
     * @param {EnvelopeMessageRouter} envelopeMessageRouter
     *
     */
    public constructor(
        logger: Logger,
        envelopeMessageRouter: EnvelopeMessageRouter
    ) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger),
                envelopeMessageRouter: Types.instanceStrict(
                    EnvelopeMessageRouter
                )
            };

            typecheck(this, params, arguments);
        }

        this.envelopeMessageRouter = envelopeMessageRouter;
        this.isEnabled = true;
        this.logger = logger;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @param {Object} event - The event to be logged.
     * @desc receives an event from logger.log and creates a dust event and posts it to the socket.
     * @note `event.isPublic` indicates this is a `Dust-like` event and extraData.isEdge indicates that
     * it is intended to be sent to the socket, i.e. not a `StreamSample` event
     * @note if `isEnabled` is false, the event is ignored and not processed further.
     * @returns {Promise<Void>}
     *
     */
    public log(event: LogEvent) {
        const extraData = LogEvent.getExtraData(event);

        if (this.isEnabled) {
            if (event.isPublic && extraData.isEdge) {
                this.postEvent(event);
            }
        }

        return Promise.resolve();
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @desc Enables the `EdgeSink`.
     * @note called from `SdkSession.enableDustSink`
     *
     */
    public enableEdgeSink() {
        this.isEnabled = true;
    }

    /**
     *
     * @access public
     * @since 13.0.0
     * @desc Disables the DUST sink.
     * @note called from `SdkSession.createSdkSession`
     * @note this disables the `EdgeSink` for the remainder of the SDK lifecycle
     *
     */
    public disableEdgeSink() {
        this.isEnabled = false;
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} event
     * @desc creates an instance of `SDK.Internal.Dust.EdgeEvent`
     * @returns {SDK.Internal.Dust.EdgeEvent}
     *
     */
    public createEdgeEvent(event: LogEvent) {
        const {
            urn,
            file,
            line,
            serviceRequests,
            startTime,
            totalDuration,
            error,
            sdkInstanceId
        } = LogEvent.getExtraData(event);

        return new EdgeEvent({
            invocation: {
                urn,
                location: {
                    file,
                    line
                }
            },
            startTime,
            totalDuration,
            services: serviceRequests,
            error,
            sdkInstanceId
        });
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} event - The event to be posted.
     *
     */
    private async postEvent(event: LogEvent) {
        try {
            const edgeEvent = this.createEdgeEvent(event);

            const envelope = new MessageEnvelope({
                eventType: SocketUrns.socketManager.event,
                schemaUrl: SocketSchemaUrls.event,
                data: edgeEvent
            });

            await this.envelopeMessageRouter.route(envelope);
        } catch (ex) {
            this.logger.warn(
                this.toString(),
                `Error creating SDK.Internal.Dust.EdgeEvent ${
                    (ex as Error).message
                }`
            );
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Internal.Dust.EdgeSink';
    }
}
