/**
 *
 * @module qrCodeFormat
 *
 */

/**
 *
 * @access public
 * @since 26.0.0
 * @typedef {Object} SDK.QRCode.QRCodeFormat
 * @desc File format for the desired QR Code.
 *
 * @property {String} jpeg - Since `26.0.0`
 * @property {String} png - Since `26.0.0`
 *
 * @example
 * SDK.QRCode.QRCodeFormat.jpeg // 'jpeg'
 *
 */
enum QRCodeFormat {
    jpeg = 'jpeg',
    png = 'png'
}

export default QRCodeFormat;
