/**
 *
 * @module updateDeviceOperatingSystemRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';
import { updateDeviceOperatingSystem } from './queries.gql';

/**
 *
 * @since 9.0.0
 *
 */
export default class UpdateDeviceOperatingSystemRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: unknown) {
        const variables = {
            input
        };

        super({
            query: updateDeviceOperatingSystem,
            variables,
            operationName: 'updateDeviceOperatingSystem'
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.UpdateDeviceOperatingSystemRequest';
    }
}
