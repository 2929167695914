/**
 *
 * @module dustServerPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { HttpCoreMethod } from '../../providers/typedefs';

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc describes any relevant server information
 * @note if the dustEvent did not include a service call this will be unused
 *
 */
export default class DustServerPayload {
    /**
     *
     * @access private
     * @type {String}
     * @desc Scheme and Hostname of the request.
     * @note example: "https://bam-sdk-configs.bamgrid.com".
     *
     */
    public host: string;

    /**
     *
     * @access private
     * @type {String}
     * @desc Path and Query Paramaters of the request.
     * @note example: "/bam-sdk/v4.0/launchpad/microsoft/v14.1/uwp/desktop/qa.json?agt_appde=qa&agt_apprp=debug&agt_drp=19044".
     *
     */
    public path: string;

    /**
     *
     * @access private
     * @type {HttpCoreMethod}
     * @desc HTTP method used in the request.
     *
     */
    public method: string;

    /**
     *
     * @access private
     * @type {Number|undefined}
     * @desc HTTP status code returned in the response.
     *
     */
    public statusCode?: number;

    /**
     *
     * @access private
     * @type {String|undefined}
     * @desc Platform request identifier, returned as X-Request-ID header in the response.
     *
     */
    public requestId?: string;

    /**
     *
     * @access private
     * @type {Number|undefined}
     * @desc Duration in milliseconds between request and response, as measured by the client.
     *
     */
    public roundTripTime?: number;

    /**
     *
     * @access private
     * @param {Object} options
     * @param {String} options.host
     * @param {String} options.path
     * @param {HttpCoreMethod} options.method
     * @param {Number} [options.statusCode]
     * @param {String} [options.requestId]
     * @param {Number} [options.roundTripTime]
     * @note unfortunately some services return an empty string for requestId
     *
     */
    public constructor(options: {
        host: string;
        path: string;
        method: HttpCoreMethod;
        statusCode?: number;
        requestId?: string;
        roundTripTime?: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    host: Types.nonEmptyString,
                    path: Types.nonEmptyString,
                    method: Types.nonEmptyString,
                    statusCode: Types.number.optional,
                    requestId: Types.nonEmptyString.optional,
                    roundTripTime: Types.number.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { host, path, method, statusCode, requestId, roundTripTime } =
            options;

        this.host = host;
        this.path = path;
        this.method = method;
        this.statusCode = statusCode;
        this.requestId = requestId;
        this.roundTripTime = roundTripTime;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustServerPayload';
    }
}
