/**
 *
 * @module sdkPlugins
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/SdkPluginModel.md
 *
 */

import { IPlugin, IPluginTypes } from './IPlugin';

import type SDKCore from './sdkCore';
import type SdkSession from './sdkSession';

const plugins: Array<IPluginTypes> = [];

type GetPluginName<T extends IPluginTypes> = T['pluginName'];

type GetPluginEntry<T> = T extends IPlugin<string, unknown>
    ? T['entry']
    : never;

type ServicePlugin =
    | IPlugin<string, unknown, unknown>
    | IPlugin<string, void, unknown>;

type GetServicePluginName<T> = T extends ServicePlugin
    ? T['pluginName']
    : never;

type GetServicePlugin<T> = T extends ServicePlugin ? T['service'] : never;

type extendTypesWithPlugins<TAry extends Array<IPluginTypes>> = {
    [K in TAry[number] as GetPluginName<K>]: GetPluginEntry<K>;
};

type extendTypesWithService<TAry extends Array<IPluginTypes>> = {
    [K in TAry[number] as GetServicePluginName<K>]: GetServicePlugin<K>;
};

export type ISDK<TPlugins extends Array<IPluginTypes>> = typeof SDKCore &
    extendTypesWithPlugins<TPlugins> & {
        Services: extendTypesWithService<TPlugins>;
    };

/**
 *
 * @access public
 * @since 23.0.0
 * @param {SDK} SDK
 * @param {SdkSession} SdkSession - The class that is used to attach plugins to.
 * @param {Array<IPluginTypes>} pluginList - The list of plugins to attach.
 * @desc Attaches a plugin namespaces to the SDK.
 * @returns {SDK}
 *
 */
export function extendSDKWithPlugins<
    T extends typeof SDKCore,
    TPlugins extends Array<IPluginTypes>
>(
    SDK: T,
    SdkSession: { attachPlugin: (plugin: IPluginTypes) => void },
    pluginList: TPlugins
) {
    const extendedSdk = SDK; // as T & ISDK<TPlugins>;

    pluginList.forEach((plugin) => {
        SdkSession.attachPlugin(plugin);

        const { pluginName, entry } = plugin as IPlugin<string, unknown>;

        // @ts-ignore TODO figure out type error
        extendedSdk[pluginName] = entry;

        const service = (plugin as IPlugin<string, unknown, unknown>).service;

        if (service) {
            // @ts-ignore TODO figure out type error
            extendedSdk.Services[pluginName] = service;
        }
    });

    return extendedSdk as T & ISDK<TPlugins>;
}

/**
 *
 * @access public
 * @since 23.0.0
 * @returns {Array<IPluginTypes>}
 *
 */
export function getPlugins() {
    return plugins;
}

/**
 *
 * @access public
 * @since 23.0.0
 * @param {Object} plugin
 * @desc Stores the referenced `plugin` in an internal collection of plugins.
 * @returns {Void}
 *
 */
export function addPlugin(plugin: IPluginTypes) {
    if (plugins.some((p) => p === plugin)) {
        // TODO console.warn('Plugin already added'); (but we don't have a logger here)
        return;
    }
    plugins.push(plugin);
}

/**
 *
 * @access public
 * @since 23.0.0
 * @param {SDKCore} SDK
 * @param {SdkSession} sdkSessionInstance
 * @param {Array<IPluginTypes>} pluginList
 * @desc Attaches a plugin to the actively running SDK session.
 * @returns {Void}
 *
 */
export function attachLateBoundPlugin<
    T extends typeof SDKCore,
    TPlugins extends Array<IPluginTypes>
>(SDK: T, sdkSessionInstance: SdkSession, pluginList: TPlugins) {
    const SdkSession = sdkSessionInstance.constructor as TodoAny;

    const extendedSdk = extendSDKWithPlugins(SDK, SdkSession, pluginList);

    const { logger, config } = sdkSessionInstance;

    pluginList.forEach((plugin) => {
        // import any known plugin dependencies
        plugin.dependencies?.forEach((dependency: IPluginTypes) => {
            attachLateBoundPlugin(SDK, sdkSessionInstance, [dependency]);
        });

        config.services.applyConfigToPlugin(plugin);

        plugin.createManager?.(SdkSession.createManagerOptions);

        plugin.createApi?.({
            sdkSession: sdkSessionInstance,
            logger
        });

        plugin.onSdkSessionCreated?.({
            SdkSession,
            sdkSession: sdkSessionInstance,
            logger
        });
    });

    return extendedSdk as T & ISDK<TPlugins>;
}
