/**
 *
 * @module subscriptionOffer
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 4.2.4
 *
 */
export default class SubscriptionOffer {
    /**
     *
     * @access public
     * @since 4.2.4
     * @type {String|undefined}
     * @desc The campaign code this offer corresponds to.
     *
     */
    public campaignCode?: string;

    /**
     *
     * @access public
     * @since 4.2.4
     * @type {String|undefined}
     * @desc The voucher code this offer corresponds to.
     *
     */
    public voucherCode?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.campaignCode]
     * @param {String} [options.voucherCode]
     *
     */
    public constructor(options: {
        campaignCode?: string;
        voucherCode?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    campaignCode: Types.nonEmptyString.optional,
                    voucherCode: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { campaignCode, voucherCode } = options;

        this.campaignCode = campaignCode;
        this.voucherCode = voucherCode;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Subscription.SubscriptionOffer';
    }
}
