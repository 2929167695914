/**
 *
 * @module ping
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import MessageEnvelope from '../messageEnvelope';
import SocketSchemaUrls from '../socketSchemaUrls';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.18.0
 * @desc Handles sending the ping, retrying the ping and handles the timeout
 *
 */
export default class Ping {
    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     *
     */
    private pongTimeout: number;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     *
     */
    private pingMaxAttempts: number;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     *
     */
    private pingAttempts: number;

    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Function}
     *
     */
    private pongResolve: () => void;
    /**
     *
     * @access private
     * @since 4.18.0
     * @type {Number}
     *
     */
    private timeoutId?: ReturnType<typeof setTimeout>;

    /**
     *
     * @param {Object} options
     * @param {SDK.Socket.SocketManager} options.socketManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {Number} options.pongTimeout
     * @param {Number} options.pingMaxAttempts
     *
     */
    public constructor(options: {
        socketManager: SocketManager;
        logger: Logger;
        pongTimeout: number;
        pingMaxAttempts: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    socketManager: Types.object(), // not importing SocketManager due to circular import reference
                    logger: Types.instanceStrict(Logger),
                    pongTimeout: Types.number,
                    pingMaxAttempts: Types.number
                })
            };

            typecheck(this, params, arguments);
        }

        const { socketManager, logger, pongTimeout, pingMaxAttempts } = options;

        this.socketManager = socketManager;
        this.logger = logger;
        this.pongTimeout = pongTimeout;
        this.pingMaxAttempts = pingMaxAttempts;
        this.pingAttempts = 0;
        this.pongResolve = () => {
            // no-op
        };
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc sends a ping message
     * @returns {Promise<Void>}
     *
     */
    public async send() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const socketEvent = new MessageEnvelope({
                eventType: SocketUrns.socketManager.ping,
                schemaUrl: SocketSchemaUrls.ping
            });

            this.pingAttempts++;

            this.pongResolve = () => {
                this.pingAttempts = 0;
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
                resolve(undefined);
            };

            await this.socketManager.sendMessage(socketEvent);

            this.timeoutId = setTimeout(() => {
                if (this.pingAttempts >= this.pingMaxAttempts) {
                    this.logger.error(
                        'Ping',
                        `Server has been pinged ${this.pingAttempts} times with no pong received`
                    );

                    reject();
                } else {
                    this.logger.error(
                        'Ping',
                        `Ping request timed out before pong received - trying attempt: ${this.pingAttempts}`
                    );

                    this.send().then(resolve, reject);
                }
            }, this.pongTimeout * 1000);
        });
    }

    /**
     *
     * @access protected
     * @since 4.18.0
     * @desc the pong message has been received
     * @returns {Void}
     *
     */
    public pong() {
        this.pongResolve();
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public toString() {
        return 'SDK.Socket.Messages.Ping';
    }
}
