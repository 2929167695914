/**
 *
 * @module toUpperFirst
 *
 */

import { Check } from '@dss/type-checking';

/**
 *
 * @since 4.18.0
 * @param {String} str
 * @desc Takes in a String and capitalizes the first character.
 * @returns {String|undefined}
 *
 * @example <caption>An uppercase String.</caption>
 * const value = toUpperFirst('LOGIN'); // Login
 *
 * @example <caption>A lowercase String.</caption>
 * const value = toUpperFirst('login'); // Login
 *
 */
export default function toUpperFirst(str: string) {
    if (!Check.nonEmptyString(str)) {
        return undefined;
    }

    const value = str.toLowerCase();

    return value.substr(0, 1).toUpperCase() + value.substr(1);
}
