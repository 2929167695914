/**
 *
 * @module orchestrationClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/orchestration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { AccessTokenType } from 'sdk-types/types/orchestration-enums';

import Logger from '../../logging/logger';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import TokenUpdater from '../tokenUpdater';
import GraphQlClient from '../graphQl/graphQlClient';

import OrchestrationClientEndpoint from './orchestrationClientEndpoint';
import OrchestrationClientConfiguration from './orchestrationClientConfiguration';
import SessionInfoStorage from '../../session/sessionInfoStorage';
import FeatureFlagsStorage from '../../session/featureFlagsStorage';
import DeviceGrant from '../token/deviceGrant';

import handleServiceResponse from '../util/handleServiceResponse';

import AccessToken from '../token/accessToken';
import Grant from '../token/grant';
import DeviceGrantStorage from '../../token/deviceGrantStorage';
import AccessStorage from '../../token/accessStorage';
import AccountDelegationRefreshTokenStorage from '../../token/accountDelegationRefreshTokenStorage';

import ExchangeDeviceGrantForAccessTokenRequest from './internal/exchangeDeviceGrantForAccessTokenRequest';
import OrchestrationRequest from './internal/orchestrationRequest';
import RefreshTokenRequest from './internal/refreshTokenRequest';
import RegisterDeviceRequest from './internal/registerDeviceRequest';

import LogTransaction from '../../logging/logTransaction';
import SessionInfo from '../session/sessionInfo';

import HttpMethod from '../configuration/httpMethod';
import { NodeEnv } from '../../typedefs';
import {
    IMonotonicTimestampProvider,
    MonotonicTimestampProviderTypedef
} from '../../providers/typedefs';

import CoreStorageProvider from '../providers/shared/coreStorageProvider';
import PlatformProviders from '../providers/platformProviders';
import ClientBase from '../clientBase';
import { IEndpoint, ServerResponse } from '../providers/typedefs';
import { SDK_REGION } from '../providers/shared/httpHeaderConstants';
import handleServerTime from './handleServerTime';

const OrchestrationClientDustUrnReference =
    DustUrnReference.services.orchestration.orchestrationClient;

type OrchestrationExtensions = {
    operation: Record<string, unknown>;
    sdk?: {
        token: {
            accessToken: string;
            accessTokenType: typeof AccessTokenType;
            expiresIn: number;
            refreshToken: string;
            tokenType: string;
        };
        session: SessionInfo;
        grant?: Grant & { device: { assertion: string; grantType: string } };
        accountDelegationRefreshToken: { token: string } | null;
        featureFlags: Record<string, unknown>;
    };
};

/**
 *
 * @access protected
 * @since 4.17.0
 *
 */
export default class OrchestrationClient extends ClientBase<OrchestrationClientConfiguration> {
    /**
     *
     * @access private
     * @since 7.0.0
     * @type {TokenUpdater}
     * @desc The object responsible for allowing updates to tokens.
     *
     */
    private tokenUpdater: TokenUpdater;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Token.DeviceGrantStorage}
     *
     */
    private deviceGrantStorage: DeviceGrantStorage;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Session.SessionInfoStorage}
     *
     */
    private sessionInfoStorage: SessionInfoStorage;

    /**
     *
     * @access private
     * @since 16.0.0
     * @type {SDK.Token.AccountDelegationRefreshTokenStorage}
     *
     */
    private accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Session.FeatureFlagsStorage}
     *
     */
    private featureFlagsStorage: FeatureFlagsStorage;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Token.AccessStorage}
     *
     */
    private accessStorage: AccessStorage;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {String}
     *
     */
    private apiKey: string;

    /**
     *
     * @access private
     * @since 24.0.0
     * @type {IMonotonicTimestampProvider}
     * @desc The monotonic timestamp provider used to generate timestamps.
     *
     */
    private monotonicTimestampProvider: IMonotonicTimestampProvider;

    /**
     *
     * @access private
     * @since 24.0.0
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @param options
     * @param {SDK.Services.Orchestration.OrchestrationClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.TokenUpdater} options.TokenUpdater
     * @param {CoreHttpClientProvider} options.httpClient
     * @param {SDK.Token.DeviceGrantStorage} options.deviceGrantStorage
     * @param {SDK.Session.SessionInfoStorage} options.sessionInfoStorage
     * @param {SDK.Token.AccountDelegationRefreshTokenStorage} options.accountDelegationRefreshTokenStorage
     * @param {SDK.Session.FeatureFlagsStorage} options.featureFlagsStorage
     * @param {SDK.Token.AccessStorage} options.accessStorage
     * @param {String} options.apiKey
     * @param {IMonotonicTimestampProvider} options.monotonicTimestampProvider
     * @param {CoreStorageProvider} options.storage
     *
     */
    public constructor(options: {
        config: OrchestrationClientConfiguration;
        logger: Logger;
        tokenUpdater: TokenUpdater;
        httpClient: CoreHttpClientProvider;
        deviceGrantStorage: DeviceGrantStorage;
        sessionInfoStorage: SessionInfoStorage;
        accountDelegationRefreshTokenStorage: AccountDelegationRefreshTokenStorage;
        featureFlagsStorage: FeatureFlagsStorage;
        accessStorage: AccessStorage;
        apiKey: string;
        monotonicTimestampProvider: IMonotonicTimestampProvider;
        storage: CoreStorageProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(
                        OrchestrationClientConfiguration
                    ),
                    logger: Types.instanceStrict(Logger),
                    tokenUpdater: Types.instanceStrict(TokenUpdater),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    deviceGrantStorage:
                        Types.instanceStrict(DeviceGrantStorage),
                    sessionInfoStorage:
                        Types.instanceStrict(SessionInfoStorage),
                    accountDelegationRefreshTokenStorage: Types.instanceStrict(
                        AccountDelegationRefreshTokenStorage
                    ),
                    featureFlagsStorage:
                        Types.instanceStrict(FeatureFlagsStorage),
                    accessStorage: Types.instanceStrict(AccessStorage),
                    apiKey: Types.nonEmptyString,
                    monotonicTimestampProvider: Types.object(
                        MonotonicTimestampProviderTypedef
                    ),
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            tokenUpdater,
            deviceGrantStorage,
            sessionInfoStorage,
            accountDelegationRefreshTokenStorage,
            featureFlagsStorage,
            accessStorage,
            apiKey,
            monotonicTimestampProvider,
            storage
        } = options;

        this.tokenUpdater = tokenUpdater;
        this.deviceGrantStorage = deviceGrantStorage;
        this.sessionInfoStorage = sessionInfoStorage;
        this.accountDelegationRefreshTokenStorage =
            accountDelegationRefreshTokenStorage;
        this.featureFlagsStorage = featureFlagsStorage;
        this.accessStorage = accessStorage;
        this.apiKey = apiKey;
        this.monotonicTimestampProvider = monotonicTimestampProvider;
        this.storage = storage;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 4.17.0
     * @param options
     * @param {SDK.Services.Orchestration.Internal.OrchestrationRequest} options.request
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note client uses static GraphQlClient to perform query
     * @returns {Promise<Object>}
     *
     */
    public query<T>(options: {
        request: OrchestrationRequest;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.instanceStrict(OrchestrationRequest),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'query', params, arguments);
        }

        const { logger, httpClient, config } = this;
        const { endpoints } = config;

        const { request, accessToken, logTransaction } = options;

        // If operationName matches an entry in OrchestrationClientEndpoint, that config entry is to be used.
        // Otherwise the generic config endpoint query is to be used.
        const { operationName } = request;

        const endpointKey = operationName || 'query';

        // if a config endpoint exists, that endpoint is going to be used, otherwise, we use "query"
        const endpointLookupKey =
            OrchestrationClientEndpoint[
                operationName as OrchestrationClientEndpoint
            ] || OrchestrationClientEndpoint.query;
        const endpoint = endpoints[endpointLookupKey] as IEndpoint;
        const urn =
            OrchestrationClientDustUrnReference.getOperationUrn(endpointKey);

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn,
            payload: {
                url: endpoint.href,
                method: HttpMethod.POST
            },
            endpointKey,
            logTransaction
        });

        const opts = {
            endpoint,
            request,
            accessToken,
            logger,
            httpClient
        };

        logger.info(this.toString(), 'Send custom query');

        return GraphQlClient.query(opts)
            .then(
                handleServerTime({
                    getTimestamp: this.monotonicTimestampProvider.getTimestamp,
                    storage: this.storage
                })
            )
            .then(async (response) => {
                return handleServiceResponse({
                    response,
                    dustLogUtility
                });
            })
            .then(async (response) => {
                await this.processExtensions(response, logTransaction);

                const { data } = response.data;

                return data as T;
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {SDK.Services.Orchestration.Internal.RegisterDeviceRequest} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note client uses static GraphQlClient to perform query
     * @note apiKey is not being passed here because it's getting pulled by the constructor
     * @returns {Promise<Object>}
     *
     */
    public registerDevice(
        request: RegisterDeviceRequest,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.instanceStrict(RegisterDeviceRequest),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'registerDevice', params, arguments);
        }

        const { logger, httpClient, config, apiKey } = this;
        const { endpoints } = config;

        const endpointKey = OrchestrationClientEndpoint.registerDevice;

        const endpoint = endpoints[endpointKey] as IEndpoint;
        const urn = OrchestrationClientDustUrnReference.registerDevice;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn,
            payload: {
                url: endpoint.href,
                method: HttpMethod.POST
            },
            endpointKey,
            logTransaction
        });

        const opts = {
            endpoint,
            request,
            apiKey,
            logger,
            httpClient
        };

        logger.info(this.toString(), 'Send custom query to register a device');

        return GraphQlClient.registerDevice(opts)
            .then(
                handleServerTime({
                    getTimestamp: this.monotonicTimestampProvider.getTimestamp,
                    storage: this.storage
                })
            )
            .then(async (response) => {
                return handleServiceResponse({
                    response,
                    dustLogUtility
                });
            })
            .then(async (response) => {
                const extensions = await this.processExtensions(
                    response,
                    logTransaction
                );

                return {
                    ...response,
                    extensions
                };
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {SDK.Services.Orchestration.Internal.ExchangeDeviceGrantForAccessTokenRequest} request
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @note client uses static GraphQlClient to perform query
     * @note apiKey is not being passed here because it's getting pulled by the constructor
     * @returns {Promise<Object>}
     *
     */
    public exchangeDeviceGrantForAccessToken(
        request: ExchangeDeviceGrantForAccessTokenRequest,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                request: Types.instanceStrict(
                    ExchangeDeviceGrantForAccessTokenRequest
                ),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(
                this,
                'exchangeDeviceGrantForAccessToken',
                params,
                arguments
            );
        }

        const { logger, httpClient, config, apiKey } = this;
        const { endpoints } = config;

        const endpointKey =
            OrchestrationClientEndpoint.exchangeDeviceGrantForAccessToken;
        const endpoint = endpoints[endpointKey] as IEndpoint;
        const urn =
            OrchestrationClientDustUrnReference.exchangeDeviceGrantForAccessToken;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn,
            payload: {
                url: endpoint.href,
                method: HttpMethod.POST
            },
            endpointKey,
            logTransaction
        });

        const opts = {
            endpoint,
            request,
            apiKey,
            logger,
            httpClient
        };

        logger.info(
            this.toString(),
            'Send custom query to exchange device grant for access token'
        );

        return GraphQlClient.registerDevice(opts)
            .then(
                handleServerTime({
                    getTimestamp: this.monotonicTimestampProvider.getTimestamp,
                    storage: this.storage
                })
            )
            .then(async (response) => {
                return handleServiceResponse({
                    response,
                    dustLogUtility
                });
            })
            .then(async (response) => {
                const extensions = await this.processExtensions(
                    response,
                    logTransaction
                );

                return {
                    ...response,
                    extensions
                };
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access public
     * @since 10.0.0
     * @param {Object} options
     * @param {SDK.Services.Orchestration.Internal.RefreshTokenRequest} options.request
     * @param {String} [options.reason]
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @note client uses static GraphQlClient to perform query
     * @note apiKey is not being passed here because it's getting pulled by the constructor
     * @returns {Promise<Object>}
     *
     */
    public refreshToken(options: {
        request: RefreshTokenRequest;
        reason?: string;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    request: Types.instanceStrict(RefreshTokenRequest),
                    reason: Types.nonEmptyString.optional,
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'refreshToken', params, arguments);
        }

        const { logger, httpClient, config, apiKey } = this;
        const { request, reason, logTransaction } = options;
        const { endpoints } = config;

        const data = reason ? { reason } : undefined;

        const endpointKey = OrchestrationClientEndpoint.refreshToken;
        const endpoint = endpoints[endpointKey] as IEndpoint;

        const urn = OrchestrationClientDustUrnReference.refreshToken;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn,
            payload: {
                url: endpoint.href,
                method: HttpMethod.POST
            },
            data,
            endpointKey,
            logTransaction
        });

        // services cannot generate an ADRT for a Device token, so we only enable it if token is anything but Device
        const access = this.accessStorage.getAccess();
        const enableADRT =
            access?.context.accessTokenType !== AccessTokenType.Device;

        const opts = {
            endpoint,
            request,
            apiKey,
            logger,
            httpClient,
            enableADRT
        };

        logger.info(this.toString(), 'Send custom query to refresh a token');

        return GraphQlClient.refreshToken(opts)
            .then(
                handleServerTime({
                    getTimestamp: this.monotonicTimestampProvider.getTimestamp,
                    storage: this.storage
                })
            )
            .then(async (response) => {
                return handleServiceResponse({
                    response,
                    dustLogUtility
                });
            })
            .then(async (response) => {
                const extensions = await this.processExtensions(
                    response,
                    logTransaction
                );

                return {
                    ...response,
                    extensions
                };
            })
            .finally(() => {
                dustLogUtility.log();
            });
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Function} action
     * @desc Helper to execute a `Function` safely in PROD but throw errors in tests to help us potentially catch bugs in DEV but not stop PROD code from executing.
     * @returns {Promise<T>}
     *
     */
    private async wrapTry(action: () => Promise<void>) {
        try {
            await action();
        } catch (error) {
            /* istanbul ignore next */
            this.logger.error(this.toString(), error);

            // throw errors in tests to catch errors sooner
            // avoiding errors in prod.
            if (process.env.NODE_ENV === NodeEnv.Test) {
                throw error;
            }
        }
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} response
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object>} the sdk extensions processed
     *
     */
    private async processExtensions(
        response: ServerResponse,
        logTransaction: LogTransaction
    ) {
        const resultExtensions: {
            deviceGrant?: DeviceGrant;
            sessionInfo?: SessionInfo;
            accountDelegationRefreshToken?: string;
            featureFlags?: Record<string, unknown>;
        } = {};

        const { headers, data: { extensions = {} } = {} } = response;

        await this.wrapTry(async () => {
            resultExtensions.deviceGrant = await this.processDeviceGrant(
                extensions
            );
        });

        await this.wrapTry(async () => {
            await this.processToken(extensions, headers, logTransaction);
        });

        await this.wrapTry(async () => {
            resultExtensions.sessionInfo = await this.processSessionInfo(
                extensions
            );
        });

        await this.wrapTry(async () => {
            resultExtensions.accountDelegationRefreshToken =
                await this.processAccountDelegationRefreshToken(extensions);
        });

        await this.wrapTry(async () => {
            resultExtensions.featureFlags = await this.processFeatureFlags(
                extensions
            );
        });

        return resultExtensions;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} extensions
     * @desc Determines if there is a new DeviceGrant that should be updated.
     * @returns {Promise<SDK.Services.Token.DeviceGrant|undefined>}
     *
     */
    private async processDeviceGrant(extensions: OrchestrationExtensions) {
        const newDeviceGrant = extensions.sdk?.grant?.device;

        let deviceGrant: DeviceGrant | undefined;

        if (newDeviceGrant) {
            const { grantType, assertion } = newDeviceGrant;

            deviceGrant = new DeviceGrant(grantType, assertion);

            await this.wrapTry(async () => {
                await this.deviceGrantStorage.saveDeviceGrant(
                    deviceGrant as DeviceGrant
                );
            });
        }

        return deviceGrant;
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} extensions
     * @param {Headers} headers
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @desc Grabs the token value from extensions and determines if the session info should be refreshed.
     * @returns {Promise<Void>}
     *
     */
    private async processToken(
        extensions: OrchestrationExtensions,
        headers: Headers,
        logTransaction: LogTransaction
    ) {
        const token = extensions.sdk?.token;

        if (token) {
            const region = headers.get(SDK_REGION);

            const newAccessToken = { ...token, region };

            const shouldRefreshSessionInfo = false;

            await this.tokenUpdater.updateAccessToken({
                newAccessToken,
                shouldRefreshSessionInfo,
                logTransaction
            });
        }
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} extensions
     * @desc Determines if the session info should be updated.
     * @returns {Promise<SessionInfo|undefined>}
     *
     */
    private async processSessionInfo(extensions: OrchestrationExtensions) {
        const newSession = extensions.sdk?.session;

        let sessionInfo: SessionInfo | undefined;

        if (newSession) {
            sessionInfo =
                SessionInfoStorage.normalizeSessionInfoFromCache(newSession);

            await this.wrapTry(async () => {
                await this.sessionInfoStorage.saveSessionInfo(
                    sessionInfo as SessionInfo
                );
            });
        }

        return sessionInfo;
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @param {Object} extensions
     * @desc Determines if the account delegation refresh token should be updated.
     * @returns {Promise<String|undefined>}
     *
     */
    private async processAccountDelegationRefreshToken(
        extensions: OrchestrationExtensions
    ) {
        const newToken = extensions.sdk?.accountDelegationRefreshToken?.token;

        let token = '';

        if (newToken) {
            token = newToken;

            await this.wrapTry(async () => {
                await this.accountDelegationRefreshTokenStorage.saveAccountDelegationRefreshToken(
                    token
                );
            });
        }

        return token;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} extensions
     * @desc Determines if featureFlags should be updated.
     * @note In the case we receive null we should not update it with the null response.
     * @returns {Promise<Object|undefined>}
     *
     */
    private async processFeatureFlags(extensions: OrchestrationExtensions) {
        const newFeatureFlags = extensions.sdk?.featureFlags;

        let featureFlags: Record<string, unknown> = {};

        if (newFeatureFlags) {
            featureFlags = newFeatureFlags;

            await this.wrapTry(async () => {
                await this.featureFlagsStorage.saveFeatureFlags(featureFlags);
            });
        }

        return featureFlags;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.OrchestrationClient';
    }
}
