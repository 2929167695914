/**
 *
 * @module externalActivationPlugin
 * @desc {SDK.ExternalActivation.ExternalActivationPlugin} entry point.
 *
 */

import ExternalActivationService from '../services/externalActivation/entry';
import ExternalActivationClientConfiguration from '../services/externalActivation/externalActivationClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    'ExternalActivation',
    void,
    typeof ExternalActivationService
> = {
    pluginName: 'ExternalActivation',
    service: ExternalActivationService,

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.externalActivation =
            new ExternalActivationClientConfiguration({
                baseUrl: services?.externalActivation?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.externalActivation?.client?.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
