/**
 *
 * @module entry
 * @desc {SDK.Explore} entry point.
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/managers/class.sdk.explore.plantuml
 * @see https://devflow.jenkins.us-east-1.bamgrid.net/job/SDK-Doc/job/spec-sdk/job/master/lastStableBuild/artifact/dist/uml/architecture/managers/Explore.svg
 *
 */

import ExploreApi from './exploreApi';

/**
 *
 * @typedef {Object} SDK.Explore
 *
 * @property {ExploreApi} ExploreApi
 *
 */
export default {
    ExploreApi
};
