/**
 *
 * @module contentClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/content.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import ContentClientConfiguration from './contentClientConfiguration';
import ContentClientEndpoint from './contentClientEndpoint';
import HttpMethod from '../configuration/httpMethod';
import QueryBuilder from './queryBuilder';

import AccessToken from '../token/accessToken';
import SearchOverrides from '../../content/searchOverrides';
import LogTransaction from '../../logging/logTransaction';
import { HttpCoreMethod, IEndpoint } from '../providers/typedefs';
import HttpHeaders from '../providers/shared/httpHeaders';
import replaceHeaders from '../util/replaceHeaders';
import ClientBase from '../clientBase';
import {
    BYPASS_CDN_CACHE,
    CONTENT_TRANSACTION_ID,
    CONTENT_TYPE,
    DELOREAN,
    GEO_OVERRIDE
} from '../providers/shared/httpHeaderConstants';

const ContentClientDustUrnReference =
    DustUrnReference.services.content.contentClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access content services.
 *
 */
export default class ContentClient extends ClientBase<ContentClientConfiguration> {
    /**
     *
     * @param {SDK.Services.Content.ContentClientConfiguration} contentClientConfiguration
     * @param {SDK.Logging.Logger} logger
     * @param {CoreHttpClientProvider} httpClient
     *
     */
    public constructor(options: {
        config: ContentClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(ContentClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.context - The GraphQL endpoint context.
     * @param {SDK.Services.Content.QueryBuilder} options.queryBuilder - A QueryBuilder object that creates
     * a valid GraphQL query to be used to get the content.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The current access token.
     * @param {SDK.Content.SearchOverrides} [options.overrides] - Values that can be used to override location and time
     * information that is otherwise automatically derived for search requests.
     * @param {String} [options.contentTransactionId] - An application developer provided value to be included with the HTTP
     * request as the value of the `X-Content-Transaction-ID` header.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Returns the results for the query.
     * @returns {Promise<Object>} The results for the query.
     *
     */
    public async query(options: {
        context: string;
        queryBuilder: QueryBuilder;
        accessToken: AccessToken;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
        logTransaction: LogTransaction;
    }) {
        const {
            context,
            queryBuilder,
            accessToken,
            overrides,
            contentTransactionId,
            logTransaction
        } = options;

        const { logger } = this;

        const endpointKey = context as ContentClientEndpoint;

        if (Check.not.assigned(ContentClientEndpoint[endpointKey])) {
            logger.warn(
                this.toString(),
                `ContentClientEndpoint[${context}] is not defined.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            queryBuilder,
            overrides,
            contentTransactionId
        });

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ContentClientDustUrnReference.query,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            handleServiceResponseMethodName: 'query',
            resultMapper: (response) => {
                const { data } = response;

                return data;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Content.ContentClientEndpoint} options.endpointKey
     * @param {SDK.Services.Content.QueryBuilder} options.queryBuilder
     * @param {SDK.Content.SearchOverrides} [options.overrides]
     * @param {String} [options.contentTransactionId]
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: ContentClientEndpoint;
        queryBuilder: QueryBuilder;
        overrides?: SearchOverrides;
        contentTransactionId?: string;
    }) {
        const {
            accessToken,
            endpointKey,
            queryBuilder,
            overrides,
            contentTransactionId
        } = options;

        const { endpoints } = this.config;
        const { urlSizeLimit } = this.config.extras;
        const endpoint = endpoints[endpointKey];
        const { href, method, headers } = endpoint as IEndpoint;
        const overrideHeaders: Record<string, string | boolean> = {};

        if (Check.assigned(overrides)) {
            const { activeDate, countryCode, bypassCDNCache } =
                overrides as SearchOverrides;

            if (activeDate) {
                overrideHeaders[DELOREAN] = activeDate;
            }

            if (countryCode) {
                overrideHeaders[GEO_OVERRIDE] = countryCode;
            }

            if (bypassCDNCache) {
                overrideHeaders[BYPASS_CDN_CACHE] = bypassCDNCache;
            }
        }

        if (Check.assigned(contentTransactionId)) {
            overrideHeaders[CONTENT_TRANSACTION_ID] =
                contentTransactionId as string;
        }

        let requestMethod = method
            ? HttpMethod[method.toUpperCase() as HttpCoreMethod]
            : HttpMethod.POST;
        let url = queryBuilder.createQueryUrl(href, requestMethod);
        let requestBody = '';

        if (requestMethod === HttpMethod.GET && url.length > urlSizeLimit) {
            requestMethod = HttpMethod.POST;
            url = queryBuilder.createQueryUrl(href, requestMethod);
        }

        const combinedHeaders = { ...headers, ...overrideHeaders };

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            combinedHeaders
        );

        const httpHeaders = new HttpHeaders(requestHeaders);

        if (requestMethod === HttpMethod.GET) {
            httpHeaders.delete(CONTENT_TYPE);
        } else if (requestMethod === HttpMethod.POST) {
            requestBody = queryBuilder.createPostContent();
        }

        return {
            url,
            body: requestBody,
            method: requestMethod,
            headers: httpHeaders
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Content.ContentClient';
    }
}
