/**
 *
 * @module checkAcceptedCase
 *
 */

import ErrorCode from '../../exception/errorCode';
import ErrorReason from '../../exception/errorReason';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Checks for error cases thrown on success (status code `200` & `202`)
 * @returns {Boolean}
 *
 */
export default function checkAcceptedCase(errors: Array<ErrorReason>) {
    let acceptedCase = false;

    if (Array.isArray(errors) && errors.length) {
        const { code } = errors[0];

        Object.keys(ErrorCode).every((key) => {
            if (ErrorCode[key].code === code) {
                acceptedCase = true;

                return false;
            }

            return true;
        });
    }

    return acceptedCase;
}
