/**
 *
 * @module accessContext
 * @desc The AccessContext contains tokens which prove the client has
 * been granted authorization to use BAM services. Additionally, content feature
 * responses have the ability to be user and platform specific based on a given AccessContext.
 *
 */

import { AccessTokenType } from 'sdk-types/types/orchestration-enums';

import RefreshGrant from './refreshGrant';

import type { TokenData } from './typedefs';

/**
 *
 * @access public
 * @desc Represents the response from exchanging a token and provides
 * access context for subsequent service requests.
 * @note Also acts as a TokenRequestBuilder (via RefreshGrant) to refresh itself.
 *
 */
export default class AccessContext extends RefreshGrant {
    /**
     *
     * @access public
     * @type {String}
     * @desc The access token issued by the authorization server.
     *
     */
    public token: string;

    /**
     *
     * @access private
     * @type {String}
     * @desc Gets or sets the token type.
     *
     */
    public tokenType: string;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The lifetime in seconds of the access token from the time that it was issued.
     *
     */
    public expiresIn: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc The timestamp of when the access token was issued (set by the SDK as now).
     * @note Services do not provide us with this data.
     * @note Returns the number of milliseconds elapsed since 1 January 1970 00:00:00 UTC.
     *
     */
    public issuedAt: number;

    /**
     *
     * @access public
     * @since 4.6.0
     * @type {String|undefined|null}
     * @desc The edge region that was used to process the token exchange.
     * @note This value comes from the x-bamtech-region response header, not the json.
     * @note it can be null from tokenClient.exchange()
     *
     */
    public region?: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {SDK.Services.Token.AccessTokenType|undefined}
     * @desc The type of the accessToken issued.
     *
     */
    public accessTokenType?: AccessTokenType;

    /**
     *
     * @param {Object} options
     * @param {String} options.token
     * @param {String} options.tokenType
     * @param {SDK.Services.Token.AccessTokenType} [options.accessTokenType]
     * @param {Number} [options.expiresIn=600]
     * @param {Number} [options.issuedAt=Date.now()]
     * @param {String} [options.region]
     * @param {String} [options.refreshToken]
     * @param {TokenData} [options.tokenData={}]
     *
     */
    public constructor(options: {
        token: string;
        tokenType: string;
        accessTokenType?: AccessTokenType;
        expiresIn?: number;
        issuedAt?: number;
        region?: Nullable<string>;
        refreshToken?: string;
        tokenData?: TokenData;
    }) {
        const {
            token,
            tokenType,
            accessTokenType,
            expiresIn,
            issuedAt,
            region,
            refreshToken,
            tokenData
        } = options;

        super(refreshToken || tokenData?.refresh_token);

        this.token = token;
        this.tokenType = tokenType;
        this.expiresIn = expiresIn || 600;
        this.issuedAt = issuedAt || Date.now();
        this.region = region;
        this.accessTokenType = accessTokenType;
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {Number} refreshThreshold - The refresh threshold (setting in the config)
     * @desc Determines the timestamp of when the access token should be refreshed
     * @returns {Number}
     *
     */
    public getRefreshAt(refreshThreshold: number) {
        return this.issuedAt + this.expiresIn * refreshThreshold * 1000;
    }

    /**
     *
     * @access protected
     * @since 16.0.0
     * @param {Number} refreshThreshold - The refresh threshold (setting in the config)
     * @desc Determines whether the access is expired
     * @returns {Boolean}
     *
     */
    public isAccessTokenExpired(refreshThreshold: number) {
        const refreshAt = this.getRefreshAt(refreshThreshold);

        const now = Date.now();

        if (now >= refreshAt) {
            return true;
        }

        // If the current system clock time is before the token's issuedAt time then assume that the user has
        // changed their system clock to a time in the past. This will help guard against cases where we get a
        // false negative when checking if the token has expired.
        return now < this.issuedAt;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.AccessContext';
    }
}
