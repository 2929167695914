/**
 *
 * @module locationType
 *
 */

/**
 *
 * @typedef {Object} SDK.Services.Session.LocationType
 *
 * @property {String} ANONYMOUS_IP - DEPRECATED use `AnonymousIp` instead
 * @property {String} COUNTRY_CODE - DEPRECATED use `CountryCode` instead
 * @property {String} LOW_CONFIDENCE - DEPRECATED use `LowConfidence` instead
 * @property {String} PROXIED_IP - DEPRECATED use `ProxiedIp` instead
 * @property {String} UNAVAILABLE - DEPRECATED use `Unavailable` instead
 * @property {String} UNKNOWN - DEPRECATED use `Unknown` instead
 * @property {String} ZIP_CODE - DEPRECATED use `ZipCode` instead
 *
 * @property {String} AnonymousIp
 * @property {String} CountryCode
 * @property {String} LowConfidence
 * @property {String} ProxiedIp
 * @property {String} Unavailable
 * @property {String} Unknown
 * @property {String} ZipCode
 *
 */
export default {
    ANONYMOUS_IP: 'AnonymousIp',
    COUNTRY_CODE: 'CountryCode',
    LOW_CONFIDENCE: 'LowConfidence',
    PROXIED_IP: 'ProxiedIp',
    UNAVAILABLE: 'Unavailable',
    UNKNOWN: 'Unknown',
    ZIP_CODE: 'ZipCode',

    AnonymousIp: 'AnonymousIp',
    CountryCode: 'CountryCode',
    LowConfidence: 'LowConfidence',
    ProxiedIp: 'ProxiedIp',
    Unavailable: 'Unavailable',
    Unknown: 'Unknown',
    ZipCode: 'ZipCode'
};
