/**
 *
 * @module typedefs
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#types
 *
 */

import { Check, Types } from '@dss/type-checking';

import { RefIdType, ActionType } from '../services/explore/enums';

/**
 *
 * @since 23.1.0
 * @desc Typecheck custom validation to ensure that the `exploreParams` `Object`
 * does not contain keys available on the root level, i.e.; limit, offset, etc. of a given query.
 * @note For context: because PageQuery & SetQuery have the params on the root level, to avoid
 * decisioning on which would take precedence, the root level param or the one provided in exploreParams,
 * we simply fail if it's provided in exploreParams.
 *
 */
function checkQueryExploreParams(
    exploreParams: Record<string, ApprovedAny>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userArguments?: Array<unknown>,
    context?: string
) {
    if (Check.not.object(exploreParams)) {
        return false;
    }

    const stringifiedParams = JSON.stringify(exploreParams);

    if (context === 'PageQuery') {
        return (
            !stringifiedParams.includes('enhancedContainersLimit') &&
            !stringifiedParams.includes('limit')
        );
    }

    if (context === 'SetQuery' || context === 'SeasonQuery') {
        return (
            !stringifiedParams.includes('offset') &&
            !stringifiedParams.includes('limit')
        );
    }

    if (context === 'GetPartnerContinueWatchingQuery') {
        return !stringifiedParams.includes('limit');
    }

    return true;
}

/**
 *
 * @typedef {Object} SDK.Explore.ExploreParams
 * @since 23.1.0
 * @property {Number} [enhancedContainersLimit] - Since `23.1.0`
 * @property {Number} [limit] - Since `23.1.0`
 * @property {Number} [offset] - Since `23.1.0`
 * @property {String} [refId] - Since `23.1.0`
 * @property {RefIdType} [refIdType] - Since `23.1.0`
 * @property {ActionType} [action] - Since `23.1.0`
 * @desc All expected/known params for the ExploreQuery and anything going through #query.
 *
 */
export type ExploreParams = {
    enhancedContainersLimit?: number;
    limit?: number;
    offset?: number;
    refId?: string;
    refIdType?: RefIdType;
    action?: ActionType;
    [key: string]: unknown;
};

/**
 *
 * @typedef {Object} SDK.Explore.ExploreQuery
 * @since 23.1.0
 * @desc Generic query object that can be used to retrieve data from an `ExploreApi` endpoint described by a `context`.
 * @property {String} context - Since `23.1.0` - The context for the query being performed. Must be represented in the SDK config to succeed. Example: `getSet`.
 * @property {String} version - Since `29.0.0` - Version for the API.
 * @property {Object} [variables] - Since `23.1.0` - Variables to replace in the templated endpoint. Optional here to support the `getDeeplink` context, however they are technically required for `getPage` and `getSet`, and potentially other future queries.
 * @property {Object} [exploreParams] - Since `23.1.0` - Query parameters that may be undeclared in the query object, but are needed to retrieve the desired data.
 *
 */
export type ExploreQuery = {
    context: string;
    version: string;
    variables?: {
        [key: string]: unknown;
    };
    exploreParams?: ExploreParams;
};

/**
 *
 * @access private
 *
 */
export const ExploreQueryTypedef = {
    context: Types.string,
    version: Types.nonEmptyString,
    variables: Types.object().optional,
    exploreParams: Types.object().optional
};

/**
 *
 * @typedef {Object} SDK.Explore.PageQuery
 * @since 23.1.0
 * @desc An `ExploreApi` query object that describes the shape of a `PageQuery`.
 * @property {String} version - Since `29.0.0` - Version for the API.
 * @property {String} pageId - Since `23.1.0` - The id of the page you would like to request. Should have an `entity-` prefix.
 * @property {Number} [enhancedContainersLimit] - Since `23.1.0` - The maximum number of containers to initially enhance on a page.
 * @property {Number} [limit] - Since `23.1.0` - Used exclusively for pagination, to limit the number of items returned.
 * @property {Object} [exploreParams] - Since `23.1.0` - Values unknown to the SDK passed in by the app and sent as query parameters.
 *
 */
export type PageQuery = {
    version: string;
    pageId: string;
    enhancedContainersLimit?: number;
    limit?: number;
    exploreParams?: Record<string, ApprovedAny>;
};

/**
 *
 * @access private
 *
 */
export const PageQueryTypedef = {
    version: Types.nonEmptyString,
    pageId: Types.string,
    enhancedContainersLimit: Types.number.optional,
    limit: Types.number.optional,
    exploreParams: Types.custom(
        checkQueryExploreParams,
        'keys that are not available on the root level of the query.',
        'PageQuery'
    ).optional
};

/**
 *
 * @typedef {Object} SDK.Explore.DeeplinkQuery
 * @since 23.1.0
 * @desc An `ExploreApi` query object that describes the shape of a `DeeplinkQuery`.
 * @property {String} version - Since `29.0.0` - Version for the API.
 * @property {String} refId - Since `23.1.0` - Reference ID to deeplink to the appropriate user experience within an application. The format of the ID depends on the value of the `refIdType` parameter.
 * @property {SDK.Services.Explore.RefIdType} refIdType - Since `23.1.0` - The type of the refId parameter.
 * @property {SDK.Services.Explore.ActionType} [action] - Since `23.1.0` - The type of action.
 *
 */
export type DeeplinkQuery = {
    version: string;
    refId: string;
    refIdType: RefIdType;
    action?: ActionType;
};

/**
 *
 * @access private
 *
 */
export const DeeplinkQueryTypedef = {
    version: Types.nonEmptyString,
    refId: Types.string,
    refIdType: Types.in(RefIdType),
    action: Types.in(ActionType).optional
};

/**
 *
 * @typedef {Object} SDK.Explore.SetQuery
 * @since 23.1.0
 * @desc An `ExploreApi` query object that describes the shape of a `SetQuery`.
 * @property {String} version - Since `29.0.0` - Version for the API.
 * @property {String} setId - Since `23.1.0` - ID of the Set to retrieve.
 * @property {Number} [limit] - Since `23.1.0` - Used exclusively for pagination, to limit the number of items returned.
 * @property {Number} [offset] - Since `23.1.0` - The offset into the set from which results should be returned.
 * @property {Object} exploreParams - Since `23.1.0` - Values unknown to the SDK passed in by the app and sent as query parameters.
 *
 */
export type SetQuery = {
    version: string;
    setId: string;
    limit?: number;
    offset?: number;
    exploreParams?: Record<string, ApprovedAny>;
};

/**
 *
 * @access private
 *
 */
export const SetQueryTypedef = {
    version: Types.nonEmptyString,
    setId: Types.string,
    limit: Types.number.optional,
    offset: Types.number.optional,
    exploreParams: Types.custom(
        checkQueryExploreParams,
        'keys that are not available on the root level of the query.',
        'SetQuery'
    ).optional
};

/**
 *
 * @typedef {Object} SDK.Explore.UserStateInput
 * @since 24.0.0
 * @desc Expected structure of a userStateInput.
 * @property {Array<String>} pids - Since `24.0.0` - A list of opaque personalization IDs (PIDs) to query for.
 * @property {String} version - Since `29.0.0` - Version of the API.
 *
 */
export type UserStateInput = {
    pids: Array<string>;
    version: string;
};

/**
 *
 * @access private
 *
 */
export const UserStateInputTypedef = {
    pids: Types.array.of.nonEmptyString,
    version: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Explore.PlayerExperienceQuery
 * @since 24.0.0
 * @desc An ExploreApi query object that describes the shape of a PlayerExperienceQuery.
 * @property {String} availId - Since `24.0.0` - ID of presentational metadata for the player experience (overlay).
 * @property {String} version - Since `29.0.0` - Version of the API.
 *
 */
export type PlayerExperienceQuery = {
    availId: string;
    version: string;
};

/**
 *
 * @access private
 *
 */
export const PlayerExperienceQueryTypedef = {
    availId: Types.nonEmptyString,
    version: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Explore.WatchlistRequest
 * @since 24.0.0
 * @desc Expected watchlist request input to the API.
 * @property {String} actionInfoBlock - Since `24.0.0` - Sent as "action_info_block" base64 encoded infoblock.
 * @property {String} [pageInfoBlock] - Since `24.0.0` - Sent as "page_info_block" base64 encoded infoblock.
 * @property {String} [containerInfoBlock] - Since `24.0.0` - Sent as "container_info_block" base64 encoded infoblock.
 * @property {String} [itemInfoBlock] - Since `24.0.0` - Sent as "item_info_block" base64 encoded infoblock.
 *
 */
export type WatchlistRequest = {
    actionInfoBlock: string;
    pageInfoBlock?: string;
    containerInfoBlock?: string;
    itemInfoBlock?: string;
};

/**
 *
 * @access private
 *
 */
export const WatchlistRequestTypedef = {
    actionInfoBlock: Types.nonEmptyString,
    pageInfoBlock: Types.nonEmptyString.optional,
    containerInfoBlock: Types.nonEmptyString.optional,
    itemInfoBlock: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Explore.SeasonQuery
 * @since 24.0.0
 * @desc An `ExploreApi` query object that describes the shape of a `SeasonQuery`.
 * @property {String} version - Since `29.0.0` - Version of the API.
 * @property {String} seasonId - Since `24.0.0` - ID of the Season to retrieve.
 * @property {Number} [limit] - Since `24.0.0` - For a limit N, this does not guarantee that N items will be returned in the response. Actual response items size can be <= N. Sent as a query parameter.
 * @property {Number} [offset] - Since `24.0.0` - The offset into the set from which results should be returned.
 * @property {Object} [exploreParams] - Since `24.0.0` - Values unknown to the SDK passed in by the app and sent as query parameters.
 *
 */
export type SeasonQuery = {
    version: string;
    seasonId: string;
    limit?: number;
    offset?: number;
    exploreParams?: Record<string, ApprovedAny>;
};

/**
 *
 * @access private
 *
 */
export const SeasonQueryTypedef = {
    version: Types.nonEmptyString,
    seasonId: Types.string,
    limit: Types.number.optional,
    offset: Types.number.optional,
    exploreParams: Types.custom(
        checkQueryExploreParams,
        'keys that are not available on the root level of the query.',
        'SeasonQuery'
    ).optional
};

/**
 *
 * @typedef {Object} SDK.Explore.UpNextQuery
 * @since 25.0.0
 * @desc An ExploreApi query object that describes the shape of a UpNextQuery.
 * @property {String} version - Since `29.0.0` - Version of the API.
 * @property {String} contentId - Since `25.0.0` - ID of content to display upNext for.
 *
 */
export type UpNextQuery = {
    version: string;
    contentId: string;
};

/**
 *
 * @access private
 *
 */
export const UpNextQueryTypedef = {
    version: Types.nonEmptyString,
    contentId: Types.string
};

/**
 *
 * @typedef {Object} SDK.Explore.SearchQuery
 * @since 25.0.0
 * @desc An `ExploreApi` query object that describes the shape of a `SearchQuery`.
 * @property {String} version - Since `29.0.0` - Version for the API.
 * @property {String} [query] - Since `25.0.0` - User input that indicates that a search should be performed with the given value instead of returning the landing page.
 *
 */
export type SearchQuery = {
    version: string;
    query?: string;
};

/**
 *
 * @access private
 *
 */
export const SearchQueryTypedef = {
    version: Types.nonEmptyString,
    query: Types.string.optional
};

/**
 *
 * @typedef {Object} SDK.Explore.DownloadMetadataInput
 * @since 27.0.0
 * @desc Expected structure of a downloadMetadataInput.
 * @property {Array<String>} availIds - Since `27.0.0` - A list of avail IDs to query for.
 * @property {String} version - Since `29.0.0` - Version of the API.
 *
 */
export type DownloadMetadataInput = {
    availIds: Array<string>;
    version: string;
};

/**
 *
 * @access private
 *
 */
export const DownloadMetadataInputTypedef = {
    availIds: Types.array.of.nonEmptyString,
    version: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Explore.GetPartnerContinueWatchingQuery
 * @since 28.3.0
 * @desc An ExploreApi query object that describes the shape of a GetPartnerContinueWatchingQuery.
 * @property {String} version - Since `28.3.0` - Version for the API.
 * @property {Number} [limit] - Since `28.3.0` - Used exclusively for pagination, to limit the number of items returned.
 * @property {String} [exploreParams] - Since `28.3.0` - Values unknown to the SDK passed in by the app and sent as query parameters.
 *
 */
export type GetPartnerContinueWatchingQuery = {
    version: string;
    limit?: number;
    exploreParams?: Record<string, unknown>;
};

/**
 *
 * @access private
 *
 */
export const GetPartnerContinueWatchingQueryTypedef = {
    version: Types.string,
    limit: Types.number.optional,
    exploreParams: Types.custom(
        checkQueryExploreParams,
        'keys that are not available on the root level of the query.',
        'GetPartnerContinueWatchingQuery'
    ).optional
};
