/**
 *
 * @module streamSampleConfiguration
 *
 */

/**
 *
 *
 */
export default class StreamSampleConfiguration {
    /**
     *
     * @access public
     * @type {Number}
     *
     */
    public streamSampleInterval: number;

    /**
     *
     * @param {Number} [streamSampleInterval=30]
     *
     */
    public constructor(streamSampleInterval = 30) {
        this.streamSampleInterval = streamSampleInterval;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.StreamSampleConfiguration';
    }
}
