/**
 *
 * @module limitAdTrackingEnabled
 *
 */

/**
 *
 * @access public
 * @since 18.0.0
 * @typedef {Object} SDK.Advertising.LimitAdTrackingEnabled
 * @desc Indicates if limit-ad-tracking has been explicitly enabled by the user.
 *
 * @property {String} YES - Since `18.0.0` - LAT is enabled.
 * @property {String} NO - Since `18.0.0` - LAT is disabled.
 * @property {String} NOT_SUPPORTED - Since `18.0.0` - Expected if the platform does not support the user inputting an election.
 * @property {String} ERROR - Since `18.0.0` - Expected if the election is possible but the client isn't sending it, either
 * because it's not yet wired up or there is an error.
 *
 * @example
 * SDK.Advertising.LimitAdTrackingEnabled.ERROR // 'ERROR'
 *
 */
enum AdActivity {
    YES = 'YES',
    NO = 'NO',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    ERROR = 'ERROR'
}

export default AdActivity;
