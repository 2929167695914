/**
 *
 * @module messageEnvelope
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { RawSocketMessage } from './typedefs';

import uuidv4 from '../services/util/uuidv4';

/**
 *
 * @access public
 * @since 4.9.0
 * @desc wrapper class used to send/receive socket messages
 *
 */
export default class MessageEnvelope {
    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String}
     * @desc the urn/type name of the event
     * @note serializes as `type`
     *
     */
    public eventType: string;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String}
     * @note serializes as `schemaurl`
     *
     */
    public schemaUrl: string;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {*}
     * @desc the optional data to be sent through the socket message
     *
     */
    public data?: RawSocketMessage['data'];

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String}
     * @desc defaults to `application/json;charset=utf-8`
     * @note serializes as `datacontenttype`
     *
     */
    public dataContentType: string;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String|undefined}
     * @desc SDK will fill this in unless overridden
     *
     */
    public subject?: Nullable<string>;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {String}
     *
     */
    public id: string;

    /**
     *
     * @access public
     * @since 4.9.0
     * @type {Date}
     *
     */
    public time: Date;

    /**
     *
     * @access private
     * @since 4.9.0
     * @type {String|null}
     *
     */
    private sdkSource: string | null;

    /**
     *
     * @param {Object} options
     * @param {String} options.eventType
     * @param {String} options.schemaUrl
     * @param {*} [options.data]
     * @param {String|null} [options.dataContentType]
     * @param {String} [options.subject]
     *
     */
    public constructor(options: {
        eventType: string;
        schemaUrl: string;
        data?: RawSocketMessage['data'];
        dataContentType?: string | null;
        subject?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    eventType: Types.nonEmptyString,
                    schemaUrl: Types.nonEmptyString,
                    dataContentType: Types.nonEmptyString.optional,
                    subject: Types.nonEmptyString.optional
                })
            };

            typecheck(this, params, arguments);
        }

        const { eventType, schemaUrl, dataContentType, data, subject } =
            options;

        this.eventType = eventType;
        this.schemaUrl = schemaUrl;
        this.data = data;
        this.dataContentType =
            dataContentType || 'application/json;charset=utf-8';
        this.subject = subject;
        this.id = uuidv4();
        this.time = new Date();
        this.sdkSource = null;
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @returns {String|null} source URN of the SDK
     * @note this property won't be set until after the `SocketApi.sendMessage(...)` has been called with this `SocketEvent` instance.
     *
     */
    public get source() {
        return this.sdkSource;
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} options
     * @param {String} [options.subject]
     * @param {String} options.source
     * @desc Compiles the complete socket message to be sent over the socket
     * @returns {Object} the final socket message
     *
     */
    public getSocketMessage(options: {
        subject?: string | null;
        source: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    subject: Types.nonEmptyString.optional,
                    source: Types.nonEmptyString
                })
            };

            typecheck(this, 'getSocketMessage', params, arguments);
        }

        const { source, subject: subjectOverride } = options;

        this.sdkSource = source;

        const { eventType, data, dataContentType, id, subject } = this;

        const message: RawSocketMessage = {
            source,
            time: this.time.toISOString(),
            id,
            data,
            datacontenttype: dataContentType,
            type: eventType,
            schemaurl: this.schemaUrl,
            subject: subjectOverride || subject || null

            // specversion: '1' // not in use for now per https://bamtechmedia.slack.com/archives/CJ5G0GL57/p1585857492064700?thread_ts=1585775277.039300&cid=CJ5G0GL57
        };

        return message;
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public toString() {
        return 'SDK.Socket.MessageEnvelope';
    }
}
