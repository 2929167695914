/**
 *
 * @module playbackSelectionAdTracking
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import LimitAdTrackingEnabled from '../../advertising/limitAdTrackingEnabled';
import PrivacyOptOut from '../../advertising/privacyOptOut';

/**
 *
 * @since 18.0.0
 *
 */
export default class PlaybackSelectionAdTracking {
    /**
     *
     * @access public
     * @since 18.0.0
     * @type {SDK.Advertising.LimitAdTrackingEnabled}
     *
     */
    public limitAdTrackingEnabled: LimitAdTrackingEnabled;

    /**
     *
     * @access public
     * @since 18.0.0
     * @type {String|undefined}
     *
     */
    public deviceAdId?: string;

    /**
     *
     * @access public
     * @since 23.1.0
     * @type {String<SDK.Advertising.PrivacyOutOut>}
     * @desc Indicates if a user has opted their device out of sharing personal information.
     * @note Source from the `Bam.Sdk.Media.MediaDescriptor`.
     *
     */
    public privacyOptOut?: PrivacyOptOut;

    /**
     *
     * @param {SDK.Advertising.LimitAdTrackingEnabled} limitAdTrackingEnabled
     * @param {String} [deviceAdId]
     * @param {String<SDK.Advertising.PrivacyOutOut>} [privacyOptOut] - Indicates if a user has opted their device out of sharing personal information.
     *
     */
    public constructor(
        limitAdTrackingEnabled: LimitAdTrackingEnabled,
        deviceAdId?: string,
        privacyOptOut?: PrivacyOptOut
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                limitAdTrackingEnabled: Types.in(LimitAdTrackingEnabled),
                deviceAdId: Types.nonEmptyString.optional,
                privacyOptOut: Types.in(PrivacyOptOut).optional
            };

            typecheck(this, params, arguments);
        }

        this.limitAdTrackingEnabled = limitAdTrackingEnabled;
        this.deviceAdId = deviceAdId;
        this.privacyOptOut = privacyOptOut;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.PlaybackSelectionAdTracking';
    }
}
