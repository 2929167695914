/**
 *
 * @module validationResultUnknownTypeError
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ValidationResult from './validationResult';

/**
 *
 * @access protected
 * @since 4.11.0
 * @desc Applicable if `ValidationResult.validationResultType` is `com.disneystreaming.sdp.http.UnknownTypeError`
 *
 */
export default class ValidationResultUnknownTypeError extends ValidationResult {
    /**
     *
     * @access public
     * @since 4.11.0
     * @type {String|undefined}
     *
     */
    public receivedType?: string;

    /**
     *
     * @param {Object} options
     * @param {String} [options.receivedType]
     *
     */
    public constructor(options: { receivedType?: string }) {
        // @ts-expect-error TODO: wrong super call...
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    receivedType: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const { receivedType } = options;

        this.receivedType = receivedType;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Internal.Telemetry.ValidationResultUnknownTypeError';
    }
}
