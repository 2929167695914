/**
 *
 * @module qrCodeClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @since 26.0.0
 * @typedef {Object} SDK.Services.QRCode.QRCodeClientEndpoint
 *
 * @property {String} main - Since `26.0.0`
 *
 */
enum QRCodeClientEndpoint {
    main = 'main'
}

export default QRCodeClientEndpoint;
