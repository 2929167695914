/**
 *
 * @module accessChangedEvent
 *
 */

import type AccessToken from './token/accessToken';

/**
 *
 * @since 9.0.0
 * @desc Emitted by SdkSession each time an access token is updated.
 * @note Should be emitted after a successful token exchange request.
 * @note This event allows app devs to be notified when to get updated session information, keep tokens in sync,
 * and entitlements to determine if the user is in a TDA state.
 *
 */
export default class AccessChangedEvent {
    /**
     *
     * @access public
     * @since 9.0.0
     * @type {SDK.Token.AccessToken}
     *
     */
    public accessToken: AccessToken;

    /**
     *
     * @param {SDK.Token.AccessToken} accessToken
     *
     */
    public constructor(accessToken: AccessToken) {
        this.accessToken = accessToken;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.AccessChangedEvent';
    }
}
