/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import {
    BillingAddress,
    BillingAddressTypedef,
    CallbackUrl,
    CallbackUrlTypedef,
    PaymentMethod,
    PaymentMethodTypedef,
    UpdatePaymentMethodBase,
    UpdatePaymentMethodBaseTypedef
} from '../typedefs';

/**
 *
 * @typedef {Object} SDK.Commerce.Klarna.KlarnaPaymentMethod
 * @since 9.0.0
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#extend-params
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} walletId - The unique identifier for a wallet. This will be the DSS identityId when the wallet has an associated identity, and an accountId otherwise.
 * @property {Boolean} isDefault - Request that this should be made a default payment method. This may be overridden due to business logic.
 * @property {String} [country] - Payment Country. See ISO 3166-1 alpha-2
 * @property {Object} [metadata] - Object containing metadata about the paymentMethod.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 * @property {Boolean} [isShared] - Flag indicating if the user has agreed to allow payment method to be shared across partners.
 * @property {Object<SDK.Commerce.BillingAddress>} billingAddress - Object that contains the users country code.
 * @property {String} orderCode - Identifier for an order. Used for repeat billing.
 * @property {String} partner - Identify the partner that this account is associated with. Used to derive the walletId.
 * @property {String} [identityId] - The unique identifier for a wallet.
 *
 */
export interface KlarnaPaymentMethod extends PaymentMethod {
    isDefault: boolean;
    country?: string;
    usage: string;
    isShared?: boolean;
    billingAddress: BillingAddress;
    orderCode: string;
}

/**
 *
 * @access private
 *
 */
export const KlarnaPaymentMethodTypedef = {
    ...PaymentMethodTypedef,
    isDefault: Types.boolean,
    country: Types.nonEmptyString.optional,
    usage: Types.nonEmptyString,
    isShared: Types.boolean.optional,
    billingAddress: Types.object(BillingAddressTypedef),
    orderCode: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Klarna.KlarnaPaymentRequest
 * @since 8.0.0
 * @property {SDK.Commerce.CallbackUrl} callbackUrl - Set of callback urls used in the Klarna payment flow.
 * @property {String} termsUrl - Terms URL. If we can differentiate terms per language and country by locale parameter.
 * @property {String} campaignCode - Campaign Code required to calculate price of the order.
 * @property {String} voucherCode - Voucher Code required to calculate price of the order.
 * @property {String} [redemptionCode] - Redemption code in a gated purchase flow.
 * @property {Array<String>} skus - Array of sku values associated with the payment request.
 * @property {Boolean} isDefault - A flag to indicate this klarna payment method is default payment method for the user.
 * @property {String} usage - Flag indicating if this payment method can be used in future. Example values: single_use | multi_use.
 *
 */
export interface KlarnaPaymentRequest {
    callbackUrl: CallbackUrl;
    termsUrl: string;
    campaignCode: string;
    voucherCode: string;
    redemptionCode?: string;
    skus: Array<string>;
    isDefault: boolean;
    usage: string;
}

/**
 *
 * @access private
 *
 */
export const KlarnaPaymentRequestTypedef = {
    callbackUrl: Types.object(CallbackUrlTypedef),
    termsUrl: Types.nonEmptyString,
    campaignCode: Types.nonEmptyString,
    voucherCode: Types.nonEmptyString,
    redemptionCode: Types.nonEmptyString.optional,
    skus: Types.array.of.nonEmptyString,
    isDefault: Types.boolean,
    usage: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Commerce.Klarna.KlarnaUpdatePaymentMethodRequest
 * @since 10.0.0
 * @property {String} paymentMethodId - The identifier for the payment method.
 * @property {String<SDK.Services.Commerce.PaymentMethodType>} type - The type of payment method.
 * @property {String} customerToken - The user's customer token.
 * @property {String} country - The users country. See ISO 3166-1 alpha-2.
 *
 */
export interface KlarnaUpdatePaymentMethodRequest
    extends UpdatePaymentMethodBase {
    customerToken: string;
    country: string;
}

/**
 *
 * @access private
 *
 */
export const KlarnaUpdatePaymentMethodRequestTypedef = {
    ...UpdatePaymentMethodBaseTypedef,
    customerToken: Types.nonEmptyString,
    country: Types.nonEmptyString
};
