/**
 *
 * @module drmClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/services-commons/mdrm/tree/master/basic_apis
 * @see https://github.bamtech.co/services-commons/mdrm/blob/master/license_acquisition_urls.md
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import handleServiceResponse from '../util/handleServiceResponse';
import replaceHeaders from '../util/replaceHeaders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import DustCategory from '../internal/dust/dustCategory';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';

import DrmClientEndpoint from './drmClientEndpoint';
import DrmClientConfiguration from './drmClientConfiguration';
import DrmUtils from './drmUtils';
import PlayReadyMessage from './playReadyMessage';

import ErrorCode from '../exception/errorCode';
import AccessToken from '../token/accessToken';

import { MediaAnalyticsKey } from '../../media/enums';
import MediaItem from '../../media/mediaItem';

import { PlaylistType } from '../media/enums';

import {
    FetchStatus,
    HttpMethod as Method,
    NetworkError,
    StartupActivity
} from '../qualityOfService/enums';

import ServerRequest from '../qualityOfService/serverRequest';
import PlaybackStartupEventData from '../qualityOfService/playbackStartupEventData';

import LogTransaction from '../../logging/logTransaction';
import DrmClientExtrasMap from './drmClientExtrasMap';
import PlaybackContext from '../media/playbackContext';

import { getDataVersion } from '../../media/qoeEventVersionInfo';

import {
    ClientDecisions,
    QosDecisions,
    QosDecisionsResponse,
    ServerDecisions
} from '../media/typedefs';

import HttpHeaders from '../providers/shared/httpHeaders';
import { GetPayloadResult, IEndpoint } from '../providers/typedefs';
import ClientBase from '../clientBase';

import HttpStatus from '../util/errorHandling/httpStatus';

import CoreStorageProvider from '../providers/shared/coreStorageProvider';

const DrmClientDustUrnReference = DustUrnReference.services.drm.drmClient;
const QualityOfServiceDustUrnReference = DustUrnReference.qualityOfService;

const requiresArrayBufferEndpoints = [
    DrmClientEndpoint.fairPlayCertificate as DrmClientEndpoint,
    DrmClientEndpoint.widevineCertificate as DrmClientEndpoint
];

/**
 *
 * @access protected
 *
 */
export default class DrmClient extends ClientBase<DrmClientConfiguration> {
    /**
     *
     * @access public
     * @since 29.0.0
     * @type {String}
     *
     */
    public clientId: string;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {String}
     *
     */
    public environment: string;

    /**
     *
     * @access public
     * @since 29.0.0
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    public storage: CoreStorageProvider;

    /**
     *
     * @param {Object} options
     * @param {DrmClientConfiguration} options.config
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {CoreStorageProvider} options.storage
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: DrmClientConfiguration;
        clientId: string;
        environment: string;
        storage: CoreStorageProvider;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(DrmClientConfiguration),
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    storage: Types.instanceStrict(CoreStorageProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientId, environment, storage } = options;

        this.clientId = clientId;
        this.environment = environment;
        this.storage = storage;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.keyLocation - The URL of the decryption key.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {Number} [options.playheadPosition] - The location of the current playhead, measured as a millisecond offset
     * from the start time. -1 if value is not available.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Retrieves a SILK decryption key from the key service.
     * @returns {Promise<Uint8Array>} The decryption key for this event.
     *
     */
    public async getSilkKey(options: {
        keyLocation: string;
        mediaItem: MediaItem;
        playheadPosition?: number;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    keyLocation: Types.nonEmptyString,
                    mediaItem: Types.instanceStrict(MediaItem),
                    playheadPosition: Types.number.optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getSilkKey', params, arguments);
        }

        const { logger } = this;

        const {
            keyLocation,
            mediaItem,
            playheadPosition,
            accessToken,
            logTransaction
        } = options;

        const endpointKey = DrmClientEndpoint.silkKey as DrmClientEndpoint;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey,
            bodyType: 'json'
        });

        // set the payload URL based on the provided key location
        payload.url = keyLocation;

        logger.info(
            this.toString(),
            `Attempting to retrieve SilkKey from keyLocation: ${keyLocation}.`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getSilkKey,
            payload,
            endpointKey,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmLicenseEvent',
            dustLogUtility,
            mediaItem,
            payload,
            playheadPosition
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @desc Retrieves a Fairplay Certificate.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH FairPlay Application Certificate as binary data.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getFairPlayCertificate(options: {
        accessToken: AccessToken;
        mediaItem: MediaItem;
        logTransaction: LogTransaction;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    mediaItem: Types.instanceStrict(MediaItem),
                    logTransaction: Types.instanceStrict(LogTransaction),
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional
                })
            };

            typecheck(this, 'getFairPlayCertificate', params, arguments);
        }

        const {
            accessToken,
            mediaItem,
            logTransaction,
            videoPlayerName,
            videoPlayerVersion
        } = options;

        const { logger } = this;

        const endpointKey = DrmClientEndpoint.fairPlayCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve FairPlay Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getFairPlayCertificate,
            payload,
            endpointKey,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmCertificateEvent',
            dustLogUtility,
            mediaItem,
            payload,
            videoPlayerName,
            videoPlayerVersion
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array|String} options.serverPlaybackContext - A server playback context created by the DRM client
     * (typically directly by the player or the platform).
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a FairPlay content key context from the license service to authorize and decrypt media.
     * @returns {Promise<Uint8Array>} A Promise fulfilled by a content key context from the license server.
     * @note This certificate is the same for all partners. It will be hosted statically and can be stored on a
     * long-term basis.
     *
     */
    public async getFairPlayLicense(options: {
        serverPlaybackContext: Uint8Array | string;
        endpointKey?: string;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    // serverPlaybackContext: Types.arrayBufferView.or.nonEmptyString,
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem).optional,
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getFairPlayLicense', params, arguments);
        }

        const { logger } = this;

        const {
            serverPlaybackContext,
            endpointKey,
            mediaItem,
            accessToken,
            logTransaction
        } = options;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.fairPlayLicense,
            body: serverPlaybackContext,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve FairPlay License: ${JSON.stringify(
                serverPlaybackContext
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getFairPlayLicense,
            payload,
            endpointKey: endpointKey || DrmClientEndpoint.fairPlayLicense,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmLicenseEvent',
            dustLogUtility,
            mediaItem,
            payload
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @desc Retrieves the BAMTECH Widevine Service Certificate.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH Widevine Service Certificate as binary data.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getWidevineCertificate(options: {
        accessToken: AccessToken;
        mediaItem: MediaItem;
        logTransaction: LogTransaction;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    mediaItem: Types.instanceStrict(MediaItem),
                    logTransaction: Types.instanceStrict(LogTransaction),
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional
                })
            };

            typecheck(this, 'getWidevineCertificate', params, arguments);
        }

        const {
            accessToken,
            mediaItem,
            logTransaction,
            videoPlayerName,
            videoPlayerVersion
        } = options;

        const { logger } = this;

        const endpointKey = DrmClientEndpoint.widevineCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve Widevine Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getWidevineCertificate,
            payload,
            endpointKey,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmCertificateEvent',
            dustLogUtility,
            mediaItem,
            payload,
            videoPlayerName,
            videoPlayerVersion
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {Uint8Array} options.buffer - A protocol buffer conforming to
     * the Widevine specification identifying the asset and encryption mode.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a Widevine License from the license service.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled with the Widevine license as binary data.
     * @note Nearly all Widevine DRM clients will provide a mechanism to create the Widevine protocol buffer. The
     * SDK shall leverage these capabilities wherever possible.
     *
     */
    public async getWidevineLicense(options: {
        buffer: Uint8Array;
        endpointKey?: DrmClientEndpoint;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    buffer: Types.arrayBufferView,
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getWidevineLicense', params, arguments);
        }

        const { logger } = this;

        const { buffer, endpointKey, mediaItem, accessToken, logTransaction } =
            options;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.widevineLicense,
            body: buffer,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve Widevine License with: ${JSON.stringify(
                buffer
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getWidevineLicense,
            payload,
            endpointKey: endpointKey || DrmClientEndpoint.widevineLicense,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmLicenseEvent',
            dustLogUtility,
            mediaItem,
            payload
        });
    }

    /**
     *
     * @access public
     * @param {Object} options
     * @param {SDK.Services.Drm.PlayReadyMessage} options.message - A configured `PlayReadyMessage` containing
     * any required headers and a `PlayReadyObject`.
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @desc Requests a PlayReady license from the license server.
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled with the PlayReady license as binary data.
     *
     */
    public async getPlayReadyLicense(options: {
        message: PlayReadyMessage;
        endpointKey?: DrmClientEndpoint;
        mediaItem: MediaItem;
        accessToken: AccessToken;
        logTransaction: LogTransaction;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    message: Types.instanceStrict(PlayReadyMessage),
                    endpointKey: Types.nonEmptyString.optional,
                    mediaItem: Types.instanceStrict(MediaItem),
                    accessToken: Types.instanceStrict(AccessToken),
                    logTransaction: Types.instanceStrict(LogTransaction)
                })
            };

            typecheck(this, 'getPlayReadyLicense', params, arguments);
        }

        const { logger } = this;

        const { message, endpointKey, mediaItem, accessToken, logTransaction } =
            options;

        const { body = {} } = message;

        const payload = this.getPayload({
            accessToken,
            rel: DrmClientEndpoint.playReadyLicense,
            body: body.xmlData,
            endpointKey
        });

        logger.info(
            this.toString(),
            `Attempting to retrieve PlayReady License: message: ${JSON.stringify(
                message.body
            )}.`
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getPlayReadyLicense,
            payload,
            endpointKey: endpointKey || DrmClientEndpoint.playReadyLicense,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmLicenseEvent',
            dustLogUtility,
            mediaItem,
            payload
        });
    }

    /**
     *
     * @access public
     * @since 4.4.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Logging.LogTransaction} options.logTransaction
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @desc Retrieves the BAMTECH Nagra Service Certificate (Open Vault).
     * @returns {Promise<ArrayBuffer>} A Promise fulfilled by the
     * BAMTECH Nagra Service Certificate as a JSON Object.
     * @note This certificate is the same for all partners.
     * It will be hosted statically and can be stored on a long-term basis.
     *
     */
    public async getNagraCertificate(options: {
        accessToken: AccessToken;
        mediaItem: MediaItem;
        logTransaction: LogTransaction;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken),
                    mediaItem: Types.instanceStrict(MediaItem),
                    logTransaction: Types.instanceStrict(LogTransaction),
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional
                })
            };

            typecheck(this, 'getNagraCertificate', params, arguments);
        }

        const {
            accessToken,
            mediaItem,
            logTransaction,
            videoPlayerName,
            videoPlayerVersion
        } = options;

        const { logger } = this;

        const endpointKey = DrmClientEndpoint.nagraCertificate;

        const payload = this.getPayload({
            accessToken,
            rel: endpointKey,
            bodyType: 'json'
        });

        logger.info(
            this.toString(),
            'Attempting to retrieve Nagra Certificate.'
        );

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: DrmClientDustUrnReference.getNagraCertificate,
            payload,
            endpointKey,
            logTransaction
        });

        return this.getResponseAndLog({
            eventHandler: 'sendQoeDrmCertificateEvent',
            dustLogUtility,
            mediaItem,
            payload,
            videoPlayerName,
            videoPlayerVersion
        });
    }

    /**
     *
     * @access private
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @returns {SDK.QualityOfService.ServerRequest}
     *
     */
    private getQosServerRequestData(dustLogUtility: DustLogUtility) {
        if (Check.not.instanceStrict(dustLogUtility, DustLogUtility)) {
            return new ServerRequest();
        }

        const { server, error } = dustLogUtility;
        const { roundTripTime, host, path, method = '', statusCode } = server;

        let networkError;
        let status = FetchStatus.completed;

        if (error) {
            if (
                statusCode === HttpStatus.UNAUTHORIZED ||
                statusCode === HttpStatus.FORBIDDEN
            ) {
                networkError = NetworkError.prohibited;
            } else if (statusCode && statusCode >= 500) {
                networkError = NetworkError.notConnected;
                status = FetchStatus.noNetwork;
            } else {
                networkError = NetworkError.unknown;
            }

            if (error.code === ErrorCode.unspecifiedDrmError.code) {
                networkError = NetworkError.unknown;
            }
        }

        return new ServerRequest({
            host,
            path,
            statusCode,
            roundTripTime,
            method: method.toLowerCase() as Method,
            status,
            error: networkError
        });
    }

    /**
     *
     * @access private
     * @since 15.2.0
     * @param {SDK.Services.Media.PlaybackContext} [playbackContext]
     * @returns {Object}
     *
     */
    private getCommonProperties(playbackContext?: PlaybackContext) {
        const { analyticsProvider } = this.logger;

        let data = playbackContext?.data ?? {};

        if (analyticsProvider) {
            const commonProperties = analyticsProvider.getCommonProperties(
                playbackContext?.playbackSessionId
            );

            data = {
                ...data,
                ...commonProperties
            };
        }

        return data;
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async getQosDecision(mediaItem: MediaItem) {
        const { qosDecisions = {} as QosDecisionsResponse } =
            mediaItem.payload.stream;

        const playlist = await mediaItem.getPreferredPlaylist();

        if (playlist.playlistType === PlaylistType.SLIDE) {
            return qosDecisions.slide;
        }

        return qosDecisions.complete;
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async getTrackingValues(
        mediaItem: MediaItem,
        mediaAnalyticsKey: MediaAnalyticsKey
    ) {
        const playlist = await mediaItem.getPreferredPlaylist();

        return playlist.getTrackingData(
            mediaAnalyticsKey,
            mediaItem.priorityTracking
        );
    }

    /**
     *
     * @access private
     * @since 24.0.0
     * @param {SDK.Media.MediaItem} mediaItem
     * @desc Gets the qoe object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async getQoeValue(mediaItem: MediaItem) {
        const playlist = await mediaItem.getPreferredPlaylist();

        if (mediaItem.priorityTracking) {
            return playlist.getTrackingData(
                MediaAnalyticsKey.qoe,
                mediaItem.priorityTracking
            );
        }

        return playlist.getTrackingData(MediaAnalyticsKey.qoe);
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {SDK.Media.MediaItem} mediaItem
     * @param {SDK.Services.Internal.Dust.DustLogUtility} dustLogUtility
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async sendQoeDrmCertificateEvent(options: {
        mediaItem: MediaItem;
        dustLogUtility: DustLogUtility;
        requestStartTime?: number;
        requestDuration?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        const {
            requestStartTime: fetchCertificateStartTime,
            requestDuration: fetchCertificateDuration
        } = options;
        const drmCertificateFetched = await this.createPlaybackStartupEventData(
            {
                ...options,
                fetchCertificateStartTime,
                fetchCertificateDuration,
                startupActivity: StartupActivity.drmCertificateFetched
            }
        );

        const qosLogUtility = new DustLogUtility({
            category: DustCategory.qoe,
            logger: this.logger,
            source: this.toString(),
            urn: QualityOfServiceDustUrnReference.playbackStartup,
            data: {
                ...drmCertificateFetched
            },
            skipLogTransaction: true,
            dataVersion: getDataVersion(
                QualityOfServiceDustUrnReference.playbackStartup
            )
        });

        qosLogUtility.log();
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {Object} options
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.Internal.Dust.DustLogUtility} options.dustLogUtility
     * @param {Number} [options.playheadPosition]
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async sendQoeDrmLicenseEvent(options: {
        mediaItem: MediaItem;
        dustLogUtility: DustLogUtility;
        playheadPosition?: number;
        requestStartTime?: number;
        requestDuration?: number;
    }) {
        const {
            requestStartTime: fetchLicenseStartTime,
            requestDuration: fetchLicenseDuration
        } = options;
        const drmKeyFetched = await this.createPlaybackStartupEventData({
            ...options,
            fetchLicenseStartTime,
            fetchLicenseDuration,
            startupActivity: StartupActivity.drmKeyFetched
        });

        const qosLogUtility = new DustLogUtility({
            category: DustCategory.qoe,
            logger: this.logger,
            source: this.toString(),
            urn: QualityOfServiceDustUrnReference.playbackStartup,
            data: {
                ...drmKeyFetched
            },
            skipLogTransaction: true,
            dataVersion: getDataVersion(
                QualityOfServiceDustUrnReference.playbackStartup
            )
        });

        qosLogUtility.log();
    }

    /**
     *
     * @access private
     * @since 15.2.1
     * @param {Object} options
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {SDK.Services.QualityOfService.StartupActivity} options.startupActivity
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {SDK.Services.Internal.Dust.DustLogUtility} options.dustLogUtility
     * @param {Number} [options.playheadPosition]
     * @desc Gets the qos decision object based on the current playlist type.
     * @returns {Object}
     *
     */
    private async createPlaybackStartupEventData(options: {
        mediaItem: MediaItem;
        startupActivity: StartupActivity;
        dustLogUtility: DustLogUtility;
        playheadPosition?: number;
        fetchCertificateStartTime?: number;
        fetchCertificateDuration?: number;
        fetchLicenseStartTime?: number;
        fetchLicenseDuration?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        const {
            mediaItem,
            startupActivity,
            dustLogUtility,
            playheadPosition,
            fetchCertificateStartTime,
            fetchCertificateDuration,
            fetchLicenseStartTime,
            fetchLicenseDuration,
            videoPlayerName,
            videoPlayerVersion
        } = options;

        const playbackContext = mediaItem.playbackContext;
        const qosDecision =
            (await this.getQosDecision(mediaItem)) || ({} as QosDecisions);

        const {
            clientDecisions = {} as ClientDecisions,
            serverDecisions = {} as ServerDecisions
        } = qosDecision;

        const {
            playbackSessionId,
            productType,
            offline,
            startupContext,
            interactionId
        } = playbackContext || {};

        const serverRequest = this.getQosServerRequestData(dustLogUtility);
        const data = this.getCommonProperties(playbackContext);
        const qos = await this.getTrackingValues(
            mediaItem,
            MediaAnalyticsKey.qos
        );
        const qoe = await this.getTrackingValues(
            mediaItem,
            MediaAnalyticsKey.qoe
        );

        return new PlaybackStartupEventData({
            startupActivity,
            playheadPosition,
            playbackSessionId,
            productType,
            localMedia: offline,
            serverRequest,
            clientGroupIds: clientDecisions.clientGroupIds,
            serverGroupIds: serverDecisions.serverGroupIds,
            qos,
            data,
            startupContext,
            fetchCertificateStartTime,
            fetchCertificateDuration,
            fetchLicenseStartTime,
            fetchLicenseDuration,
            interactionId,
            videoPlayerName,
            videoPlayerVersion,
            qoe
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {SDK.Services.Drm.DrmClientEndpoint} options.rel
     * @param {Object} [options.body]
     * @param {String} [options.endpointKey] - A key that helps determine what endpoint to use or the default endpoint will be used.
     * @param {String} [options.bodyType='arrayBuffer'] - default `bodyType` for DRM requests
     * @returns {Object} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        rel: DrmClientEndpoint;
        body?: TodoAny;
        endpointKey?: string;
        bodyType?: string;
    }) {
        const {
            accessToken,
            rel,
            body,
            endpointKey,
            bodyType = 'arrayBuffer'
        } = options;

        const { endpoints = {}, extras = {} as DrmClientExtrasMap } =
            this.config;

        const drmType = DrmUtils.getDrmType(rel);

        const {
            href: defaultHref,
            headers: defaultHeaders,
            method: defaultMethod
        } = endpoints[rel] as IEndpoint;

        const {
            href: url = defaultHref,
            headers = defaultHeaders,
            method = defaultMethod
        } = endpoints[
            extras.licenseEndpoints?.[drmType]?.[endpointKey as string]
        ] || {};

        let needArrayBuffer;

        if (requiresArrayBufferEndpoints.includes(rel)) {
            needArrayBuffer = true;
        }

        const requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: (accessToken as AccessToken).token
                    };
                }
            },
            headers
        );

        return {
            url,
            method,
            body,
            bodyType,
            headers: new HttpHeaders(requestHeaders),
            needArrayBuffer
        };
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @returns {Promise<ArrayBuffer | Uint8Array>} The `data` of the httpClient call
     *
     */
    private async getResponseAndLog(options: {
        eventHandler: 'sendQoeDrmCertificateEvent' | 'sendQoeDrmLicenseEvent';
        dustLogUtility: DustLogUtility;
        mediaItem: MediaItem;
        payload: GetPayloadResult;
        playheadPosition?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        const {
            eventHandler,
            dustLogUtility,
            mediaItem,
            payload,
            playheadPosition,
            videoPlayerName,
            videoPlayerVersion
        } = options;

        const { httpClient, logger } = this;
        const { playbackContext } = mediaItem;

        let data;
        let requestStartTime;
        let requestDuration;

        try {
            const response = await httpClient.request(payload);

            await handleServiceResponse({ response, dustLogUtility });

            ({ data, requestStartTime, requestDuration } = response);

            return data as ArrayBuffer | Uint8Array;
        } finally {
            if (logger.dustEnabled && Check.assigned(playbackContext)) {
                await this[eventHandler]({
                    mediaItem,
                    dustLogUtility,
                    playheadPosition,
                    requestStartTime,
                    requestDuration,
                    videoPlayerName,
                    videoPlayerVersion
                });
            }

            dustLogUtility.log();
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Drm.DrmClient';
    }
}
