/**
 *
 * @module glimpseEvents
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/user-activity.md#known-events
 *
 */

import DustUrnReference from '../../services/internal/dust/dustUrnReference';

const GlimpseUrnReference = DustUrnReference.userActivity.glimpse;

function GlimpseEvent(eventUrn: string) {
    return {
        eventUrn
    };
}

/**
 *
 * @access public
 * @type {Object<String, GlimpseEvent>}
 * @desc contains a list of predefined Glimpse events
 * @note the IIFE is required as the application developer will not be expecting to invoke this method
 *
 */
export default {
    appLaunched: GlimpseEvent(GlimpseUrnReference.appLaunched),
    backgroundPlaybackStarted: GlimpseEvent(
        GlimpseUrnReference.backgroundPlaybackStarted
    ),
    backgroundPlaybackExited: GlimpseEvent(
        GlimpseUrnReference.backgroundPlaybackExited
    ),
    contentSelected: GlimpseEvent(GlimpseUrnReference.contentSelected),
    searchViewLoaded: GlimpseEvent(GlimpseUrnReference.searchViewLoaded),
    playbackSelected: GlimpseEvent(GlimpseUrnReference.playbackSelected),
    playbackExited: GlimpseEvent(GlimpseUrnReference.playbackExited),
    /**
     *
     * @deprecated Deprecated as of `22.0.0`. GlimpseEvents.purchaseCompletedv2 should be used instead.
     *
     */
    purchaseCompleted: GlimpseEvent(GlimpseUrnReference.purchaseCompleted),
    purchaseCompletedv2: GlimpseEvent(GlimpseUrnReference.purchaseCompletedv2),
    viewLoaded: GlimpseEvent(GlimpseUrnReference.viewLoaded)
};
