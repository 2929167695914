/**
 *
 * @module serviceClientConfiguration
 *
 */

import { IEndpoints } from '../providers/typedefs';

/**
 *
 * @access protected
 * @desc A model containing service client configuration settings.
 *
 */
export default class ServiceClientConfiguration<
    TEndpoints = Record<string, never>,
    TExtras = Record<string, never>
> {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public baseUrl: string;
    /**
     *
     * @access public
     * @type {Object}
     *
     */
    public endpoints: IEndpoints<TEndpoints>;
    /**
     *
     * @access public
     * @type {TExtras}
     *
     */
    public extras: TExtras;

    /**
     *
     * @param {Object} options
     * @param {String} options.baseUrl
     * @param {Object} [options.endpoints={}]
     * @param {Object} [options.extras={}]
     *
     */
    public constructor(options: {
        baseUrl: string;
        endpoints?: IEndpoints<TEndpoints>;
        extras?: TExtras;
    }) {
        const { baseUrl, endpoints, extras } = options;

        this.baseUrl = baseUrl;
        this.endpoints = endpoints || ({} as IEndpoints<TEndpoints>);
        this.extras = extras || ({} as TExtras);
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ServiceClientConfiguration';
    }
}
