/**
 *
 * @module errorReason
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @desc information regarding exception
 *
 */
export default class ErrorReason {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public code: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public description: string;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Number|undefined}
     *
     */
    public backoffSeconds?: number;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Object|undefined}
     *
     */
    public data?: Record<string, unknown>;

    /**
     *
     * @param {Object} [options] - Since 24.0.0
     * @param {String} [options.code=''] - Reason code. Can be used by the application developer to look up localized error messages.
     * @param {String} [options.description=''] - Additional error information. May be used to get a more specific error message, such as with token service.
     * @param {Number} [options.backoffSeconds] - Since 24.0.0 - The amount of time clients should wait until making subsequent requests.
     * @param {Object} [options.data] - Since 24.0.0 - Optional, generic map containing service specific data.
     *
     */
    public constructor(options?: {
        code?: string;
        description?: string;
        backoffSeconds?: number;
        data?: Record<string, unknown>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    code: Types.string.optional,
                    description: Types.string.optional,
                    backoffSeconds: Types.number.optional,
                    data: Types.object().optional
                }).optional
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            code = '',
            description = '',
            backoffSeconds,
            data
        } = options || {};

        this.code = code;
        this.description = description;
        this.backoffSeconds = backoffSeconds;
        this.data = data;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Exception.ErrorReason';
    }
}
