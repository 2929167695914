/**
 *
 * @module sdk
 * @desc {BAM.SDK} entry point (core).
 * @see https://github.bamtech.co/fed-packages/dss-type-checking/blob/master/docs/index.md#configuration
 *
 */

import { Config } from '@dss/type-checking';

import Device from './device/entry';
import Internal from './internal/entry';
import Logging from './logging/entry';
import Services from './services/entry';
import Token from './token/entry';
import VersionInfo from './versionInfo';

import SdkSession from './sdkSession';
import InternalEvents from './internalEvents';
import Events from './events';
import OffDeviceTokenRefreshEvent from './offDeviceTokenRefreshEvent';
import ReauthorizationFailure from './reauthorizationFailure';
import MonotonicTimestampProvider from './logging/monotonicTimestampProvider';
import Endpoint from './endpoint';
import DiagnosticsApi from './diagnosticsApi';
import DiagnosticFeature from './diagnosticFeature';
import SessionInfoChangedEvent from './sessionInfoChangedEvent';
import AccessChangedEvent from './accessChangedEvent';
import FeatureFlagsChangedEvent from './featureFlagsChangedEvent';
import InitializationState from './initializationState';
import PlatformProviders from './services/providers/platformProviders';

import AgeVerificationFlowType from './ageVerificationFlowType';
import * as SdkPluginInfo from './sdkPlugins';

/* istanbul ignore else */
if (__SDK_TYPECHECK__) {
    Config.setup({
        ErrorType: Services.Exception.InvalidArgumentException,
        logger: Logging
    });
}

const SDKCore = {
    /**
     *
     * @access public
     * @returns {VersionInfo}
     *
     */
    get VersionInfo() {
        return VersionInfo;
    },

    /**
     *
     * @param {Object} options
     * @param {Function<HttpClient>} options.HttpClient - an `HttpClient` class that implements `CoreHttpClientProvider`
     * @param {Function<SDK.Services.Configuration.BaseEnvironmentConfiguration>} options.EnvironmentConfiguration - an `EnvironmentConfiguration` class
     * @param {Function<Storage>} options.Storage - A `Storage` class
     * @note we can't typecheck the specific providers due to circular reference issues
     * in builds so we do an at minimum function/object structure validation.
     *
     */
    bootstrapProviders(options: TodoAny) {
        PlatformProviders.setProviders(options);
    },

    /**
     *
     * @access public
     * @returns {SDK.Device}
     *
     */
    get Device() {
        return Device;
    },

    /**
     *
     * @access public
     * @since 9.0.0
     * @returns {SDK.InitializationState}
     *
     */
    get InitializationState() {
        return InitializationState;
    },

    /**
     *
     * @access private
     * @returns {SDK.Internal}
     *
     */
    get Internal() {
        return Internal;
    },

    /**
     *
     * @access public
     * @returns {SDK.Logging}
     *
     */
    get Logging() {
        return Logging;
    },

    /**
     *
     * @access private
     * @returns {SDK.Services}
     *
     */
    get Services() {
        return Services;
    },

    /**
     *
     * @access public
     * @returns {SDK.Token}
     *
     */
    get Token() {
        return Token;
    },

    /**
     *
     * @access public
     * @returns {SdkSession}
     *
     */
    get SdkSession() {
        return SdkSession;
    },

    /**
     *
     * @access private
     * @since 10.0.0
     * @returns {SDK.InternalEvents}
     *
     */
    get InternalEvents() {
        return InternalEvents;
    },

    /**
     *
     * @access public
     * @since 10.0.0
     * @returns {SDK.Events}
     *
     */
    get Events() {
        return Events;
    },

    /**
     *
     * @access public
     * @since 4.15.0
     * @returns {OffDeviceTokenRefreshEvent}
     *
     */
    get OffDeviceTokenRefreshEvent() {
        return OffDeviceTokenRefreshEvent;
    },

    /**
     *
     * @access public
     * @returns {ReauthorizationFailure}
     *
     */
    get ReauthorizationFailure() {
        return ReauthorizationFailure;
    },

    /**
     *
     * @access public
     * @since 20.0.0
     * @returns {MonotonicTimestampProvider}
     *
     */
    get MonotonicTimestampProvider() {
        return MonotonicTimestampProvider;
    },

    /**
     *
     * @access public
     * @returns {Endpoint}
     *
     */
    get Endpoint() {
        return Endpoint;
    },

    /**
     *
     * @access public
     * @since 4.11.0
     * @returns {DiagnosticsApi}
     *
     */
    get DiagnosticsApi() {
        return DiagnosticsApi;
    },

    /**
     *
     * @access public
     * @since 4.11.0
     * @returns {DiagnosticFeature}
     *
     */
    get DiagnosticFeature() {
        return DiagnosticFeature;
    },

    /**
     *
     * @access public
     * @returns {SessionInfoChangedEvent}
     *
     */
    get SessionInfoChangedEvent() {
        return SessionInfoChangedEvent;
    },

    /**
     *
     * @access public
     * @since 9.0.0
     * @returns {AccessChangedEvent}
     *
     */
    get AccessChangedEvent() {
        return AccessChangedEvent;
    },

    /**
     *
     * @access public
     * @since 15.0.0
     * @returns {FeatureFlagsChangedEvent}
     *
     */
    get FeatureFlagsChangedEvent() {
        return FeatureFlagsChangedEvent;
    },

    /**
     *
     * @access public
     * @since 12.0.0
     * @returns {AgeVerificationFlowType}
     *
     */
    get AgeVerificationFlowType() {
        return AgeVerificationFlowType;
    }
};

export default {
    ...SDKCore,
    extendSDKWithPlugins: SdkPluginInfo.extendSDKWithPlugins
};
