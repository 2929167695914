/**
 *
 * @module mediaManagerConfiguration
 *
 */

import MediaClientConfiguration from '../media/mediaClientConfiguration';
import MediaManagerExtrasMap from './mediaManagerExtrasMap';
import ServiceConfiguration from './serviceConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing media.
 *
 */
export default class MediaManagerConfiguration extends ServiceConfiguration<
    MediaClientConfiguration,
    MediaManagerExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.MediaManagerConfiguration';
    }
}
