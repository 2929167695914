/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';

import { PaymentMethod, PaymentMethodTypedef } from '../../commerce/typedefs';

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Address
 * @since 11.0.0
 * @property {String} [streetAddress]
 * @property {String} [city]
 * @property {String} [state]
 * @property {String} [postalCode]
 * @property {String} country
 * @property {String} [phoneNumber]
 * @property {String} [faxNumber]
 *
 */
export type Address = {
    streetAddress?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country: string;
    phoneNumber?: string;
    faxNumber?: string;
};

/**
 *
 * @access private
 *
 */
export const AddressTypedef = {
    streetAddress: Types.nonEmptyString.optional,
    city: Types.nonEmptyString.optional,
    state: Types.nonEmptyString.optional,
    postalCode: Types.nonEmptyString.optional,
    country: Types.nonEmptyString,
    phoneNumber: Types.nonEmptyString.optional,
    faxNumber: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.BusinessEntity
 * @since 11.0.0
 * @property {String} name
 * @property {Object<SDK.Services.Invoice.Address>} address
 * @property {String} [vatTaxId]
 *
 */
export type BusinessEntity = {
    name: string;
    address: Address;
    vatTaxId?: string;
};

/**
 *
 * @access private
 *
 */
export const BusinessEntityTypedef = {
    name: Types.nonEmptyString,
    address: Types.object(AddressTypedef),
    vatTaxId: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.TaxDocument
 * @since 11.0.0
 * @property {String} [type]
 * @property {String} [url]
 *
 */
export type TaxDocument = {
    type?: string;
    url?: string;
};

/**
 *
 * @access private
 *
 */
export const TaxDocumentTypedef = {
    type: Types.nonEmptyString.optional,
    url: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Breakdown
 * @since 11.0.0
 * @property {Number} rate
 * @property {Number} calculatedTax
 * @property {String} jurisdiction
 * @property {String} type
 *
 */
export type Breakdown = {
    rate: number;
    calculatedTax: number;
    jurisdiction: string;
    type: string;
};

/**
 *
 * @access private
 *
 */
export const BreakdownTypedef = {
    rate: Types.number,
    calculatedTax: Types.number,
    jurisdiction: Types.nonEmptyString,
    type: Types.nonEmptyString
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Tax
 * @since 11.0.0
 * @property {Number} total
 * @property {Number} effectiveRate
 * @property {Array<Object<SDK.Service.Invoice.Breakdown>>} breakdown
 *
 */
export type Tax = {
    total: number;
    effectiveRate: number;
    breakdown: Array<Breakdown>;
};

/**
 *
 * @access private
 *
 */
export const TaxTypedef = {
    total: Types.number,
    effectiveRate: Types.number,
    breakdown: Types.array.of.object(BreakdownTypedef)
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Product
 * @since 11.0.0
 * @property {String} sku
 * @property {String} name
 * @property {Number} price
 *
 */
export type Product = {
    sku: string;
    name: string;
    price: number;
};

/**
 *
 * @access private
 *
 */
export const ProductTypedef = {
    sku: Types.nonEmptyString,
    name: Types.nonEmptyString,
    price: Types.number
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.ServicePeriod
 * @since 11.0.0
 * @property {String} start
 * @property {String} [end]
 *
 */
export type ServicePeriod = {
    start: string;
    end?: string;
};

/**
 *
 * @access private
 *
 */
export const ServicePeriodTypedef = {
    start: Types.nonEmptyString,
    end: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.LineItem
 * @since 11.0.0
 * @property {Number} total
 * @property {Number} subtotal
 * @property {Number} discount
 * @property {Object<SDK.Services.Invoice.Tax>} tax
 * @property {Object<SDK.Services.Invoice.Product>} product
 * @property {Object<SDK.Services.Invoice.ServicePeriod>} servicePeriod
 *
 */
export type LineItem = {
    total: number;
    subtotal: number;
    discount: number;
    tax: Tax;
    product: Product;
    servicePeriod: ServicePeriod;
};

/**
 *
 * @access private
 *
 */
export const LineItemTypedef = {
    total: Types.number,
    subtotal: Types.number,
    discount: Types.number,
    tax: Types.object(TaxTypedef),
    product: Types.object(ProductTypedef),
    servicePeriod: Types.object(ServicePeriodTypedef)
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Invoice
 * @since 11.0.0
 * @property {String} reference
 * @property {String} [externalReference]
 * @property {String} due
 * @property {Number} total
 * @property {Number} subtotal
 * @property {Number} tax
 * @property {Array<Object<SDK.Services.Invoice.TaxDocument>>} [taxDocuments]
 * @property {String} currency
 * @property {Object<SDK.Commerce.PaymentMethod>} paymentMethod
 * @property {Object<SDK.Services.Invoice.BusinessEntity>} [businessEntity]
 * @property {Array<Object<SDK.Services.Invoice.LineItem>>} lineItems
 *
 */
export type Invoice = {
    reference: string;
    externalReference?: string;
    due: string;
    total: number;
    subtotal: number;
    tax: number;
    taxDocuments?: Array<TaxDocument>;
    currency: string;
    paymentMethod: PaymentMethod;
    businessEntity?: BusinessEntity;
    lineItems: Array<LineItem>;
};

/**
 *
 * @access private
 *
 */
export const InvoiceTypedef = {
    reference: Types.nonEmptyString,
    externalReference: Types.nonEmptyString.optional,
    due: Types.nonEmptyString,
    total: Types.number,
    subtotal: Types.number,
    tax: Types.number,
    taxDocuments: Types.array.of.object(TaxDocumentTypedef).optional,
    currency: Types.nonEmptyString,
    paymentMethod: Types.object(PaymentMethodTypedef),
    businessEntity: Types.object(BusinessEntityTypedef).optional,
    lineItems: Types.array.of.object(LineItemTypedef)
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.PagedInvoice
 * @since 11.0.0
 * @property {String} due
 * @property {Number} total
 * @property {String} currency
 * @property {Array<Object<SDK.Services.Invoice.LineItem>>} lineItems
 *
 */
export type PagedInvoice = {
    due: string;
    total: number;
    currency: string;
    lineItems: Array<LineItem>;
};

/**
 *
 * @access private
 *
 */
export const PagedInvoiceTypedef = {
    due: Types.nonEmptyString,
    total: Types.number,
    currency: Types.nonEmptyString,
    lineItems: Types.array.of.object(LineItemTypedef)
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.ItemInvoice
 * @since 11.0.0
 * @property {String} link
 * @property {Object<SDK.Services.Invoice.PagedInvoice>} invoice
 *
 */
export type ItemInvoice = {
    link: string;
    invoice: PagedInvoice;
};

/**
 *
 * @access private
 *
 */
export const ItemInvoiceTypedef = {
    link: Types.nonEmptyString,
    invoice: Types.object(PagedInvoiceTypedef)
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.Paging
 * @since 11.0.0
 * @property {String} [nextPageToken]
 *
 */
export type Paging = {
    nextPageToken?: string;
};

/**
 *
 * @access private
 *
 */
export const PagingTypedef = {
    nextPageToken: Types.nonEmptyString.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Invoice.PaginatedInvoicesResponse
 * @since 11.0.0
 * @property {Object<SDK.Services.Invoice.Paging>} [paging]
 * @property {Array<Object<SDK.Services.Invoice.ItemInvoice>>} items
 *
 */
export type PaginatedInvoicesResponse = {
    paging?: Paging;
    items: Array<ItemInvoice>;
};

/**
 *
 * @access private
 *
 */
export const PaginatedInvoicesResponseTypedef = {
    paging: Types.object(PagingTypedef).optional,
    items: Types.array.of.object(ItemInvoiceTypedef)
};
