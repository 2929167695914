/**
 *
 * @module refreshGrant
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import TokenRequestBuilder from './tokenRequestBuilder';

/**
 *
 * @access public
 * @desc Represents the response from exchanging a token and provides
 * access context for subsequent service requests.
 *
 */
export default class RefreshGrant extends TokenRequestBuilder {
    /**
     *
     * @access protected
     * @type {String}
     *
     */
    public override readonly grantType: string;

    /**
     *
     * @param {String} [refreshToken]
     *
     */
    public constructor(refreshToken?: string) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                refreshToken: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.grantType = 'refresh_token';
        // assign refresh_token
        this.tokenData.refresh_token = refreshToken || '';
    }

    /**
     *
     * @access public
     * @type {String}
     * @desc The refresh token, which can be used to obtain a new access token.
     * @note A getter is used to avoid serialization
     *
     */
    public get refreshToken() {
        return this.tokenData.refresh_token;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.RefreshGrant';
    }
}
