/**
 *
 * @module subscriptionPlugin
 * @desc {SDK.Subscription.SubscriptionPlugin} entry point.
 *
 */

import Subscription from './entry';
import SubscriptionApi from './subscriptionApi';
import SubscriptionService from '../services/subscription/entry';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<
    'Subscription',
    typeof Subscription,
    typeof SubscriptionService
> = {
    pluginName: 'Subscription',
    entry: Subscription,
    service: SubscriptionService,

    /**
     *
     * @access protected
     * @since 29.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.subscriptionApi = new SubscriptionApi({
            subscriptionClient: clients.subscriptionClient,
            accessTokenProvider,
            logger
        });
    }
};

/**
 *
 * @access public
 * @since 29.0.0
 *
 */
export default plugin;
