/**
 *
 * @module identity
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 3.5.0
 *
 */
export default class Identity {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public identityId: string;

    /**
     *
     * @access public
     * @type {String}
     *
     */
    public providerId: string;

    /**
     *
     * @param {String} identityId
     * @param {String} providerId
     *
     */
    public constructor(identityId: string, providerId: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                identityId: Types.nonEmptyString,
                providerId: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.identityId = identityId;
        this.providerId = providerId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Account.Identity';
    }
}
