/**
 *
 * @module featureFlagsStorage
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { EventEmitter } from 'events';

import Logger from '../logging/logger';
import Events from '../events';
import FeatureFlagsChangedEvent from '../featureFlagsChangedEvent';
import PlatformProviders from '../services/providers/platformProviders';
import type CoreStorageProvider from '../services/providers/shared/coreStorageProvider';
import { FEATURE_FLAGS_KEY } from '../services/providers/shared/storageConstants';
import { REPLACE_NL_WS } from '../constants';

/**
 *
 * @since 15.0.0
 * @desc Provides a storage mechanism for storing feature flags.
 * @note FeatureFlagsStorage should never be cleared, only updated based on the Orchestration response.
 *
 */
export default class FeatureFlagsStorage extends EventEmitter {
    /**
     *
     * @access private
     * @since 15.0.0
     * @type {String}
     *
     */
    private clientId: string;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {String}
     *
     */
    private environment: string;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {SDK.Services.PlatformProviders.Storage}
     *
     */
    private storage: CoreStorageProvider;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {String}
     * @desc cache key scoped under client ID and environment to prevent clashes,
     * maintains the same structure as all other cacheKey(s) in the SDK.
     *
     */
    private cacheKey: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.clientId
     * @param {String} options.environment
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Services.PlatformProviders.Storage} options.storage
     *
     */
    public constructor(options: {
        clientId: string;
        environment: string;
        logger: Logger;
        storage: CoreStorageProvider;
    }) {
        super();

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    clientId: Types.nonEmptyString,
                    environment: Types.nonEmptyString,
                    logger: Types.instanceStrict(Logger),
                    storage: Types.instanceStrict(PlatformProviders.Storage)
                })
            };

            typecheck(this, params, arguments);
        }

        const { clientId, environment, logger, storage } = options;

        this.clientId = clientId;
        this.environment = environment;
        this.logger = logger;
        this.storage = storage;
        this.cacheKey = `
            ${FEATURE_FLAGS_KEY}${this.clientId}_${this.environment}
        `.replace(REPLACE_NL_WS, '');

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @param {Object} featureFlags
     * @desc Stores feature flags in Storage and raises `FeatureFlagsChanged` event.
     * @returns {Promise<Void>}
     *
     */
    public async saveFeatureFlags(featureFlags: Record<string, unknown>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                featureFlags: Types.object()
            };

            typecheck(this, 'saveFeatureFlags', params, arguments);
        }

        this.logger.info(
            this.toString(),
            `Save feature flags to Storage using key: "${this.cacheKey}".`
        );

        const oldFeatureFlags = await this.getFeatureFlags();

        const featureFlagsChangedEvent = new FeatureFlagsChangedEvent(
            oldFeatureFlags,
            featureFlags
        );

        await this.storage.set(this.cacheKey, featureFlags);

        this.emit(Events.FeatureFlagsChanged, featureFlagsChangedEvent);
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc Returns feature flags from Storage.
     * @returns {Promise<Object|undefined>}
     *
     */
    public async getFeatureFlags() {
        this.logger.log(this.toString(), 'Get cached feature flags.');

        let cachedInfo;

        try {
            cachedInfo = await this.storage.get(this.cacheKey);
        } catch (ex) {
            this.logger.error(this.toString(), ex);
        }

        return cachedInfo;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Session.FeatureFlagsStorage';
    }
}
