/**
 *
 * @module entry
 * @desc {SDK.Services.Subscriber} entry point.
 *
 */

import {
    CancellationType,
    RiskState,
    SourceSubType,
    StackingStatus,
    SubscriberPeriod,
    SubscriberStatus,
    SubscriptionState
} from './enums';

/**
 *
 * @typedef {Object} SDK.Services.Subscriber
 *
 * @property {SDK.Services.Subscriber.CancellationType} CancellationType
 * @property {SDK.Services.Subscriber.RiskState} RiskState
 * @property {SDK.Services.Subscriber.SourceSubType} SourceSubType
 * @property {SDK.Services.Subscriber.StackingStatus} StackingStatus
 * @property {SDK.Services.Subscriber.SubscriberPeriod} SubscriberPeriod
 * @property {SDK.Services.Subscriber.SubscriberStatus} SubscriberStatus
 * @property {SDK.Services.Subscriber.SubscriptionState} SubscriptionState
 *
 */
export default {
    CancellationType,
    RiskState,
    SourceSubType,
    StackingStatus,
    SubscriberPeriod,
    SubscriberStatus,
    SubscriptionState
};
