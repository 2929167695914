/**
 *
 * @module flexErrorHandler
 *
 */

import ErrorReason from '../../../exception/errorReason';
import { IErrorHandler } from './IErrorHandler';
import { FlexErrorDetails } from '../typedefs';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Handles errors returned by the Flex service.
 * @returns {Object}
 *
 */
const FlexErrorHandler: IErrorHandler = {
    is: (options) => {
        const { data = {} } = options || {};
        const { details = [] } =
            typeof data.error === 'object' ? data.error : {};

        return !!details.length;
    },
    process: (options) => {
        const { data = {} } = options;
        const { details = [] } = (data.error as FlexErrorDetails) || {};
        const errorReasons: Array<ErrorReason> = [];

        details.forEach((detail: Record<string, string>) => {
            const { code, issue: description } = detail;

            errorReasons.push(new ErrorReason({ code, description }));
        });

        return { errorReasons };
    }
};

export default FlexErrorHandler;
