/**
 *
 * @module invoiceClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the InvoiceClient.
 *
 */
export default class InvoiceClientConfiguration extends ServiceClientConfiguration {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Invoice.InvoiceClientConfiguration';
    }
}
