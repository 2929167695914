/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

/**
 *
 * @typedef {Object} SDK.Services.Internal.Telemetry.HoraValidationManifest
 * @since 14.0.0
 * @param {*} [options.accepts]
 * @param {String} [options.description]
 * @param {Object} [options.metadataMatcher]
 * @param {String} [options.owner]
 * @param {String} [options.status]
 * @param {String} [options.url]
 * @param {Array<String>} [options.urnCategoryMatcher]
 *
 */
export type HoraValidationManifest = {
    description?: string;
    metadataMatcher?: object;
    owner?: string;
    status?: string;
    url?: string;
    urnCategoryMatcher?: Array<string>;
};

export const HoraValidationManifestTypedef = {
    description: Types.string.optional,
    metadataMatcher: Types.object().optional,
    owner: Types.string.optional,
    status: Types.string.optional,
    url: Types.string.optional,
    urnCategoryMatcher: Types.array.of.string.optional
};

type HoraValidationResultInternal = {
    countErrors?: number;
    countErrorsRelative?: number;
    countSkipped?: number;
    countSuccess?: number;
    skipped?: Array<object>;
    description?: string;
    failures?: Array<object>;
    httpMessage?: string;
    httpStatus?: number;
    name?: string;
    result?: string;
    sha?: string;
    source?: string;
    successes?: Array<object>;
    validationManifest?: HoraValidationManifest;
    validationMessage?: Array<string>;
};

const HoraValidationResultInternalTypedef = {
    countErrors: Types.number.optional,
    countErrorsRelative: Types.number.optional,
    countSkipped: Types.number.optional,
    countSuccess: Types.number.optional,
    skipped: Types.array.of.object().optional,
    description: Types.string.optional,
    failures: Types.array.of.object().optional,
    httpMessage: Types.string.optional,
    httpStatus: Types.number.optional,
    name: Types.string.optional,
    result: Types.string.optional,
    sha: Types.string.optional,
    source: Types.string.optional,
    successes: Types.array.of.object().optional,
    validationManifest: Types.object(HoraValidationManifestTypedef).optional,
    validationMessage: Types.array.of.string.optional
};

/**
 *
 * @typedef {Object} SDK.Services.Internal.Telemetry.HoraValidationResult
 * @since 14.0.0
 * @param {Number} [options.countErrors]
 * @param {Number} [options.countErrorsRelative]
 * @param {Number} [options.countSkipped]
 * @param {Number} [options.countSuccess]
 * @param {Array<SDK.Services.Internal.Telemetry.HoraValidationResult>} [options.skipped]
 * @param {String} [options.description]
 * @param {Array<SDK.Services.Internal.Telemetry.HoraValidationResult>} [options.failures]
 * @param {String} [options.httpMessage]
 * @param {Number} [options.httpStatus]
 * @param {String} [options.name]
 * @param {String} [options.result]
 * @param {String} [options.sha]
 * @param {String} [options.source]
 * @param {Array<SDK.Services.Internal.Telemetry.HoraValidationResult>} [options.successes]
 * @param {SDK.Services.Internal.Telemetry.HoraValidationManifest} [options.validationManifest]
 * @param {Array<String>} [options.validationMessage]
 *
 */
export type HoraValidationResult = {
    countErrors?: number;
    countErrorsRelative?: number;
    countSkipped?: number;
    countSuccess?: number;
    skipped?: Array<HoraValidationResultInternal>;
    description?: string;
    failures?: Array<HoraValidationResultInternal>;
    httpMessage?: string;
    httpStatus?: number;
    name?: string;
    result?: string;
    sha?: string;
    source?: string;
    successes?: Array<HoraValidationResultInternal>;
    validationManifest?: HoraValidationManifest;
    validationMessage?: Array<string>;
};

export const HoraValidationResultTypedef = {
    countErrors: Types.number.optional,
    countErrorsRelative: Types.number.optional,
    countSkipped: Types.number.optional,
    countSuccess: Types.number.optional,
    skipped: Types.array.of.object(HoraValidationResultInternalTypedef)
        .optional,
    description: Types.string.optional,
    failures: Types.array.of.object(HoraValidationResultInternalTypedef)
        .optional,
    httpMessage: Types.string.optional,
    httpStatus: Types.number.optional,
    name: Types.string.optional,
    result: Types.string.optional,
    sha: Types.string.optional,
    source: Types.string.optional,
    successes: Types.array.of.object(HoraValidationResultInternalTypedef)
        .optional,
    validationManifest: Types.object(HoraValidationManifestTypedef).optional,
    validationMessage: Types.array.of.string.optional
};
