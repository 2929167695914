/**
 *
 * @module refreshMessageProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import SocketEvents from '../socketEvents';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import type SocketManager from '../socketManager';
import { RawSocketMessage } from '../typedefs';

const SocketUrns = DustUrnReference.socket;

/**
 *
 * @access private
 * @since 4.9.0
 * @desc Handles the refresh socket message
 *
 */
export default class RefreshMessageProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        const messageType = SocketUrns.socketManager.refresh;

        super(messageType);

        this.socketManager = socketManager;
    }

    /**
     *
     * @access public
     * @since 4.9.0
     * @desc Raises the `tokenRefreshRequested` event
     *
     */
    public async process(message: RawSocketMessage) {
        const { reason } = message.data as { reason: string };

        this.socketManager.emit(SocketEvents.tokenRefreshRequested, { reason });
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.RefreshMessageProcessor';
    }
}
