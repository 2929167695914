/**
 *
 * @module invalidArgumentException
 *
 */

import ErrorReason from './errorReason';
import ServiceException from './serviceException';

/**
 *
 * @desc One or more of the supplied variables does not pass validation.
 *
 */
export default class InvalidArgumentException extends ServiceException {
    /**
     *
     * @param {String} message - Human-readable description of the error.
     * @desc This will always be thrown at instantiation of an SDK class, and never after requesting for transactionId.
     * It will most often be thrown as part of an assertion test by the check-types library, and will need to
     * accept the args of JavaScript's TypeError class. As such, the 1st arg must be message, not transactionId.
     * Furthermore, it makes no sense to expect the 2nd and 3rd args expected by the ServiceException superclass.
     *
     */
    public constructor(message: string) {
        const reasons = [new ErrorReason({ description: message })];
        const exceptionData = {
            name: 'InvalidArgumentException',
            message
        };

        const options = { reasons, exceptionData };

        super(options);
    }

    /**
     *
     * @access public
     * @returns {String}
     *
     */
    public static get typeName() {
        return 'InvalidArgumentException';
    }

    /**
     *
     * @access public
     * @desc A brief message describing the exception
     * @returns {String}
     *
     */
    public static get message() {
        return 'One or more of the supplied variables does not pass validation.';
    }
}
