/**
 *
 * @module httpClientLegacy
 * @see https://github.github.io/fetch/
 * @see https://fetch.spec.whatwg.org/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 */

import fetch from '../../lib/whatwg-fetch';

import Logger from '../../../logging/logger';
import TokenUpdater from '../../tokenUpdater';
import HttpClientBase from './httpClientBase';

export default class HttpClient extends HttpClientBase {
    /**
     *
     * @param {SDK.Logging.Logger} logger
     * @param {TokenUpdater} tokenUpdater
     *
     */
    public constructor(logger: Logger, tokenUpdater: TokenUpdater) {
        super({
            logger,
            tokenUpdater,
            fetch,
            Request: fetch.Request,
            Headers: fetch.Headers
        });

        this.logger.log(
            this.toString(),
            'Created Legacy Browser HttpClient Provider.'
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Providers.Browser.HttpClientLegacy';
    }
}
