/**
 *
 * @module edgeEvent
 * @see https://github.bamtech.co/schema-registry/schema-registry-sdk/blob/series/1.X.X/yaml/dss/event/sdk/client/api/event.oas2.yaml
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ServiceException from '../../services/exception/serviceException';

import {
    Invocation,
    InvocationTypedef,
    ServiceInteraction,
    ServiceInteractionTypedef
} from './typedefs';

/**
 *
 * @access protected
 * @since 13.0.0
 *
 */
export default class EdgeEvent {
    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Object<SDK.Services.Internal.Dust.Invocation>}
     *
     */
    private invocation: Invocation;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {String}
     * @desc The timestamp of when the event was logged, in ISO 8601/RFC-3339 format.
     *
     */
    private startTime: string;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Number}
     * @desc The total duration the API took to run (i.e. all underlying service calls and processing).
     *
     */
    private totalDuration: number;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Array<Object<SDK.Services.Internal.Dust.ServiceInteraction>>}
     * @desc The array of service interactions metadata.
     *
     */
    private services: Array<ServiceInteraction>;

    /**
     *
     * @access private
     * @since 13.0.0
     * @type {Object|undefined}
     *
     */
    private error?: unknown;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {String}
     * @desc Root level GUID that groups all events within an instantiated version of an SDK.
     *
     */
    private sdkInstanceId: string;

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {Object} options
     * @param {Object<SDK.Internal.Dust.Invocation>} options.invocation
     * @param {Array<Object<SDK.Internal.Dust.ServiceInteraction>>} options.services
     * @param {Date} options.startTime
     * @param {Number} options.totalDuration
     * @param {ServiceException} [options.error]
     * @param {String} options.sdkInstanceId
     *
     */
    public constructor(options: {
        invocation: Invocation;
        services: Array<ServiceInteraction>;
        startTime: Date;
        totalDuration: number;
        error?: unknown;
        sdkInstanceId: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    invocation: Types.object(InvocationTypedef),
                    services: Types.array.of.object(ServiceInteractionTypedef),
                    startTime: Types.date,
                    totalDuration: Types.number,
                    error: Types.instanceStrict(ServiceException).optional,
                    sdkInstanceId: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            invocation,
            services,
            startTime,
            totalDuration,
            error,
            sdkInstanceId
        } = options;

        this.invocation = invocation;
        this.startTime = startTime.toISOString();
        this.totalDuration = totalDuration;
        this.services = services;
        this.error = error ? { code: (error as TodoAny).name } : undefined;
        this.sdkInstanceId = sdkInstanceId;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Internal.Dust.EdgeEvent';
    }
}
