import EventEmitter from 'events';

class BroadcastService {
    static instance;
    public ee = new EventEmitter();

    static getInstance() {
        if (!BroadcastService.instance) {
            BroadcastService.instance = new BroadcastService();

            (window as any).broadcastService = BroadcastService.instance;
        }

        return BroadcastService.instance;
    }

    emit<T>(signal: string, payload: T): void {
        this.ee.emit(signal, payload);
    }

    on(signal: string, cb: (...args: any[]) => void): void {
        this.ee.on(signal, cb);
    }

    once(signal: string, cb: (...args: any[]) => void): void {
        this.ee.once(signal, cb);
    }

    off(signal: string, cb: (...args: any[]) => void): void {
        this.ee.off(signal, cb);
    }
}

export default BroadcastService;