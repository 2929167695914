/**
 *
 * @module telemetryManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';
import { UrnsFilterType } from '../../internal/telemetry/eventBufferBase';

import TelemetryBufferConfiguration from './telemetryBufferConfiguration';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class TelemetryManagerExtrasMap {
    /**
     *
     * @access protected
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @desc optional stream buffer configuration
     *
     */
    public streamSampleBufferConfiguration: TelemetryBufferConfiguration;

    /**
     *
     * @access protected
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @desc optional Glimpse buffer configuration
     *
     */
    public glimpseBufferConfiguration: TelemetryBufferConfiguration;

    /**
     *
     * @access protected
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @desc optional QoE buffer configuration
     *
     */
    public qoeBufferConfiguration: TelemetryBufferConfiguration;

    /**
     *
     * @access protected
     * @since 4.3.0
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @desc optional eventBufferConfiguration used for DUST
     *
     */
    public eventBufferConfiguration: TelemetryBufferConfiguration;

    /**
     *
     * @access protected
     * @since 3.10.0
     * @type {UrnsFilterType|undefined}
     * @desc contains fast track information for events - it is expected to contain a property `urns` which
     * is an `Array` that will contain a list of urn's used to fast track events
     *
     */
    public fastTrack?: UrnsFilterType;

    /**
     *
     * @access protected
     * @since 4.8.0
     * @type {UrnsFilterType}
     * @desc A collection of URNs to be prohibited.
     *
     */
    public prohibited: UrnsFilterType;

    /**
     *
     * @access protected
     * @since 4.8.0
     * @type {Boolean}
     * @desc Disables all custom DUST events when set to false.
     *
     */
    public permitAppDustEvents: boolean;

    /**
     *
     * @access protected
     * @since 23.0.0
     * @type {SDK.Services.Configuration.TelemetryBufferConfiguration}
     * @desc Optional config used for Dustv2 RESTful fallback.
     *
     */
    public dustEnvelopeBufferConfiguration: TelemetryBufferConfiguration;

    /**
     *
     * @access protected
     * @since 23.0.0
     * @type {Boolean}
     * @desc Flag to determine if sockets are enabled for sending dust events.
     *
     */
    public isEventsAtEdgeEnabled: boolean;

    /**
     *
     * @access protected
     * @since 24.0.0
     * @type {Boolean}
     * @desc Flag to determine if qoe or stream samples are preferred.
     * @note If `preferQoeOverStreamSamples` is not set in the configuration the SDK should default to true.
     *
     */
    public preferQoeOverStreamSamples: boolean;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {Number} options.batchLimit (deprecated)
     * @param {Number} options.replyAfterFallback (deprecated)
     * @param {Number} options.minimumBatchSize (deprecated)
     * @param {UrnsFilterType} options.prohibited
     * @param {Boolean} options.permitAppDustEvents
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.glimpseBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.streamSampleBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.qoeBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.eventBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.glimpseBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.streamSampleBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.qoeBufferConfiguration
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.eventBufferConfiguration
     * @param {UrnsFilterType} [options.fastTrack]
     * @param {SDK.Services.Configuration.TelemetryBufferConfiguration} options.dustEnvelopeBufferConfiguration
     * @param {Boolean} options.isEventsAtEdgeEnabled
     * @param {Boolean} [options.preferQoeOverStreamSamples=true]
     *
     */
    public constructor(options: {
        prohibited: UrnsFilterType;
        permitAppDustEvents: boolean;
        glimpseBufferConfiguration: TelemetryBufferConfiguration;
        streamSampleBufferConfiguration: TelemetryBufferConfiguration;
        qoeBufferConfiguration: TelemetryBufferConfiguration;
        eventBufferConfiguration: TelemetryBufferConfiguration;
        fastTrack?: UrnsFilterType;
        dustEnvelopeBufferConfiguration: TelemetryBufferConfiguration;
        isEventsAtEdgeEnabled: boolean;
        preferQoeOverStreamSamples?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    prohibited: Types.object(),
                    permitAppDustEvents: Types.boolean,
                    streamSampleBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    glimpseBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    qoeBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    eventBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    fastTrack: Types.object().optional,
                    dustEnvelopeBufferConfiguration: Types.instanceStrict(
                        TelemetryBufferConfiguration
                    ),
                    isEventsAtEdgeEnabled: Types.boolean,
                    preferQoeOverStreamSamples: Types.boolean.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            streamSampleBufferConfiguration,
            glimpseBufferConfiguration,
            qoeBufferConfiguration,
            eventBufferConfiguration,
            fastTrack,
            prohibited,
            permitAppDustEvents,
            dustEnvelopeBufferConfiguration,
            isEventsAtEdgeEnabled,
            preferQoeOverStreamSamples = true
        } = options;

        this.streamSampleBufferConfiguration = streamSampleBufferConfiguration;
        this.glimpseBufferConfiguration = glimpseBufferConfiguration;
        this.qoeBufferConfiguration = qoeBufferConfiguration;
        this.eventBufferConfiguration = eventBufferConfiguration;
        this.fastTrack = fastTrack;
        this.prohibited = prohibited;
        this.permitAppDustEvents = permitAppDustEvents;
        this.dustEnvelopeBufferConfiguration = dustEnvelopeBufferConfiguration;
        this.isEventsAtEdgeEnabled = isEventsAtEdgeEnabled;
        this.preferQoeOverStreamSamples = preferQoeOverStreamSamples;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.TelemetryManagerExtrasMap';
    }
}
