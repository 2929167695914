/**
 *
 * @module socketClientConfiguration
 *
 */

import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

import SocketClientExtrasMap from './socketClientExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the Socket.
 *
 */
export default class SocketClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    SocketClientExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Socket.SocketClientConfiguration';
    }
}
