/**
 *
 * @module videoResolution
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { ResolutionMax } from './enums';

/**
 *
 * @deprecated Deprecated as of version `28.0.0` and will be removed in version `29.0.0`. See release notes for replacement.
 * @since 4.18.0
 *
 */
export default class VideoResolution {
    /**
     *
     * @access public
     * @since 4.18.0
     * @type {Array<SDK.Services.Media.ResolutionMax>|undefined}
     * @desc `Array` of maximum supported video resolutions for the device.
     *
     */
    public max?: Array<ResolutionMax>;

    /**
     *
     * @param {Array<SDK.Services.Media.ResolutionMax>} [max]
     *
     */
    public constructor(max?: Array<ResolutionMax>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                max: Types.array.of.in(ResolutionMax).optional
            };

            typecheck(this, params, arguments);
        }

        this.max = max;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.VideoResolution';
    }
}
