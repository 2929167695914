/**
 *
 * @module exchangeDeviceGrantForAccessTokenRequest
 *
 */

import OrchestrationRequest from './orchestrationRequest';
import { exchangeDeviceGrantForAccessToken } from './queries.gql';

/**
 *
 * @since 10.0.0
 *
 */
export default class ExchangeDeviceGrantForAccessTokenRequest extends OrchestrationRequest {
    /**
     *
     * @param {Object} input
     *
     */
    public constructor(input: { deviceGrant: string; huluUserToken?: string }) {
        const variables = {
            input
        };

        super({
            query: exchangeDeviceGrantForAccessToken,
            variables,
            operationName: 'exchangeDeviceGrantForAccessToken'
        });
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Orchestration.Internal.ExchangeDeviceGrantForAccessTokenRequest';
    }
}
