/**
 *
 * @module entry
 * @desc {SDK.Ripcut} entry point.
 *
 */

import ImageFormat from './imageFormat';
import PNGCompression from './pngCompression';
import PVRTexture from './pvrTexture';
import PVRTextureQuality from './pvrTextureQuality';
import RipcutImageRequestMode from './ripcutImageRequestMode';
import RipcutLabelConfigurationRequestMode from './ripcutLabelConfigurationRequestMode';

/**
 *
 * @typedef {Object} SDK.Ripcut
 *
 * @property {ImageFormat} ImageFormat
 * @property {PNGCompression} PNGCompression
 * @property {PVRTexture} PVRTexture
 * @property {PVRTextureQuality} PVRTextureQuality
 * @property {RipcutImageRequestMode} RipcutImageRequestMode
 * @property {RipcutLabelConfigurationRequestMode} RipcutLabelConfigurationRequestMode
 *
 */
export default {
    ImageFormat,
    PNGCompression,
    PVRTexture,
    PVRTextureQuality,
    RipcutImageRequestMode,
    RipcutLabelConfigurationRequestMode
};
