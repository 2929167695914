/**
 *
 * @module paymentCardApi
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import {
    CardPaymentMethodInformation,
    CardPaymentMethodInformationTypedef,
    CreateCardPaymentMethodRequest,
    SubmitOrderWithCardPaymentMethodRequest,
    SubmitOrderWithCardPaymentMethodRequestTypedef
} from './typedefs';

import {
    CreatePaymentMethodRequiredInput,
    CreatePaymentMethodRequiredInputTypedef
} from '../../services/commerce/typedefs';

import CommerceClient from '../../services/commerce/commerceClient';
import BaseApi from '../../baseApi';
import DustUrnReference from '../../services/internal/dust/dustUrnReference';
import DustDecorators from '../../services/internal/dust/dustDecorators';

import { ApiOptions } from '../../typedefs';

import type Logger from '../../logging/logger';
import type AccessTokenProvider from '../../token/accessTokenProvider';

const DustUrn = DustUrnReference.commerce.paymentCardApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @desc Provides ability to access payment card data.
 *
 */
export default class PaymentCardApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {CommerceClient}
     *
     */
    private commerceClient: CommerceClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {CommerceClient} options.commerceClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {Logger} options.logger
     *
     */
    public constructor(options: {
        commerceClient: CommerceClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    commerceClient: Types.instanceStrict(CommerceClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { commerceClient } = options;

        this.commerceClient = commerceClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.PaymentCard.SubmitOrderWithCardPaymentMethodRequest>} request - A description of products to be
     * ordered and the card payment method necessary to process the order.
     * @param {String} [deviceProfile] - A `String` taken from `RequestConnectedDeviceDetailsResult.deviceProfile` if this
     * purchase was made as part of a LicensePlate flow. Otherwise null should be provided.
     * @desc Submit an order for purchasing using a previously stored card payment method. If the order is
     * successfully placed, a unique identifier for the order is returned.
     * @throws {InvalidDataException} Unable to submit the order due to invalid payment information.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @note the template value `{deviceProfile}` for the optional header `X-BAMTech-Sales-Platform` should be replaced
     * with the deviceProfile argument. If `deviceProfile` argument is null SDKs should send the same `deviceProfile`
     * value used when requesting a DeviceGrant.
     * @returns {Promise<String>} A promise that completes when the operation has succeeded and returns a unique identifier for the order.
     *
     */
    public async submitOrderWithCardPaymentMethod(
        request: SubmitOrderWithCardPaymentMethodRequest,
        deviceProfile?: string
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            request: Types.object(
                SubmitOrderWithCardPaymentMethodRequestTypedef
            ),
            deviceProfile: Types.nonEmptyString.optional
        }
    })
    public async submitOrderWithCardPaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [request, deviceProfile]
        } = apiOptions as ApiOptions<
            [SubmitOrderWithCardPaymentMethodRequest, string?]
        >;

        const response = await this.commerceClient.submitOrderWithPaymentMethod(
            {
                request,
                deviceProfile,
                accessToken: super.accessToken,
                logTransaction
            }
        );

        return response.guid;
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object<SDK.Commerce.PaymentCard.CardPaymentMethodInformation>} paymentInformation - info about the payment method to create.
     * @param {Object<SDK.Services.Commerce.CreatePaymentMethodRequiredInput>} createPaymentMethodRequired - Information retrieved from a call to `SDK.Commerce.CommerceApi.PriceOrder()`.
     * @desc Creates a new payment method which case be used later to submit an order.
     * @throws {InvalidPaymentInformationException} Unable to create payment information due to invalid data.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<String>} The payment method ID for the payment that was added.
     *
     */
    public async createCardPaymentMethod(
        paymentInformation: CardPaymentMethodInformation,
        createPaymentMethodRequired: CreatePaymentMethodRequiredInput
    ): Promise<string>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            paymentInformation: Types.object(
                CardPaymentMethodInformationTypedef
            ),
            createPaymentMethodRequired: Types.object(
                CreatePaymentMethodRequiredInputTypedef
            )
        }
    })
    public async createCardPaymentMethod(apiOptions: unknown) {
        const {
            logTransaction,
            args: [paymentInformation, createPaymentMethodRequired]
        } = apiOptions as ApiOptions<
            [CardPaymentMethodInformation, CreatePaymentMethodRequiredInput]
        >;

        const { creditCardNumber, ...passthroughData } = paymentInformation;
        const { region } = createPaymentMethodRequired;

        passthroughData.usage = passthroughData.isReusable
            ? 'multi_use'
            : 'single_use';

        const namespaceId = this.commerceClient.getNamespaceID();

        const request = {
            creditCardNumber,
            namespaceId,
            passthroughData
        } as CreateCardPaymentMethodRequest;

        const { paymentMethodId } =
            await this.commerceClient.createCardPaymentMethod({
                request,
                region,
                accessToken: super.accessToken,
                logTransaction
            });

        return paymentMethodId;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Commerce.PaymentCard.PaymentCardApi';
    }
}
