/**
 *
 * @module campaign
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @since 3.9.0
 * @desc Provides campaign and voucher codes for a given sku. This will only be populated for direct billing skus.
 *
 */
export default class Campaign {
    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String}
     * @desc Campaign code associated with a sku.
     *
     */
    public campaignCode: string;

    /**
     *
     * @access public
     * @since 3.9.0
     * @type {String}
     * @desc Voucher code associated with a sku.
     *
     */
    public voucherCode: string;

    /**
     *
     * @param {String} campaignCode
     * @param {String} voucherCode
     *
     */
    public constructor(campaignCode: string, voucherCode: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                campaignCode: Types.nonEmptyString,
                voucherCode: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.campaignCode = campaignCode;

        this.voucherCode = voucherCode;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Paywall.Campaign';
    }
}
