/**
 *
 * @module isValidURL
 *
 */

/**
 *
 * @param {String} url
 * @desc Returns true if `url` is a valid URL.
 * @returns {Boolean}
 *
 */
export default (url: string) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', // validate fragment locator
        'i'
    );

    return !!urlPattern.test(url);
};
