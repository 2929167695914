/**
 *
 * @module entry
 * @desc {SDK.Services.Orchestration.Internal} entry point.
 *
 */

import ExchangeDeviceGrantForAccessTokenRequest from './exchangeDeviceGrantForAccessTokenRequest';
import OrchestrationRequest from './orchestrationRequest';
import RefreshTokenRequest from './refreshTokenRequest';
import RegisterDeviceRequest from './registerDeviceRequest';
import UpdateDeviceOperatingSystemRequest from './updateDeviceOperatingSystemRequest';

/**
 *
 * @typedef {Object} SDK.Services.Orchestration.Internal
 *
 * @property {SDK.Services.Orchestration.Internal.ExchangeDeviceGrantForAccessTokenRequest} ExchangeDeviceGrantForAccessTokenRequest
 * @property {SDK.Services.Orchestration.Internal.OrchestrationRequest} OrchestrationRequest
 * @property {SDK.Services.Orchestration.Internal.RefreshTokenRequest} RefreshTokenRequest
 * @property {SDK.Services.Orchestration.Internal.RegisterDeviceRequest} RegisterDeviceRequest
 * @property {SDK.Services.Orchestration.Internal.UpdateDeviceOperatingSystemRequest} UpdateDeviceOperatingSystemRequest
 *
 */
export default {
    ExchangeDeviceGrantForAccessTokenRequest,
    OrchestrationRequest,
    RefreshTokenRequest,
    RegisterDeviceRequest,
    UpdateDeviceOperatingSystemRequest
};
