/**
 *
 * @module entry
 * @desc {SDK.Services.Internal.Dust} entry point.
 *
 */

import DustApplicationPayload from './dustApplicationPayload';
import DustCategory from './dustCategory';
import DustClientPayload from './dustClientPayload';
import DustDecorators from './dustDecorators';
import DustDevicePayload from './dustDevicePayload';
import DustLogUtility from './dustLogUtility';
import DustPayload from './dustPayload';
import DustSdkPayload from './dustSdkPayload';
import DustServerPayload from './dustServerPayload';
import DustUrnReference from './dustUrnReference';

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Internal.Dust
 *
 * @property {DustApplicationPayload} DustApplicationPayload
 * @property {SDK.Services.Internal.Dust.DustCategory} DustCategory
 * @property {DustClientPayload} DustClientPayload
 * @property {DustDecorators} DustDecorators
 * @property {DustDevicePayload} DustDevicePayload
 * @property {DustLogUtility} DustLogUtility
 * @property {DustPayload} DustPayload
 * @property {DustSdkPayload} DustSdkPayload
 * @property {DustServerPayload} DustServerPayload
 * @property {SDK.Services.Internal.Dust.DustUrnReference} DustUrnReference
 *
 */
export default {
    DustApplicationPayload,
    DustCategory,
    DustClientPayload,
    DustDecorators,
    DustDevicePayload,
    DustLogUtility,
    DustPayload,
    DustSdkPayload,
    DustServerPayload,
    DustUrnReference
};
