/**
 *
 * @module explorePlugin
 * @desc {SDK.Explore.ExplorePlugin} entry point.
 *
 */

import Explore from './entry';
import ExploreApi from './exploreApi';
import ExploreService from '../services/explore/entry';
import ExploreClient from '../services/explore/exploreClient';
import ExploreClientConfiguration from '../services/explore/exploreClientConfiguration';

import type { IPlugin } from '../IPlugin';

/**
 *
 * @since 23.1.0
 *
 */
const plugin: IPlugin<'Explore', typeof Explore, typeof ExploreService> = {
    pluginName: 'Explore',
    entry: Explore,
    service: ExploreService,

    /**
     *
     * @access protected
     * @since 23.1.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, sourceUrn, clients } = options;
        const { accessTokenProvider, managers } = sdkSession;

        sdkSession.exploreApi = new ExploreApi({
            exploreClient: new ExploreClient({
                config: sdkSession.config.services.explore,
                logger,
                httpClient: clients.httpClient
            }),
            sessionManager: managers.sessionManager,
            accessTokenProvider,
            source: sourceUrn,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 23.1.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        const { baseUrl, endpoints, extras } = services?.explore?.client;

        serviceDefinitionsConfiguration.explore =
            new ExploreClientConfiguration({
                baseUrl,
                endpoints:
                    serviceDefinitionsConfiguration.processEndpoints(endpoints),
                extras
            });
    }
};

/**
 *
 * @access public
 * @since 23.1.0
 *
 */
export default plugin;
