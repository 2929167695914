/**
 *
 * @module mediaFetchErrorMapping
 *
 */

import { MediaFetchError, QoePlaybackError } from '../qualityOfService/enums';

/**
 *
 * @typedef {Object} SDK.Services.Media.MediaFetchErrorMapping
 * @access private
 * @since 9.0.0
 * @desc Maps SDK Error Cases to MediaFetchError enum for QoS.
 *
 */
export const MediaFetchErrorMapping = {
    AccountConsentTokenCreationFailedException:
        MediaFetchError.gdprConsentConflict,
    AgeNotVerifiedException: MediaFetchError.ageNotVerified,
    AgeNotVerifiedKrException: MediaFetchError.ageNotVerifiedKr,
    AuthenticationExpiredException: MediaFetchError.authenticationExpired,
    AuthorizationExpiredException: MediaFetchError.authorizationExpired,
    BlackoutException: MediaFetchError.blackout,
    StreamConcurrencyException: MediaFetchError.concurrency,
    MediaUnavailableException: MediaFetchError.contentNotAvailable,
    LocationAcquisitionException: MediaFetchError.locationAcquisitionError,
    LocationNotAllowedException: MediaFetchError.locationNotAllowed,
    LoginRequiredException: MediaFetchError.loginRequired,
    MediaNotAllowedException: MediaFetchError.mediaNotAllowed,
    NetworkConnectionException: MediaFetchError.networkFailure,
    NotEntitledException: MediaFetchError.notEntitled,
    ParentalControlsRestrictedException:
        MediaFetchError.parentalControlsRestricted,
    ProfilePinExpiredException: MediaFetchError.pinExpired,
    ProfileMissingException: MediaFetchError.profileMissing,
    ProfilePinMissingException: MediaFetchError.profilePinMissing,
    RequestBlockedException: MediaFetchError.requestBlocked,
    RequestTemporarilyBlockedException:
        MediaFetchError.requestTemporarilyBlocked,
    TemporarilyThrottledException: MediaFetchError.temporarilyThrottled,
    TemporarilyUnavailableException: MediaFetchError.temporarilyUnavailable,
    UnexpectedErrorException: MediaFetchError.unexpectedError,
    UpgradeRequiredException: MediaFetchError.upgradeRequired,
    default: MediaFetchError.unknown
};

/**
 *
 * @typedef {Object} SDK.Services.Media.QoePlaybackErrorMapping
 * @access private
 * @since 27.0.0
 * @desc Maps SDK Error Cases to QoePlaybackError enum for QoS.
 *
 */
export const QoePlaybackErrorMapping = {
    AccountConsentTokenCreationFailedException:
        QoePlaybackError.gdprConsentConflict,
    AgeNotVerifiedException: QoePlaybackError.ageNotVerified,
    AuthenticationExpiredException: QoePlaybackError.authenticationExpired,
    AuthorizationExpiredException: QoePlaybackError.authorizationExpired,
    BlackoutException: QoePlaybackError.blackout,
    StreamConcurrencyException: QoePlaybackError.concurrency,
    MediaUnavailableException: QoePlaybackError.contentNotAvailable,
    LocationAcquisitionException: QoePlaybackError.locationAcquisitionError,
    LocationNotAllowedException: QoePlaybackError.locationNotAllowed,
    LoginRequiredException: QoePlaybackError.loginRequired,
    MediaNotAllowedException: QoePlaybackError.mediaNotAllowed,
    NetworkConnectionException: QoePlaybackError.networkFailure,
    NotEntitledException: QoePlaybackError.notEntitled,
    ParentalControlsRestrictedException:
        QoePlaybackError.parentalControlsRestricted,
    ProfilePinExpiredException: QoePlaybackError.pinExpired,
    ProfileMissingException: QoePlaybackError.profileMissing,
    ProfilePinMissingException: QoePlaybackError.profilePinMissing,
    RequestBlockedException: QoePlaybackError.requestBlocked,
    RequestTemporarilyBlockedException:
        QoePlaybackError.requestTemporarilyBlocked,
    TemporarilyThrottledException: QoePlaybackError.temporarilyThrottled,
    TemporarilyUnavailableException: QoePlaybackError.temporarilyUnavailable,
    UnexpectedErrorException: QoePlaybackError.unexpectedError,
    UpgradeRequiredException: QoePlaybackError.upgradeRequired,
    default: QoePlaybackError.unknown
};
