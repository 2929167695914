/**
 *
 * @module logLevel
 *
 */

/**
 *
 * @enum
 * @typedef {Object} LogLevel
 * @property {0} none
 * @property {1} trace
 * @property {2} debug
 * @property {3} info
 * @property {4} warn
 * @property {5} error
 * @property {6} diagnostics
 * @property {"none"} 0
 * @property {"trace"} 1
 * @property {"debug"} 2
 * @property {"info"} 3
 * @property {"warn"} 4
 * @property {"error"} 5
 * @property {"diagnostics"} 6
 *
 * @desc The enumerable keys of LogLevel are strings like `none`, `trace`, `warn`, `error`, `diagnostics`, the values of which are integers.
 * The use of integers instead of strings for this enumeration allow comparison filters and ordering of severity.
 * There are non-enumerable numeric keys pointing back to the level name, see example:
 *
 * @example
 * LogLevel[0] === 'none'; // hidden in prototype
 * LogLevel.none === 0; // own property
 *
 */

enum LogLevel {
    none,
    trace,
    debug,
    info,
    warn,
    error,
    diagnostics
}

export default LogLevel;
