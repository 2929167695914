/**
 *
 * @module entry
 * @desc {SDK.Services.CustomerService} entry point.
 *
 */

import CustomerServiceClient from './customerServiceClient';

/**
 *
 * @typedef {Object} SDK.Services.CustomerService
 *
 * @property {CustomerServiceClient} CustomerServiceClient
 *
 */
export default {
    CustomerServiceClient
};
