/**
 *
 * @module paywallApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/paywall.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';

import Paywall from '../services/paywall/paywall';
import PaywallClient from '../services/paywall/paywallClient';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import PurchaseContext from '../services/paywall/purchaseContext';

import type AccessTokenProvider from '../token/accessTokenProvider';
import type Logger from '../logging/logger';

import { ApiOptions } from '../typedefs';

const DustUrn = DustUrnReference.paywall.paywallApi;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 3.9.0
 * @desc Provides available product information and account context.
 *
 */
export default class PaywallApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {PaywallClient}
     *
     */
    private paywallClient: PaywallClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {PaywallClient} options.paywallClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        paywallClient: PaywallClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    paywallClient: Types.instanceStrict(PaywallClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { paywallClient } = options;

        this.paywallClient = paywallClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 3.9.0
     * @param {String} [purchaseContextId] - A context ID used by paywall to lookup the correct SKU.
     * @note purchaseContextId is required when purchaseContext is also passed in.
     * @param {String<SDK.Services.Paywall.PurchaseContext>} [purchaseContext] - Purchase context to identify the page this request originated from.
     * @note PurchaseContext is a flexible enum.
     * @desc Returns a paywall object containing sku, campaign and account context information for a given country, platform and account/device.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<SDK.Services.Paywall.Paywall>}
     *
     */
    public async getPaywall(
        purchaseContextId?: string,
        purchaseContext?: OpenEnumOf<typeof PurchaseContext>
    ): Promise<Paywall>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            purchaseContextId: Types.nonEmptyString.optional,
            purchaseContext: Types.skip
        }
    })
    public async getPaywall(apiOptions?: unknown) {
        const {
            logTransaction,
            args: [purchaseContextId, purchaseContext]
        } = apiOptions as ApiOptions<
            [string?, OpenEnumOf<typeof PurchaseContext>?]
        >;

        return this.paywallClient.getPaywall(
            super.accessToken,
            logTransaction,
            purchaseContextId,
            purchaseContext as PurchaseContext
        );
    }

    /**
     *
     * @access public
     *
     */
    public override toString() {
        return 'SDK.Paywall.PaywallApi';
    }
}
