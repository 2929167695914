/**
 *
 * @module stringErrorHandler
 *
 */

import ErrorReason from '../../../exception/errorReason';
import { IErrorHandler } from './IErrorHandler';

/**
 *
 * @access protected
 * @since 24.0.0
 * @desc Handles legacy errors, where the error is returned as a `String`.
 * @returns {Object}
 *
 */
const StringErrorHandler: IErrorHandler = {
    is: (options) => {
        const { data, isError } = options;
        const { error } = data || {};

        if (isError && typeof error === 'string') {
            return true;
        }

        return false;
    },
    process: (options) => {
        const { error, error_description: errorDescription } =
            options.data || {};

        return {
            errorReasons: [
                new ErrorReason({
                    code: error as string,
                    description: errorDescription
                })
            ]
        };
    }
};

export default StringErrorHandler;
