/**
 *
 * @module forwardAllMessagesProcessor
 *
 */

import MessageProcessorBase from './messageProcessorBase';
import type SocketManager from '../socketManager';
import { RawSocketMessage } from '../typedefs';

/**
 *
 * @access private
 * @since 4.9.0
 * @desc Handles piping socket messages out so app devs can consume the message
 *
 */
export default class ForwardAllMessagesProcessor extends MessageProcessorBase {
    /**
     *
     * @access private
     * @since 4.9.0
     * @type {SDK.Socket.SocketManager}
     *
     */
    private socketManager: SocketManager;

    /**
     *
     * @param {SDK.Socket.SocketManager} socketManager
     *
     */
    public constructor(socketManager: SocketManager) {
        // @ts-expect-error TODO: base class expects a message - but what about "all" message cases? this works but not type-safe or readable
        super();

        this.socketManager = socketManager;
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @desc override the base to allow all messages to be processed by this class
     *
     */
    public override is(/* messageData */) {
        return true;
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {Object} messageData
     * @desc Raises each message out of the SDK through the `SocketManager` `EventEmitter`
     *
     */
    public process(messageData: RawSocketMessage) {
        this.socketManager.emit(messageData.type, messageData);
    }

    /**
     *
     * @access private
     * @since 4.18.0
     * @desc Returns the fully qualified name of this instance
     * @returns {String}
     *
     */
    public override toString() {
        return 'SDK.Socket.Messages.ForwardAllMessagesProcessor';
    }
}
