/**
 *
 * @module cafPlayerAdapter
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://developers.google.com/cast/docs/caf_receiver_overview
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.PlayerManager
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.PlaybackConfig
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.messages.LoadRequestData
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.messages.MediaInformation
 * @see https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.events#.EventType
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import PlayerAdapter from '../playerAdapter';
import PlaybackMetrics from '../playbackMetrics';
import PlaybackEventListener from '../playbackEventListener';
import Playlist from '../playlist';
import DssHlsPlayerAdapter from './dssHlsPlayerAdapter';
import MelHivePlayerAdapter from './melHivePlayerAdapter';

import DrmProvider from '../../drm/drmProvider';

import {
    PlaybackExitedCause,
    PlaybackMode,
    PlaybackSeekCause,
    SeekDirection
} from '../../services/qualityOfService/enums';

import adEngineRegex from '../../services/util/adEngineRegex';

import { createInvalidStateException } from '../../services/util/errorHandling/createException';

import {
    MultivariantPlaylistFetchedEvent,
    VariantPlaylistFetchedEvent
} from '../typedefs';

import { DrmType } from '../../services/media/enums';
import EventListenerProvider from '../eventListenerProvider';

type PlaybackConfig = {
    disableSourceBufferTimeAdjust: boolean;
    segmentRequestHandler: Noop;
    licenseUrl?: string;
    protectionSystem: unknown;
    licenseRequestHandler: Noop;
};

const EventType = {
    BUFFERING: 'BUFFERING',
    PLAY: 'PLAY',
    PLAYING: 'PLAYING',
    PAUSE: 'PAUSE',
    BITRATE_CHANGED: 'BITRATE_CHANGED',
    SEEKED: 'SEEKED',
    SEEKING: 'SEEKING',
    PLAYER_LOAD_COMPLETE: 'PLAYER_LOAD_COMPLETE'
};

/**
 *
 * @since 3.1.0
 * @desc Chromecast Application Framework (CAF) PlayerAdapter.
 *
 */
export default class CafPlayerAdapter extends PlayerAdapter<CafNativePlayer> {
    /**
     *
     * @access private
     * @type {cast.framework|null}
     * @desc Internal reference to the cast framework `Object`. Allows property
     * reference instead of checking against a cast global, which in turn makes this
     * `PlayerAdapter` easily testable.
     *
     */
    private framework: Nullable<{
        events: {
            EventType: typeof EventType;
            EndedReason: Record<string, string>;
        };
        messages?: {
            HlsSegmentFormat?: {
                FMP4: unknown;
                TS_AAC: unknown;
            };
            HlsVideoSegmentFormat?: {
                FMP4: unknown;
                MPEG2_TS: unknown;
            };
        };
        ContentProtection?: Record<DrmType, unknown>;
        PlaybackConfig: Class;
    }>;

    /**
     *
     * @access private
     * @type {Number|null}
     * @desc The current bit rate in kbit.
     * @note we must do this, make sure that the value gets updated "onEnded" "onPause" etc
     *
     */
    private currentBitrate: Nullable<number>;

    /**
     *
     * @access private
     * @since 4.15.0
     * @type {Number|null}
     * @desc The current bitrate average rate in kbit.
     *
     */
    private currentBitrateAvg: Nullable<number>;

    /**
     *
     * @access private
     * @since 4.15.0
     * @type {Number|null}
     * @desc The current bitrate peak rate in kbit.
     *
     */
    private currentBitratePeak: Nullable<number>;

    /**
     *
     * @access private
     * @since 4.3.0
     * @type {Boolean}
     * @desc Overrides the `disableSourceBufferTimeAdjust` property in `cast.framework.PlaybackConfig`.
     *
     */
    private disableSourceBufferTimeAdjust: boolean;

    /**
     *
     * @access private
     * @type {dds-hls|mel-hive|undefined}
     * @since 4.5.0
     * @desc instance of the dss-hls player or mel hive player.
     *
     */
    private streamer?: unknown;

    /**
     *
     * @access public
     * @since 3.2.0
     * @type {cast.framework.messages.HlsSegmentFormat}
     *
     */
    private audioSegmentFormat: unknown;

    /**
     *
     * @access public
     * @since 3.2.0
     * @type {cast.framework.messages.HlsVideoSegmentFormat}
     *
     */
    public videoSegmentFormat: unknown;

    /**
     *
     * @access public
     * @type {Array<SDK.Drm.DrmProvider>}
     * @since 4.5.0
     * @desc Set of DRM providers
     *
     */
    public drmProviders: Array<DrmProvider>;

    /**
     *
     * @access private
     * @type {SDK.Media.PlayerAdapter.DssHlsPlayerAdapter|SDK.Media.PlayerAdapter.MelHivePlayerAdapter|null}
     * @since 4.7.0
     * @desc instance of the DssHlsPlayerAdapter or MelHivePlayerAdapter.
     *
     */
    public streamerAdapter: Nullable<
        DssHlsPlayerAdapter | MelHivePlayerAdapter
    >;

    /**
     *
     * @param {Object} options
     * @param {cast.framework.PlayerManager} options.nativePlayer
     * @param {cast.framework} options.framework
     * @param {dss-hls} [options.streamer]
     * @param {Boolean} [options.disableSourceBufferTimeAdjust=false]
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} [options.initialPlaybackMode]
     *
     */
    public constructor(options: {
        nativePlayer: CafNativePlayer;
        framework: CafPlayerAdapter['framework'];
        streamer?: MelHiveNativePlayer;
        disableSourceBufferTimeAdjust?: boolean;
        videoPlayerName: string;
        videoPlayerVersion: string;
        initialPlaybackMode?: PlaybackMode;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    framework: Types.object(),
                    nativePlayer: Types.object(),
                    streamer: Types.object({
                        on: Types.function
                    }).optional,
                    disableSourceBufferTimeAdjust: Types.boolean.optional,
                    initialPlaybackMode: Types.in(PlaybackMode).optional
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            framework,
            streamer,
            disableSourceBufferTimeAdjust = false,
            videoPlayerName,
            videoPlayerVersion,
            initialPlaybackMode
        } = options;

        this.framework = framework;
        this.currentBitrate = null;
        this.currentBitrateAvg = null;
        this.currentBitratePeak = null;
        this.disableSourceBufferTimeAdjust = disableSourceBufferTimeAdjust;
        this.streamer = streamer;
        this.audioSegmentFormat = undefined;
        this.videoSegmentFormat = null;
        this.drmProviders = [];
        this.streamerAdapter = this.createStreamerAdapter(
            streamer as MelHiveNativePlayer,
            videoPlayerName,
            videoPlayerVersion,
            initialPlaybackMode
        );
        this.offHandlerRemovalList = [];
    }

    /**
     *
     * @access public
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @throws {InvalidStateException} Unable to set playlistUri on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    public override async setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        try {
            if (Check.assigned(this.streamerAdapter)) {
                return await this.streamerAdapter?.setSource(playlist);
            }

            this.playlistUri = playlist.streamUri;

            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            // undocumented Google MPL flag
            playbackConfig.disableSourceBufferTimeAdjust =
                this.disableSourceBufferTimeAdjust;

            // set current player config
            this.nativePlayer?.setPlaybackConfig(playbackConfig);

            // override the mediaUrl / contentId in the LOAD request
            this.nativePlayer?.setMediaUrlResolver(() => playlist.streamUri);
        } catch (ex) {
            throw createInvalidStateException(
                `${this.toString()}.setSource(playlist) unable to set playlistUri on NativePlayer - ${ex}`
            );
        }

        return undefined;
    }

    /**
     *
     * @access public
     * @param {Array<String>} audioSegmentTypes
     * @param {Array<String>} videoSegmentTypes
     * @since 3.2.0
     * @returns {Promise<Void>}
     *
     */
    public async updateSegmentFormat(
        audioSegmentTypes: Array<string>,
        videoSegmentTypes: Array<string>
    ) {
        this.updateAudioSegmentFormat(audioSegmentTypes);
        this.updateVideoSegmentFormat(videoSegmentTypes);
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener, nativePlayer } = this;

        super.clean();

        // clear the override the mediaUrl / contentId in the LOAD request
        nativePlayer?.setMediaUrlResolver();

        this.playlistUri = '';

        this.currentBitrate = null;
        this.currentBitrateAvg = null;
        this.currentBitratePeak = null;

        this.drmProvider = null;
        this.drmProviders = [];
        this.audioSegmentFormat = undefined;
        this.videoSegmentFormat = undefined;

        if (Check.assigned(this.streamerAdapter)) {
            this.streamerAdapter?.clean();
        }

        this.removeListener(listener);
    }

    /**
     *
     * @access public
     * @since 3.1.0
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.nativePlayer = null;
        this.accessToken = null;
        this.framework = null;
        this.streamerAdapter = null;
        this.streamer = null;
    }

    /**
     *
     * @access public
     * @since 3.6.0
     * @param {Object} adEngineData
     * @desc assigns adEngine data from the playlist service, some of which should be included in segment requests
     * @note if adEngineData is unassigned do not do anything
     *
     */
    public override setAdEngineData(
        adEngineData: PlayerAdapter['adEngineData']
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                adEngineData: Types.object()
            };

            typecheck(this, 'setAdEngineData', params, arguments);
        }

        this.adEngineData = adEngineData;

        if (Check.nonEmptyObject(adEngineData)) {
            const { nativePlayer } = this;
            const { ssess } = adEngineData;

            const playbackConfig = this.getPlaybackConfig();

            playbackConfig.segmentRequestHandler = (requestInfo: {
                url: string;
                headers: Nullable<Record<string, string>>;
                crossDomain?: boolean;
                withCredentials: boolean;
            }) => {
                const isAdEngineCall = adEngineRegex(requestInfo.url);

                // need to reset these for each segment request
                requestInfo.headers = null;
                requestInfo.crossDomain = undefined;
                requestInfo.withCredentials = false;

                if (ssess && isAdEngineCall) {
                    requestInfo.headers = {};
                    requestInfo.headers.ssess = ssess;
                    requestInfo.crossDomain = true;
                    requestInfo.withCredentials = true;
                }
            };

            nativePlayer?.setPlaybackConfig(playbackConfig);
        }
    }

    /**
     *
     * @access public
     * @since 15.2.0
     * @param {Object} data
     * @param {Number} data.seekSize - Indication that the user is seeking by a fixed time (size) e.g. +30, -30. This value is expected to be in seconds.
     * @param {String<SDK.Services.QualityOfService.SeekDirection>} [data.seekDirection] - used for Live events
     * @param {String<SDK.Services.QualityOfService.PlaybackSeekCause>} data.seekCause
     * @desc Sets the seekData property, for the streamer adapter, provided by the application.
     *
     */
    public override setSeekData(data: {
        seekSize: number;
        seekDirection?: SeekDirection;
        seekCause: PlaybackSeekCause;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                data: Types.object({
                    seekSize: Types.number,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekCause: Types.in(PlaybackSeekCause)
                })
            };

            typecheck.warn(this, 'setSeekData', params, arguments);
        }

        if (this.streamerAdapter) {
            this.streamerAdapter.setSeekData(data);
        } else {
            this.logger.warn(
                this.toString(),
                'Streamer is not set. Seek data will not be set.'
            );
        }
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the time when the player has started to be initialized.
     *
     */
    public override setInitializePlayerStartTime() {
        if (this.streamerAdapter) {
            this.streamerAdapter.setInitializePlayerStartTime();
        } else {
            this.logger.warn(
                this.toString(),
                'Streamer is not set. InitializePlayerStartTime will not be set.'
            );
        }
    }

    /**
     *
     * @access public
     * @since 28.1.0
     * @desc Sets the duration for how long the player took to be initialized. `setInitializePlayerStartTime` must be
     * called before this method.
     *
     */
    public override setInitializePlayerDuration() {
        if (this.streamerAdapter) {
            this.streamerAdapter.setInitializePlayerDuration();
        } else {
            this.logger.warn(
                this.toString(),
                'Streamer is not set. InitializePlayerDuration will not be set.'
            );
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {InvalidStateException} Unable to add PlaybackEventListener
     * @returns {Void}
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        try {
            const {
                framework,
                streamerAdapter,
                nativePlayer,
                playbackReadyEvent,
                playbackStartedEvent,
                playbackInitializedEvent,
                rebufferingEvent,
                playbackSeekedEvent,
                bitrateChangedEvent,
                playbackPausedEvent
            } = this;

            if (!framework) {
                return;
            }

            const {
                BUFFERING,
                PLAY,
                PLAYING,
                PAUSE,
                BITRATE_CHANGED,
                SEEKED,
                SEEKING,
                PLAYER_LOAD_COMPLETE
            } = framework.events.EventType;

            if (streamerAdapter) {
                streamerAdapter.addListener(listener);

                return;
            }

            if (nativePlayer && nativePlayer.addEventListener) {
                this.listener = listener;

                this.eventListenerProvider = new EventListenerProvider({
                    onHandler: nativePlayer.addEventListener,
                    offHandler: nativePlayer.removeEventListener,
                    logger: this.logger
                });

                this.eventListenerProvider.addEventHandler(
                    this,
                    PLAY,
                    playbackReadyEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    PLAYING,
                    playbackStartedEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    BUFFERING,
                    rebufferingEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    SEEKED,
                    playbackSeekedEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    SEEKING,
                    playbackSeekedEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    BITRATE_CHANGED,
                    bitrateChangedEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    PAUSE,
                    playbackPausedEvent
                );

                this.eventListenerProvider.addEventHandler(
                    this,
                    PLAYER_LOAD_COMPLETE,
                    playbackInitializedEvent
                );
            }
        } catch (ex) {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access protected
     * @param {SDK.Media.PlaybackEventListener} [listener]
     * @throws {InvalidStateException} Unable to remove PlaybackEventListener
     * @returns {Void}
     *
     */
    public override removeListener(listener?: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener).optional
            };

            typecheck(this, 'removeListener', params, arguments);
        }

        if (!listener) {
            return;
        }

        try {
            const { framework, streamerAdapter } = this;

            if (!framework) {
                return;
            }

            if (Check.assigned(streamerAdapter)) {
                streamerAdapter?.removeListener(listener);
            } else {
                super.removeListener(listener);
            }
        } catch (ex) {
            throw createInvalidStateException(
                `${this.toString()}.removeListener(listener) unable to remove PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access protected
     * @since 3.2.0
     * @param {SDK.Drm.DrmProvider} drmProvider
     * @returns {Promise<Void>}
     *
     */
    public override setDrmProvider(drmProvider: DrmProvider) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProvider: Types.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProvider', params, arguments);
        }

        this.drmProvider = drmProvider;

        return this.attach();
    }

    /**
     *
     * @access public
     * @since 4.5.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    public async setDrmProviders(drmProviders: Array<DrmProvider>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { streamerAdapter } = this;

        this.drmProviders = drmProviders;

        if (Check.assigned(streamerAdapter)) {
            return streamerAdapter?.setDrmProviders(drmProviders);
        }

        return this.setDrmProvider(drmProviders[0]);
    }

    /**
     *
     * @access public
     * @since 4.15.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     *
     */
    public override getPlaybackMetrics() {
        // bypass to use DssHls getPlaybackMetrics.
        if (Check.assigned(this.streamerAdapter)) {
            return this.streamerAdapter?.getPlaybackMetrics() as PlaybackMetrics;
        }

        return new PlaybackMetrics({
            currentBitrate: this.currentBitrate,
            currentPlayhead: this.nativePlayer?.getCurrentTimeSec(),
            currentBitrateAvg: this.currentBitrateAvg,
            currentBitratePeak: this.currentBitratePeak
        });
    }

    /**
     *
     * @access public
     * @since 27.0.0
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} playbackMode
     * @desc Sets Playback Mode.
     *
     */
    public override setPlaybackMode(playbackMode: PlaybackMode) {
        this.streamerAdapter?.setPlaybackMode(playbackMode);
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @returns {Promise<Void>}
     *
     */
    private attach() {
        const { drmProvider, framework } = this;

        if (drmProvider?.type === DrmType.WIDEVINE) {
            return this.attachDrm(framework?.ContentProtection?.WIDEVINE);
        }

        if (drmProvider?.type === DrmType.PLAYREADY) {
            return this.attachDrm(framework?.ContentProtection?.PLAYREADY);
        }

        return this.attachSilkDrm();
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @param {cast.framework.ContentProtection} protectionSystem
     * @throws {InvalidStateException} Unable to set playback configuration on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    private attachDrm(protectionSystem: unknown) {
        const { nativePlayer, drmProvider } = this;

        try {
            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            playbackConfig.licenseUrl = drmProvider?.licenseRequestUri;
            playbackConfig.protectionSystem = protectionSystem;

            // override license request handler to set credentials
            playbackConfig.licenseRequestHandler = (requestInfo: {
                crossDomain: boolean;
                withCredentials: boolean;
                headers: Record<string, string | undefined>;
            }) => {
                requestInfo.crossDomain = true;
                requestInfo.withCredentials = true;
                requestInfo.headers =
                    drmProvider?.processLicenseRequestHeaders() || {};
            };

            // set current player config
            nativePlayer?.setPlaybackConfig(playbackConfig);

            return Promise.resolve();
        } catch (ex) {
            return Promise.reject(
                createInvalidStateException(
                    `${this.toString()}.attachDrm(protectionSystem) unable to set playback configuration on NativePlayer - ${ex}`
                )
            );
        }
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @throws {InvalidStateException} Unable to set playback configuration on NativePlayer
     * @returns {Promise<Void>}
     *
     */
    private attachSilkDrm() {
        const { nativePlayer, accessToken } = this;

        try {
            /**
             *
             * @type {cast.framework.PlaybackConfig}
             * @desc A copy of the current player config
             *
             */
            const playbackConfig = this.getPlaybackConfig();

            // override license request handler to set credentials
            playbackConfig.licenseRequestHandler = (requestInfo) => {
                requestInfo.withCredentials = true;
                requestInfo.headers = {};
                requestInfo.headers.Authorization = accessToken;
            };

            // set current player config
            nativePlayer?.setPlaybackConfig(playbackConfig);

            return Promise.resolve();
        } catch (ex) {
            return Promise.reject(
                createInvalidStateException(
                    `${this.toString()}.attachSilkDrm() unable to set playback configuration on NativePlayer - ${ex}`
                )
            );
        }
    }

    /**
     *
     * @access private
     * @param {Array<String>} audioSegmentTypes
     * @since 3.2.0
     * @returns {this}
     *
     */
    private updateAudioSegmentFormat(audioSegmentTypes: Array<string>) {
        const { framework } = this;

        this.audioSegmentFormat = undefined;

        if (Check.nonEmptyArray(audioSegmentTypes)) {
            if (audioSegmentTypes.includes('FMP4')) {
                this.audioSegmentFormat =
                    framework?.messages?.HlsSegmentFormat?.FMP4;

                return this;
            }

            if (audioSegmentTypes.includes('PACKED_AUDIO')) {
                this.audioSegmentFormat =
                    framework?.messages?.HlsSegmentFormat?.TS_AAC;

                return this;
            }
        }

        return this;
    }

    /**
     *
     * @access private
     * @param {Array<String>} videoSegmentTypes
     * @since 3.2.0
     * @returns {this}
     *
     */
    private updateVideoSegmentFormat(videoSegmentTypes: Array<string>) {
        const { framework } = this;

        this.videoSegmentFormat = undefined;

        if (Check.nonEmptyArray(videoSegmentTypes)) {
            if (videoSegmentTypes.includes('FMP4')) {
                this.videoSegmentFormat =
                    framework?.messages?.HlsVideoSegmentFormat?.FMP4;

                return this;
            }

            if (videoSegmentTypes.includes('TS')) {
                this.videoSegmentFormat =
                    framework?.messages?.HlsVideoSegmentFormat?.MPEG2_TS;

                return this;
            }
        }

        return this;
    }

    /**
     *
     * @access private
     * @since 3.2.0
     * @returns {cast.framework.PlaybackConfig}
     *
     */
    private getPlaybackConfig() {
        const { nativePlayer, framework } = this;

        return Object.assign(
            Check.function(nativePlayer?.getPlaybackConfig)
                ? nativePlayer?.getPlaybackConfig() || {}
                : {},
            framework ? new framework.PlaybackConfig() : {}
        ) as PlaybackConfig;
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when audio stream changes.
     *
     */
    private audioChangedEvent() {
        this.onAudioChanged();
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when the subtitle changes.
     *
     */
    private subtitleChangedEvent() {
        this.onSubtitleChanged();
    }

    /**
     *
     * @access private
     * @param {Object<SDK.Media.MultivariantPlaylistFetchedEvent>} eventData
     * @since 15.0.0
     *
     */
    private multivariantPlaylistFetchedEvent(
        eventData: MultivariantPlaylistFetchedEvent
    ) {
        this.onMultivariantPlaylistFetched(eventData);
    }

    /**
     *
     * @access private
     * @param {Object<SDK.Media.VariantPlaylistFetchedEvent>} eventData
     * @since 4.0.0
     *
     */
    private variantPlaylistFetchedEvent(
        eventData: VariantPlaylistFetchedEvent
    ) {
        this.onVariantPlaylistFetched(eventData);
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.BufferingEvent} eventData
     * @desc Trigger when buffering starts or ends.
     *
     */
    private rebufferingEvent(eventData: { isBuffering: boolean }) {
        const { isBuffering } = eventData || {};

        if (isBuffering) {
            this.onRebufferingStarted({});
        } else {
            this.onRebufferingEnded({});
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [options]
     * @param {cast.framework.events.MediaFinishedEvent} [options.eventData]
     * @param {Boolean} [options.isEnded]
     * @desc Trigger when playback has been exited.
     *
     */
    private async playbackEndedEvent(options: {
        eventData?: {
            endedReason: string;
            currentMediaTime: number;
            errorName?: string;
            errorDetail?: string;
        };
        isEnded?: boolean;
    }) {
        if (this.streamerAdapter) {
            this.streamerAdapter.playbackEndedEvent(options);
        } else {
            const { eventData } = options || {};
            const { framework } = this;
            const { END_OF_STREAM, STOPPED, ERROR } =
                framework?.events.EndedReason || ({} as Record<string, string>);
            const { endedReason, currentMediaTime = 0 } = eventData || {};

            let cause;

            switch (endedReason) {
                case END_OF_STREAM:
                    cause = PlaybackExitedCause.playedToEnd;
                    break;

                case STOPPED:
                    cause = PlaybackExitedCause.user;
                    break;

                case ERROR:
                    cause = PlaybackExitedCause.error;
                    break;

                // no default
            }

            const playheadPosition = currentMediaTime * 1000;

            await this.onPlaybackEnded({
                cause,
                playheadPosition
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.LoadEvent} eventData
     * @desc Trigger when playback has been initialized.
     *
     */
    private playbackInitializedEvent(eventData: {
        media: { startAbsoluteTime: number };
    }) {
        const { playlistUri: streamUrl } = this;
        const { media } = eventData || {};
        const { startAbsoluteTime } = media;

        let playheadPosition;

        if (Check.assigned(startAbsoluteTime)) {
            playheadPosition = startAbsoluteTime * 1000;
        }

        this.onPlaybackInitialized({
            streamUrl,
            playheadPosition
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.MediaElementEvent} eventData
     * @desc Trigger when playback seeks.
     *
     */
    private playbackSeekedEvent(eventData: {
        type?: string;
        currentMediaTime: number;
    }) {
        const { framework } = this;
        const { SEEKED, SEEKING } = framework?.events.EventType || {};
        const { type, currentMediaTime = 0 } = eventData || {};

        const playheadPosition = currentMediaTime * 1000;

        if (type === SEEKED) {
            this.onPlaybackSeekEnded({
                playheadPosition
            });
        }

        if (type === SEEKING) {
            this.onPlaybackSeekStarted({
                playheadPosition
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {cast.framework.events.BitrateChangedEvent} eventData
     * @desc Trigger when the bitrate changes.
     *
     */
    private bitrateChangedEvent(eventData: {
        bitrateAvg: number;
        bitratePeak: number;
        totalBitrate: number;
    }) {
        const { bitrateAvg, bitratePeak, totalBitrate = 0 } = eventData || {};

        try {
            // convert to Kbps
            this.currentBitrate = Math.round(totalBitrate / 1000);
        } catch (ex) {
            this.currentBitrate = null;
        }

        this.onBitrateChanged({
            bitrateAvg,
            bitratePeak,
            playheadPosition: this.nativePlayer?.getCurrentTimeSec()
        });
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback is ready.
     *
     */
    private playbackReadyEvent() {
        // noop
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback starts.
     *
     */
    private playbackStartedEvent() {
        this.onPlaybackStarted({});
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @desc Trigger when playback gets paused.
     *
     */
    private playbackPausedEvent() {
        this.onPlaybackPaused({
            playheadPosition: this.nativePlayer?.getCurrentTimeSec()
        });
    }

    /**
     *
     * @access private
     * @since 18.0.0
     * @param {dds-hls|mel-hive} streamer
     * @param {String} videoPlayerName
     * @param {String} videoPlayerVersion
     * @param {String<SDK.Services.QualityOfService.PlaybackMode>} [options.initialPlaybackMode]
     * @desc Creates a player adapter based on what streamer is provided if applicable.
     * @returns {PlayerAdapter|null}
     *
     */
    private createStreamerAdapter(
        streamer: MelHiveNativePlayer,
        videoPlayerName: string,
        videoPlayerVersion: string,
        initialPlaybackMode?: PlaybackMode
    ) {
        if (streamer) {
            if (
                Check.function(streamer.getClass) &&
                (streamer.getClass() as Record<string, string>).playerName ===
                    'MEL-HIVE'
            ) {
                this.logger.info(
                    this.toString(),
                    'Creating MelHivePlayerAdapter'
                );

                return new MelHivePlayerAdapter({
                    nativePlayer: streamer,
                    videoPlayerName,
                    videoPlayerVersion,
                    initialPlaybackMode
                });
            }

            this.logger.info(this.toString(), 'Creating DssHlsPlayerAdapter');

            return new DssHlsPlayerAdapter({
                nativePlayer: streamer,
                videoPlayerName,
                videoPlayerVersion
            });
        }

        return null;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.CafPlayerAdapter';
    }
}
