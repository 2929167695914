/**
 *
 * @module configurationClientHostParams
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc A model containing the ConfigurationClient's host params.
 *
 */
export default class ConfigurationClientHostParams {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public baseUrl: string;

    /**
     *
     * @param {String} baseUrl
     *
     */
    public constructor(baseUrl: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                baseUrl: Types.nonEmptyString
            };

            typecheck(this, params, arguments);
        }

        this.baseUrl = baseUrl;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.ConfigurationClientHostParams';
    }
}
