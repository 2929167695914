/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

import AgeVerificationFlowType from './ageVerificationFlowType';
import LogTransaction from './logging/logTransaction';
import DustLogUtility from './services/internal/dust/dustLogUtility';

/**
 *
 * @typedef {Object} SDK.AgeVerificationChangedEvent
 * @since 12.0.0
 * @property {String} deviceId - Device Id of the user who initiated age verification.
 * @property {String} accountId - Account Id of the user who initiated age verification.
 * @property {String} profileId - Profile Id of the user who initiated age verification.
 * @property {String} [errorCode] - Error Code for Age Verification Request or Redemption.
 * @property {String} timestamp
 * @property {SDK.AgeVerificationFlowType} flowType
 *
 */
export const AgeVerificationChangedEvent = {
    deviceId: Types.nonEmptyString,
    accountId: Types.nonEmptyString,
    profileId: Types.nonEmptyString,
    errorCode: Types.nonEmptyString.optional,
    timestamp: Types.nonEmptyString,
    flowType: Types.in(AgeVerificationFlowType)
};

export const enum NodeEnv {
    Development = 'development',
    Test = 'test',
    Production = 'production'
}

/**
 *
 * @typedef SDK.ApiOptions
 * @since 24.0.0
 * @property {LogTransaction} logTransaction
 * @property {Array<*>} args
 * @property {String} method
 * @property {DustLogUtility} [dustLogUtility]
 *
 */

export type ApiOptions<TArgs extends Array<ApprovedAny> = ApprovedAny> = {
    logTransaction: LogTransaction;
    args: [...TArgs];
    method: string;
    dustLogUtility?: DustLogUtility;
};
