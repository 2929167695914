/**
 *
 * @module entry
 * @desc {SDK.Services.Orchestration} entry point.
 *
 */

import OffDeviceRedemptionFlowServiceMap from './offDeviceRedemptionFlowServiceMap';
import Internal from './internal/entry';

/**
 *
 * @typedef {Object} SDK.Services.Orchestration
 *
 * @property {SDK.Services.Orchestration.OffDeviceRedemptionFlowServiceMap} OffDeviceRedemptionFlowServiceMap
 * @property {SDK.Services.Orchestration.Internal} Internal
 *
 */
export default {
    OffDeviceRedemptionFlowServiceMap,
    Internal
};
