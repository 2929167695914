/**
 *
 * @module bam-browser-sdk-configuration-util
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/_utils/bam-browser-sdk-configuration-util.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlatformGroupings.md
 *
 */

import ConfigurationHostName from '../services/configuration/configurationHostName';

export { default as ConfigurationHostName } from '../services/configuration/configurationHostName';

/**
 *
 * @access private
 * @since 23.0.0
 * @type {RegExp}
 * @desc A regular expression used to parse a version string.
 *
 */
export const versionRegExp = /^(\d+\.)?(\d+).(.*)/;

/**
 *
 * @access public
 * @since 23.0.0
 * @param {String} versionToParse
 * @returns {String}
 * @desc Gets the short version of a version string. EX: `1.2.3` => `1.2`.
 *
 */
export const getVersionShort = (versionToParse: string) =>
    versionToParse.replace(versionRegExp, '$1$2');

const baseConfigUrl = `bam-sdk-configs.bamgrid.com/bam-sdk`;

/**
 * @access private
 * @since 23.0.0
 * @type {Object}
 * @desc A collection of host parameters used to construct the `configPathnameTemplate` URL.
 * @note The `configPathnameTemplate` URL is used to construct the SDK configuration URL.
 *
 */
export const configHostParams = {
    dev: {
        baseUrl: `https://dev-${baseConfigUrl}`
    },
    release: {
        baseUrl: `https://${baseConfigUrl}`
    },
    staging: {
        baseUrl: `https://staging-${baseConfigUrl}`
    }
};

/**
 *
 * @access private
 * @since 23.0.0
 * @type {String}
 * @desc A template used to construct the SDK configuration URL.
 *
 */
export const configPathnameTemplate = `
    {baseUrl}/
    v{configVersion}/
    {clientId}/{deviceFamily}/v{sdkVersion}/
    {deviceProfile}/{applicationRuntime}/{environment}.json
`.replace(/(?:\n?\s+)/g, '');

/**
 *
 * @access private
 * @since 23.0.0
 * @type {ConfigurationHostName}
 * @desc The default configuration host name to use (EX: "release").
 *
 */
export const defaultConfigHostName = ConfigurationHostName.release;

/**
 *
 * @access public
 * @since 23.0.0
 * @type {String}
 * @desc Gets the SDK configuration version used in the `configPathnameTemplate` `v{configVersion}` template location. This value is represented a a `MAJOR.MINOR` version string.
 *
 */
export const sdkConfigVersion = '5.0';

/**
 *
 * @param {Object} options
 * @param {String} options.deviceProfile - The device profile to use (EX: "google").
 * @param {String} options.applicationRuntime - The application runtime to use (EX: "chromecast").
 * @param {String} options.clientId - The client id to used to identify which client is booting up.
 * @param {String} options.sdkVersion - The full SDK version tied to the supported SDK version running in the application.
 * @param {String} [options.environment="prod"] - The environment to use (EX: "prod" | "qa" | "mock").
 * @param {String} [options.deviceFamily="browser"]
 * @param {String} [options.configVersion="5.0"] - The sdk configuration version to use expected to use.
 * @param {ConfigurationHostName} [options.configHostName=ConfigurationHostName.release] - The configuration host name to use (EX: ConfigurationHostName.release | ConfigurationHostName.dev | ConfigurationHostName.staging).
 * @param {String} [options.configHostOverride] - The configuration host override. By default we base this on configHostParams[environment].
 *
 * @desc Returns a SDK configuration url based on the provided options.
 * @note To keep this module simple, only checking required params.
 * @returns {String}
 *
 * @example
 * const url = createConfigurationUrl({
 *     deviceProfile: 'google',
 *     applicationRuntime: 'chromecast'
 * });
 *
 */
export function createConfigurationUrl(options: {
    deviceProfile: string;
    applicationRuntime: string;
    clientId: string;
    sdkVersion: string;
    environment?: 'prod' | 'qa' | 'mock';
    deviceFamily?:
        | 'android'
        | 'apple'
        | 'browser'
        | 'microsoft'
        | 'roku'
        | 'rust';
    configVersion?: string;
    configHostName?: ConfigurationHostName;
    configHostOverride?: string;
}) {
    const {
        deviceProfile,
        applicationRuntime,
        clientId,
        sdkVersion,
        environment = 'prod',
        deviceFamily = 'browser',
        configVersion = sdkConfigVersion,
        configHostName = defaultConfigHostName,
        configHostOverride
    } = options || {};

    const baseUrl =
        configHostOverride || configHostParams[configHostName].baseUrl;

    if (!deviceProfile) {
        throw new Error('Missing required deviceProfile value.');
    }

    if (!applicationRuntime) {
        throw new Error('Missing required applicationRuntime value.');
    }

    if (!sdkVersion) {
        throw new Error('Missing required sdkVersion value.');
    }

    const url = configPathnameTemplate
        .replace(/\{baseUrl\}/gi, baseUrl)
        .replace(/\{configVersion\}/gi, configVersion)
        .replace(/\{clientId\}/gi, clientId)
        .replace(/\{deviceFamily\}/gi, deviceFamily)
        .replace(/\{sdkVersion\}/gi, getVersionShort(sdkVersion as string))
        .replace(/\{applicationRuntime\}/gi, applicationRuntime)
        .replace(/\{deviceProfile\}/gi, deviceProfile)
        .replace(/\{environment\}/gi, environment);

    return url;
}
