/* eslint-disable @typescript-eslint/prefer-for-of */

/**
 *
 * @module device
 * @desc Detect and classify some device specific information via userAgent.
 * Detects browser, browser version, os, form-factor.
 *
 */

/* eslint-disable guard-for-in */

import { typecheck, Types } from '@dss/type-checking';
import RegExes from './device.regexs';

type DeviceDetails = { family: string; name: string };

type Is = {
    [key: string]: boolean;
    android: boolean;
    ios: boolean;
    macintosh: boolean;
    msie: boolean;
    windows: boolean;
    windowsphone: boolean;
};

const REGEXES_OTHER = RegExes.constants.OTHER;
const REGEXES_VERSION = RegExes.constants.VERSION;
const REGEXES_FORMFACTORS = RegExes.constants.FORMFACTORS;

export default class Device {
    /**
     *
     * @access private
     * @type {string}
     *
     */
    private userAgent: string;

    /**
     *
     * @access public
     * @type {object}
     *
     */
    public is: Is;

    /**
     *
     * @access public
     * @type {object}
     *
     */
    public details: DeviceDetails;

    /**
     *
     * @access public
     * @type {string|undefined}
     *
     */
    public platform?: string;

    /**
     *
     * @access public
     * @type {string}
     *
     */
    public platformVersion: string;

    /**
     *
     * @access public
     * @type {string}
     *
     */
    public browser: string;

    /**
     *
     * @access public
     * @type {string}
     *
     */
    public browserVersion: string;

    /**
     *
     * @access private
     * @type {string}
     *
     */
    private formFactor: string;

    /**
     *
     * @access public
     * @type {string|null}
     * @desc The browser language.
     *
     */
    public browserLanguage: Nullable<string>;

    /**
     *
     * @param {String} [userAgent]
     *
     */
    public constructor(userAgent?: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                userAgent: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        userAgent = userAgent ?? window.navigator.userAgent;

        this.userAgent = userAgent;
        this.is = {} as Is;
        this.details = this.getDeviceDetails();
        this.platform = this.getPlatform();
        this.platformVersion = this.getPlatformVersion();
        this.browser = this.getBrowser();
        this.browserVersion = this.getBrowserVersion();
        this.formFactor = this.getFormFactor();
        this.browserLanguage = this.getBrowserLanguage();
    }

    /**
     *
     * @returns {Object} The device details.
     *
     */
    private getDeviceDetails() {
        let details: Nullable<DeviceDetails> = null;

        for (let i = 0; i < RegExes.devices.length; i++) {
            const device = RegExes.devices[i];

            for (let j = 0; j < device.regExes.length; j++) {
                const re = device.regExes[j];
                let match = this.match(re, device.groupIndex) as string;

                if (match) {
                    if (device.done) {
                        match = device.done(match);
                    }

                    details = {
                        family: device.family,
                        name: match.toLowerCase().replace(/(\s|-|_)/gi, '')
                    };

                    break;
                }
            }

            if (details) {
                break;
            }
        }

        return (
            details || {
                family: 'default',
                name: 'default'
            }
        );
    }

    /**
     *
     * @desc Helper method for safely running regex matches against the instances userAgent.
     * @param {RegExp} regex -Regex to match.
     * @param {Number} [index] - Optional captured match to return.
     * If no index is supplied, the entire match set is return as an array.
     * @returns {RegExpExecArray|string} String when used with index, or array of matches.
     *
     */
    private match(regex: RegExp, index: number) {
        const results = this.safeArray(regex.exec(this.userAgent), index) || '';

        return results;
    }

    /**
     *
     * @param {Object} reMap
     * @returns {*}
     *
     */
    private test(reMap: Record<string, RegExp | Array<RegExp>>) {
        const ua = this.userAgent;

        let result = '';
        let check: boolean;

        Object.keys(reMap).forEach((key) => {
            const re = reMap[key];

            if (Array.isArray(re)) {
                for (let i = 0; i < re.length; i++) {
                    check = re[i].test(ua);

                    if (check) {
                        break;
                    }
                }
            } else {
                check = re.test(ua);
            }

            key = key.toLowerCase();

            this.is[key] = this.is[key] ?? (check && !result);

            if (check && !result) {
                result = key;
            }
        });

        return result;
    }

    /**
     *
     * @returns {String} The platform value.
     *
     */
    private getPlatform() {
        return this.test(RegExes.platforms) || REGEXES_OTHER;
    }

    /**
     *
     * @returns {String} The platform version
     *
     */
    private getPlatformVersion() {
        let platformVersion = '';

        if (this.platform && this.platform !== REGEXES_OTHER) {
            platformVersion =
                (this.match(
                    RegExes.platformVersion[
                        this.platform.toUpperCase() as keyof typeof RegExes.platformVersion
                    ],
                    2
                ) as string) || REGEXES_VERSION;
        }

        switch (true) {
            case this.is.android:
            case this.is.ios:
            case this.is.macintosh:
                platformVersion = this.version(platformVersion as string);
                break;
            case this.is.windows:
            case this.is.windowsphone:
                platformVersion = this.windowsVersion(platformVersion);
                break;
            // no default
        }

        return platformVersion;
    }

    /**
     *
     * @returns {String} The browser.
     *
     */
    private getBrowser() {
        this.test(RegExes.browserCore);

        return this.test(RegExes.browser) || REGEXES_OTHER;
    }

    /**
     *
     * @returns {String} The browser version
     *
     */
    private getBrowserVersion() {
        let browserVersion = '';

        if (this.browser) {
            const re =
                RegExes.browser[
                    this.browser.toUpperCase() as keyof typeof RegExes.browser
                ];

            if (re) {
                if (Array.isArray(re)) {
                    for (let i = 0; i < re.length; i++) {
                        browserVersion = this.match(re[i], 1) as string;

                        if (browserVersion !== '') {
                            break;
                        }
                    }
                } else {
                    browserVersion = this.match(re, 1) as string;
                }
            }
        }

        return this.version(browserVersion);
    }

    /**
     *
     * @returns {String} The form factor
     * @todo different tests per platform?
     *
     */
    private getFormFactor() {
        let formFactorTest = {};
        const formFactors = REGEXES_FORMFACTORS;

        if (this.is.msie) {
            formFactorTest = RegExes.formFactor.ms;
        } else {
            formFactorTest = RegExes.formFactor.default;
        }

        return (
            formFactors[
                this.test(formFactorTest) as keyof typeof formFactors
            ] || formFactors.other
        );
    }

    /**
     *
     * @since 10.0.0
     * @returns {String} The browser language.
     * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#specifying-languages
     *
     */
    private getBrowserLanguage() {
        let lang;

        const langPropKeys = [
            'language',
            'browserLanguage',
            'systemLanguage',
            'userLanguage'
        ];

        for (let i = 0; i < langPropKeys.length; i++) {
            lang = window.navigator[
                langPropKeys[i] as keyof typeof window.navigator
            ] as string;

            if (lang) {
                return lang;
            }
        }

        return null;
    }

    /**
     *
     * @access private
     * @function version
     * @param {String} vstr - The version string to parse.
     * @returns {string}
     *
     */
    private version(vstr: string) {
        const varr = vstr.split(/[._]/).slice(0, 3);

        if (vstr && varr.length < 2) {
            varr.push('0');
        }

        return varr.join('.');
    }

    /**
     *
     * @access private
     * @function safeArray
     * @param {Array} ar
     * @param {Number} index
     * @returns {RegExpExecArray|string}
     *
     */
    private safeArray(ar: Nullable<RegExpExecArray>, index: number) {
        return ar && index ? ar[index] : ar;
    }

    /**
     *
     * @access private
     * @function windowsVersion
     * @param {String} vstr - The version string to parse.
     * @returns {string}
     *
     */
    private windowsVersion(vstr: string) {
        const versionMap = {
            'NT 6.0': 'vista',
            'NT 6.1': '7.0',
            'NT 6.2': '8.0',
            'NT 6.3': '8.1',
            'NT 6.4': '10.0',
            'NT 10.0': '10.0'
        };

        return versionMap[vstr as keyof typeof versionMap] ?? vstr;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Providers.Device';
    }
}
