/**
 *
 * @module entitlementClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @since 4.9.0
 * @typedef {Object} SDK.Services.Entitlement.EntitlementClientEndpoint
 *
 * @property {String} verifyMediaRights - Since `4.9.0`.
 *
 */
enum EntitlementClientEndpoint {
    verifyMediaRights = 'verifyMediaRights'
}

export default EntitlementClientEndpoint;
