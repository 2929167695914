/**
 *
 * @module flexClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/flex.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/growth-life-client-api.yaml
 * @see https://www.typescriptlang.org/docs/handbook/2/classes.html#implements-clauses
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from '../../logging/logger';
import LogTransaction from '../../logging/logTransaction';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import AccessToken from '../token/accessToken';
import HttpMethod from '../configuration/httpMethod';
import appendQuerystring from '../util/appendQuerystring';

import { FlexOptionsTypedef, FlexOptions } from '../../flex/typedefs';
import FlexClientEndpoint from './flexClientEndpoint';
import FlexClientConfiguration from './flexClientConfiguration';

import { Screen, ExecutionResponseTypedef, ScreenTypedef } from './typedefs';
import { IEndpoint } from '../providers/typedefs';
import HttpHeaders from '../providers/shared/httpHeaders';
import replaceHeaders from '../util/replaceHeaders';
import ClientBase from '../clientBase';

const FlexClientDustUrnReference = DustUrnReference.services.flex.flexClient;

/**
 *
 * @access protected
 * @since 16.0.0
 * @desc Provides a data client that can be used to access GrowthLife services.
 *
 */
export default class FlexClient extends ClientBase<FlexClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Flex.FlexClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: FlexClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(FlexClientConfiguration),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider)
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.Screen>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getScreen(
        flexOptions: FlexOptions,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                flexOptions: Types.object(FlexOptionsTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getScreen', params, arguments);
        }

        const { logger } = this;
        const { key, version, queryParams, configOverride, requestBody } =
            flexOptions;

        let endpointKey = key;

        if (!this.isKeyInConfig(key)) {
            endpointKey = FlexClientEndpoint.default;

            logger.warn(
                this.toString(),
                `FlexClientEndpoint[${key}] is not defined, using the "default" endpoint.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                queryParams,
                requestBody,
                version,
                configOverride,
                clientOverrideHeaders: flexOptions.headers
            }
        });

        logger.info(this.toString(), `Attempting to get Screen: ${key}.`);

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: FlexClientDustUrnReference.getScreen,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data, headers } = response;
                const viewData = data.data;

                viewData.headers = this.getHeadersObject(headers);

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        viewData: Types.object(ScreenTypedef)
                    };

                    typecheck.warn(responseType, [viewData]);
                }

                return viewData as Screen;
            }
        });
    }

    /**
     *
     * @since 24.0.0
     * @param {Headers|undefined} headers
     * @desc Converts an http `Headers` to a simple `Object`.
     * @returns {Object}
     *
     */
    private getHeadersObject(headers?: Headers) {
        if (headers) {
            const mappedHeaders: Record<string, string> = {};

            headers.forEach((value, headerKey) => {
                mappedHeaders[headerKey] = value;
            }, {});

            return mappedHeaders;
        }

        return {};
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {SDK.Flex.FlexOptions} flexOptions - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {SDK.Services.Token.AccessToken} accessToken - The access token to provide user context.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<Object<SDK.Services.Flex.ExecutionResponse>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async execute(
        flexOptions: FlexOptions,
        accessToken: AccessToken,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                flexOptions: Types.object(FlexOptionsTypedef),
                accessToken: Types.instanceStrict(AccessToken),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'execute', params, arguments);
        }

        const { logger } = this;
        const { key, version, queryParams, configOverride, requestBody } =
            flexOptions;

        let endpointKey = key;

        if (!this.isKeyInConfig(key)) {
            endpointKey = FlexClientEndpoint.execution;

            logger.warn(
                this.toString(),
                `FlexClientEndpoint[${key}] is not defined, using the "execution" endpoint.`
            );
        }

        const payload = this.getPayload({
            accessToken,
            endpointKey,
            data: {
                queryParams,
                requestBody,
                version,
                configOverride,
                clientOverrideHeaders: flexOptions.headers
            }
        });

        logger.info(this.toString(), `Attempting to execute: ${key}.`);

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: FlexClientDustUrnReference.execute,
            payload,
            endpointKey,
            logTransaction
        });

        return super.request({
            payload,
            dustLogUtility,
            resultMapper: (response) => {
                const { data, headers } = response;
                const flexData = data.data;

                flexData.headers = this.getHeadersObject(headers);

                /* istanbul ignore else */
                if (__SDK_TYPECHECK__) {
                    const responseType = {
                        flexData: Types.object(ExecutionResponseTypedef)
                    };

                    typecheck.warn(responseType, [flexData]);
                }

                return flexData;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} options.accessToken
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.endpointKey
     * @param {Object} [options.data={}] - additional data to be used (i.e. data to be used within a
     * templated href, etc...).
     * @returns {GetPayloadResult} The payload for the client call.
     *
     */
    private getPayload(options: {
        accessToken: AccessToken;
        endpointKey: string;
        data: {
            queryParams?: object;
            requestBody?: object;
            version: string;
            configOverride?: (endpoint: IEndpoint) => IEndpoint;
            clientOverrideHeaders?: Record<string, string>;
        };
    }) {
        const { accessToken, endpointKey, data } = options;

        const { endpoints } = this.config;
        const endpoint = endpoints[endpointKey as FlexClientEndpoint];
        const { headers, href, method, templated } = endpoint as IEndpoint;

        const {
            queryParams,
            version,
            configOverride,
            clientOverrideHeaders,
            requestBody
        } = data;

        let requestMethod = method;
        let requestHref = href;
        let body;
        let url;

        if (configOverride) {
            if (
                endpointKey === FlexClientEndpoint.default ||
                endpointKey === FlexClientEndpoint.execution
            ) {
                const overrideSettings = configOverride({
                    ...(endpoint as IEndpoint)
                });

                requestMethod = overrideSettings.method;
                requestHref = overrideSettings.href;
            } else {
                this.logger.warn(
                    this.toString(),
                    'configOverride is only allowed for unknown key(s).'
                );
            }
        }

        if (templated && version) {
            requestHref = requestHref.replace(/\{version\}/gi, version);
        }

        url = requestHref;

        if (Check.assigned(queryParams)) {
            url = appendQuerystring(requestHref, stringify(queryParams));
        }

        // Data to be sent as the body of a POST or PUT request.
        if (
            Check.assigned(requestBody) &&
            (requestMethod === HttpMethod.POST ||
                requestMethod === HttpMethod.PUT)
        ) {
            body = JSON.stringify(requestBody);
        }

        let requestHeaders = replaceHeaders(
            {
                Authorization: () => {
                    return {
                        replacer: '{accessToken}',
                        value: accessToken.token
                    };
                }
            },
            headers
        );

        if (Check.nonEmptyObject(clientOverrideHeaders)) {
            requestHeaders = {
                ...requestHeaders,
                ...clientOverrideHeaders
            };
        }

        return {
            url,
            method: requestMethod,
            body,
            headers: new HttpHeaders(requestHeaders)
        };
    }

    /**
     *
     * @param {String} key
     * @returns {Boolean}
     *
     */
    private isKeyInConfig(key: string) {
        return !!this.config.endpoints[key as FlexClientEndpoint];
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Flex.FlexClient';
    }
}
