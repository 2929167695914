/**
 *
 * @module partner
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import type { Bundle } from './typedefs';
import EligibilityStatus from './eligibilityStatus';

/**
 *
 * @access public
 * @since 4.2.0
 *
 */
export default class Partner {
    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String}
     *
     */
    public partner: string;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String<SDK.Services.Eligibility.EligibilityStatus>|undefined}
     *
     */
    public eligibility?: EligibilityStatus;

    /**
     *
     * @access public
     * @since 4.2.0
     * @type {String}
     *
     */
    public email: string;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {String|undefined}
     *
     */
    public redirect?: string;

    /**
     *
     * @access public
     * @since 4.17.0
     * @type {String<SDK.Services.Eligibility.EligibilityStatus>|undefined}
     *
     */
    public noahEligibility?: EligibilityStatus;

    /**
     *
     * @access public
     * @since 21.1.0
     * @type {Object<String, Object<SDK.Services.Eligibility.Bundle>>}
     *
     */
    public bundles: Record<string, Bundle>;

    /**
     *
     * @param {Object} options
     * @param {String} options.partner
     * @param {String<SDK.Services.Eligibility.EligibilityStatus>} [options.eligibility]
     * @param {String} options.email
     * @param {String} [options.redirect]
     * @param {String<SDK.Services.Eligibility.EligibilityStatus>} [options.noahEligibility]
     * @param {Object} options.bundles
     *
     */
    public constructor(options: {
        partner: string;
        eligibility?: EligibilityStatus;
        email: string;
        redirect?: string;
        noahEligibility?: EligibilityStatus;
        bundles: Record<string, Bundle>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    partner: Types.nonEmptyString,
                    eligibility: Types.in(EligibilityStatus).optional.warn,
                    email: Types.nonEmptyString.warn,
                    redirect: Types.nonEmptyString.optional.warn,
                    noahEligibility: Types.in(EligibilityStatus).optional.warn,
                    bundles: Types.nonEmptyObject.warn
                })
            };

            typecheck(this, params, arguments);
        }

        const {
            partner,
            eligibility,
            email,
            redirect,
            noahEligibility,
            bundles = {}
        } = options;

        this.partner = partner;
        this.eligibility = eligibility;
        this.email = email;
        this.redirect = redirect;
        this.noahEligibility = noahEligibility;
        this.bundles = {};

        Object.keys(bundles).forEach((name: string) => {
            this.bundles[name] = { ...bundles[name], name };
        });
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Eligibility.Partner';
    }
}
