/**
 *
 * @module purchasePlugin
 * @desc {SDK.Purchase.PurchasePlugin} entry point.
 *
 */

import EligibilityPlugin from '../eligibility/eligibilityPlugin';
import ExternalActivationPlugin from '../externalActivation/externalActivationPlugin';

import PurchaseApi from './purchaseApi';

import EligibilityClient from '../services/eligibility/eligibilityClient';
import ExternalActivationClient from '../services/externalActivation/externalActivationClient';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Purchase'> = {
    pluginName: 'Purchase',
    dependencies: [EligibilityPlugin, ExternalActivationPlugin],
    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { httpClient } = clients;

        /**
         *
         * @access public
         * @type {PurchaseApi}
         * @desc Gets the purchases resource used to support and retrieve in app purchases.
         *
         */
        sdkSession.purchaseApi = new PurchaseApi({
            eligibilityClient: new EligibilityClient({
                config: sdkSession.config.services.eligibility,
                logger,
                httpClient
            }),
            externalActivationClient: new ExternalActivationClient({
                config: sdkSession.config.services.externalActivation,
                logger,
                httpClient
            }),
            tokenManager: sdkSession.managers.tokenManager,
            logger
        });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
