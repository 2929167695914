/**
 *
 * @module graphQlClient
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import GraphQlRequest from './graphQlRequest';

import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import Logger from '../../logging/logger';
import ServiceEndpoint from '../configuration/serviceEndpoint';
import replaceHeaders from '../util/replaceHeaders';

import circularReplacer from '../util/circularReplacer';
import AccessToken from '../token/accessToken';
import { IEndpoint } from '../providers/typedefs';
import HttpHeaders from '../providers/shared/httpHeaders';
import { GENERATE_ADRT } from '../providers/shared/httpHeaderConstants';

/**
 *
 * @access protected
 * @since 4.12.0
 *
 */
export default class GraphQlClient {
    /**
     *
     * @access public
     * @since 4.12.0
     * @param {Object} options
     * @param {SDK.Services.Configuration.ServiceEndpoint} options.endpoint - The URL for which to build the query.
     * @param {SDK.Services.GraphQl.GraphQlRequest} options.request - The request for which to build the query.
     * @param {SDK.Services.Token.AccessToken} options.accessToken - The access token.
     * @param {String} [options.apiKey] - The apiKey.
     * @param {SDK.Logging.Logger} options.logger - The logger.
     * @param {CoreHttpClientProvider} options.httpClient - The httpClient provider.
     * @param {Object} [options.extraHeaders] - Any extra header
     * @desc Executes the query.
     * @returns {Promise<ServerResponse>}
     *
     */
    public static async query(options: {
        endpoint: IEndpoint;
        request: GraphQlRequest;
        accessToken: AccessToken;
        apiKey?: string;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        extraHeaders?: Record<string, string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    endpoint: Types.instanceStrict(ServiceEndpoint),
                    request: Types.instanceStrict(GraphQlRequest),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    extraHeaders: Types.object().optional,
                    accessToken: Types.instanceStrict(AccessToken)
                })
            };

            typecheck(GraphQlClient, 'query', params, arguments);
        }

        const {
            endpoint,
            request,
            accessToken,
            logger,
            httpClient,
            extraHeaders
        } = options;

        const payload = GraphQlClient.getPayload({
            accessToken,
            endpoint,
            request,
            logger,
            extraHeaders
        });

        logger.info(
            this.toString(),
            `Attempting to execute GraphQl query with variables: ${JSON.stringify(
                request.variables,
                circularReplacer()
            )}`
        );

        return httpClient.request(payload);
    }

    /**
     *
     * @access public
     * @since 18.0.0
     * @param {Object} options
     * @param {SDK.Services.Configuration.ServiceEndpoint} options.endpoint - The URL for which to build the query.
     * @param {SDK.Services.GraphQl.GraphQlRequest} options.request - The request for which to build the query.
     * @param {String} options.apiKey - The apiKey.
     * @param {SDK.Logging.Logger} options.logger - The logger.
     * @param {CoreHttpClientProvider} options.httpClient - The httpClient provider.
     * @param {Boolean} options.enableADRT - Indicate whether to enable ADRT header
     * @desc Executes the query to refresh token.
     * @returns {Promise<ServerResponse>}
     *
     */
    public static async refreshToken(options: {
        endpoint: IEndpoint;
        request: GraphQlRequest;
        apiKey: string;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        enableADRT: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    endpoint: Types.instanceStrict(ServiceEndpoint),
                    request: Types.instanceStrict(GraphQlRequest),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    apiKey: Types.nonEmptyString,
                    enableADRT: Types.boolean
                })
            };

            typecheck(GraphQlClient, 'refreshToken', params, arguments);
        }

        const { endpoint, request, apiKey, logger, httpClient, enableADRT } =
            options;

        const payload = GraphQlClient.getPayload({
            apiKey,
            endpoint,
            request,
            logger,
            enableADRT
        });

        logger.info(
            this.toString(),
            `Attempting to refresh token with variables: ${JSON.stringify(
                request.variables,
                circularReplacer()
            )}`
        );

        return httpClient.request(payload);
    }

    /**
     *
     * @access public
     * @since 9.0.0
     * @param {Object} options
     * @param {SDK.Services.Configuration.ServiceEndpoint} options.endpoint - The URL for which to build the query.
     * @param {SDK.Services.GraphQl.GraphQlRequest} options.request - The request for which to build the query.
     * @param {String} options.apiKey - The apiKey.
     * @param {SDK.Logging.Logger} options.logger - The logger.
     * @param {CoreHttpClientProvider} options.httpClient - The httpClient provider.
     * @param {Object} [options.extraHeaders] - Any extra header
     * @desc Executes the query.
     * @returns {Promise<ServerResponse>}
     *
     */
    public static async registerDevice(options: {
        endpoint: IEndpoint;
        request: GraphQlRequest;
        apiKey: string;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
        extraHeaders?: Record<string, string>;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    endpoint: Types.instanceStrict(ServiceEndpoint),
                    request: Types.instanceStrict(GraphQlRequest),
                    logger: Types.instanceStrict(Logger),
                    httpClient: Types.instanceStrict(CoreHttpClientProvider),
                    extraHeaders: Types.object().optional,
                    apiKey: Types.nonEmptyString
                })
            };

            typecheck(GraphQlClient, 'registerDevice', params, arguments);
        }

        const { endpoint, request, apiKey, logger, httpClient, extraHeaders } =
            options;

        const payload = GraphQlClient.getPayload({
            apiKey,
            endpoint,
            extraHeaders,
            request,
            logger
        });

        logger.info(
            this.toString(),
            `Attempting to register a device with variables: ${JSON.stringify(
                request.variables,
                circularReplacer()
            )}`
        );

        return httpClient.request(payload);
    }

    /**
     *
     * @access private
     * @since 4.12.0
     * @param {Object} options
     * @param {SDK.Services.Token.AccessToken} [options.accessToken] - The access token
     * @param {String} [options.apiKey] - The service apiKey
     * @param {SDK.Services.Configuration.ServiceEndpoint} options.endpoint - The URL for which to build the query.
     * @param {SDK.Services.GraphQl.GraphQlRequest} options.request - Request to be serialized and passed with the request as body.
     * @param {Object} [options.extraHeaders={}] - Any extra header
     * @param {SDK.Logging.Logger} options.logger
     * @param {Boolean} [options.enableADRT=true] - Indicate whether to enable ADRT header
     * @returns {Object} The payload for the client request.
     *
     */
    private static getPayload(options: {
        accessToken?: AccessToken;
        apiKey?: string;
        endpoint: IEndpoint;
        request: GraphQlRequest;
        extraHeaders?: Record<string, string>;
        logger: Logger;
        enableADRT?: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    accessToken: Types.instanceStrict(AccessToken).optional,
                    apiKey: Types.nonEmptyString.optional
                })
            };

            typecheck.atLeastOne(
                GraphQlClient,
                'getPayload',
                params,
                arguments
            );
        }

        const {
            accessToken,
            apiKey,
            endpoint,
            request,
            extraHeaders = {},
            enableADRT = true
        } = options;

        const { href: url, method } = endpoint;

        const body = JSON.stringify(request);

        const headers = replaceHeaders(
            {
                Authorization: () => {
                    if (apiKey) {
                        return { replacer: '{apiKey}', value: apiKey };
                    }

                    return {
                        replacer: '{accessToken}',
                        value: accessToken?.token
                    };
                },
                [GENERATE_ADRT]: () => {
                    return { value: enableADRT };
                }
            },
            endpoint.headers,
            extraHeaders
        );

        return {
            url,
            method,
            body,
            headers: new HttpHeaders(headers)
        };
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return GraphQlClient.toString();
    }

    /**
     *
     * @access private
     *
     */
    public static toString() {
        return 'SDK.Services.GraphQl.GraphQlClient';
    }
}
