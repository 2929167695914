/**
 *
 * @module flexApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/flex.md
 * @see https://github.bamtech.co/services-commons/public-api/blob/master/swagger/services/growth-life-client-api.yaml
 * @see https://wiki.disneystreaming.com/pages/viewpage.action?pageId=444577632
 * @see https://wiki.disneystreaming.com/display/DEVOPS/FLEX+API
 * @see https://www.typescriptlang.org/cheatsheets
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import FlexClient from '../services/flex/flexClient';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';

import { FlexOptionsTypedef, FlexOptions } from './typedefs';
import { Screen, ExecutionResponse } from '../services/flex/typedefs';
import { ApiOptions } from '../typedefs';

import type Logger from '../logging/logger';
import type AccessTokenProvider from '../token/accessTokenProvider';

const DustUrn = DustUrnReference.flex.flexApi;
const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access public
 * @since 16.0.0
 * @desc Provides an object for all GrowthLife related flows.
 * @note FLEX is the gateway layer we interact with, for accessing the overall Unified GrowthLife ecosystem.
 * @note Flex hits the SPLAT service which is an orchestration like layer that interacts with different services and obtains
 * all the necessary data. Once all the data is gathered, Flex determines how the UI should be painted.
 *
 */
export default class FlexApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {SDK.Services.Flex.FlexClient}
     *
     */
    private flexClient: FlexClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Flex.flexClient} options.flexClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        logger: Logger;
        flexClient: FlexClient;
        accessTokenProvider: AccessTokenProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    flexClient: Types.instanceStrict(FlexClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { flexClient } = options;

        this.flexClient = flexClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 16.0.0
     * @param {Object<SDK.Flex.FlexOptions>} options - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.key - Used to determine the endpoint to hit. EX: "billingHistory", "subscriptionSummary", "cancelFlow"
     * @param {String} options.version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
     * @param {Object} [options.queryParams] - Since `29.0.0` - Parameters to be applied as query params to a request.
     * @param {Object} [options.requestBody] - Since `29.0.0` - Flex parameters to send as POST/PUT data with a request. The values from the map have to be JSON serializable.
     * @param {Function} [options.configOverride] - Optional override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen, "execution" for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or "execution" href value, changing the HTTP method, etc...
     * @param {Object} [options.headers] - `Since 26.0.0` - Client-provided request headers.
     * @desc Returns the template for a given page.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Flex.Screen>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async getScreen(options: FlexOptions): Promise<Screen>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(FlexOptionsTypedef)
        }
    })
    public async getScreen(apiOptions?: unknown) {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions<[FlexOptions]>;

        return this.flexClient.getScreen(
            options,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access public
     * @since 17.0.0
     * @param {Object<SDK.Flex.FlexOptions>} options - Options that determine how the SDK interacts with a Flex endpoint.
     * @param {String<SDK.Services.Flex.FlexClientEndpoint>} options.key - Used to determine the endpoint to hit. EX: "subscriptionRestart", "subscriptionCancel", "subscriptionSwitch"
     * @param {String} options.version - Version provided by app dev (app dev determines which version they want/support). EX: "v1"
     * @param {Object} [options.queryParams] - Since `29.0.0` - Parameters to be applied as query params to a request.
     * @param {Object} [options.requestBody] - Since `29.0.0` - Flex parameters to send as POST/PUT data with a request. The values from the map have to be JSON serializable.
     * @param {Function} [options.configOverride] - Override function. Right before the SDK makes a Flex api call we grab the config (if found, "default" for #getScreen, "execution"
     * for #execute if not found) based on the key. If this function is defined, allows app devs to manipulate the configuration by adding to the "default", or "execution" href value, changing the HTTP method, etc...
     * @param {Object} [options.headers] - Since `26.0.0` - Client-provided request headers.
     * @desc A generic execute for interaction with Flex endpoints.
     * @note Gives application developers the ability to hit Flex endpoints and get some kind of data back.
     * @throws {SDK.Services.Exception.CommonExceptions} Exception cases generic to all endpoints.
     * @returns {Promise<Object<SDK.Services.Flex.ExecutionResponse>>} A promise that completes when the
     * operation has succeeded.
     *
     */
    public async execute(options: FlexOptions): Promise<ExecutionResponse>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            options: Types.object(FlexOptionsTypedef)
        }
    })
    public async execute(apiOptions: unknown) {
        const {
            logTransaction,
            args: [options]
        } = apiOptions as ApiOptions<[FlexOptions]>;

        return this.flexClient.execute(
            options,
            super.accessToken,
            logTransaction
        );
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Flex.FlexApi';
    }
}
