/**
 *
 * @module playReadyDrmProvider
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/drm.md#playready
 *
 */

import { Types } from '@dss/type-checking';

import Events from '../events';
import DrmProvider from './drmProvider';
import PlayReadyMessage from '../services/drm/playReadyMessage';
import DustUrnReference from '../services/internal/dust/dustUrnReference';
import DustDecorators from '../services/internal/dust/dustDecorators';
import type DrmClient from '../services/drm/drmClient';
import type TokenManager from '../token/tokenManager';
import type Logger from '../logging/logger';
import type MediaItem from '../media/mediaItem';
import DrmClientEndpoint from '../services/drm/drmClientEndpoint';
import { ApiOptions } from '../typedefs';

import { DrmType } from '../services/media/enums';

const DustUrn = DustUrnReference.drm.playReadyDrmProvider;

const apiMethodDecorator = DustDecorators.apiMethodDecorator.bind(
    null,
    DustUrn
);

/**
 *
 * @access protected
 * @since 3.2.0
 * @desc PlayReady is the Microsoft content encryption standard.
 *
 */
export default class PlayReadyDrmProvider extends DrmProvider {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Drm.DrmClient} options.drmClient
     * @param {SDK.Token.TokenManager} options.tokenManager
     * @param {SDK.Logging.Logger} options.logger
     * @param {SDK.Media.MediaItem} options.mediaItem
     * @param {String} [options.endpointKey]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     *
     */
    public constructor(options: {
        drmClient: DrmClient;
        tokenManager: TokenManager;
        logger: Logger;
        mediaItem: MediaItem;
        endpointKey?: DrmClientEndpoint;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
    }) {
        super({
            ...options,
            type: DrmType.PLAYREADY
        });

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @param {Object} [body={}]
     * @desc Gets a license required for decrypting PlayReady protected content.
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Promise<ArrayBuffer>}
     *
     */
    public async getPlayReadyLicense(
        body?: Record<string, unknown>
    ): Promise<ArrayBuffer>;

    @apiMethodDecorator({
        paramTypes: __SDK_TYPECHECK__ && {
            body: Types.object().optional
        }
    })
    public async getPlayReadyLicense(apiOptions?: unknown) {
        const {
            logTransaction,
            args: [body = {}]
        } = apiOptions as ApiOptions<[Record<string, unknown>?]>;

        const { licenseRequestHeaders, mediaItem, endpointKey, accessToken } =
            this;

        const message = new PlayReadyMessage(body, licenseRequestHeaders);

        try {
            return await this.drmClient.getPlayReadyLicense({
                message,
                mediaItem,
                endpointKey,
                accessToken,
                logTransaction
            });
        } catch (exception) {
            this.logger.error(
                this.toString(),
                'PlayReady license request failure.'
            );
            this.logger.info(
                this.toString(),
                `Dispatch ${Events.MediaFailure} event.`
            );

            this.emit(Events.MediaFailure, exception);

            throw exception;
        }
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Drm.PlayReadyDrmProvider';
    }
}
