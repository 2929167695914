/**
 *
 * @module qrCodeApi
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/qrCode.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import BaseApi from '../baseApi';
import QRCodeClient from '../services/qrCode/qrCodeClient';

import { QRCodeQueryParams, QRCodeQueryParamsTypedef } from './typedefs';

import isValidURL from '../services/util/isValidURL';
import InvalidArgumentException from '../services/exception/invalidArgumentException';

import type AccessTokenProvider from '../token/accessTokenProvider';
import type Logger from '../logging/logger';

/**
 *
 * @access public
 * @since 26.0.0
 * @desc Provides an object used to access QR Code related features.
 *
 */
export default class QRCodeApi extends BaseApi {
    /**
     *
     * @access private
     * @since 29.0.0
     * @type {QRCodeClient}
     *
     */
    private qrCodeClient: QRCodeClient;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {QrCodeClient} options.qrCodeClient
     * @param {AccessTokenProvider} options.accessTokenProvider
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        qrCodeClient: QRCodeClient;
        accessTokenProvider: AccessTokenProvider;
        logger: Logger;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    qrCodeClient: Types.instanceStrict(QRCodeClient)
                })
            };

            typecheck(this, params, arguments);
        }

        const { qrCodeClient } = options;

        this.qrCodeClient = qrCodeClient;

        this.logger.info(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 26.0.0
     * @param {QRCodeQueryParams} params
     * @param {String} params.url
     * @param {Number} params.size
     * @param {QRCodeFormat} params.format
     * @returns {String}
     *
     */
    public getQrCodeUrl(params: QRCodeQueryParams) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const paramsObj = {
                params: Types.object(QRCodeQueryParamsTypedef)
            };

            typecheck(this, 'getQrCodeUrl', paramsObj, arguments);
        }

        const { url } = params;

        if (isValidURL(url)) {
            return this.qrCodeClient.getQrCodeUrl(params);
        }
        throw new InvalidArgumentException(`${url} is not a valid URL.`);
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.QrCode.QrCodeApi';
    }
}
