/**
 *
 * @module entry
 * @desc {SDK.Services.Session} entry point.
 *
 */

import LocationType from './locationType';
import SessionInfo from './sessionInfo';

/**
 *
 * @typedef {Object} SDK.Services.Session
 *
 * @property {LocationType} LocationType
 * @property {SessionInfo} SessionInfo
 *
 */
export default {
    LocationType,
    SessionInfo
};
