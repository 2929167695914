/* eslint-disable no-underscore-dangle */

/**
 *
 * @module eventSchemata
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import EventSchema from './eventSchema';

/**
 *
 * @desc A collection of `SDK.UserActivity.EventSchema` used by `SDK.UserActivity.EventSchemataProvider` when
 * constructing a payload
 * @note this is entirely private and is constructed within `SDK.UserActivity.EventSchemataProvider`
 *
 */
export default class EventSchemata {
    /**
     *
     * @access private
     * @type {Array<SDK.UserActivity.EventSchema>}
     *
     */
    private schemata: Array<EventSchema>;

    /**
     *
     * @access private
     * @type {Boolean}
     * @desc determines if we should apply global tracking parameters when the payload is generated
     *
     */
    private passthrough: boolean;

    /**
     *
     * @access private
     * @param {Array<SDK.UserActivity.EventSchema>} schemata
     * @param {Boolean} passthrough
     *
     */
    public constructor(schemata: Array<EventSchema>, passthrough: boolean) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                schemata: Types.array.of.instanceStrict(EventSchema),
                passthrough: Types.boolean
            };

            typecheck(this, params, arguments);
        }

        this.schemata = schemata;

        this.passthrough = passthrough;
    }

    /**
     *
     * @access private
     * @param {Object} trackingParameters
     * @note `trackingParameters` should be cloned to avoid modifying it
     * @note this will loop through all of the available schema and generate all possible individual values
     * first it will potentially merge in the global tracking params and then start to iterate through the schemata
     * which in turn will iterate through each individual schemas values
     * @note we keep track of any failures that occur during this loop but since we are merging the object each time
     * we need to store an external variable outside of the loop to keep track of the failures
     * @returns {Object}
     *
     */
    public generateEventPayload(trackingParameters: Record<string, unknown>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                trackingParameters: Types.object()
            };

            typecheck(this, 'generateEventPayload', params, arguments);
        }

        const { schemata, passthrough } = this;

        const _failures: Array<string> = [];

        let eventPayload = passthrough ? trackingParameters : {};

        for (const schema of Object.keys(schemata)) {
            const schemaGeneratedData =
                schemata[schema as unknown as number].generateEventData(
                    trackingParameters
                );

            if (Check.nonEmptyArray(schemaGeneratedData._failures)) {
                _failures.push(
                    ...(schemaGeneratedData._failures as Array<string>)
                );

                // not necessary but cleaner to remove it and add back the aggregate _failures array before returning
                delete schemaGeneratedData._failures;
            }

            eventPayload = Object.assign(eventPayload, schemaGeneratedData);
        }

        // since `eventPayload._failures` is overwritten in each loop we have to keep track and rewrite before returning
        if (Check.nonEmptyArray(_failures)) {
            eventPayload._failures = _failures;
        }

        return eventPayload;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.UserActivity.EventSchemata';
    }
}
