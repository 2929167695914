/**
 *
 * @module retryPolicy
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Provides service retry rules.
 *
 */
export default class RetryPolicy {
    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets or sets the retry base period for activation in seconds.
     *
     */
    public retryBasePeriod: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets or sets the retry multiplier for activation.
     *
     */
    public retryMultiplier: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets or sets the maximum number of retry attempts for activation.
     *
     */
    public retryMaxAttempts: number;

    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets or sets the maximum period for retry attempts for activation.
     *
     */
    public retryMaxPeriod: number;

    /**
     *
     * @param {Object} options
     * @param {Number} options.retryBasePeriod
     * @param {Number} options.retryMultiplier
     * @param {Number} options.retryMaxAttempts
     * @param {Number} options.retryMaxPeriod
     *
     */
    public constructor(options: {
        retryBasePeriod: number;
        retryMultiplier: number;
        retryMaxAttempts: number;
        retryMaxPeriod: number;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                retryBasePeriod: Types.number.warn,
                retryMultiplier: Types.number.warn,
                retryMaxAttempts: Types.number.warn,
                retryMaxPeriod: Types.number.warn
            };

            typecheck(this, params, arguments);
        }

        const {
            retryBasePeriod,
            retryMultiplier,
            retryMaxAttempts,
            retryMaxPeriod
        } = options;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the retry base period for activation in seconds.
         *
         */
        this.retryBasePeriod = retryBasePeriod;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the retry multiplier for activation.
         *
         */
        this.retryMultiplier = retryMultiplier;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the maximum number of retry attempts for activation.
         *
         */
        this.retryMaxAttempts = retryMaxAttempts;

        /**
         *
         * @access public
         * @type {Number}
         * @desc Gets or sets the maximum period for retry attempts for activation.
         *
         */
        this.retryMaxPeriod = retryMaxPeriod;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.RetryPolicy';
    }
}
