/**
 *
 * @module tokenManagerConfiguration
 *
 */

import TokenClientConfiguration from '../token/tokenClientConfiguration';
import ServiceConfiguration from './serviceConfiguration';
import TokenManagerExtrasMap from './tokenManagerExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information necessary for managing token.
 *
 */
export default class TokenManagerConfiguration extends ServiceConfiguration<
    TokenClientConfiguration,
    TokenManagerExtrasMap
> {
    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.TokenManagerConfiguration';
    }
}
