/**
 *
 * @module deviceAttributeProvider
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import ProviderException from '../services/exception/providerException';
import Logger from '../logging/logger';

import DeviceAttributes from './deviceAttributes';

/**
 *
 * @access public
 * @since 4.11.0
 * @desc Defines the contract for a class that can provide the attributes of the device.
 * The SDK ships with a default implementation of the `DeviceAttributeProvider` that will
 * return no additional device attributes.
 *
 */
export default abstract class DeviceAttributeProvider {
    /**
     *
     * @access private
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @param {SDK.Logging.Logger} [logger=Logger.instance]
     *
     */
    public constructor(logger?: Logger) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                logger: Types.instanceStrict(Logger).optional
            };

            typecheck(this, params, arguments);
        }

        this.logger = logger || Logger.instance;

        this.logger.log(this.toString(), 'Created provider.');
    }

    /**
     *
     * @access public
     * @since 4.11.0
     * @desc An interface method. Retrieves application-provided device attributes.
     * @returns {DeviceAttributes}
     *
     */
    public abstract getDeviceAttributes(): DeviceAttributes;

    /**
     *
     * @access private
     * @since 9.0.0
     * @param {SDK.Device.DeviceAttributes} attributes
     * @param {String} advertisingId - advertising identifier sourced from `SDK.Advertising.AdvertisingIdProvider`.
     * @desc Validates if attributes object is an instance of {@link DeviceAttributes}
     * @note Shows a warning if there is no match between the advertising identifier and values provided
     * within `OsDeviceIds` as there is an expected match.
     * @throws {ProviderException}
     * @returns {Void}
     *
     */
    public static validateAttributes(
        attributes: DeviceAttributes,
        advertisingId: string
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                attributes: Types.instanceStrict(DeviceAttributes)
            };

            typecheck(
                DeviceAttributeProvider,
                'validateAttributes',
                params,
                [attributes],
                ProviderException
            );
        }

        const hasOsDeviceIds = !!attributes.osDeviceIds.length;
        const hasAdvertisingIdMatch = attributes.osDeviceIds.some(
            (osDeviceId) => {
                return osDeviceId.identifier === advertisingId;
            }
        );

        if (hasOsDeviceIds && !hasAdvertisingIdMatch) {
            Logger.instance.warn(
                'DeviceAttributeProvider',
                `Advertising Identifier: "${advertisingId}" not found in provided osDeviceIds: ${JSON.stringify(
                    attributes.osDeviceIds
                )}.`
            );
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Device.DeviceAttributeProvider';
    }
}
