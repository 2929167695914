/* eslint-disable import/no-unresolved, import/extensions */
import device from 'device/device';
import pageMeta from 'btm-pageMeta/pageMeta';


window.browser_device = device();
const baseUrl = window.location.host;
const isIE = device().is.msie;
// const isWinSeven = window.navigator.userAgent.includes('NT 6.1');
// var isSafari = device().is.safari;
// var isFirefox = device().is.firefox;
// var isChrome = device().is.chrome;
// const version = parseInt(device().browserVersion, 10);
// var safariVersionToUse = 9; // because the lowest supported iOS version for the iOS app is 9.3

function navigateToUnsupported() {
    window.location.replace(`//${baseUrl}/unsupported`);
}

// if section's config has {"showInAllBrowsers": true}
// do not redirect to unsupported browser page
if (!pageMeta.showInAllBrowsers) {

    if (isIE) {
        navigateToUnsupported();
    // } else if (isSafari && version < safariVersionToUse) {
    //     navigateToUnsupported();
    // } else if (isFirefox && version < 49) {
    //     navigateToUnsupported();
    // } else if (isChrome && version < 53) { // because Android Studio Emulator comes with Chrome 53 (as of 2017-03-01)
    //     navigateToUnsupported();
    }
}

// if (location.pathname.indexOf('video/') > -1) {
//     safariVersionToUse = 10; // Exclude Safari <10 from the video section
// }
