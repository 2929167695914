/**
 *
 * @module entry
 * @desc {SDK.Services.Util} entry point.
 *
 */

import handleServiceResponse from './handleServiceResponse';
import delay from './delay';
import getScope from './getScope';
import adEngineRegex from './adEngineRegex';
import dateRegex from './dateRegex';
import circularReplacer from './circularReplacer';
import toUpperFirst from './toUpperFirst';
import appendQuerystring from './appendQuerystring';
import hasLocalStorage from './hasLocalStorage';
import replaceHeaders from './replaceHeaders';
import replaceUrl from './replaceUrl';
import monotonicClock from './monotonicClock';
import parseUrl from './parseUrl';
import isValidURL from './isValidURL';
import uuidv4 from './uuidv4';

/**
 *
 * @typedef {Object} SDK.Services.Util
 *
 * @property {Function} handleServiceResponse
 * @property {Function} delay
 * @property {Function} getScope
 * @property {Function} adEngineRegex
 * @property {Function} circularReplacer
 * @property {Function} toUpperFirst
 * @property {Function} appendQuerystring
 * @property {Function} hasLocalStorage
 * @property {Function} replaceHeaders
 * @property {Function} replaceUrl
 * @property {Object} monotonicClock
 * @property {Function} parseUrl
 * @property {Boolean} isValidURL
 * @property {Function} uuidv4
 *
 */
export default {
    handleServiceResponse,
    delay,
    getScope,
    adEngineRegex,
    dateRegex,
    circularReplacer,
    toUpperFirst,
    appendQuerystring,
    hasLocalStorage,
    replaceHeaders,
    replaceUrl,
    monotonicClock,
    parseUrl,
    isValidURL,
    uuidv4
};
