/**
 *
 * @module accountGrant
 *
 */

import Grant from '../token/grant';

/**
 *
 * @access public
 * @desc A special type of grant used for exchanging account information for a token.
 * @note services returns grant_type NOT grantType and is renamed when destructured
 *
 */
export default class AccountGrant extends Grant {
    /**
     *
     * @access public
     * @type {String}
     * @desc Key used to lookup the `subject_token_type` value needed during token exchange.
     *
     */
    public override readonly subjectTokenTypeKey: string;

    /**
     *
     * @param {Object} options
     * @param {String} options.assertion
     * @param {String} [options.grant_type]
     *
     */
    public constructor(options: {
        assertion: string;
        grant_type?: string; // eslint-disable-line camelcase
    }) {
        const { assertion, grant_type: grantType } = options;

        super(assertion, grantType);

        this.subjectTokenTypeKey = 'account';
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Account.AccountGrant';
    }
}
