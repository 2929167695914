/**
 *
 * @module adEngineClientExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class AdEngineClientExtrasMap {
    /**
     *
     * @access public
     * @type {Object}
     * @desc Contains data to be used when setting cookies related to AdEngine tracking.
     *
     */
    public adTargeting: object;

    /**
     *
     * @param {Object} adTargeting
     *
     */
    public constructor(adTargeting: object) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                adTargeting: Types.object()
            };

            typecheck.warn(this, params, arguments);
        }

        this.adTargeting = adTargeting;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Media.AdEngine.AdEngineClientExtrasMap';
    }
}
