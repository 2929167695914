/**
 *
 * @module metric
 *
 */

import LogLevel from './logLevel';

/**
 *
 *
 */
export default class Metric {
    /**
     *
     * @access public
     * @type {String}
     *
     */
    public name: string;
    /**
     *
     * @access public
     * @type {Boolean}
     *
     */
    public isPublic: boolean;
    /**
     *
     * @access public
     * @type {LogLevel|null}
     *
     */
    public level: LogLevel | null;
    /**
     *
     * @access public
     * @type {Object<String, JSONSerializable>}
     *
     */
    public extraData: object;

    /**
     *
     * @access protected
     * @param {Object} [options={}]
     * @param {Boolean} [options.isPublic=false]
     * @param {LogLevel} [options.level=null]
     * @param {String} [options.name='']
     * @param {Object<String, JSONSerializable>} [options.extraData = {}]
     *
     */
    public constructor(options: {
        isPublic: boolean;
        level: LogLevel;
        name: string;
        extraData: object;
    }) {
        const { isPublic, level, name, extraData } = options || {};

        this.isPublic = isPublic || false;
        this.level = level || null;
        this.name = name || '';
        this.extraData = extraData || {};
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Logging.Metric';
    }
}
