/**
 *
 * @module ripcutImageRequestMode
 *
 */

/**
 *
 * @access public
 * @since 17.0.0
 * @typedef {Object} SDK.Ripcut.RipcutImageRequestMode
 * @desc Specifies image retrieval option such as compositing or scaling logic to be applied by Ripcut to the returned image.
 * @note For platforms that support it, a smart enum may be used with specifying parameters.
 *
 * @property {String} main - Since `17.0.0` - The original image associated with the master ID. If no image is found, a default black image is returned.
 * @note No associated parameters.
 * @property {String} mainScale - Since `17.0.0` - A new image of the requested size, created by scaling the master image. If no width and height dimensions provided the endpoint will return an original master image.
 * @note Associated parameters RipcutScaleParams.
 * @property {String} mainCompose - Since `17.0.0` - A new image of the requested size, created by compositing master image with a mask image or another master image. The composited image then changed to the new aspect ratio if the new aspect ratio value provided by aspectRatio parameter and further transformed according to provided parameters.
 * @note Associated parameters RipcutComposeParams.
 * @property {String} mainBadging - Since `17.0.0` - A new image of the requested size, created by scaling the master image and placing a label image on top of the new image. If no width and height dimensions provided the endpoint will return a new image with a label on top of it with dimensions of the master image.
 * @note Associated parameters RipcutBadgingParams.
 * @property {String} rawFiles - Since `22.0.0` - Returns a rawfile Image (.gif, .svg, .mp4) for the requested `partnerId` & `masterId`.
 * @note No associated parameters.
 * @property {String} trim - Since `27.0.0` - Returns a trimmed/resized Image into the max boundary provided for the requested imageId.
 * @note Associated parameters RipcutTrimParams.

 * @example
 * SDK.Ripcut.RipcutImageRequestMode.main // 'main'
 *
 */
enum RipcutImageRequestMode {
    main = 'main',
    mainScale = 'mainScale',
    mainCompose = 'mainCompose',
    mainBadging = 'mainBadging',
    rawFiles = 'rawFiles',
    // @note For `trim` - Uses the ratio between the aspect of the trimmed image and the aspect of the max bounds to determine how to fit-resize the trimmed image into the max boundary provided.
    trim = 'trim'
}

export default RipcutImageRequestMode;
