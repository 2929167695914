/**
 *
 * @module handleServiceResponse
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/sdkErrorCases.json
 * @see https://github.bamtech.co/userservices/orchestration-api/blob/master/OrchestrationApi.yaml#L99
 * @see https://docs.google.com/spreadsheets/d/1kHtlh7D08dArPp97iYTMHA2aIoBtK2bqBIbIUK1PJiM
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import checkAcceptedCase from './errorHandling/checkAcceptedCase';
import consoleDebug from './errorHandling/consoleDebug';
import createException from './errorHandling/createException';
import handleErrorCase from './errorHandling/handleErrorCase';
import processServerErrors from './errorHandling/processServerErrors';

import DustLogUtility from '../internal/dust/dustLogUtility';

import { ServerResponse } from '../providers/typedefs';

import { ServerErrorData } from './errorHandling/typedefs';
import HttpStatus from './errorHandling/httpStatus';
import { REQUEST_ID } from '../providers/shared/httpHeaderConstants';

/**
 *
 * @access private
 * @param {Object} options
 * @param {Object} options.response
 * @param {DustLogUtility} [options.dustLogUtility]
 * @param {String} [options.methodName] - name of the failed method
 * @param {IMonotonicTimestampProvider} [options.monotonicTimestampProvider] - The monotonic timestamp provider used to generate timestamps for events.
 * @returns {Promise<ServerResponse>} resolves the response or rejects with an exception
 * @note methodName is used for error cases that rely on the http status and not a service error code.
 * it is currently only used for error cases relating to the Commerce feature.
 * @see https://tools.ietf.org/html/rfc6749#section-5.1 (success)
 * @see https://tools.ietf.org/html/rfc6749#section-5.2 (error)
 *
 */
export default function handleServiceResponse<T>(options: {
    response: ServerResponse<T>;
    dustLogUtility?: DustLogUtility;
    methodName?: string;
}) {
    /* istanbul ignore else */
    if (__SDK_TYPECHECK__) {
        const params = {
            options: Types.object({
                response: Types.object(),
                dustLogUtility: Types.skip, // DustLogUtility - can't add ref to item due to circular reference.
                methodName: Types.nonEmptyString.optional
            })
        };

        typecheck('handleServiceResponse', params, arguments);
    }

    const { response, dustLogUtility, methodName } = options;
    const { status, headers, data } = response;

    const isSuccessStatus = [HttpStatus.OK, HttpStatus.ACCEPTED].includes(
        status
    );
    const transactionId = headers.get(REQUEST_ID) as string;

    let isError = status >= 400;
    let isAcceptedErrorCase = false;

    /**
     *
     * @note logs service response and transactionId
     *
     */
    if (dustLogUtility) {
        dustLogUtility.parseResponse({
            response,
            transactionId
        });
    }

    const { errors, isGraphError } = processServerErrors(
        data as ServerErrorData,
        headers,
        isError,
        isSuccessStatus
    );

    if (isGraphError) {
        isError = isGraphError;
    }

    if (isSuccessStatus) {
        isAcceptedErrorCase = checkAcceptedCase(errors);
    }

    if (isError || isAcceptedErrorCase) {
        const exceptionData = handleErrorCase(errors, status, methodName);

        const exception = createException(errors, response, exceptionData);

        if (dustLogUtility?.logTransaction) {
            dustLogUtility.setServiceInteraction({
                response,
                exception
            });
        }

        dustLogUtility?.captureError(exception);

        consoleDebug(errors, exception, response);

        return Promise.reject(exception);
    }

    if (dustLogUtility?.logTransaction) {
        dustLogUtility.setServiceInteraction({ response });
    }

    return Promise.resolve(response);
}
