/**
 *
 * @module qrCodeClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/qrCode.md
 *
 */

/* eslint-disable camelcase */

import { Types, typecheck } from '@dss/type-checking';
import { stringify } from 'query-string';

import Logger from '../../logging/logger';

import appendQuerystring from '../util/appendQuerystring';

import QRCodeClientConfiguration from './qrCodeClientConfiguration';
import QRCodeClientEndpoint from './qrCodeClientEndpoint';

import {
    QRCodeQueryParams,
    QRCodeQueryParamsTypedef
} from '../../qrCode/typedefs';

import { IEndpoint } from '../providers/typedefs';

/**
 *
 * @access protected
 * @since 26.0.0
 * @desc QR Codes are fetched from a backend service called QRCode.
 *
 */
export default class QRCodeClient {
    /**
     *
     * @access private
     * @since 26.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    protected logger: Logger;

    /**
     *
     * @access private
     * @since 26.0.0
     * @type {ServiceClientConfiguration}
     * @desc The configuration information to use.
     *
     */
    public config: QRCodeClientConfiguration;

    /**
     *
     * @param {Object} options
     * @param {SDK.Services.QRCode.QRCodeClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     *
     */
    public constructor(options: {
        config: QRCodeClientConfiguration;
        logger: Logger;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    config: Types.instanceStrict(QRCodeClientConfiguration),
                    logger: Types.instanceStrict(Logger)
                })
            };

            typecheck(this, params, arguments);
        }

        const { logger, config } = options;

        this.logger = logger;
        this.config = config;

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @access public
     * @since 26.0.0
     * @param {QRCodeQueryParams} params
     * @returns {String}
     *
     */
    public getQrCodeUrl(params: QRCodeQueryParams) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const paramObj = {
                params: Types.object(QRCodeQueryParamsTypedef)
            };

            typecheck(this, 'getQrCodeUrl', paramObj, arguments);
        }

        const { size } = params;

        const endpointKey = QRCodeClientEndpoint.main;

        const { endpoints } = this.config;
        const { href } = endpoints[endpointKey] as IEndpoint;

        const url = appendQuerystring(
            href,
            stringify({ ...params, size: `${size}x${size}` })
        );

        return url;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QrCode.QrCodeClient';
    }
}
