/**
 *
 * @module entry
 * @desc {SDK.Services.QualityOfService} entry point.
 *
 */

import ErrorEventData from './errorEventData';
import ExperimentsDetails from './experimentsDetails';
import InterstitialEventData from './interstitialEventData';
import mapHiveToQoeErrorCodes from './mapHiveToQoeErrorCodes';
import PlaybackAdEventData from './playbackAdEventData';
import PlaybackCustomEventData from './playbackCustomEventData';
import PlaybackEventData from './playbackEventData';
import PlaybackHeartbeatEventData from './playbackHeartbeatEventData';
import PlaybackSnapshotEventData from './playbackSnapshotEventData';
import PlaybackStartupEventData from './playbackStartupEventData';
import QoeError from './qoeError';
import ServerRequest from './serverRequest';

import {
    AdActivity,
    AdInsertionType,
    ApplicationContext,
    ApplicationProtocol,
    AudioTrackType,
    BufferType,
    ErrorLevel,
    ErrorSource,
    ExperimentPlatform,
    FetchStatus,
    HeartbeatSampleType,
    HttpMethod,
    InterstitialActivity,
    InterstitialInsertionType,
    InterstitialPlacement,
    InterstitialType,
    MediaFetchError,
    MediaSegmentType,
    NetworkError,
    NetworkType,
    PlaybackActivity,
    PlaybackExitedCause,
    PlaybackIntent,
    PlaybackMode,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackSeekCause,
    PlaybackState,
    PlayerSeekDirection,
    PlaylistBitrateType,
    PodPosition,
    PresentationType,
    ProductType,
    QoePlaybackError,
    SeekDirection,
    SkipType,
    SnapshotDownloadDataType,
    SnapshotDownloadFailure,
    SnapshotEventType,
    SnapshotNextAction,
    StartupActivity,
    TimedTextTrackType
} from './enums';

/**
 *
 * @typedef {Object} SDK.Services.QualityOfService
 *
 * @property {SDK.Services.QualityOfService.AdActivity} AdActivity
 * @property {SDK.Services.QualityOfService.AdInsertionType} AdInsertionType
 * @property {SDK.Services.QualityOfService.AudioTrackType} AudioTrackType
 * @property {SDK.Services.QualityOfService.ApplicationContext} ApplicationContext
 * @property {SDK.Services.QualityOfService.ApplicationProtocol} ApplicationProtocol
 * @property {SDK.Services.QualityOfService.BufferType} BufferType
 * @property {ErrorEventData} ErrorEventData
 * @property {SDK.Services.QualityOfService.ErrorLevel} ErrorLevel
 * @property {SDK.Services.QualityOfService.ErrorSource} ErrorSource
 * @property {SDK.Services.QualityOfService.ExperimentPlatform} ExperimentPlatform
 * @property {ExperimentsDetails} ExperimentsDetails
 * @property {SDK.Services.QualityOfService.FetchStatus} FetchStatus
 * @property {HeartbeatSampleType} HeartbeatSampleType
 * @property {SDK.Services.QualityOfService.HttpMethod} HttpMethod
 * @property {SDK.Services.QualityOfService.InterstitialActivity} InterstitialActivity
 * @property {SDK.Services.QualityOfService.InterstitialEventData} InterstitialEventData
 * @property {SDK.Services.QualityOfService.InterstitialInsertionType} InterstitialInsertionType
 * @property {SDK.Services.QualityOfService.InterstitialPlacement} InterstitialPlacement
 * @property {SDK.Services.QualityOfService.InterstitialType} InterstitialType
 * @property {Function} mapHiveToQoeErrorCodes
 * @property {SDK.Services.QualityOfService.MediaFetchError} MediaFetchError
 * @property {SDK.Services.QualityOfService.MediaSegmentType} MediaSegmentType
 * @property {SDK.Services.QualityOfService.NetworkError} NetworkError
 * @property {SDK.Services.QualityOfService.NetworkType} NetworkType
 * @property {SDK.Services.QualityOfService.PlaybackActivity} PlaybackActivity
 * @property {PlaybackAdEventData} PlaybackAdEventData
 * @property {PlaybackCustomEventData} PlaybackCustomEventData
 * @property {PlaybackEventData} PlaybackEventData
 * @property {SDK.Services.QualityOfService.PlaybackExitedCause} PlaybackExitedCause
 * @property {PlaybackHeartbeatEventData} PlaybackHeartbeatEventData
 * @property {SDK.Services.QualityOfService.PlaybackIntent} PlaybackIntent
 * @property {SDK.Services.QualityOfService.PlaybackMode} PlaybackMode
 * @property {SDK.Services.QualityOfService.PlaybackPausedCause} PlaybackPausedCause
 * @property {SDK.Services.QualityOfService.PlaybackResumedCause} PlaybackResumedCause
 * @property {SDK.Services.QualityOfService.PlaybackSeekCause} PlaybackSeekCause
 * @property {PlaybackSnapshotEventData} PlaybackSnapshotEventData
 * @property {PlaybackStartupEventData} PlaybackStartupEventData
 * @property {SDK.Services.QualityOfService.PlaybackState} PlaybackState
 * @property {SDK.Services.QualityOfService.PlayerSeekDirection} PlayerSeekDirection
 * @property {SDK.Services.QualityOfService.PlaylistBitrateType} PlaylistBitrateType
 * @property {SDK.Services.QualityOfService.PodPosition} PodPosition
 * @property {SDK.Services.QualityOfService.PresentationType} PresentationType
 * @property {SDK.Services.QualityOfService.ProductType} ProductType
 * @property {QoeError} QoeError
 * @property {SDK.Services.QualityOfService.QoePlaybackError} QoePlaybackError
 * @property {SDK.Services.QualityOfService.SeekDirection} SeekDirection
 * @property {SDK.Services.QualityOfService.ServerRequest} ServerRequest
 * @property {SDK.Services.QualityOfService.SkipType} SkipType
 * @property {SDK.Services.QualityOfService.SnapshotDownloadDataType} SnapshotDownloadDataType
 * @property {SDK.Services.QualityOfService.SnapshotDownloadFailure} SnapshotDownloadFailure
 * @property {SDK.Services.QualityOfService.SnapshotEventType} SnapshotEventType
 * @property {SDK.Services.QualityOfService.SnapshotNextAction} SnapshotNextAction
 * @property {SDK.Services.QualityOfService.StartupActivity} StartupActivity
 * @property {SDK.Services.QualityOfService.TimedTextTrackType} TimedTextTrackType
 *
 */
export default {
    ApplicationContext,
    BufferType,
    ErrorEventData,
    ErrorLevel,
    ErrorSource,
    ExperimentPlatform,
    ExperimentsDetails,
    FetchStatus,
    HeartbeatSampleType,
    HttpMethod,
    InterstitialActivity,
    InterstitialEventData,
    InterstitialInsertionType,
    InterstitialPlacement,
    InterstitialType,
    mapHiveToQoeErrorCodes,
    MediaFetchError,
    MediaSegmentType,
    NetworkError,
    NetworkType,
    PlaybackActivity,
    PlaybackAdEventData,
    PlaybackCustomEventData,
    PlaybackEventData,
    PlaybackExitedCause,
    PlaybackHeartbeatEventData,
    PlaybackIntent,
    PlaybackMode,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackSeekCause,
    PlaybackSnapshotEventData,
    PlaybackStartupEventData,
    PlaybackState,
    PlayerSeekDirection,
    PlaylistBitrateType,
    ProductType,
    QoeError,
    QoePlaybackError,
    SeekDirection,
    ServerRequest,
    SkipType,
    StartupActivity,

    AdActivity,
    AdInsertionType,
    ApplicationProtocol,
    AudioTrackType,
    PodPosition,
    PresentationType,
    SnapshotEventType,
    SnapshotNextAction,
    SnapshotDownloadDataType,
    SnapshotDownloadFailure,
    TimedTextTrackType
};
