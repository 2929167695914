/**
 *
 * @module DssHlsPlayerAdapter
 * @desc PlayerAdapter for dss-hls devices like smart tv's
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/media.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/stream-sample.md
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/quality-of-experience.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerEvents.md
 * @see https://github.bamtech.co/fed-core/browser-sdk/blob/main/docs/reference/PlayerProperties.md
 * @see https://github.bamtech.co/dss-hls
 * @see https://github.bamtech.co/dss-hls/dss-hls.js
 * @see https://github.bamtech.co/pages/dss-hls/documentation/
 * @see https://github.bamtech.co/pages/dss-hls/documentation/source/api/events.html
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';

import adEngineRegex from '../../services/util/adEngineRegex';
import parseUrl from '../../services/util/parseUrl';

import PublicEvents from '../../events';
import InternalEvents from '../../internalEvents';
import PlaybackEventListener from '../playbackEventListener';
import DrmProvider from '../../drm/drmProvider';
import PlaybackMetrics from '../playbackMetrics';
import Playlist from '../playlist';
import PlayerAdapter from '../playerAdapter';
import BamHlsErrorMapping from '../bamHlsErrorMapping';

import PlaybackVariant from '../../services/media/playbackVariant';

import { DrmType, PlaylistType } from '../../services/media/enums';

import {
    BufferType,
    ErrorLevel,
    FetchStatus,
    HttpMethod,
    MediaSegmentType,
    PlaybackExitedCause,
    PlaybackPausedCause,
    PlaybackResumedCause,
    PlaybackState,
    PlayerSeekDirection,
    SeekDirection,
    StartupActivity,
    QoePlaybackError
} from '../../services/qualityOfService/enums';

import ServerRequest from '../../services/qualityOfService/serverRequest';
import PlaybackStartupEventData from '../../services/qualityOfService/playbackStartupEventData';
import mapHiveToQoeErrorCodes from '../../services/qualityOfService/mapHiveToQoeErrorCodes';

import { getResolutionString } from './playerAdapterUtils';
import { AUTHORIZATION } from '../../services/providers/shared/httpHeaderConstants';
import {
    createSimpleException,
    createInvalidStateException
} from '../../services/util/errorHandling/createException';

import EventListenerProvider from '../eventListenerProvider';

const HlsStreamEvents = {
    MEDIAELEMENT_STATE_CHANGED: 'MEDIAELEMENT_STATE_CHANGED',
    PRESENTATION_STATE_LOADED: 'PRESENTATION_STATE_LOADED',
    MEDIA_PLAYING: 'MEDIA_PLAYING',
    DRM_LICENSE_REQUESTED: 'DRM_LICENSE_REQUESTED',
    DRM_LICENSE_RECEIVED: 'DRM_LICENSE_RECEIVED',
    CANPLAY: 'CANPLAY',
    SEEKING_STARTED: 'SEEKING_STARTED',
    SEEKABLE_RANGE_CHANGED: 'SEEKABLE_RANGE_CHANGED',
    BUFFERING_STARTED: 'BUFFERING_STARTED',
    BUFFERING_FINISHED: 'BUFFERING_FINISHED',
    SUBTITLE_RENDITION_CHANGED: 'SUBTITLE_RENDITION_CHANGED',
    MULTIVARIANT_PLAYLIST_REQUEST: 'MULTIVARIANT_PLAYLIST_REQUEST',
    MULTIVARIANT_PLAYLIST_FALLBACK: 'MULTIVARIANT_PLAYLIST_FALLBACK',
    MULTIVARIANT_PLAYLIST_LOADED: 'MULTIVARIANT_PLAYLIST_LOADED',
    MULTIVARIANT_PLAYLIST_PARSED: 'MULTIVARIANT_PLAYLIST_PARSED',
    SEEKING_FINISHED: 'SEEKING_FINISHED',
    VIDEO_RENDITION_UPDATED: 'VIDEO_RENDITION_UPDATED',
    AUDIO_RENDITION_UPDATED: 'AUDIO_RENDITION_UPDATED',
    SUBTITLE_RENDITION_UPDATED: 'SUBTITLE_RENDITION_UPDATED',
    AUDIO_RENDITION_CHANGED: 'AUDIO_RENDITION_CHANGED',
    ERROR: 'ERROR',
    SEGMENT_PLAYING: 'SEGMENT_PLAYING',
    SOURCE_BUFFER_APPEND_STARTED: 'SOURCE_BUFFER_APPEND_STARTED',
    CONTENT_DOWNLOAD_FINISHED: 'CONTENT_DOWNLOAD_FINISHED',
    PLAYBACK_METRICS_CHANGED: 'PLAYBACK_METRICS_CHANGED',
    NETWORK_METRICS_CHANGED: 'NETWORK_METRICS_CHANGED',
    INTERSTITIAL_SESSION_REQUESTED: 'INTERSTITIAL_SESSION_REQUESTED',
    INTERSTITIAL_SESSION_FETCHED: 'INTERSTITIAL_SESSION_FETCHED',
    INTERSTITIAL_SESSION_STARTED: 'INTERSTITIAL_SESSION_STARTED',
    INTERSTITIAL_SESSION_FINISHED: 'INTERSTITIAL_SESSION_FINISHED',
    INTERSTITIAL_MULTIVARIANT_FETCHED: 'INTERSTITIAL_MULTIVARIANT_FETCHED',
    INTERSTITIAL_VARIANT_FETCHED: 'INTERSTITIAL_VARIANT_FETCHED',
    INTERSTITIAL_ASSET_STARTED: 'INTERSTITIAL_ASSET_STARTED',
    INTERSTITIAL_ASSET_FINISHED: 'INTERSTITIAL_ASSET_FINISHED',
    BEACON_ERROR: 'BEACON_ERROR',
    INTERSTITIAL_SESSION_REQUESTED_ERROR:
        'INTERSTITIAL_SESSION_REQUESTED_ERROR',
    VIDEO_SEGMENT_LOADED: 'VIDEO_SEGMENT_LOADED',
    MEDIA_PLAYLIST_REQUEST: 'MEDIA_PLAYLIST_REQUEST',
    MEDIA_PLAYLIST_LOADED: 'MEDIA_PLAYLIST_LOADED',
    STREAM_PLAYBACKSTATE_CHANGED: 'STREAM_PLAYBACKSTATE_CHANGED',
    SUBTITLES_RENDITION_UPDATED: 'SUBTITLES_RENDITION_UPDATED',
    MASTER_PLAYLIST_REQUEST: 'MASTER_PLAYLIST_REQUEST',
    MASTER_PLAYLIST_FALLBACK: 'MASTER_PLAYLIST_FALLBACK',
    MASTER_PLAYLIST_LOADED: 'MASTER_PLAYLIST_LOADED',
    VARIANT_UPDATED: 'VARIANT_UPDATED'
} as const;

const HlsStreamMediaElementStates = {
    DETACHING: 0,
    DETACHED: 1,
    ATTACHING: 2,
    ATTACHED: 3,
    ENDED: 4,
    PLAYING: 5,
    PAUSED: 6,
    BUFFERING: 7,
    SEEKING: 8
} as const;

const HlsStreamStates = {
    UNLOADED: 'UNLOADED',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FETCHING: 'FETCHING',
    PLAYBACK: 'PLAYBACK'
} as const;

const HlsStreamDataTypes = {
    KEY: 'KEY',
    CHUNK: 'CHUNK',
    MULTIVARIANT_PLAYLIST: 'MULTIVARIANT_PLAYLIST',
    MEDIA_PLAYLIST_VIDEO: 'MEDIA_PLAYLIST_VIDEO',
    MEDIA_PLAYLIST_AUDIO: 'MEDIA_PLAYLIST_AUDIO',
    MEDIA_PLAYLIST_SUBTITLES: 'MEDIA_PLAYLIST_SUBTITLES',
    MEDIA_SEGMENT_VIDEO: 'MEDIA_SEGMENT_VIDEO',
    MEDIA_SEGMENT_AUDIO: 'MEDIA_SEGMENT_AUDIO',
    MEDIA_SEGMENT_SUBTITLES: 'MEDIA_SEGMENT_SUBTITLES',
    INIT_SECTION_VIDEO: 'INIT_SECTION_VIDEO',
    INIT_SECTION_AUDIO: 'INIT_SECTION_AUDIO',
    INIT_SECTION_SUBTITLES: 'INIT_SECTION_SUBTITLES',
    DRM_LICENSE: 'DRM_LICENSE',
    AD_POD: 'AD_POD'
} as const;

const HlsStreamAssetTypes = {
    AD: 'ad',
    CONTENT: 'content',
    BUMPER: 'bumper',
    SLUG: 'slug',
    CONTENT_PROMO: 'promo'
} as const;

const HlsStreamAssetIds = {
    MAIN_CONTENT: 'main-content'
} as const;

const HlsPlaybackStates = {
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    BUFFERING: 'BUFFERING',
    ENDED: 'ENDED',
    SEEKING: 'SEEKING',
    NOTREADY: 'NOTREADY'
} as const;

export type HlsStream = {
    Events: typeof HlsStreamEvents;
    States: typeof HlsStreamStates;
    MediaElementStates: typeof HlsStreamMediaElementStates;
    DataTypes: typeof HlsStreamDataTypes;
    AssetTypes: typeof HlsStreamAssetTypes;
    AssetIds: typeof HlsStreamAssetIds;
    PlaybackStates: typeof HlsPlaybackStates;
};

type EventDataPlaylist = {
    priority: number;
    url: string;
    primaryContent: {
        tracking: {
            conviva: Record<string, string>;
            telemetry: Record<string, string>;
            adEngine: Record<string, string>;
            qos: Record<string, string>;
        };
    };
};

/**
 *
 * @since 4.4.0
 * @desc Interface used to communicate with the media player.
 *
 */
export default class DssHlsPlayerAdapter extends PlayerAdapter<DssHlsNativePlayer> {
    /**
     *
     * @access private
     * @since 10.0.0
     * @type {Number|undefined}
     * @desc Value of the `PROGRAM-DATE-TIME` of the last (most recent) seek-able segment + segment length in
     * epoch millis i.e. end of last segment. Used to support live latency calculation.
     * @note This is stored here as an intermediary due to the data being returned in one event but being needed in others.
     *
     */
    private seekableRangeEndProgramDateTime?: number | undefined;

    /**
     *
     * @access private
     * @since 8.0.0
     * @type {SDK.Services.QualityOfService.PlaybackStartupEventData}
     * @desc Keeps track of CDN fallback for QoE support.
     *
     */
    private playbackStartupEventData: PlaybackStartupEventData;

    /**
     *
     * @access private
     * @since 11.0.0
     * @type {Object}
     * @desc Keeps track of CDN fallback QoE tracking information.
     *
     */
    private qos: Record<string, string>;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {Number|null}
     * @desc Time when rebufferingStartedEvent is triggered. Used to calculate duration for rebufferingEndedEvent.
     *
     */
    private rebufferingDuration: Nullable<number>;

    /**
     *
     * @access public
     * @since 14.0.0
     * @type {Array<SDK.Drm.DrmProvider>}
     * @desc Set of DRM providers
     *
     */
    public drmProviders: Array<
        DrmProvider & {
            individualizationUri?: string;
            getFairPlayCertificate?: Noop<undefined>;
            getWidevineCertificate?: Noop<undefined>;
            getNagraCertificate?: Noop<undefined>;
        }
    >;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {HlsStream}
     *
     */
    private hlsStatic: HlsStream;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {HlsStream.Events}
     * @note Events enum
     *
     */
    private Events: typeof HlsStreamEvents;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {HlsStream.States}
     * @note States Enum
     *
     */
    private States: typeof HlsStreamStates;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {HlsStream.MediaElementStates}
     * @note MediaElementStates Enum
     *
     */
    private MediaElementStates: typeof HlsStreamMediaElementStates;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {HlsStream.PlaybackStates}
     * @note States Enum
     *
     */
    private PlaybackStates: typeof HlsPlaybackStates;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {HlsStream.DataTypes}
     * @note DataTypes Enum
     *
     */
    private DataTypes: typeof HlsStreamDataTypes;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {Boolean}
     * @desc Indicates when player is buffering.
     *
     */
    private isBuffering: boolean;

    /**
     *
     * @access private
     * @since 14.0.0
     * @type {Boolean}
     * @desc Indicates when application is backgrounded or foregrounded.
     *
     */
    private isApplicationBackgrounded: boolean;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number|null}
     * @desc current playing segment from the `SEGMENT_PLAYING` event.
     * @note eventData.segment.programDateTimeStart.
     *
     */
    private segmentPosition: Nullable<number>;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {Number|null}
     * @desc the starting bitrate for the media content.
     *
     */
    private mediaStartBitrate: Nullable<number>;

    /**
     *
     * @access private
     * @since 15.0.0
     * @type {MediaSegmentType|null}
     * @desc the starting bitrate for the media content.
     *
     */
    private mediaSegmentType: Nullable<MediaSegmentType>;

    /**
     *
     * @access private
     * @since 15.1.0
     * @type {Number}
     * @desc the total number of media bytes downloaded.
     *
     */
    private mediaBytesDownloaded: number;

    /**
     *
     * @access private
     * @since 15.2.0
     * @type {BufferType|undefined}
     * @desc Used to store buffer type for rebuffering ended.
     *
     */
    private bufferType?: BufferType | undefined;

    /**
     *
     * @access private
     * @since 15.2.3
     * @type {Boolean}
     * @desc A flag to keep track of when the player is ready.
     *
     */
    private isReady: boolean;

    /**
     *
     * @access private
     * @since 16.1.0
     * @type {Object}
     * @desc Cached values use for the heartbeat event that are updated
     * when specific events fire.
     *
     */
    private heartbeatData: Record<string, unknown>;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Number}
     * @desc Used to track total time of media downloaded (gets reset with each heartbeat).
     *
     */
    public override mediaDownloadTotalTime: number;

    /**
     *
     * @access public
     * @since 16.1.0
     * @type {Number}
     * @desc Used to track how many times media gets downloaded (gets reset with each heartbeat).
     *
     */
    public override mediaDownloadTotalCount: number;

    /**
     *
     * @access private
     * @since 20.0.1
     * @type {Number|undefined}
     * @desc used to indicate the current bitrateAvg for the chosen variant
     *
     */
    private bitrateAvg?: Nullable<number> | undefined;

    /**
     *
     * @param {Object} options
     * @param {NativePlayer} options.nativePlayer An instance of DssHls.js<HlsStream>
     * @param {String} options.videoPlayerName
     * @param {String} options.videoPlayerVersion
     * @note The DssHlsPlayerAdapter requires nativePlayer.on && nativePlayer.off
     *
     */
    public constructor(options: {
        nativePlayer: DssHlsNativePlayer;
        videoPlayerName: string;
        videoPlayerVersion: string;
    }) {
        super(options);

        this.seekableRangeEndProgramDateTime = undefined;

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        this.qos = {};
        this.rebufferingDuration = null;
        this.drmProviders = [];
        this.hlsStatic = this.nativePlayer?.getClass() as HlsStream;
        this.Events = this.hlsStatic.Events;
        this.States = this.hlsStatic.States;
        this.MediaElementStates = this.hlsStatic.MediaElementStates;
        this.PlaybackStates = this.hlsStatic.PlaybackStates;
        this.DataTypes = this.hlsStatic.DataTypes;
        this.isBuffering = false;
        this.isApplicationBackgrounded = false;
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.mediaBytesDownloaded = 0;
        this.bufferType = undefined;
        this.isReady = false;
        this.heartbeatData = {};
        this.mediaDownloadTotalTime = 0;
        this.mediaDownloadTotalCount = 0;
        this.bitrateAvg = 0;
        this.offHandlerRemovalList = [];
    }

    /**
     *
     * @access public
     * @since 7.0.0
     * @param {SDK.Media.Playlist} playlist - The playlist to be used during playback.
     * @desc Callback used when prepare has been called (usually via the {PlaybackSession}).
     * Sets the source URI on the {NativePlayer} instance.
     * @returns {Promise<Void>}
     *
     */
    public override async setSource(playlist: Playlist) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                playlist: Types.instanceStrict(Playlist)
            };

            typecheck(this, 'setSource', params, arguments);
        }

        const { cdnFallback } = this;

        // only attempt to assign this if the user hasn't already done so when initializing bam-hls
        if (Check.not.assigned(this.nativePlayer?.setupXhrCallback)) {
            this.setupXhrCallback();
        }

        // `playlistUri` can be either a string or array of objects for backwards compatibility purposes.
        this.playlistUri = playlist.streamUri;
        this.currentStreamUrl = playlist.streamUri;

        if (
            this.nativePlayer?.startFailureSaveSupported &&
            cdnFallback.isEnabled
        ) {
            this.playlistUri = playlist.mediaSources;
            this.nativePlayer.config = {
                bufferingTimeoutStart: cdnFallback.defaultTimeout
                    ? cdnFallback.defaultTimeout * 1000
                    : 90000,
                requestStartContentNetworkFallbackLimit:
                    cdnFallback.fallbackLimit || 5
            };
        }
    }

    /**
     *
     * @access public
     * @desc Resets player adapter state. Removes all playback listeners.
     * @returns {Void}
     *
     */
    public override clean() {
        const { listener } = this;

        super.clean();

        this.playlistUri = '';
        this.drmProvider = null;
        this.drmProviders = [];
        this.segmentPosition = null;
        this.mediaStartBitrate = null;
        this.mediaSegmentType = null;
        this.qos = {};
        this.mediaBytesDownloaded = 0;
        this.isBuffering = false;
        this.isReady = false;
        this.heartbeatData = {};
        this.mediaDownloadTotalCount = 0;
        this.mediaDownloadTotalTime = 0;
        this.bitrateAvg = 0;

        this.playbackStartupEventData = new PlaybackStartupEventData({
            startupActivity: StartupActivity.reattempt
        });

        this.removeListener(listener);
    }

    /**
     *
     * @access public
     * @desc Completes the cleanup process by completely cleaning up all `PlayerAdapter`
     * references. This method should be executed by the application developer
     * when they no longer need to use a `PlayerAdapter` instance.
     * @note Because `clean` is automatically called by the `PlaybackSession` instance,
     * another method is necessary to handle the use case of an application developer who
     * wants to continue using a `PlayerAdapter` instance after a `PlaybackSession` has been released.
     * @returns {Void}
     *
     */
    public dispose() {
        this.clean();

        this.nativePlayer = null;
        this.accessToken = null;
    }

    /**
     *
     * @access public
     * @since 14.0.0
     * @desc Sets the application state flag to backgrounded.
     *
     */
    public setApplicationBackgrounded() {
        this.isApplicationBackgrounded = true;
    }

    /**
     *
     * @access public
     * @since 14.0.0
     * @desc Sets the application state flag to foregrounded.
     *
     */
    public setApplicationForegrounded() {
        this.isApplicationBackgrounded = false;
    }

    /**
     *
     * @access public
     * @since 8.0.0
     * @param {Object} [options]
     * @param {Object} [options.eventData]
     * @param {Boolean} [options.isEnded]
     * @note Handles the PLAYBACK_ENDED events
     * @note eventData is internal use only when called via playbackErrorEvent, should be undefined otherwise.
     *
     */
    public async playbackEndedEvent(options: {
        eventData?: { errorName?: string; errorDetail?: string };
        isEnded?: boolean;
    }) {
        const { eventData, isEnded } = options || {};
        const { playbackStartupEventData } = this;
        const { errorName: playbackError, errorDetail: playbackErrorDetail } =
            eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (Check.assigned(eventData)) {
            cause = PlaybackExitedCause.error;
        } else if (isEnded) {
            cause = PlaybackExitedCause.playedToEnd;
        } else {
            cause = PlaybackExitedCause.user;
        }

        const playbackEndedEvent: Record<string, unknown> = {
            ...playbackData,
            cause,
            playbackError,
            playbackErrorDetail
        };

        playbackEndedEvent.cdnRequestedTrail =
            playbackStartupEventData.cdnRequestedTrail;
        playbackEndedEvent.cdnFailedTrail =
            playbackStartupEventData.cdnFailedTrail;
        playbackEndedEvent.cdnFallbackCount =
            playbackStartupEventData.cdnFallbackCount;
        playbackEndedEvent.isCdnFallback =
            playbackStartupEventData.isCdnFallback;

        // case: Playback has ended and the rebufferingEndedEvent did not fire for an unknown reason.
        if (this.isBuffering) {
            this.rebufferingEndedEvent();
        }

        await this.onPlaybackEnded(playbackEndedEvent);
    }

    /**
     *
     * @access protected
     * @since 4.9.0
     * @param {PlaybackEventListener} listener - The instance of the `PlaybackEventListener` to use.
     * @desc Attaches handlers to player events.
     * @throws {SDK.Services.Exception.ServiceException} Unable to add PlaybackEventListener
     * @emits {SDK.Events.MediaFailure} Occurs when there was an error requesting a DRM license or certificate which will result in playback failure.
     * @returns {Void}
     * @note messageDetailed is the error message pre-formatted with error, message and cause
     * from bam-hls ERROR event
     *
     */
    public override addListener(listener: PlaybackEventListener) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                listener: Types.instanceStrict(PlaybackEventListener)
            };

            typecheck(this, 'addListener', params, arguments);
        }

        const { nativePlayer, Events } = this;

        const {
            STREAM_PLAYBACKSTATE_CHANGED,
            PRESENTATION_STATE_LOADED,
            MEDIA_PLAYING,
            DRM_LICENSE_RECEIVED,
            CANPLAY,
            SEEKING_STARTED,
            NETWORK_METRICS_CHANGED,
            SEEKABLE_RANGE_CHANGED,
            BUFFERING_STARTED,
            BUFFERING_FINISHED,
            SUBTITLES_RENDITION_UPDATED,
            MASTER_PLAYLIST_REQUEST,
            MASTER_PLAYLIST_FALLBACK,
            MASTER_PLAYLIST_LOADED,
            SEEKING_FINISHED,
            VARIANT_UPDATED,
            AUDIO_RENDITION_UPDATED,
            ERROR,
            SEGMENT_PLAYING,
            SOURCE_BUFFER_APPEND_STARTED,
            CONTENT_DOWNLOAD_FINISHED
        } = Events;

        if (nativePlayer && nativePlayer.on) {
            this.listener = listener;
            this.eventListenerProvider = new EventListenerProvider({
                onHandler: nativePlayer.on,
                onceHandler: nativePlayer.once,
                offHandler: nativePlayer.off,
                logger: this.logger
            });

            this.eventListenerProvider.addEventHandler(
                this,
                PRESENTATION_STATE_LOADED,
                this.playbackInitializedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                STREAM_PLAYBACKSTATE_CHANGED,
                this.playbackStateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                NETWORK_METRICS_CHANGED,
                this.bitrateChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                BUFFERING_STARTED,
                this.rebufferingStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                BUFFERING_FINISHED,
                this.rebufferingEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SUBTITLES_RENDITION_UPDATED,
                this.subtitleChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                AUDIO_RENDITION_UPDATED,
                this.audioChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MASTER_PLAYLIST_LOADED,
                this.multivariantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MASTER_PLAYLIST_LOADED,
                this.successfulPlaylistLoad
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MASTER_PLAYLIST_REQUEST,
                this.multivariantPlaylistRequest
            );
            this.eventListenerProvider.addEventHandler(
                this,
                MASTER_PLAYLIST_FALLBACK,
                this.multivariantPlaylistFallback
            );
            this.eventListenerProvider.addEventHandler(
                this,
                VARIANT_UPDATED,
                this.variantPlaylistFetchedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SEEKING_STARTED,
                this.playbackSeekStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SEEKING_FINISHED,
                this.playbackSeekEndedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                ERROR,
                this.playbackErrorEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SEGMENT_PLAYING,
                this.segmentPlayingEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SOURCE_BUFFER_APPEND_STARTED,
                this.sourceBufferAppendStartedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                CONTENT_DOWNLOAD_FINISHED,
                this.contentDownloadFinishedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                SEEKABLE_RANGE_CHANGED,
                this.seekableRangeChangedEvent
            );
            this.eventListenerProvider.addEventHandler(
                this,
                DRM_LICENSE_RECEIVED,
                this.drmKeyFetchedEvent
            );

            // *********************************
            // below this are .once(...) handlers
            this.eventListenerProvider.addEventHandlerOnce(
                this,
                CANPLAY,
                this.playbackReadyEvent
            );
            this.eventListenerProvider.addEventHandlerOnce(
                this,
                MEDIA_PLAYING,
                this.playbackStartedEvent
            );
        } else {
            throw createInvalidStateException(
                `${this.toString()}.addListener(listener) unable to add PlaybackEventListener`
            );
        }
    }

    /**
     *
     * @access protected
     * @since 4.4.0
     * @param {Array<SDK.Drm.DrmProvider>} drmProviders
     * @note filters out unsupported drm keys before creating the drm configuration object
     * @returns {Promise<Void>}
     *
     */
    public async setDrmProviders(drmProviders: Array<DrmProvider>) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                drmProviders: Types.array.of.instanceStrict(DrmProvider)
            };

            typecheck(this, 'setDrmProviders', params, arguments);
        }

        const { nativePlayer } = this;

        this.drmProviders = drmProviders;

        const drmCapabilities = await nativePlayer?.getDrmCapabilities();

        const { keySystems = {} } = drmCapabilities || {};

        let drmProvidersIndex = drmProviders.length;

        while (drmProvidersIndex--) {
            const type = drmProviders[drmProvidersIndex].type;
            const provider =
                type === DrmType.PRMNAGRA ? keySystems.NAGRA : keySystems[type];

            if (!provider?.supported ?? true) {
                drmProviders.splice(drmProvidersIndex, 1);
            }
        }

        (nativePlayer as DssHlsNativePlayer).drmConfiguration =
            this.setDrmConfiguration();
    }

    /**
     *
     * @access private
     * @since 4.4.0
     * @desc The DRM Configuration contains an array of key system configurations, one for
     * each supported key system. At a minimum, the key system configuration specifies
     * the key system, and a method to acquire a license, either a uri or a callback function.
     *
     * If a callback is provided, then the license request uri is optional, and would be available to the callback.
     * If no callback is provided, then the licenseRequestUri is not optional.
     *
     * @note licenseRequestHeaders expects a name/value set,
     * i.e. [{name: 'Content-Type', value: 'application/octet-stream'}]
     * @note serverCertificate is mandatory for FAIRPLAY and recommended for WIDEVINE
     * @note serverCertificate is expected to be a Promise<BufferSource> or BufferSource
     * @note DssHls expects the keySystems[x].keySystem value to be `NAGRA` instead of `PRMNAGRA`
     * @see https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/API.md#keysystemconfiguration
     * @returns {Object<Array>}
     *
     */
    public setDrmConfiguration() {
        const { drmProviders } = this;

        const providerImplementations = {
            [DrmType.FAIRPLAY]: {
                keySystem: DrmType.FAIRPLAY,
                getCertificate: (
                    drmProvider: DssHlsPlayerAdapter['drmProviders'][0]
                ) => drmProvider.getFairPlayCertificate?.()
            },
            [DrmType.WIDEVINE]: {
                keySystem: DrmType.WIDEVINE,
                getCertificate: (
                    drmProvider: DssHlsPlayerAdapter['drmProviders'][0]
                ) => drmProvider.getWidevineCertificate?.()
            },
            [DrmType.PRMNAGRA]: {
                keySystem: 'NAGRA',
                getCertificate: (
                    drmProvider: DssHlsPlayerAdapter['drmProviders'][0]
                ) => drmProvider.getNagraCertificate?.()
            }
        };

        const keySystems = drmProviders.map((drmProvider) => {
            const keySystemObj = {
                individualizationUri: drmProvider.individualizationUri,
                keySystem: drmProvider.type,
                licenseRequestUri: drmProvider.licenseRequestUri,
                licenseRequestHeadersCallback: () =>
                    drmProvider.formatRequestHeadersList(
                        drmProvider.processLicenseRequestHeaders()
                    ),
                serverCertificate: Promise.resolve(undefined)
            };

            const providerImplementation =
                providerImplementations[
                    drmProvider.type as keyof typeof providerImplementations
                ];

            if (providerImplementation) {
                const { keySystem, getCertificate } = providerImplementation;

                keySystemObj.keySystem = keySystem as DrmType;
                keySystemObj.serverCertificate = (async () => {
                    return getCertificate(drmProvider);
                })();
            }

            return keySystemObj;
        });

        return {
            keySystems
        };
    }

    /**
     *
     * @access protected
     * @since 10.0.0
     * @desc Gets a snapshot of information about media playback.
     * @returns {SDK.Media.PlaybackMetrics} - instance that contains a snapshot of information about media playback.
     * @note metric value is rounded down to prevent possible service issues with floats
     * @note executed by {PlaybackTelemetryDispatcher#recordStreamSample}
     * @note (chosenBitRate / 1000) - need to convert to Kbps
     * @note `Math.floor(null)` will result in 0 so a check is needed for what is being
     * passed into the floor function to protect against bad data.
     *
     */
    public override getPlaybackMetrics() {
        const { nativePlayer } = this;
        const { currentPDT, isLive, isBehindLive } =
            nativePlayer as DssHlsNativePlayer;

        let bufferSegmentDuration;
        let liveLatencyAmount = 0;

        const isLiveEdge = isLive ? !isBehindLive : false;
        const {
            currentTime,
            bufferedRange = {} as Record<string, number>,
            seekableRange
        } = (nativePlayer as DssHlsNativePlayer).getPlaybackMetrics();
        const { chosenBitrate, chosenMaxBitrate, throughput } = (
            nativePlayer as DssHlsNativePlayer
        ).getNetworkMetrics();

        const currentPlayhead = currentTime * 1000;
        const chosenBitrateKbps = chosenBitrate / 1000; // divide by 1000 to convert to Kbps
        const currentBitrateAvg = chosenBitrate;
        const currentBitratePeak = chosenMaxBitrate;

        if (bufferedRange.end - currentTime > 0) {
            bufferSegmentDuration = bufferedRange.end - currentTime;
        }

        if (isBehindLive && seekableRange.pdtEnd - currentPDT > 0) {
            liveLatencyAmount = seekableRange.pdtEnd - currentPDT;
        }

        return new PlaybackMetrics({
            currentBitrate: chosenBitrateKbps,
            currentPlayhead,
            currentBitrateAvg,
            currentBitratePeak,
            currentThroughput: throughput,
            playheadProgramDateTime: currentPDT,
            seekableRangeEndProgramDateTime: seekableRange.pdtEnd,
            isLiveEdge,
            bufferSegmentDuration,
            mediaBytesDownloaded: this.mediaBytesDownloaded,
            playbackState: this.getPlaybackState(),
            segmentPosition: currentPDT,
            liveLatencyAmount,
            maxAllowedVideoBitrate: this.getMaxAllowedVideoBitrate()
        });
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {String}
     *
     */
    public override getCdnName() {
        const cdnName = this.qos.cdnName;

        return Check.string(cdnName) ? cdnName : 'null';
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Number}
     *
     */
    public override getAudioBitrate() {
        const { audioRenditions = [], currentAudioRenditionIndex } = this
            .nativePlayer as DssHlsNativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] ||
            ({} as Record<string, number>);

        return audioRendition.averageBitrate;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @returns {Number}
     *
     */
    public override getMaxAllowedVideoBitrate() {
        const variants = this.nativePlayer?.variants;
        const maxVariant = variants?.[variants.length - 1];

        return maxVariant?.peakBitrate;
    }

    /**
     *
     * @access private
     * @since 5.0.0
     * @note Handles the MEDIA_PLAYING event. This uses the nativePlayer.once to make sure that this QoE
     * event is only posted once. The MEDIA_PLAYING event will fire every time media is started, even after pausing, so
     * we only want this QoE event to post after the first time MEDIA_PLAYING fires and then disregard it thereafter
     * for this playback session.
     *
     */
    private playbackStartedEvent() {
        const playbackData = this.getPlaybackData();

        this.onPlaybackStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_LOADED event
     *
     */
    private multivariantPlaylistFetchedEvent(eventData: {
        url?: string;
        playlist?: EventDataPlaylist;
    }) {
        const { isLive, isSlidingWindow } = this
            .nativePlayer as DssHlsNativePlayer;

        if (Check.object(eventData)) {
            const url = eventData.playlist?.url || eventData.url;
            const priority = eventData.playlist?.priority || 1;
            const playbackStartupData = this.getPlaybackStartupData();
            const playlistLiveType =
                isLive && isSlidingWindow
                    ? PlaylistType.SLIDE
                    : PlaylistType.COMPLETE;
            const { host, path } = parseUrl(url);

            const serverRequest = new ServerRequest({
                host,
                path,
                status: FetchStatus.completed,
                method: HttpMethod.get
            });

            this.emit(InternalEvents.UpdateAdEngine, priority);

            this.onMultivariantPlaylistFetched({
                ...playbackStartupData,
                mediaStartBitrate: this.mediaStartBitrate,
                playlistLiveType,
                serverRequest
            });
        }
    }

    /**
     *
     * @access private
     * @since 4.0.0
     * @param {Object} [eventData]
     * @note Handles the VARIANT_UPDATED event
     * @note The VariantPlaylistFetchedEventData.serverRequest.status is only set if there were no errors loading
     * the variant, e.g. nativePlayer.variants[eventData.level].loadError = 0. The status is not set if loadError > 0
     * because the nativePlayer does not expose the reason for the errors.
     *
     */
    private variantPlaylistFetchedEvent(eventData: {
        details?: {
            url?: string;
            audioRendition?: {
                channels?: string;
                name?: string;
                language?: string;
            };
        };
    }) {
        const { details = {} } = eventData || {};
        const { url, audioRendition = {} } = details;
        const { host, path } = parseUrl(url);
        const { currentPlayhead } = this.getPlaybackMetrics();

        const {
            channels: playlistChannels,
            name: playlistName,
            language: playlistLanguage
        } = audioRendition;

        const {
            averageBitrate: playlistAverageBandwidth,
            bitrate: playlistBandwidth,
            resolution
        } = this.getCurrentVariant();

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onVariantPlaylistFetched({
            ...eventData,
            playlistAverageBandwidth,
            playlistBandwidth,
            playlistChannels,
            playlistName,
            playlistLanguage,
            playlistResolution: resolution as string,
            serverRequest,
            playheadPosition: currentPlayhead as number
        });
    }

    /**
     *
     * @access private
     * @since 4.7.0
     * @desc Handles the CANPLAY event
     *
     */
    private playbackReadyEvent() {
        const { mediaStartBitrate } = this;
        const playbackStartupData = this.getPlaybackStartupData();

        this.onPlaybackReady({
            ...playbackStartupData,
            mediaStartBitrate,
            readyPlayerStartTime: 0,
            readyPlayerDuration: 0
        });

        this.isReady = true;
    }

    /**
     *
     * @access private
     * @since 4.9.0
     * @param {Object} eventData
     * @desc Handles the STREAM_PLAYBACKSTATE_CHANGED event
     *
     */
    private playbackStateChangedEvent(eventData: {
        playbackState: string;
        lastPlaybackState: string;
    }) {
        const { PlaybackStates } = this;
        const { playbackState, lastPlaybackState } = eventData || {};

        const playbackData = this.getPlaybackData();

        let cause;

        if (
            lastPlaybackState === PlaybackStates.PAUSED &&
            playbackState === PlaybackStates.PLAYING
        ) {
            cause = PlaybackResumedCause.user; // resuming playback is always caused by the user

            this.onPlaybackResumed({
                ...playbackData,
                cause
            });
        }

        if (
            lastPlaybackState === PlaybackStates.PLAYING &&
            playbackState === PlaybackStates.PAUSED
        ) {
            if (this.isApplicationBackgrounded) {
                cause = PlaybackPausedCause.applicationBackgrounded;
            } else if (this.isBuffering) {
                cause = PlaybackPausedCause.stall;
            } else {
                cause = PlaybackPausedCause.user;
            }

            this.onPlaybackPaused({
                ...playbackData,
                cause
            });
        }
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @desc Triggered when playback is initialized.
     * @note Handles the PRESENTATION_STATE_LOADED event
     *
     */
    private playbackInitializedEvent() {
        const playbackStartupData = this.getPlaybackStartupData();

        const {
            variant,
            audio = {} as AudioRenditionType,
            subtitle = {} as SubtitleRenditionType
        } = playbackStartupData;

        // Cache values to be used for heartbeat event.
        this.heartbeatData = {
            playlistAudioChannels: variant.audioChannels,
            playlistAudioCodec: variant.audioCodec,
            playlistAudioLanguage: audio.language,
            playlistAudioName: audio.name,
            playlistSubtitleLanguage: subtitle.language,
            playlistSubtitleName: subtitle.name,
            subtitleVisibility: subtitle.forced
        };

        this.onPlaybackInitialized(playbackStartupData);
    }

    /**
     *
     * @access private
     * @since 8.0.0
     * @param {Object} eventData
     *
     */
    private drmKeyFetchedEvent(eventData: { url?: string; keyID: string }) {
        const playbackStartupData = this.getPlaybackStartupData();

        const { url = '', keyID: drmKeyId } = eventData || {};
        const { host, path } = parseUrl(url);

        const serverRequest = new ServerRequest({
            host,
            path,
            status: FetchStatus.completed,
            method: HttpMethod.get
        });

        this.onDrmKeyFetched({
            ...playbackStartupData,
            drmKeyId,
            serverRequest,
            fetchLicenseStartTime: 0,
            fetchLicenseDuration: 0
        });
    }

    /**
     *
     * @access private
     * @since 7.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_LOADED event to update tracking when successful playlist loads.
     *
     */
    private successfulPlaylistLoad(eventData: {
        playlist?: EventDataPlaylist;
    }) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.currentStreamUrl = playlist.url;

            if (playlist.primaryContent.tracking) {
                this.onSuccessfulPlaylistLoad({
                    priority: playlist.priority,
                    url: playlist.url,
                    tracking: playlist.primaryContent.tracking
                });
            }
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_REQUEST event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistRequest(eventData: {
        playlist?: EventDataPlaylist;
    }) {
        const { playbackStartupEventData } = this;
        const { playlist } = eventData || {};

        if (playlist?.primaryContent) {
            const qosData = playlist.primaryContent.tracking.qos;
            const cdnVendor = qosData ? qosData.cdnVendor : null;

            this.qos = qosData;

            playbackStartupEventData.fallbackAttempt(cdnVendor);

            if (playbackStartupEventData.isCdnFallback) {
                playbackStartupEventData.setQosData(qosData);
                this.onPlaybackReattempt(playbackStartupEventData);
            }
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the MASTER_PLAYLIST_FALLBACK event to update tracking when successful playlist loads.
     *
     */
    private multivariantPlaylistFallback(eventData: {
        playlist?: EventDataPlaylist;
    }) {
        const { playlist } = eventData || {};

        if (playlist) {
            this.playbackStartupEventData.playbackError =
                QoePlaybackError.unknown;
            this.playbackStartupEventData.fallbackFailed();
        }
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_STARTED event
     *
     */
    private playbackSeekStartedEvent(eventData: {
        currentTime?: number;
        targetPosition?: number;
    }) {
        const { isLive } = this.nativePlayer as DssHlsNativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause, seekSize } = this.seekData || {};
        const playbackMetrics = this.getPlaybackMetrics();
        const seekDistance =
            Math.abs(Math.floor(targetPosition - currentTime)) * 1000; // coverts seekDistance to milliseconds.

        let playerSeekDirection;
        let seekDirection;

        if (seekSize && seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekStarted({
            ...eventData,
            playerSeekDirection,
            seekSize,
            seekDistance,
            playheadPosition: playbackMetrics.currentPlayhead as number,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the SEEKING_FINISHED event
     *
     */
    private playbackSeekEndedEvent(eventData: {
        currentTime?: number;
        targetPosition?: number;
    }) {
        const { isLive } = this.nativePlayer as DssHlsNativePlayer;
        const { currentTime = 0, targetPosition = 0 } = eventData || {};
        const { seekCause: cause, seekSize } = this.seekData || {};
        const { currentPlayhead } = this.getPlaybackMetrics();
        const seekDistance =
            Math.abs(Math.floor(targetPosition - currentTime)) * 1000; // coverts seekDistance to milliseconds.

        let playerSeekDirection;
        let seekDirection;

        if (seekSize && seekSize < 0) {
            playerSeekDirection = PlayerSeekDirection.backward;
            seekDirection = isLive ? SeekDirection.fromLiveEdge : undefined;
        } else if (seekSize === 0) {
            playerSeekDirection = PlayerSeekDirection.same;
        } else {
            playerSeekDirection = PlayerSeekDirection.forward;
            seekDirection = isLive ? SeekDirection.toLiveEdge : undefined;
        }

        this.onPlaybackSeekEnded({
            ...eventData,
            playerSeekDirection,
            seekDistance,
            playheadPosition: currentPlayhead as number,
            cause,
            seekDirection
        });
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} eventData
     * @note Handles the ERROR event
     *
     */
    private playbackErrorEvent(eventData: {
        fatal?: boolean;
        message: string;
        error: string;
        cause: keyof typeof BamHlsErrorMapping;
    }) {
        const { fatal = false, message: errorDetail } = eventData || {};
        const { currentTime } = (
            this.nativePlayer as DssHlsNativePlayer
        ).getPlaybackMetrics();
        const { currentPDT: segmentPosition } = this
            .nativePlayer as DssHlsNativePlayer;
        const playheadPosition = this.normalizePlayhead(
            Math.floor(currentTime * 1000)
        );
        const { liveLatencyAmount } = this.getPlaybackMetrics();

        const errorName = mapHiveToQoeErrorCodes(eventData);

        if (fatal) {
            if (eventData.error === 'DRM_FAILED') {
                const errorCase =
                    this.constructErrorCaseFromDssHlsError(eventData);

                this.emit(PublicEvents.MediaFailure, errorCase);
            }

            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: fatal,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.error,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount
                });

                this.playbackEndedEvent({
                    eventData: { errorName, errorDetail }
                });

                this.removeListener(this.listener);
            }
        } else {
            if (Check.assigned(this.listener)) {
                this.onPlaybackError({
                    isFatal: false,
                    errorName,
                    errorMessage: errorDetail,
                    errorLevel: ErrorLevel.warn,
                    playheadPosition,
                    segmentPosition,
                    liveLatencyAmount
                });
            }
        }

        return undefined;
    }

    /**
     *
     * @access private
     * @since 10.0.0
     * @param {Object} eventData
     * @note Handles the SEEKABLE_RANGE_CHANGED event
     *
     */
    private seekableRangeChangedEvent(eventData: { pdtEnd: number }) {
        const { pdtEnd } = eventData || {};

        this.seekableRangeEndProgramDateTime = pdtEnd;
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} [eventData={}]
     * @note Handles the NETWORK_METRICS_CHANGED event
     *
     */
    private bitrateChangedEvent(eventData = {}) {
        if (this.isReady) {
            const {
                currentBitrateAvg: bitrateAvg,
                currentBitratePeak: bitratePeak,
                currentPlayhead: playheadPosition
            } = this.getPlaybackMetrics();

            if (this.bitrateAvg !== bitrateAvg) {
                this.bitrateAvg = bitrateAvg;

                this.onBitrateChanged({
                    ...eventData,
                    bitrateAvg,
                    bitratePeak,
                    playheadPosition
                });
            }
        }
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} eventData
     * @note Handles the SUBTITLES_RENDITION_UPDATED event
     *
     */
    private subtitleChangedEvent(eventData: {
        language?: string;
        name?: string;
        forced?: boolean;
    }) {
        const {
            language: subtitleLanguage,
            name: subtitleName,
            forced: subtitleVisibility
        } = eventData || {};

        // Cache values to be used for heartbeat event.
        this.heartbeatData.playlistSubtitleLanguage =
            subtitleLanguage ?? this.heartbeatData.playlistSubtitleLanguage;
        this.heartbeatData.playlistSubtitleName =
            subtitleName ?? this.heartbeatData.playlistSubtitleName;
        this.heartbeatData.subtitleVisibility =
            subtitleVisibility ?? this.heartbeatData.subtitleVisibility;

        this.onSubtitleChanged();
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} eventData
     * @note Handles the AUDIO_RENDITION_UPDATED event
     *
     */
    private audioChangedEvent(eventData: {
        channels?: string;
        codec?: string;
        language?: string;
        name?: string;
    }) {
        if (this.isReady) {
            const {
                channels: audioChannels,
                codec: audioCodec,
                language: audioLanguage,
                name: audioName
            } = eventData || {};

            // Cache values to be used for heartbeat event.
            this.heartbeatData.playlistAudioChannels =
                audioChannels ?? this.heartbeatData.playlistAudioChannels;
            this.heartbeatData.playlistAudioCodec =
                audioCodec ?? this.heartbeatData.playlistAudioCodec;
            this.heartbeatData.playlistAudioLanguage =
                audioLanguage ?? this.heartbeatData.playlistAudioLanguage;
            this.heartbeatData.playlistAudioName =
                audioName ?? this.heartbeatData.playlistAudioName;

            this.onAudioChanged();
        }
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @note Handles the BUFFERING_STARTED event
     *
     */
    private rebufferingStartedEvent() {
        // Used to calculate duration for rebufferingEndedEvent
        this.rebufferingDuration = Date.now();

        this.isBuffering = true;

        const playbackData = this.getPlaybackData();

        this.bufferType = playbackData.bufferType;

        this.onRebufferingStarted(playbackData);
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @note Handles the `BUFFERING_FINISHED` event
     *
     */
    private rebufferingEndedEvent() {
        this.isBuffering = false;

        const playbackData = this.getPlaybackData();

        const duration = Date.now() - (this.rebufferingDuration as number);

        this.onRebufferingEnded({
            ...playbackData,
            duration,
            bufferType: playbackData.bufferType
                ? playbackData.bufferType
                : this.bufferType
        });

        this.bufferType = undefined;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc Handles the `SEGMENT_PLAYING` event
     *
     */
    private segmentPlayingEvent(eventData: {
        type: MediaSegmentType;
        segment?: { programDateTimeStart?: number };
    }) {
        const { type, segment = {} } = eventData || {};
        const programDateTimeStart = segment.programDateTimeStart;

        this.segmentPosition =
            typeof programDateTimeStart === 'number'
                ? Math.floor(programDateTimeStart)
                : null;

        this.mediaSegmentType = MediaSegmentType[type] || null;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @param {Object} [eventData]
     * @desc Handles the `SOURCE_BUFFER_APPEND_STARTED` event.
     * @note This value gets cleared when `PlaybackTelemetryDispatcher.recordStreamSample()` is called.
     *
     */
    private sourceBufferAppendStartedEvent(eventData?: {
        segment?: { byteSize?: number };
    }) {
        if (Check.number(eventData?.segment?.byteSize)) {
            // TS isn't aware of the `Check.number` call
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.mediaBytesDownloaded += eventData!.segment!.byteSize;
        }
    }

    /**
     *
     * @access private
     * @since 16.1.0
     * @param {Object} eventData
     * @desc Handles the `CONTENT_DOWNLOAD_STARTED` event.
     * @note These values get reset to zero when `PlaybackTelemetryDispatcher.createPlaybackHeartbeatEventData(provider)` is called.
     *
     */
    private contentDownloadFinishedEvent(eventData: {
        startTime: number;
        endTime: number;
    }) {
        const { startTime, endTime } = eventData;

        this.mediaDownloadTotalCount++;
        this.mediaDownloadTotalTime += endTime - startTime;
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @desc sets bam-hls.xhrSetupCallback to ensure a proper accessToken is passed on xhr requests
     * @note in the event of an AdEngine request we want to make `withCredentials=true` in case any platforms are
     * relying on cookies - not all platforms can support the `ssess` header
     *
     */
    private setupXhrCallback() {
        const {
            nativePlayer,
            accessToken,
            DataTypes,
            adEngineData = {}
        } = this;
        const { ssess } = adEngineData;

        (nativePlayer as DssHlsNativePlayer).xhrSetupCallback = (
            xhr,
            url,
            type
        ) => {
            if (DataTypes) {
                const isKeyCall = type === DataTypes.KEY;
                const isAdEngineCall =
                    adEngineRegex(url) && type === DataTypes.CHUNK;

                xhr.withCredentials = false; // Ensure no false positive from any cookies

                if (!xhr.readyState) {
                    xhr.open('GET', url, true);
                }

                if (isKeyCall) {
                    xhr.setRequestHeader(AUTHORIZATION, accessToken as string);
                } else if (isAdEngineCall && ssess) {
                    xhr.withCredentials = true;
                    xhr.setRequestHeader('ssess', ssess);
                }
            }
        };
    }

    /**
     *
     * @access private
     * @since 14.0.0
     * @param {Object} exception - the exception object that bam-hls throws
     * @note exception.cause will be one value from the DRM section of this list:
     * https://github.bamtech.co/bam-hls/bam-hls.js/blob/master/src/hlsjs/errors.js
     * @desc Converts a bam-hls error to an error case object
     * @returns {SDK.Services.Exception.ServiceException} Associated error case for the given bam-hls error
     *
     */
    private constructErrorCaseFromDssHlsError(exception: {
        cause: keyof typeof BamHlsErrorMapping;
    }) {
        const errorCode =
            BamHlsErrorMapping[exception.cause] || BamHlsErrorMapping.default;

        const { code, exceptionData } = errorCode || {};

        return createSimpleException({ code, exceptionData });
    }

    /**
     *
     * @access private
     * @since 16.0.0
     * @returns {Object}
     *
     */
    private getCurrentVariant() {
        const { variants = [] } = this.nativePlayer as DssHlsNativePlayer;
        const { currentVariantIndex } = (
            this.nativePlayer as DssHlsNativePlayer
        ).getNetworkMetrics();

        const currentVariant = variants[currentVariantIndex] || ({} as Variant);
        const maxVariant = variants[variants.length - 1] || ({} as Variant);

        const {
            encodedFrameRate: playlistFrameRate,
            peakBitrate: maxAllowedVideoBitrate
        } = maxVariant;

        const {
            peakBitrate: bitrate,
            encodedFrameRate: frameRate,
            audioCodecs,
            averageBitrate,
            videoCodec,
            videoRangeSubType: videoRange
        } = currentVariant;

        if (this.mediaStartBitrate === null) {
            this.mediaStartBitrate = averageBitrate;
        }

        return {
            resolution: getResolutionString(currentVariant),
            bitrate,
            averageBitrate,
            frameRate,
            audioCodec: Check.array(audioCodecs) ? audioCodecs[0] : undefined,
            videoCodec,
            videoRange,
            maxAllowedVideoBitrate,
            playlistResolution: getResolutionString(maxVariant),
            playlistFrameRate
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {Object}
     *
     */
    private getAudioRendition() {
        const { audioRenditions = [], currentAudioRenditionIndex } = this
            .nativePlayer as DssHlsNativePlayer;
        const audioRendition =
            audioRenditions[currentAudioRenditionIndex] ||
            ({} as AudioRenditionType);

        const { averageBitrate, channels, codec, name, language } =
            audioRendition || {};

        return {
            audioRendition: { name, language },
            averageBitrate,
            audioChannels: channels,
            audioCodec: codec
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {SDK.Services.Media.SubtitleRendition}
     *
     */
    private getSubtitleRendition() {
        const { subtitleRenditions = [], currentSubtitleRenditionIndex } = this
            .nativePlayer as DssHlsNativePlayer;

        return subtitleRenditions[currentSubtitleRenditionIndex] || {};
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @returns {String<SDK.Services.QualityOfService.BufferType>}
     *
     */
    private getBufferType() {
        let bufferType;

        const { mediaElementState } = this.nativePlayer as DssHlsNativePlayer;

        const {
            // DETACHING,
            // DETACHED,
            ATTACHING,
            // ATTACHED,
            // ENDED,
            // PLAYING,
            PAUSED,
            BUFFERING,
            SEEKING
        } = this.MediaElementStates || {};

        if (mediaElementState) {
            switch (mediaElementState) {
                case ATTACHING:
                    bufferType = BufferType.initializing;
                    break;
                case BUFFERING:
                    bufferType = BufferType.buffering;
                    break;
                case SEEKING:
                    bufferType = BufferType.seeking;
                    break;
                case PAUSED:
                    bufferType = BufferType.resuming;
                    break;
                // no default
            }
        }

        return bufferType;
    }

    /**
     *
     * @access protected
     * @since 15.0.0
     * @desc returns playback state enum
     * @note nativePlayer.playbackState will always be populated.
     * @returns {String}
     *
     */
    public override getPlaybackState() {
        let state;

        const { PlaybackStates } = this;

        switch (this.nativePlayer?.playbackState) {
            case PlaybackStates.BUFFERING:
            case PlaybackStates.NOTREADY:
                state = PlaybackState.buffering;
                break;

            case PlaybackStates.ENDED:
                state = PlaybackState.finished;
                break;

            case PlaybackStates.PAUSED:
                state = PlaybackState.paused;
                break;

            case PlaybackStates.PLAYING:
                state = PlaybackState.playing;
                break;

            case PlaybackStates.SEEKING:
                state = PlaybackState.seeking;
                break;

            // no default
        }

        return state;
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc return data relevant to create `PlaybackEventData`
     * @returns {Object}
     *
     */
    private getPlaybackData() {
        const { currentStreamUrl: streamUrl } = this;
        const { currentPDT: segmentPosition } = this
            .nativePlayer as DssHlsNativePlayer;

        const { liveLatencyAmount, currentPlayhead: playheadPosition } =
            this.getPlaybackMetrics();
        const { averageBitrate: audioBitrate } = this.getAudioRendition();

        const {
            bitrate: videoBitrate,
            averageBitrate: videoAverageBitrate,
            maxAllowedVideoBitrate
        } = this.getCurrentVariant();

        const bufferType = this.getBufferType();

        return {
            playheadPosition,
            streamUrl,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            liveLatencyAmount,
            bufferType
        };
    }

    /**
     *
     * @access private
     * @since 15.0.0
     * @desc return data relevant to create `PlaybackStartupEventData`
     * @returns {Object}
     *
     */
    private getPlaybackStartupData() {
        const { currentStreamUrl: streamUrl } = this;

        const { bufferSegmentDuration, currentPlayhead } =
            this.getPlaybackMetrics();
        const { audioRendition, audioChannels } = this.getAudioRendition();
        const subtitle = this.getSubtitleRendition();

        const {
            bitrate,
            averageBitrate,
            resolution,
            frameRate,
            audioCodec,
            videoCodec,
            videoRange
        } = this.getCurrentVariant();

        let audioChannelsNumber;

        if (Check.string(audioChannels)) {
            const audioChannelsMatches = /\d+/.exec(audioChannels);

            if (audioChannelsMatches) {
                audioChannelsNumber = Number(audioChannelsMatches[0]);
            }
        } else if (Check.number(audioChannels)) {
            audioChannelsNumber = audioChannels;
        }

        // TODO: Look into commented values.
        const variant = new PlaybackVariant({
            bandwidth: bitrate,
            resolution,
            // videoBytes,
            // maxAudioRenditionBytes,
            // maxSubtitleRenditionBytes,
            audioChannels: audioChannelsNumber,
            videoRange,
            videoCodec,
            // audioType,
            audioCodec,
            averageBandwidth: averageBitrate,
            frameRate
        });

        return {
            variant,
            audio: audioRendition,
            subtitle,
            streamUrl,
            playheadPosition: currentPlayhead,
            bufferSegmentDuration
        };
    }

    /**
     *
     * @access protected
     * @since 16.1.0
     * @desc Returns heartbeatData object on the ctor for heartbeat event.
     * @returns {Object}
     *
     */
    public override getHeartbeatData() {
        return this.heartbeatData;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Media.PlayerAdapter.DssHlsPlayerAdapter';
    }

    // #endregion
}
