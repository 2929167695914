/* eslint-env browser, amd */

import pageMeta from 'btm-pageMeta/pageMeta'; //eslint-disable-line import/no-unresolved, import/extensions
import browserNag from '../scripts/browserNag';
import geoHelper from '../scripts/geoHelper';
import ApplicationState from './applicationState'; //eslint-disable-line import/no-unresolved, import/extensions
import { BroadcastServiceActions } from './services/actions'; //eslint-disable-line import/no-unresolved, import/extensions
import setupDCF from './dcfBootstrap';

function isExpired(timestamp) {
    const expirationTime = new Date(timestamp);
    const currentTime = new Date();
    return currentTime > expirationTime;
}

function handleExpiredBtmToken(appState) {
    const { oneIdService: { oneId }, sdkService } = appState;

    const refreshAttemptCounter = (function () {
        let count = 0;
        return function () {
            return count++;
        };
    }());

    const forceTokenRefresh = function () {
        const tries = refreshAttemptCounter();
        console.log(`Attempting to refresh OneId token, try ${tries}`);
        if (tries > 2) {
            console.warn('Refresh OneId token attempt limit hit.');
            return oneId.logout().then(() => {
                return sdkService.logout();
            });
        }
        return oneId.forceTokenRefresh().fail(() => {
            return oneId.logout().then(() => {
                return sdkService.logout();
            });
        });
    };

    const handleBreachErrors = function (error) {
        console.error(error);
        if (error && error.reasons && error.reasons.length > 0 && error.reasons[0].code === 'invalid_grant') {
            return forceTokenRefresh().fail(() => {
                return oneId.logout()
                    .then(() => {
                        return sdkService.logout(); // sdk service logout
                    });
            });
        }
        return Promise.resolve();
    };

    return oneId.getGuest()
        .then((guest) => {
            const token = guest.token && guest.token.id_token ? guest.token.id_token : '';
            const swid = guest.profile && guest.profile.swid ? guest.profile.swid : '';

            if (token && swid && !isExpired(guest.token.expires)) {
                sdkService.logout().then(() => {
                    sdkService.login(token);
                }).catch(handleBreachErrors);
            } else {
                forceTokenRefresh();
            }
        })
        .catch(() => {
            forceTokenRefresh();
        });
}


const initialize = async () => {
    const section = pageMeta.section;
    const oneIdEnabled = JSON.parse(!!pageMeta.oneIdEnabled);
    const appState = await ApplicationState.getInstance();

    appState.broadcastService.on(BroadcastServiceActions.Application.ready, async () => {
        const { espnTrackingConfig = {} } = window;
        if (espnTrackingConfig.dcfBootstrapEnabled) {
            setupDCF();
        }

        if (appState.browserInfo.isIE && appState.browserInfo.version === 11) {
            document.body.classList.add('isIE');
        }

        if (appState.browserInfo.isFireFox) {
            document.body.classList.add('firefox');
        }

        if (section !== 'video' && window.location.pathname.indexOf('denied') === -1) {
            try {
                const location = appState.sdkService.sessionInfo.location;
                const geoEnabled = await geoHelper.isUserInGeofence(location.countryCode);

                if (!geoEnabled) {
                    window.location.replace('/denied?block=geo');
                }

            } catch (err) {
                console.error(err);
            }
        }

        try {
            await appState.sdkService.sdkSession.socketApi.start();

            if (oneIdEnabled) {
                appState.sdkService.onReauthorizationFailure(() => {
                    handleExpiredBtmToken(appState);
                });

                appState.broadcastService.on(BroadcastServiceActions.SdkService.sdkSessionResetOnError, () => {
                    handleExpiredBtmToken(appState);
                });
            }

        } catch (err) {
            console.error(err);
        }
    });

    try {
        await appState.initialize();
    } catch (err) {
        console.error(err);
    }
};

initialize();

console.log('site-core: all devices');
