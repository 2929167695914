/**
 *
 * @module invoicePlugin
 * @desc {SDK.Invoice.InvoicePlugin} entry point.
 *
 */

import InvoiceApi from './invoiceApi';

import InvoiceClient from '../services/invoice/invoiceClient';
import InvoiceClientConfiguration from '../services/invoice/invoiceClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Invoice'> = {
    pluginName: 'Invoice',

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.invoiceApi = new InvoiceApi({
            invoiceClient: new InvoiceClient({
                config: sdkSession.config.services.invoice,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.invoice =
            new InvoiceClientConfiguration({
                baseUrl: services?.invoice?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services?.invoice?.client?.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
