/**
 *
 * @module osDeviceIdType
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/architecture/managers/class.sdk.device.plantuml
 *
 */

/**
 *
 * @access public
 * @typedef {Object} SDK.Device.OsDeviceIdType
 * @since 9.0.0
 * @desc Contains Os Device Type Id values.
 *
 * @property {String} amazonAdvertisingId - Since `9.0.0` - For [Amazon AFAI](https://developer.amazon.com/docs/fire-tv/advertising-id.html)
 * @property {String} androidAdvertisingId - Since `9.0.0` - For [AdvertisingIdClient.Info.getId](https://goo.gl/ujzzWh)
 * @property {String} androidDrmId - Since `9.0.0` - For [PROPERTY_DEVICE_UNIQUE_ID](https://goo.gl/smFGrs)
 * @property {String} androidVendorId - Since `9.0.0` - For [ANDROID_ID](https://goo.gl/NQgWvP)
 * @property {String} appleAdvertisingId - Since `9.0.0` - For [ASIdentifierManager.advertisingIdentifier](https://goo.gl/vXB6Qz)
 * @property {String} appleVendorId - Since `9.0.0` - For [UIDevice.identifierForVendor](https://goo.gl/9buBLM)
 * @property {String} bbdAdvertisingId - Since `20.0.0`
 * @property {String} bbdDeviceId - Since `20.0.0`
 * @property {String} chromecastDeviceId - Since `9.0.0`
 * @property {String} comcastAdvertisingId - Since `20.0.0` - For [Comcast](https://developer.comcast.com/x1/docs/develop/limit-ad-tracking-and-ccpa-technical-requirements)
 * @property {String} fireboltAdvertisingId - Since `2.0.0` - For [Sky Firebolt](https://developer.rdkcentral.com/firebolt/documentation/firebolt-core-sdk/api/advertising/)
 * @property {String} playstationDeviceId - Since `9.0.0`
 * @property {String} rokuAdvertisingId - Since `9.0.0` - For [RIDA](https://goo.gl/RNESpu)
 * @property {String} tizenAdvertisingId - Since `20.0.0` - For [Tizen](https://developer.samsung.com/smarttv/develop/guides/unique-identifiers-for-smarttv/tizen-id-for-advertising.html#:~:text=What%20is%20TIFA%3F,at%20CES%20in%20January%2C%202019.)
 * @property {String} tizenDeviceId - Since `20.0.0` - For [Device.uuid](https://goo.gl/PRzdxA)
 * @property {String} unknown - Since `9.0.0` - When waiting for a device identifier type to be added to this enumeration.
 * @property {String} vizioAdvertisingId - Since `20.0.0` - For Vizio docs can be found in a PDF attached to this ticket [BBD-2780](https://jira.disneystreaming.com/browse/BBD-2780)
 * @property {String} webosAdvertisingId - Since `20.0.0` - For [Webos](https://webostv.developer.lge.com/develop/references/device-unique-id#getids)
 * @property {String} webosDeviceId - Since `9.0.0` - For [Device Unique ID](https://goo.gl/SGNvtw)
 * @property {String} windowsAdvertisingId - Since `9.0.0` - For [AdvertisingManager.AdvertisingId](https://goo.gl/wr3nMX)
 * @property {String} windowsHardwareId - Since `9.0.0` - For [ASHWID](https://goo.gl/wTC6a7)
 * @property {String} xboxAdvertisingId - Since `9.0.0` - For [AdvertisingManager.AdvertisingId](https://goo.gl/wr3nMX)
 * @property {String} xboxHardwareId - Since `9.0.0` - For [ASHWID](https://goo.gl/wTC6a7)
 *
 */
enum OsDeviceIdType {
    amazonAdvertisingId = 'amazon.advertising.id',
    androidAdvertisingId = 'android.advertising.id',
    androidDrmId = 'android.drm.id',
    androidVendorId = 'android.vendor.id',
    appleAdvertisingId = 'apple.advertising.id',
    appleVendorId = 'apple.vendor.id',
    bbdAdvertisingId = 'bbd.advertising.id',
    bbdDeviceId = 'bbd.device.id',
    chromecastDeviceId = 'chromecast.device.id',
    comcastAdvertisingId = 'comcast.advertising.id',
    fireboltAdvertisingId = 'firebolt.advertising.id',
    playstationDeviceId = 'playstation.device.id',
    rokuAdvertisingId = 'roku.advertising.id',
    tizenAdvertisingId = 'tizen.advertising.id',
    tizenDeviceId = 'tizen.device.id',
    unknown = 'unknown',
    vizioAdvertisingId = 'vizio.advertising.id',
    webosAdvertisingId = 'webos.advertising.id',
    webosDeviceId = 'webos.device.id',
    windowsAdvertisingId = 'windows.advertising.id',
    windowsHardwareId = 'windows.hardware.id',
    xboxAdvertisingId = 'xbox.advertising.id',
    xboxHardwareId = 'xbox.hardware.id'
}

export default OsDeviceIdType;
