/**
 *
 * @module configurationClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @enum
 * @typedef {Object} SDK.Services.Configuration.ConfigurationClientEndpoint
 *
 * @property {String} getConfiguration
 *
 */
enum ConfigurationClientEndpoint {
    getConfiguration = 'getConfiguration'
}

export default ConfigurationClientEndpoint;
