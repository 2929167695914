/* eslint-disable import/prefer-default-export */

/**
 *
 * @typedef SDK.UserActivity.UserActivityEventCategory
 * @since 22.0.0
 * @desc Defines the known types of user activity events.
 * @see Type 1 enum https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/contributing.md#enum
 * @property {String} AppQoe - Since 22.0.0 - Represents a QoE User Activity Event.
 * @property {String} Glimpse - Since 22.0.0 - Represents a Glimpse User Activity Event.
 *
 */
export enum UserActivityEventCategory {
    /**
     * @note Maps to the urn:bamtech:dust:bamsdk:event:qoe category.
     */
    AppQoe = 'AppQoe',
    /**
     * @note Maps to the urn:bamtech:dust:bamsdk:event:glimpse category.
     */
    Glimpse = 'Glimpse'
}
