/**
 *
 * @module paywallPlugin
 * @desc {SDK.Paywall.PaywallPlugin} entry point.
 *
 */

import PaywallApi from './paywallApi';

import PaywallService from '../services/paywall/entry';
import PaywallClient from '../services/paywall/paywallClient';
import PaywallClientConfiguration from '../services/paywall/paywallClientConfiguration';

import type { IPlugin } from '../IPlugin';

const plugin: IPlugin<'Paywall', void, typeof PaywallService> = {
    pluginName: 'Paywall',
    service: PaywallService,

    /**
     *
     * @access private
     * @since 12.0.0
     * @param {Object} options
     *
     */
    createApi(options) {
        const { sdkSession, logger, clients } = options;
        const { accessTokenProvider } = sdkSession;

        sdkSession.paywallApi = new PaywallApi({
            paywallClient: new PaywallClient({
                config: sdkSession.config.services.paywall,
                logger,
                httpClient: clients.httpClient
            }),
            accessTokenProvider,
            logger
        });
    },

    /**
     *
     * @access protected
     * @since 13.0.0
     * @param {SDK.Services.Configuration.ServiceDefinitionsConfiguration} serviceDefinitionsConfiguration - to attach the *ClientConfiguration object for this plugin.
     * @param {Object} services - JSON services config object returned from configuration service.
     *
     */
    applyConfig(serviceDefinitionsConfiguration, services) {
        serviceDefinitionsConfiguration.paywall =
            new PaywallClientConfiguration({
                baseUrl: services?.paywall?.client?.baseUrl,
                endpoints: serviceDefinitionsConfiguration.processEndpoints(
                    services.paywall.client.endpoints
                )
            });
    }
};

/**
 *
 * @access public
 * @since 12.0.0
 *
 */
export default plugin;
