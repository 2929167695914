/**
 *
 * @module dustSdkPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc contains information specific to this SDK version
 *
 */
export default class DustSdkPayload {
    /**
     *
     * @access private
     * @type {String}
     *
     */
    public version: string;

    /**
     *
     * @access private
     * @type {String}
     * @note platform is being overridden in order to ensure that this data is consistent for all applications using
     * the JS SDK.
     * @note See https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/dust.md#sdk-data
     *
     */
    public platform?: string;

    /**
     *
     * @access private
     * @param {String} version
     * @param {String} [platform]
     *
     */
    // eslint-disable-next-line no-unused-vars
    public constructor(version: string) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                version: Types.nonEmptyString,
                platform: Types.nonEmptyString.optional
            };

            typecheck(this, params, arguments);
        }

        this.version = version;
        this.platform = 'javascript';
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustSdkPayload';
    }
}
