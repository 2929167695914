/**
 *
 * @module accessTokenDelegation
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/token.md#delegation-grants
 *
 */

import DelegationToken from './delegationToken';

/**
 *
 * @access public
 * @since 4.9.0
 *
 */
export default class AccessTokenDelegation extends DelegationToken {
    /**
     *
     * @access public
     * @type {String}
     * @since 4.9.0
     *
     */
    public accessToken: string;

    /**
     *
     * @param {String} accessToken
     *
     */
    public constructor(accessToken: string) {
        const subject = accessToken;
        const subjectType = 'urn:ietf:params:oauth:token-type:access_token';

        super(subject, subjectType);

        this.accessToken = accessToken;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Token.AccessTokenDelegation';
    }
}
