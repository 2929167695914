/**
 *
 * @module dustApplicationPayload
 *
 */

import { Types, typecheck } from '@dss/type-checking';

/**
 *
 * @access protected
 * @since 3.4.0
 * @desc describes the application environment
 *
 */
export default class DustApplicationPayload {
    /**
     *
     * @access public
     * @type {String}
     * @desc Application version
     * @example "1.0"
     *
     */
    public version: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Application Id
     * @example "com.espn.enterprise.sportscenter"
     *
     */
    public id: string;

    /**
     *
     * @access public
     * @type {String}
     * @desc Application name
     * @example "Espn"
     *
     */
    public name: string;

    /**
     *
     * @access public
     * @param {Object} options
     * @param {String} options.version
     * @param {String} options.id
     * @param {String} options.name
     *
     */
    public constructor(options: { version: string; id: string; name: string }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    version: Types.nonEmptyString,
                    id: Types.nonEmptyString,
                    name: Types.nonEmptyString
                })
            };

            typecheck(this, params, arguments);
        }

        const { version, id, name } = options;

        this.version = version;
        this.id = id;
        this.name = name;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Internal.Dust.DustApplicationPayload';
    }
}
