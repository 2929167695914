/**
 *
 * @module typedefs
 *
 */

/* eslint-disable import/prefer-default-export */

import { Types } from '@dss/type-checking';

/**
 *
 * @typedef {Object} SDK.Services.Paywall.CurrentSubscription
 * @since 11.0.0
 * @property {String} subscriptionId
 * @property {String<SDK.Services.Subscription.SubscriptionSourceType>} sourceType - The original source of the subscription.
 * @property {String<SDK.Subscription.SubscriptionProvider>} sourceProvider
 * @property {Boolean} partnerPurchaseSupport
 *
 */
export const CurrentSubscription = {
    subscriptionId: Types.nonEmptyString,
    sourceType: Types.nonEmptyString, // Types.in(SubscriptionSourceType).or.nonEmptyString,
    sourceProvider: Types.nonEmptyString, // Types.in(SubscriptionProvider).or.nonEmptyString,
    partnerPurchaseSupport: Types.boolean
};
