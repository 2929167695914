/**
 *
 * @module contentClientEndpoint
 *
 */

/**
 *
 * @access protected
 * @typedef {Object} SDK.Services.Content.ContentClientEndpoint
 *
 * @property {String} autoSuggestPersisted
 * @property {String} dmcVideos
 * @property {String} getAiring
 * @property {String} getAiringByAiringId
 * @property {String} getAvatar
 * @property {String} getAvatars
 * @property {String} getCWSeason
 * @property {String} getCWSeasonWithBookmark
 * @property {String} getCWSeries
 * @property {String} getCWSeriesWithBookmark
 * @property {String} getCWSet
 * @property {String} getCWSetWithBookmark
 * @property {String} getCWVideo
 * @property {String} getCWVideoWithBookmark
 * @property {String} getCollection
 * @property {String} getCollectionByGroupId
 * @property {String} getCompleteStandardCollection
 * @property {String} getCuratedSet
 * @property {String} getDictionaryLatest
 * @property {String} getDictionaryVersion
 * @property {String} getDmcEpisodes
 * @property {String} getDmcExtras
 * @property {String} getDmcProgramBundle
 * @property {String} getDmcSeason
 * @property {String} getDmcSeasonByNumber
 * @property {String} getDmcSeasons
 * @property {String} getDmcSeries
 * @property {String} getDmcSeriesBundle
 * @property {String} getDmcSeriesMeta
 * @property {String} getDmcVideo
 * @property {String} getDmcVideoBundle
 * @property {String} getDmcVideoMeta
 * @property {String} getLiveAndUpcomingEpisodes
 * @property {String} getPersonalizedProgramBundle
 * @property {String} getPersonalizedProgramBundleWithBookmark
 * @property {String} getPersonalizedSeriesBundle
 * @property {String} getPersonalizedSeriesBundleWithBookmark
 * @property {String} getPersonalizedSet
 * @property {String} getRelatedItemsForSeries
 * @property {String} getRelatedItemsForVideo
 * @property {String} getSearchResults
 * @property {String} getSet
 * @property {String} getSiteSearch
 * @property {String} getStandardCollection
 * @property {String} getUpNext
 * @property {String} putItemInWatchlist
 * @property {String} search
 * @property {String} searchPersisted
 *
 */
enum ContentClientEndpoint {
    autoSuggestPersisted = 'autoSuggestPersisted',
    dmcVideos = 'dmcVideos',
    getAiring = 'getAiring',
    getAiringByAiringId = 'getAiringByAiringId',
    getAvatar = 'getAvatar',
    getAvatars = 'getAvatars',
    getCWSeason = 'getCWSeason',
    getCWSeasonWithBookmark = 'getCWSeasonWithBookmark',
    getCWSeries = 'getCWSeries',
    getCWSeriesWithBookmark = 'getCWSeriesWithBookmark',
    getCWSet = 'getCWSet',
    getCWSetWithBookmark = 'getCWSetWithBookmark',
    getCWVideo = 'getCWVideo',
    getCWVideoWithBookmark = 'getCWVideoWithBookmark',
    getCollection = 'getCollection',
    getCollectionByGroupId = 'getCollectionByGroupId',
    getCompleteStandardCollection = 'getCompleteStandardCollection',
    getCuratedSet = 'getCuratedSet',
    getDictionaryLatest = 'getDictionaryLatest',
    getDictionaryVersion = 'getDictionaryVersion',
    getDmcEpisodes = 'getDmcEpisodes',
    getDmcExtras = 'getDmcExtras',
    getDmcProgramBundle = 'getDmcProgramBundle',
    getDmcSeason = 'getDmcSeason',
    getDmcSeasonByNumber = 'getDmcSeasonByNumber',
    getDmcSeasons = 'getDmcSeasons',
    getDmcSeries = 'getDmcSeries',
    getDmcSeriesBundle = 'getDmcSeriesBundle',
    getDmcSeriesMeta = 'getDmcSeriesMeta',
    getDmcVideo = 'getDmcVideo',
    getDmcVideoBundle = 'getDmcVideoBundle',
    getDmcVideoMeta = 'getDmcVideoMeta',
    getLiveAndUpcomingEpisodes = 'getLiveAndUpcomingEpisodes',
    getPersonalizedProgramBundle = 'getPersonalizedProgramBundle',
    getPersonalizedProgramBundleWithBookmark = 'getPersonalizedProgramBundleWithBookmark',
    getPersonalizedSeriesBundle = 'getPersonalizedSeriesBundle',
    getPersonalizedSeriesBundleWithBookmark = 'getPersonalizedSeriesBundleWithBookmark',
    getPersonalizedSet = 'getPersonalizedSet',
    getRelatedItemsForSeries = 'getRelatedItemsForSeries',
    getRelatedItemsForVideo = 'getRelatedItemsForVideo',
    getSearchResults = 'getSearchResults',
    getSet = 'getSet',
    getSiteSearch = 'getSiteSearch',
    getStandardCollection = 'getStandardCollection',
    getUpNext = 'getUpNext',
    putItemInWatchlist = 'putItemInWatchlist',
    search = 'search',
    searchPersisted = 'searchPersisted'
}

export default ContentClientEndpoint;
