/**
 *
 * @module playbackEventData
 * @see https://github.bamtech.co/schema-registry/schema-registry-qoe/blob/v1.4.1/yaml/dss/event/qoe/client/playback/v1/playback.yaml
 *
 */

import { Check, Types, typecheck } from '@dss/type-checking';
import { AdMetadata } from '../../media/typedefs';

import {
    AdInsertionType,
    PresentationType,
    BufferType,
    NetworkType,
    PlaybackActivity,
    PlayerSeekDirection,
    ProductType,
    SeekDirection,
    QoePlaybackError,
    MediaSegmentType,
    SkipType
} from './enums';

import ServerRequest from './serverRequest';

import {
    AdPodData,
    AdPodDataTypedef,
    AdPodPlacement,
    AdPodPlacementTypedef,
    AdSlotData,
    AdSlotDataTypedef
} from './typedefs';

/**
 *
 * @since 13.0.0
 * @desc These events represent playback related events. This event should be sent every time the `playbackActivity` changes.
 * @note urn:dss:event:client:playback:event:v1
 *
 */
export default class PlaybackEventData {
    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.PlaybackActivity|undefined}
     * @desc Used to identify what activity is occurring.
     *
     */
    public playbackActivity?: PlaybackActivity;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc The selected stream URL from the MediaPayload.
     *
     */
    public streamUrl: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.ProductType|undefined}
     * @desc The Product type, Live or VOD.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public productType?: ProductType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The location of the current playhead, measured as a millisecond offset from the start time. -1 if value is not available.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackStartedEvent`).
     *
     */
    public playheadPosition: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The peak video bitrate in bps.
     * @note The SDK should keep track of the most recent videoBitrate value from the `PlaybackEventListener.onBitrateChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The average video bitrate in bps.
     * @note The SDK should keep track of the most recent videoAverageBitrate value from the `PlaybackEventListener.onBitrateChanged` event.
     * @note If value is 0 or not available return 0.
     *
     */
    public videoAverageBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Nominal average (encoded) bitrate of the currently selected audio representation or of the audio representation/variant last
     * played before the event, as reported in the HLS or DASH manifest.
     * @note The SDK should keep track of the most recent audioBitrate value from the `PlaybackEventListener.onAudioBitrateChanged` event.
     * @note If the value is 0 or not available, return 0. If value is muxed, send the bitrate of the combined content.
     *
     */
    public audioBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc The highest video bitrate (in bps) available in the current DASH/HLS manifest that is allowed to play.
     * The user may not reach this bitrate in their session.
     * @note This value should account for client-side constraints such as data-saver or player imposed bitrate or
     * resolution caps based on video window resolution/orientation.
     * @note The value should default to 0 if unavailable or unknown.
     * @note If the `PlaybackActivity` is `started`, SDKs should source this from the associated `SDK.Media.PlaybackStartedEvent`
     * event and update the cached `maxAllowedVideoBitrate` value.
     * @note If the `PlaybackActivity` is not `started`, SDKs should source this from the cached `maxAllowedVideoBitrate` value.
     *
     */
    public maxAllowedVideoBitrate: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc This should be the epoch time in milliseconds of the video.
     * @note Required if `productType` is `Live`.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `Bam.Sdk.Media.PlaybackStartedEvent`).
     *
     */
    public segmentPosition?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String}
     * @desc The CDN running the server.
     * @note A string value of 'null' needs to be provided if not run by a CDN.
     *
     */
    public cdnName: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.NetworkType}
     * @desc The type of network connection currently in use by the client.
     *
     */
    public networkType: NetworkType;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Latency of the current playback position in milliseconds, with the live head (live head - current position >= 0).
     * Required if `productType` is `Live`.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackStartedEvent`).
     *
     */
    public liveLatencyAmount?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @description The reason for ending playback, pausing, seeking or resuming. Required if `playbackActivity` is `ended`,
     * `seekStarted`, `seekEnded`, `paused` or `resumed`.
     * @note Values for `ended` are defined by `PlaybackExitedCause`.
     * @note Values for `pause` are defined by `PlaybackPausedCause`.
     * @note Values for `resume` are defined by `PlaybackResumedCause`.
     * @note Values for `seekStarted`/`seekEnded` are defined by `PlaybackSeekCause`.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackPausedEvent`).
     *
     */
    public cause?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The type of buffering that is happening.
     * @note Required if `playbackActivity` is `rebufferingStarted` or `rebufferingEnded`.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.RebufferingStartedEvent`
     * or `SDK.Media.RebufferingEndedEvent`).
     *
     */
    public bufferType?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc The amount of wall clock time in milliseconds, between rebuffering started and rebuffering ended.
     * @note Required if `playbackActivity` is `rebufferingEnded`.
     * @note Source from the `SDK.Media.RebufferingEndedEvent`.
     *
     */
    public duration?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.PlayerSeekDirection|undefined}
     * @desc Where the intended seek position is relative to current position.
     * @note Required if `playbackActivity` is `seekStarted` or `seekEnded`.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `SDK.Media.PlaybackSeekStartedEvent`
     * or `SDK.Media.PlaybackSeekEndedEvent`).
     *
     */
    public playerSeekDirection?: PlayerSeekDirection;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc How far the player seeked in stream time, in milliseconds. This is the actual value, not a measure of intent.
     * @note Required if `playbackActivity` is `seekStarted` or `seekEnded`.
     * @note This value should always be positive. If a player is attempting the seek while already in the middle of a seek, it
     * is the distance from where the previous seek was to.
     * @note Source from the associated playback event that matches the `PlaybackActivity` (e.g. `Bam.Sdk.Media.PlaybackSeekStartedEvent`
     * or `Bam.Sdk.Media.PlaybackSeekEndedEvent`).
     *
     */
    public seekDistance?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.SeekDirection|undefined}
     * @desc To provide indication that the user is seeking to/from a specific point of playback eg. live playback (live edge).
     * @note Heimdall 1.0 field. Can optionally be provided if `productType` is `Live` and if `playbackActivity` is `seekStarted`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackSeekStartedEvent`.
     *
     */
    public seekDirection?: SeekDirection;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number|undefined}
     * @desc Indication that the user is seeking by a fixed time (size) e.g. +30, -30.
     * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `seekStarted`.
     * @note Source from the associated `Bam.Sdk.Media.PlaybackSeekStartedEvent`.
     *
     */
    public seekSize?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {SDK.Services.QualityOfService.QoePlaybackError|undefined}
     * @desc An error representing a fatal playback failure. `null` if no error occurred.
     * @note Can optionally be provided if `playbackActivity` is `ended`.
     * @note If an error occurred, SDKs should convert either the associated MediaFetchError or a PlaybackError to a QoePlaybackError.
     *
     */
    public playbackError?: QoePlaybackError;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc Supporting error text for `playbackError` property where this can be provided for additional context.
     * @note Unless requested otherwise, set to native platform error message if available.
     * @note Heimdall 1.0 field. Can optionally be provided if `playbackActivity` is `ended`.
     * @note Source from the `SDK.Media.PlaybackEndedEvent`.
     *
     */
    public playbackErrorDetail?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Array<String>}
     * @desc List of CDNs attempted in case of failure cases e.g. {Fastly, Akamai, Level-3}.
     * @note Conditionally required if `playbackActivity` is `ended` or `reattempt`.
     *
     */
    public cdnRequestedTrail: Array<string>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Array<String>}
     * @desc List of CDNs failed e.g. {Fastly, Akamai}.
     * @note Conditionally required if `playbackActivity` is `ended` or `reattempt`.
     *
     */
    public cdnFailedTrail: Array<string>;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Number}
     * @desc Value increments from 0 for each fallback action. This value initializes at zero for the
     * first CDN attempt, and will increment by 1 for each fallback action that occurs.
     * @note Conditionally required if `playbackActivity` is `ended` or `reattempt`.
     *
     */
    public cdnFallbackCount: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Boolean}
     * @desc False if first cdn selected successful i.e. `cdnFailedTrail` empty, True otherwise.
     * @note Conditionally required if `playbackActivity` is `ended` or `reattempt`.
     *
     */
    public isCdnFallback: boolean;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when testing PQM.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public clientGroupIds?: string;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {String|undefined}
     * @desc The group IDs of the current playback session when using a certain backend QoS algorithm.
     * @note Source from the playlist request payload response from the `qosDecisions` object.
     *
     */
    public serverGroupIds?: string;

    /**
     *
     * @access public
     * @since 20.0.0
     * @type {Number|undefined}
     * @desc Timestamp in milliseconds (relative to when the device was booted, or some other fixed time origin) when the record was captured.
     * @note Source from `MonotonicTimestampProvider.getTimestamp()`.
     *
     */
    public monotonicTimestamp?: number;

    /**
     *
     * @access public
     * @since 13.0.0
     * @type {Object<String, String>}
     * @desc Associated content keys for the media item.
     * @note KVPs encompassing these: CollectionId, ProgramId, FamilyId, ContentId, SeriesId, MediaId values.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public contentKeys: Record<string, string>;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.PresentationType}
     * @desc The type of presentation currently being played.
     * @note Source from the latest `PresentationType` provided by the `PlaybackEventListener.onPresentationTypeChanged`
     * event or the `Bam.Sdk.Media.PlaybackStartedEvent` object.
     *
     */
    public presentationType: PresentationType;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {SDK.Services.QualityOfService.AdInsertionType}
     * @desc The way ads are inserted into the stream.
     * @note Source by converting the `Bam.Sdk.Media.MediaDescriptor.AssetInsertionStrategy`.
     *
     */
    public adInsertionType: AdInsertionType;

    /**
     *
     * @access public
     * @since 22.0.0
     * @type {Boolean|undefined}
     * @desc Indicates whether the playlist contains slugs.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.hasSlugs field.
     * @note Default to false if unavailable.
     *
     */
    public hasSlugs?: boolean;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String}
     * @desc An identifier that provides insight into the tier of service associated with the subscription that
     * is entitled for playback.
     * @note Required if `adInsertionType` != `none`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.subscriptionType field.
     *
     */
    public subscriptionType: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {String|undefined}
     * @desc Random UUID assigned by the ad server.
     * @note Required if `adInsertionType` != `none`.
     * @note Source from the Bam.Sdk.Services.MediaPayloadStream.AdsQos.adSession.id field.
     *
     */
    public adSessionId?: string;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodPlacement>|undefined}
     * @desc Placement information relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adPodPlacement?: AdPodPlacement;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdPodData>|undefined}
     * @desc Metadata relevant to ad pods.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adPodData?: AdPodData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Object<SDK.Services.QualityOfService.AdSlotData>|undefined}
     * @desc Metadata relevant to ad slots.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adSlotData?: AdSlotData;

    /**
     *
     * @access public
     * @since 19.0.0
     * @type {Number|undefined}
     * @desc The location of the current ad playhead, measured as a millisecond offset from the start time of the
     * current ad pod.
     * @note Required if `presentationType` is `ad` and `adInsertionType` != `none`.
     * @note Source from the associated `PlaybackEventListener` event arguments.
     *
     */
    public adPlayheadPosition?: number;

    /**
     *
     * @deprecated Value will be reported as 'deprecated'. Replaced by `presentationType`.
     * @access public
     * @since 20.0.1
     * @type {String}
     *
     */
    public periodType: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The name of the video player used to play a stream.
     * @note Source from the `Bam.Sdk.Media.PlayerAdapter`.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all playback events.
     *
     */
    public videoPlayerName?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The version of the video player used to play a stream.
     * @note Source from the `Bam.Sdk.Media.PlayerAdapter`.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all playback events.
     *
     */
    public videoPlayerVersion?: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The vendor of the CDN. e.g. VERIZON.
     * @note This field is a pass-through from the Playlist Service response, so SDK does not control the name or
     * value. Conditionally required if `playbackActivity` is `ended`, `variantFetched`, or `reattempt`.
     * @note Default to a string value of 'null' if unavailable.
     *
     */
    public cdnVendor: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc Name of the CDN, appended with Origin e.g. LEVEL3-SECURE.
     * @note This field is a pass-through from the Playlist Service response, so SDK does not control the name or
     * value. Conditionally required if `playbackActivity` is `ended`, `variantFetched`, or `reattempt`.
     * @note Default to a string value of 'null' if unavailable.
     *
     */
    public cdnWithOrigin: string;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {Boolean}
     * @desc True if the media is being played from a local source, e.g. it has been downloaded for offline playback or was bundled in the application.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for all playback events.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public localMedia: boolean;

    /**
     *
     * @access public
     * @since 23.0.0
     * @type {String}
     * @desc The client-side generated unique ID representing a single element interaction within the container.
     * The `interactionId` will correspond with one of the defined `interactionTypes`. The `interactionId` will be used as
     * the primary key for all interactions.
     * @note Marked as optional in Helios to prevent upstream breaking changes, but SDKs should send this field for
     * all playback events when `interactionId` is not null.
     * @note Source from the `SDK.Media.PlaybackContext`.
     *
     */
    public interactionId?: string;

    /**
     *
     * @access public
     * @since 24.0.0
     * @type {Boolean}
     * @desc A flag indicating if the media fetch request to the playback orchestration service succeeded.
     * @note SDKs can determine this value if the media fetch to the PBO service was successful or not.
     * @note This is required for all playback events.
     *
     */
    public mediaFetchSucceeded: boolean;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {Number|undefined}
     * @desc As defined by the BANDWIDTH (where available) of the highest bitrate variant attained during a
     * viewer session while playing primary content. For HLS Manifest, use the 'BANDWIDTH' value and for
     * DASH, use the '@bandwidth' value.
     * @note Should be updated at least once per period (per heartbeat).
     * @note The SDK should keep track of the highest `videoBitrate` value from the `PlaybackEventListener.onPlaybackStarted`, `PlaybackEventListener.onBitrateChanged`, and `PlaybackEventListener.onPresentationTypeChanged` events.
     * @note If value is 0 or not available return 0.
     * @note Conditionally required if playbackActivity is `ended`.
     *
     */
    public maxAttainedBitrate?: number;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {SDK.Services.QualityOfService.MediaSegmentType}
     * @desc Media type of the HLS variant manifest.
     * @note Conditionally required if `playbackActivity` is `variantFetched`.
     *
     */
    public mediaSegmentType?: MediaSegmentType;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {SDK.Services.QualityOfService.ServerRequest}
     * @desc Data about a request/response to a server.
     * @note Conditionally required if `playbackActivity` is `variantFetched`.
     *
     */
    public serverRequest?: ServerRequest;

    /**
     *
     * @access public
     * @since 27.0.0
     * @type {Object}
     * @desc Metadata for the currently presented ad, if the manifest finishes loading while an ad is playing, null otherwise.
     *
     */
    public adMetadata?: Nullable<AdMetadata>;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {SDK.Services.QualityOfService.SkipType}
     * @desc This field is used to indicate user engagement on skip functionality during playback.
     * @note Required if `playbackActivity` is `seekStarted` or `seekEnded` and `cause` = `skip`.
     * @note Source from the `Bam.Sdk.Media.PlaybackSeekStartedEvent` or `Bam.Sdk.Media.PlaybackSeekEndedEvent`.
     *
     */
    public skipType?: Nullable<SkipType>;

    /**
     *
     * @access public
     * @since 28.4.0
     * @type {String|undefined}
     * @desc An opaque encoded string from the Playback Orchestration interface sent during program boundaries.
     * @note This field should be included on all playback events if the SDK has a cached value from a `PlaybackSession.updateProgramMetadata()` invocation.
     * @note If the SDK does not have a cached `programBoundaryInfoBlock` omit this field from the payload. Do not set to an empty string or null.
     *
     */
    public programBoundaryInfoBlock?: string;

    /**
     *
     * @access public
     * @since 28.0.0
     * @type {String|undefined}
     * @desc Viewing environments are 360 degree immersive spaces that transport the user into a full screen disney experience.
     * @note Conditionally required where `platformId` = `realitydevice`.
     * @note Source from the `Bam.Sdk.Media.PlaybackContext`.
     * @note The SDK should keep track of the most recent `viewingEnvironment` value, initially from the `PlaybackContextOptions.viewingEnvironment`
     * field and updates through the `PlaybackEventListener.onViewingEnvironmentChanged` event.
     *
     */
    public viewingEnvironment?: string;

    /**
     *
     * @param {Object} [options={}]
     * @param {SDK.Services.QualityOfService.PlaybackActivity} [options.playbackActivity]
     * @param {String} [options.streamUrl='null']
     * @param {SDK.Services.QualityOfService.ProductType} [options.productType]
     * @param {Number} [options.playheadPosition=-1]
     * @param {Number} [options.videoBitrate=0]
     * @param {Number} [options.videoAverageBitrate=0]
     * @param {Number} [options.audioBitrate=0]
     * @param {Number} [options.maxAllowedVideoBitrate=0]
     * @param {Number} [options.segmentPosition]
     * @param {String} [options.cdnName='null']
     * @param {String} [options.cdnVendor='null']
     * @param {String} [options.cdnWithOrigin='null']
     * @param {SDK.Services.QualityOfService.NetworkType} [options.networkType=SDK.Services.QualityOfService.NetworkType.unknown]
     * @param {Number} [options.liveLatencyAmount]
     * @param {String} [options.cause]
     * @param {SDK.Services.QualityOfService.BufferType} [options.bufferType]
     * @param {Number} [options.duration]
     * @param {SDK.Services.QualityOfService.PlayerSeekDirection} [options.playerSeekDirection]
     * @param {Number} [options.seekDistance]
     * @param {SDK.Services.QualityOfService.SeekDirection} [options.seekDirection]
     * @param {Number} [options.seekSize]
     * @param {SDK.Services.QualityOfService.QoePlaybackError} [options.playbackError]
     * @param {String} [options.playbackErrorDetail]
     * @param {Array<String>} [options.cdnRequestedTrail=[]]
     * @param {Array<String>} [options.cdnFailedTrail=[]]
     * @param {Number} [options.cdnFallbackCount=0]
     * @param {Boolean} [options.isCdnFallback=false]
     * @param {String} [options.clientGroupIds]
     * @param {String} [options.serverGroupIds]
     * @param {Number} [options.monotonicTimestamp]
     * @param {Object<String, String>} [options.contentKeys={}]
     * @param {Object} [options.data={}]
     * @param {SDK.Services.QualityOfService.PresentationType} [options.presentationType=PresentationType.unknown]
     * @param {SDK.Services.QualityOfService.AdInsertionType} [options.adInsertionType=AdInsertionType.none]
     * @param {Boolean} [options.hasSlugs=false]
     * @param {SDK.Services.Media.SubscriptionType|undefined} [options.subscriptionType]
     * @param {String} [options.adSessionId]
     * @param {Object<SDK.Services.QualityOfService.AdPodPlacement>} [options.adPodPlacement]
     * @param {Object<SDK.Services.QualityOfService.AdPodData>} [options.adPodData]
     * @param {Object<SDK.Services.QualityOfService.AdSlotData>} [options.adSlotData]
     * @param {Number} [options.adPlayheadPosition]
     * @param {String} [options.videoPlayerName]
     * @param {String} [options.videoPlayerVersion]
     * @param {Boolean} [options.localMedia=false]
     * @param {String} [options.interactionId]
     * @param {Boolean} options.mediaFetchSucceeded
     * @param {SDK.Services.QualityOfService.MediaSegmentType} [options.mediaSegmentType]
     * @param {SDK.Services.QualityOfService.ServerRequest} [options.serverRequest]
     * @param {Object} [options.adMetadata = null]
     * @param {Object} [options.qoe={}]
     * @param {Number} [options.maxAttainedBitrate=0]
     * @param {Object<SDK.Services.QualityOfService.SkipType>} [options.skipType=SkipType.unknown]
     * @param {String} [options.viewingEnvironment]
     * @param {String} [options.programBoundaryInfoBlock]
     *
     */
    public constructor(options?: {
        playbackActivity?: PlaybackActivity;
        streamUrl?: string;
        productType?: ProductType;
        playheadPosition?: number;
        videoBitrate?: number;
        videoAverageBitrate?: number;
        audioBitrate?: number;
        maxAllowedVideoBitrate?: number;
        segmentPosition?: number;
        cdnName?: string;
        cdnVendor?: string;
        cdnWithOrigin?: string;
        networkType?: NetworkType;
        liveLatencyAmount?: number;
        cause?: string;
        bufferType?: BufferType;
        duration?: number;
        playerSeekDirection?: PlayerSeekDirection;
        seekDistance?: number;
        seekDirection?: SeekDirection;
        seekSize?: number;
        playbackError?: QoePlaybackError;
        playbackErrorDetail?: string;
        cdnRequestedTrail?: Array<string>;
        cdnFailedTrail?: Array<string>;
        cdnFallbackCount?: number;
        isCdnFallback?: boolean;
        contentKeys?: Record<string, string>;
        clientGroupIds?: string;
        serverGroupIds?: string;
        monotonicTimestamp?: number;
        data?: Record<string, unknown>;
        presentationType?: PresentationType;
        adInsertionType?: AdInsertionType;
        hasSlugs?: boolean;
        subscriptionType?: string;
        adSessionId?: string;
        adPodPlacement?: AdPodPlacement;
        adPodData?: AdPodData;
        adSlotData?: AdSlotData;
        adPlayheadPosition?: number;
        videoPlayerName?: string;
        videoPlayerVersion?: string;
        localMedia?: boolean;
        interactionId?: string;
        mediaFetchSucceeded: boolean;
        mediaSegmentType?: MediaSegmentType;
        serverRequest?: ServerRequest;
        adMetadata?: AdMetadata;
        qoe?: Record<string, unknown>;
        maxAttainedBitrate?: Nullable<number>;
        skipType?: Nullable<SkipType>;
        viewingEnvironment?: string;
        programBoundaryInfoBlock?: string;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    playbackActivity: Types.in(PlaybackActivity).optional,
                    streamUrl: Types.nonEmptyString.optional,
                    productType: Types.in(ProductType).optional,
                    playheadPosition: Types.number.optional,
                    videoBitrate: Types.number.optional,
                    videoAverageBitrate: Types.number.optional,
                    audioBitrate: Types.number.optional,
                    maxAllowedVideoBitrate: Types.number.optional,
                    segmentPosition: Types.number.optional,
                    cdnName: Types.nonEmptyString.optional,
                    cdnVendor: Types.nonEmptyString.optional,
                    cdnWithOrigin: Types.nonEmptyString.optional,
                    networkType: Types.in(NetworkType).optional,
                    liveLatencyAmount: Types.number.optional,
                    cause: Types.nonEmptyString.optional,
                    bufferType: Types.in(BufferType).optional,
                    duration: Types.number.optional,
                    playerSeekDirection: Types.in(PlayerSeekDirection).optional,
                    seekDistance: Types.number.optional,
                    seekDirection: Types.in(SeekDirection).optional,
                    seekSize: Types.number.optional,
                    playbackError: Types.in(QoePlaybackError).optional,
                    playbackErrorDetail: Types.nonEmptyString.optional,
                    cdnRequestedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFailedTrail: Types.array.of.nonEmptyString.optional,
                    cdnFallbackCount: Types.number.optional,
                    isCdnFallback: Types.boolean.optional,
                    contentKeys: Types.object().optional,
                    clientGroupIds: Types.nonEmptyString.optional,
                    serverGroupIds: Types.nonEmptyString.optional,
                    monotonicTimestamp: Types.number.optional,
                    data: Types.object().optional,
                    presentationType: Types.in(PresentationType).optional,
                    adInsertionType: Types.in(AdInsertionType).optional,
                    hasSlugs: Types.boolean.optional,
                    subscriptionType: Types.string.optional,
                    adSessionId: Types.nonEmptyString.optional,
                    adPodPlacement: Types.object(AdPodPlacementTypedef)
                        .optional,
                    adPodData: Types.object(AdPodDataTypedef).optional,
                    adSlotData: Types.object(AdSlotDataTypedef).optional,
                    adPlayheadPosition: Types.number.optional,
                    videoPlayerName: Types.nonEmptyString.optional,
                    videoPlayerVersion: Types.nonEmptyString.optional,
                    localMedia: Types.boolean.optional,
                    interactionId: Types.nonEmptyString.optional,
                    mediaFetchSucceeded: Types.boolean.optional,
                    qoe: Types.object().optional,
                    maxAttainedBitrate: Types.number.optional,
                    mediaSegmentType: Types.in(MediaSegmentType).optional,
                    serverRequest: Types.instanceStrict(ServerRequest).optional,
                    adMetadata: Types.object().optional,
                    skipType: Types.in(SkipType).optional,
                    viewingEnvironment: Types.nonEmptyString.optional,
                    programBoundaryInfoBlock: Types.nonEmptyString.optional
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            playbackActivity,
            streamUrl,
            productType,
            playheadPosition,
            videoBitrate,
            videoAverageBitrate,
            audioBitrate,
            maxAllowedVideoBitrate,
            segmentPosition,
            cdnName,
            cdnVendor,
            cdnWithOrigin,
            networkType,
            liveLatencyAmount,
            cause,
            bufferType,
            duration,
            playerSeekDirection,
            seekDistance,
            seekDirection,
            seekSize,
            playbackError,
            playbackErrorDetail,
            cdnRequestedTrail,
            cdnFailedTrail,
            cdnFallbackCount,
            isCdnFallback,
            clientGroupIds,
            serverGroupIds,
            monotonicTimestamp,
            contentKeys,
            data = {},
            presentationType,
            adInsertionType,
            hasSlugs,
            subscriptionType,
            adSessionId,
            adPodPlacement,
            adPodData,
            adSlotData,
            adPlayheadPosition,
            videoPlayerName,
            videoPlayerVersion,
            localMedia,
            interactionId,
            mediaFetchSucceeded,
            qoe = {},
            maxAttainedBitrate,
            mediaSegmentType,
            serverRequest,
            adMetadata,
            skipType,
            viewingEnvironment,
            programBoundaryInfoBlock
        } = options || {};

        this.playbackActivity = playbackActivity;
        this.streamUrl = streamUrl || 'null';
        this.productType = productType;

        this.playheadPosition = Math.ceil(playheadPosition ?? -1);
        this.videoBitrate = videoBitrate || 0;
        this.videoAverageBitrate = videoAverageBitrate || 0;
        this.audioBitrate = audioBitrate || 0;
        this.maxAllowedVideoBitrate = maxAllowedVideoBitrate || 0;

        this.segmentPosition = Check.number(segmentPosition)
            ? Math.floor(segmentPosition)
            : undefined;

        this.cdnName = cdnName || 'null';
        this.cdnVendor = cdnVendor || 'null';
        this.cdnWithOrigin = cdnWithOrigin || 'null';
        this.networkType = networkType || NetworkType.unknown;

        this.liveLatencyAmount = Check.number(liveLatencyAmount)
            ? Math.floor(liveLatencyAmount)
            : undefined;

        this.cause = cause;
        this.bufferType = bufferType;
        this.duration = duration;
        this.playerSeekDirection = playerSeekDirection;
        this.seekDistance = seekDistance;
        this.seekDirection = seekDirection;
        this.seekSize = seekSize;
        this.playbackError = playbackError;
        this.playbackErrorDetail = playbackErrorDetail;
        this.cdnRequestedTrail = cdnRequestedTrail || [];
        this.cdnFailedTrail = cdnFailedTrail || [];
        this.cdnFallbackCount = cdnFallbackCount || 0;
        this.isCdnFallback = isCdnFallback || false;
        this.clientGroupIds = clientGroupIds;
        this.serverGroupIds = serverGroupIds;
        this.monotonicTimestamp = monotonicTimestamp;
        this.contentKeys = contentKeys || {};
        this.presentationType = presentationType || PresentationType.unknown;
        this.adInsertionType = adInsertionType || AdInsertionType.none;
        this.hasSlugs = hasSlugs || false;
        this.subscriptionType = subscriptionType || '';
        this.adSessionId = adSessionId;
        this.adPodPlacement = adPodPlacement;
        this.adPodData = adPodData;
        this.adSlotData = adSlotData;
        this.adPlayheadPosition = adPlayheadPosition;
        this.localMedia = localMedia || false;
        this.interactionId = interactionId;
        this.periodType = 'deprecated';
        this.videoPlayerName = videoPlayerName;
        this.videoPlayerVersion = videoPlayerVersion;
        this.mediaFetchSucceeded = mediaFetchSucceeded ?? true;
        this.maxAttainedBitrate = maxAttainedBitrate ?? 0;
        this.mediaSegmentType = mediaSegmentType;
        this.serverRequest = serverRequest;
        this.adMetadata = adMetadata || null;
        this.skipType = skipType || SkipType.unknown;
        this.viewingEnvironment = viewingEnvironment;
        this.programBoundaryInfoBlock = programBoundaryInfoBlock;

        this.setData(data);
        this.setData(qoe);
    }

    /**
     *
     * @access private
     * @since 13.0.0
     * @param {Object} [data]
     * @desc Assign data.
     * @note IMPORTANT: The key/value pairs from the data HashMap must be flattened upon serialization such that the
     * resulting json does not contain a "data" property but rather a new top level property for each key/value pair
     * in the HashMap.
     *
     */
    private setData(data?: Record<string, unknown>) {
        if (Check.nonEmptyObject(data)) {
            Object.assign(this, data);
        }
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.QualityOfService.PlaybackEventData';
    }
}
