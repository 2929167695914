/**
 *
 * @module replaceUrl
 *
 */

/**
 *
 * @access private
 * @since 17.0.0
 * @param {*} value
 * @desc Stringify value.
 * @returns {String}
 *
 */
function toString(value: unknown) {
    if (Array.isArray(value)) {
        return value.join('');
    }

    if (value == null) {
        return '';
    }

    return `${value}`;
}

/**
 *
 * @access protected
 * @since 17.0.0
 * @param {String} url - Url to be replaced
 * @param {Object} paramsObject - Object where each key represent a param name in given `url`.
 * @desc Replaces params in given `url` with values.
 * @note Values will be stringified as param values are always string
 * @returns {String} A new url
 *
 * @example
 * const url = '/{version}/variant/{partnerId}/{imageId}';
 *
 * const newUrl = replaceUrl(url, { partnerId: 'disney', imageId: 123, version: 'v1' });
 *
 * // newUrl = '/v1/variant/disney/123';
 *
 */
export default (url: string, paramsObject?: Record<string, unknown>) => {
    if (!paramsObject) {
        return url;
    }

    return Object.entries(paramsObject).reduce((prev, curr) => {
        const key = curr[0];
        const value = curr[1];

        const regexp = new RegExp(`{${key}}`, 'i');

        return prev.replace(regexp, toString(value));
    }, url);
};
