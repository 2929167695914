/**
 *
 * @module contentClientConfiguration
 *
 */

import { IEndpoints } from '../providers/typedefs';
import ServiceClientConfiguration from '../configuration/serviceClientConfiguration';

import ContentClientExtrasMap from './contentClientExtrasMap';

/**
 *
 * @access protected
 * @desc Provides configuration information used by the `ContentClient`.
 *
 */
export default class ContentClientConfiguration extends ServiceClientConfiguration<
    Record<string, never>,
    ContentClientExtrasMap
> {
    /**
     *
     * @access public
     * @type {ContentClientExtrasMap}
     * @desc Gets or sets the extra data needed by the client.
     *
     */
    public override extras: ContentClientExtrasMap;

    /**
     *
     * @param {Object} options
     * @param {String} options.baseUrl
     * @param {Object} options.endpoints
     * @param {ContentClientExtrasMap} options.extras
     *
     */
    public constructor(options: {
        baseUrl: string;
        endpoints: IEndpoints<Record<string, never>>;
        extras: ContentClientExtrasMap;
    }) {
        super(options);

        this.extras = options.extras;
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Content.ContentClientConfiguration';
    }
}
