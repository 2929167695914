/**
 *
 * @module configurationClient
 * @see https://github.bamtech.co/sdk-doc/spec-sdk/blob/master/specs/feature_overviews/configuration.md
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import Logger from '../../logging/logger';
import PlatformProviders from '../providers/platformProviders';
import DustLogUtility from '../internal/dust/dustLogUtility';
import DustUrnReference from '../internal/dust/dustUrnReference';
import CoreHttpClientProvider from '../providers/shared/coreHttpClientProvider';
import replaceHeaders from '../util/replaceHeaders';

import ConfigurationClientConfiguration from './configurationClientConfiguration';
import ConfigurationClientEndpoint from './configurationClientEndpoint';
import BootstrapConfiguration from './bootstrapConfiguration';
import LogTransaction from '../../logging/logTransaction';
import EnvironmentConfiguration from '../providers/browser/environmentConfiguration';
import HttpHeaders from '../providers/shared/httpHeaders';

import type { SdkConfigRoot } from './typedefs';
import ClientBase from '../clientBase';
import { IEndpoint } from '../providers/typedefs';

const ConfigurationClientDustReference =
    DustUrnReference.services.configuration.configurationClient;

/**
 *
 * @access protected
 * @desc Provides a data client that can be used to access configuration services.
 *
 */
export default class ConfigurationClient extends ClientBase<ConfigurationClientConfiguration> {
    /**
     *
     * @param {Object} options
     * @param {SDK.Services.Configuration.ConfigurationClientConfiguration} options.config
     * @param {SDK.Logging.Logger} options.logger
     * @param {CoreHttpClientProvider} options.httpClient
     *
     */
    public constructor(options: {
        config: ConfigurationClientConfiguration;
        logger: Logger;
        httpClient: CoreHttpClientProvider;
    }) {
        super(options);

        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = Types.object({
                config: Types.instanceStrict(ConfigurationClientConfiguration)
            });

            typecheck(this, params, arguments);
        }

        this.logger.log(this.toString(), 'Created.');
    }

    /**
     *
     * @param {SDK.Services.Configuration.BootstrapConfiguration} bootstrapConfiguration - The bootstrap configuration
     * information used to construct the service request.
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} environmentConfiguration - The environment
     * configuration information used to construct the service request.
     * @param {SDK.Logging.LogTransaction} logTransaction
     * @returns {Promise<SdkConfigRoot>} The configuration information.
     *
     */
    public getConfiguration(
        bootstrapConfiguration: BootstrapConfiguration,
        environmentConfiguration: EnvironmentConfiguration,
        logTransaction: LogTransaction
    ) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                bootstrapConfiguration: Types.instanceStrict(
                    BootstrapConfiguration
                ),
                environmentConfiguration: Types.instanceStrict(
                    PlatformProviders.EnvironmentConfiguration
                ),
                logTransaction: Types.instanceStrict(LogTransaction)
            };

            typecheck(this, 'getConfiguration', params, arguments);
        }

        const { logger } = this;

        const payload = this.getPayload({
            bootstrapConfiguration,
            environmentConfiguration
        });

        const endpointKey = ConfigurationClientEndpoint.getConfiguration;

        const dustLogUtility = new DustLogUtility({
            logger,
            source: this.toString(),
            urn: ConfigurationClientDustReference.getConfiguration,
            payload,
            endpointKey,
            logTransaction
        });

        logger.info(this.toString(), `GET ${payload.url}`);

        return super.request({
            payload,
            dustLogUtility,
            handleServiceResponseMethodName: endpointKey,
            resultMapper: (response) => {
                return response.data as SdkConfigRoot;
            }
        });
    }

    /**
     *
     * @access private
     * @param {Object} options
     * @param {SDK.Services.Configuration.BootstrapConfiguration} options.bootstrapConfiguration
     * @param {SDK.Services.Configuration.EnvironmentConfiguration} options.environmentConfiguration
     * @returns {Object} payload
     *
     */
    private getPayload(options: {
        bootstrapConfiguration: BootstrapConfiguration;
        environmentConfiguration: EnvironmentConfiguration;
    }) {
        const { bootstrapConfiguration, environmentConfiguration } = options;
        const {
            sdkVersion,
            deviceFamily,
            deviceProfile,
            configVersion,
            applicationRuntimeConfigSegment
        } = environmentConfiguration;

        const {
            debugEnabled,
            environment,
            clientId,
            configHostName,
            configHostOverride,
            configHostUrlOverride,
            extras: {
                configExtensions: { configTokenReplacements = {} } = {}
            } = {}
        } = bootstrapConfiguration;

        const { endpoints, extras } = this.config;
        const { bootstrap } = endpoints;
        const {
            headers: originalHeaders,
            href,
            templated,
            method
        } = bootstrap as IEndpoint;

        const headers = replaceHeaders({}, originalHeaders, undefined, {
            ignoreRequestId: true // to help avoid OPTIONS request
        });

        let url = (debugEnabled && configHostUrlOverride) || href;

        if (templated) {
            const baseUrl =
                configHostOverride ||
                (extras.configHostParams[configHostName]?.baseUrl as string);

            url = url
                .replace(/\{baseUrl\}/gi, baseUrl)
                .replace(/\{configVersion\}/gi, configVersion)
                .replace(/\{clientId\}/gi, clientId)
                .replace(/\{deviceFamily\}/gi, deviceFamily)
                .replace(/\{sdkVersion\}/gi, sdkVersion)
                .replace(
                    /\{applicationRuntime\}/gi,
                    applicationRuntimeConfigSegment
                )
                .replace(/\{deviceProfile\}/gi, deviceProfile)
                .replace(/\{environment\}/gi, environment);
        }

        return {
            url,
            method,
            headers: new HttpHeaders(headers),
            tuningOptions: {
                ignoreRequestIdValidation: true
            },
            preJsonParseProcessor: (data: string) => {
                if (configTokenReplacements) {
                    Object.entries(configTokenReplacements).forEach(
                        ([key, value]) => {
                            data = data.replace(
                                new RegExp(`{${key}}`, 'g'),
                                value as string
                            );
                        }
                    );
                }

                return data;
            }
        };
    }

    /**
     *
     * @access private
     *
     */
    public override toString() {
        return 'SDK.Services.Configuration.ConfigurationClient';
    }
}
