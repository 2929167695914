/**
 *
 * @module tokenManagerExtrasMap
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import RetryPolicy from './retryPolicy';

/**
 *
 * @access protected
 * @desc Provides additional configuration information used by the manager.
 *
 */
export default class TokenManagerExtrasMap {
    /**
     *
     * @access public
     * @type {Number}
     * @desc Gets or sets a value that indicates when the access context should be refreshed.
     *
     */
    public refreshThreshold: number;

    /**
     *
     * @access public
     * @type {RetryPolicy}
     * @desc Gets or sets the retry policy to help drive the retry behavior for service failures.
     *
     */
    public autoRefreshRetryPolicy: RetryPolicy;

    /**
     *
     * @access public
     * @type {Record<string, string>}
     * @desc Gets or sets the `subject_token_type` values to use with each grant type.
     *
     */
    public subjectTokenTypes: Record<string, string>;

    /**
     *
     * @access public
     * @since 16.0.0
     * @type {Boolean}
     * @desc Disables token refresh timer
     *
     */
    public disableTokenRefresh?: boolean;

    /**
     *
     * @param {Object} options
     * @param {Number} options.refreshThreshold
     * @param {RetryPolicy} options.autoRefreshRetryPolicy
     * @param {Record<string, string>} options.subjectTokenTypes
     * @param {Boolean} options.disableTokenRefresh
     *
     */
    public constructor(options: {
        refreshThreshold: number;
        autoRefreshRetryPolicy: RetryPolicy;
        subjectTokenTypes: Record<string, string>;
        disableTokenRefresh: boolean;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    refreshThreshold: Types.number,
                    autoRefreshRetryPolicy: Types.instanceStrict(RetryPolicy),
                    subjectTokenTypes: Types.nonEmptyObject,
                    disableTokenRefresh: Types.boolean
                })
            };

            typecheck.warn(this, params, arguments);
        }

        const {
            refreshThreshold,
            autoRefreshRetryPolicy,
            subjectTokenTypes,
            disableTokenRefresh
        } = options || {};

        this.refreshThreshold = refreshThreshold;
        this.autoRefreshRetryPolicy = autoRefreshRetryPolicy;
        this.subjectTokenTypes = subjectTokenTypes;
        this.disableTokenRefresh = disableTokenRefresh;
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Services.Configuration.TokenManagerExtrasMap';
    }
}
