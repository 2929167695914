/**
 *
 * @module typedefs
 *
 */

import { Types } from '@dss/type-checking';
import { HttpCoreMethod } from '../../providers/typedefs';

/**
 *
 * @typedef {Object} SDK.Services.Internal.Dust.DustLogUtilityPayload
 * @property {String} [url]
 * @property {HttpCoreMethod} method
 *
 */
export type DustLogUtilityPayload = {
    url?: string;
    method: HttpCoreMethod;
};

/**
 *
 * @access private
 *
 */
export const DustLogUtilityPayloadTypedef = {
    url: Types.string.optional,
    method: Types.nonEmptyString
};
