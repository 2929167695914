/**
 *
 * @module envelopeMessageQueue
 *
 */

import { Types, typecheck } from '@dss/type-checking';

import { SocketMessageAcknowledgment } from '../socket/typedefs';

import DustUrnReference from '../services/internal/dust/dustUrnReference';
import Logger from '../logging/logger';
import MessageEnvelope from '../socket/messageEnvelope';
import {
    UnacknowledgedEventBuffer,
    UnacknowledgedEventBufferTypedef
} from '../services/configuration/typedefs';

const SocketUrns = DustUrnReference.socket;

type TokenAwareMessageEnvelope = {
    accessToken?: string;
    message: MessageEnvelope;
};

/**
 *
 * @access protected
 *
 */
export default class EnvelopeMessageQueue {
    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Number}
     * @desc The fallback max number of `SocketEvent`s to queue and wait for acknowledgment messages for.
     *
     */
    public static fallbackMaxBufferSize = 100;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {SDK.Logging.Logger}
     *
     */
    private logger: Logger;

    /**
     *
     * @access protected
     * @since 28.0.0
     * @type {Array<MessageEnvelope>}
     * @desc Buffer of sent messages awaiting the socket to be in a connected state.
     *
     */
    private messageBuffer: Array<MessageEnvelope>;

    /**
     *
     * @access protected
     * @since 28.0.0
     * @type {Array<TokenAwareMessageEnvelope>}
     * @desc Buffer of sent messages awaiting acknowledgment from the service.
     *
     */
    public unacknowledgedBuffer: Array<TokenAwareMessageEnvelope>;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Array<String>}
     * @desc The set of URNs that should not be added to the unacknowledgedBuffer. Meaning we do not care about their delivery status.
     *
     */
    private ignoreMessageAcknowledgementUrns: Array<string>;

    /**
     *
     * @access private
     * @since 28.0.0
     * @type {Number}
     * @desc The max number of `SocketEvent`s to queue and wait for acknowledgment messages for.
     *
     */
    public maxUnacknowledgedBufferSize: number;

    /**
     *
     * @access protected
     * @param {Object} options
     * @param {SDK.Logging.Logger} options.logger
     * @param {UnacknowledgedEventBuffer} [options.unacknowledgedEventBuffer]
     *
     */
    public constructor(options: {
        logger: Logger;
        unacknowledgedEventBuffer?: UnacknowledgedEventBuffer;
    }) {
        /* istanbul ignore else */
        if (__SDK_TYPECHECK__) {
            const params = {
                options: Types.object({
                    logger: Types.instanceStrict(Logger),
                    unacknowledgedEventBuffer: Types.object(
                        UnacknowledgedEventBufferTypedef
                    ).optional
                })
            };

            typecheck(this, params, arguments);
        }

        this.logger = options.logger;

        const maxUnacknowledgedBufferSize =
            options.unacknowledgedEventBuffer?.maxSize;
        const useFallbackMaxBufferSize = Number.isNaN(
            Number(maxUnacknowledgedBufferSize)
        );

        this.maxUnacknowledgedBufferSize = useFallbackMaxBufferSize
            ? EnvelopeMessageQueue.fallbackMaxBufferSize
            : maxUnacknowledgedBufferSize!;

        this.unacknowledgedBuffer = [];
        this.ignoreMessageAcknowledgementUrns = [SocketUrns.socketManager.ping];
        this.messageBuffer = [];
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {TokenAwareMessageEnvelope} messageEnvelope
     *
     */
    public trackMessage(messageEnvelope: TokenAwareMessageEnvelope) {
        if (
            this.ignoreMessageAcknowledgementUrns.includes(
                messageEnvelope.message.eventType
            )
        ) {
            return;
        }

        if (
            this.unacknowledgedBuffer.length >
            this.maxUnacknowledgedBufferSize - 1
        ) {
            this.logger.log(
                this.toString(),
                'Max unacknowledgedBuffer - removing oldest message'
            );

            this.unacknowledgedBuffer.shift();
        }

        if (this.maxUnacknowledgedBufferSize !== 0) {
            this.unacknowledgedBuffer.push(messageEnvelope);
        }
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {MessageEnvelope} envelope
     *
     */
    public enqueue(envelope: MessageEnvelope) {
        this.messageBuffer.push(envelope);
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @returns {MessageEnvelope}
     *
     */
    public dequeue() {
        return this.messageBuffer.shift();
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @returns {Boolean}
     *
     */
    public get hasMessagesToSend() {
        return this.messageLength > 0;
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @returns {Number}
     *
     */
    public get messageLength() {
        return this.messageBuffer.length;
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {Number} [batchLimit=messageLength] - The max number of messages to return, defaults to all messages in queue.
     * @returns {Array<SDK.Socket.MessageEnvelope>}
     *
     */
    public getMessagesToSend(batchLimit = this.messageLength) {
        const batchCount = Math.min(batchLimit, this.messageLength);

        return this.messageBuffer.splice(0, batchCount);
    }

    /**
     *
     * @access public
     * @since 28.0.0
     * @param {Function} resendMessage - The function to resend a message.
     * @param {Function} sendWithSessionAuthentication - The function to send a message with session authentication.
     * @param {Object} socketMessageAcknowledgment - The acknowledgment message from the socket.
     * @desc Removes a message from the `unacknowledgedBuffer` and, based on the `status`, determines whether the message needs to be resent as-is or with `sessionAuthentication`.
     * @returns {Void}
     *
     */
    public processAcknowledgment(
        resendMessage: (message: TodoAny) => void,
        sendWithSessionAuthentication: (
            accessToken: string,
            message: MessageEnvelope
        ) => void,
        socketMessageAcknowledgment?: SocketMessageAcknowledgment
    ) {
        const { eventId, status, retriesExhausted } =
            socketMessageAcknowledgment || {};

        const messageIndex = this.unacknowledgedBuffer.findIndex((item) => {
            return eventId === item.message.id;
        });

        if (messageIndex > -1) {
            // Always remove the message if we get a received event for it
            const [{ message, accessToken }] = this.unacknowledgedBuffer.splice(
                messageIndex,
                1
            );

            if (!retriesExhausted) {
                if (status === 'rejected.internal-failure') {
                    resendMessage(message);
                } else if (status === 'rejected.envelope-subject-invalid') {
                    sendWithSessionAuthentication(
                        accessToken as string,
                        message
                    );
                }
            }
        }
    }

    /**
     *
     * @access public
     * @since 28.0.0
     *
     */
    public clear() {
        this.messageBuffer = [];
    }

    /**
     *
     * @access private
     *
     */
    public toString() {
        return 'SDK.Internal.EnvelopeMessageQueue';
    }
}
